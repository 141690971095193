import React from 'react';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';
import Button from '@mui/material/Button';

const primaryStyles = css`
  background-color: #FCED50;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #424242;
    background-color: #FCED50;
  }
`;

const secondaryStyles = css`
  background-color: none;
  font-weight: 700;
  color: #303030;
  border: 1px solid #bababa;

  &.disabled {
    color: #303030;
    border: 1px solid #bababa;
    background-color: none;
  }
`;

const tertiaryStyles = css`
  background-color: #000;
  color: white;

  &:hover {
    background-color: #424242;
  }

  &.disabled {
    color: white;
    background-color: #000;
  }
`;

const borderedStyles = css`
  background-color: #fff;
  color: #235A91;
  border: 2px solid #235A91;

  &.disabled {
    color: white;
    background-color: #78AADA;
  }
`;

const getButtonStyles = ({ type }) => {
  switch (type) {
    case 'secondary':
      return secondaryStyles;
    case 'tertiary':
      return tertiaryStyles;
    case 'bordered':
      return borderedStyles;
    default:
      return primaryStyles;
  }
};

export default styled(({ color, ...otherProps }) => (
  <Button {...otherProps} classes={{ disabled: 'disabled' }} />
))`
  border-radius: 1rem;
  width: 100%;
  height: 47px;
  white-space: nowrap;

  & .label {
    font-size: 1.4rem;
  }

  @media (min-width: 992px) {
    border-radius: .4rem;
    // width: ${({ large }) => (large ? '7rem' : '4.5rem')};
    // height: ${({ large }) => (large ? '3rem' : '2.9rem')};

    & .label {
      font-size:.98rem;
      font-weight: 500;
    }
  }

  ${getButtonStyles}
`;
