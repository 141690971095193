import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@mui/material';
import { AffiliateBodyCell, MainTableRow } from '../dashboard/dashboard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import ReconciliationActionTypes from '../../../providers/reducers/reconciliation/reconciliationTypes';
import { useStateValue } from '../../../providers/stateProvider';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';

const ReturnOutOfStockRow = ({
  outOfStockSale,
}) => {
  const [, dispatch] = Object.values(useStateValue());
  const {
    id,
    quantity,
    total,
    saleItem: {
      product,
      sale: {
        dateCreated,
        receiptNumber,
        businessUser: {
          user: {
            username
          }
        }
      },
      name,
      packSize,
    }
  } = outOfStockSale;
  const selectForReconciliation = () => {
    dispatch({ type: ReconciliationActionTypes.SELECT_FOR_RECONCILIATION, payload: { ...product, targetQuantity: quantity, saleItemBatchId: id } });
  };
  return (
    <TableColumnRows className="erp-uat-product-tb-row" style={{ cursor: 'pointer' }} onClick={selectForReconciliation}>
      <TableColumnRowText>{dateCreated ? moment(dateCreated).format('lll') : 'N/A'}</TableColumnRowText>
      <TableColumnRowText>
        {name}
      </TableColumnRowText>
      <TableColumnRowText className="erp-uat-product-tb-brandName">
        {quantity > 0 ? `-${Math.abs(quantity)}` : `${quantity}`}
      </TableColumnRowText>
      <TableColumnRowText>{packSize}</TableColumnRowText>
      <TableColumnRowText>{receiptNumber}</TableColumnRowText>
      <TableColumnRowText>
        {username}
      </TableColumnRowText>
      <TableColumnRowText>
        {currencyFormatter(total)}
      </TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnOutOfStockRow.propTypes = {
  outOfStockSale: PropTypes.instanceOf(Object).isRequired,
};

export default ReturnOutOfStockRow;
