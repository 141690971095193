import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@mui/material';
import { MenuItemStyled } from './actionPopper.styles';
import { Dots } from '../../assets/svgs';
import { useStateValue } from '../../providers/stateProvider';

export default function ActionPopper({
  row, handleAction
}) {
  const [{
    user: { allowedPermissionsMap: { userPermissions } },
  },] = Object.values(useStateValue());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Dots
        fontSize="large"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <Menu
        elevation={0}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItemStyled
          disabled={!userPermissions?.includes('customers_view_customer_prescription')}
          onClick={(e) => { handleAction(e, 'view', row); handleClose(); }}
        >
          View prescription
        </MenuItemStyled>
        <MenuItemStyled
          disabled={!userPermissions?.includes('customers_edit_customer')}
          onClick={(e) => { handleAction(e, 'edit', row); handleClose(); }}
        >
          Edit
        </MenuItemStyled>
        <MenuItemStyled
          disabled={!userPermissions?.includes('customers_delete_customer')}
          onClick={(e) => { handleAction(e, 'delete', row); handleClose(); }}
        >
          Delete
        </MenuItemStyled>
      </Menu>
    </div>
  );
}

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
};
