import { createTheme, styled, adaptV4Theme } from '@mui/material/styles';
import { Typography, Grid, Box } from '@mui/material';

export const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%',
        paddingTop: '20px'
      },
      root: {
        backgroundColor: '#fff',
        border: '1px dashed #F3F9FF',
        borderColor: '#78AADA'
      }
    }
  }
}));

export const TitleText = styled(Typography)`
  color: #303030;
  font-size: 11.107px;
  font-family: Montserrat;
  font-weight: 700;
`;

export const TitleDesc = styled(Typography)`
  color: rgba(0, 0, 0, 0.40);
  font-size: 11.107px;
  font-family: Montserrat;
  font-weight: 400;
  margin: 5px 0 10px 0;
`;

export const Dash = styled(Box)`
    height: 3px;
    width: 8%;
    margin: 2px 8px;
    background: #CECECE;
`;

export const MiddleText = styled(Typography)`
    font-family: Montserrat,medium,sans-serif;
    font-size: 17px;
`;
export const BackgroundImage = styled('img')`
    height: 70px;
    margin-bottom: 20px;
`;

export const MiddleGroup = styled(Grid)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 30px 0 40px;
`;

export const BrowseFileButton = styled(Grid)`
  width: 120px;
  height: 35px;
  line-height: 35px;
  color: #235A91;
  border: 1px solid #235A91;
  border-radius: 3.96671px;
  font-weight: 600;
  font-size: 12px;
  margin: 0 auto;
`;

export const DropZoneContainer = styled(Grid)`
`;
