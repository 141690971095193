import {
  Grid, Typography, Paper, Button, CircularProgress,
  TextField, ButtonGroup, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardPaper = styled(Paper)`
  border-radius: 1rem;
  height: ${(props) => (props.cart ? '33rem' : '34rem')};
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  background: #FFFFFF;
  border: 0.5px solid #E7E8E9;
  box-shadow: 0px 6px 16px rgba(193, 192, 192, 0.25);

  @media (max-width: 991px) {
    height: ${(props) => (props.cart ? '44rem' : '46rem')};
    min-height: ${(props) => (props.stock ? '600px' : '')};
    border-radius: 2rem;
    display: flex;
    padding:  2.5rem;
  }
`;

export const ImgBox = styled('div')`
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 200px;
  position: relative;
  padding: 1rem;
  margin: auto auto 0.5rem auto;

  @media (max-width: 991px) {
    height: 100%;
    width: 30%;
    margin: auto;
    padding: 0;
  }
`;

export const ProductImg = styled('img')`
  width: ${(props) => (props.cart ? '90%' : '100%')};
  height: 200px;

  @media (max-width: 991px) {
    height: 45%;
    margin-top: 9rem;
  }
`;

export const DetailsBox = styled('div')`
  height: 100px;
  padding: 15px;

  @media (max-width: 991px) {
    padding: 0 3rem 3rem 3rem;
    width: 70%;
    height: 100%;
  }
`;

export const DetailName = styled(Typography)`
  font-size: 1em;
  font-weight: 550;
`;

export const DetailPack = styled(Typography)`
  color: #F2EFEE;
`;

export const PriceTypo = styled(Typography)`
  font-size: 1.5em;
  font-weight: 600;
`;

export const SavingsTypo = styled(Typography)`
`;

export const PromoGrid = styled(Grid)`
  margin: 10px 0;

  @media(min-width: 992px) {
    margin: 15px;
    margin-left: 0px;
  }
`;

export const PromoImg = styled('img')`
  width: 25px;
  margin-right: 10px;
  margin-bottom: -7px;

  @media(min-width: 992px) {
    width: 18px;
  }
`;

export const PromoOffer = styled(Typography)`
  font-size: 25px;
  font-weight: 500;
  color: #AF1839;

  @media(min-width: 992px) {
    font-size: 15px;
  }
`;

export const HeaderContainer = styled(Grid)`
  width: 100%;

  @media (min-width: 992px) {
    height: 35px;
  }

  @media (max-width: 991px) {
    margin-bottom: 1rem;
    max-height: 11rem;
  }
`;

export const DetailsContainer = styled('div')`
  width: 100%;
`;
export const ActionsContainer = styled(Grid)`
  display: flex;
  align-items: center;

  @media(max-width: 991px) {
    margin-top: ${(props) => ((!props.status && !props.promo) ? '75px' : (!props.status && props.promo) ? '6rem' : (props.status && props.promo) ? '6rem' : '30px')};
  }
  @media(min-width: 992px) {
    margin-top: ${(props) => ((!props.status && !props.promo) ? '40px' : (!props.status && props.promo) ? '61px' : (props.status && props.promo) ? '44px' : '20px')};
  }
`;

export const AddCartGrid = styled(Grid)`
  margin-top: -2rem;
`;

export const ActionBox = styled(Box)`
  diplay: flex;
  flex-direction: row;
`;

export const HeaderText = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const TypoContainer = styled(Grid)`
  margin-top: 15px;
  margin-bottom: .5rem;
`;

export const ImageContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const PackSizeTypo = styled(Typography)`
  font-size: 1.9rem;
  font-weight: 500;
  color: #909090;
  margin-bottom: 1rem;

  @media(min-width: 992px) {
    font-size: .75rem;
    margin-bottom: .5rem;
  }

  @media (max-width: 991px) {
    margin-bottom: .5rem;
    // display: ${(props) => (props.similarAction ? 'block' : '')}
  }
`;

export const PricingAndSaving = styled(Grid)`
  // margin-top: 8px;
  align-items: center;
  margin-bottom: .5rem;
`;

export const PromoPriceText = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  text-decoration-line: line-through;
  margin-left: auto;
  color: #5E6366;

  @media (max-width: 991px) {
    font-size: 2.2rem;
    font-weight: 500;
  }
`;

export const PricingWrapper = styled(Grid)`
`;

export const SavingsWrapper = styled(Box)`
  display: flex;
  // margin-bottom: .5rem;
  // margin-top: 5px;
  // align-items: right;
  // justify-content: flex-end;
  // text-align: right;

  // @media(min-width: 992px) {
  //   justify-content: flex-end;
  // }
`;

export const SavingsGrid = styled(Grid)`
`;

export const PricingTypo = styled(Typography)`
  font-size: 2rem;
  color: #909090;

  @media(min-width: 992px) {
    font-size: .875rem;
    font-weight: 400;
    color: #5E6366;
  }

  @media (max-width: 991px) {
    font-weight: 500;
  }
`;

export const Pricing = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  color: #3e3e3e;

  @media(min-width: 992px) {
    font-size: 1.25rem;
  }

  @media (max-width: 991px) {
     font-weight: 700;
  }
`;

export const SavingPricing = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 600;
  color: #3e3e3e;
  margin-left: 10px;

  @media(min-width: 992px) {
    font-size: .875rem;
  }
`;

export const ProductImage = styled('div')`
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

export const PromoImage = styled('div')`
  width: 25px;
`;

export const ActionButton = styled(Button)`
  border: none;

  &:disabled {
    border: none;
  }

  @media (min-width: 992px) {
    &.MuiButtonGroup-grouped {
      min-width: 0;
    }

    &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
      margin-left: -15px;
    }
  }
`;

export const InputButton = styled(Button)`
  font-size: 1rem;
  padding: 5px 24px;
  font-weight: 400;
  background-color: #F0F0F0;
  width: 3.7rem;

  &:disabled {
    color: #424242;
    border: 1px solid #F0F0F0;
    border-right: #F0F0F0;
  }
`;

export const InputButtonGroup = styled(ButtonGroup)`
  border: none;

  @media (max-width: 991px) {
    &.MuiButtonGroup-root {
      width: 100%;
    }
  }
`;

export const ActionTextField = styled(TextField)`
  & ::placeholder {
    color: #000;
    text-align: center;
  }

  & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
    font-size: 1em;
    width: 1.2rem;
    text-align: center;
    color: #000;
  }

  @media (max-width: 991px) {
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
      font-size: 3rem;
      width: 10rem;
    }
  }

  @media (min-width: 992px) {
    margin-left: 10px;
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
      font-size: 1rem;
    }
  }
`;

export const ActionTextBox = styled(Box)`
`;

export const AddButton = styled(Button)`
  border-radius: 8px;
  background-color: ${(props) => (props.counter > 0 ? '#F9F123' : '')};
  border: ${(props) => (props.counter > 0 ? 'none' : '')};

  &:hover {
    background-color: ${(props) => (props.counter > 0 ? '#F9F123' : '')};
  }

  &.MuiButton-outlined.Mui-disabled {
    border: 1px solid #606060;
  }

  &.MuiButton-root.Mui-disabled {
    color: #424242;
  }

  &.MuiButton-contained.Mui-disabled {
    color: #424242;
    background-color: #FCE850;
    opacity: 0.5;
  }

  @media(min-width: 992px) {
    border-radius: 4px;
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 15px;
    height: 5rem;
  }
`;

export const AddCartTypo = styled(Typography)`
  font-size: 2.2rem;

  @media (max-width: 991px) {
    margin-left: 5px;
    color: #606060;
    font-size: ${(props) => (props.similarAction ? '1.8rem' : '2rem')}
  }

  @media(min-width: 992px) {
    font-size: .7rem;
  }
`;

export const ShoppingCartImg = styled('img')`
  width: 15px;

  @media (max-width: 991px) {
    width: ${(props) => (props.similarAction ? '40px' : '45px')}
  }
`;

export const ActionImg = styled('img')`
  width: 100px;

  @media(min-width: 992px) {
    width: 20px;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #C5C5C5;
`;

// export const PromoText = styled('p')`
//   font-size: 1.3rem;
//   text-align: right;
//   margin: 0;
//   padding: 0;
//   position: absolute;
//   right: 10px;
//   top: 10px;
//   color: white;
//   padding: 0 1.5%;
//   border-radius: 5px;

//   @media(min-width: 992px) {
//     font-size: .7rem;
//     top: 5px;
//   }
// `;

export const ProductVContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  width: 8.125rem;
  height: ${(props) => (props.cart ? '2.2rem' : '2.313rem')};
  background-color: ${(props) => (props.status === 72 ? '#E1FFE2' : '#EEE900')};
  border-radius: 0.231rem;
  margin-bottom: 1rem;
  // position: absolute;
  // right: 1rem;
  // top: 1rem;

  @media(max-width: 991px) {
    padding: 20px;
    width: 16.25rem;
    height: ${(props) => (props.cart ? '2.313rem' : '4.626rem')};
    margin-top: ${(props) => (props.cart ? '25px' : '')};
  }
`;
export const ProductVariantText = styled('div')`
  // padding: 1rem;
  text-align: center;
  // color: #000000;
  // padding: .5rem 1rem;
  // border-radius: 5px;
  // background-color: #EEE900;
  font-weight: 500;
  font-size: .75rem;
  color: ${(props) => (props.status === 72 ? '#05AC0C' : '#000000')};

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }

`;

export const ChangeBox = styled(Typography)`
  // padding: 5px;
  // background-color: #D0EECC;
  color: #E83A25;
  font-size: .75rem;
  border-radius: 5px;
  font-weight: 500;

  @media(max-width: 991px) {
    font-size: 1.9rem;
    font-weight: 450;
    margin-bottom: .5rem;
  }
`;

export const ChangeWrapper = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 60%;

  @media (max-width: 991px) {
    margin-bottom: .5rem;
  }

  @media(min-width: 992px) {
    width: 100%;
    margin-bottom: 0;
    display: block;
  }
`;

export const PromoContainer = styled(Box)`
  background: #FFEDEA;
  border-radius: 5px;
  width: 4.563rem;
  height: 2.313rem;
  position: absolute;

  @media (max-width: 991px) {
    height: 4.626rem;
    width: 9.126rem;
    border-radius: 10px;
  }
`;

export const PromoText = styled(Typography)`
  font-size: .75rem;
  color: #E83A25;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;

  @media (max-width: 991px) {
    font-size: 1.5rem;
    padding: 20px 0;
  }
`;

export const TimeText = styled(Typography)`
  font-weight: 400;
  font-size: .75rem;
  color: #606060;
  margin-left: 10px;
  margin-top: 1.5px;

  @media (max-width: 991px) {
    font-size: 1.7rem;
    margin-left: 20px;
  }
`;

export const TimeBox = styled(Box)`
  display: flex;
`;

export const TimeImg = styled('img')`
  @media (max-width: 991px) {
    width: 50px;
    height: 50px;
  }
`;
