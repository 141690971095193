import {
  Paper, Popper, ListItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CPopper = styled(Popper)`
  z-index: 15000000
`;

export const CListItem = styled(ListItem)`
  padding: 0.5rem;
  background-color: ${({ selected }) => (selected ? '#F3F9FF' : '')};
  &:hover {
    background-color: #F3F9FF;
  }
`;

export const PaperWrapper = styled(Paper)`
  width: 9rem;
  padding: .5rem;
  box-shadow: -1px 2px 8px 1px #00000017;
  transition: transform .3s;
  z-index: 500;
  margin-top: 5px;
`;
