import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Table,
  TableContainer,
  TableRow,
  TableBody
} from '@mui/material';
import currencyFormatter from '../../../../../utils/currencyFormatter';
import {
  TableCellStyled,
  TableCellBodyStyled,
  TableRowStyled,
  TableHeadStyled,
  CardTitle,
  BoxStyled
} from '../return-details.style';
import { JSONParse } from '../../../../../utils/json';

const SalesDetails = ({ row, isReturn }) => {
  const getDiscountPercentage = (total, disc) => (disc / total) * 100;

  const {
    sale: {
      total,
      discount,
      // totalToPay,
      meta,
      aggregateTotal,
      changeDue,
      transactionDate,
      business: { name },
      receiptNumber,
      dateCreated,
      paymentMethod,
      customer,
      businessUser,
      salesPayment = []
    }
  } = row;

  const vat_percent = JSONParse(meta)?.sale_vat?.vat_percent || '0';

  const {
    user: { username }
  } = businessUser;

  let cash = 0.0;
  let cashUsedInPayment = 0.0;

  salesPayment.forEach((method) => {
    if (method.paymentMethod === 'CASH') {
      cash = method.rawAmount;
      cashUsedInPayment = method.amount;
    }
    return null;
  });

  return (
    <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px" mt={4}>
      <CardTitle>{isReturn ? 'Return Details' : 'Sales Details' }</CardTitle>
      <TableContainer style={{ marginTop: '1rem' }}>
        <Table style={{ width: '100%' }}>
          <TableHeadStyled>
            <TableRowStyled>
              <TableCellStyled>Date Created</TableCellStyled>
              <TableCellStyled>Outlet ID</TableCellStyled>
              <TableCellStyled>Reg. ID</TableCellStyled>
              <TableCellStyled>Receipt #</TableCellStyled>
              <TableCellStyled>{isReturn ? 'Return By' : 'Sold By' }</TableCellStyled>
              <TableCellStyled>Payment Method</TableCellStyled>
              <TableCellStyled>Customer</TableCellStyled>
              <TableCellStyled>Loyalty Points</TableCellStyled>
              <TableCellStyled>{isReturn ? 'Amount Refunded' : 'Sales Amount' }</TableCellStyled>
              <TableCellStyled>Change Due</TableCellStyled>
              <TableCellStyled>VAT%</TableCellStyled>
              <TableCellStyled>Discount %</TableCellStyled>
              <TableCellStyled>Discount Total</TableCellStyled>
            </TableRowStyled>
          </TableHeadStyled>
          <TableBody>
            <TableRow>
              <TableCellBodyStyled>
                {transactionDate ? moment(dateCreated).format('lll') : 'N/A'}
              </TableCellBodyStyled>
              <TableCellBodyStyled>{name}</TableCellBodyStyled>
              <TableCellBodyStyled>N/A</TableCellBodyStyled>
              <TableCellBodyStyled>{receiptNumber}</TableCellBodyStyled>
              <TableCellBodyStyled>{username}</TableCellBodyStyled>
              <TableCellBodyStyled>{paymentMethod}</TableCellBodyStyled>
              <TableCellBodyStyled>
                {customer ? customer.name : 'N/A'}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {customer ? customer.loyaltyPoint : 'N/A'}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {currencyFormatter(aggregateTotal)}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                { currencyFormatter(cash - cashUsedInPayment || changeDue) }
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {`${vat_percent}%`}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {`${getDiscountPercentage(total, discount).toFixed(0)}%`}
              </TableCellBodyStyled>
              <TableCellBodyStyled>{discount}</TableCellBodyStyled>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxStyled>
  );
};

SalesDetails.propTypes = {
  row: PropTypes.isRequired,
  isReturn: PropTypes.string,
};
SalesDetails.defaultProps = {
  isReturn: null,
};

export default SalesDetails;
