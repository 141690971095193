import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Grid, Checkbox, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMutation, useQuery } from '@apollo/client';
import ReturnAffiliateRow from './returnAffiliateRow';
import TablePagination from '../../shared/tablePagination';
import {
  TableGrid, TableColumnHeaders, ShowingTextGrid,
  FooterGrid, TableColumnHeaderTitle, SortCart, SortImage, PaddingBottom, DivFullWidth
} from './dashboard.styles';
import { useStateValue } from '../../../providers/stateProvider';
import ascImg from '../../../assets/images/Sort Ascending Icon.png';
import descImg from '../../../assets/images/Sort Descending Icon.png';
import Loader from '../../shared/loader';
import { SALE_QUERY } from '../../../queries/sales';
import { DialogActionButtonText, DialogCancelButton, DialogOkButton } from '../../cart/cart.styles';
import { DELETE_SALE_MUTATION } from '../../../mutations/sales';

const DashboardInfo = ({
  searchText, filterOption, refreshCount, handleRefreshCount
}) => {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  // const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    data: {},
    saleToBeDeleted: null,
  };
  const [{
    user: { businessId, allowedPermissionsMap: { userPermissions } },
    business: {
      businessUser: businessUserId, dashboardState: {
        active,
        startDate,
        endDate,
        range
      }
    },
  }, dispatch] = Object.values(useStateValue());
  const [state, setState] = useState(initialState);
  const [allData, setAllData] = useState({});
  const [sortCart, setSortCart] = useState(false);
  const [asc, setAsc] = useState(false);
  const [desc, setDesc] = useState(false);
  const [deleteSaleMutation] = useMutation(DELETE_SALE_MUTATION);

  const { saleToBeDeleted } = state;

  const sortByCart = (direction) => {
    if (direction === 'asc') {
      setDesc(false);
      setAsc(!asc);
      // eslint-disable-next-line no-use-before-define
      data.sales.sort((a, b) => ((a.total > b.total) ? 1 : -1));
    } else if (direction === 'desc') {
      setDesc(!desc);
      setAsc(false);
      // eslint-disable-next-line no-use-before-define
      data.sales.sort((a, b) => ((a.total < b.total) ? 1 : -1));
    }
    // eslint-disable-next-line no-use-before-define
    setAllData(data);
  };
  const variables = {
    search: searchText,
    pageCount,
    pageNumber,
    dateFrom: startDate,
    dateTo: endDate,
    refreshCount
  };
  if (businessId) {
    variables.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  if (filterOption) {
    let option = false;
    if (filterOption === 'With returns') {
      option = true;
    }
    variables.filterOption = option;
  }
  const { data, loading, error } = useQuery(SALE_QUERY, {
    variables,
    fetchPolicy: 'network-only'
  });
  const deleteSale = () => {
    deleteSaleMutation({
      variables: {
        receiptNumber: saleToBeDeleted?.receiptNumber,
      }
    })
      .then(({ resp }) => {
        handleRefreshCount();
        toast.success(`${saleToBeDeleted?.receiptNumber} successfully deleted`);
        setState({ saleToBeDeleted: null });
      })
      .catch((err) => {
        toast.error(err?.message);
        setState({ saleToBeDeleted: null });
      });
  };
  const [paginateData, setPaginateData] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    const prodIds = [];
    if (data && data.sales) {
      setInProgress(true);
      setAllData(data);
      // eslint-disable-next-line no-unused-expressions
      data?.sales.map((item) => prodIds.push(item?.id));
      setPaginateData(prodIds);
    }
  }, [data]);

  if (paginateData.length && inProgress) {
    localStorage.setItem('__PAGINATION__', JSON.stringify(paginateData));
    setInProgress(false);
  }

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;
  const {
    sales = [], salesTotalNumber = 0
  } = allData;

  // const showing = paginationShowing(sales, pageCount);
  const handleDeleteSale = (sale) => {
    setState({ ...state, saleToBeDeleted: sale });
  };

  return (
    <>
      <Grid container style={{ overflowX: 'auto' }}>
        <TableGrid item container>
          <TableColumnHeaders style={{ padding: 0 }}>
            <TableColumnHeaderTitle style={{ width: '210px' }}>
              Date Created
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '200px' }}>
              Outlet ID
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '110px' }}>
              Reg. ID
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '180px' }}>
              Receipt #
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '180px' }}>
              Sold By
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '180px' }}>
              Customer
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '130px' }}>
              Order Cost
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ cursor: 'pointer', width: '150px' }} onClick={() => setSortCart(!sortCart)}>
              Sale Amount
              <ArrowDropDownIcon style={{ fontSize: '1.1rem' }} />
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '150px' }}>
              Gross Profit
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '150px' }}>
              Gross Margin
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '150px' }}>
              Discount Value
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '150px' }}>
              Excess Change
            </TableColumnHeaderTitle>
            <TableColumnHeaderTitle style={{ width: '200px' }}>
              Payment Method
            </TableColumnHeaderTitle>
            {sortCart
              ? (
                <SortCart>
                  <PaddingBottom>Sort</PaddingBottom>
                  <SortImage src={ascImg} />
                  Ascending
                  <Checkbox
                    checked={asc}
                    onChange={() => sortByCart('asc')}
                    disabled={loading}
                    style={{ position: 'absolute', left: '265px' }}
                    name="asc"
                    color="primary"
                  />
                  <div style={{ padding: '3%' }} />
                  <SortImage src={descImg} />
                  Descending
                  <Checkbox
                    checked={desc}
                    onChange={() => sortByCart('desc')}
                    disabled={loading}
                    style={{ position: 'absolute', left: '265px' }}
                    name="desc"
                    color="primary"
                  />
                </SortCart>
              ) : ''}
            {userPermissions?.includes('CAN_DELETE_SALE') && <TableColumnHeaderTitle style={{ width: '40px' }} />}
          </TableColumnHeaders>

          {sales.map((sale) => (
            <ReturnAffiliateRow
              key={sale.id}
              sale={sale}
              handleDeleteSale={handleDeleteSale}
            />
          ))}
        </TableGrid>

        <DivFullWidth>
          {salesTotalNumber > 0 && (
            <TablePagination
              total={salesTotalNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </DivFullWidth>
      </Grid>
      <Dialog
        open={!!saleToBeDeleted}
        keepMounted
        onClose={() => ({})}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Delete
          {` ${saleToBeDeleted?.receiptNumber}` }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this sale ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton onClick={() => setState({ ...state, saleToBeDeleted: null })}>
            <DialogActionButtonText>Cancel</DialogActionButtonText>
          </DialogCancelButton>
          <DialogOkButton onClick={deleteSale}>
            <DialogActionButtonText>OK</DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

DashboardInfo.propTypes = {
  handleRefreshCount: PropTypes.instanceOf(Object),
  searchText: PropTypes.string,
  refreshCount: PropTypes.number,
  filterOption: PropTypes.bool
};
DashboardInfo.defaultProps = {
  searchText: '',
  handleRefreshCount: () => ({}),
  refreshCount: 0,
  filterOption: false
};
export default DashboardInfo;
