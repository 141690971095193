import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
  TitleGridContainer,
  TitleTextGridContainer,
  PrimaryTitle,
  SecondaryTitle
} from './titleGrid.styles';
import CreateSubBusinessDialog from './subBusinessDialog/CreateSubBusinessDialog';

export function TitleGrid() {
  const [createSubBusinessDialog, setCreateSubBusinessDialog] = useState(false);
  const handleUserDialog = () => {
    setCreateSubBusinessDialog(!createSubBusinessDialog);
  };
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item md={6}>
        <PrimaryTitle>Sub-businesses</PrimaryTitle>
        <SecondaryTitle>Add, view and edit outlet(s)</SecondaryTitle>
      </TitleTextGridContainer>
      <Grid container md={6} spacing={2} justifyContent="flex-end">
        <Button
          onClick={handleUserDialog}
          variant="contained"
          style={{ margin: '0 1rem' }}
        >
          Add Sub-business
        </Button>
      </Grid>

      <CreateSubBusinessDialog
        open={createSubBusinessDialog}
        close={handleUserDialog}
      />
    </TitleGridContainer>
  );
}

export default TitleGrid;
