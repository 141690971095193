import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BodyCell, MainTableRow, OrderListButton } from './returnRow.styles';

const ReturnRow = ({ row }) => {
  const {
    id, dateSent, orderListProducts, mpAccount
  } = row;
  return (
    <MainTableRow>
      <BodyCell>{dateSent ? moment(dateSent).format('DD MMM YYYY h:mm a') : 'N/A'}</BodyCell>
      <BodyCell>{id}</BodyCell>
      <BodyCell>{orderListProducts.length}</BodyCell>
      <BodyCell>{mpAccount ? mpAccount?.name : 'N/A'}</BodyCell>
      <BodyCell>
        <OrderListButton>View products</OrderListButton>
      </BodyCell>
    </MainTableRow>
  );
};
ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  // sn: PropTypes.number.isRequired
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
