import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import currencyFormatter from '../../shared/currencyFormatter';
import InBatch from '../../../assets/images/Batch Added Successfully.png';
import SingleBatchDialog from './singleBatchDialog';
import AllBatchDialog from './allBatchDialog';
import { useStateValue } from '../../../providers/stateProvider';
import { isERP } from '../../../utils/utils';
import ReceiveMOActionTypes from '../../../providers/reducers/receiveManualOrders/receiveManaulOrderTypes';
import {
  ProductDetailImage, PackSizeText, MainTableRow, ProductDetailSavingsText, TickImg,
  BodyCell, BrandNameText, EditButton, PartialReceived
} from './returnRow.styles';

const ReturnRow = ({
  cartItem, addToBatch, deleteBatchHandler, status,
}) => {
  const {
    opSetId, name: brandName, orderStatus, quantity, quantityRecieved,
    image, unitCost, packSize, marketRrp, newPrice, partiallyFulfilled
  } = cartItem || {};
  const [{ receiveManualOrder: { orderList } }, dispatch] = Object.values(useStateValue());

  const { id: orderId } = useParams();

  const [receivedQty, setReceivedQty] = useState(0);
  const [modal, setModal] = useState(false);
  const [allBatchModal, setAllBatchModal] = useState();
  const [batchAdded, setBatchAdded] = useState(false);
  const [singleBatchDetails, setSingleBatchDetails] = useState(null);

  const handleOpenCreateBatch = (edit = false) => {
    setModal(!modal);
    if (!edit) setSingleBatchDetails(null);
  };
  const allBatchModalHandler = () => setAllBatchModal(!allBatchModal);

  const singleOrder = orderList?.find((item) => item.order_id === orderId);
  const singleBatchItem = singleOrder?.listBatches?.filter((item) => item.orderProductId === opSetId);
  const qtyReceivedArray = singleBatchItem?.map(({ quantityReceived }) => Number(quantityReceived));
  const qtyReceived = qtyReceivedArray?.reduce((a, b) => a + b, 0);

  const validateQuantityReceived = (batch) => {
    const { batchNo, quantityReceived: batchQR } = batch;
    const othersBatches = singleBatchItem?.filter((item) => item?.batchNo !== batchNo);
    const extractQty = othersBatches?.map(({ quantityReceived }) => Number(quantityReceived)).reduce((a, b) => a + b, 0);
    if ((extractQty + Number(batchQR)) <= quantity) {
      return true;
    }
    toast.error('Oops! Can not received more than the quantity ordered.');
    return false;
  };

  const updateBatchHandler = (batch) => {
    if (!validateQuantityReceived(batch)) return;
    dispatch({
      type: ReceiveMOActionTypes.UPDATE_LIST,
      payload: { orderId, batch }
    });
  };

  const submitBatchHandler = (batch, addMore) => {
    addToBatch(batch);
    if (!addMore) setModal(false);
    setReceivedQty(receivedQty + Number(batch.quantityReceived));
  };

  const storagePrice = newPrice > 0 ? newPrice : unitCost;
  let price = 0;
  if (orderStatus === 'MANUAL_ITEM_RECONCILED' || orderStatus === 'AUTOMATIC_ITEM_RECONCILED') {
    price = Number(storagePrice) * quantityRecieved;
  } else {
    price = Number(storagePrice) * (Number(qtyReceived) ?? Number(receivedQty));
  }

  const getPrice = () => currencyFormatter(price);

  return (
    <>
      <MainTableRow container item isReconciled={orderStatus === 'MANUAL_ITEM_RECONCILED' || orderStatus === 'AUTOMATIC_ITEM_RECONCILED'}>
        <BodyCell>
          <Grid container item>
            <Grid container item xs={6} alignItems="center">
              { batchAdded
                ? <TickImg src={InBatch} alt="add to batch" />
                : <TickImg src={InBatch} style={{ opacity: 0 }} alt="..." />}
              <ProductDetailImage src={image} alt="" />
            </Grid>
            <Grid container item xs={6} direction="column" justifyContent="center">
              {(partiallyFulfilled === 'YES') && <PartialReceived>Partially Received</PartialReceived>}
              <BrandNameText>{brandName}</BrandNameText>
              <PackSizeText>{`Pack Size: ${packSize}`}</PackSizeText>
            </Grid>
          </Grid>
        </BodyCell>
        <BodyCell>{Number(quantity)}</BodyCell>
        <BodyCell>{ orderStatus && (orderStatus === 'MANUAL_ITEM_RECONCILED' || orderStatus === 'AUTOMATIC_ITEM_RECONCILED') ? quantityRecieved : (qtyReceived ?? receivedQty)}</BodyCell>
        <BodyCell>
          {`₦ ${currencyFormatter(storagePrice)}`}
          {!isERP() && (
            <ProductDetailSavingsText>
              Savings: ₦&nbsp;
              {(marketRrp - unitCost) < 0
                ? currencyFormatter(0)
                : currencyFormatter((Number(marketRrp) || Number(unitCost)) - Number(unitCost))}
            </ProductDetailSavingsText>
          )}
        </BodyCell>
        <BodyCell>{`₦ ${getPrice()}`}</BodyCell>
        <BodyCell>
          {(status === 'MANUAL_SUPPLIER_ORDER_RECEIVED' || status === 'AUTOMATIC_SUPPLIER_ORDER_RECEIVED') ? (
            singleBatchItem.length ? (
              <EditButton onClick={() => setAllBatchModal(true)}>+</EditButton>
            ) : (
              <EditButton disabled={(orderStatus === 'MANUAL_ITEM_RECONCILED' || status === 'AUTOMATIC_ITEM_RECONCILED')} onClick={handleOpenCreateBatch}>+</EditButton>
            )
          ) : ''}
        </BodyCell>
      </MainTableRow>
      <AllBatchDialog
        modalStatus={allBatchModal}
        modalHandler={allBatchModalHandler}
        product={cartItem}
        singleBatchItem={singleBatchItem}
        deleteBatchHandler={deleteBatchHandler}
        handleOpenCreateBatch={handleOpenCreateBatch}
        setSingleBatchDetails={setSingleBatchDetails}
      />

      <SingleBatchDialog
        modalStatus={modal}
        modalHandler={handleOpenCreateBatch}
        product={cartItem}
        singleBatchDetails={singleBatchDetails}
        setSingleBatchDetails={setSingleBatchDetails}
        submitBatchHandler={submitBatchHandler}
        updateBatchHandler={updateBatchHandler}
        singleBatchItem={singleBatchItem}
        singleOrder={singleOrder}
      />
    </>
  );
};

ReturnRow.propTypes = {
  cartItem: PropTypes.instanceOf(Object).isRequired,
  status: PropTypes.string,
  addToBatch: PropTypes.func,
  deleteBatchHandler: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  status: '',
  addToBatch: () => {},
};

export default ReturnRow;
