import {
  Dialog, Grid, Typography, CircularProgress,
  Checkbox,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: 100%;
    border-radius: 12px;
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: .5rem 1.2rem;
    width: 100%;

    &:first-child, :last-child {
      padding: 1.5rem 1.2rem;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 20px;
  margin-bottom: 4px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 12px;
  color: #858383;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const ComplianceContainer = styled('div')`
  margin-top: 1rem;
  padding: 1rem;
  background: #F1F8FF;
  border-radius: 12px;
`;

export const ComplianceTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #235A91;
`;

export const ComplianceDescription = styled(Typography)`
  color: #606060;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const CheckboxStyled = styled(Checkbox)`
  color: #78AADA;
  margin-top: -15px;
  margin-left: -10px;
`;
