import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import currencyFormatter from '../../../utils/currencyFormatter';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';

const ReturnRow = ({ row }) => {
  const navigate = useNavigate();
  const {
    id, orderId, lastUpdated, amountOfProducts, totalOrderValue, mpAccount
  } = row;
  const { name } = mpAccount;
  const renderDate = () => moment(lastUpdated).format('DD MMM YYYY h:mm A');
  const handleRoute = () => navigate(`/inbound-details-info/${id}/${orderId}`);
  return (
    <TableColumnRows onClick={handleRoute}>
      <TableColumnRowText style={{ cursor: 'pointer', width: '200px' }}>{renderDate()}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '170px' }}>{orderId}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '180px' }}>{amountOfProducts}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '220px' }}>{currencyFormatter(totalOrderValue)}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '250px' }}>{name}</TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
