import * as React from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 6px',
    backgroundColor: '#EE4223',
    color: '#fff'
  }
}));

export default function SideBadge({ value }) {
  return (
    <IconButton>
      <StyledBadge badgeContent={value} />
    </IconButton>
  );
}
SideBadge.propTypes = {
  value: PropTypes.number.isRequired,
};
