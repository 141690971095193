import {
  DialogContent,
  Button,
  Typography,
  Checkbox,
  Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 607px;
  height: auto;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
`;

export const TitleStyled = styled('span')`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #303030;
`;

export const DescStyled = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 164.2%;
  color: #606060;
  padding: 1.3rem 0 .8rem 0;
`;

export const ButtonDarkStyled = styled(Button)`
  width: 95%;
  height: 48px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 12px;
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 178px;
  height: 48px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #303030 !important;
  font-weight: 700;
  font-size: 12px;
`;

export const FooterStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 1.5rem 0;
`;

export const ComplianceContainer = styled('div')`
  margin-top: 1rem;
  padding: 1rem;
  background: #F1F8FF;
  border-radius: 12px;
`;

export const ComplianceTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #235A91;
`;

export const ComplianceDescription = styled(Typography)`
  color: #606060;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const CheckboxStyled = styled(Checkbox)`
  color: #78AADA;
  margin-top: -15px;
  margin-left: -10px;
`;
