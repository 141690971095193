import {
  Grid, Typography, IconButton, TextField, Paper,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// the width in pixels for sm breakpoint.
const sm = '960px';
export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const TextWrapper = styled(Grid)`
  margin-left: 1rem;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  color: #424242;

  @media(max-width: 991px) {
    display: none;
  }
`;

export const HeaderIcon = styled('img')`
  width: 40px;

  @media(min-width: 992px) {
    width: 18px;
  }
`;

export const StatisticsGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 35px;
  transition: 500ms;
  flex-wrap: nowrap;
  width: calc(100vw - 500px);
  align-items: center;
  @media(max-width: ${sm}) {
    flex-wrap: wrap ;
    justify-content: space-around
  }
`;

export const StatisticsGridContainerWrapper = styled(Grid)`
  overflow: hidden;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
`;

export const OrderSubHeader = styled(Grid)`
  margin-bottom: 2rem;
  justify-content: space-between;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 30px 0px;
  align-items: center;

   @media(min-width: 992px) {
    padding: 20px 0px;
  }
`;

export const ProductItemsTotal = styled(Typography)`
  font-size: 30px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const ProductItemsContainer = styled(Grid)`
  align-items: center;

  @media(max-width: ${sm}) {

  }
`;

export const SearchContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 2rem;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };

  @media(min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 10px;

  & .MuiOutlinedInput-input {
    padding: 25px;
  };
  & .MuiOutlinedInput-root {
    font-size: 2.2rem;
    border-radius: 10px;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };

  @media(min-width: 992px) {
    & .MuiOutlinedInput-input {
      padding: 15px 14px;
    };

    & .MuiOutlinedInput-root {
    font-size: 1rem;
    border-radius: 10px;
  };
  }
`;

export const SearchButton = styled(IconButton)`
  padding: 14px 20px;
  background-color: #235A91;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #D2C647;
  }

  &:disabled {
    color: #424242;
    background-color: #FCED50;
  }

  @media(min-width: 992px) {
    padding: 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 64px;

  @media(min-width: 992px) {
    width: 30px;
  }
`;

export const ViewCartButton = styled(IconButton)`
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 25px;
  background-color: #FCED50;
  color: #424242;
  border-radius: 10px;
  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
  }
`;

export const ViewCartButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const PayButton = styled(IconButton)`
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 25px;
  color: #424242;
  border: 2px solid #424242;
  border-radius: 10px;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    margin-right: 25px;
  }
`;

export const PayButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const PayIcon = styled('img')`
    width: 30px;
`;

export const PaymentCountText = styled(Typography)`
  font-size: 16px;
  color: #4D4F5C;
  margin: 14px 0;
  @media(min-width: 992px) {
    font-size: 14px;
  }
`;
export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 2.1875rem;
  }
`;

export const CustomPaper = styled(Paper)`
  height: 85px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  display: flex;
  gap: 10px;
  padding: 1.2rem;
`;

export const SortBox = styled(Box)`
  width: 5.5rem;
  height: 2.938rem;
  background: #FFFFFF;
  border: 1px solid #E7E8E9;
  border-radius: 8px;
  display: flex;
  padding: .8rem;
`;

export const SortImg = styled('img')`
  height: 20px;
  width: 20px;
`;

export const SortText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  color: #16181B;
  opacity: .5;
`;

export const ContentContainer = styled(Paper)`
  border-radius: 12px;
  margin-top: 2rem;
  padding: 3rem 7rem;
`;

export const ContentDiv = styled(Grid)`
  width: 520px;
  height: 100px;
  background: #FFFFFF;
  border: 0.602329px solid #E7E8E9;
  border-radius: 14.4559px;
  padding: .5rem 1rem;
`;

export const ContentImg = styled('img')`
  width: 70px;
  height: 70px;
`;

export const ContentTextName = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  color: #606060;
  margin-bottom: .5rem;
  margin-left: 12px;
`;

export const ContentText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  color: #606060;
  margin-bottom: .5rem;
  margin-left: 12px;
`;
