import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Paper, Table, TableBody } from '@mui/material';
import TablePagination from '../../shared/tablePagination';
import { UPDATED_UNRECONCILED_MANUAL_SUPPLIER_ORDERS } from '../../../queries/orders';
import { TableContainerWrapper, DivFullWidth } from './orders.styles';
import { TableComponent, TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';
import ReturnRow from './returnRow';
import OrdersFilter from './ordersFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';

const headers = [
  { name: 'Order Date', width: '190px' }, { name: 'Supplier', width: '210px' }, { name: 'PO Number', width: '180px' }, { name: 'Parent ID', width: '170px' },
  { name: 'Amount of Products', width: '250px' }, { name: 'Total Order Value', width: '200px' }, { name: 'Order Status', width: '200px' }
];

const Orders = () => {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const initialState = {
    currentPage: 1,
    currentPageCount: 20,
    poNumber: '',
    parentId: '',
    orderType: '',
    supplier: '',
    dateFrom: '',
    dateTo: '',
    filters: {}
  };

  const [state, setState] = useState(initialState);

  const {
    poNumber, orderType, parentId, supplier, dateFrom, dateTo, filters
  } = state;

  const handleSetFilter = () => {
    setState({
      ...state,
      filters: {
        poNumber,
        parentId,
        orderType,
        dateFrom,
        dateTo,
        supplier
      }
    });
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    dateFrom,
    dateTo,
    ...filters,
  };

  const {
    loading, error, data
  } = useQuery(UPDATED_UNRECONCILED_MANUAL_SUPPLIER_ORDERS, {
    fetchPolicy: 'no-cache',
    variables
  });

  if (error) return <div>{error.message}</div>;

  return (
    <>
      <OrdersFilter
        state={state}
        handleFilterChange={handleFilterChange}
        handleSetFilter={handleSetFilter}
        setState={setState}
      />
      <TableContainerWrapper component={Paper}>
        {loading ? <ProductsTableLoader /> : (
          <TableComponent>
            <Table>
              <TableColumnHeaders>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {data?.unreconciledManualSupplierOrders.map((order) => (
                  <ReturnRow
                    key={order.id}
                    row={order}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
        <DivFullWidth>
          <TablePagination
            total={data?.supplierOrderTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </DivFullWidth>
      </TableContainerWrapper>
    </>
  );
};

export default Orders;
