import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, DialogTitle, Slide, Button
} from '@mui/material';
// import CustomButton from '../../customComponents/customButton';
import {
  CDialog, ButtonGrid, CloseIcon,
  CTitle, CSubTitle, FieldCheckbox, SelectNameTypo, CheckboxImage, CMenuItem
} from './changeDialog.styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ChangeDialog = ({
  popup, setPopup, saveChange, setSaveChange, handleConfirmation, customerId, loading
}) => {
  const choices = [
    { field: 'Save in customer wallet', value: 'WALLET' },
    { field: 'Customer got change', value: 'RETURNED' },
    { field: 'Save as excess', value: 'EXCESS' },
  ];

  return (
    <CDialog
      open={popup}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setPopup(false)}
      maxWidth="sm"
      data-testid="change-dialog"
    >
      <ButtonGrid item container xs={12} justifyContent="flex-end">
        <CloseIcon onClick={() => setPopup(false)} />
      </ButtonGrid>
      <DialogTitle style={{ padding: '16px 40px' }}>
        <CTitle>
          What would you like to do with your change?
        </CTitle>
        <CSubTitle>
          You currently have excess value in the cash box
        </CSubTitle>
      </DialogTitle>
      <DialogContent style={{ padding: '5px 40px' }}>
        {choices.map(({ field, value }) => (
          <CMenuItem
            key={field}
            value={field}
            onClick={() => setSaveChange(value)}
            disabled={value === 'WALLET' && !customerId}
            checked={value === saveChange}
          >
            <FieldCheckbox
              checked={value === saveChange}
              sx={{ color: '#78AADA' }}
            />
            <SelectNameTypo>{field}</SelectNameTypo>
          </CMenuItem>
        ))}
      </DialogContent>

      <DialogActions style={{ padding: '16px 40px 35px' }}>
        <Button
          variant="contained"
          disabled={loading}
          onClick={handleConfirmation}
        >
          Proceed
        </Button>
      </DialogActions>
    </CDialog>
  );
};

ChangeDialog.propTypes = {
  popup: PropTypes.bool,
  setPopup: PropTypes.func.isRequired,
  saveChange: PropTypes.string,
  setSaveChange: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  customerId: PropTypes.number,
  loading: PropTypes.bool,
};

ChangeDialog.defaultProps = {
  popup: false,
  saveChange: '',
  customerId: '',
  loading: false,
};

export default ChangeDialog;
