import React, { useState, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Grid, Fade, Button } from '@mui/material';
import toast from 'react-hot-toast';
import { FIND_CUSTOMERS_QUERY } from '../../../queries/customers';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  CustomerDialog, DialogWrapper, DialogHeader, DialogBody, DialogActions,
  CloseIcon, TableHeader, HeaderGrid, MainTableRow, BodyCell, Header, CenterTextGrid
} from './selectCustomerDialog.styles';
import { getCustomerObject } from '../../../utils/utils';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const initialState = {
  searchText: '',
  searchValue: '',
  currentPageCount: 25,
  currentPage: 1,
};

const SelectCustomerDialog = ({ open, close, addCustomer }) => {
  const [state, setState] = useState(initialState);

  const {
    searchText, searchValue, currentPageCount, currentPage
  } = state;

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, searchValue: searchText });
    } else if (!searchText) {
      setState({ ...state, searchValue: '' });
    }
  }, [searchText]);

  const { data, refetch } = useQuery(FIND_CUSTOMERS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      search: searchValue,
      pageCount: currentPageCount,
      pageNumber: currentPage
    },
  });

  const [, dispatch] = Object.values(useStateValue());

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleAddCustomer = () => {
    close();
    addCustomer(true);
  };

  const handleClick = (customer) => {
    setState({ ...state, searchText: '', });
    dispatch({
      type: SaleActionTypes.UPDATE_CUSTOMER,
      payload: {
        ...customer,
        refetchCustomer: refetch
      }
    });
    toast.success('Customer added successfully');
    close();
  };

  const filteredData = data?.findCustomer.map((customer) => getCustomerObject(customer)) || [];

  const headers = ['S/N', 'Customer Name', 'Phone Number', 'Email', 'Loyalty Point'];

  return (
    <CustomerDialog
      data-testid="customerDialog"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      maxWidth="sm"
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <Grid item container xs={6}>
            <Header variant="h5">Select Customer</Header>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={close} />
          </Grid>
          <Grid item container style={{ marginTop: '1.5rem' }}>
            <CustomSearchField
              name="search"
              value={searchText}
              fullWidth
              placeholder="Search"
              handleChange={(e) => handleSearch(e?.target?.value)}
            />
          </Grid>
        </DialogHeader>
        <DialogBody item container>
          <HeaderGrid item container>
            {headers.map((header) => <TableHeader>{header}</TableHeader>)}
          </HeaderGrid>
          {filteredData.length ? filteredData.map((customer, indx) => (
            <MainTableRow item container key={customer.id} data-testid="addRowCustomers" onClick={() => handleClick(customer)}>
              <BodyCell>{indx + 1}</BodyCell>
              <BodyCell>{customer.name}</BodyCell>
              <BodyCell>{customer.phoneNo || 'N/A'}</BodyCell>
              <BodyCell>{customer.email || 'N/A'}</BodyCell>
              <BodyCell>{customer.loyaltyPoint || '0.00'}</BodyCell>
            </MainTableRow>
          )) : (
            <CenterTextGrid item md={6} xs={6}>No record found</CenterTextGrid>
          )}
        </DialogBody>
        <DialogActions item container>
          <Button
            variant="outlined"
            style={{ width: '11rem', marginRight: '1.2rem' }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAddCustomer}
            style={{ width: '11rem' }}
            data-testid="addNewCustomer"
          >
            Add New Customer
          </Button>
        </DialogActions>
      </DialogWrapper>
    </CustomerDialog>
  );
};

SelectCustomerDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  addCustomer: PropTypes.func.isRequired,
};

SelectCustomerDialog.defaultProps = {
  open: false
};

export default SelectCustomerDialog;
