import { Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentContainer = styled(Typography)`
    padding: 1rem 8rem 5rem 8rem;
    background: #fff;
    @media (max-width: 991px) {
        padding: 2rem 3rem 5rem 3rem;

    }
`;

export const ProductTypo = styled(Typography)`
    font-size: 1.5rem;
    font-weight: 600;

    @media (max-width: 991px) {
        font-size: 3.6rem;
        font-weight: 650;
    }
`;

export const ProductNameTypo = styled(Typography)`
    font-size: 1rem;
    font-weight: 600;
    margin: 1rem 0;

    @media (max-width: 991px) {
        font-size: 2.8rem;
        font-weight: 550;
        margin: 2rem 0;
    }
`;

export const MenuButton = styled(Button)`
    border: none;
    @media (max-width: 991px) {
      padding: 10px 0px;
      padding-bottom: 20px;
      .MuiButton-iconSizeMedium > *:first-child {
        font-size: 60px;
      }
  }
`;

export const BackTypo = styled(Typography)`
    font-weight: 600;

    @media (max-width: 991px) {
        font-size: 2.3rem;
        font-weight: 500;
    }
`;
