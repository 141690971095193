import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Button, Grid } from '@mui/material';
import CustomInputBase from '../../customComponents/customInputBase';
import CustomSideSelectInputBase from '../../customComponents/customSideSelectInputBase';
import {
  CDialog, GridWrapper, FooterStyled, DialogTitleText, DialogTitleSubText, CloseIcon
} from './addPrescriptionDialog.styles';
import NotificationDialog from '../../shared/notificationDialog';
import { useStateValue } from '../../../providers/stateProvider';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';

const initialState = {
  dateIssued: moment().format('YYYY-MM-DD'),
  numTablets: '',
  packetOption: '',
  dosePerDay: '',
  usageDays: '',
};
const AddPrescriptionDialog = ({
  open, onClose, cartItem, pharmacist
}) => {
  const [state, setState] = useState(initialState);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [, dispatch] = Object.values(useStateValue());

  const {
    productId, brandName, quantity: itemQty, prescription
  } = cartItem;
  const {
    dateIssued, numTablets, packetOption, dosePerDay, usageDays
  } = state;

  useEffect(() => {
    if (prescription) {
      const {
        dateIssued: dIssued, unit, numTablets: nTablets, dosePerDay: dPerDay,
      } = prescription;
      setState((_state) => ({
        ..._state,
        dateIssued: moment(dIssued).format('YYYY-MM-DD'),
        packetOption: unit === 'SACHET' ? 'Per Sachet' : 'Per Pack',
        numTablets: nTablets,
        dosePerDay: dPerDay,
      }));
    }
  }, [prescription]);

  useEffect(() => {
    if (numTablets && dosePerDay) {
      const days = (numTablets * itemQty) / dosePerDay;
      setState((_state) => ({
        ..._state, usageDays: days
      }));
    }
  }, [numTablets, dosePerDay, itemQty]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({ ..._state, [name]: value }));
  };

  const handleSelectChange = (val) => {
    setState((_state) => ({ ..._state, packetOption: val }));
  };

  const handleClose = () => {
    onClose();
    setState(initialState);
  };

  const validateFields = () => {
    if (!dateIssued || !numTablets || !dosePerDay || !usageDays) {
      return true;
    }
    return false;
  };

  const handleDialogClose = (val) => {
    setSuccessOpenDialog(val);
    setIsEditing(true);
  };

  const handleAddPrescription = () => {
    if (validateFields()) return toast.error('Kindly validate all input fields');
    dispatch({
      type: SaleActionTypes.ADD_CART_ITEM_PRESCRIPTION,
      payload: {
        productId,
        prescription: {
          dateIssued: new Date(dateIssued),
          unit: packetOption === 'Per Sachet' ? 'SACHET' : 'PACK',
          numTablets,
          dosePerDay,
          usageDays,
        }
      }
    });
    handleClose();
    return setSuccessOpenDialog(true);
  };

  const fields = [
    {
      type: 'text', name: 'productName', label: 'Product Name', 'data-testid': 'productName', required: false, value: brandName,
      disabled: true
    },
    {
      type: 'text', name: 'pharmacist', label: 'Pharmacist Name', 'data-testid': 'pharmacistname', required: false, value: pharmacist,
      disabled: true
    },
    {
      type: 'date', name: 'dateIssued', label: 'Date Issued', 'data-testid': 'dateIssued', required: true, value: dateIssued,
      placeholder: 'Enter Date Received', onChange: handleChange
    },
    {
      type: 'number', name: 'numTablets', label: 'No of Tablets Per Satchet or Pack', 'data-testid': 'numTablets', required: true,
      value: numTablets, placeholder: 'Enter No of Tablets', onChange: handleChange, onSelectChange: handleSelectChange,
      options: ['Per Sachet', 'Per Pack']
    },
    {
      type: 'number', name: 'dosePerDay', label: 'Enter Usage expected Dose Per Day', 'data-testid': 'dosePerDay', required: true,
      value: dosePerDay, placeholder: 'Enter Usage expected Dose', onChange: handleChange
    },
    {
      type: 'number', name: 'usageDays', label: 'No of Usage Days', 'data-testid': 'usageDays', required: true,
      value: usageDays, placeholder: '0', disabled: true
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, disabled, placeholder, onChange, onSelectChange,
      value, type, options
    } = field;
    if (['numTablets'].includes(fieldName)) {
      return (
        <CustomSideSelectInputBase
          label={label}
          value={value}
          size="small"
          adonmentOptions={options}
          type={type || 'text'}
          error={error || false}
          helperText={error && helperText}
          required={required}
          disabled={disabled}
          name={fieldName}
          onChange={onChange}
          onSelectChange={onSelectChange}
          placeholder={placeholder}
          cSize="lg"
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        required={required}
        disabled={disabled}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        cSize="lg"
      />
    );
  };

  return (
    <>
      <CDialog
        open={open}
        keepMounted
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        data-testid="prescriptions-dialog"
      >
        <GridWrapper container>
          <Grid container>
            <Grid item container xs={6}>
              <DialogTitleText>
                {prescription ? 'Edit Prescription' : 'Add Prescription'}
              </DialogTitleText>
              <DialogTitleSubText>
                {prescription
                  ? 'Edit Product prescription to the fields in the form'
                  : 'Add Product prescription to the fields in the form'}
              </DialogTitleSubText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon color="primary" onClick={handleClose} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item key={field?.name} xl={6}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
          <FooterStyled>
            <Button
              variant="outlined"
              style={{ width: '11rem', marginRight: '1.2rem' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ width: '11rem' }}
              onClick={handleAddPrescription}
              data-testid="batch_button"
            >
              {prescription ? 'Edit' : 'Add'}
            </Button>
          </FooterStyled>
        </GridWrapper>
      </CDialog>
      <NotificationDialog
        openDialog={successOpenDialog}
        setOpenDialog={handleDialogClose}
        title={`Prescription ${isEditing ? 'Edited' : 'Added'}`}
        desc={`You have successfully ${isEditing ? 'edited' : 'added'} a prescription to a customer product.`}
      />
    </>
  );
};

AddPrescriptionDialog.propTypes = {
  cartItem: PropTypes.shape(Object).isRequired,
  pharmacist: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

AddPrescriptionDialog.defaultProps = {
  pharmacist: '',
  onClose: () => { },
};

export default AddPrescriptionDialog;
