import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Grow, Paper, Popper, MenuList
} from '@mui/material';
import { Dots } from '../../../../assets/svgs';
import { ButtonEllipsis, MenuItemStyled } from './actionPopper.styles';
import { useStateValue } from '../../../../providers/stateProvider';

export default function ActionPopper({
  row, handleAction
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { status } = row;
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonEllipsis
        onClick={handleToggle}
        ref={anchorRef}
        style={{ cursor: 'pointer' }}
      >
        <Dots fontSize="large" />
      </ButtonEllipsis>
      <Popper
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled
                    onClick={(e) => {
                      handleAction(e, 'view users', row);
                      handleToggle();
                    }}
                    style={{ pointerEvents: !userPermissions?.includes('role_permissions_view_user') ? 'none' : '' }}
                  >
                    View User
                  </MenuItemStyled>
                  <MenuItemStyled
                    onClick={(e) => {
                      handleAction(e, 'edit role', row);
                      handleToggle();
                    }}
                    style={{ pointerEvents: !userPermissions?.includes('role_permissions_edit_permissions') ? 'none' : '' }}
                  >
                    Edit Role
                  </MenuItemStyled>
                  <MenuItemStyled
                    onClick={(e) => {
                      handleAction(e, 'delete role', row);
                      handleToggle();
                    }}
                    style={{ pointerEvents: !userPermissions?.includes('role_permissions_delete_role') ? 'none' : '' }}
                  >
                    Delete Role
                  </MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
};
