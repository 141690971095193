import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FiltersGrid = styled(Grid)`
  margin: 2rem 0 1.5rem;
  align-items: center;
`;

export const ButtonsGrid = styled(Grid)`
  padding: 0 1.8rem 1.8rem;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0 1.8rem 1.8rem;
  }
`;
