import {
  Typography, Checkbox, InputBase, FormControl,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormField = styled(FormControl)`
  width: 100%;
  padding-top: 10px;
  & .MuiFilledInput-root {
    background-color:  #FFF;
    border-radius: 15px;
    border: 1px solid #C0C0C1;
  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  & .MuiSelect-filled.MuiSelect-filled {
    text-align: initial;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }
  & .MuiFormLabel-root.Mui-disabled {
    color: #000000;
  }
  & .MuiFilledInput-inputMarginDense {
    padding: 15px 10px;
  }
  @media (min-width: 992px) {
    & .MuiFilledInput-root {
      height: ${(props) => (props.cart && '60px')};
      border-radius: ${(props) => (props.cart && '10px')};
    }
  }
  @media (max-width: 991px) {
    & .MuiFilledInput-root {
      height: ${(props) => (props.cart && '100px')};
      margin-bottom: ${(props) => (props.cart && '1.5rem')};
      border-radius: ${(props) => (props.cart && '25px')};
    }
    & .MuiFilledInput-input {
      font-size: 2rem;
    }
  }
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CreditNameTypo = styled(Typography)`
  font-size: .7rem;
`;

export const CreditDaysInput = styled(InputBase)`
  font-size: .7rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;
