import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  Description,
  RowContainer,
  RowTitle,
} from './mapProductDialog.styles';
import currencyFormatter from '../../shared/currencyFormatter';

const MapProductReturnRow = ({ row, mapHandler }) => {
  const {
    brand_name, mkt_price, quantity, pack_size
  } = row;

  return (
    <RowContainer onClick={() => mapHandler(row)}>
      <Box>
        <RowTitle>{brand_name}</RowTitle>
        <Description>{`Pack Size: ${pack_size || 0} Quantity Available: ${quantity || 0}`}</Description>
      </Box>
      <Box>
        <RowTitle>
          ₦
          {currencyFormatter(mkt_price)}
        </RowTitle>
      </Box>
    </RowContainer>
  );
};

MapProductReturnRow.propTypes = {
  row: PropTypes.shape(Object),
  mapHandler: PropTypes.func.isRequired,
};

MapProductReturnRow.defaultProps = {
  row: {}
};

export default MapProductReturnRow;
