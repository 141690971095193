import React from 'react';
import PropTypes from 'prop-types';
import CustomSearchField from '../../../shared/CustomSearchField';
import {
  StatusesGrid,
} from './filter.styles';

const Filter = ({
  state, handleSearch
}) => {
  const { searchText } = state ?? '';

  return (
    <StatusesGrid container item>
      <CustomSearchField
        name="search"
        value={searchText}
        fullWidth
        placeholder="Search"
        handleChange={(e) => handleSearch(e?.target?.value)}
      />
    </StatusesGrid>
  );
};

Filter.propTypes = {
  state: PropTypes.shape(Object),
  handleSearch: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  state: {}
};

export default Filter;
