import React, { useState } from 'react';
import {
  Button, Slide, Grid, Typography, TableContainer
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { PrintOutlined, EmailRounded, Close } from '@mui/icons-material';
import ReceiptPrint from './receiptPrint';
import { SEND_RECEIPT_VIA_MAIL } from '../../../mutations/sales';
import currencyFormatter from '../../../utils/currencyFormatter';
import { JSONParse } from '../../../utils/json';
import {
  CardTitle, DialogContentStyled, Hbg, HbgSmall, CustomerDetails, CustomerDetail, DefaultTable, DefaultTrHead,
  DefaultTd, DefaultTr, DefaultTdBody, Separator, CustomerDetailLt, ReceiptFooter, SmallText, SmallerText,
  ButtonDarkStyled, ButtonWhiteStyled, ButtonGrayStyled, CDialog, CardSubTitle, CardSubTitle2
} from './receiptDialog.styles';

import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ReceiptDialog = ({
  openDialog, cart, subTotal, discount, total, saleReturnData, onClose, paymentMethod,
  saveChange, changeDue, customer
}) => {
  const [printing, setPrinting] = useState(false);
  const [{
    sale: { percDiscount, vatTotal, vatPercentage },
  }] = Object.values(useStateValue());

  const { businessUser, receiptNumber } = saleReturnData || {};
  const date = new Date().toLocaleDateString();
  const { business, user } = businessUser || {};
  const { contacts = '', name: businessName } = business || {};
  const {
    address_line_1: addressLineOne,
    city,
    region,
    mobile_number: mobileNumber
  } = JSONParse(contacts) || {};
  const { last_name: lastName, first_name: firstName } = JSONParse(user?.profile) || {};

  const getTime = () => {
    const dt = new Date();
    let hours = dt.getHours();
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = dt.getMinutes();
    return `${hours}:${minutes} ${AmOrPm}`;
  };

  const handlePrint = () => {
    setPrinting(true);
  };

  const getDiscount = (item) => {
    const {
      discount: prodDiscount, isPercentage, resolvedPriceInUseValue, itemPrice, quantity
    } = item;
    const price = resolvedPriceInUseValue || itemPrice;
    const tot = Math.ceil(price * quantity);
    if (isPercentage) return Math.ceil(tot * (prodDiscount / 100));
    return prodDiscount || 0;
  };

  const getItemPrice = (item) => {
    const {
      resolvedPriceInUseValue, itemPrice, quantity, UOM, product: { noOfUom }
    } = item;
    const price = resolvedPriceInUseValue || itemPrice;
    let tot = 0;
    if (!UOM || UOM === 'Pack') {
      tot = Math.ceil(price * quantity);
    } else {
      tot = Math.ceil((quantity / noOfUom) * price);
    }
    const disc = getDiscount(item);
    return tot - disc;
  };

  const [sendReceiptViaMail] = useMutation(SEND_RECEIPT_VIA_MAIL);
  const handleEmail = () => {
    sendReceiptViaMail({
      variables: {
        receiptNumber
      }
    }).then(() => {
      toast.success('Receipt sent to customer mail successfully');
    }).catch((err) => {
      toast.error(err.message);
    });
  };

  return (
    <CDialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Grid item container style={{ marginTop: '1rem' }} justifyContent="space-between">
          <Grid item container justifyContent="flex-end">
            <Close fontSize="small" style={{ cursor: 'pointer' }} onClick={onClose} />
          </Grid>
          <Grid item container justifyContent="center">
            <CardTitle>{businessName}</CardTitle>
          </Grid>
          <Grid item container justifyContent="center">
            <CardSubTitle>
              {addressLineOne && `${addressLineOne}`}
              {city && `, ${city}`}
              {region && `, ${region}`}
            </CardSubTitle>
          </Grid>
          <Grid item container justifyContent="center">
            <CardSubTitle2>
              {mobileNumber && `${mobileNumber}`}
            </CardSubTitle2>
          </Grid>
        </Grid>
        {printing ? (
          <ReceiptPrint
            receiptNumber={receiptNumber}
            closePrint={() => setPrinting(false)}
            type="sales"
          />
        ) : (
          <>
            <Hbg>
              <Typography style={{ fontWeight: 'bold' }}>
                {`Receipt #${receiptNumber}`}
              </Typography>
              <HbgSmall>
                {`${date} ${getTime()}`}
              </HbgSmall>
            </Hbg>
            <CustomerDetails>
              <CustomerDetail>Customer Name:</CustomerDetail>
              <CustomerDetail>{(customer && customer?.name) || 'N/A'}</CustomerDetail>
            </CustomerDetails>
            <TableContainer style={{ marginTop: '1rem' }}>
              <DefaultTable>
                <DefaultTrHead>
                  <DefaultTd>Product Name</DefaultTd>
                  <DefaultTd>UOM</DefaultTd>
                  <DefaultTd>Qty</DefaultTd>
                  <DefaultTd>Price</DefaultTd>
                </DefaultTrHead>
                {cart.map((item, idx) => (item.missedSale ? null : (
                  <DefaultTr key={idx}>
                    <DefaultTdBody>{item.brandName}</DefaultTdBody>
                    <DefaultTdBody>{item?.product?.selectedUnitOfMeasurement}</DefaultTdBody>
                    <DefaultTdBody>
                      {`1 x ${item.quantity}`}
                    </DefaultTdBody>
                    <DefaultTdBody style={{ whiteSpace: 'nowrap' }}>
                      {currencyFormatter(getItemPrice(item))}
                    </DefaultTdBody>
                  </DefaultTr>
                )))}
                <DefaultTr>
                  <DefaultTdBody>Sub Total</DefaultTdBody>
                  <DefaultTdBody />
                  <DefaultTdBody style={{ whiteSpace: 'nowrap' }}>
                    {currencyFormatter(subTotal)}
                  </DefaultTdBody>
                </DefaultTr>
                <DefaultTr>
                  <DefaultTdBody>
                    {`${percDiscount}% Discount`}
                  </DefaultTdBody>
                  <DefaultTdBody />
                  <DefaultTdBody>
                    {currencyFormatter(discount)}
                  </DefaultTdBody>
                </DefaultTr>
              </DefaultTable>
              <Separator />
              <CustomerDetails>
                <CustomerDetail>Total Before VAT</CustomerDetail>
                <CustomerDetail>
                  {currencyFormatter(total)}
                </CustomerDetail>
              </CustomerDetails>
              <CustomerDetails>
                <CustomerDetail>
                  VAT (
                  {`${vatPercentage}%`}
                  )
                </CustomerDetail>
                <CustomerDetail>
                  {currencyFormatter(vatTotal)}
                </CustomerDetail>
              </CustomerDetails>
              {saveChange !== 'EXCESS' ? (
                <CustomerDetails>
                  <CustomerDetailLt>Change Due</CustomerDetailLt>
                  <CustomerDetailLt>{ currencyFormatter(changeDue.toFixed(2)) }</CustomerDetailLt>
                </CustomerDetails>
              ) : ''}
              <CustomerDetails>
                <CustomerDetail>Payment Method</CustomerDetail>
              </CustomerDetails>
              {Object.entries(paymentMethod).map(([key, value]) => (value > 0 ? (
                <CustomerDetails key={key}>
                  <CustomerDetailLt>{key}</CustomerDetailLt>
                  <CustomerDetail>{currencyFormatter(value)}</CustomerDetail>
                </CustomerDetails>
              ) : ''))}
              <Separator />
              <ReceiptFooter>
                <SmallText>Your Cashier Today</SmallText>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', padding: '.5rem 0' }}
                >
                  {firstName ? `${firstName} ${lastName}` : user?.username}
                </Typography>
                <SmallerText variant="caption">
                  Thank You For Shopping With Us. Please Come Again.
                </SmallerText>
              </ReceiptFooter>
            </TableContainer>
          </>
        )}
        <Separator />

        <Grid
          item
          container
          style={{ gap: '.3rem', padding: '1rem 2rem 2rem' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Button variant="outlined" onClick={onClose}>
            Close Transaction
          </Button>
          <Button variant="contained" style={{ background: '#78AADA' }} onClick={handleEmail}>
            <EmailRounded
              fontSize="small"
              style={{ marginRight: '.5rem' }}
            />
            <span>Send To Email</span>
          </Button>
          <Button variant="contained" onClick={handlePrint}>
            <PrintOutlined
              fontSize="small"
              style={{ marginRight: '.5rem' }}
            />
            <span>Print Receipt</span>
          </Button>
        </Grid>
      </DialogContentStyled>
    </CDialog>
  );
};

ReceiptDialog.propTypes = {
  openDialog: PropTypes.bool,
  cart: PropTypes.shape(Array),
  subTotal: PropTypes.number,
  discount: PropTypes.number,
  total: PropTypes.number,
  saleReturnData: PropTypes.shape(Object),
  onClose: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  saveChange: PropTypes.string,
  changeDue: PropTypes.number,
  customer: PropTypes.string,
};

ReceiptDialog.defaultProps = {
  openDialog: false,
  cart: [],
  subTotal: 0,
  discount: 0,
  total: 0,
  saleReturnData: {},
  paymentMethod: '',
  saveChange: '',
  changeDue: 0,
  customer: '',
};

export default ReceiptDialog;
