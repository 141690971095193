import React, { useState } from 'react';
import {
  Grid,
  Box,
  Table,
  TableContainer,
  TableRow,
  TableBody,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import {
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableCellBStyled,
  BoxStyled
} from '../returnSettingsTable.styles';
import { GET_RETURN_PRODUCTS } from '../../../queries/return';
import TablePagination from '../../shared/tablePagination';
import TableLoader from '../../shared/tableLoader';
import CustomSearchField from '../../shared/CustomSearchField';
import { numberFormater } from '../../shared/utils';

const ReturnTable = () => {
  const navigate = useNavigate();
  // const [searchVal, setSearchVal] = useState('');
  const [search, setSearch] = useState('');

  // Begin pagination props states
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  // End pagination props states

  const { loading, error, data } = useQuery(GET_RETURN_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search,
      pageCount,
      pageNumber
    }
  });

  const totalRecord = data?.returnsTotalNumber || 0;

  const handleReturnRoute = (id) => {
    navigate(`/sale-details/${id}/return`);
  };

  return (
    <Grid container style={{ marginTop: '1rem' }}>
      <Grid item xl={12} md={12} sm={12}>
        <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px">
          <Grid item>
            <Box fontWeight="fontWeightBold" fontSize={20} color="inherit">
              Returns
            </Box>
          </Grid>
          <div style={{ margin: '1rem 0' }}>
            <CustomSearchField
              name="search"
              value={search}
              fullWidth
              placeholder="Search"
              handleChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {loading || error ? (
            <TableLoader rows={5} />
          ) : (
            <Grid xl={12} md={12} sm={12}>
              <TableContainer>
                <Table>
                  <TableHeadStyled>
                    <TableRow>
                      <TableCellStyled>S/N</TableCellStyled>
                      <TableCellStyled>Date Returned</TableCellStyled>
                      <TableCellStyled>Receipt #</TableCellStyled>
                      <TableCellStyled>Amount Refunded</TableCellStyled>
                      <TableCellStyled>Returned By</TableCellStyled>
                    </TableRow>
                  </TableHeadStyled>
                  <TableBody>
                    {data?.returns.length > 0 ? (
                      data?.returns?.map((item, index) => (
                        <TableRowStyled
                          cond={index % 2 !== 0}
                          key={item?.id}
                          onClick={() => handleReturnRoute(item?.id)}
                        >
                          <TableCellBStyled>
                            { index + 1 }
                          </TableCellBStyled>
                          <TableCellBStyled>
                            {dayjs(item?.dateCreated).format('DD MMM YY h:mm A')}
                          </TableCellBStyled>
                          <TableCellBStyled>{item?.receiptNumber}</TableCellBStyled>
                          <TableCellBStyled>
                            NGN
                            {' '}
                            { numberFormater(item?.amountRefunded)}
                          </TableCellBStyled>
                          <TableCellBStyled>{item?.customer?.name || 'N/A' }</TableCellBStyled>
                        </TableRowStyled>
                      ))
                    ) : (
                      <TableRow>
                        <TableCellBStyled
                          style={{ textAlign: 'center' }}
                          colSpan={5}
                        >
                          No record found
                        </TableCellBStyled>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: '1rem' }} />
              {data?.returns.length > 0 && (
                <TablePagination
                  total={totalRecord}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              )}
            </Grid>
          )}
        </BoxStyled>
      </Grid>
    </Grid>
  );
};
export default ReturnTable;
