import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Box, MenuItem, Button
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  CDialog, DialogTitleText, CircularProgressLoader, TextFieldBox, CTextField,
  GridWrapper, CloseIcon, FieldHeader, DialogTitleSubText
} from './addLocationDialog.styles';
import { SAVE_DELIVERY_LOCATION } from '../../../../mutations/outlets';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AddLocationDialog = ({
  open, close, currentState, changeDelivery
}) => {
  const initialState = {
    name: '', primaryContactName: '', primaryEmailAddress: '', mobileNumber: '',
    addressLine1: '', city: '', country: ''
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [createDeliveryLocation] = useMutation(SAVE_DELIVERY_LOCATION);

  const {
    name, primaryContactName, primaryEmailAddress, mobileNumber, addressLine1, city, country
  } = state;

  const rightField = [
    {
      name: 'Branch Name', type: 'name',
      label: "Enter Customer's Name"
    },
    {
      name: 'Email Address', type: 'primaryEmailAddress',
      label: 'Enter Email Address'
    },
    {
      name: 'Address Line 1', type: 'addressLine1',
      label: 'Enter Address Line 1'
    },
  ];

  const leftField = [
    { name: 'Primary Contact Name', type: 'primaryContactName', label: "Enter Customer's Name" },
    { name: 'Phone Number', type: 'mobileNumber', label: 'Enter Phone Number' },
    { name: 'City', type: 'city', label: 'Enter City' },
  ];

  const selectField = { name: 'Country', type: 'country', label: 'Select Country' };

  const handleChange = (event) => {
    const { name: fieldName, value } = event.target;
    setState({ ...state, [fieldName]: value });
  };

  const formValidation = () => {
    const emailValidation = ((((((((((((((((((((((((((((((((((((((((((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)))))))))))))))))))))))))))))))))))))))))).test(primaryEmailAddress);
    if (!name || !primaryContactName || !addressLine1 || !city || !country) {
      toast.error('Kindly validate all fields');
      return false;
    }
    if (!primaryEmailAddress || !emailValidation) {
      toast.error('Enter a valid Email Address');
      return false;
    }
    if (!mobileNumber || mobileNumber.length < 11 || mobileNumber.length > 11) {
      toast.error('Enter a valid Phone Number');
      return false;
    }
    return true;
  };

  const handleCloseDialog = () => {
    setState(initialState);
    close();
  };

  const handleSave = () => {
    if (!formValidation()) return;
    setLoading(true);
    const variables = {
      name, primaryContactName, primaryEmailAddress, mobileNumber, addressLine1, city, country
    };
    const ogaOrderingForAffiliate = localStorage.getItem('oga_ordering_for_affiliate');
    const business = ogaOrderingForAffiliate ? JSON.parse(ogaOrderingForAffiliate).id : localStorage.getItem('ogarx_business_id');
    createDeliveryLocation({
      variables: {
        business: +business, ...variables
      }
    })
      .then(() => {
        const { deliveryLocations } = currentState;
        const newDeliveryLocation = [
          ...deliveryLocations,
          {
            name, contacts: {
              city, country, mobile_number: mobileNumber, address_line_1: addressLine1,
              primary_contact_name: primaryContactName, primary_email_address: primaryEmailAddress
            }
          }
        ];
        handleCloseDialog();
        toast.success('Location added successfully');
        changeDelivery(newDeliveryLocation);
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const textField = (field, select) => {
    const {
      label, shrink, type
    } = field;
    const _value = state[type];

    const status = [
      { name: 'Nigeria', id: 'Nigeria' },
    ];

    const returnType = (filedType) => {
      if (select) return;
      switch (filedType) {
        case 'mobileNumber':
          return 'number';
        default:
          return 'text';
      }
    };

    return (
      <TextFieldBox>
        <CTextField
          variant="outlined"
          label={label}
          value={_value}
          size="large"
          name={type}
          onChange={handleChange}
          type={returnType(type)}
          select={select}
          fullWidth
          required
          InputProps={{
            style: { height: '4rem', fontSize: '.9rem', borderRadius: '.6rem' }
          }}
          InputLabelProps={{
            style: { fontSize: '.8rem', textAlign: 'center', color: '#858383' },
            shrink
          }}
          SelectProps={select && {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  borderRadius: '10px'
                }
              }
            }
          }}
        >
          {select && status.map(({ name: statusName, id }) => (
            <MenuItem
              key={id}
              value={id}
              style={{
                fontSize: '1rem', marginTop: '15px',
                padding: '1rem 2.5rem .5rem 2.5rem'
              }}
            >
              {statusName}
            </MenuItem>
          ))}
        </CTextField>
      </TextFieldBox>
    );
  };

  const select = true;

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={8}>
            <DialogTitleText container item xs={12}>
              Add Delivery Location
            </DialogTitleText>
            <DialogTitleSubText>
              Add your delivery information to the fields in the form
            </DialogTitleSubText>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={6}>
            {rightField.map((fields) => (
              <Box key={fields}>
                <FieldHeader>
                  {fields.name}
                </FieldHeader>
                {textField(fields)}
              </Box>
            ))}
            <FieldHeader>
              Country
            </FieldHeader>
            {textField(selectField, select)}
          </Grid>
          <Grid item md={6}>
            {leftField.map((fields) => (
              <Box key={fields}>
                <FieldHeader>
                  {fields.name}
                </FieldHeader>
                {textField(fields)}
              </Box>
            ))}
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : 'Submit' }
          </Button>
        </DialogActions>
      </GridWrapper>
    </CDialog>
  );
};

AddLocationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  changeDelivery: PropTypes.func.isRequired,
  currentState: PropTypes.instanceOf(Object).isRequired
};

export default AddLocationDialog;
