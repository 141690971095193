import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  TableColumnRowText, TableColumnRows
} from './returnRow.styles';
import { Product } from '../../../../providers/reducers/product/classes/Product';

const ReturnRow = ({ row, rowIndx }) => {
  const {
    date, orderCost, expiryDate, quantity, updatedField, updatedBy, updateDate, previousValue, currentValue
  } = row;
  const isLarge = useMediaQuery('(min-width:992px)');

  const _date = moment(date).format('D MMM YYYY h:mm a');
  const _expiryDate = moment(expiryDate).format('D MMM YYYY');
  const lastUpdated = moment(updateDate).format('DD MMM YYYY h:mm a');

  const cells = [
    { name: _date, width: '230px' }, { name: `₦ ${currencyFormatter(orderCost)}`, width: '150px' }, { name: _expiryDate, width: '150px' },
    { name: quantity, width: '100px' }, { name: updatedField, width: '175px' }, { name: updatedBy, width: '150px' },
    { name: lastUpdated, width: '230px' }, { name: previousValue, width: '230px' }, { name: currentValue, width: '220px' }
  ];

  const returnCell = (value) => value || '-';

  const renderBodyCells = () => cells.map(({ name, width }) => (
    <TableColumnRowText style={{ width }} key={name}>{isLarge ? returnCell(name) : ''}</TableColumnRowText>
  ));

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '70px' }}>{rowIndx + 1}</TableColumnRowText>
      {renderBodyCells()}
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  rowIndx: PropTypes.number.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
