import {
  Grid, Typography, IconButton, TableContainer, Table,
  TableHead, TableRow, TableCell, Checkbox, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// the width in pixels for sm breakpoint.
const sm = '960px';
export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding:10px 0px;
`;

export const OrderGridWrapper = styled(Grid)`
  padding:0px 10px;
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

export const TextWrapper = styled(Grid)`
  margin-left: 0.5rem;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  color: #424242;

  @media(max-width: 991px) {
    display: none;
  }
`;

export const HeaderIcon = styled('img')`
  width: 40px;

  @media(min-width: 992px) {
    width: 18px;
  }
`;

export const StatisticsGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 35px;
  transition: 500ms;
  flex-wrap: nowrap;
  width: calc(100vw - 500px);
  align-items: center;
  @media(max-width: ${sm}) {
    flex-wrap: wrap ;
    justify-content: space-around
  }
`;

export const StatisticsGridContainerWrapper = styled(Grid)`
  overflow: hidden;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  font-size: 2rem;
  margin: 10px 0px;
  &.MuiIconButton-root {
    padding: 0px;
  }
  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const ViewCartButton = styled(IconButton)`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 40px;
  background-color: #FCED50;
  color: #424242;
  border-radius: 10px;
  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 27px 50px;
  }
`;

export const ViewCartButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;
  margin-left: 5px;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-left: 20px;
  }
`;

export const HeaderButtons = styled(Grid)`
  display: flex;
`;

export const PayButton = styled(IconButton)`
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  color: #ffffff;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #303030;

  &:hover {
    cursor: pointer;
    background-color: #303030;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #ffffff;
    background-color: #303030;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 24px 35px;
    margin-right: 25px;
  }
`;

export const PayButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const PayIcon = styled('img')`
    width: 30px;
`;

export const TableGridContainer = styled(Grid)`

  padding: 30px;
`;

export const TableContainerWrapper = styled(TableContainer)`
  box-shadow: 0px 3px 4px #42424240;
  border-radius: 7px;
  border: 1px solid #79797940;
  overflow-y: hidden;
`;

export const TableOptionsTab = styled(Grid)`
  justify-content: space-between;
  padding: 0 10px;
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const TableComponent = styled(Table)`
  min-width: 650px;
`;

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const PaymentTableTitle = styled(Typography)`
  font-size: 27px;
  color: #424242;
  font-weight: 600;
  padding: 10px 0px;

  @media(min-width: 992px) {
    font-size: 20px;
    padding: 10px 0px;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export const PaymentHistoryHeaders = styled('div')`
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  padding: 28px 35px;
  margin-top: 30px;
`;

export const PaymentColumnHeaderTitle = styled(TableCell)`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  white-space: nowrap;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const PaymentRowText = styled(TableCell)`
  color: #424242;
  font-size: 20px;
  margin: 0;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    width: 340px;
    line-height: 23px;
    font-size: 18px;
  }
`;

export const NoPaymentText = styled(Typography)`
  color: #424242;
  font-size: 20px;
  margin: 20px;

  @media (min-width: 992px) {
    line-height: 23px;
    font-size: 18px;
  }
`;

export const PaginationGrid = styled(Grid)`
  margin-bottom: 30px;

  @media(max-width: ${sm}) {
    margin-bottom: 10px;
  }
`;

export const PaymentCountText = styled(Typography)`
  font-size: 16px;
  color: #4D4F5C;
  margin: 14px 0;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 2.1875rem;
  }
`;

export const InvoiceButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #FCED50;
  border-radius: 10px;

  &:hover {
    background-color: #D2C647;
  }

  @media (min-width: 992px) {
    line-height: 23px;
    font-size: 18px;
  }

`;

// export const FooterGrid = styled(Grid)`
//   align-items: center;
//   font-size: 0.6rem;
//   margin-bottom: 15px;
// `;

export const CheckBoxButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.5;
  color: #303030;
  border: none;
  outline: none;
  padding-right: 20px;
  background: none;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const DispatchContainer = styled(Grid)`
  flex-direction: column;
  padding: 20px 40px 30px;
  background-color:  #F4F5F7;
  min-height: 100vh;
`;

export const BackButton = styled(Button)`
  border: none;
  padding: 30px 30px 0 40px;
  font-size: 1rem;
`;

export const ArrowImg = styled('img')`
  width: 10px;
`;
