import {
  Grid, Typography, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
  background-color:  #F4F5F7;
  @media(max-width: 991px) {
    padding: 0px 30px;
    background-color: #fff;
  }
`;

export const OrderWrapper = styled(Grid)`
  background: #FFFFFF;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  padding: 30px 50px;
  @media(max-width: 991px) {
    background: none;
  }
`;

export const TitleGridContainer = styled(Grid)`
  padding: 1rem 3rem 1rem .7rem;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled(Grid)`
`;

export const ButtonsPaper = styled(Paper)`
  border-bottom: 3px solid #EEEEEE;
  width: 100%;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;
