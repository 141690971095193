import { gql } from '@apollo/client';

export const CREATE_THERAPEUTIC_TYPE = gql`
mutation createTherapeuticType($therapeuticType: String!) {
    createTherapeuticType(therapeuticType: $therapeuticType) {
      therapeuticType {
        id
        therapeuticType
        therapeuticTypeProductCount
      }
    }
  }`;

export const UPDATE_THERAPEUTIC_TYPE = gql`
mutation updateTherapeuticType($therapeuticType: String, $therapeuticTypeId: String!) {
    updateTherapeuticType(therapeuticType: $therapeuticType, therapeuticTypeId: $therapeuticTypeId ) {
      therapeuticType {
        id
        therapeuticType
        therapeuticTypeProductCount
      }
    }
  }`;

export const DELETE_THERAPEUTIC_TYPE = gql`
  mutation deleteTherapeuticType($therapeuticTypeId:String!){
    deleteTherapeuticType(therapeuticTypeId:$therapeuticTypeId){
      message
    }
  }
`;

export const CREATE_SUB_THERAPEUTIC_TYPE = gql`
  mutation createSubTherapeuticType($parentId: Int, $therapeuticType: String!){
  createTherapeuticType(parentId:$parentId, therapeuticType:$therapeuticType){
    therapeuticType{
      id
      therapeuticType
    }
    message
  }
}
`;
