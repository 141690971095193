import { IconButton, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderDialog = styled(Dialog)`
  z-index: 13000000 !important;

  & .MuiDialog-paperWidthMd {
    height: 90%;
  }
`;

export const ButtonIcon = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 2rem;
`;

export const AddImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: 1rem;
  }
`;
