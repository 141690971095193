import {
  TableRow, TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }

  &:nth-of-type(even) {
    background-color: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.3rem;
  padding: 15px;
  line-height: 2.5rem;

  @media(min-width: 992px) {
    font-size: 1rem;
    padding: 10px;

    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 10%;
    }
  }
`;
