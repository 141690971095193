import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import Moment from 'moment';
import FormTemplate from '../shared/fieldTemplate/template';
import {
  CaretUpIcon, CaretDownIcon, DeleteIcon, UpdateIconWhite
} from '../../assets/svgs';
import {
  CustomInputBase, RemoveButton, HeaderText, TextBox, DateText, ContentBox,
  MobileContainer, DetailText
} from './returnRow.styles';
import { JSONParse } from '../../utils/json';

const ReturnRow = ({
  row, updateHandler, reportLoading
}) => {
  const [counter, setCounter] = useState(0);
  const [expDate, setExpDate] = useState(0);
  const [trigger, setTrigger] = useState(false);

  const {
    batch: { batchNo, expiryDate },
    product: { brandName, meta },
    id,
    quantityInStock
  } = row;

  const { pack_size: packSize } = JSONParse(meta) || {};

  useEffect(() => {
    if (expiryDate) setExpDate(Moment(expiryDate).format('YYYY-MM-DD'));
    else setExpDate(0);
    setCounter(quantityInStock);
  }, [row, expiryDate, quantityInStock]);

  const handleDateChange = (e) => {
    const newExpDate = e?.target?.value;
    if (newExpDate !== 0) setTrigger(true);
    setExpDate(newExpDate);
  };

  const handleValChange = (value) => {
    if (expDate !== 0) setTrigger(true);
    if (value < 0) setCounter(0);
    else setCounter(value);
  };

  const handleActionButtons = (value) => {
    if (expDate !== 0) setTrigger(true);
    if (value === -1 && counter <= 0) setCounter(0);
    else setCounter(counter + value);
  };

  const triggerUpdateHandler = (type) => {
    setTrigger(false);
    updateHandler(row, counter, type, expDate);
  };

  const expireDateField = {
    type: 'date', name: 'expiryDate', value: expDate, onChange: handleDateChange,
    style: { width: '95%', fontSize: '2rem', height: '5rem' }
  };

  const maxLength = 27;

  return (
    <MobileContainer
      key={id}
      data-testid="routeBtn"
      style={{ marginTop: '2rem' }}
    >
      <DateText container>
        <DetailText>
          {batchNo || 'N/A'}
        </DetailText>
      </DateText>
      <hr />
      <ContentBox>
        <TextBox container>
          <HeaderText xs={4}>Product Name</HeaderText>
          <DetailText xs={8}>
            {
              brandName.length > maxLength
                ? `${brandName.substring(0, maxLength)}...`
                : brandName
            }
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={4}>Pack Size</HeaderText>
          <DetailText xs={8}>
            {packSize}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={4}>Expiry Date</HeaderText>
          <DetailText xs={8}>
            <FormTemplate fields={expireDateField} />
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={4}>QTY Counted</HeaderText>
          <DetailText xs={8}>
            <CustomInputBase
              type="number"
              placeholder="0"
              min={1}
              size="medium"
              value={counter || ''}
              onChange={(e) => handleValChange(Number(e.target.value))}
              required
              style={{
                height: '5rem', fontSize: '2rem', width: '95%', border: '1px solid #78AADA'
              }}
              endAdornment={(
                <InputAdornment position="end">
                  <Grid item container direction="column">
                    <CaretUpIcon
                      style={{ width: '1.5rem', height: '1.5rem', cursor: 'pointer' }}
                      onClick={() => handleActionButtons(1)}
                    />
                    <CaretDownIcon
                      style={{ width: '1.5rem', height: '1.5rem', cursor: 'pointer' }}
                      onClick={() => handleActionButtons(-1)}
                    />
                  </Grid>
                </InputAdornment>
          )}
            />
          </DetailText>
        </TextBox>

        <RemoveButton
          disabled={reportLoading || counter === 0 || !trigger}
          variant="contained"
          style={{
            marginLeft: '1rem', fontSize: '2rem', paddingTop: '1rem', marginBottom: '2.5rem', width: '45%',
            height: '7rem', borderRadius: '20px', color: 'white'
          }}
          onClick={() => triggerUpdateHandler('UPDATE')}
        >
          <UpdateIconWhite style={{ fontSize: '4rem', marginRight: '4px' }} />
          Update
        </RemoveButton>
        <RemoveButton
          variant="outlined"
          style={{
            marginLeft: '2rem', fontSize: '2rem', paddingTop: '1rem', marginBottom: '2.5rem', width: '45%',
            height: '7rem', borderRadius: '20px',
          }}
          onClick={() => triggerUpdateHandler('REMOVE')}
        >
          <DeleteIcon style={{ fontSize: '4rem', marginRight: '4px' }} />
          Remove
        </RemoveButton>
      </ContentBox>
    </MobileContainer>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  updateHandler: PropTypes.func.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
