import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Grid, Button, CircularProgress, useMediaQuery, Box,
  Hidden
} from '@mui/material';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { KeyboardArrowDown } from '@mui/icons-material';
import CustomInputBase from '../customComponents/customInputBase';
import {
  HaveAccountTypo, FooterGrid, BillingContainer, BillingTitle, BillingContainerHeader,
  BillingDescription,
  BillingBody,
  BillingPlan, AdornmentStyled,
  RadioButton
} from './register.styles';
import { validateEmail } from './utils';
import { StackIcon } from '../../assets/svgs';
import TooltipContainer from '../shared/tooltip/tooltip';
import { SIGNUP_FLOW } from '../../mutations/business';
import { blockInvalidChar } from '../../utils/mobileCheck';

const Register = () => {
  const navigate = useNavigate();
  const initialState = {
    businessName: '',
    businessOwnerFullName: '',
    businessAddress: '',
    emailAddress: '',
    phoneNumber: '',
    businessPlan: '',
  };

  const initialErrorState = {
    businessNameError: false,
    businessOwnerFullNameError: false,
    businessAddressError: false,
    emailAddressError: false,
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [step, setStep] = useState(1);

  const isSmall = useMediaQuery('(max-width: 991px)');

  const {
    businessName, businessOwnerFullName, businessAddress, emailAddress, phoneNumber,
  } = state;
  const {
    businessNameError, businessOwnerFullNameError, businessAddressError, emailAddressError
  } = errorState;

  const validateState = (name, value) => {
    switch (name) {
      case 'businessName':
      case 'businessOwnerFullName':
      case 'businessAddress':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });
      case 'emailAddress':
        return setErrorState({
          ...errorState,
          emailAddressError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const selectPlan = (e) => {
    const { value, name } = e.target;
    setState({ ...state, [name]: value });
  };

  const proceed = () => {
    if (businessName === '') {
      toast.error('Business name is required');
    } else if (businessOwnerFullName === '') {
      toast.error('Owner\s name is required');
    } else if (businessAddress === '') {
      toast.error('Business address is required');
    } else if (validateEmail(emailAddress)) {
      toast.error('Email address is required');
    } else {
      navigate('/register?step=2');
      setStep(2);
    }
    return setErrorState({
      ...errorState,
      businessNameError: businessName === '',
      businessOwnerFullNameError: businessOwnerFullName === '',
      businessAddressError: businessAddress === '',
      emailAddressError: validateEmail(emailAddress),
    });
  };

  const phoneAdornment = (
    <AdornmentStyled>
      <span>+234</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const fields = [
    {
      name: 'businessName', label: 'Business Name', required: true, placeholder: 'Enter your business name',
      error: businessNameError, helperText: 'Business name required', secured: false, show: true
    },
    {
      name: 'businessOwnerFullName', label: 'Business Owner\'s Full Name', required: true, placeholder: 'Enter owner\'s full name',
      error: businessOwnerFullNameError, helperText: 'Business owner\'s name required', secured: false, show: true
    },
    {
      name: 'businessAddress', label: 'Business Address', required: true, placeholder: 'Enter your business address',
      error: businessAddressError, helperText: 'Business address required', secured: false, show: true
    },
    {
      name: 'emailAddress', label: 'Email Address', required: true, placeholder: 'Enter your email address',
      error: emailAddressError, helperText: 'Invalid email address', secured: false, show: true
    },
    {
      name: 'phoneNumber', label: 'Phone Number', required: false, placeholder: 'Enter your phone number',
      helperText: 'Phone number is required', secured: false, show: true, sAdornment: phoneAdornment
    },
  ];

  const billing = [
    {
      name: 'Procurement Plan', value: 'PROCUREMENT', price: 'NGN700,0000', priceText: 'monthly order placed', tooltip: true,
      tooltipDescription: 'Client has to make monthly minimum order of at least NGN700,000 per store from the <a style="color:white !important" href=\'https://marketplace.uat.ogapharmacy.com/\' target="_blank">OGApharmacy marketplace</a> which will waive the subscription fee',
      description: 'Order placed on OGA marketplace default fee of NGN  <strong> 10,000 </strong> when monthly order quota is missed',
    },
    {
      name: 'Monthly Subscription Plan', value: 'MONTHLY', price: 'NGN5,0000', priceText: 'per store / month', tooltip: false, tooltipDescription: '', description: ''
    },
    {
      name: 'Annual Subscription Plan', value: 'ANNUAL', price: 'NGN60,0000', priceText: 'per store / year', tooltip: false, tooltipDescription: '', description: ''
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, isLoading, secured, placeholder, type, readOnly, sAdornment
    } = field;
    const value = state[fieldName];
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={fieldName === 'phoneNumber' ? 'number' : 'text'}
        onKeyDown={fieldName === 'phoneNumber' && blockInvalidChar}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={isLoading}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        sAdornment={sAdornment}
        cSize="lg"
        readOnly={readOnly}
      />
    );
  };

  const [createUser, { loading }] = useMutation(SIGNUP_FLOW);
  const handleSubmit = () => {
    if (!state.businessPlan) return toast.error('Select a plan');
    createUser({
      variables: { ...state, phoneNumber: phoneNumber ? `+234${phoneNumber}` : '' },
    }).then(({ data: { signup: { message } } }) => {
      toast.success(message);
      navigate(`/email-verification?ref=${state.emailAddress}`);
    }).catch((err) => {
      navigate('/register?step=2');
      setStep(1);
      setState({ ...state, businessPlan: '' });
      toast.error(err.message);
    });
  };

  return (
    <>
      <form autoComplete="off">
        {
          step === 1 ? (
            <Grid>
              <Grid container spacing={2}>
                {fields.map((field) => (
                  <Grid style={{ marginTop: isSmall && '1.5rem' }} item key={field?.name} xs={12}>{returnTextField(field)}</Grid>
                ))}
              </Grid>
              <Grid item xs={12} style={{ margin: '2rem 0 0' }}>
                <Button
                  onClick={proceed}
                  variant="contained"
                  style={{
                    width: '100%', height: isSmall ? '5.7rem' : '', fontSize: isSmall ? '2rem' : '', borderRadius: isSmall ? '1rem' : ''
                  }}
                >
                  Proceed
                </Button>
              </Grid>
              <Hidden mdDown>
                <FooterGrid item container xs={12}>
                  <HaveAccountTypo variant="subtitle2" display="inline" style={{ color: 'black', fontWeight: isSmall ? '800' : '' }}>
                    Already have an account?
                    &nbsp;
                    <Link to="/" style={{ color: '#235A91', fontWeight: 800 }}>
                      Log In
                    </Link>
                  </HaveAccountTypo>
                </FooterGrid>
              </Hidden>
            </Grid>
          )
            : (
              <Grid container gap={1} marginTop={-3} style={{ marginTop: isSmall && '2rem', gap: isSmall && '3rem' }}>
                {
                  billing.map((item) => {
                    const {
                      name, price, priceText, description, tooltip, tooltipDescription, value
                    } = item;
                    return (
                      <BillingContainer key={name}>
                        <BillingContainerHeader>
                          <Box display="flex" alignItems="center" gap={1}>
                            <StackIcon style={{ fontSize: isSmall ? '6rem' : '40px', fill: '#D1E9FF' }} />
                            <BillingTitle>{name}</BillingTitle>
                          </Box>
                          <RadioButton onChange={selectPlan} type="radio" value={value} name="businessPlan" style={{ fontSize: '4rem' }} />
                        </BillingContainerHeader>
                        <BillingBody>
                          <Box display="flex" alignItems="center" gap={1} style={{ fontSize: isSmall && '1.8rem' }}>
                            <BillingPlan>{price}</BillingPlan>
                            {priceText}
                            {tooltip && <TooltipContainer description={tooltipDescription} />}
                          </Box>
                          <BillingDescription>
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                          </BillingDescription>
                          <BillingDescription>
                            (Plus NGN 40,000 licence fee paid one-off)
                          </BillingDescription>
                        </BillingBody>
                      </BillingContainer>
                    );
                  })
                }
                <Grid item xs={12} style={{ margin: '2rem 0 0' }}>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    style={{
                      width: '100%', height: isSmall ? '5.7rem' : '', fontSize: isSmall ? '2rem' : '', borderRadius: isSmall ? '1rem' : ''
                    }}
                  >
                    {loading ? <CircularProgress size={18} /> : 'Sign Up'}
                  </Button>
                </Grid>
                <Hidden mdDown>
                  <FooterGrid item container xs={12}>
                    <HaveAccountTypo variant="subtitle2" display="inline" style={{ color: 'black', fontWeight: isSmall ? '800' : '' }}>
                      Already have an account?
                      &nbsp;
                      <Link to="/" style={{ color: '#235A91', fontWeight: 800 }}>
                        Log In
                      </Link>
                    </HaveAccountTypo>
                    <HaveAccountTypo variant="subtitle2" display="block" style={{ color: 'black', textAlign: 'center', fontWeight: isSmall ? '800' : '' }}>
                      By selecting Sign Up, you agree to our
                      <Link to="https://docs.google.com/document/d/1_C8cNuNPW082HTbG2rTXuvGGoa29dBAgFhm6k041DWU/edit?usp=sharing" target="_blank" style={{ color: '#235A91', fontWeight: 800 }}>
                        {' '}
                        Term of Use
                        {' '}
                      </Link>
                      and acknowledge our
                      {' '}
                      <Link to="https://docs.google.com/document/d/1YdkivdJq2KApNRDNG4HqjOFZaflVpnSz9m3TFsucyvQ/edit?usp=sharing" target="_blank" style={{ color: '#235A91', fontWeight: 800 }}>
                        Privacy Statement
                      </Link>
                    </HaveAccountTypo>
                  </FooterGrid>
                </Hidden>
              </Grid>
            )
        }
      </form>

      <Hidden mdUp>
        <HaveAccountTypo
          variant="subtitle2"
          display="inline"
          style={{
            color: 'black', fontWeight: '600', textAlign: 'center', marginTop: '2rem', fontSize: '2rem'
          }}
        >
          Already have an account?
          &nbsp;
          <Link to="/" style={{ color: '#235A91', fontWeight: 800 }}>
            Login
          </Link>
        </HaveAccountTypo>
      </Hidden>
    </>

  );
};

export default Register;
