import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import Moment from 'moment';
import FormTemplate from '../shared/fieldTemplate/template';
import {
  CaretUpIcon, CaretDownIcon, DeleteIcon, UpdateIcon
} from '../../assets/svgs';
import {
  MainTableRow, BodyCell, CustomInputBase, RemoveButton
} from './returnRow.styles';
import { JSONParse } from '../../utils/json';

const ReturnRow = ({
  row, updateHandler, reportLoading
}) => {
  const [counter, setCounter] = useState(0);
  const [expDate, setExpDate] = useState(0);
  const [trigger, setTrigger] = useState(false);

  const {
    batch: { batchNo, expiryDate },
    product: { brandName, meta },
    id,
    quantityInStock
  } = row;

  const { pack_size: packSize } = JSONParse(meta) || {};

  useEffect(() => {
    if (expiryDate) setExpDate(Moment(expiryDate).format('YYYY-MM-DD'));
    else setExpDate(0);
    setCounter(quantityInStock);
  }, [row, expiryDate, quantityInStock]);

  const handleDateChange = (e) => {
    const newExpDate = e?.target?.value;
    if (newExpDate !== 0) setTrigger(true);
    setExpDate(newExpDate);
  };

  const handleValChange = (value) => {
    if (expDate !== 0) setTrigger(true);
    if (value < 0) setCounter(0);
    else setCounter(value);
  };

  const handleActionButtons = (value) => {
    if (expDate !== 0) setTrigger(true);
    if (value === -1 && counter <= 0) setCounter(0);
    else setCounter(counter + value);
  };

  const triggerUpdateHandler = (type) => {
    setTrigger(false);
    updateHandler(row, counter, type, expDate);
  };

  const expireDateField = {
    type: 'date', name: 'expiryDate', value: expDate, onChange: handleDateChange,
    style: { width: '75%', fontSize: '14px' }
  };

  return (
    <MainTableRow
      key={id}
      item
      container
    // style={{ color: (!row.quantityInStock && !counter) && '#EE0101' }}
      style={{ cursor: 'auto' }}
    >
      <BodyCell>{batchNo || 'N/A'}</BodyCell>
      <BodyCell>{brandName}</BodyCell>
      <BodyCell>{packSize}</BodyCell>
      <BodyCell>
        <FormTemplate fields={expireDateField} />
      </BodyCell>
      <BodyCell>
        <CustomInputBase
          type="number"
          placeholder="0"
          min={1}
          size="medium"
          value={counter || ''}
          onChange={(e) => handleValChange(Number(e.target.value))}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  data-testid="increment"
                  style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                  onClick={() => handleActionButtons(1)}
                />
                <CaretDownIcon
                  style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                  onClick={() => handleActionButtons(-1)}
                />
              </Grid>
            </InputAdornment>
          )}
        />
      </BodyCell>
      <BodyCell>
        <RemoveButton
          disabled={reportLoading || counter === 0 || !trigger}
          onClick={() => triggerUpdateHandler('UPDATE')}
          data-testid="update"
        >
          <UpdateIcon style={{ fontSize: '1.2rem', marginRight: '4px' }} />
          Update
        </RemoveButton>
        <RemoveButton
          style={{ marginLeft: '2px' }}
          onClick={() => triggerUpdateHandler('REMOVE')}
          data-testid="remove"
        >
          <DeleteIcon style={{ fontSize: '1.2rem', marginRight: '4px' }} />
          Remove
        </RemoveButton>
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  updateHandler: PropTypes.func.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
