import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardLoader from '../../shared/cardLoader';
import { numberFormater } from '../../shared/utils';
import {
  TypographyStyled,
  TypographyCountStyled,
  Title,
  BoxStyled
} from '../dashboardContainer.styles';

const OrderActivityCards = ({ response }) => {
  const { loading, error, data } = response;

  const activity = [
    {
      id: 0,
      title: 'Total Order Value',
      section: '(In House)',
      total: data?.supplyChainDashboard?.totalOrderValueInHouse || 0,
      link: '/inventory/orders/own_orders',
      dataTest: 'totalOrderValueIH'
    },
    {
      id: 1,
      title: 'Total Order Count',
      section: '(In House)',
      total: data?.supplyChainDashboard?.totalOrderCountInHouse || 0,
      link: '/inventory/orders/own_orders',
      dataTest: 'totalOrderCountIH'
    },
    {
      id: 2,
      title: 'Total Order Value',
      section: '(Affiliate)',
      total: data?.supplyChainDashboard?.totalOrderValueAffiliate || 0,
      link: '/inventory/orders/authorised',
      dataTest: 'totalOrderValueA'
    },
    {
      id: 3,
      title: 'Total Order Count',
      section: '(Affiliate)',
      total: data?.supplyChainDashboard?.totalOrderCountAffiliate || 0,
      link: '/inventory/orders/authorised',
      dataTest: 'totalOrderCountA'
    }
  ];

  const navigate = useNavigate();
  const onRouteHanlder = (path) => {
    navigate(path);
  };

  if (loading || error) return <CardLoader cols={4} />;
  return (
    <>
      <Title>Order Overview</Title>
      <Grid container spacing={4} style={{ marginTop: '.7rem' }}>
        {activity?.map((card) => (
          <Grid item xs={6} xl={3} key={card?.id} data-testid={card?.dataTest} onClick={() => onRouteHanlder(card?.link)}>
            <BoxStyled
              p={4}
              bgcolor="background.paper"
              borderRadius="20px"
              className="text-center"
              style={{ textAlign: 'center', cursor: 'pointer' }}
            >
              <TypographyStyled>{card?.title}</TypographyStyled>
              <TypographyStyled>{card?.section}</TypographyStyled>
              <TypographyCountStyled>
                {numberFormater(card?.total)}
              </TypographyCountStyled>
            </BoxStyled>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

OrderActivityCards.propTypes = {
  response: PropTypes.isRequired
};

export default OrderActivityCards;
