import React from 'react';
import PropTypes from 'prop-types';
import {
  GridWrapper, Image, UploadPicIcon, ChangeImgButton,
} from './initialImage.styles';

const initialImage = ({
  image, handleImageChange
}) => (
  <GridWrapper container item>
    <Image src={image} alt="image" />
    <ChangeImgButton onClick={handleImageChange}>
      <UploadPicIcon />
    </ChangeImgButton>
  </GridWrapper>
);
initialImage.propTypes = {
  image: PropTypes.string.isRequired,
  handleImageChange: PropTypes.func.isRequired,
};
export default initialImage;
