import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  CardGridContainer, CardHeader, HeaderText,
  HeaderIcon, CardContentGrid, ContentHeader,
  ContentMeta, FooterMeta
} from './therapeuticTypeCard.styles';

export default function SupplierCard({
  id, logo,
  name, creditDays,
  productCount
}) {
  const navigate = useNavigate();
  return (
    <CardGridContainer
      elevation={2}
      item
      container
      onClick={() => navigate(`/suppliers/${id}/details`)}
    >
      <CardHeader container item>
        {/* <HeaderIcon src={logo} alt="supplier logo" /> */}
        <HeaderText>
          {`su${id}`}
        </HeaderText>
      </CardHeader>

      <CardContentGrid>
        <ContentHeader>
          {name}
        </ContentHeader>

        <FooterMeta>
          <ContentMeta>
            {creditDays}
          </ContentMeta>
          <ContentMeta>
            {`${productCount} Product(s)`}
          </ContentMeta>
        </FooterMeta>

      </CardContentGrid>
    </CardGridContainer>
  );
}

SupplierCard.propTypes = {
  id: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  creditDays: PropTypes.string.isRequired,
  productCount: PropTypes.number.isRequired
};
