import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { StatusColor, StatusText } from './returnRow.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import currencyFormatter from '../../../utils/currencyFormatter';

const ReturnRow = ({
  row
}) => {
  const navigate = useNavigate();
  const {
    id, dateCreated, orderproductSet, supplier, order, status
  } = row;

  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');

  const getColorGrid = (bkg, name) => (
    <Box style={{ display: 'flex' }}>
      <StatusColor bkg={bkg} />
      <StatusText>{name}</StatusText>
    </Box>
  );

  const getStatus = () => {
    // closed-order/
    if (status === 'MANUAL_SUPPLIER_ORDER_RECONCILED') return getColorGrid('#FF0F0F', 'Closed Order');
    if (order?.metaStatus === 'ORDER_IN_DRAFT') return getColorGrid('#F29053', 'Draft');
    if (order?.metaStatus === 'ORDER_CANCELED') return getColorGrid('#EE4223', 'Cancelled');
    return getColorGrid('#00B588', 'Open orders');
  };

  const orderVolume = orderproductSet.reduce((previous, current) => previous + current.quantity, 0);
  const orderAmount = orderproductSet.reduce((previous, current) => previous + (current.quantity * current.unitCost), 0);

  const disableRoute = order?.metaStatus === 'ORDER_CANCELED';
  const handleRoute = () => {
    if (status === 'MANUAL_SUPPLIER_ORDER_RECONCILED' && !disableRoute) return navigate(`/closed-order/${id}`);
    if (order?.metaStatus === 'ORDER_IN_DRAFT' && !disableRoute) return navigate(`/update-purchase-order/${order?.id}`);
    return !disableRoute && navigate(`/receive-manual-order/${id}`);
  };

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '200px' }}>{renderDate}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{supplier?.name}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '200px' }} onClick={handleRoute}>{`#${id}`}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '320px' }} onClick={handleRoute}>{`#${order?.id}`}</TableColumnRowText>
      <TableColumnRowText style={{ width: '220px' }}>{orderVolume}</TableColumnRowText>
      <TableColumnRowText style={{ width: '220px' }}>{currencyFormatter(orderAmount)}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{getStatus()}</TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
