import { styled } from '@mui/material/styles';
import {
  TableCell,
  Typography,
  Grid,
  Paper,
  Box,
  Accordion,
  AccordionDetails
} from '@mui/material';
import CustomButton from '../../customComponents/customButton';

export const PaperExport = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px
    rgba(163, 171, 185, 0.12);
`;

export const ContentContainer = styled(Box)`
  padding-bottom: 2rem;
  border: none;
  outline: none;
`;

export const AccordionStyled = styled(Accordion)`
  border-radius: 14px;
  border: 0.602px solid #e7e8e9;
  background: #fff;
  box-shadow: none;
  padding: 1rem;
  margin-top: 1rem;
`;

export const AccordionDetailStyled = styled(AccordionDetails)`
  margin-top: -0.4rem;
`;

export const DelContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

export const SubCategoryContainer = styled(Box)`
  padding-bottom: 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
`;

export const SubCategoryHeader = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const AccordionHeader = styled(Box)`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FlexCenter = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ExpandText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #235A91;
  font-size: 14px;
`;

export const ImageContainer = styled('div')`
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  background: #ecf6ff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled('img')`
  width: 50px;
  height: 50px;
  border-radius: 100%;
`;

export const HeaderBody = styled(Box)``;

export const HeaderText = styled(Typography)`
  color: #606060;
  font-size: 16px;
`;

export const HeaderDesc = styled(Typography)`
  color: #606060;
  font-size: 14px;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;

export const FilterGrid = styled(Grid)`
  padding: 0.5rem 0 1.5rem 0;
  align-items: center;
`;

export const TableGrid = styled(Grid)`
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)``;

export const TotalRecord = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  color: #303030;
`;

export const TableColumnHeaders = styled('div')`
  display: flex;
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #f3f9ff;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const FooterWrapper = styled(Grid)`
  width: 100%;
  padding-bottom: 1.5rem;
`;

export const TCustomButton = styled(CustomButton)`
  color: #606060;
  height: 47px;
  border: 1px solid rgb(186, 186, 186);
  box-shadow: 0px 4px 14px rgb(219 219 219 / 19%);
  border-radius: 8px;
  width: 100%;

  & .label {
    font-weight: 500;
    font-size: 0.75rem;
  }
`;
