import {
  styled, createTheme, adaptV4Theme
} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const SortingContainer = styled('div')`
  margin: 80px;

  @media (min-width: 992px) {
    margin: 120px;
  }
`;

export const TitleWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Heading = styled('div')`
  text-align: center;
  color: #424242;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const SubHeading = styled('div')`
  letter-spacing: 0px;
  color: #424242;
  font-size: 26px;
  text-align: center;

  @media (min-width: 992px) {
    font-size: 17px;
  }
`;

export const TitleText = styled('p')`
    font-size: 28px;
    font-weight: bold;

    @media (min-width: 992px) {
      font-size: 20px;
    }
`;

export const DateText = styled('p')`
    color: #A3A3A3;
    font-size: 25px;
    margin: 0;
    cursor: pointer;

    @media (min-width: 992px) {
      font-size: 18px;
    }
`;

export const DividerLine = styled('hr')`
    border: 0.3px solid #CCCCBD;
    width: 100%;
    min-width: 300px;
    opacity: 0.2;
`;

export const DialogFooter = styled('div')`
    display: flex;
    align-items: center;
    padding: 20px 30px;
`;

export const RiderImage = styled('img')`
    width: 70px;
    height: 70px;
    background: #A3A3A3;
    border-radius: 50%;
    margin-right: 20px;

    @media (min-width: 992px) {
      width: 50px;
      height: 50px;
    }
`;

export const RiderDetails = styled('div')`
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    @media (min-width: 992px) {
      padding-top: 0px;
    }
`;

export const RiderTitle = styled('div')`
    font-size: 24px;
    color: #A3A3A3;
    opacity: 1;

    @media (min-width: 992px) {
      font-size: 15px;
    }
`;

export const RiderName = styled('div')`
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 25px;

    @media (min-width: 992px) {
      font-size: 17px;
    }
`;

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export const theme = createTheme(adaptV4Theme({

  overrides: {
    MuiStepConnector: {
      lineVertical: {
        minHeight: '70px',
        marginLeft: '3px',
      },
      root: {
        '&.Mui-completed': {
          '& $line': {
            borderColor: '#FCED50',
            borderLeftStyle: 'solid',
            borderLeftWidth: '0.15rem'
          }
        }
      },
    },
    MuiStepper: {
      vertical: {
        padding: '15px 10px',
        marginTop: '-20px'
      }
    }
  }
}));

export const StepperText = styled('div')`
  letter-spacing: 0px;
  color: #424242;
  opacity: 1;
  font-size: 17px;
  font-weight: initial;
  position: relative;
`;

export const StepperSubtext = styled('div')`
  position: absolute;
  top: 45px;
  display: flex;
  align-items: center
`;

export const Line = styled('div')`
  height: 20px;
  background: #A3A3A3;
  width: 2px;
`;

export const TimeLineWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const RoboRobo = styled('div')`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #A3A3A3;
`;
