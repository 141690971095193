import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BellNotifyIcon } from '../../assets/svgs';
import { CHANGE_NOTIFICATION_STATUS } from '../../mutations/notifications';
import {
  CListItem, CListItemAvatar, CAvatar, CListItemText, CListItemSecondaryAction, TimingText,
  RedBadge, SmallText
} from './notificationListItem.styles';

const NotificationListItem = ({ item }) => {
  const navigate = useNavigate();

  const {
    id, title, message, dateCreated, status, productId, productName, batchNo, deletedBy
  } = item;

  const { fullName } = deletedBy ?? {};

  const [updateNotification] = useMutation(CHANGE_NOTIFICATION_STATUS);
  const changeNotificationHandler = (notificationId, _status) => {
    updateNotification({
      variables: { notificationId, status: _status }
    });
  };

  const handleCLick = () => {
    if (status === 'NOT_READ') changeNotificationHandler(id, 'read');
    if (batchNo) {
      navigate(`/batch-details/${batchNo}/${productName}`);
    } else {
      navigate(`/products/${productId}/details`);
    }
  };

  return (
    <CListItem disableGutters button onClick={handleCLick}>
      <CListItemAvatar>
        {status === 'NOT_READ' && <RedBadge />}
        <CAvatar>
          <BellNotifyIcon />
        </CAvatar>
      </CListItemAvatar>
      <CListItemText primary={title} secondary={message} />
      <CListItemSecondaryAction>
        <TimingText>{moment.utc(dateCreated).local().startOf('seconds').fromNow()}</TimingText>
        {!!fullName && <SmallText>{`Archived By ${fullName}`}</SmallText>}
      </CListItemSecondaryAction>
    </CListItem>

  );
};

NotificationListItem.propTypes = {
  item: PropTypes.shape(Object),

};

NotificationListItem.defaultProps = {
  item: {},
};

export default NotificationListItem;
