import { styled } from '@mui/material/styles';
import {
  Typography, TableCell, Grid, Button, TextField, Dialog, DialogContent, IconButton,
  CircularProgress
} from '@mui/material';

export const CartGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 3rem;
  width: 100%;
  background-color: #FFFFFF;
  @media (min-width: 992px) {
    padding: 15px 3rem 15px 3rem;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #FFFFFF;
  margin-top: 4px;
  & .MuiCircularProgress-svg {
    width: 1.6rem;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
`;

export const HeaderSubText = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
`;

export const HeaderCell = styled(TableCell)`
  font-size: .9rem;
  font-weight: 500;
  line-height: 2rem;
  padding: 5px 10px;
  &.MuiTableCell-root {
    border-bottom: unset;
  }

  @media(max-width: 991px) {
    font-size: 1.4rem;
  }
`;

export const CardWrapper = styled(Grid)`
  width: 100%;
  margin-bottom: 2.5rem;
`;

export const SupplierDetailCardWrapper = styled(Grid)`
  width: 30%;
  @media(max-width: 991px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const DeliveryAddressCard = styled(Grid)`
  width: 40%;
  padding: 0 15px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

export const NoteButton = styled(Button)`
  margin: 0;
  color: #000000;
  background: none;
  border: 1px solid #303030;
  border-radius: 5px;
  font-size: 2rem;
  line-height: 32px;
  outline: none;
  font-family: Montserrat,medium,sans-serif;
  padding: 1.2rem 2.5rem;
  &:hover {
    color: #000000;
    background: none;
  }
  @media (min-width: 992px) {
    font-size: 16px;
    padding: 5px 10px;
  }
`;

export const NoteContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  padding-bottom: 20px;
`;

export const NoteTextField = styled(TextField)`
  background-color: #F5F5F7;
  height: 55px;

  label + .MuiInput-formControl {
    margin-top: 21px;
  }

  label {
    left: 20px;
    top: 5px;
    color: #A3A3A3;
  }

  input, textarea {
    padding-left: 20px;
    background-color: #F5F5F7;
    color: #424242;
    font-size: 18px;
    padding-top: 10px;
  }
  & .MuiInputBase-multiline {
      padding: 0;
    }
  & .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 991px) {
    height: 80px;

    label + .MuiInput-formControl {
      margin-top: 23px;
    }

    label {
      font-size: 1.6rem;
    }

    input, textarea {
      font-size: 2rem;
    }
  }
`;

export const SaveNoteButton = styled(Button)`
  background-color: #333333;
  border-radius: 5px;
  color: #fff;
  padding: 15px 10px;
  margin-top: 20px;
  @media (max-width: 991px) {
    font-size: 2rem;
    margin-top: 5rem;
  }
`;

export const NoteInfoContainer = styled(Grid)`
  margin-bottom: 8px;
`;

export const NoteInfo = styled(Grid)`
  padding: 10px;
  background: #F5F5F7;
  overflow-wrap: anywhere;
`;

export const NoteHeader = styled(Grid)`
  padding: 5px 0px;
  justify-content: space-between;
`;

export const NoteDescription = styled(Typography)`
font-size: .9rem;
@media (max-width: 991px) {
  font-size: 2rem;
}

`;

export const OutletContactLine = styled(Typography)`
  font-size: .9rem;
  color: #606060;
  font-weight: 500;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const NoteDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 15px;
    padding-bottom: 20px;
  }
  & .MuiDialog-paperWidthSm {
    width: 750px;
  }

  @media (max-width: 991px) {
    & .MuiDialog-paperWidthSm {
      width: 850px;
    }
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-height: 15rem;
`;

export const CloseStyled = styled('div')`
  text-align: right;
`;

export const DeliveryAddressCardHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  @media (min-width: 992px) {
    padding-top: 17px;
    padding-bottom: 17px;
  }
`;

export const NoteCardHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  @media (min-width: 992px) {
    padding-top: 17px;
    padding-bottom: 17px;
  }
`;

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
    padding-bottom: 30px;
  }
`;

export const SortButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 35px;
  border-radius: 5px;
  margin-right: 7px;
  background-color: #303030;
  color: #ffffff;
  border: 1px solid #303030;
  &:hover {
    background-color: #ffffff;
    color: #606060;
  }
`;

export const ViewAllNotes = styled(Grid)`
  color: #1F86FF;
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  cursor: pointer;
  font-weight: 600;
`;

export const DispatchButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;
  background-color: #303030;
  color: #ffffff;
  border-radius: 5px;

  &:hover {
    background-color: #303030;
    color: #ffffff;
  }

  &:disabled {
    color: #ffffff;
    background-color: #303030;
    opacity: 0.5;
  }

  @media(min-width: 992px) {
    padding: 15px 25px;
    border-radius: 5px;
    min-width: 200px;
  }
`;

export const OverviewFooter = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding: 0;

  @media (min-width: 992px) {
    padding: 0;
  }
`;
