import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import MainContent from '../../customComponents/mainContent';
import affiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import SideNav from '../shared/sideNav/sideNav';
import Loader from '../../shared/loader';
import { GET_MY_BUSINESS } from '../../../queries/affiliates';
import TitleGrid from './titleGrid';
import { useStateValue } from '../../../providers/stateProvider';
import BusinessInfoCard from './businessInformation/businessInfoCard';

import {
  TitleGridWrapper, OutletContentWrapper,
} from './business.styles';

export default function Outlets() {
  const [, dispatch] = Object.values(useStateValue());
  const [key, setKey] = useState(0);

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, [dispatch]);

  const {
    data, loading, error, refetch
  } = useQuery(GET_MY_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(localStorage.getItem('ogarx_business_id'))
    },
  });

  const updateAffiliateState = (loggedInAffiliate) => {
    dispatch({
      type: affiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        loggedInAffiliate,
        loggedInAffiliateRefetch: refetch
      }
    });
  };

  useEffect(() => {
    updateAffiliateState(data);
  }, [data]);

  if (loading || !data) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <MainContent>
      <TitleGridWrapper>
        <TitleGrid setContentKey={setKey} />
      </TitleGridWrapper>
      <OutletContentWrapper container spacing={3}>
        <SideNav />
        <BusinessInfoCard key={key} />
      </OutletContentWrapper>
    </MainContent>
  );
}
