import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Grow, Paper, Popper, MenuList, Button
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { MenuItemStyled } from './productPopper.style';

export default function ProductButtonsPopper({ actionHandler }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        className="erp-uat-add-product"
        onClick={handleToggle}
        ref={anchorRef}
      >
        Add Product
        <ArrowDropDown />
      </Button>
      <Popper
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled className="erp-uat-add-individually" onClick={() => { actionHandler('individual_prod'); setOpen(false); }}> Add Individually</MenuItemStyled>
                  <MenuItemStyled className="erp-uat-upload-order-csv" onClick={() => { actionHandler('upload_order_csv'); setOpen(false); }}> Upload CSV file</MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

ProductButtonsPopper.propTypes = {
  actionHandler: PropTypes.func.isRequired,
};
