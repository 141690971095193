import {
  Grid, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleGridWrapper = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const OutletContentWrapper = styled(Grid)`
  justify-content: space-between;
  flex-wrap: no-wrap;
  padding: 0 30px 10px;
`;
