import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableContainer,
  TableRow,
  TableBody
} from '@mui/material';
import { JSONParse } from '../../../../../utils/json';
import {
  TableCellStyled,
  TableCellBodyStyled,
  TableRowStyled,
  TableHeadStyled,
  CardTitle,
  BoxStyled
} from '../return-details.style';

const CustomerRewards = ({ row }) => {
  const {
    sale: {
      meta: saleMeta, customer, loyaltyAwarded, changeDue
    }
  } = row;

  const { change_due: isChangeDue } = JSONParse(saleMeta);

  return (
    <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px" mt={4}>
      <CardTitle>Customer Rewards</CardTitle>
      <TableContainer style={{ marginTop: '1rem' }}>
        <Table style={{ width: '100%' }}>
          <TableHeadStyled>
            <TableRowStyled>
              <TableCellStyled>Loyalty Member</TableCellStyled>
              <TableCellStyled>Loyalty Points</TableCellStyled>
              <TableCellStyled>Loyalty Points Balance</TableCellStyled>
              <TableCellStyled>Store Credit Added</TableCellStyled>
              <TableCellStyled>Store Credit Balance</TableCellStyled>
            </TableRowStyled>
          </TableHeadStyled>
          <TableBody>
            <TableRow>
              <TableCellBodyStyled>
                {customer?.loyaltyPoint ? 'Yes' : 'No'}
              </TableCellBodyStyled>
              <TableCellBodyStyled>{loyaltyAwarded || 0}</TableCellBodyStyled>
              <TableCellBodyStyled>
                {customer?.loyaltyPoint || 0}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {isChangeDue === 'True' && changeDue ? changeDue : 0}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {customer ? customer.storeCredit : 'N/A'}
              </TableCellBodyStyled>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BoxStyled>
  );
};

CustomerRewards.propTypes = {
  row: PropTypes.isRequired
};
export default CustomerRewards;
