import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import CustomNewTextField from '../../shared/CustomNewTextField';
import { FiltersGrid } from './productsFilter.styles';

const ProductFilter = ({
  state, handleFilterChange
}) => {
  const { categories } = state;

  const FilterFields = [
    { name: 'productName', label: 'Search by Product Name' },
    { name: 'supplier', label: 'Search by Supplier' },
    { name: 'manufacturer', label: 'Search by Manufacturer' },
  ];

  return (
    <FiltersGrid container item>
      {FilterFields.map((field) => (
        <CustomNewTextField
          key={field?.name}
          field={field}
          state={state}
          handleChange={handleFilterChange}
          style={{ width: '25%', margin: 0, paddingRight: '8px' }}
        />
      ))}
      <CustomNewTextField
        field={{ name: 'categoryId', label: 'Pricing Category' }}
        state={state}
        select
        handleChange={handleFilterChange}
        style={{ width: '25%', margin: 0 }}
      >
        {categories.map(({ categoryName, id }) => (
          <MenuItem
            key={id}
            value={id}
            style={{ paddingTop: '9px', paddingBottom: '9px' }}
          >
            {categoryName}
          </MenuItem>
        ))}
      </CustomNewTextField>
    </FiltersGrid>
  );
};

ProductFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
};

ProductFilter.defaultProps = {
  state: {},
};

export default ProductFilter;
