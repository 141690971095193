const currencyFormatter = (value) => `₦ ${new Intl.NumberFormat(
  'en-US',
  { minimumFractionDigits: 0 }
).format(value)}`;

export const valuesFormatter = (value) => new Intl.NumberFormat(
  'en-US',
  { minimumFractionDigits: 0 }
).format(value);

export default currencyFormatter;

export const stringShortner = (str, len) => {
  if (str.length < len) return str.substring(0, len);
  return str.substring(0, len).concat(' ...');
};
