import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, Slide, Grid, Button,
  Stack, Slider, Box
} from '@mui/material';
import DropZone from '../logoDropzone';
import getCroppedImg from './cropImage';
import CropImageContainer from './cropImageContainer';
import NotificationDialog from '../notificationDialog';
import {
  CDialog, DialogTitleText, DialogTitleSubText, CircularProgressLoader, UploadGrid, Image,
  GridWrapper, CloseIcon, SearchMinusIcon, SearchPlusIcon
} from './imageUploadDialog.styles';
import imageUploadActionTypes from '../../../providers/reducers/imageUpload/imageUploadTypes';
import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ImageUploadDialog = ({
  dialogOpen, image, handleFinalImage, handleImageDelete, closeDialog,
  setLoading, loading, placeholder
}) => {
  const [files, setFiles] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [uploadInitiated, setUploadInitiated] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);

  const [{
    imageUpload: { zoomed, imageSrc, croppedAreaPixels }
  }, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    const val = Math.round(zoomed * 10) / 10;
    const decimalVal = Number(val.toString().split('.')[1]);
    if (decimalVal) setSliderValue(decimalVal * 10);
    else if (val <= 1) setSliderValue(0);
    else if (val >= 2) setSliderValue(100);
  }, [zoomed]);

  const mapRange = (value, inputMin, inputMax, outputMin, outputMax) => {
    // First, calculate the normalized value within the 0 to 1 range
    const normalizedValue = (value - inputMin) / (inputMax - inputMin);

    // Then, map the normalized value to the new range (1 to 2 in this case)
    const mappedValue = normalizedValue * (outputMax - outputMin) + outputMin;

    return mappedValue;
  };

  useEffect(() => {
    const result = mapRange(sliderValue, 0, 100, 1, 2);
    dispatch({
      type: imageUploadActionTypes.UPDATE_IMAGE_UPLOADS,
      payload: {
        zoomed: result
      }
    });
  }, [sliderValue, dispatch]);

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleCloseDialog = () => {
    setUploadInitiated(false);
    setFiles(null);
    closeDialog();
  };

  const handleZoomChange = (val) => {
    let _zoomed = zoomed;
    if (val === 'minus' && _zoomed > 1) _zoomed -= 0.1;
    else if (val === 'plus' && _zoomed < 2) _zoomed += 0.1;
    dispatch({
      type: imageUploadActionTypes.UPDATE_IMAGE_UPLOADS,
      payload: {
        zoomed: _zoomed
      }
    });
  };

  const handleFile = (filesData) => {
    setFiles(filesData);
  };

  const handleSave = useCallback(async () => {
    try {
      const img = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );
      handleFinalImage(img);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const handleMainButton = async () => {
    if (uploadInitiated) {
      setLoading(true);
      await handleSave();
      setUploadInitiated(false);
      setFiles(null);
    } else setUploadInitiated(true);
  };

  return (
    <CDialog
      data-testid="product-dialog"
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container>
          <Grid item container direction="column" xs={10}>
            <DialogTitleText>
              Profile Photo
            </DialogTitleText>
            <DialogTitleSubText>
              Please upload a photo of yourself. Then crop and adjust it to perfection.
            </DialogTitleSubText>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <DialogContent>
          <UploadGrid container item>
            {image && !uploadInitiated
              ? <Image src={image} alt="image" />
              : files && files.length ? (
                <CropImageContainer
                  files={files}
                />
              ) : (
                <DropZone
                  handleFile={handleFile}
                />
              )}
          </UploadGrid>
        </DialogContent>
        <DialogActions
          style={uploadInitiated && files && files.length
            ? { justifyContent: 'space-between' }
            : { justifyContent: 'flex-end' }}
        >
          {uploadInitiated && files && files.length ? (
            <Box sx={{ width: 300 }}>
              <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <SearchMinusIcon style={{ cursor: 'pointer' }} onClick={() => handleZoomChange('minus')} />
                <Slider aria-label="Size" value={sliderValue} onChange={handleChange} />
                <SearchPlusIcon style={{ cursor: 'pointer' }} onClick={() => handleZoomChange('plus')} />
              </Stack>
            </Box>
          ) : ''}

          {image && !uploadInitiated && (
            <Button
              variant="outlined"
              onClick={() => setOpenDeleteDialog(true)}
              disabled={image === placeholder || loading}
            >
              Delete Photo
            </Button>
          )}
          <Button
            variant="contained"
            style={{ marginLeft: '18px' }}
            onClick={handleMainButton}
            disabled={loading}
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : uploadInitiated ? 'Save' : 'Upload Photo'}
          </Button>
        </DialogActions>
      </GridWrapper>

      <NotificationDialog
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        type="warning"
        title="Delete Photo"
        desc="Are you sure you want to delete this photo?"
        action="cancel"
        action2="Yes, Delete Photo"
        action2Func={handleImageDelete}
      />
    </CDialog>
  );
};

ImageUploadDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  image: PropTypes.shape(Image),
  handleFinalImage: PropTypes.func.isRequired,
  handleImageDelete: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
};

ImageUploadDialog.defaultProps = {
  image: {},
  placeholder: ''
};

export default ImageUploadDialog;
