import React from 'react';
import PropTypes from 'prop-types';
import { Label, CInputBase } from '../fields.styles';

const DateField = ({ fields }) => {
  const { label, required } = fields;
  return (
    <>
      <Label>
        {label ?? ''}
        {' '}
        {required && <span style={{ color: 'red' }}>*</span>}
      </Label>
      <CInputBase {...fields} />
    </>
  );
};

DateField.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired
};
export default DateField;
