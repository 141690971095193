import React from 'react';
import { Grid } from '@mui/material';
import { EmptyFlex, EmptyImg } from './mapProductDialog.styles';
import EmptyCount from '../../../assets/images/empty_count.gif';

const NoDisplay = () => (
  <EmptyFlex item container direction="column">
    <EmptyImg alt="Empty stock count report" src={EmptyCount} />
    <Grid
      item
      style={{
        fontWeight: 700,
        fontSize: '18px',
        color: '#303030',
        marginBottom: '0.5rem'
      }}
    >
      Nothing to display yet!
    </Grid>
    <Grid
      item
      style={{ fontSize: '14px', fontWeight: '400', color: '#606060' }}
    >
      Hi there, all searched products will be displayed here
    </Grid>
  </EmptyFlex>
);

export default NoDisplay;
