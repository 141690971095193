import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle, SupplierOrderImage,
  SecondaryTitle, OrderButton, OrderButtonText, PlusIcon, ButtonsGrid,
  ManageProductImage, OrderCreateButton, OrdersPrimaryTitle, KeyboardArrowLeft, BackArrowIconContainer, FlexDiv
} from './titleGrid.styles';
import SupplierOrderIcon from '../../../assets/images/order/View Supplier Orders.png';
import BagIcon from '../../../assets/images/navigation/Product Icon (black).png';
import OrderDropdown from '../../shared/orderDropdown/orderDropdown';
import AffiliateModal from '../admin/affiliate-modal';
import VerificationDialog from '../newOrder/verificationDialog';

export function TitleGrid({
  match: { path }
}) {
  const role = localStorage.getItem('oga_user_role');
  const [orderButtonEl, setOrderButtonEl] = useState(null);
  const [route, setRoute] = useState('');
  const [action, setAction] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const affiliateStatus = localStorage.getItem('affiliateStatus');
  const navigate = useNavigate();

  const isAdmin = role === 'oga-pharmacy-admin' || role === 'chain-manager';
  const isAffiliateAdmin = role === 'affiliate-admin';

  const handleOrderButtonClick = (event, routeTo) => {
    if (isAdmin) setAction(true);
    if (isAffiliateAdmin) navigate(routeTo);
    setRoute(routeTo || event.currentTarget.name);
    setOrderButtonEl(orderButtonEl ? null : event.currentTarget);
  };

  const handleSelectAffiliate = (event, routeTo) => {
    if (isAdmin) setOpenModal(true);
    else handleOrderButtonClick(event, routeTo);
  };

  const handleCloseDialog = (event) => {
    setOpenDialog(openDialog ? false : Boolean(event.currentTarget));
  };

  const handleVerification = (event, routeTo) => {
    if (affiliateStatus !== 'VERIFIED') {
      setOpenDialog(true);
      return;
    }
    handleOrderButtonClick(event, routeTo);
  };

  return <>
    <TitleGridContainer container item>
      <FlexDiv item lg={12} sm={8}>
        {path.split('/')[1] !== 'orders' && (
          <BackArrowIconContainer onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </BackArrowIconContainer>
        )}
        <TitleTextGridContainer>
          {path.split('/')[1] === 'orders' ? (
            <OrdersPrimaryTitle> Orders </OrdersPrimaryTitle>
          ) : <PrimaryTitle> Order </PrimaryTitle> }
          <SecondaryTitle>
            View Orders and Analytics
          </SecondaryTitle>
        </TitleTextGridContainer>
      </FlexDiv>
      <ButtonsGrid container item lg={10} sm={12}>
        {path.split('/')[1] === 'orders-admin' && (
          <>
            {(role === 'oga-pharmacy-admin') ? (
              <OrderButton type="secondary" onClick={() => navigate('/dispatch-orders')}>
                <Hidden mdDown>
                  <SupplierOrderImage alt="supplier order icon" src={SupplierOrderIcon} />
                </Hidden>
                <OrderButtonText>Dispatch Orders</OrderButtonText>
              </OrderButton>
            ) : ''}
            <OrderButton onClick={(event) => handleSelectAffiliate(event, '/new-order')} name="/new-order">
              <Hidden mdDown>
                <PlusIcon />
              </Hidden>
              <OrderButtonText>Create New Order</OrderButtonText>
            </OrderButton>
            <AffiliateModal
              afterMath={handleOrderButtonClick}
              open={openModal}
              handleCloseModal={() => setOpenModal(false)}
            />
          </>
        )}
        {path.split('/')[1] === 'manual-order-dashboard' && (
          <>
            <OrderButton type="secondary" onClick={() => navigate('/open-orders')}>
              <Hidden mdDown>
                <SupplierOrderImage alt="supplier order icon" src={SupplierOrderIcon} />
              </Hidden>
              <OrderButtonText>View Open Orders</OrderButtonText>
            </OrderButton>
            <OrderButton onClick={(event) => handleSelectAffiliate(event, '/manual-order')} name="/manual-order">
              <Hidden mdDown>
                <PlusIcon />
              </Hidden>
              <OrderButtonText>Create New Order</OrderButtonText>
            </OrderButton>
            <AffiliateModal
              afterMath={handleOrderButtonClick}
              open={openModal}
              handleCloseModal={() => setOpenModal(false)}
            />
          </>
        )}

        {path.split('/')[1] === 'orders' && (
        <>
          <Hidden mdDown>
            <OrderCreateButton className="mpFe-uat-orders-create-new-order" container onClick={((event) => handleVerification(event, '/new-order'))} name="/new-order">
              <OrderButtonText>Create New Order</OrderButtonText>
            </OrderCreateButton>
          </Hidden>
          <Hidden mdUp>
            <OrderCreateButton style={{ justifyContent: 'center', width: '100%', marginTop: '20px' }} container onClick={((event) => handleVerification(event, '/new-order'))} name="/new-order">
              <OrderButtonText>Create New Order</OrderButtonText>
            </OrderCreateButton>
          </Hidden>
        </>
        )}
        {path.split('/')[1] === 'orders-supplier' && (
        <OrderButton onClick={() => navigate('/products')}>
          <Hidden mdDown>
            <ManageProductImage alt="supplier order icon" src={BagIcon} />
          </Hidden>
          <OrderButtonText>Manage Products</OrderButtonText>
        </OrderButton>
        )}
      </ButtonsGrid>

      <OrderDropdown
        route={route}
        extraAction={action}
        isAdmin={isAdmin}
        orderButtonEl={orderButtonEl}
        setOrderButtonEl={setOrderButtonEl}
        handleProductButtonClick={handleOrderButtonClick}
      />
    </TitleGridContainer>
    {openDialog && (
      <VerificationDialog
        openDialog={openDialog}
        handleClose={handleCloseDialog}
        type="affiliateUser"
        status={affiliateStatus}
      />
    )}
  </>;
}

TitleGrid.propTypes = {
  match: PropTypes.instanceOf(Object),
};

TitleGrid.defaultProps = {
  match: {},
};

export default TitleGrid;
