import React, { useState } from 'react';
import {
  Grid, Button, CircularProgress,
  useMediaQuery
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { CancelRounded, CheckCircleOutlineRounded } from '@mui/icons-material';
import { RESET_PASSWORD } from '../../mutations/auth';
import PasswordTextField from './passwordTextField';
import { validatePassword } from './utils';
import { IsValidPasswordStyled } from './resetPassword.styles';
import {
  containsLowerCase, containsNumber, containsSpecialCharacter, containsUpperCase, minStrLength
} from '../../utils/funcs';

const ResetPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const initialState = {
    password: '',
    confirmNewPassword: '',
  };
  const initialErrorState = {
    passwordError: false,
    confirmNewPasswordError: false,
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);

  const {
    password, confirmNewPassword
  } = state;

  const acceptPassword = [
    { id: 1, value: 'Minimum 8 Characters', valid: minStrLength(password, 8) },
    { id: 2, value: 'One Uppercase Character', valid: containsUpperCase(password) },
    { id: 3, value: 'One Special Character', valid: containsSpecialCharacter(password) },
    { id: 4, value: 'One Lower Character', valid: containsLowerCase(password) },
    { id: 5, value: 'One Number', valid: containsNumber(password) },
  ];

  const { passwordError, confirmNewPasswordError } = errorState;

  const validateState = (name, value) => {
    switch (name) {
      case 'password':
      case 'confirmNewPassword':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: value === '' || validatePassword(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const resetPasswordHandler = () => {
    resetPassword({
      variables: { token, password }
    }).then(({ data: { resetPassword: { message } } }) => {
      toast.success(message);
      navigate('/');
    }).catch((err) => toast.error(err.message));
  };

  const handleResetPassword = () => {
    if (validatePassword(password)) {
      toast.error('validate password');
    } else if (validatePassword(confirmNewPassword)) {
      toast.error('validate confirm password');
    } else if (password !== confirmNewPassword) {
      toast.error('password mismatch');
    } else {
      resetPasswordHandler();
    }
    return setErrorState({
      ...errorState,
      passwordError: validatePassword(password),
      confirmNewPasswordError: validatePassword(confirmNewPassword),
    });
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <>
      <Grid container spacing={2} mt={-3}>
        <Grid item xs={12}>
          <PasswordTextField
            password={password}
            label="Create New Password"
            placeholder="***********"
            handleChange={handleChange}
            passwordError={passwordError}
            loading={loading}
          />
          <Grid container sm={12} mt={1}>
            {acceptPassword?.map((item) => {
              const { id, value, valid } = item;
              return (
                <Grid sm={6} key={id}>
                  <IsValidPasswordStyled valid={valid}>
                    {valid ? (
                      <CheckCircleOutlineRounded sx={{ width: '15px', color: '#2ECC71' }} />
                    ) : (
                      <CancelRounded sx={{ width: '15px', color: '#E74C3C' }} />
                    )}
                    {value}
                  </IsValidPasswordStyled>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: isSmall && '1rem' }}>
          <PasswordTextField
            name="confirmNewPassword"
            password={confirmNewPassword}
            label="Confirm New Password"
            placeholder="***********"
            handleChange={handleChange}
            passwordError={confirmNewPasswordError}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleResetPassword}
        variant="contained"
        style={{
          width: '100%', marginTop: isSmall ? '2.5rem' : '2rem', height: isSmall && '6rem', fontSize: isSmall && '2rem'
        }}
      >
        {loading ? <CircularProgress size={18} /> : 'Submit'}
      </Button>
    </>
  );
};

export default ResetPassword;
