import * as React from 'react';
import { Grid, Skeleton } from '@mui/material';

const ChartLoader = () => {
  const { innerWidth: width } = window;
  const cols = Math.round((width - 700) * 0.03);

  return (
    <Grid container spacing={2} style={{ margin: '.4rem' }}>
      {Array.from(new Array(cols)).map((index) => (
        <Skeleton
          key={index}
          variant="rounded"
          sx={{
            width: '3%', height: '100%', borderRadius: '.6rem', marginRight: '10px'
          }}
        />
      ))}
    </Grid>
  );
};

export default ChartLoader;
