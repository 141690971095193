import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import ActionPopper from './actionPopper';
import {
  CInputBase, Label, HelperText, CButton, DivLine, ArrowDownIcon, ArrowUpIcon
} from './styles';

const CustomSideSelectInputBase = ({
  label, sideLabel, required, error, loading, helperText, endAdornment, adonmentOptions,
  onSelectChange, ...props
}) => {
  const [option, setOption] = useState(adonmentOptions[0]);
  const [buttonEl, setButtonEl] = useState(null);

  const handleOpenPopper = (event) => {
    setButtonEl(buttonEl ? null : event.currentTarget);
  };

  const handlePopperChange = (_option) => {
    setOption(_option);
    setButtonEl(null);
    onSelectChange(_option);
  };

  return (
    <Grid item container direction="column">
      <Grid item container justifyContent="space-between">
        {label ? (
          <Label error={error}>
            {label}
            {' '}
            {required && '*'}
          </Label>
        ) : ''}
      </Grid>
      <CInputBase
        error={error}
        {...props}
        endAdornment={(
          <InputAdornment position="end" onClick={handleOpenPopper}>
            <DivLine />
            <CButton variant="text" disableRipple disableFocusRipple>
              {option}
              {buttonEl ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </CButton>
          </InputAdornment>
        )}
      />
      <ActionPopper
        buttonEl={buttonEl}
        options={adonmentOptions}
        handlePopperChange={handlePopperChange}
        handleClose={() => setButtonEl(false)}
      />
      {helperText && error ? <HelperText>{helperText}</HelperText> : ''}
    </Grid>
  );
};

CustomSideSelectInputBase.propTypes = {
  label: PropTypes.string,
  sideLabel: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  endAdornment: PropTypes.element,
  helperText: PropTypes.string,
  adonmentOptions: PropTypes.shape(Array),
  props: PropTypes.shape(Object).isRequired,
  onSelectChange: PropTypes.func.isRequired,
};

CustomSideSelectInputBase.defaultProps = {
  label: '',
  sideLabel: '',
  required: false,
  error: false,
  loading: false,
  helperText: '',
  endAdornment: [],
  adonmentOptions: []
};

export default CustomSideSelectInputBase;
