import React, { useState } from 'react';
import {
  Paper, Table, TableRow, TableHead, Grid
} from '@mui/material';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  TableContainerWrapper, ArrowImg, TableBox, TableContent,
  HeaderGrid, DivFullWidth, HeaderCell, BackButton,
  PrimaryTitle, TitleGridContainer,
} from './usersContainer.styles';
import CustomSearchField from '../../../shared/CustomSearchField';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../../shared/tablePagination';
import backImg from '../../../../assets/images/marketOrder/Arrow.png';
import { BUSINESS_USERS_QUERY } from '../../../../queries/businesses';
import { MainTableBody } from '../roles/roles.styles';

const Users = ({ routeState, userRoles }) => {
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const headers = [
    'S/N', 'Users', 'Email Address', 'Role', 'Action'
  ];

  let variables = {
    pageCount,
    pageNumber,
    search: searchText.length >= 3 ? searchText : ''
  };

  const { name: role, id: roleId } = routeState || {};
  if (routeState) {
    variables = {
      ...variables,
      roleId
    };
  }
  const {
    loading, data, refetch
  } = useQuery(BUSINESS_USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const {
    businessUsers = [], businessUsersCount = 0
  } = data || {};
  const handleSearch = (value) => {
    setSearchText(value);
  };

  return (
    <Grid item container justifyContent="flex-end" xs={9}>
      <TableContainerWrapper elevation={0}>
        <BackButton
          variant="none"
          startIcon={<ArrowImg src={backImg} alt="back" />}
          disableRipple
          onClick={() => navigate(-1)}
        >
          back
        </BackButton>
        <TitleGridContainer container item xs={12} justifyContent="space-between">
          <PrimaryTitle variant="h5">
            {`${role ? `${role}` : 'All'} Users`}
          </PrimaryTitle>
        </TitleGridContainer>
        <HeaderGrid>
          <CustomSearchField
            name="search"
            value={searchText}
            fullWidth
            placeholder="Search"
            handleChange={(e) => handleSearch(e?.target?.value)}
          />
        </HeaderGrid>

        {loading ? <ProductsTableLoader /> : (
          <TableBox>
            <TableContent component={Paper}>
              <Table aria-label="users table">
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <HeaderCell key={header}>{header}</HeaderCell>
                    ))}
                  </TableRow>
                </TableHead>
                <MainTableBody item container>
                  {businessUsers.map(({
                    id, fullName, user, systemRole,
                  }, index) => (
                    <ReturnRow
                      id={id}
                      key={index}
                      index={index}
                      fullName={fullName}
                      user={user}
                      systemRole={systemRole}
                      userRoles={userRoles}
                      refetch={refetch}
                    />
                  ))}
                </MainTableBody>
              </Table>
            </TableContent>
          </TableBox>
        )}
        <DivFullWidth>
          <TablePagination
            total={businessUsersCount}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </DivFullWidth>
      </TableContainerWrapper>
    </Grid>
  );
};

Users.propTypes = {
  routeState: PropTypes.shape(Object),
  userRoles: PropTypes.string.isRequired
};

Users.defaultProps = {
  routeState: {}
};

export default Users;
