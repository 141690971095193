import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Grid, TableBody, Paper, Table
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MenuButtonStyled, PageTitleContainer, BackRouteText,
  CartGridContainerHeader, TableComponent
} from '../../cart/cart.styles';
import { TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';
import {
  KeyboardArrowLeft, TableContainerWrapper, MainTableRow, TableCellStyled,
  FilterGrid, PaperWrapper, Footer, PageTitleText
} from './prescription.styles';
import { GET_CUSTOMER_PRESCRIPTIONS } from '../../../queries/customerPrescriptions';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { OrderGridContainer } from '../../orders/admin/order.styles';
import CustomSearchField from '../../shared/CustomSearchField';
import ReturnRow from './returnRow';
import MainContent from '../../customComponents/mainContent';
import TablePagination from '../../shared/tablePagination';

const PrescriptionContainer = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [search, setSearch] = useState('');
  const { id } = useParams();

  const navigate = useNavigate();

  const headers = [
    { name: 'Product Name', width: '220px' }, { name: 'Pharmacist Name', width: '200px' }, { name: 'Date Issued', width: '150px' },
    { name: 'Number of Tablet Per Sachet/Pack', width: '300px' }, { name: 'Usage Expected Dose/Day', width: '230px' }, { name: 'No of Usage Days', width: '180px' },
    { name: 'Status', width: '150px' }
  ];

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const triggerSearch = () => {
    if (search.length > 3) return search;
  };

  const { loading, data, error } = useQuery(GET_CUSTOMER_PRESCRIPTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      customerId: id,
      pageNumber,
      pageCount,
      search: triggerSearch()
    }
  });

  if (error) return `Error! ${error.message}`;

  const prescriptions = data?.customerPrescriptions || [];

  return (
    <MainContent>
      <OrderGridContainer container>
        <Grid container item alignItems="center" style={{ marginBottom: '1rem' }}>
          <MenuButtonStyled onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
          </MenuButtonStyled>
          <BackRouteText variant="caption">back</BackRouteText>
        </Grid>
        <CartGridContainerHeader item container>
          <Grid container xs={12} md={10} lg={10} alignItems="center" justify-content="space-between">
            <PageTitleContainer>
              <PageTitleText>
                {prescriptions[0]?.customer?.name}
                {' '}
                Prescription
              </PageTitleText>
            </PageTitleContainer>
          </Grid>
        </CartGridContainerHeader>
        <PaperWrapper elevation={0}>
          <FilterGrid item container spacing={1}>
            <Grid item container xs={12}>
              <CustomSearchField
                name="search"
                value={search}
                fullWidth
                placeholder="Search"
                handleChange={(e) => setSearch(e?.target?.value)}
              />
            </Grid>
          </FilterGrid>
          <TableContainerWrapper component={Paper}>
            {loading ? <ProductsTableLoader />
              : (
                <TableComponent aria-label="affiliates table">
                  <Table>
                    <TableColumnHeaders>
                      {returnHeaders()}
                    </TableColumnHeaders>
                    <TableBody>
                      {
                        prescriptions.length
                          ? prescriptions?.map((item) => <ReturnRow row={item} key={item?.id} />)
                          : (
                            <MainTableRow>
                              <TableCellStyled colSpan={6} style={{ textAlign: 'center' }}>No record found</TableCellStyled>
                            </MainTableRow>
                          )
                      }
                    </TableBody>
                  </Table>
                </TableComponent>
              )}
          </TableContainerWrapper>
          {prescriptions?.length > 0 && (
            <Footer>
              <TablePagination
                total={data?.businessTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </Footer>
          )}
        </PaperWrapper>
      </OrderGridContainer>
    </MainContent>
  );
};

export default PrescriptionContainer;
