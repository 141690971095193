import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import toast from 'react-hot-toast';

import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid, useMediaQuery, InputLabel, Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import ReturnSelectField from './returnSelectField';
import SavePopper from './savePopper';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader, CDialog,
  GridWrapper, TopGrid, MiddleGrid, BottomGrid, SupplierTextField, FormWrapper
} from './individualOutletDialog.styles';
import { SAVE_DELIVERY_LOCATION } from '../../../mutations/outlets';
import { validateEmail } from '../../auth/utils';
import AffiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';

import { useStateValue } from '../../../providers/stateProvider';
import { JSONParse } from '../../../utils/json';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualOutletDialog = ({
  dialogOpen, closeDialog, existingOutlet, cart
}) => {
  const today = moment().format('YYYY-MM-DD');
  const isSmall = useMediaQuery('(max-width: 991px)');
  const initialState = {
    deliveryLocationId: null,
    outletName: '',
    mobile: '',
    primaryContactName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    region: '',
    city: 'Lagos',
    country: 'Nigeria',
  };
  const [state, setState] = useState(initialState);
  useEffect(() => {
    if (existingOutlet) {
      const { name } = existingOutlet;
      const contacts = JSONParse(existingOutlet.contacts);
      const {
        region, city, country,
        address_line_1: addressLine1,
        primary_email_address: email, mobile_number: mobile,
        primary_contact_name: primaryContactName,
      } = contacts;
      const deliveryLocationState = {
        deliveryLocationId: existingOutlet.id,
        outletName: name,
        mobile,
        primaryContactName,
        email,
        addressLine1,
        region,
        city,
        country,
      };
      setState({ ...state, ...deliveryLocationState });
    }
  }, [existingOutlet]);

  const initialErrorState = {
    nameError: false,
    emailError: false,
  };

  const initialAlertState = {
    message: '',
    showAlert: false,
    type: ''
  };
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState(initialAlertState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [editing, setEditing] = useState(false);

  const [{
    affiliate: { affiliate, refetch, affiliateDetailRefetch }
  }, dispatch] = Object.values(useStateValue());

  const [createDeliveryLocation] = useMutation(SAVE_DELIVERY_LOCATION);

  const { nameError } = errorState;

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    // dispatch({
    //   type: AffiliateActionTypes.UPDATE_AFFILIATE,
    //   payload: { affiliate: null }
    // });
    closeDialog();
  };

  const addAffiliate = (logo, addMore) => {
    const {
      outletName, mobile, email,
      addressLine1, primaryContactName, region, city, country, deliveryLocationId
    } = state;
    const ogaOrderingForAffiliate = localStorage.getItem('oga_ordering_for_affiliate');
    const business = ogaOrderingForAffiliate ? JSON.parse(ogaOrderingForAffiliate).id : localStorage.getItem('ogarx_business_id');
    createDeliveryLocation({
      variables: {
        business: +business,
        deliveryLocationId: +deliveryLocationId,
        name: outletName,
        primaryContactName,
        mobileNumber: mobile,
        primaryEmailAddress: email,
        addressLine1,
        region,
        city,
        country,
      }
    })
      .then(({ data }) => {
        const { message } = data?.createOutlet || {};
        toast.success(message);
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setSaveButtonEl(null);
    setLoading(true);
    return addAffiliate();
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
  };

  const handleSaveButtonClick = (event) => {
    const { outletName } = state;
    if (outletName.length > 1 && existingOutlet) {
      return handleSave();
    }
    if (outletName.length > 1 && !editing) {
      return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
    } if (outletName.length > 1 && editing) {
      return handleSave();
    }
    return setErrorState({
      ...errorState,
      nameError: true
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const nigeriaState = [
    'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo',
    'Ekiti', 'Enugu', 'FCT - Abuja', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos',
    'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara'
  ];

  const topFields = [
    {
      name: 'outletName',
      label: 'Branch Name',
      helperText: 'Branch Name required',
      placeholder: "Enter Customer's Name"
    },
    { name: 'primaryContactName', label: 'Primary Contact Name', placeholder: "Enter Customer's Name" },
    { name: 'email', label: 'Email Address', placeholder: 'Enter Email Address' },
    { name: 'mobile', label: 'Phone Number', placeholder: 'Enter Phone Number' },
    { name: 'addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line 1' },
    {
      name: 'state',
      label: 'State',
      placeholder: 'Select State',
      options: nigeriaState
    },
    {
      name: 'country',
      label: 'Country',
      options: ['Nigeria'],
      placeholder: 'Select Country'
    },
    { name: 'city', label: 'City', placeholder: 'Enter City' },
  ];

  // const middleFields = [
  //   { name: 'addressLine1', label: 'Address Line 1' }
  // ];

  // const bottomFields = [
  //   { name: 'region', label: 'Region' },
  //   { name: 'city', label: 'City' },
  //   {
  //     name: 'country',
  //     label: 'Country',
  //     options: ['Nigeria', 'Uganda', 'Kenya']
  //   }
  // ];
  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, placeholder
    } = field;
    const value = state[fieldName];
    if (['country', 'state', 'deliveryLocationType'].includes(fieldName)) {
      return (
        <FormWrapper item xs={12} lg={6}>
          <InputLabel
            htmlFor={label}
            style={{ fontSize: isSmall ? '1.8rem' : '.9rem', marginBottom: isSmall && '.7rem' }}
            sx={{ display: 'inline' }}
          >
            {label}
          </InputLabel>
          <ReturnSelectField
            field={field}
            value={value}
            handleChange={handleChange}
            cart={cart}
            showCheckBox={cart && false}
          />
        </FormWrapper>
      );
    }
    return (
      <FormWrapper item xs={12} lg={6}>
        <InputLabel
          htmlFor={label}
          style={{ fontSize: isSmall ? '1.8rem' : '.9rem', marginBottom: isSmall && '.7rem' }}
          sx={{ display: 'inline' }}
        >
          {label}
        </InputLabel>
        <SupplierTextField
          variant="filled"
          size="small"
          id={label}
          // label={label}
          value={value}
          placeholder={placeholder}
          type={fieldName === 'mobile' ? 'number' : fieldName === 'dateLaunched' ? 'date' : 'text'}
          error={fieldName === 'legalName' ? nameError : false}
          helperText={nameError && helperText}
          name={fieldName}
          onChange={handleChange}
        />
      </FormWrapper>
    );
  };

  return (
    <>
      <CDialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fileslimit={1}
      >
        <Grid container>
          <GridWrapper container item xs>
            <DialogTitle id="alert-dialog-slide-title">
              <Close
                fontSize="large"
                style={{
                  position: 'absolute', right: '1.5rem', cursor: 'pointer',
                  width: isSmall ? '50px' : '25px', height: isSmall && '50px'
                }}
                onClick={handleCloseDialog}
              />
              <DialogTitleWrapper container>
                <DialogTitleText>
                  {editing ? 'Edit Delivery Location' : 'Add Delivery Location' }
                </DialogTitleText>
                <DialogTitleSubText>
                  {editing
                    ? 'Edit the form to update outlet information'
                    : 'Add your delivery information to the fields in the form'}
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>
            <DialogContent container sm={12} style={{ marginBottom: '-2rem' }}>
              <TopGrid container item>
                {topFields.map((field) => returnTextField(field))}
              </TopGrid>
              {/* <MiddleGrid item>
                {middleFields.map((field) => returnTextField(field))}
              </MiddleGrid>
              <BottomGrid item>
                {bottomFields.map((field) => returnTextField(field))}
              </BottomGrid> */}
            </DialogContent>

            <DialogActions style={{ display: isSmall ? 'block' : 'flex' }}>
              <Button
                variant="outlined"
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                )
                  : 'Submit'}
              </Button>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </CDialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

IndividualOutletDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  existingOutlet: PropTypes.instanceOf(Object),
  cart: PropTypes.bool
};
IndividualOutletDialog.defaultProps = {
  existingOutlet: {},
  cart: false
};
export default IndividualOutletDialog;
