import React from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  HelpOutlineStyled,
  ToolTipContent,
  TooltipTitle
} from './tooltip.styles';

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#235A91',
    color: '#fff',
    fontSize: theme?.typography?.pxToRem(12),
    border: '1px solid #235a91',
    borderRadius: '8px',
    padding: '.5rem 0.5rem .5rem 1rem',
    minWidth: '21rem !important',
    marginLeft: '15rem',
    minHeight: '5rem',
    '&[x-placement*="top"] $arrow': {
      bottom: '-8px',
      left: '50%',
      borderWidth: '8px 8px 0',
      borderTopColor: '#235A91'
    }
  },
  '.MuiTooltip-arrow': {
    color: '#235A91',
    marginLeft: '-7.5rem'
  }
}));

const TooltipContainer = ({ title, description }) => (
  <HtmlTooltip
    title={(
      <>
        <TooltipTitle>{title}</TooltipTitle>
        <ToolTipContent>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </ToolTipContent>
      </>
    )}
    arrow
  >
    <HelpOutlineStyled />
  </HtmlTooltip>
);

TooltipContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

TooltipContainer.defaultProps = {
  title: 'Quick Guide',
  description: ''
};

export default TooltipContainer;
