import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  OrderItemPaper, OrderItemImage, ImageGridContainer, PaperCardGrid,
  TextGridContainer, DatePlacedText, OrderNumberText,
  DeliveryDateText, TotalText, SavingsText, MarkerGridContainer,
  MarkerOrderImage, MarkerOrderText, ContentGridContainer,
  SupplierText, OrderStatus, AffiliteText
} from './orderItemCard.styles';
import MarkerOrderIcon from '../../../assets/images/order/View Order Detail.png';
import currencyFormatter from '../../../utils/currencyFormatter';
import { isERP } from '../../../utils/utils';

export default function orderItemCard({
  id, dateCreated, total, savings, logo,
  supplierName, handleOrderItemCardClicked,
  status, affiliateName
}) {
  const datePlaced = moment(dateCreated).format('DD/MM/YYYY HH:mm');
  const expectedDelivery = moment(dateCreated).add(2, 'days').format('DD/MM/YYYY');
  const orderId = `000000000${id}`.slice(-6);
  const orderStatus = () => {
    switch (status) {
      case 'ORDER_SORTED':
        return ['O Sorted', 'Sorted'];
      case 'ORDER_DISPATCHED':
        return ['O Out for Delivery', 'Out for Delivery'];
      case 'ORDER_CLOSED':
        return ['O Delivered', 'Delivered'];
      case 'ORDER_CANCELED':
        return ['O Cancelled', 'Cancelled'];
      default:
        return ['O New', 'New'];
    }
  };

  return (
    <OrderItemPaper elevation={2} onClick={() => handleOrderItemCardClicked(id)}>
      <PaperCardGrid container item>
        <ContentGridContainer container>
          <ImageGridContainer container>
            <OrderItemImage alt="" src={logo} />
          </ImageGridContainer>
          <TextGridContainer>
            <DatePlacedText>
              {`Placed on ${datePlaced}`}
            </DatePlacedText>
            {affiliateName && (
              <Grid container item>
                <AffiliteText>{affiliateName}</AffiliteText>
              </Grid>
            )}
            <OrderNumberText>
              { `#${orderId}` }
            </OrderNumberText>
            <DeliveryDateText>
              {`Estimated Delivery on ${expectedDelivery}`}
            </DeliveryDateText>
            {
              status && (
                <OrderStatus status={orderStatus()[0]}>
                  {orderStatus()[1]}
                </OrderStatus>
              )
            }
            <Grid container item>
              <TotalText> Total: </TotalText>
              <TotalText>
                {currencyFormatter(total)}
              </TotalText>
              {!isERP() && (
              <>
                <SavingsText> Savings: </SavingsText>
                <SavingsText>
                  {currencyFormatter(savings < 0 ? 0 : savings)}
                </SavingsText>
              </>
              )}
            </Grid>
            {supplierName && (
            <Grid container item>
              <SupplierText style={{ margin: 0, fontWeight: 400 }}>
                Supplier:
              </SupplierText>
              <SupplierText>{supplierName}</SupplierText>
            </Grid>
            )}
          </TextGridContainer>
        </ContentGridContainer>
        <MarkerGridContainer container id="hide">
          <MarkerOrderImage alt="view order icon" src={MarkerOrderIcon} />
          <MarkerOrderText>
            Click anywhere to open order detail
          </MarkerOrderText>
        </MarkerGridContainer>
      </PaperCardGrid>
    </OrderItemPaper>
  );
}

orderItemCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  dateCreated: PropTypes.string,
  total: PropTypes.number,
  savings: PropTypes.number,
  logo: PropTypes.string,
  supplierName: PropTypes.string,
  supplierLogo: PropTypes.string,
  handleOrderItemCardClicked: PropTypes.func.isRequired,
  status: PropTypes.string
};

orderItemCard.defaultProps = {
  id: '',
  name: '',
  dateCreated: '',
  total: 0,
  savings: 0,
  logo: '',
  supplierName: '',
  supplierLogo: '',
  status: undefined
};
