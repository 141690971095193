import {
  Grid, TableContainer, Table, TableHead, TableCell, Button, Typography, Paper
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: table-header-group;
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 0 0 0;
  padding-left: 2rem;
  padding-bottom: 2rem;
`;

export const TableComponent = styled(Table)`
  min-width: 650px;
`;

export const TableHeader = styled(TableCell)`
  text-transform: uppercase;
  padding: 10px;
  font-weight: 600;
  background-color: #F4F7FC;

  @media(min-width: 992px) {
    &:first-child, :nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6){
      width: 16.5%;
    }
  }
`;

export const TableTitle = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 20px;
  @media(max-width: 991px) {
    font-size: 2.4rem;
    text-align: center;
    font-weight: 600;
    padding-bottom: 2rem;
  }
`;

export const FiltersGrid = styled(Grid)`
  padding: 1.8rem 1.8rem 1rem;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
  padding: 1.8rem 1.8rem 1rem;
  }
`;

export const MobileFiltersGrid = styled(Paper)`
  display: flex;
  padding: 1.8rem 1.8rem 1rem;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  padding: 3rem;
  margin: 1rem 0 3rem 0;
`;

export const CustomButton = styled(Button)`
  border-radius: 1rem;
  color: ${({ active }) => (active ? '#FFF' : '#424242')};
  border: 1px solid #bababa;
  width: 7rem;
  height: 3rem;
  margin-left: 1rem;
  background-color: ${({ active }) => (active ? '#424242' : '#FFF')};


  & .label {
    font-size: 1.4rem;
  }

  &:hover {
    background-color: ${({ active }) => (active ? '#424242' : '#EAEAEA')};
    color: ${({ active }) => (active ? '#FFF' : '#424242')}
  }

  @media(max-width: 991px) {
    width: 17rem;
    height: 5rem;
    font-size: 2rem;
  }
`;

export const MobileContainer = styled(Grid)`
    padding: 0rem 2.5rem 3rem 2.5rem;
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  @media(max-width: 991px) {
    justify-content: flex-start;
  }
`;
