import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Grid } from '@mui/material';
import {
  OverviewHeader, OverviewHeaderText, OverviewPaper, ContentGrid,
  HeaderBar, HeaderBarText, ContentHeaderSubText,
  OverviewHeaderDate, OverviewText, OverviewFooter, OverviewFooterText,
  OverviewFooterButtons, LabelButton, OrderButtonText,
  OrderButton, CancelButton, NavButton, NavButtonText,
  ReturnOrderButton, ReturnOrderButtonText, ArrowLeft, ArrowRight, ViewProduct
} from './orderOverview.styles';
import InvoiceDropdown from './invoiceDownloadDropdown';
import { useStateValue } from '../../../providers/stateProvider';
import { DropDown, EyeOutline } from '../../../assets/svgs';

const OrderOverview = ({
  state,
  handleOpenPrintOrderDialog,
  handleOpenPlaceOrderDialog,
  handleCancelOrderDialog,
  handleReturnOrder,
  handleBack,
  activeStep,
  handleDispatchMoreItems,
  dispatchMoreLoading,
  toggleViewProduct,
  viewProduct
}) => {
  const {
    id, dateCreated, isOrderDispatched, done, cart, freshlyClosed, orderStatus,
    trackingId, returnStatus
  } = state;
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const renderButtonText = () => {
    if (isOrderDispatched && !done) {
      return 'Close Order';
    }
    if (isOrderDispatched && done && freshlyClosed) {
      return 'Fulfil another order';
    }
    if (isOrderDispatched && done) {
      return 'Edit';
    }
    // return 'Dispatch Order';
    return 'Ready for Dispatch';
  };

  const supplierOrders = () => {
    const suppliers = _.groupBy(cart, 'supplier');
    return Object.entries(suppliers).length;
  };

  const handleOpenModel = (event) => {
    setOpenInvoiceModal(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setOpenInvoiceModal(false);
  };

  return (
    <>
      <OverviewPaper elevation={1}>
        <OverviewHeader>
          <OverviewHeaderText>
            Delivery #3456
          </OverviewHeaderText>
          <OverviewHeaderDate>
            {moment(dateCreated).format('DD/MM/YYYY')}
          </OverviewHeaderDate>
        </OverviewHeader>
        <ContentGrid item>
          <OverviewText>
            Order Overview
          </OverviewText>
          <HeaderBar container>
            <Grid item>
              <HeaderBarText>Order number:</HeaderBarText>
              <ContentHeaderSubText>
                {String(id).padStart(6, 0)}
              </ContentHeaderSubText>
            </Grid>
            {cart.length !== 0
              ? (
                <>
                  <Grid item>
                    <HeaderBarText>No. of Items</HeaderBarText>
                    <ContentHeaderSubText>
                      {cart.length}
                    </ContentHeaderSubText>
                  </Grid>
                  <Grid item>
                    <HeaderBarText>Supplier Order</HeaderBarText>
                    <ContentHeaderSubText>
                      {supplierOrders()}
                    </ContentHeaderSubText>
                  </Grid>
                </>
              ) : '' }
            <Grid item>
              <HeaderBarText>Delivery method:</HeaderBarText>
              <ContentHeaderSubText>
                Kwik Delivery
              </ContentHeaderSubText>
            </Grid>
            <Grid item>
              <HeaderBarText>Tracking ID:</HeaderBarText>
              <ContentHeaderSubText>
                {trackingId ? `kwik-00${trackingId}` : 'N/A'}
              </ContentHeaderSubText>
            </Grid>

            {(orderStatus === 'ORDER_QUEUED_FOR_DISPATCH' || orderStatus === 'ORDER_SORTED') && (
              <Grid item>
                <HeaderBarText>&nbsp;</HeaderBarText>
                <ViewProduct onClick={toggleViewProduct}>
                  <EyeOutline style={{ marginRight: '.5rem', width: '1.3rem' }} />
                  View Products
                </ViewProduct>
              </Grid>
            )}
          </HeaderBar>
          {/* <ContentDivider style={{ marginTop: '1.5rem' }} /> */}
        </ContentGrid>
      </OverviewPaper>
      {!viewProduct && (
        <Grid container>
          <OverviewFooter container sm={12} md={12}>
            <CancelButton
              onClick={(e) => handleCancelOrderDialog(e)}
              disabled={!userPermissions?.includes('manage_orders_cancel_order') || orderStatus === 'ORDER_DISPATCHED' || done}
              md={3}
            >
              <OverviewFooterText>
                Cancel Order
              </OverviewFooterText>
              <DropDown style={{ fontSize: '.9rem' }} />
            </CancelButton>
            <OverviewFooterButtons container item md={9} xs={12}>
              {isOrderDispatched ? (
                <>
                  <OrderButton
                    onClick={handleDispatchMoreItems}
                    disabled={orderStatus === 'ORDER_QUEUED_FOR_DISPATCH' || dispatchMoreLoading}
                    style={{ marginRight: '1.5rem' }}
                  >
                    <OrderButtonText>{dispatchMoreLoading ? 'loading...' : 'Dispatch more Items'}</OrderButtonText>
                  </OrderButton>
                  <ReturnOrderButton
                    onClick={handleReturnOrder}
                    disabled={orderStatus === 'ORDER_QUEUED_FOR_DISPATCH' || returnStatus}
                  >
                    <ReturnOrderButtonText>Arrange Return</ReturnOrderButtonText>
                  </ReturnOrderButton>
                  <LabelButton
                    onClick={(event) => handleOpenModel(event)}
                    disabled={!isOrderDispatched || orderStatus === 'ORDER_QUEUED_FOR_DISPATCH'}
                  >
                    <OrderButtonText>Print Invoice</OrderButtonText>
                  </LabelButton>
                </>
              ) : ''}
              <OrderButton
                onClick={() => handleOpenPlaceOrderDialog()}
                disabled={orderStatus === 'ORDER_QUEUED_FOR_DISPATCH'
                  || !userPermissions?.includes('manage_orders_ready_for_dispatch')}
                style={{ marginLeft: '1.5rem' }}
              >
                {/* <Hidden mdDown>
                  <PlaceOrderIcon />
                </Hidden> */}
                <OrderButtonText>{renderButtonText()}</OrderButtonText>
              </OrderButton>
            </OverviewFooterButtons>
          </OverviewFooter>
        </Grid>
      )}
      {!viewProduct && (
        <Grid container justifyContent="flex-end">
          <Grid container item lg={2} md={3} xs={5} justifyContent="space-between">
            <NavButton onClick={handleBack} disabled={activeStep === 2}>
              <ArrowLeft />
              <NavButtonText>Previous</NavButtonText>
            </NavButton>
            <NavButton disabled>
              <NavButtonText>Next</NavButtonText>
              <ArrowRight />
            </NavButton>
          </Grid>
        </Grid>
      )}
      <InvoiceDropdown
        openInvoiceDialog={openInvoiceModal}
        anchorEl={anchorEl}
        handleOpenPrintOrderDialog={handleOpenPrintOrderDialog}
        handleCloseModal={handleCloseModal}
        orderId={id}
      />
    </>
  );
};

OrderOverview.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  handleOpenPrintOrderDialog: PropTypes.func.isRequired,
  handleOpenPlaceOrderDialog: PropTypes.func.isRequired,
  handleCancelOrderDialog: PropTypes.func.isRequired,
  handleDispatchMoreItems: PropTypes.func.isRequired,
  handleReturnOrder: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  dispatchMoreLoading: PropTypes.bool.isRequired,
  toggleViewProduct: PropTypes.func.isRequired,
  viewProduct: PropTypes.bool.isRequired,
};

export default OrderOverview;
