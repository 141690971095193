import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Button,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { DivFlex, MenuItemStyled } from './actionButtonsPopper.style';
import { useStateValue } from '../../providers/stateProvider';

const ActionButtonsPopper = ({ handleAction }) => {
  const [{
    user: { allowedPermissionsMap: { userPermissions } },
  },] = Object.values(useStateValue());
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <DivFlex>
        <Button
          variant="contained"
          style={{ width: '178px' }}
          onClick={handleToggle}
          ref={anchorRef}
          disabled={!userPermissions?.includes('customers_add_customer')}
        >
          Add Customer
          <ExpandMore />
        </Button>
      </DivFlex>
      <Popper
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled
                    onClick={() => handleAction('add_individually', setOpen)}
                  >
                    Add Individually
                  </MenuItemStyled>
                  <MenuItemStyled
                    onClick={() => handleAction('add_by_csv', setOpen)}
                  >
                    Add by CSV
                  </MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

ActionButtonsPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  createBatchHandler: PropTypes.func.isRequired,
};

export default ActionButtonsPopper;
