import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../../../../providers/stateProvider';
import { SummaryPlaceHolder } from '../../../shared/summaryPlaceholder';
import { KeyboardArrow, MenuButton, TextWrapper } from '../../newOrder/newOrderContainer.styles';
import { PrimaryTitle, SecondaryTitle } from '../../shared/titleGrid.styles';
import {
  ArrowButton,
  ArrowLeftIcon,
  ArrowRightIcon,
  StatisticsGridContainer,
  StatisticsGridWrapper
} from '../order.styles';
import StatisticCard from '../../shared/statisticCard';
import currencyFormatter from '../../../shared/currencyFormatter';
import { PageTitleSubText, PageTitleText } from '../../../cart/cart.styles';

function SummaryController(props) {
  const {
    statistics, querySchema, queryName, primaryTitle, secondaryTitle,
    extraCharts, backRoute, allowChainManager, filterOption
  } = props;
  const [disabledButton, setDisabledButton] = useState(false);
  const navigate = useNavigate();
  const totalsContainer = useRef(null);
  const element = totalsContainer.current;
  const [{
    navbar: { openSideDrawer },
    business: {
      businessId, businessUser:
      businessUserId,
      dashboardState: {
        active,
        startDate,
        endDate,
      }
    }
  }] = Object.values(useStateValue());

  const scrollLeft = () => {
    element.style.transform = 'translateX(0px)';
    setDisabledButton(!disabledButton);
  };
  const variables = {
    dateFrom: startDate,
    dateTo: endDate,
    period: active
  };
  if (allowChainManager && businessId) {
    variables.businesses = [businessId];
  }
  if (allowChainManager && businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  if (filterOption) {
    let option = false;
    if (filterOption === 'With returns') {
      option = true;
    }
    variables.filterOption = option;
  }
  const {
    data, loading, error
  } = useQuery(querySchema, {
    fetchPolicy: 'no-cache',
    variables
  });
  if (loading) {
    return (
      <SummaryPlaceHolder
        primaryTitle={primaryTitle}
        secondaryTitle={secondaryTitle}
      />
    );
  }
  if (error) return `Error! ${error.message}`;
  const dashboardSummary = data[queryName];
  const scrollRight = () => {
    const containerWidth = statistics.length * 269;
    const translateX = containerWidth - element.clientWidth;
    element.style.transform = `translateX(-${translateX}px)`;
    setDisabledButton(!disabledButton);
  };
  const getStatistics = (statistic) => {
    const outputStatistic = {};
    outputStatistic.value = dashboardSummary[statistic.value];
    // eslint-disable-next-line no-prototype-builtins
    if (statistic.hasOwnProperty('type') && statistic.type === 'amount') {
      outputStatistic.value = currencyFormatter(Number(dashboardSummary[statistic.value]));
    }
    // eslint-disable-next-line no-prototype-builtins
    if (statistic.hasOwnProperty('diff') && statistic.diff) {
      outputStatistic.diff = `${dashboardSummary[statistic.diff]}`;
    }
    return { ...statistic, ...outputStatistic };
  };
  return (
    <>
      <Grid container justifyContent="space-between">
        {backRoute && (
        <Grid item xs={12} md={5} lg={6}>
          <Grid container>
            <MenuButton onClick={() => navigate(`/${backRoute}`)}>
              <KeyboardArrow />
            </MenuButton>
            <Grid style={{ marginLeft: 15 }}>
              <PageTitleText>{primaryTitle}</PageTitleText>
              <PageTitleSubText>{secondaryTitle}</PageTitleSubText>
            </Grid>
          </Grid>
        </Grid>
        )}
        {!backRoute && (
        <TextWrapper item xs={12} md={5} lg={6}>
          <PrimaryTitle variant="h5">{primaryTitle}</PrimaryTitle>
          <SecondaryTitle>
            {secondaryTitle}
          </SecondaryTitle>
        </TextWrapper>
        )}
        {/* <DatePicker item container md={7} lg={6}>
          <DashboardDateRanges
            active={active}
            range={range}
            handleDateButtons={handleDateButtons}
            handleIconDateRange={handleIconDateRange}
            xs={12}
          />
        </DatePicker> */}
      </Grid>
      <Grid item container xs={12} wrap="nowrap" alignItems="center">
        <ArrowButton
          disabled={!disabledButton}
          onClick={scrollLeft}
          component="span"
          show={openSideDrawer}
        >
          <ArrowLeftIcon />
        </ArrowButton>
        <StatisticsGridWrapper item container>
          <StatisticsGridContainer
            ref={totalsContainer}
            container
            item
            wrap="nowrap"
            justifyContent="space-between"
          >
            {statistics.map((statistic) => (
              <StatisticCard
                key={statistic.id}
                statistic={getStatistics(statistic)}
                admin
              />
            ))}
          </StatisticsGridContainer>
        </StatisticsGridWrapper>
        <ArrowButton
          disabled={disabledButton}
          onClick={scrollRight}
          component="span"
          show={openSideDrawer}
        >
          <ArrowRightIcon />
        </ArrowButton>
      </Grid>
      {extraCharts(dashboardSummary, startDate, endDate)}
    </>
  );
}
SummaryController.propTypes = {
  statistics: PropTypes.instanceOf(Array).isRequired,
  querySchema: PropTypes.string.isRequired,
  queryName: PropTypes.string,
  primaryTitle: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string.isRequired,
  backRoute: PropTypes.string,
  filterOption: PropTypes.string,
  allowChainManager: PropTypes.bool,
  extraCharts: PropTypes.func
};
SummaryController.defaultProps = {
  queryName: 'dashboardSummary',
  backRoute: null,
  filterOption: '',
  allowChainManager: false,
  extraCharts: () => <Grid />
};
export default SummaryController;
