import {
  DialogContent, Dialog, Typography, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloseNewIcon } from '../../../assets/svgs';

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    box-shadow: none;
    border-radius: 12px;
  }
`;

export const CloseStyled = styled(CloseNewIcon)`
  cursor: pointer;
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 500px;
  height: 400px;
  padding: 1.5rem;
`;

export const HeaderText = styled(Typography)`
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
`;

export const Description = styled(Typography)`
  color: #858383;
  font-size: 12px;
  margin-top: 0.5rem;
`;

export const ContentHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterStyled = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;

export const FormStyled = styled('div')`
  margin-top: 1.5rem;
  width: 100%;
`;
