import { gql } from '@apollo/client';

export const CHAIN_MANAGER_DASHBOARD = gql`
  query chainManagerDashboard(
    $pageCount: Int, $search: String,
    $pageNumber: Int, $dateFrom: DateTime!, $dateTo: DateTime!
  ){
    chainManagerDashboard(
      pageCount: $pageCount
      pageNumber: $pageNumber
      dateFrom: $dateFrom
      dateTo: $dateTo
      search: $search
    ){
      storeName
      totalSales
      numberOfSales
      grossMargin
      cashSales
      bankTransferSales
      creditSales
      cardSales
    }
    chainManagerDashboardTotalNumber
  }
`;

export const STORE_SALE_ANALYTICS_GRAPH = gql`
  query storesSaleAnalyticsGraph(
    $filterBy: String, $businessIds: [Int]
  ){
    storesSaleAnalyticsGraph(
      filterBy:$filterBy, businessIds:$businessIds
    ){
      totalSalesValueVsCostOfGoodsSoldGraph
      totalSalesByCountGraph
      totalSalesByMarginGraph
    }
  }
`;
