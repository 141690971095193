import {
  InputBase, Typography, Divider, Button
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { styled } from '@mui/material/styles';

export const CInputBase = styled(InputBase)`
  border: ${({ error }) => (error ? '1.3px solid #BA1A1A' : '1.3px solid #E7E8E9')};
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : '')};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  line-height: 3.2;
  @media(max-width: 991px) {
    font-size: 14px;
  }


  & .MuiInputBase-input {
    padding: ${({ cSize }) => (cSize === 'lg' ? '16px' : '12px')};
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 8px;
    margin-left: 8px;
  }

  & .MuiSvgIcon-root {
    color: #606060;
  }

  &:active, &:focus, &:focus-within {
    border: ${({ error }) => (error ? '1.3px solid #BA1A1A' : '1.3px solid #78AADA')};

    & .MuiSvgIcon-root {
      color: #78AADA;
    }
  }
`;

export const Label = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ error }) => (error ? '#BA1A1A' : '#606060')};
  margin-bottom: .2rem;
  @media(max-width: 991px) {
    font-size: 14px;
  }
`;

export const HelperText = styled(Typography)`
  font-size: 12px;
  color: #ff0000;
  opacity: 0.8;
  margin-left: 10px;
`;

export const LightDivider = styled(Divider)`
  background-color: #D6D6D6;

  @media(min-width: 992px) {
    background-color: #F0F0F0;
  }
`;

export const DivLine = styled('div')`
  border-left: 1px solid #DEDEDE;
  height: 30px;
  margin-right: 1rem;
`;

export const CButton = styled(Button)`
  padding: 0;
  color: #303030;
  font-size: 12px;
  font-weight: 400;

  &:hover {
    background-color: transparent;
  }
`;

export const ArrowDownIcon = styled(KeyboardArrowDownIcon)`
  font-size: 22px;
  margin-left: 5px;
`;

export const ArrowUpIcon = styled(KeyboardArrowUpIcon)`
  font-size: 22px;
  margin-left: 5px;
`;
