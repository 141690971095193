import {
  Typography, Grid, ButtonGroup, Button, TextField, Box,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DetailGrid = styled(Grid)`
    height: 450px;
    margin-top: 20px;
    box-shadow: 0px 2.13419px 48.0481px rgba(163, 171, 185, 0.12);
    border-radius: 15px;

    @media (max-width: 991px) {
        height: 100%;
        border-radius: 15px;
        margin-top: 40px;
    }
`;

export const DetailContentGrid = styled(Grid)`
    height: 100%;
    width: 100%;
    padding: 40px;
    @media (max-width: 991px) {
      padding-bottom: 10px;
  }
`;

export const ContentGrid = styled(Grid)`
    height: 100%;
    width: 100%;
    padding: 20px;
`;

export const ImgLogo = styled('img')`
    width: 50%;
    display: block;
    margin: auto;

`;

export const DetailHeader = styled(Typography)`
    color: #787885;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;

    @media (max-width: 991px) {
        font-size: 30px;
        font-weight: 400;
    }
`;

export const DetailTypo = styled(Typography)`
    color: #394359;
    font-weight: 650;
    font-size: 20px;

    @media (max-width: 991px) {
        font-size: 37px;
        font-weight: 650;
    }
`;

export const QuantityGrid = styled(Grid)`
  width: 95%;
@media (max-width: 991px) {
  width: 100%;
}
`;

export const QuantityContentGrid = styled(Grid)`
`;

export const InputButtonGroup = styled(ButtonGroup)`
    border: none;
    margin-left: -5px;
    margin-top: 5px;
`;

export const ActionButton = styled(Button)`
    border: none;

    &:disabled {
        border: none;
    }

    @media (min-width: 992px) {
        &.MuiButtonGroup-grouped {
            min-width: 20px;
        }

        &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
            margin-left: -15px;
        }
    }

    @media (max-width: 991px) {
        &.MuiButtonGroup-grouped {
            min-width: 30px;
        }

        &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
            margin-left: -15px;
        }
    }
`;

export const ActionTextField = styled(TextField)`
    & ::placeholder {
        color: #000;
        text-align: center;
    }

    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
        font-size: 1.2em;
        width: 1.2rem;
        text-align: center;
        color: #000;
    }

    @media (min-width: 992px) {
        margin-left: 10px;
    }

    @media (max-width: 991px) {
        & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
            font-size: 1.9em;
            width: 5rem;
            text-align: center;
            color: #000;
        }
    }
`;

export const ActionImg = styled('img')`
    width: 20px;

    @media (max-width: 991px) {
        width: 50px;
    }
`;

export const Content = styled(Box)`
    padding: 20px;
    position: relative;
`;

export const AddButton = styled(Button)`
    border-radius: 8px;
    background-color: #333333;
    margin-top: 20px;
    color: #fff;
    height: 40px;

    &:hover {
        background-color: #333333;
        color: #fff;
    }


    &.MuiButton-contained.Mui-disabled {
        color: #fff;
        opacity: 0.5;
    }

    @media(min-width: 992px) {
        border-radius: 4px;
    }

    @media (max-width: 991px) {
        height: 120px;
    }
`;

export const AddCartTypo = styled(Typography)`
    font-size: .9rem;

    @media (max-width: 991px) {
        font-size: 2rem;
    }
`;

export const ShoppingCartImg = styled('img')`
    width: 15px;

    @media (max-width: 991px) {
        width: 25px;
    }
`;

export const CircularProgressLoader = styled(CircularProgress)`
    color: #C5C5C5;
`;

export const Typo = styled(Typography)`
    font-size: 30px;
    font-weight: 650;
    margin-top: 5px;

    @media (max-width: 991px) {
        font-size: 50px;
        font-weight: 700;
    }
`;

export const ProductVariantText = styled('p')`
  font-size: 2rem;
  text-align: right;
  margin: 0;
  color: #000000;
  padding: 1.5rem 2rem;
  position: absolute;
  right: 2rem;
  top: 2rem;
  border-radius: 5px;
  background-color: #EEE900;

  @media(min-width: 992px) {
    font-size: 1rem;
    padding: .4rem 1rem;
  }
`;
