import {
  DialogContent, Dialog, Button, Typography,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 0.75rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 650px;
  height: 25rem !important;
  overflow: auto;
  padding-bottom: 1rem;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  color: #235A91;
  margin: 1rem 0 0 0;
`;

export const Description = styled(Typography)`
  color: #606060;
  font-size: 14px;
  font-weight: 400;
`;

export const ButtonBorder = styled(Button)`
  border: 1px solid #235a91;
  min-width: 6.5rem;
  height: 3rem;
  margin-top: 14px;
  font-size: 13px;
  border-radius: 8px;
`;

export const EmptyFlex = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #606060;
`;

export const EmptyImg = styled('img')`
  width: 6rem;
`;

export const RowContainer = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #E7E8E9;
`;

export const RowTitle = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  color: #303030;
`;
