import { gql } from '@apollo/client';
import { PRODUCT_VERSIONS } from './products';

export const SINGLE_ORDER_QUERY = gql`
  query($orderId: Int!) {
    businessOrder(
      orderId: $orderId
    ){
      id,
      status,
      dateCreated,
      business{
        id
        name,
        logo
      }
      deliveryOutlet{
        id
        name
        contacts
      }
      paymentSummary,
      meta,
      supplierorderSet {
        id
        meta
        orderproductSet {
          id
          name
          price
          unitCost
          quantity
          quantityRecieved
          savings
          supplierOrder {
            supplier {
              name
              settings
            }
          }
          product {
            meta
            price
          }
        }
      }
    }
  }
`;

export const ORDER_QUERY = gql`
  ${PRODUCT_VERSIONS}
  query($orderId: Int!) {
    orderDeliveryLocations(
      orderId: $orderId
    ){
      id
      name
      contacts
    }

    businessOrder(
      orderId: $orderId
    ){
      id,
      status,
      metaStatus
      dateCreated,
      returned,
      business{
        id
        name,
        logo
      }
      paymentSummary,
      meta,
      logisticsId,
      supplierorderSet {
        id
        meta
        orderproductSet {
          id
          name
          price
          unitCost
          quantity
          quantityRecieved
          returned
          reasonForReturn
          savings
          meta
          quantityConfirmed
          supplierOrder {
            status
            supplier {
              name
              settings
            }
          }
          product {
            meta
            batches{
              id
              batchNo
              quantityRecieved
              orderCost
              dateRecieved
              expiryDate
              meta
              quantityInStock
            }
            ...productVersions
          }
        }
      }
    }
  }
`;
