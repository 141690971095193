import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Button, DialogContent
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomInputBase from '../../../../customComponents/customInputBase';
import CustomSelectInputBase from '../../../../customComponents/customSelectInputBase';
import NotificationDialog from '../../../../shared/notificationDialog';
import {
  CDialog, DialogTitleText, CircularProgressLoader, GridWrapper, CloseIcon
} from './createUserDialog.styles';
import { CREATE_OUTLET_USER } from '../../../../../mutations/outlets';
import { ALL_BUSINESS_ROLES_QUERY } from '../../../../../queries/businesses';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateUserDialog = ({
  open, close, refetch
}) => {
  const initialState = {
    fullName: '', email: '', role: '', roleId: ''
  };

  const [state, setState] = useState(initialState);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const [createOutletUser, { loading }] = useMutation(CREATE_OUTLET_USER);
  const {
    fullName, email, role, roleId
  } = state;

  const roleVariables = {
    pageCount: 50,
    pageNumber: 1,
  };

  const { data } = useQuery(ALL_BUSINESS_ROLES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: roleVariables
  });

  const {
    allSystemRoles = [],
  } = data || {};

  const fields = [
    {
      name: 'fullName', label: 'Username', helperText: 'Username required', placeholder: 'Enter Username'
    },
    { name: 'email', label: 'User Email Address', placeholder: 'Enter User Email Address' },
    {
      name: 'role',
      label: 'Role',
      placeholder: 'Select Role',
      options: allSystemRoles
    }
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'fullName');
    if (name === 'role') {
      const field = allSystemRoles.find((val) => val.name === value);
      setState((s) => ({ ...s, roleId: field.id }));
    }
    setState((s) => ({ ...s, [name]: value }));
  };

  const formValidation = () => {
    const emailValidation = ((((((((((((((((((((((((((((((((((((((((((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)))))))))))))))))))))))))))))))))))))))))).test(email);
    if (!fullName || fullName.length < 3) {
      toast.error("User's Full Name is required");
      return false;
    }
    if (!email || !emailValidation) {
      toast.error('Enter a valid Email Address');
      return false;
    }
    if (!roleId) {
      toast.error('Select Role');
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!formValidation()) return;
    const variables = { fullName, email, roleId };
    createOutletUser({ variables })
      .then(() => {
        setOpenSuccessDialog(true);
        refetch();
        close();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const handleCloseDialog = () => {
    setState(initialState);
    close();
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, placeholder, helperText
    } = field;
    const value = state[fieldName];
    if (fieldName === 'role') {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          placeholder={placeholder}
          handleChange={handleChange}
          showCheckBox={false}
        />
      );
    }

    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type="text"
        placeholder={placeholder}
        name={fieldName}
        onChange={handleChange}
        cSize="lg"
      />
    );
  };

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={6}>
            <DialogTitleText container item xs={12}>
              Create User
            </DialogTitleText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={3}>
            {fields.map((field) => (
              <Grid item key={field?.name} xs={12}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{ width: '11rem', height: '3.2rem', marginRight: '1.2rem' }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ width: '11rem', height: '3.2rem' }}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : 'Create User' }
          </Button>
        </DialogActions>
      </GridWrapper>
      <NotificationDialog
        openDialog={openSuccessDialog}
        setOpenDialog={setOpenSuccessDialog}
        action="Ok"
        title="User Created!"
        desc={`Hi Pharm, ${role} role has been assigned to ${fullName}`}
        actionFunc={() => setState(initialState)}
      />
    </CDialog>
  );
};

CreateUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default CreateUserDialog;
