import React from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemButton, Typography, Fade, ClickAwayListener
} from '@mui/material';

import {
  CListItemText, TextGrid, CPopper, PaperWrapper
} from './cartDropdown.styles';

const CartDropdown = ({ options, dotsButtonEl, setDotsButtonEl }) => {
  const open = Boolean(dotsButtonEl);

  return (
    <CPopper
      open={open}
      anchorEl={dotsButtonEl}
      transition
      disablePortal
      placement="left-start"
    >
      {({ TransitionProps, placement }) => (
        <Fade {...TransitionProps}>
          <PaperWrapper>
            <ClickAwayListener onClickAway={() => setDotsButtonEl(null)}>
              <List style={{ width: '100%', padding: 0 }}>
                {options.map(({ name, action, disabled }) => (
                  <ListItem
                    disableGutters
                    dense
                    style={{ padding: '.5rem 1.5rem' }}
                    key={name}
                  >
                    <ListItemButton
                      onClick={action}
                      disabled={disabled}
                    >
                      <CListItemText
                        primary={(
                          <TextGrid container item>
                            <Typography
                              variant="caption"
                              noWrap
                              style={{ fontSize: '1rem', color: '#606060' }}
                            >
                              {name}
                            </Typography>
                          </TextGrid>
                      )}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </ClickAwayListener>
          </PaperWrapper>
        </Fade>
      )}
    </CPopper>
  );
};

CartDropdown.propTypes = {
  dotsButtonEl: PropTypes.shape(Object),
  setDotsButtonEl: PropTypes.func.isRequired,
  options: PropTypes.shape(Array),
};

CartDropdown.defaultProps = {
  dotsButtonEl: {},
  options: [],
};

export default CartDropdown;
