import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { FilterList, ExpandMore } from '@mui/icons-material';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';
import DashboardInfo from './dashboardInfo';
import MainContent from '../../customComponents/mainContent';
import { IconsGridContainer } from '../../affiliates/affiliates.styles';
import { useStateValue } from '../../../providers/stateProvider';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  BarChart, MoneyStack, LoyaltyMoney, HandMoney, CashTiles, BankExchange, CardTimer, CreditCard
} from '../../../assets/svgs';
import { EXPORT_MUTATION } from '../../../mutations/reports';
import StatisticsComponent from './statisticsComponent';
import { GET_SALES_DASHBOARD_SUMMARY } from '../../../queries/sales';
import ToggleBusiness from '../../shared/busnesss';
import SalesFilterPopper from './salesFilterPopper';
import { OgaToken } from '../../../graphql/token';
import {
  GridContainer, PerformanceTab, StyledCustomButton, WrapperPaper, HeaderText,
  SearchGrid, FilterText
} from './dashboard.styles';
import CustomExportButton from '../../downloads/exportModule/exportComponent';
import CsvPopper from './csvPopper';

const DashboardContainer = () => {
  const initialState = {
    filterOption: '',
    selectedOptionFilter: new Map(),
  };

  const [state, setState] = useState(initialState);
  const [searchText, setSearchText] = useState('');
  const [refreshCount, setRefreshCount] = useState(0);
  const [filterButton, setFilterButton] = useState(null);
  const [filterToggle, setFilterToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const handlePopper = (event) => {
    setOpen(open ? null : event.currentTarget);
  };

  const [{
    user: { businessId },
    business: {
      dashboardState: { startDate, endDate }
    }
  }, dispatch] = Object.values(useStateValue());

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  };

  const variable = {
    dateFrom: moment(startDate).format('YYYY-M-DD'),
    dateTo: moment(endDate).format('YYYY-M-DD')
  };

  const handleFilterButtonClick = (event) => {
    setFilterButton(filterButton ? null : event.currentTarget);
  };

  // const handleDownloadCSV = () => {
  //   const sd = moment(startDate).format('YYYY-M-DD');
  //   const ed = moment(endDate).format('YYYY-M-DD');
  //   const filterParam = filterToggle ? `&filter_option=${filterToggle}` : '';
  //   window.open(
  //     `${OgaToken.SERVER_LINK}download_sales_history_csv/?date_from=${sd}&date_to=${ed}&business_id=${businessId}${filterParam}`,
  //     '_blank'
  //   );
  // };

  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const handleDownloadCSV = (type) => {
    const randomNum = Math.floor(Math.random() * 10000);
    const newFileName = `Sales Report ${randomNum}`;
    const { dateFrom, dateTo } = variable;
    exportMutation({
      variables: {
        name: newFileName,
        type,
        dateFrom,
        dateTo
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
        handlePopper();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const { filterOption, selectedOptionFilter } = state;

  const handleOptionToggle = (option) => {
    const filterSelectedOption = [];
    setFilterToggle(true);
    if (option === 'With return') {
      selectedOptionFilter.delete('All');
    } else {
      selectedOptionFilter.clear();
    }
    if (selectedOptionFilter.get(option)) {
      selectedOptionFilter.delete(option);
    } else {
      selectedOptionFilter.set(option, option);
    }
    selectedOptionFilter.forEach((prov) => filterSelectedOption.push(prov));
    setState({ ...state, selectedOptionFilter, filterOption: filterSelectedOption[0] });
  };

  const statistics = [{
    id: 1,
    icon: BarChart,
    title: 'No of Sales',
    size: 3,
    value: 'totalSales'
  }, {
    id: 2,
    icon: CashTiles,
    title: 'Total Sale Amount',
    size: 3,
    value: 'totalSaleAmount',
    type: 'amount'
  }, {
    id: 3,
    icon: HandMoney,
    title: 'Total Excess Change',
    size: 3,
    value: 'totalExcessChange',
    type: 'amount'
  }, {
    id: 4,
    icon: LoyaltyMoney,
    title: 'Loyalty Sales',
    size: 3,
    value: 'salesInLoyalty',
    type: 'amount'
  }, {
    id: 5,
    icon: MoneyStack,
    title: 'Cash Sales',
    size: 3,
    value: 'salesInCash',
    type: 'amount'
  }, {
    id: 6,
    icon: BankExchange,
    title: 'Bank Transfer Sales',
    size: 3,
    value: 'salesInBankTransfer',
    type: 'amount'
  }, {
    id: 7,
    icon: CardTimer,
    title: 'Credit Sales',
    size: 3,
    value: 'salesInCredit',
    type: 'amount'
  }, {
    id: 8,
    icon: CreditCard,
    title: 'Card Sales',
    size: 3,
    value: 'salesInCard',
    type: 'amount'
  }];
  return (
    <MainContent>
      <Grid item container xs>
        <GridContainer container>
          <StatisticsComponent
            allowChainManager
            statistics={statistics}
            querySchema={GET_SALES_DASHBOARD_SUMMARY}
            filterOption={filterOption}
            queryName="salesDashboardSummary"
            primaryTitle="Sales History"
            secondaryTitle="View sales record"
            backRoute="affiliate-dashboard"
          />
          <WrapperPaper>
            <PerformanceTab container alignItems="center">
              <Grid item>
                <HeaderText>Sales Performance</HeaderText>
              </Grid>
              <IconsGridContainer item container style={{ width: '13%' }}>
                <Button
                  variant="contained"
                  style={{ width: '100%', height: '2.7rem' }}
                  onClick={handlePopper}
                  ref={anchorRef}
                >
                  Download CSV
                  <ExpandMore />
                </Button>
              </IconsGridContainer>
              <ToggleBusiness />
              <SearchGrid item container justifyContent="space-between">
                <Grid item container style={{ width: '92%' }}>
                  <CustomSearchField
                    name="search"
                    value={searchText}
                    fullWidth
                    placeholder="Search"
                    handleChange={(e) => setSearchText(e?.target?.value)}
                  />
                </Grid>
                <Button
                  variant="outlined"
                  style={{ width: '7.5%', height: '2.95rem' }}
                  onClick={handleFilterButtonClick}
                >
                  <FilterList style={{ marginRight: '.4rem' }} />
                  Filter
                </Button>
              </SearchGrid>
            </PerformanceTab>
            <DashboardInfo
              searchText={searchText}
              filterOption={filterOption}
              refreshCount={refreshCount}
              handleRefreshCount={() => setRefreshCount(refreshCount + 1)}
            />

            <SalesFilterPopper
              filterButton={filterButton}
              handleOptionToggle={handleOptionToggle}
              handleTriggerButtonClick={handleFilterButtonClick}
              selectedOptionFilter={selectedOptionFilter}
              filterToggle={filterToggle}
            />
            <CsvPopper action={open} close={handlePopper} handleDownload={handleDownloadCSV} />
          </WrapperPaper>
        </GridContainer>
      </Grid>
    </MainContent>
  );
};

export default DashboardContainer;
