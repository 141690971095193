import { Grid, MenuItem, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from '../../customComponents/customButton';

export const FiltersGrid = styled(Grid)`
  margin: 20px 0 5px 0;
  padding-bottom: 1rem;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const ButtonsGrid = styled(Grid)`
  padding: 0 1.8rem 1.8rem;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0 1.8rem 1.8rem;
  }
`;

export const SetFilter = styled(CustomButton)`
  width: 6.5rem;
  height: 2.95rem;
  border-radius: 5px;
  background: #303030;
  margin-left: 8px;
`;

export const FilterContainer = styled(Grid)`
  margin-top: -20px;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 2rem 0.9rem 1rem;
  font-size: 15px;

  &:hover {
    background: #f3f9ff;
  }
  &:active {
    background: #f3f9ff !important;
  }
`;

export const ButtonOutlined = styled(Button)`
  font-size: 13px;
  font-weight: 700;
  border: 1.5px solid #235A91;
  border-radius:  7px;
  white-space: nowrap;
  height: 2.9rem;
  padding: 0 2rem;
  display:flex;
  align-items: center;
  gap: 4px;
`;
