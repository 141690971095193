import { Version } from './Version';
import { ErpVersion } from './ErpVersion';
import { MarketplaceVersion } from './MarketplaceVersion';

export class Product extends Version {
  id = '';

  meta = {};

  quantityInStock = 0;

  productRef = '';

  businessId = '';

  promo = [];

  category = '';

  erpVersion = new ErpVersion({});

  marketplaceVersion = new MarketplaceVersion({});

  version = { ...(new MarketplaceVersion()), ...(new ErpVersion()) };

  constructor(rawVersion = {}, platform = 'marketplace') {
    super();
    this.copyToThis(rawVersion);
    this.erpVersion = new ErpVersion(rawVersion.erpVersion);
    this.marketplaceVersion = new MarketplaceVersion(rawVersion.marketplaceVersion);
    if (platform === 'erp') {
      this.copyToThis(this.erpVersion);
    } else {
      this.copyToThis(this.marketplaceVersion);
    }
  }

  copyToThis(obj) {
    const keys = Object.keys(obj);
    for (let key = 0; key < keys.length; key++) {
      // eslint-disable-next-line no-prototype-builtins
      this[keys[key]] = obj[keys[key]];
    }
  }
}
