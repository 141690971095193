import {
  DialogContent, Dialog, Button, Typography, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConnectMpStoreIcon } from '../../../assets/svgs';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 0.75rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 650px;
  max-height: 30rem;
  overflow: hidden;
  height: auto;
  padding-bottom: 4rem;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  color: #303030;
  margin: 1rem 0 0 0;
`;

export const Description = styled(Typography)`
  color: #858383;
  font-size: 13px;
`;

export const ButtonBorder = styled(Button)`
  border: 1px solid #235a91;
  min-width: 6.5rem;
  height: 3rem;
  margin-top: 14px;
  font-size: 13px;
  border-radius: 8px;
`;

export const RowContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e7e8e9;
`;

export const RowTitle = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  color: #303030;
`;

export const ConnectedMPIcon = styled(ConnectMpStoreIcon)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 2.8rem;
  }
`;

export const RadioButton = styled('input')`
  width: 1rem;
  height: 1rem;
  color: #235a91;
  
  // appearance: none;
  // position: relative;
  // border-radius: 50%;
  // border: 1px solid #235A91;
  // background: #fff;
  // cursor: pointer;
  // outline: none;

  // &:checked {
  //   background: #235A91;
  //   border-color: #235A91;
  // }

  // &:checked::after {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 1rem;
  //   height: 1rem;
  //   border-radius: 50%;
  //   transform: translate(-50%, -50%);
  // }
`;
