import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Grid, useMediaQuery, Box, Button
} from '@mui/material';
import moment from 'moment';
import downvote from '../../../assets/images/marketOrder/Downvote.png';
import upvote from '../../../assets/images/marketOrder/Upvote.png';
import cart from '../../../assets/images/marketOrder/cartImg.png';
import timer from '../../../assets/images/marketOrder/timer.png';
import {
  CardPaper, ProductImg, ImgBox, DetailsBox, HeaderContainer,
  DetailsContainer, ActionsContainer, PromoPriceText,
  HeaderText, TypoContainer, PackSizeTypo, TimeText, TimeBox, TimeImg,
  PricingAndSaving, PricingWrapper, SavingsWrapper, PricingTypo,
  Pricing, SavingPricing,
  CircularProgressLoader, ActionTextField, InputButtonGroup,
  AddCartGrid, ShoppingCartImg, ActionImg, PromoContainer, PromoText,
  AddCartTypo, ChangeBox, ProductVariantText, ProductVContainer, ChangeWrapper
} from './productCard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { UPDATE_ORDER_MUTATION } from '../../../mutations/orders';
import { useStateValue } from '../../../providers/stateProvider';
import { Product } from '../../../providers/reducers/product/classes/Product';

const ProductCard = ({
  product, openAlert, cart: cartProduct, similarAction, index
}) => {
  const [{
    cart: { isManualOrder }, user: { platform },
    affiliate: { pricePolicyFactor = 1 }
  }, dispatch] = Object.values(useStateValue());
  const preferredPlatform = platform;

  const navigate = useNavigate();
  const {
    id, brandName, meta, quantityInStock,
    resolvedPriceInUseValue: actualPrice, packSize, image, orderCost,
    promoEndDate, mktPrice, promoValue, promoCondition
  } = new Product(product, preferredPlatform) || {};
  const obj = JSON.parse(meta);
  const {
    savings,
  } = obj || {};

  const [counter, setCounter] = useState(0);
  const [countdown, setCountdown] = useState('');

  const [updateOrder, { loading }] = useMutation(UPDATE_ORDER_MUTATION);

  const handleActionButtons = (value) => {
    setCounter(counter + value);
  };
  const handleChange = (value) => {
    setCounter(value);
  };

  let itemPrice = 0;
  if (preferredPlatform === 'erp') {
    itemPrice = orderCost;
  } else {
    itemPrice = actualPrice * pricePolicyFactor;
  }
  const isSmall = useMediaQuery('(max-width: 991px)');
  const qtyCount = !quantityInStock;
  const promoStatus = promoCondition === 'ONGOING' && true;

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        productId: id,
        quantity: counter,
        isManualOrder
      }
    })
      .then(({ data }) => {
        const { message, order } = data?.updateOrderProduct || {};
        dispatch({
          type: CartActionTypes.CART_COUNTER,
          payload: order?.supplierorderSet
        });
        openAlert(message);
      });
  };

  const handleClick = () => {
    navigate(`/new-order/${id}/details`);
  };
  const parsedDate = moment(promoEndDate);

  function formatDuration(duration) {
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${days}days - ${hours}hrs ${minutes}Mins ${seconds}Sec`;
  }

  if (promoStatus) {
    setInterval(() => {
      const currentDate = moment();
      const remainingTime = parsedDate.diff(currentDate);
      const timeCountdown = moment.duration(remainingTime);
      setCountdown(formatDuration(timeCountdown));
    }, 5000);
  }

  const truncateString = (data, maxLength = 30) => {
    if (data?.length > maxLength) return `${data.substring(0, maxLength)}...`;
    return data;
  };

  return (
    <CardPaper
      similarAction={similarAction}
      elevation={2}
      // style={{ width: isSimilarProduct ? smallScreen : '', margin: isSimilarProduct && 'auto' }}
      stock={qtyCount}
      spacing={3}
      cart={cartProduct}
      className={`mpFe-uat-new-order-card-${index}`}
    >
      <ImgBox onClick={handleClick}>
        {promoStatus && (
          <PromoContainer>
            <PromoText>
              {`${promoValue}% OFF`}
            </PromoText>
          </PromoContainer>
        )}
        <ProductImg cart={cartProduct} src={image} alt={brandName} />
      </ImgBox>
      <DetailsBox>
        {quantityInStock
          ? (
            <ProductVContainer cart={cartProduct} status={24}>
              <ProductVariantText status={24}>Available in 24hrs</ProductVariantText>
            </ProductVContainer>
          )
          : (
            <ProductVContainer cart={cartProduct} status={72}>
              <ProductVariantText status={72}>Available in 72hrs</ProductVariantText>
            </ProductVContainer>
          )}
        <DetailsContainer>
          <Box onClick={handleClick}>
            {/* {!similarAction && (
              <ChangeBox>
                {isSmall && quantityInStock < 1 ? 'Price subject to change' : ''}
              </ChangeBox>
            )} */}
            <HeaderContainer>
              <HeaderText variant="subtitle2">
                {truncateString((brandName))}
              </HeaderText>
            </HeaderContainer>
            <TypoContainer item container xs={12}>
              <ChangeWrapper container item xs={12}>
                <PackSizeTypo item xs={6} variant="body1" similarAction={similarAction}>
                  Pack Size:
                  {' '}
                  {packSize}
                </PackSizeTypo>
              </ChangeWrapper>
              <PricingAndSaving item container justify-content="space-between">
                {!quantityInStock && (
                <ChangeBox item xs={6}>Price subject to change</ChangeBox>
                )}
                <PricingWrapper item xs={6} md={6}>
                  {!promoStatus ? (
                    <Pricing>
                      {currencyFormatter(itemPrice)}
                    </Pricing>
                  )
                    : (
                      <Pricing>
                        {currencyFormatter(mktPrice)}
                      </Pricing>
                    )}
                </PricingWrapper>
                <Grid item container xs={6} md={6}>
                  {promoStatus && (
                    <PromoPriceText>
                      {currencyFormatter(itemPrice)}
                    </PromoPriceText>
                  )}
                </Grid>
              </PricingAndSaving>
              <SavingsWrapper item container xs={6} md={6}>
                <PricingTypo>
                  Saving
                </PricingTypo>
                <SavingPricing>
                  {currencyFormatter(savings || 0)}
                </SavingPricing>
              </SavingsWrapper>
            </TypoContainer>
            {promoStatus && (
              <TimeBox>
                <TimeImg src={timer} alt="availability" />
                <TimeText>
                  {countdown}
                </TimeText>
              </TimeBox>
            )}
          </Box>
          <ActionsContainer container spacing={isSmall ? 3 : ''} status={!quantityInStock} promo={!promoStatus}>
            <AddCartGrid item lg={7} md={8} xs={7}>
              <Button
                startIcon={<ShoppingCartImg similarAction={similarAction} src={cart} alt="cart" />}
                variant="outlined"
                disableElevation
                onClick={handleAddToCart}
                disabled={counter === 0}
                counter={counter}
                className={`mpFe-uat-new-order-plus-${index}`}
                style={{ width: '8rem', height: '2.5rem' }}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={24}
                    thickness={4}
                  />
                ) : <AddCartTypo similarAction={similarAction}> Add to cart </AddCartTypo>}
              </Button>
            </AddCartGrid>

            <AddCartGrid item lg={3} md={4} xs={5}>
              <InputButtonGroup size="small" aria-label="small outlined button group">
                <Button
                  variant="text"
                  onClick={() => handleActionButtons(-1)}
                  disabled={counter === 0}
                  className={`mpFe-uat-new-order-minus-${index}`}
                  style={{ width: '3rem' }}
                >
                  <ActionImg src={downvote} alt="remove" />
                </Button>
                <ActionTextField
                  InputProps={{ disableUnderline: true, }}
                  variant="standard"
                  placeholder={0}
                  value={counter || ''}
                  onChange={(e) => handleChange(Number(e.target.value))}
                />
                <Button
                  variant="text"
                  onClick={() => handleActionButtons(1)}
                  className={`mpFe-uat-new-order-add-${index}`}
                  style={{ width: '3rem' }}
                >
                  <ActionImg src={upvote} alt="add" />
                </Button>
              </InputButtonGroup>
            </AddCartGrid>
          </ActionsContainer>
        </DetailsContainer>
      </DetailsBox>
    </CardPaper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  openAlert: PropTypes.func.isRequired,
  cart: PropTypes.bool,
  similarAction: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired
};

ProductCard.defaultProps = {
  product: {},
  cart: false
};

export default ProductCard;
