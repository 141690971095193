import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Table, TableBody } from '@mui/material';
import { SALES_PRODUCTS_QUERY } from '../../../queries/products';
import {
  TableContainerWrapper, TableComponent, TableHeader, FooterGrid, ExportButton,
  MainTableHead, HeaderGrid, Header, TableGrid, DivFullWidth, HeaderGridItem, ExportIconStyled
} from './products.styles';
import TablePagination from '../../shared/tablePagination';
import ReturnRow from './returnRow';
import Filters from './filter';
import useSearchParamsState from '../../helpers/ulrSearchParams';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';
import { useStateValue } from '../../../providers/stateProvider';
import { Product } from '../../../providers/reducers/product/classes/Product';
import CustomExportButton from '../../downloads/exportModule/exportComponent';

const headers = [
  'Product Name', 'Qty in Stock', 'Cost Price', 'Selling Price', '% Profit/Loss',
  'UOM', 'Status', 'Action'
];

const initialState = {
  filters: {
    productType: 'only_business_products',
    status: 'ACTIVE'
  }
};

const Products = ({ handleStore, setActionType, productBatchPreference }) => {
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');

  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [searchType, setSearchType] = useSearchParamsState('st', 'products');
  const [searchText, setSearchText] = useSearchParamsState('sTxt', '');

  const [{ user }, dispatch] = Object.values(useStateValue());
  const { platform, allowedPermissionsMap: { userPermissions } } = user;

  const { filters } = state;

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSetFilter = (name, value) => {
    if (!name) return setState({ ...state, filters: {} });
    if (name === 'productType') {
      return setState({
        ...state,
        filters: { [name]: value, status: 'ACTIVE' }
      });
    }
    return setState({
      ...state, filters: { [name]: value }
    });
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleSearchButton = () => {
    switch (searchType) {
      case 'products':
        setSearchType('molecules');
        break;
      default:
        setSearchType('products');
        break;
    }
  };

  const editProduct = (row) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: row }
    });
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const returnHeaders = () => headers.map((header, index) => {
    if (header === 'Cost Price' && !userPermissions?.includes('sales_view_cost_price')) return null;
    return (
      <TableHeader key={index} className={`erp-uat-${header.replace(/ /g, '-')}`}>
        {header}
      </TableHeader>
    );
  });

  const updateProductState = (products, totalCount, refetch) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { products, totalCount, refetch }
    });
  };

  const variables = {
    search: searchValue,
    ...(searchValue && { searchType }),
    pageCount,
    pageNumber,
    ...filters,
  };

  const {
    loading, error, data, refetch
  } = useQuery(SALES_PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.salesPageProducts) {
      const { salesPageProducts, salesPageTotalNumber } = data;
      updateProductState(salesPageProducts, salesPageTotalNumber, refetch);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    salesPageProducts = [], salesPageTotalNumber = 0
  } = data || {};

  const rows = salesPageProducts?.map((product) => {
    const newProduct = new Product(product, platform);
    const manufacturer = product?.manufacturer?.name;
    return { ...newProduct, manufacturer };
  });

  return (
    <TableContainerWrapper elevation={0}>
      <HeaderGrid container>
        <HeaderGridItem><Header>Product Items</Header></HeaderGridItem>
        <HeaderGridItem>
          <Header>
            <CustomExportButton
              title="Export"
              color="bordered"
              fileName="business_product"
              large="large"
              icon="withIcon"
              name="Products"
              variable={variables}
              disabled={!userPermissions?.includes('sales_export_all_products')}
            />
          </Header>
        </HeaderGridItem>
      </HeaderGrid>
      <Filters
        searchText={searchText}
        handleSetFilter={handleSetFilter}
        handleSearch={handleSearch}
        searchButtonText={searchType}
        handleSearchButton={handleSearchButton}
      />
      <TableGrid item container>
        {loading
          ? <ProductsTableLoader />
          : (
            <TableComponent item container>
              <Table style={{ width: '70rem' }}>
                <MainTableHead item container>
                  {returnHeaders()}
                </MainTableHead>
                <TableBody>
                  {rows.map((_row) => (
                    <ReturnRow
                      key={_row.id}
                      refetch={refetch}
                      row={_row}
                      user={user}
                      editProduct={editProduct}
                      handleStore={handleStore}
                      setActionType={setActionType}
                      productBatchPreference={productBatchPreference}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableComponent>
          )}
      </TableGrid>
      <DivFullWidth>
        {salesPageTotalNumber > 0 && (
          <TablePagination
            total={salesPageTotalNumber}
            pageCount={+pageCount}
            setPageCount={setPageCount}
            pageNumber={+pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </DivFullWidth>
    </TableContainerWrapper>
  );
};

Products.propTypes = {
  handleStore: PropTypes.func,
  setActionType: PropTypes.func,
  productBatchPreference: PropTypes.func.isRequired,
};

Products.defaultProps = {
  handleStore: () => null,
  // eslint-disable-next-line no-unused-vars
  setActionType: (val) => null
};
export default Products;
