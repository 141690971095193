import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import CreateUserDialog from './users/createUserDialog/CreateUserDialog';
import CreateRoleDialog from './roles/createRoleDialog/CreateRoleDialog';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, ButtonsGrid,
} from './titleGrid.styles';
import { useStateValue } from '../../../providers/stateProvider';

export function TitleGrid({ refetch }) {
  const [openModal, setOpenModal] = useState(false);
  const [createUserDialog, setCreateUserDialog] = useState(false);
  const [open, setOpen] = useState(false);

  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const navigate = useNavigate();

  const handleUserDialog = () => {
    setCreateUserDialog(!createUserDialog);
  };

  const toggleDialog = () => {
    setOpen(!open);
  };

  const closeDialogHandler = () => {
    setOpenModal(false);
  };

  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item md={6}>
        <PrimaryTitle>
          Roles & Permissions
        </PrimaryTitle>
        <SecondaryTitle>
          Manage your users and give permissions
        </SecondaryTitle>
      </TitleTextGridContainer>
      <Grid container md={6} spacing={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          data-testid="export"
          onClick={() => navigate('/settings/users')}
          disabled={!userPermissions?.includes('role_permissions_view_users')}
        >
          View All Users
        </Button>
        <Button
          variant="contained"
          style={{ margin: '0 1rem' }}
          data-testid="export"
          onClick={() => setCreateUserDialog(true)}
          disabled={!userPermissions?.includes('role_permissions_add_user')}
        >
          Create Users
        </Button>
        <Button
          variant="contained"
          data-testid="export"
          onClick={toggleDialog}
          disabled={!userPermissions?.includes('role_permissions_create_new_role')}
        >
          Create Role
        </Button>
      </Grid>

      <CreateRoleDialog
        dialogOpen={open}
        closeDialog={() => toggleDialog()}
        refetch={refetch}
      />
      <CreateUserDialog
        open={createUserDialog}
        close={handleUserDialog}
        refetch={refetch}
      />

    </TitleGridContainer>
  );
}

TitleGrid.propTypes = {
  refetch: PropTypes.func.isRequired
};

export default TitleGrid;
