export const defaultCustomerData = {
  openedCustomerModal: false,
  id: null,
  name: null,
  email: null,
  addressLineOne: null,
  region: null, city: null, photo: null,
  storeCredit: null,
  loyaltyPoint: null,
  refetchCustomer: () => {}
};

const sale = {
  batchModalData: {
    openedBatchModal: false,
    product: null,
    productName: '',
    packSize: '',
    batches: new Map()
  },
  customerModalData: {
    ...defaultCustomerData
  },
  cart: new Map(),
  outOfStockBatches: new Map(),
  inStockBatches: new Map(),
  saleTotal: 0.00,
  vatTotal: 0.00,
  vatPercentage: 0,
  subTotal: 0.00,
  percDiscount: 0,
  discount: 0.00,
  totalCount: 0,
  salesOnHold: [],
  openHoldSale: false
};

export default sale;
