import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, ButtonsGrid,
} from './titleGrid.styles';
import EditBusinessModal from './editBusinessInformation/addBusinessDialog';

export function TitleGrid({ setContentKey }) {
  const [openModal, setOpenModal] = useState(false);

  const closeDialogHandler = () => {
    setOpenModal(false);
    setContentKey(Math.random());
  };

  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          Business Profile
        </PrimaryTitle>
        <SecondaryTitle>
          Manage your business profile
        </SecondaryTitle>
      </TitleTextGridContainer>
      <ButtonsGrid item>
        <Button
          variant="contained"
          onClick={() => setOpenModal(true)}
        >
          Edit Profile
        </Button>
        <EditBusinessModal
          dialogOpen={openModal}
          closeDialog={closeDialogHandler}
        />
      </ButtonsGrid>

    </TitleGridContainer>
  );
}

TitleGrid.propTypes = {
  setContentKey: PropTypes.instanceOf(Function)
};

TitleGrid.defaultProps = {
  setContentKey: () => 'clicked'
};

export default TitleGrid;
