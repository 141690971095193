import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import toast from 'react-hot-toast';
import { EXPORT_MUTATION } from '../../../mutations/reports';
import CustomButton from './customButton';
import {
  ExportIconStyled, TabIcon, TabImage
} from './exportComponents.styles';
import exportIcon from '../../../assets/images/supplier/Export Icon.png';

const CustomExportButton = ({
  title, color, fileName, variable, large, icon, onlyIcon, name, disabled
}) => {
  const [exportMutation] = useMutation(EXPORT_MUTATION);

  const randomNum = Math.floor(Math.random() * 10000);
  const NewfileName = `${name} ${randomNum}`;
  const handleClick = async () => {
    exportMutation({
      variables: {
        type: fileName,
        name: NewfileName,
        ...variable
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return onlyIcon ? (
    <TabIcon
      onClick={handleClick}
      disabled={disabled}
    >
      <TabImage src={exportIcon} alt="Search" />
    </TabIcon>
  ) : (
    <CustomButton
      type={color}
      // variant="contained"
      onClick={handleClick}
      large={large}
      disabled={disabled}
      style={{ border: '1.5px solid #235A91' }}
    >
      {icon ? <ExportIconStyled /> : ''}
      {title}
    </CustomButton>
  );
};

CustomExportButton.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  variable: PropTypes.instanceOf(Object)
};

CustomExportButton.defaultProps = {
  title: 'Export',
  color: '',
  variable: {}
};

export default CustomExportButton;
