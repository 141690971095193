import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, ClickAwayListener, Fade, Paper
} from '@mui/material';
import { CautionOutline } from '../../../../assets/svgs';
import {
  CPopper, WrapperGrid, SupplierItemGrid, TCustomButton, SimpleTypo
} from './searchPopper.styles';

const SearchPopper = ({
  buttonEl, handleClose, searchResult, handleChange, handleAdd, loading,
  modelType
}) => {
  const open = Boolean(buttonEl) && !loading;

  return (
    <CPopper
      open={open}
      anchorEl={buttonEl}
      transition
      disablePortal
      placement="bottom-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, .5vh',
        }
      }}
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => handleClose(null)}>
              <WrapperGrid item container>
                {searchResult?.length ? (
                  searchResult?.map((s) => (
                    <SupplierItemGrid item container key={s} onClick={() => handleChange(s)}>
                      {s}
                    </SupplierItemGrid>
                  ))
                ) : (
                  <Grid item container style={{ padding: '12px 15px' }}>
                    <Grid item container alignItems="center">
                      <CautionOutline style={{ marginRight: '.5rem', width: '1.3rem' }} />
                      <SimpleTypo variant="caption">{`Searched ${modelType} is not in our records`}</SimpleTypo>
                    </Grid>
                    <TCustomButton
                      type="secondary"
                      header
                      onClick={handleAdd}
                      style={{ width: '11rem' }}
                    >
                      {`Add ${modelType}`}
                    </TCustomButton>
                  </Grid>
                )}
              </WrapperGrid>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </CPopper>
  );
};

SearchPopper.propTypes = {
  buttonEl: PropTypes.shape(Object),
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  searchResult: PropTypes.shape(Array),
  loading: PropTypes.bool,
  modelType: PropTypes.string,
};

SearchPopper.defaultProps = {
  buttonEl: {},
  searchResult: [],
  loading: false,
  modelType: ''
};

export default SearchPopper;
