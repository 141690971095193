import { styled } from '@mui/material/styles';
import { Pagination, Box, Grid } from '@mui/material';

export const CPagination = styled(Pagination)`
  & .MuiPaginationItem-outlinedPrimary.Mui-selected {
    color: #F5F5F5;
    background-color: #235A91;
    font-size: 10px;
    font-weight: 400;
    border: 0;
    box-shadow: 0px 4.39216px 10.0392px rgba(0, 0, 0, 0.14);
  }
  &.MuiPagination-root {
    & .MuiPagination-ul {
      flex-wrap: nowrap;
      li {
        > button {
          border: 1px solid #78AADA;
          font-size: 10px;
          font-weight: 400;
        }
        &:first-child {
          flex-basis: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          > button {
            content: 'previous';
            border: 0;
            color: #78AADA;
          }
        }
        &:last-child {
          flex-basis: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          > button {
            content: 'next';
            border: 0;
            color: #78AADA;
          }
        }
      }
    }
  }

  & .MuiPaginationItem-outlinedPrimary {
    color: #235A91;
    border: 1px solid #78AADA;
  }

  @media (max-width: 991px) {
    & .MuiPaginationItem-root {
      height: 64px;
      min-width: 64px;
      margin: 0 7px;
      font-size: 9px;
      font-weight: 400;
      border-radius: 35px;
    }

    & .MuiSvgIcon-root {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const SelectStyled = styled('select')`
  padding: .8rem;
  border-radius: 5px;
  border: 1px solid #78AADA;
`;

export const ContentGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    justify-content: center;
    display: grid;
  }
`;
export const ResultBox = styled(Box)`
  font-size: 1rem;

  @media (min-width: 992px) {
    margin-right: auto;
  }
  @media (max-width: 991px) {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;
