import {
  Grid, Paper, TableCell, TableBody, Typography,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableContainerWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 50px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem 0;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const MainTableHead = styled(TableRow)`
  width: 100%;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const TableHeader = styled(TableCell)`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  color: #303030;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;
`;
