import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { InputAdornment, Grid, Hidden } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import MainContent from '../../customComponents/mainContent';
import ProductBatches from './productBatches';
import SearchIcon from '../../../assets/images/Search Icon.png';
import { PRODUCTS_QUERY } from '../../../queries/products';
import {
} from '../../../mutations/orders';
import ProductBatchDialog from './productBatchDialog';
import AddInvoiceDialog from '../addInvoiceDialog';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, TextWrapper, MenuButton, ClearIcon,
  SearchImage, KeyboardArrow, SearchContainer, SearchTextField, SearchButton
} from '../../orders/newOrder/newOrderContainer.styles';
import {
  ButtonsWrapper, Details, DetailCard, DetailCardHeaderGrid, DetailCardHeaderText,
  DetailCardText, PaperCardGrid
} from '../invoices.styles';
import {
  HeaderGrid, ProductItemsTotal, SaveProgressIcon, GenerateReportIcon
} from './productBatches.styles';
import { OrderButton, OrderButtonText } from '../../orders/shared/titleGrid.styles';
import { OrderGridContainer } from '../../orders/admin/order.styles';
import { JSONParse } from '../../../utils/json';
import SearchPopper from './searchPopper';
import { renderStatus } from '../utils';
import { Product } from '../../../providers/reducers/product/classes/Product';

const InvoiceDetailsContainer = () => {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    searchValue: '',
    searchedProducts: [],
    currentPage: 1,
    currentPageCount: pageCount,
    business: '',
  };

  const [state, setState] = useState(initialState);
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [updatingInvoice, setUpdatingInvoice] = useState(true);
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProd, setSelectedProd] = useState({});
  const navigate = useNavigate();

  const textRef = useRef();
  const { id: orderInvoiceId } = useParams();

  const [searchProduct, { loading, data }] = useLazyQuery(PRODUCTS_QUERY);
  useEffect(() => {
    if (data && data.products) {
      const { products } = data;
      setSearchedProducts(products);
    }
    if (loading && !data) setIsLoading(true);
    else setIsLoading(false);
  }, [data, loading]);

  const handleSearch = async (text) => {
    setSearchText(text);
    if (text && text.length >= 3) {
      searchProduct({
        fetchPolicy: 'cache-and-network',
        variables: {
          search: text,
          suppliers: [],
          pageCount: 20,
          pageNumber: 1,
          onlyMarketProducts: false
        },
      });
      setShowSearch(true);
    } else if (!text) {
      setState({ ...state, searchedProducts: [] });
      setShowSearch(false);
    }
  };

  const handleClearSearch = () => {
    setSearchText('');
    setShowSearch(false);
  };

  const handleProdSelection = (prod) => {
    setShowSearch(false);
    setSelectedProd(prod);
    setOpenDialog(true);
  };

  // const [deleteOrderInvoiceBatches] = useMutation(DELETE_ORDER_INVOICE_BATCHES_MUTATION);
  const handleRemoveBatch = () => {
    // deleteOrderInvoiceBatches({
    //   variables: {
    //     orderInvoiceId: +invoiceId,
    //     batchIds: [+batch.id]
    //   }
    // }).then(({ data: { deleteOrderInvoiceBatches: { message } } }) => {
    //   toast.success(message);
    //   if (refetch) refetch();
    // }).catch((err) => {
    //   toast.error(err?.message);
    // });
  };

  // const [receiveOrderInvoice] = useMutation(RECEIVE_ORDER_INVOICE_MUTATION);
  const handleReceiveInvoice = () => {
    // receiveOrderInvoice({
    //   variables: { orderInvoiceId }
    // }).then(({ data: { receiveOrderInvoice: { message } } }) => {
    //   toast.success(message);
    //   if (refetch) refetch();
    // }).catch((err) => {
    //   toast.error(err?.message);
    // });
  };

  // const [deleteOrderInvoice] = useMutation(DELETE_ORDER_INVOICE_MUTATION);
  const handleInvoiceDelete = () => {
    // deleteOrderInvoice({
    //   variables: { orderInvoiceId }
    // }).then(({ data: { deleteOrderInvoice: { message } } }) => {
    //   toast.success(message);
    //   setTimeout(() => navigate('/invoices'), 1000);
    // }).catch((err) => {
    //   toast.error(err?.message);
    // });
  };

  const handleSetInvoiceEditing = () => {
    setInvoiceDialogOpen(true);
    setUpdatingInvoice(true);
  };

  const createData = (product) => {
    const {
      id, brandName, quantityInStock, genericName, meta, suppliers, resolvedPriceInUseValue, batches
    } = new Product(product, 'erp') || {};
    const {
      image, market_rpp: rpp, pack_size: packSize,
      manufacturer, qty_per_carton: qpc
    } = JSONParse(meta) || {};

    return {
      id, image, brandName, quantityInStock, genericName, suppliers, itemPrice: resolvedPriceInUseValue, rpp,
      packSize, manufacturer, qpc, batches
    };
  };

  const sResults = searchedProducts?.map((product) => createData(product));

  const renderSearchField = (placeholder, searchValue, handleChange, ClearSearch, small = false) => (
    <SearchContainer container item xs={12} md={small ? 12 : 6}>
      <SearchTextField
        variant="outlined"
        label=""
        autoFocus
        value={searchValue}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        fullWidth
        ref={textRef}
        InputProps={{
          endAdornment:
  <InputAdornment position="end">
    <SearchButton
      aria-label={placeholder}
      edge="end"
      disabled={!searchValue}
      small={small}
      onClick={ClearSearch}
    >
      {searchValue ? (
        <ClearIcon small={small} />
      ) : (
        <SearchImage alt="search icon" src={SearchIcon} small={small} />
      )}
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const invoiceData = [];
  const refetch = '';
  // const {
  //   loading: invoiceLoading, error, data: invoiceData, refetch
  // } = useQuery(GET_ORDER_INVOICE, {
  //   fetchPolicy: 'cache-and-network',
  //   variables: { id: orderInvoiceId }
  // });

  useEffect(() => {
    if (invoiceData && invoiceData.orderInvoice) {
      const { status } = invoiceData?.orderInvoice || {};
      if (status === 'COMPLETE') setUpdatingInvoice(false);
    }
  }, [invoiceData]);

  // if (invoiceLoading) return <Loader />;
  // if (error) return <div>{error.message}</div>;

  const {
    id, business, supplier, dateReceived, status, invoiceNumber, total,
    orderinvoiceproductbatchSet: prodBatches
  } = invoiceData?.orderInvoice || {};

  const batches = prodBatches.map(({ product: prod, ...batch }) => {
    const product = new Product(prod, 'erp');
    return { product, ...batch };
  });
  const receivedDate = moment(dateReceived).format('DD/MM/YYYY');
  return (
    <MainContent>
      <Grid item container>
        <OrderGridContainer container>
          <TitleGridContainer container item>
            <TitleTextGridContainer item>
              <MenuButton onClick={() => navigate(-1)}>
                <KeyboardArrow />
              </MenuButton>
              <TextWrapper>
                <PrimaryTitle variant="h5">
                  {`New Invoice: ${invoiceNumber}`}
                </PrimaryTitle>
                <SecondaryTitle>
                  Add products to the invoice
                </SecondaryTitle>
              </TextWrapper>
            </TitleTextGridContainer>
            <ButtonsWrapper>
              <OrderButton
                onClick={handleInvoiceDelete}
                style={{ marginRight: '1rem', backgroundColor: '#fc5050' }}
                disabled={status === 'COMPLETE'}
              >
                <OrderButtonText style={{ marginLeft: 0 }}>
                  Delete Invoice
                </OrderButtonText>
              </OrderButton>
              <OrderButton
                onClick={handleSetInvoiceEditing}
                style={{ marginRight: '1rem' }}
                disabled={status === 'IN_PROGRESS'}
              >
                <Hidden mdDown>
                  <SaveProgressIcon />
                </Hidden>
                <OrderButtonText>Edit Invoice</OrderButtonText>
              </OrderButton>
              <OrderButton
                onClick={() => handleReceiveInvoice(refetch)}
                disabled={!batches.length || status === 'COMPLETE'}
              >
                <Hidden mdDown>
                  <GenerateReportIcon />
                </Hidden>
                <OrderButtonText>Receive Invoice</OrderButtonText>
              </OrderButton>
            </ButtonsWrapper>
          </TitleGridContainer>

          <Details>
            <DetailCard elevation={2} onClick={() => {}} style={{ width: '70%' }}>
              <PaperCardGrid>
                <DetailCardHeaderGrid container item justifyContent="space-between">
                  <DetailCardHeaderText>{`Supplier: ${supplier?.name}`}</DetailCardHeaderText>
                  <DetailCardHeaderText>{`Date Received: ${receivedDate}`}</DetailCardHeaderText>
                </DetailCardHeaderGrid>
                <DetailCardText>{business?.name}</DetailCardText>
                <DetailCardText>
                  {renderStatus(status)}
                </DetailCardText>
              </PaperCardGrid>
            </DetailCard>
            <DetailCard style={{ width: '25%' }}>
              <PaperCardGrid style={{ textAlign: 'center' }}>
                <DetailCardHeaderText>Total Invoice Value</DetailCardHeaderText>
                <DetailCardText style={{ fontSize: '18px', fontWeight: '600' }}>
                  { total || 'N/A' }
                </DetailCardText>
              </PaperCardGrid>
            </DetailCard>
          </Details>
          <HeaderGrid item container>
            <ProductItemsTotal>{`${batches.length} item(s)`}</ProductItemsTotal>
            {updatingInvoice ? renderSearchField('Search inventory to add product item', searchText, handleSearch, handleClearSearch) : ''}
          </HeaderGrid>
          <Grid item container md={12} style={{ marginTop: '20px' }}>
            <ProductBatches
              batches={batches}
              updatingInvoice={updatingInvoice}
              handleRemoveBatch={(batch) => handleRemoveBatch(batch, id, refetch)}
            />
          </Grid>
        </OrderGridContainer>

        <SearchPopper
          open={showSearch}
          anchorEl={textRef.current}
          handleClose={() => setShowSearch(false)}
          isLoading={isLoading}
          searchedProducts={sResults}
          handleProdSelection={handleProdSelection}
        />
        <ProductBatchDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          orderInvoiceId={Number(orderInvoiceId)}
          product={selectedProd}
          supplier={supplier}
          dateReceived={dateReceived}
          refetch={refetch}
        />
        <AddInvoiceDialog
          dialogOpen={invoiceDialogOpen}
          closeDialog={() => setInvoiceDialogOpen(false)}
          invoiceData={invoiceData}
          refetchInvoice={refetch}
        />
      </Grid>
    </MainContent>
  );
};

export default InvoiceDetailsContainer;
