import React from 'react';
import {
  DialogContent, Slide, Box, Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import success from '../../assets/images/success.gif';
import {
  DialogContainer,
  DialogContentStyled,
  TitleStyled,
  DescStyled,
  FooterStyled
} from './successDialog.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SuccessDialog = ({ openDialog, setOpenDialog }) => {
  const navigate = useNavigate();
  const handleClose = async () => {
    setOpenDialog(false);
  };

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem' }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <img src={success} alt="warning" width={155} />
            </Box>
            <TitleStyled>Products Added Successfully!</TitleStyled>
            <DescStyled>
              Your out of stock products have been successfully added to your
              Order List.
            </DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <Button onClick={() => navigate('/inventory/my-order-list')} variant="outlined" fullWidth style={{ width: '50%' }}>
            Proceed to Order List
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            fullWidth
            style={{ width: '50%' }}
          >
            Ok
          </Button>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

SuccessDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func
};

SuccessDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false
};

export default SuccessDialog;
