import React, { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { useNavigate } from 'react-router-dom';
import { GET_COMPLETED_MANUAL_SUPPLIER_ORDERS } from '../../../queries/orders';

import Order from './order';
import Loader from '../../shared/loader';
import { useStateValue } from '../../../providers/stateProvider';

const ManualOrderDashboardContainer = () => {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [{ business: { businessId, businessUser: businessUserId } }] = Object.values(useStateValue());
  const navigate = useNavigate();

  const handleOrderItemCardClicked = (id) => {
    navigate(`/suppliers-orders/${id}/manual-order-dashboard`);
  };
  const variables = {
    pageCount,
    pageNumber
  };
  if (businessId) {
    variables.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  return (
    <Query
      query={GET_COMPLETED_MANUAL_SUPPLIER_ORDERS}
      fetchPolicy="cache-and-network"
      variables={variables}
    >
      {({ data, loading, error }) => {
        if (loading) return <Loader />;
        if (error) return `Error! ${error.message}`;
        const {
          reconciledManualSupplierOrders = [], supplierOrderTotalNumber = 0
        } = data;

        const supplierOrderItems = reconciledManualSupplierOrders
          .map(
            (supplierOrderItem) => {
              const {
                dateCreated, id, order: { business }, orderproductSet, total, savings, supplier
              } = supplierOrderItem || {};
              const { name: supplierName, logo: supplierLogo } = supplier || {};
              const { name, logo } = business || {};
              const orderVolume = orderproductSet.reduce((previous, current) => previous + current.quantity, 0);
              return {
                dateCreated, id, name, logo, total, savings, orderVolume,
                supplierName, supplierLogo
              };
            }
          );

        return (
          <Order
            supplierOrders={supplierOrderItems}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
            page={pageNumber}
            currentPageCount={pageCount}
            totalCount={supplierOrderTotalNumber}
            onOrderItemCardClicked={handleOrderItemCardClicked}
          />
        );
      }}
    </Query>
  );
};

export default ManualOrderDashboardContainer;
