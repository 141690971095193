import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Hidden } from '@mui/material';
import {
  AuthGridContainer, PharmIQLogo,
  LogoTypo, LogoGridContainer, InputsGridContainer, InputsGrid, LogoSubTypo,
  InputsHeaderTypo, InputsSubheaderTypo, LogoContainer
} from './authContainer.styles';
import Register from './register';
import Login from './login';
import PasswordReset from './passwordReset';
import { useStateValue } from '../../providers/stateProvider';
import EmailVerification from './emailVerification';
import ResetPassword from './resetPassword';
import { maskEmail } from '../../utils/funcs';

const AuthContainer = () => {
  const { pathname, search } = useLocation();
  const [, dispatch] = Object.values(useStateValue());

  const query = new URLSearchParams(search);
  const email = query.get('ref');
  const registerStep = query.get('step');

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
  }, [dispatch]);

  const returnHeader = () => {
    switch (pathname) {
      case '/':
        return 'Sign in to OGApharmacy';
      case '/register':
        return 'Sign up on OGApharmacy';
      case '/email-verification':
        return 'Email Verification';
      case '/forgot-password':
        return 'Forgot Password';
      default:
        return 'Reset Password?';
    }
  };

  // Welcome back to retail POS, make sales with our platform.
  const renderDescription = () => {
    if (pathname === '/forgot-password') return 'Please enter the email address you registered with and a link will be sent to reset your password.';
    if (registerStep === '2') return 'Select a billing plan to continue your sign up';
    if (pathname === '/email-verification') return `We sent a verification code to ${email && maskEmail(email)}`;
    if (pathname === '/' || registerStep === '1') return 'Welcome back to retail POS, make sales with our platform.';
    return 'Create a new and unique password following the prompts below';
  };

  const returnComponent = () => {
    switch (pathname) {
      case '/':
        return <Login />;
      case '/register':
        return <Register />;
      case '/email-verification':
        return <EmailVerification />;
      case '/forgot-password':
        return <PasswordReset />;
      default:
        return <ResetPassword />;
    }
  };

  return (
    <AuthGridContainer container>
      <Hidden mdDown>
        <LogoGridContainer item container xs={6}>
          <LogoContainer>
            <LogoTypo variant="h3">
              Effortlessly manage sales and inventory & much more.
            </LogoTypo>
            <LogoSubTypo variant="caption">
              Continue running your procurement with OGApharmacy marketplace; Trusted by over 1,625 pharmaceutical stores.
            </LogoSubTypo>
          </LogoContainer>
        </LogoGridContainer>
      </Hidden>
      <InputsGridContainer item container xs={12} md={6} lg={6}>
        <InputsGrid item container>
          <PharmIQLogo />
          <InputsHeaderTypo variant="h5">
            {returnHeader()}
          </InputsHeaderTypo>
          <InputsSubheaderTypo variant="body2" color="text.secondary">
            {renderDescription()}
          </InputsSubheaderTypo>
          {returnComponent()}
        </InputsGrid>
      </InputsGridContainer>
    </AuthGridContainer>
  );
};

export default AuthContainer;
