import { TableRow, TableCell } from '@mui/material';
import styled, { css } from 'styled-components';

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #f3f9ff;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;
