import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Paper, Popper, Grow
} from '@mui/material';
import { Dots } from '../../../../assets/svgs';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo, ButtonEllipsis
} from './actionPopper.styles';
import { useStateValue } from '../../../../providers/stateProvider';

export default function ActionPopper({
  handleAction
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonEllipsis
        onClick={handleToggle}
        ref={anchorRef}
        style={{ cursor: 'pointer' }}
      >
        <Dots fontSize="large" />
      </ButtonEllipsis>
      <Popper
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <GridWrapper container item>
                  <GridContainer
                    container
                    item
                    onClick={(e) => handleAction(e, 'edit role')}
                    style={{ pointerEvents: !userPermissions?.includes('role_permissions_change_role') ? 'none' : '' }}
                  >
                    <ItemWrapper>
                      <ItemTypo variant="subtitle1">
                        Edit Role
                      </ItemTypo>
                    </ItemWrapper>
                  </GridContainer>
                  <GridContainer container item onClick={(e) => handleAction(e, 'disable user')}>
                    <ItemWrapper>
                      <ItemTypo variant="subtitle1">
                        Disable user
                      </ItemTypo>
                    </ItemWrapper>
                  </GridContainer>
                </GridWrapper>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
};
