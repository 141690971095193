import {
  Popper, Paper, Grid, CircularProgress, DialogContentText, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PopperContainer = styled(Popper)`
  z-index: 15000000;
  top: 70px !important;
  left: -145px !important;
  @media(max-width: 991px) {
    left: -255px !important;
  }
`;

export const WrapperPaper = styled(Paper)`
  z-index: 1500
`;

export const CustomDialog = styled(Dialog)`
  @media(max-width: 991px) {
    .MuiDialog-paperWidthSm {
      max-width: 900px;
    }
  }
`;
export const CDialog = styled('p')`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
  padding-left: 2.5rem;
  padding-top: 1.2rem;

  @media(max-width: 991px) {
    font-size: 3rem;
  }
`;

export const TopGrid = styled(Grid)`
    & > * {
      width: calc(50% - 0.75rem);
      margin: 0 1.5rem 1.5rem 0;

      :nth-child(2n) {
        margin-right: 0;
      }
    }
`;

export const GridItem = styled(Grid)`
  display: flex;
  margin-left: 2.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

export const GridTitle = styled(Grid)`
  margin-left: 2.5rem;
  margin-top: 0.3rem;
`;

export const Item = styled(Paper)`
  background-color: #F5F5F5;
  width: 52px;
  height: 43px;
`;
export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  position: relative;
`;

export const DialogText = styled(DialogContentText)`
  font-size: 14px;
  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;
