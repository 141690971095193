import { styled } from '@mui/material/styles';
import {
  TableContainer, Table, TableCell, TableHead,
  Typography, Grid, IconButton, Paper
} from '@mui/material';

export const Details = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DetailCard = styled(Paper)`
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  margin-right: 10px;

  @media(min-width: 992px) {
    padding: 20px 30px;
    margin-bottom: 20px;
    border-radius: 8px;

  }
`;
export const DetailCardHeaderGrid = styled(Grid)`
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
`;
export const DetailCardHeaderText = styled('p')`
  font-weight: 600;
  font-size: 16px;
`;
export const DetailCardText = styled('p')`
  font-size: 12px;
`;
export const PaperCardGrid = styled(Grid)`
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
`;

export const StockReportStatus = styled('div')`
  border-radius: 50%;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 1em;
`;

export const TableContainerWrapper = styled(TableContainer)`

`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.5rem;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  width: 6%;

  &:first-child, :last-child {
    width: 4%;
  }
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TextWrapper = styled(Grid)`
`;

export const ButtonsWrapper = styled(Grid)`
  display: flex;
  margin-left: 40px;
  margin-top: 20px;

  @media(min-width: 992px) {
    margin-top: 0px;
  }
`;

export const EditReportIcon = styled('img')`
  width: 30px;
  margin-bottom: 4px;

  @media(min-width: 992px) {
    width: 17px;
  }
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: grid;

  @media(min-width: 992px) {
    display: flex;
  }
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  color: #424242;

  @media(max-width: 991px) {
    display: none;
  }
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
`;

export const TabImage = styled('img')`
  width: 26px;
`;

export const SubContentHandler = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding: 30px 15px;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  margin-top: 50px;
  margin-bottom: 30px;
`;
