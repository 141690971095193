import {
  Grid, Typography, IconButton, Box, Paper, TextField, Checkbox, Button,
  CircularProgress, DialogContentText, Table, TableHead, TableRow, TableCell, TableContainer
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import TelegramIcon from '@mui/icons-material/Telegram';
import CachedIcon from '@mui/icons-material/Cached';
import { styled } from '@mui/material/styles';
import CustomButton from '../../customComponents/customButton';

// the width in pixels for sm breakpoint.
const sm = '960px';
export const NavbarContainer = styled(Grid)`
  height: 100px;
  background-color: #424242;
  padding-left: 35px;
  box-sizing: border-box;
`;

export const SeperatorGrid = styled(Grid)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  color: white;
  box-sizing: border-box;
`;

export const NavLeftMenu = styled(Grid)`
  justify-content: flex-start;
  align-items: center;
`;

export const TCustomButton = styled(CustomButton)`
  width: 12rem;
  height: 2.9rem;
  margin-right: 1rem;
`;

export const NavRightMenu = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderMenuButton = styled(IconButton)`
  color: white;
  margin-right: 50px;
`;

export const NavItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  text-align: right;
  border-left: 1px solid rgba(240, 240, 240, .1);
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius:0px;
`;

export const DatetimeTypo = styled(Typography)`
  font-size: 14px;
  color: #A3A3A3;
  letter-spacing: 0px;
  text-align: right;
  padding: 0px 20px;
`;

export const AffiliatesLogo = styled(Box)`
    display: flex;
    justify-content: center;
    align-items:  center;
    width: 119px;
    height: 100px;
    border-left: 1px solid rgba(240, 240, 240, .1);
    border-radius:0px;

    @media(max-width: ${sm}) {
      border-left: 0px;
    }
`;

export const PharmIQImage = styled('img')`
    height: 50px;
    justify-self: flex-start;
`;

export const HeaderIcon = styled('img')`
    height: 25px;
`;

export const AffiliateImage = styled('img')`
  height: 50px;
  width: 50px;
`;

export const ContentWrapper = styled(Grid)`
  display:flex;
  flex-wrap: nowrap;
  background: #424242;
`;

export const SideNav = styled(Grid)`
  color: white;
  background: #424242;
  top: -100px;
  width: 400px;
  min-height: calc(100vh + 100px);
  position: relative;
  overflow: hidden;
  transition: 400ms ease-in-out;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MainContent = styled(Grid)`
//   height: calc(100vh + 100px);
  background: #F0F0F0;
`;

export const SideNavHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:100px;
  width: 400px;
  border-bottom: 1px solid rgba(240, 240, 240, .1);
`;

export const SideNavItems = styled(Grid)`
  margin-top:50px;
  width: 100%;
`;

export const SideNavItem = styled(IconButton)`
  border-radius: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 45px;
  width: 100%;
  justify-content: flex-start;
  color: #CCCCCC;
`;

export const SideNavText = styled(Typography)`
    margin-left: 50px;
    font-size: 16px;
    height: 19px;
    font-weight: 600;
    text-transform: capitalize;
`;

export const FullPharmIQLogo = styled('img')`
  width: 196px;
  height: 50px;
  margin-left: 35px;
`;

export const NavigationIcon = styled(IconButton)`
    height: 50px;
`;

export const MobileNavArrow = styled('img')`
  width: 9px;
  height: 8px;
`;

export const SideNavBackground = styled('img')`
  position:absolute;
  // height: 100%;
  // width: 100%;
  z-index: 12;
  margin-top: 100px;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 2px;
`;

export const BackText = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const MainGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

export const TitleTextGridContainer = styled(Grid)`
  flex-direction: column;
`;

export const PimaryTitle = styled(Typography)`
  color: #424242;
  font-size: 30px;
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  color: #424242;
`;

export const UpdateButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 15px;
  // background-color: #FCED50;
  color: #424242;
  border-radius: 15px;
  border: 3px solid #424242;
  height: 70px;

  &:hover {
    background-color: #D2C647;
  }

  &:disabled {
    border: 3px solid #b2b2b2;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 10px;
    border: 1.5px solid #424242;

    &:disabled {
      border: 1.5px solid #b2b2b2;
    }
  }
`;

export const ToggleButton = styled(IconButton)`
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 17px 35px;
  color: #424242;
  border-radius: 15px;
  border: 3px solid #424242;

  &:disabled {
    border: 3px solid #b2b2b2;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 10px;
    border: 1.5px solid #424242;

    &:disabled {
      border: 1.5px solid #b2b2b2;
    }
  }
`;

export const PlaceOrderIcon = styled(TelegramIcon)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const UpdateCartIcon = styled(CachedIcon)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const OrderButtonText = styled(Typography)`
  font-size: 28px;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: 16px;
    margin-left: 20px;
  }
`;

export const DialogActionButtonText = styled('p')`
  font-size: 3rem;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 14px;
  }
`;

export const SupplierProductsCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 35px;
  border-bottom: 2px solid #efefef;

  @media (min-width: 992px) {
    padding: 15px 2.3rem;
    border-bottom: 1px solid #e8e8e8;
  }
`;

export const SupplierProductsCardHeaderText = styled('p')`
  color: #a3a3a3;
  font-size: 30px;
  font-weight: 600;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;
export const SupplierProductsCardFooter = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding: 28px 35px;
  border-bottom: 2px solid #efefef;

  @media (min-width: 992px) {
    padding: 15px 2.3rem;
  }
`;

export const SupplierProductsCardFooterText = styled(Typography)`
  font-size: 30px;
  font-weight: 500;
  margin-left: .5rem;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const CheckboxImage = styled('img')`
  width: 2rem;

  @media (min-width: 992px) {
    width: 17px;
  }
`;

export const ProductSubTotalHeaders = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 35px;
  border-top: 1px solid #E7E8E9;
  width: 48%;
  margin-right: 14px;
  @media (max-width: 991px) {
    padding: 3rem;
    font-size: 2rem;
    width: 100%;
    justify-content: space-around;
    border-top: none;
  }
`;

export const ProductSubTotalWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const ProductColumnHeaderTitle = styled('span')`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  white-space: nowrap;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const ProductColumnHeaderTitleW = styled('span')`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  white-space: nowrap;
  min-width: 410px;
  max-width: 410px;
`;

export const ProductSubTotalHeadersMobile = styled('div')`
  display: none;

  @media (max-width: 1199px) {
    display: flex;
    padding: 2rem 2rem 2rem 31.5rem;
    justify-content: flex-start;
    color: #424242;
    font-size: 1.5rem;
    font-weight: 600;

    & > * {
      margin: 0;

      &:last-child {
        margin-left: 3.5rem;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 2rem;
    font-size: 2rem;
    justify-content: space-between;
  }
`;

export const BackArrowIconContainer = styled(IconButton)`
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 21.64px;

  @media(min-width: 992px) {
    margin-right: 5px;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 2.1875rem;
  }
`;
export const OutletInfoContainer = styled(Grid)`
  background: #F8F8F8;
  padding: 15px 0px 15px 40px;
  border-radius: 8px;
  justify-content: space-between;
`;

export const OutletContactHeader = styled(Grid)`
  font-size: 1rem;
  color: #000000;
  font-weight: 600;

  @media(max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const OutletContactLine = styled(Grid)`
  font-size: 1rem;
  color: #606060;
  @media(max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const PageTitleText = styled('p')`
  color: #303030;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 25px;
  }
`;

export const PageTitleSubText = styled('p')`
  color: #606060;
  margin: 0;
  font-size: 1.7rem;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const CartItemCountContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 33px 0;

  @media (min-width: 992px) {
    padding: 25px 0;
    width: 70%;

  }
`;
export const CartItemContainer = styled('div')`
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: 992px) {
    display: flex;
  }
`;
export const CartContainer = styled(Grid)`

`;

export const CartItemCountText = styled(Typography)`
  margin: 0;
  color: #606060;
  font-size: 30px;
  font-weight: 600;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;
export const CartItemWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 991px) {
    margin-top: 1rem;
  }
`;

export const ClearAllCart = styled(Button)`
  background-color: #303030;
  padding: 10px 4rem;
  color: #FFFFFF;
  font-size: 27px;
  font-weight: 600;
  border-radius: 5px;
  margin-left: 1rem;

  &:hover {
    color: #303030;
  }

  @media (min-width: 992px) {
    font-size: 14px;
    padding: 10px 2rem;
  }
`;

export const TableHeaderCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 33%;
    }
    &:nth-child(2), &:nth-child(3), :nth-child(4) {
      width: 16%;
    }
    :nth-child(5) {
      width: 14%;
    }
    &:nth-child(6) {
      width: 5%;
    }
  }
`;

export const ProductColumnHeaders = styled(Grid)`
  margin-top: 20px;
`;

export const NewProductColumnHeaders = styled('div')`
  grid-template-columns: 38% 13% 20% 20% 0%;
  display: grid;
  padding: 28px 35px;
  margin-top: 30px;
`;
export const ColumnHeaderGrid = styled(Grid)`
  padding: 20px 10px;
  margin-top: 30px;
`;

export const DeliveryAddressCard = styled(Grid)`
width: 100%;

  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 2px;
    margin-top: 2rem;
  }
`;

export const DeliveryAddressCard2 = styled(Grid)`
  @media (max-width: 991px) {
    width: 100%;
    padding-top: 3rem;
  }
`;

export const Span = styled('span')`
`;

export const DeliveryAddressCardSection = styled(Paper)`
  padding: 0 30px 30px 30px;
  min-height: 455px;

  @media (max-width: 991px) {
    padding: 0 2rem 3rem;
    width: 100%;
    border-radius: 15px;
    height: 560px;
  }
`;

export const PaymentCardSection = styled(Paper)`
  padding: 0 30px 30px 30px;
  min-height: 455px;
  margin: 1.2rem;

  @media (max-width: 991px) {
    padding: 0 2rem 3rem;
    width: 100%;
    border-radius: 15px;
    min-height: 560px;
    margin: 0rem;
  }
`;

export const DeliveryAddressCardHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;


  @media (min-width: 992px) {
    padding-top: 17px;
    padding-bottom: 17px;
  }
`;

export const DeliveryAddressCardHeaderTitle = styled('p')`
  margin: 0;
  color: #424242;
  font-weight: 600;
  font-size: 2rem;

  @media (min-width: 992px) {
    font-size: 20px
  }
`;

export const SupplierCardMainWrapper = styled('div')`
  margin-top: 20px;
  margin-bottom: 52px;
`;

export const DeliveryAddressCardHeaderSubTitle = styled(Button)`
  margin: 0;
  color: #FFFFFF;
  background: #000000;
  font-size: 2rem;
  line-height: 32px;
  outline: none;
  font-family: Montserrat,medium,sans-serif;
  &:hover {
    color: #000000;
  }

  @media (min-width: 992px) {
    font-size: 16px
  }
`;

export const DeliveryAddressCardLocation = styled('p')`
  font-size: 2rem;
  line-height: 40px;

  @media (min-width: 992px) {
    font-size: 20px
  }
`;

export const PaymentSummaryRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const PaymentSummaryRowTitle = styled('p')`
  color: #424242;
  font-size: 2rem;
  line-height: 32px;
  margin: 0;

  &:nth-child(2n) {
    margin-left: 6rem;
  }

  @media (min-width: 992px) {
    font-size: 20px
  }
`;

export const PaymentSummaryRowBoldTitle = styled('p')`
  color: #424242;
  font-size: 2rem;
  line-height: 32px;
  font-weight: 600;
  margin: 0;

  &:nth-child(2n) {
    margin-left: 6rem;
  }

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const PaymentSummaryGrandTotalRow = styled('div')`
  display: flex;
  align-items: center;
  border-top: 1px solid #E8E8E8;
  padding-top: 20px;
  margin-top: 20px;

  @media (max-width: 991px) {
    padding-top: 35px;
    border-top: 2px solid #ECECEC;
  }
`;

export const SupplierCardPaper = styled(Paper)`
  margin-top: 45px;
  margin-bottom: 52px;
  border-radius: 15px;
  width: 100%;

  @media (min-width: 992px) {
    margin-top: 20px;
    border-radius: 4px;
  }
`;

export const EmptyCardPaper = styled(Paper)`
  margin-top: 45px;
  margin-bottom: 52px;
  border-radius: 15px;
  min-height: 455px;
  width: 100%;

  @media (min-width: 992px) {
    margin-top: 20px;
    border-radius: 4px;
  }
`;

export const CartBottomActionsButtonContainer = styled('div')`
  margin: 50px 0;
  display: flex;
  justify-content: flex-end;

  @media(max-width: 991px) {
    justify-content: center;
  }
`;

export const PageTitleContainer = styled('div')`
  margin-right: auto;
`;

export const DialogCancelButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  color: #424242;
  border-radius: 0;
  border-radius: 10px;
  margin-right: 7px;
  border: 1px solid #eee;
`;

export const ManualDialogDoneButton = styled(IconButton)`
  height: 40px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 5px;
  margin-right: 25px;
  background-color: #FCED50!important;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }
`;
export const ManualDialogCancelButton = styled(ManualDialogDoneButton)`
    background-color: #fff!important;
    color: #424242;
    border: 1px solid grey;
`;

export const ManualDialogPadder = styled(Grid)`
    padding: 30px 80px;
`;

export const ManualPlaceOrder = styled('h2')`
    font-weight: 600;
`;

export const DialogOkButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  border-radius: 5px;
  margin-right: 7px;
  background-color: #FCED50;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }
`;

export const NoteButton = styled(IconButton)`
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 22px;
  border: 1px solid #606060;
  outline: none;

  &:not(:last-of-type) {
    margin-right: 20px;
  }
`;

export const ChangeLocationButton = styled('button')`
  background-color: white;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #424242;
  outline: none;

  &:not(:last-of-type) {
    margin-right: 50px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ChangeLocationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
`;

export const ChangeLocationLowRow = styled('div')`
  display: flex;

  & > * {
    margin-right: 15px;
  }
`;
export const ChangeOutletRow = styled(Grid)`
  & > * {
    font-size: 2rem;

    @media(min-width: 992px) {
      font-size: 0.875rem;
    }
  }
`;
export const SelectOutletText = styled('p')`
  font-size: 1.3rem;
  color: #606060;

  @media(max-width: 991px) {
    font-size: 1.7rem;
  }
`;
export const LocationTextField = styled(TextField)`
  background-color: #F0F0F0;
  height: 55px;

  label + .MuiInput-formControl {
    margin-top: 21px;
  }

  label {
    left: 20px;
    top: 5px;
    color: #A3A3A3;
  }

  input, textarea {
    padding-left: 20px;
    background-color: #F0F0F0;
    color: #424242;
    font-size: 16px;
    margin-top: 5px;
  }

  @media (max-width: 991px) {
    height: 80px;

    label + .MuiInput-formControl {
      margin-top: 23px;
    }

    label {
      font-size: 1.6rem;
    }

    input, textarea {
      font-size: 2rem;
    }
  }
`;

export const NotesText = styled(Typography)`
  color: #858383;
  font-weight: 500;
  font-size: 14px;
  overflow: auto;

  @media(max-width: ${sm}) {
    font-size: 12px;
  }
`;

export const ManualMethodImage = styled('img')`
  width: 6rem;
  display: block;
  margin-bottom: 30px;
  @media(min-width: 992px) {
    width: 4rem;
  }
`;
export const CartImage = styled('img')`
  width: 3.5rem;
  display: block;
  @media(min-width: 992px) {
    width: 2.5rem;
  }
`;
export const ManualSupplierItem = styled(Grid)`
  margin-right: 20px;
`;
export const OptionText = styled(Grid)`
  margin-bottom: 15px;
  color: #424242;
  font-weight: 500;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const TitleText = styled(Typography)`
  font-size: 2.25rem;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 1.25rem;
  }
`;

export const ContentText = styled(DialogContentText)`
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const TableHeader = styled(TableHead)`
  display: table-header-group;
  background-color: #F4F5F7;

  @media (max-width: 991px) {
    display: none;
  }
`;
export const MainTableRow = styled(TableRow)`
  border-top: 1px solid #E7E8E9;
  & > * {
    border-bottom: unset;
  }
`;

export const Header = styled(TableCell)`
  text-transform: uppercase;
  padding: 25px 5px;
  font-weight: 600;
  font-size: 1rem;

  :first-child {
    width: 40%;
  }
  :last-child {
    width: 10%;
  }

  @media(min-width: 992px) {
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 20%;
    }
  }
`;

export const ViewMore = styled(Typography)`
transform: rotate(90deg);
font-weight: 700;
font-size: 1rem;
margin-top: 1rem;
text-align: center;
&:hover {
  cursor: pointer;
}

@media(max-width: 991px) {
  font-size: 2rem;
}
`;

export const GridContainer = styled(Grid)`
    padding: 5px 25px;
    width: 25rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 231px
    }
`;

export const EditContainer = styled(Grid)`
    padding: 5px 25px;
    width: 25rem;
    align-items: center;
    cursor: pointer;
    background: #F4F5F6;

    @media(min-width: 992px) {
      padding: 0px;
      width: 231px
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    border-radius: 10px;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 2rem;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 1.2rem;
    padding: 6px 12px;
  }
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

export const TableWrapper = styled(TableContainer)`
  padding: 15px;
`;

export const TextWrapper = styled(Grid)`
  justify-content: center;
  align-items: center;
  width: 50%;
  text-align: center;
  margin: 6rem 2rem 1rem 14rem;
  @media(max-width: 991px) {
    width: 70%;
    margin: 6rem 2rem 1rem 8rem;
  }
`;

export const TextHeader = styled(Typography)`
  font-size: 1.2rem;
  color: #303030;
  padding-bottom: 25px;
  font-weight: 600;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const TextBody = styled(Typography)`
  font-size: 1rem;
  color: #606060;
  padding-bottom: 10px;
  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const PaperCard = styled(Paper)`
  box-shadow: 1px 1px 20px #f8f8f82e;
  border-radius: 12px;
  width: 100%;
  min-height: 30rem;
  padding: 2rem 1rem;
`;

export const CardText = styled(Typography)`
  font-size: 16px;
  color: #606060;
  font-weight: 500;
  margin-bottom: 1rem;

  @media(max-width: 991px) {
    font-size: 1.2rem;
  }
`;

export const CardLogo = styled('img')`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  background-color: white;

  @media (max-width: ${sm}) {
    width: 50px;
    height: 50px;
  }
`;

export const CardHeaderGrid = styled(Grid)`
  padding-bottom: 15px;
  border-bottom: 1px solid #E8E8E8;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: ${sm}) {
    padding-bottom: 10px;
  }
`;

export const TotalsGrid = styled(Grid)`
  justify-content: flex-end;
  padding: 1rem 2rem;
`;

export const DivFlex = styled('div')`
  display: flex;
  gap: 0.5rem
`;

export const CardHeaderText = styled(Typography)`
  color: #303030;
  font-size: 16px;
  font-weight: 700;

  @media (max-width: ${sm}) {
    font-size: 13px;
  }
`;

export const NoteHeaderGrid = styled(Grid)`
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: center;

  @media(max-width: ${sm}) {
    padding-bottom: 10px;
  }
`;

export const Emoji = styled('img')`
  width: 60px;
`;

export const EmojiText = styled(Typography)`
  font-size: 16px;
  color: #A3A3A3;
  margin-bottom: 10px;
`;
export const SmallText = styled(Typography)`
  color: #424242;
  font-size: 18px;
`;

export const ViewAll = styled(Typography)`
  color: #235A91;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`;

export const Divider = styled('div')`
  height: 1px;
  background: rgba(0, 0, 0, 0.20);
  margin-bottom: 1rem;
`;

export const NoteContainer = styled('div')`
  width: 100%;
  margin-bottom: 1rem;
`;

export const FlexStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NameStyled = styled(Typography)`
  color: #606060;
  font-size: 12px;
  font-weight: 600;
`;

export const TimeStyled = styled(Typography)`
  color: #82849F;
  font-size: 12px;
  font-weight: 600;
`;

export const NoteBody = styled('div')`
  width: 100%;
  height: auto;
  padding: 12px 16px;
  border-radius: 8px;
  background: #F3F9FF;
  margin: 0.7rem 0 1.2rem 0;
  color: #606060;
  cursor: pointer;
`;
