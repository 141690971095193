import { gql } from '@apollo/client';

export const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategory(
    $categoryName: String!
    $loyaltyWeight: String!
    $vatStatus: String!
    $markup: Float
  ) {
    createCategory(
      categoryName: $categoryName
      loyaltyWeight: $loyaltyWeight
      vatStatus: $vatStatus
      markup: $markup
    ){
      message
      category {
        id,
        categoryName
        status
        loyaltyWeight
        markup
        vatStatus
      }
    }
  }
`;

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation updateCategory(
    $categoryId: String!
    $categoryName: String!
    $loyaltyWeight: String!
    $vatStatus: String!
    $markup: Float
  ) {
    updateCategory(
      categoryId: $categoryId
      categoryName: $categoryName
      loyaltyWeight: $loyaltyWeight
      vatStatus: $vatStatus
      markup: $markup
    ){
      message
      category {
        id
      }
    }
  }
`;

export const CREATE_CLOSE_TILL = gql`
  mutation closeTill(
    $cashTotal: Int!
    $cardTotal: Int!
    $bankTotal: Int!
    $saleAmount: Int!
    $footfall: Int!
    $note: String
    $storeCredit: Float!
  ) {
    closeTill(
      cashTotal: $cashTotal
      cardTotal: $cardTotal
      bankTotal: $bankTotal
      saleAmount: $saleAmount
      footfall: $footfall
      note: $note
      storeCredit: $storeCredit
    ){
      message
    }
  }
`;

export const DELETE_PRICE_CATEGORY_MUTATION = gql`
mutation deleteCategory(
  $categoryId: String!
) {
  deleteCategory(
    categoryId: $categoryId
  ){
    message
  }
}
`;
