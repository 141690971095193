import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, TableRow, TableBody, useMediaQuery, Box
} from '@mui/material';
import CTablePagination from '../../../customComponents/cTablePagination';
import {
  TableContainerWrapper, TableComponent, ErpHeader, FooterGrid,
  MainTableHead, MobileContainer, SortImg
} from './orders.styles';
import ReturnRow from './returnRow';
import OrdersFilter from './ordersFilter';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import { useStateValue } from '../../../../providers/stateProvider';
import { JSONParse } from '../../../../utils/json';
import sort from '../../../../assets/images/marketOrder/sort.png';
import { addHyphen } from '../../../shared/methods';

const headers = [
  'order date', 'order number', 'value', 'affiliate', 'estimated delivery', 'order status',
];

const Orders = ({
  state, data, loading, error, handleSetFilter, handleFilterChange, totalCount, handleSort,
  page, handleChangePage, currentPageCount, handleChangeRowsPerPage, handleClear, handleDateSubmit, refetch
}) => {
  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);

  const [{ user: { isSupplyChain } }] = Object.values(useStateValue());
  const isSmall = useMediaQuery('(max-width:991px)');

  const returnHeaders = () => headers.map((header) => (
    <ErpHeader className={`mpAdmin-uat-ordersPage-${addHyphen(header)}`} key={header}>{header}</ErpHeader>
  ));

  const { openAndClosedOrders = [] } = data ?? '';
  const xeroStatuses = ['authorised', 'paid', 'voided'];

  const orderItems = openAndClosedOrders.map((openOrder) => {
    const {
      business: { name: businessName }, paymentSummary, id,
      supplierorderSet, metaStatus, meta, status, parentOrderId,
      datePlaced,
    } = openOrder;
    const { total: orderTotal, savings: orderSavings } = JSONParse(paymentSummary);
    const { xero_status: xeroStatus, xero_approval: approved } = JSONParse(meta);
    const suppliers = supplierorderSet.map(({ supplier: supplierItem }) => supplierItem);
    if (!isSupplyChain || (isSupplyChain && xeroStatuses.includes(xeroStatus))) {
      return {
        businessName, datePlaced, orderTotal, orderSavings, id, suppliers, metaStatus, status, parentOrderId, approved
      };
    }
    return null;
  });

  if (error) return <div>{error.message}</div>;
  const handleMobileFilter = () => {
    setDisplayMobileFilter(!displayMobileFilter);
  };

  return !isSmall ? (
    <Box container style={{ width: '100%' }}>
      <TableContainerWrapper component={Paper}>
        <OrdersFilter
          state={state}
          handleFilterChange={handleFilterChange}
          handleSetFilter={handleSetFilter}
          handleDateSubmit={handleDateSubmit}
        />
        {loading ? <ProductsTableLoader /> : (
          <TableComponent aria-label="Orders table">
            <MainTableHead>
              <TableRow>
                <ErpHeader className="mpAdmin-uat-ordersPage-order-date" />
                {returnHeaders()}
                {state?.status === 'open' ? (
                  <ErpHeader style={{ cursor: 'pointer' }} onClick={handleSort}>
                    Action
                    <SortImg src={sort} alt="sort" />
                  </ErpHeader>
                ) : ''}
                <ErpHeader />
              </TableRow>
            </MainTableHead>
            {orderItems && (
            <TableBody>
              {orderItems?.map((order, index) => (
                <ReturnRow
                  key={order?.id}
                  row={order}
                  user={isSupplyChain}
                  index={index}
                  refetch={refetch}
                />
              ))}
            </TableBody>
            )}
          </TableComponent>
        )}
      </TableContainerWrapper>
      <FooterGrid item container>
        <CTablePagination
          rowsPerPageOptions={[20, 50, 100, 150, 200]}
          component="div"
          count={totalCount}
          page={page - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid>
    </Box>
  ) : (
    <MobileContainer>
      <OrdersFilter
        state={state}
        handleFilterChange={handleFilterChange}
        handleSetFilter={handleSetFilter}
        handleMobile={handleMobileFilter}
        orderItems={orderItems}
        loading={loading}
        handleClear={handleClear}
        count={totalCount}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={currentPageCount}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        refetch={refetch}
      />
    </MobileContainer>
  );
};

Orders.propTypes = {
  state: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  currentPageCount: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleDateSubmit: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired
};

Orders.defaultProps = {
  state: {},
  data: {},
  loading: false,
};

export default Orders;
