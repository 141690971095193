import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import { CustomCheckbox } from './preloaded.styles';
import ActionPopper from './actionPopper';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import { useStateValue } from '../../providers/stateProvider';

const ReturnRow = ({
  row, sn, handleSelect, selected, deleteHandler, refetch
}) => {
  const [, dispatch] = Object.values(useStateValue());

  const {
    id, sku, brandName, packSize, dateCreated, manufacturer: { name }
  } = row;

  const [action, setAction] = useState(null);

  const isSelected = () => selected.indexOf(row.id) !== -1;

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row.id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  const handleAction = (event, type,) => {
    if (type === 'edit_product') {
      dispatch({
        type: ProductActionTypes.UPDATE_PRODUCT,
        payload: { productRow: { ...row, manufacturer: name }, refetch }
      });
      dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
    } else if (type === 'delete_product') {
      deleteHandler([Number(id)]);
    }
    handleOptions(event);
  };

  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');

  return (
    <MainTableRow>
      <BodyCell>{renderCheckbox()}</BodyCell>
      <BodyCell>{sn}</BodyCell>
      <BodyCell>{renderDate}</BodyCell>
      <BodyCell style={{ width: '10rem' }}>{sku || 'N/A'}</BodyCell>
      <BodyCell>{brandName}</BodyCell>
      <BodyCell>{packSize}</BodyCell>
      <BodyCell>
        <ActionPopper
          handleAction={handleAction}
          row={row}
        />
      </BodyCell>
      <BodyCell />
    </MainTableRow>
  );
};
ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  sn: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  deleteHandler: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
