import React, { useState } from 'react';
import {
  Grid, TableBody, Paper, Table, Button
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import { FIND_CUSTOMERS_QUERY } from '../../queries/customers';
import TablePagination from '../shared/tablePagination';
import SaleActionTypes from '../../providers/reducers/sales/salesTypes';
import IndividualCustomerDialog from './individualCustomerDialog';
import { TableColumnHeaders, TableHeader } from '../shared/tableGlobalStyle.styles';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import { SortIcon } from '../../assets/svgs';
import ActionButtonsPopper from './actionButtonsPopper';
import CustomExportButton from '../downloads/exportModule/exportComponent';
import ReturnRow from './returnRow';
import ConfirmDialog from '../shared/confirmDialog';
import { DELETE_CUSTOMER_MUTATION } from '../../mutations/customers';
import CustomerCSVUpload from './customerCSVUpload';
import {
  OrderGridContainer, CartGridContainerHeader, PageTitleContainer, PageTitleText, PageTitleSubText, PaperWrapper, FilterGrid, TableContainerWrapper, TableComponent,
  PaperExport, TotalRecord, TableCellStyled, MainTableRow
} from './index.styles';
import CustomSearchField from '../shared/CustomSearchField';
import { useStateValue } from '../../providers/stateProvider';
import ComplianceDialog from './complianceDialog';

const Customers = () => {
  const navigate = useNavigate();

  const [, dispatch] = Object.values(useStateValue());

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openIndividualCustomerModal, setOpenIndividualCustomerModal] = useState(false);
  const [search, setSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [sort, setSort] = useState('NONE');
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [complianceDialog, setComplianceDialog] = useState(false);
  const [ndpr, setNdpr] = useState(false);
  const [proceed, setProceed] = useState(false);

  const openDialogHandler = () => setOpenDialog(!openDialog);

  const {
    loading, data, refetch
  } = useQuery(FIND_CUSTOMERS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      search,
      pageCount,
      pageNumber,
      sort
    },
  });

  const businessTotalNumber = data?.businessTotalNumber || 0;
  const customers = data?.findCustomer || [];

  if (proceed) {
    setComplianceDialog(false);
    openDialogHandler();
    setProceed(false);
    setNdpr(false);
  }

  const handleAction = (action, setOpen) => {
    switch (action) {
      case 'add_individually': return setOpenIndividualCustomerModal(true);
      case 'add_by_csv': return setComplianceDialog(true);
      default:
        break;
    }
    setOpen(false);
  };

  const headers = ['S/N', 'Name', 'Loyalty Point', 'Wallet Balance', 'PAD Letter', 'Action'];
  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header}>{header}</TableHeader>
  ));

  const handleClick = (customer) => {
    dispatch({
      type: SaleActionTypes.UPDATE_CUSTOMER,
      payload: { ...customer }
    });
  };

  const actionHandler = (e, action, res) => {
    switch (action) {
      case 'view':
        return navigate(`/prescription/${res?.id}`);
      case 'edit':
        setOpenIndividualCustomerModal(true);
        handleClick(res);
        break;
      case 'delete':
        setCustomerId(res?.id);
        return setOpenConfirmDialog(true);
      default:
        break;
    }
  };

  const [deleteCustomer, { loading: deloading }] = useMutation(DELETE_CUSTOMER_MUTATION);

  if (confirmStatus) {
    deleteCustomer({
      refetchQueries: ['customers'],
      variables: {
        customerId
      }
    })
      .then(({ resp }) => {
        const { message } = resp?.deleteCustomer || {};
        setOpenConfirmDialog(false);
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    setConfirmStatus(false);
  }

  const sortHandler = () => {
    switch (sort) {
      case 'NONE': return setSort('ASC');
      case 'ASC': return setSort('DESC');
      case 'DESC': return setSort('NONE');
      default:
        break;
    }
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <CartGridContainerHeader item container>
          <Grid container xs={12} md={10} lg={10} alignItems="center" justify-content="space-between">
            <PageTitleContainer>
              <PageTitleText>Customers</PageTitleText>
              <PageTitleSubText>Business Customers on Portal</PageTitleSubText>
            </PageTitleContainer>
          </Grid>
          <Grid>
            <ActionButtonsPopper handleAction={handleAction} />
          </Grid>
        </CartGridContainerHeader>

        <PaperExport elevation={0}>
          <Grid container style={{ alignItems: 'center' }}>
            <Grid lg={11}>
              <TotalRecord>{`${businessTotalNumber} customer(s)`}</TotalRecord>
            </Grid>
            <Grid lg={1}>
              <CustomExportButton
                title="Export"
                color="bordered"
                fileName="customers"
                large="large"
                icon="withIcon"
                name="customers"
              />
            </Grid>
          </Grid>
        </PaperExport>

        <PaperWrapper elevation={0}>
          <FilterGrid item container spacing={2}>
            <Grid item container xs={11}>
              <CustomSearchField
                name="search"
                value={search}
                fullWidth
                placeholder="Search"
                handleChange={(e) => setSearch(e?.target?.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                onClick={sortHandler}
                style={{
                  width: '100%',
                  border: '1px solid #E7E8E9',
                  color: '#606060',
                  boxShadow: 'none', borderRadius: '8px',
                  fontSize: '14px', fontWeight: '500'
                }}
              >
                <SortIcon style={{ marginRight: '2px' }} />
                Sort
              </Button>
            </Grid>
          </FilterGrid>
          <TableContainerWrapper component={Paper}>
            {loading ? <ProductsTableLoader />
              : (
                <TableComponent aria-label="affiliates table">
                  <Table>
                    <TableColumnHeaders>
                      {returnHeaders()}
                    </TableColumnHeaders>
                    <TableBody>
                      {customers.length
                        ? customers?.map((item, index) => (
                          <ReturnRow
                            actionHandler={actionHandler}
                            idx={index}
                            row={item}
                            key={item?.id}
                          />
                        )) : (
                          <MainTableRow>
                            <TableCellStyled colSpan={6} style={{ textAlign: 'center' }}>No record found</TableCellStyled>
                          </MainTableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableComponent>
              )}
            {customers?.length > 0 && (
            <TablePagination
              total={businessTotalNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
            )}
          </TableContainerWrapper>
        </PaperWrapper>
      </OrderGridContainer>
      <IndividualCustomerDialog
        dialogOpen={openIndividualCustomerModal}
        closeDialog={() => setOpenIndividualCustomerModal(false)}
      />
      <CustomerCSVUpload
        openDialog={openDialog}
        toggleHandler={openDialogHandler}
        refetch={refetch}
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Remove Customer"
        desc="Sure you want to remove customer?"
        options={['Cancel', 'Yes, Remove']}
        setStatus={setConfirmStatus}
        loading={deloading}
      />

      <ComplianceDialog
        openDialog={complianceDialog}
        setOpenDialog={setComplianceDialog}
        ndpr={ndpr}
        setNdpr={setNdpr}
        setProceed={setProceed}
      />
    </MainContent>
  );
};

export default Customers;
