import {
  TableCell, TableHead, TableRow, MenuItem, OutlinedInput, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableCellStyled = styled(TableCell)`
  font-size: .9rem;
  font-weight: 600;
  border-bottom: none;
`;

export const TableCellBStyled = styled(TableCell)`
  border-bottom: none;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #ECF6FF;
  font-weight: bold;
`;

export const TableRowStyled = styled(TableRow)`
    cursor: pointer;
    border: none;
    ${({ cond }) => cond && 'background: #ECF6FF;'}
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 5rem 0.9rem 1rem;
  font-size: 15px;
`;

export const OutlinedInputStyled = styled(OutlinedInput)`
  width: 100%;
  margin: 1rem 0;
  border-radius: 1rem !important;
`;

export const BoxStyled = styled(Box)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;
