import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import registerServiceWorker from './registerServiceWorker';
import getApolloClient from './graphql';
import './assets/styles/style.css';
import theme from './assets/theme';
import App from './components/App';
import StateProvider from './providers/stateProvider';
import CToaster from './components/shared/CToaster';

const root = ReactDOM.createRoot(document.getElementById('app'));
getApolloClient().then((client) => {
  root.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CToaster />
          <BrowserRouter>
            <ApolloProvider client={client}>
              <StateProvider>
                <CssBaseline />
                <App />
              </StateProvider>
            </ApolloProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
});

if (module.hot) {
  module.hot.accept();
}

registerServiceWorker();
