import {
  Dialog, Typography, Grid, Checkbox, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const CDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
  }
`;

export const CTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
  color: #303030
`;

export const CSubTitle = styled(Typography)`
  font-size: .75rem;
  font-weight: 500;
  padding-top: .5rem;
  color: #303030
`;

export const ButtonGrid = styled(Grid)`
  padding: 1.5rem 1rem 0;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #235A91;
  padding: 6px;
`;

export const CMenuItem = styled(MenuItem)`
  padding: 16px;
  background-color: ${({ checked }) => (checked ? '#F3F9FF' : '#fff')};
`;

export const SelectNameTypo = styled(Typography)`
  font-size: .85rem;
  color: #303030;
  margin-left: 0.8rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;
