import { Popper, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PopperContainer = styled(Popper)`
  z-index: 15000000;
  
  @media(max-width: 991px) {
    margin-top: 8rem;
    margin-left: 17rem;
  }
`;

export const WrapperPaper = styled(Paper)`
  z-index: 1500;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: 12px;
`;

export const Arrow = styled(Box)`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  border-bottom: 15px solid white;
  top: -12px;
  right: 18px;
`;
