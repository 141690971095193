import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TabsGrid = styled(Grid)`
  padding: 1rem 0;
  align-items: center;
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ active }) => (active ? '3px solid #235A91' : '3px solid #ECF6FF')};
  width: ${({ width }) => width};
`;

export const Typo = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  color: ${({ active }) => (active ? '#1A0E00' : '#C0C0C1')};
  display: flex;
  align-items: center;
`;

export const TotalsGrid = styled('div')`
  border-radius: 30%/50%;
  height: 14px;
  margin-left: 5px;
  background: #ee4223;
  color: #fff;
  text-align: center;
  font-size: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 2px;
`;
