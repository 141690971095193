import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { Box } from '@mui/material';
import {
  ProductCardsContainer, ProductCardGrid, NoResultsText,
  FooterGrid,
} from './newOrder.styles';
import ProductCard from './productCard';
import CatalogueActionTypes from '../../../providers/reducers/catalogue/catalogueTypes';
import { NEW_ORDER_PRODUCTS_PAGE_QUERY } from '../../../queries/products';
import { useStateValue } from '../../../providers/stateProvider';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import TablePagination from '../../shared/tablePagination';

const NewOrder = ({
  state,
}) => {
  const {
    searchValue, priceMin,
    priceMax, therapeuticTypeIds, availabilityStatus, manufacturer
  } = state;

  const handleAlertOpen = (msg) => toast(msg);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);

  const [, dispatch] = Object.values(useStateValue());

  const updateCatalogueState = (products, newOrderPageProductsTotalNumber) => {
    dispatch({
      type: CatalogueActionTypes.UPDATE_CATALOGUE,
      payload: {
        products,
        totalCount: newOrderPageProductsTotalNumber
      }
    });
  };

  const { loading, data } = useQuery(NEW_ORDER_PRODUCTS_PAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: searchValue,
      // suppliers,
      therapeuticTypeIds,
      manufacturer,
      priceMin: priceMin ? parseFloat(priceMin) : false,
      priceMax: priceMax ? parseFloat(priceMax) : false,
      pageCount,
      pageNumber,
      availabilityStatus: (availabilityStatus === 'availableIn24') ? 'true' : (availabilityStatus === 'availableIn72') ? 'false' : '',
    }
  });

  useEffect(() => {
    if (data && data.newOrderPageProducts) {
      const { newOrderPageProducts: products, newOrderPageProductsTotalNumber } = data;
      updateCatalogueState(products, newOrderPageProductsTotalNumber);
    }
  }, [data]);

  const { newOrderPageProducts: products = {}, newOrderPageProductsTotalNumber = 0 } = data || {};

  return loading
    ? <NewOrdersLoader />
    : (
      <Box style={{ marginTop: '.5rem' }}>
        <ProductCardsContainer container spacing={3}>
          {products.length
            ? products.map((product, index) => (
              <ProductCardGrid key={product.id} item lg={3} md={12} xs={12}>
                <ProductCard
                  product={product}
                  openAlert={handleAlertOpen}
                  index={index}
                />
              </ProductCardGrid>
            )) : (
              <NoResultsText>
                No results found matching :
                {searchValue}
              </NoResultsText>
            )}
        </ProductCardsContainer>
        <FooterGrid item container className="joy-new-order-pagination">
          <TablePagination
            total={newOrderPageProductsTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            hideRowsPerPage
            displayShowResult
          />
        </FooterGrid>
      </Box>
    );
};

NewOrder.propTypes = {
  state: PropTypes.instanceOf(Object),
};

NewOrder.defaultProps = {
  state: {},
};

export default NewOrder;
