import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText, Slide, Grid, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import MapCsvDialog from './mapCsvDialog';
import { useStateValue } from '../../providers/stateProvider';
import {
  CardTitle, DialogContentStyled, TitleDesc, DialogContainer
} from './createBndP.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function CreateProduct({ openProdDialog, toggleHandler, storeIds }) {
  const [{ user: { platform } }, dispatch] = Object.values(useStateValue());
  const onErp = platform === 'erp';
  const fields = [
    ['Product Brand Name', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Molecules', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Description', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
    }],
    ['Pack Size', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Supplier', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Manufacturer', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Therapeutic Type', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Barcode', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Category', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Unit Of Measurement', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str, required'
    }],
    ['Order Cost', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,required,money'
    }],
    ['Fixed Selling Price', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,required,money'
    }],
    ['Use Fixed Selling Price', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,required'
    }],
    ['Promo Value', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,nullable,money'
    }],
    ['Reorder Unit', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Reorder Point', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Reorder Max', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Item Number', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    }],
    ['Create If Not Exist(Yes/No)', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true,
      rules: 'str,required'
    }],
    ['Product Variant', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,nullable'
    }],
    ['Minimum Order Quantity', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Max Lead Time', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Safety Stock', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Product Class', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,required'
    }],
    ['Buffer', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,nullable'
    }],
    ['Status', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,required'
    }],
  ];
  const experimentalFields = [
    ['Service Provider', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    }],
    // ['Original Name', {
    //   required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    // }],
  ];

  if (!`${platform}`) {
    return <div />;
  }

  return (
    <DialogContainer
      data-testid="product-dialog"
      open={openProdDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>Import CSV</CardTitle>
              <Close
                fontSize="small"
                onClick={toggleHandler}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <TitleDesc>Upload CSV File To Add Bulk Product</TitleDesc>
            <MapCsvDialog
              experimentalFields={experimentalFields}
              fields={fields.filter((item) => item[1].show)}
              model="product"
              endpoint="products"
              backRoute="products"
              storeIds={storeIds}
            />
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
}

CreateProduct.propTypes = {
  openProdDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
  storeIds: PropTypes.instanceOf(Array),
};

CreateProduct.defaultProps = {
  openProdDialog: false,
  storeIds: [],
};
