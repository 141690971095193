import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';

export const MenuItemStyled = styled(MenuItem)`
  padding: .7rem 1rem;
  color: #606060;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    background: #f3f9ff;
  }
  &:active{
    background: #f3f9ff;
  }
`;
