import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Grow, Paper, Popper, MenuList
} from '@mui/material';
import { Dots } from '../../../assets/svgs';
import { MenuItemStyled, ButtonEllipsis } from './actionPopper.styles';

export default function ActionPopper({ row, handleAction }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { status } = row;
  const statusOption = () => {
    if (status !== 'IN_ACTIVE') return true;
    return false;
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonEllipsis
        data-testid="btn-popper"
        onClick={handleToggle}
        ref={anchorRef}
      >
        <Dots fontSize="small" />
      </ButtonEllipsis>
      <Popper
        data-testid="popper"
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled
                    data-testid="product_status"
                    onClick={() => handleAction(setOpen)}
                  >
                    {statusOption() ? 'Disable' : 'Enable'}
                  </MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
};
