import { ApolloClient } from '@apollo/client/core';
import { ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { OgaToken } from './token';

const API_HOST = OgaToken.APP_LINK;

const getApolloClient = async () => {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('oga_auth_token') || OgaToken.authToken;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : '',
      }
    };
  });

  const httpLink = new HttpLink({
    uri: API_HOST,
  });

  const cache = new InMemoryCache();

  return new ApolloClient({
    link: ApolloLink.from([
      authLink,
      httpLink
    ]),
    cache
  });
};

export default getApolloClient;
