import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@mui/material';
import { AffiliateBodyCell, MainTableRow } from '../dashboard/dashboard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';

const ReturnMissedSalesRow = ({
  missedSale, history
}) => {
  const {
    id,
    sale: {
      dateCreated,
      receiptNumber,
      businessUser: {
        user: {
          username
        }
      }
    },
    name,
    packSize,
    quantity,
    total,
  } = missedSale;
  return (
    <TableColumnRows className="erp-uat-product-tb-row" style={{ cursor: 'pointer' }}>
      <TableColumnRowText>{dateCreated ? moment(dateCreated).format('lll') : 'N/A'}</TableColumnRowText>
      <TableColumnRowText>
        {name}
      </TableColumnRowText>
      <TableColumnRowText className="erp-uat-product-tb-brandName">
        {quantity}
      </TableColumnRowText>
      <TableColumnRowText>{packSize}</TableColumnRowText>
      <TableColumnRowText>
        {username}
      </TableColumnRowText>
      <TableColumnRowText>
        {currencyFormatter(total)}
      </TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnMissedSalesRow.propTypes = {
  missedSale: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default ReturnMissedSalesRow;
