import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  CloseModal,
  WarningButton,
  WarningContainer,
  WarningDescription,
  WarningIconStyled,
  WarningTitle
} from './inventoryContainer.styles';

const WarningComp = () => {
  const navigate = useNavigate();
  const [warning, setWarning] = useState(true);

  return (
    <div>
      {warning && (
        <WarningContainer>
          <Box sx={{ display: 'flex', gap: '15px' }}>
            <WarningIconStyled />
            <Box>
              <WarningTitle>Out & Low on stock Products</WarningTitle>
              <WarningDescription>
                You have 100 products that are out & low on stock in your
                inventory
              </WarningDescription>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
              <WarningButton onClick={() => navigate('/low-on-stock')}>
                View Products
              </WarningButton>
              <CloseModal onClick={() => setWarning(false)} />
            </Box>
          </Box>
        </WarningContainer>
      )}
    </div>
  );
};

export default WarningComp;
