import {
  Dialog, Grid, Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { styled } from '@mui/material/styles';

export const PaymentDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const WrapperGrid = styled(Grid)`
  width: 30vw;
  min-height: 37vw;
  padding: 1.5rem 2rem;;
`;

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

export const ItemsGrid = styled(Grid)`
  padding-top: 1rem;
`;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;
