import { styled } from '@mui/material/styles';
import {
  Paper, Grid, Typography
} from '@mui/material';

export const WrapperGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

export const ChartGrid = styled(Grid)`
  height: 550px;
  width: ${({ range, loading }) => (range === 'month' || loading ? '100%' : '100rem')};
`;

export const InputGrid = styled(Grid)`
  padding: 7px 10px;
  justify-content: space-between;
  cursor: pointer;
  width: 10rem;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #E7E8E9;
  border-radius: 8px;
  align-items: center;
  text-transform: capitalize;
  margin-left: 1rem;
`;

export const GridTypo = styled(Typography)`
  font-size: 0.875rem;
  width: 8rem;
`;

export const ChartsTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  color: #303030;
`;

export const ChartPaper = styled(Paper)`
  margin-top: 1.5rem;
  width: 100%;
  height: 600px;
  padding: 1.5rem;
  overflow: scroll;
  border-radius: 10px;
  box-shadow: 0px 2.96px 66.64px rgba(163,171,185,0.24);
`;

export const Dropdown = styled('img')`
  width: 24px;
  height: 24px;
  margin-left: 0.7rem;
`;
