import {
  Grid,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Box,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: none;

  @media (min-width: 992px) {
    display: table-header-group;
  }
`;

export const DivFullWidth = styled('div')`
  width: 100%;
  margin: 1rem 0;
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const ErpHeader = styled(TableCell)`
  text-transform: uppercase;
  padding: 10px;

  :first-child,
  :last-child {
    width: 3%;
  }

  @media (min-width: 992px) {
    &:nth-child(5) {
      width: 25%;
    }
    &:nth-child(2),
    :nth-child(4),
    :nth-child(6) {
      width: 15%;
    }
    &:nth-child(3),
    :nth-child(7) {
      width: 12%;
    }
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const MinMaxContainer = styled(Box)`
  background-color: #f1f8ff;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 0.8rem;
  display: flex;
  gap: 5px;
`;

export const MinMaxTitle = styled(Typography)`
  color: #235a91;
  font-weight: 600;
  font-size: 16px;
`;

export const MinMaxDescription = styled(Typography)`
  color: #606060;
  font-weight: 400;
  font-size: 14px;
`;
