import {
  Box, Grid, Paper, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const SearchPaper = styled(Paper)`
  background: #fff;
  width: 100%;
  padding: 1rem;
  max-height: 26rem;
  overflow-y: scroll;
  border-radius: 12px;
`;

export const ProductItem = styled('div')`
  cursor: pointer;
  padding: 1rem 0;

  &:hover {
    background: #f3f9ff;
  }
`;

export const ProdName = styled(Grid)`
  font-size: 14px;
  font-weight: 400;
  color: #303030;
`;

export const NotfoundContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
  gap: 0.6rem;
  padding: 2rem 0;
`;

export const NotfoundTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;

export const NotfoundDescription = styled(Typography)`
  font-size: 13px;
  font-weight: 400;
  color: #303030;
`;

export const DFlex = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Preloaded = styled('div')`
  background: #01b588;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const UserAdded = styled('div')`
  background: #F29053;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;
