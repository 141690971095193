import { styled } from '@mui/material/styles';
import {
  TableCell, Paper, TextField, Button,
  Typography, Grid, TableBody
} from '@mui/material';
import { ExportIcon } from '../../assets/svgs';

export const ButtonImage = styled('img')`
  width: 5rem;
  display: block;

  @media(min-width: 992px) {
    width: 2rem;
  }
`;

export const TableColumnHeaderTitle2 = styled('p')`
  font-size: .75rem;
  color: #757575;
  font-weight: 400;
  line-height: 23px;
  white-space: nowrap;
  margin-bottom: 0;
`;

export const TableColumnRowText2 = styled('p')`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 23px;
  white-space: nowrap;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: .5rem;
  border-bottom: 1px solid rgba(224,224,224,1);

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const ShowingText = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: #303030;
`;

export const ExportIconStyled = styled(ExportIcon)`
  font-size: 1.5rem;
  color: '#606060';
  margin-right: 5px;
`;

export const CTableBody = styled(TableBody)`
  width: 100%;
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F4F5F7;

  @media(min-width: 992px) {
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
      width: 15%;
    }
    &:nth-child(7) :nth-child(1) {
      width: 10%;
    }
  }
`;
