import {
  Grid, Typography, IconButton, TextField, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Drawer from '@mui/material/Drawer';

export const OrderGridContainer = styled(Grid)`
  padding: 1rem;

  @media (min-width: 992px) {
    padding: 1rem 1.3rem 1.3rem 1.3rem;
  }
`;

export const FilterSide = styled('div')`
  width: 18%;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const ProductSide = styled('div')`
  width: 80%;
  margin-left: 1rem;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const BoxGrid = styled('div')`
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const OrderSubHeader = styled(Grid)`
  padding: 15px 0px;
  align-items: center;

  @media(max-width: 991px) {
    justify-content: space-between;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  border: 1px solid #E7E8E9;
  border-radius: 10px;
  height: 90px;
  width: 100%;
`;

export const SearchBox = styled(Grid)`
`;

export const FilterImg = styled('img')`
  width: 3rem;
`;

export const LeftDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorLeft {
    width: 100%;
  }
`;

export const SearchContainer = styled(Box)`
  align-items: center;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #E7E8E9;

  & .MuiOutlinedInput-input {
    font-size: 2rem;
    margin-top: 5px;
  };
  & .MuiOutlinedInput-root {
    border-radius: 10px;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };

  @media(min-width: 992px) {
    height: 2.7rem;
    & .MuiOutlinedInput-input {
      font-size: .9rem;
      font-weight: 500;
    };
    & .MuiOutlinedInput-adornedStart {
      padding-left: 30px;
    }
    & .MuiOutlinedInput-root {
      height: 2.5rem;
    };
  }

  @media (max-width: 991px) {
    border: 2px solid #E7E8E9;
    height: 95px;
    border-radius: 1.2rem;
  }

`;

export const SearchButton = styled(IconButton)`
  padding: ${(props) => (props.small ? '15px 20px' : '19px 28px')};
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:disabled {
    color: #424242;
  }

  @media(min-width: 992px) {
    padding: ${(props) => (props.small ? '5px 8px' : '10px 12px')};
  }
`;

export const SearchImage = styled('img')`
  width: 40px;

  @media(min-width: 992px) {
    width: ${(props) => (props.small ? '20px' : '22px')};
  }
`;

export const ClearIcon = styled(CloseIcon)`
  font-size: ${(props) => (props.small ? '1.25rem' : '22px')};

  @media(max-width: 992px) {
    font-size: ${(props) => (props.small ? '2.5rem' : '3.8rem')};
  }
`;

export const ContentBox = styled('div')`
  padding: 1rem;
  background: #ffffff;
`;

export const CategoriesBox = styled('div')`
  // padding: 1rem;

  @media (max-width: 991px) {
    margin-top: 3rem;
  }

  // @media (min-width: 992px) {
  //   padding: 1rem;
  // }
`;

export const CategoriesTitle = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 650;

  @media(min-width: 992px) {
    font-size: 1.5em;
    font-weight: 650;
    margin-bottom: 0;
  }
`;

export const ProductsBox = styled('div')`
`;
