// eslint-disable-next-line consistent-return
export const extractPrices = (rawProduct, platform) => {
  const {
    erpVersion: {
      priceInUse: priceInUseA
    }
  } = rawProduct;
  if (platform === 'erp') {
    if (!priceInUseA) return { erpPriceInUse: 'Automatic' };
    return {
      erpPriceInUse: priceInUseA.indexOf('fixed') > -1 ? 'Fixed' : 'Automatic'
    };
  }
  if (platform === 'marketplace') {
    const {
      marketplaceVersion: {
        priceInUse: priceInUseB
      }
    } = rawProduct;
    return {
      marketPlacePriceInUse: priceInUseB.indexOf('fixed') > -1 ? 'Fixed' : 'Automatic'
    };
  }
};
