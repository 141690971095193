import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Chart } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { ExportButton, LineBox, PaperBox } from '../dashboardContainer.styles';
import ActionButtonsPopper from './actionButtonsPopper';
import { EXPORT_GRAPH_REPORT } from '../../../mutations/reports';
import { SALES_ANALYTIC_GRAPH } from '../../../queries/dashboard';

const DashboardChart = () => {
  const [filter, setFilter] = useState('month');
  const fontStyle = { size: '16px', weight: 'bolder', color: '#000000' };
  const labels = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const [exportMutation] = useMutation(EXPORT_GRAPH_REPORT);

  const { loading, data: graphData } = useQuery(SALES_ANALYTIC_GRAPH, {
    fetchPolicy: 'cache-and-network',
    variables: { filterBy: filter }
  });

  const orderValueAnalysis = graphData?.salesAnalyticsGraph?.totalSalesValueGraph || [];
  const goodSoldAnalysis = graphData?.salesAnalyticsGraph?.totalSalesCostOfGoodsSoldGraph || [];

  const saleValueMonth = orderValueAnalysis?.map((value) => value.month);
  const saleOrderValue = orderValueAnalysis?.map((value) => +value.total);
  const goodSoldMonth = goodSoldAnalysis?.map((value) => value.month);
  const goodSoldValue = goodSoldAnalysis?.map((value) => +value.total);

  const orderValueWeek = [];
  for (let index = 1; index < orderValueAnalysis?.length; index++) {
    const construct = `Week ${index}`;
    orderValueWeek.push(construct);
  }

  const orderValueMonth = [];
  if (saleValueMonth) {
    saleValueMonth.forEach((val) => {
      orderValueMonth.push(labels[+val - 1]);
    });
  }

  const goodSoldWeek = [];
  for (let index = 1; index < goodSoldAnalysis?.length; index++) {
    const construct = `Week ${index}`;
    goodSoldWeek.push(construct);
  }

  const goodsSoldMonth = [];
  if (goodSoldMonth) {
    goodSoldMonth.forEach((val) => {
      goodsSoldMonth.push(labels[+val - 1]);
    });
  }

  const orderValuePeriod = () => {
    switch (filter) {
      case 'month':
        return orderValueMonth;
      case 'week':
        return orderValueWeek;
      default:
        return orderValueMonth;
    }
  };

  const goodSoldPeriod = () => {
    switch (filter) {
      case 'month':
        return goodsSoldMonth;
      case 'week':
        return goodSoldWeek;
      default:
        return goodsSoldMonth;
    }
  };

  const orderValueData = {
    labels: orderValuePeriod(),
    datasets: [
      {
        label: 'Sales by Value',
        backgroundColor: '#ff6b0f',
        borderColor: '#ff6b0f',
        data: saleOrderValue,
        tension: 0.1
      },
    ]
  };

  const goodsSoldData = {
    labels: goodSoldPeriod(),
    datasets: [
      {
        label: 'Cost of Goods Sold',
        backgroundColor: '#0cb970',
        borderColor: '#0cb970',
        data: goodSoldValue,
        tension: 0.1
      },
    ]
  };

  const ticks = {
    callback: (value) => {
      if (parseInt(value) >= 1000) {
        return `₦${parseInt(value).toLocaleString()}`;
      }
      return `₦${value}`;
    }
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: { display: true, text: 'Period', font: fontStyle },
        grid: { display: false }
      },
      y: {
        title: { display: true, text: 'Value', font: fontStyle },
        ticks
      }
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Sales by Value',
        align: 'start',
        font: fontStyle
      },
      legend: {
        onClick: (e) => e.stopPropagation()
      }
    }
  };

  // Cost of Goods Sold option
  const copiedOption = JSON.parse(JSON.stringify(options));
  copiedOption.plugins.title.text = 'Cost of Goods Sold';
  copiedOption.scales.y.ticks = ticks;
  copiedOption.plugins.legend.onClick = (e) => e.stopPropagation();

  const handleDownloadCSV = (csvType) => {
    const typeValue = { type: '', name: '' };
    const randomNum = Math.floor(Math.random() * 10000);
    if (csvType === 'orderValue') {
      typeValue.type = 'total_sales_value_graph';
      typeValue.name = `Sales by Value Graph Report ${randomNum}`;
    } else {
      typeValue.type = 'total_sales_cost_of_goods_sold_graph';
      typeValue.name = `Cost of Goods Sold Graph Report ${randomNum}`;
    }
    const { type, name } = typeValue;
    exportMutation({
      variables: { type, name, filterBy: filter }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleFilterChange = (filterType) => setFilter(filterType);

  return (
    <Box style={{ marginTop: '1rem' }}>
      <Grid container xl={12} style={{ marginBottom: '1.5rem' }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={9}>
            <Box fontWeight="fontWeightBold" fontSize={18} color="inherit">Sales Analytics</Box>
          </Grid>
          <Grid item container xs={3} justifyContent="flex-end">
            <Grid item fontSize={16} color="inherit">
              <ActionButtonsPopper filter={filter} handleAction={handleFilterChange} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PaperBox elevation={2} filter={filter === 'week'}>
            <Box data-testid="orderValueExport" style={{ textAlign: 'right' }}>
              <ExportButton onClick={() => handleDownloadCSV('orderValue')}>Export Csv</ExportButton>
            </Box>
            <LineBox width={filter === 'week' ? '70rem' : '100%'}>
              <Line data={orderValueData} options={options} />
            </LineBox>
          </PaperBox>
        </Grid>
        <Grid item xs={6}>
          <PaperBox elevation={2} filter={filter === 'week'}>
            <Box data-testid="goodsSoldExport" style={{ textAlign: 'right' }}>
              <ExportButton onClick={() => handleDownloadCSV('goodsSold')}>Export Csv</ExportButton>
            </Box>
            <LineBox width={filter === 'week' ? '70rem' : '100%'}>
              <Line data={goodsSoldData} options={copiedOption} />
            </LineBox>
          </PaperBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardChart;
