import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from '../../customComponents/customButton';

export const FiltersGrid = styled(Grid)`
  margin: 5px 0;
  padding-bottom: 1rem;
  justify-content: flex-end;
  align-items: center;
`;

export const ButtonsGrid = styled(Grid)`
  padding: 0 1.8rem 1.8rem;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0 1.8rem 1.8rem;
  }
`;

export const SetFilter = styled(CustomButton)`
  width: 6.5rem;
  height: 2.95rem;
  border-radius: 5px;
  background: #303030;
`;
