import { gql } from '@apollo/client';

export const FIND_MANUFACTURERS_QUERY = gql`
  query manufacturers(
    $search: String, $pageCount: Int, $pageNumber: Int
  ){
    manufacturers(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
    ){
        id
        name
        manufacturerProductCount
        totalProductValue
      }
    manufacturersTotalNumber
  }
`;
