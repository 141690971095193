import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Slide, Grid, Chip, FormControl, Button
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  CDialog,
  DialogTitleText,
  DialogTitleSubText,
  GridWrapper,
  FooterSec,
  CloseIcon,
  ButtonStyled,
  DialogContentStyled,
  TField
} from './expiredDialog.styles';
import { blockInvalidChar } from '../../../utils/mobileCheck';

const useStyles = makeStyles(() => ({
  formControlRoot: {
    display: 'flex',
    marginTop: '5px',
    alignItems: 'center',
    gap: '8px',
    width: '42rem',
    minHeight: '3.2rem',
    flexWrap: 'wrap',
    flexDirection: 'row',
    border: '1px solid #e1e1e1;',
    padding: 10,
    borderRadius: '10px',
    '&> div.container': {
      gap: '6px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    '& > div.container > span': {
      backgroundColor: 'gray',
      padding: '1px 3px',
      borderRadius: '4px'
    }
  }
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ExpiredDialog = ({
  dialogOpen,
  handleDialog,
  selectedExpired,
  setSelectedExpired,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState('');
  const [arrList, setArrList] = useState([]);

  const handleKeyUp = (e) => {
    const { value } = e.target;
    if (e.key === 'Enter') {
      const randomNum = Math.floor(Math.random() * 10000);
      const payload = { id: randomNum, value };
      const exist = selectedExpired.find((item) => item?.value === value);
      if (!exist && !values.includes(value) && value.length) {
        setValues([...values, value]);
        setArrList([...arrList, payload]);
      }
      setCurrValue('');
    }
  };

  const handleChange = (e) => {
    setCurrValue(e.target.value);
  };

  const summitHandler = () => {
    setSelectedExpired([...selectedExpired, ...arrList]);
    setArrList([]);
    setValues([]);
    handleDialog();
  };

  const handleDelete = (item, index) => {
    const arr = [...values];
    arr.splice(index, 1);
    setValues(arr);
  };

  return (
    <CDialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      fullWidth
      data-testid="expiredDialog"
      onClose={handleDialog}
    >
      <GridWrapper container>
        <Grid container>
          <Grid item xs={6}>
            <DialogTitleText container item xs={12}>
              Expired Product
            </DialogTitleText>
            <DialogTitleSubText container item xs={12}>
              Notify me on expired product
            </DialogTitleSubText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleDialog} />
          </Grid>
        </Grid>
        <DialogContentStyled>
          <DialogTitleSubText container style={{ marginTop: '20px' }}>
            When to notify me
          </DialogTitleSubText>
          <FormControl variant="standard" classes={{ root: classes.formControlRoot }}>
            <div className="container">
              {values.map((item, index) => (
                <Chip
                  size="medium"
                  onDelete={() => handleDelete(item, index)}
                  label={`${item} Days`}
                />
              ))}
            </div>
            <TField
              type="number"
              autoFocus
              name="expired"
              value={currValue}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              onKeyDown={blockInvalidChar}
              placeholder="Enter Duration"
              data-testid="expired_field"
            />
          </FormControl>
          <FooterSec>
            <Button variant="contained" data-testid="submitExpiredProd" onClick={summitHandler}>Submit</Button>
          </FooterSec>
        </DialogContentStyled>
      </GridWrapper>
    </CDialog>
  );
};

ExpiredDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func.isRequired,
  selectedExpired: PropTypes.instanceOf(Array).isRequired,
  setSelectedExpired: PropTypes.instanceOf(Array).isRequired
};

export default ExpiredDialog;
