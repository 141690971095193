import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  BellImg,
  Container,
  Duration,
  Header,
  HeaderContainer,
  Info,
  NotificationImg,
  TestWrapper
} from './styles';
import bell from '../../../assets/images/bell.gif';

const NotificationDialog = ({
  message, setMessage
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    setMessage({
      message: null,
    });
  }, [message]);

  const handleView = () => {
    if (message?.message?.message?.inbound_order_id) {
      navigate(`/inbound-details-info/${message?.message?.message?.inbound_order_id}/${message?.message?.message?.marketplace_order_id}`);
    }
  };
  const notificationTitle = (type) => {
    switch (type) {
      case 'mp_order_inbound':
        return 'New Inbound Order Alert';
      default:
        return null;
    }
  };
  return (
    toast((t) => (
      <Container data-testid="toastId" onClick={handleView}>
        <BellImg src={bell} alt="warning" width={120} />
        <TestWrapper>
          <HeaderContainer>
            <Header>
              {notificationTitle(message?.message?.type)}
            </Header>
            <NotificationImg />
          </HeaderContainer>
          <Info>{message?.message?.message?.message}</Info>
        </TestWrapper>
        <Duration>Now</Duration>
      </Container>
    ),
    {
      duration: 10000,
      position: 'top-right',
    }
    )
  );
};

NotificationDialog.propTypes = {
  message: PropTypes.shape(Object),
};

NotificationDialog.defaultProps = {
  message: {}
};

export default NotificationDialog;
