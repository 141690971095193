import React, { useEffect, useState } from 'react';
import {
  Box, Button, DialogContent, Slide
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  DialogContainer,
  DialogContentStyled,
  Title,
  Description,
  ButtonBorder
} from './storesDialog.styles';
import Filter from '../../setup/outlets/outlets/filter';
// import { GET_BUSINESS_AND_SIBLINGS } from '../../../queries/affiliates';
import { CONNECTED_OUTLETS_QUERY } from '../../../queries/businesses';
import ReturnRow from './returnRow';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const StoresDialog = ({
  openDialog, setOpenDialog, storeIds, setStoreIds, toggleDialog, editFunction, toggleCSVDialog, actionType
}) => {
  const [state, setState] = useState({ searchText: '' });
  const [name, setName] = useState('');
  const { searchText } = state;

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setName(searchText);
    } else if (!searchText) {
      setName('');
    }
  }, [searchText]);

  const { loading, data } = useQuery(CONNECTED_OUTLETS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { name }
  });

  const { outlets = [] } = data || {};

  const handleSelectAll = () => {
    const ids = outlets.map(({ id }) => id);
    if (storeIds.length) return setStoreIds([]);
    return setStoreIds(ids);
  };

  const handleSelect = (id) => {
    if (storeIds.includes(id)) {
      const ids = storeIds.filter((val) => val !== id);
      setStoreIds(ids);
    } else setStoreIds([...storeIds, id]);
  };

  const proceedHandler = () => {
    if (!storeIds.length) return toast.error('Select at least one store');
    if (actionType === 'individual_prod') {
      if (editFunction) editFunction.func();
      else toggleDialog();
    } else {
      toggleCSVDialog();
    }
    handleClose();
  };

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <Title>Pharmaceutical Stores</Title>
        <Description>
          Select stores that this product creation should reflect on
        </Description>
        <DialogContent sx={{ marginTop: '-1rem', paddingX: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Filter state={state} handleSearch={handleSearch} />
            <ButtonBorder onClick={handleSelectAll}>Select All</ButtonBorder>
          </Box>
          <Box sx={{ marginTop: '1.5rem' }}>
            {loading
              ? 'Loading ...'
              : outlets.map((outlet) => (
                <ReturnRow
                  key={outlet.id}
                  row={outlet}
                  storeIds={storeIds}
                  handleSelect={handleSelect}
                />
              ))}
          </Box>
          <Box
            sx={{
              marginTop: '1.5rem',
              gap: '10px',
              display: 'flex',
              justifyContent: 'end'
            }}
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={proceedHandler}>Proceed</Button>
          </Box>
        </DialogContent>
      </DialogContentStyled>
    </DialogContainer>
  );
};

StoresDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  storeIds: PropTypes.instanceOf(Array),
  setStoreIds: PropTypes.instanceOf(Array),
  toggleDialog: PropTypes.func,
  toggleCSVDialog: PropTypes.func,
  editFunction: PropTypes.instanceOf(Object),
  actionType: PropTypes.string
};

StoresDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  storeIds: [],
  setStoreIds: [],
  toggleDialog: () => null,
  toggleCSVDialog: () => null,
  editFunction: null,
  actionType: ''
};

export default StoresDialog;
