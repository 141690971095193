import { styled } from '@mui/material/styles';
import {
  Paper,
  Grid,
  TableCell, Typography, IconButton
} from '@mui/material';
import { Receipt, Check, Autorenew } from '@mui/icons-material';
import { ArrowLeftIcon, ArrowRightIcon } from '../../orders/admin/order.styles';
import { OrderButton } from '../../orders/shared/titleGrid.styles';

const sm = '960px';

export const SaleContainerGrid = styled(Grid)`
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
  margin-top: 150px;

  @media(min-width: 992px) {
    margin-bottom: 30px;
    margin-top: 100px;
  }
`;
export const SaleId = styled(Grid)`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 4px;
`;
export const ViewInfo = styled(Grid)`
  font-size: 13px;
`;
export const SaleDetailCard = styled(Grid)`
  box-shadow: 0 0 4px #0004;
  background: #fff;
  border-radius: 5px;
  padding-bottom: 20px;
`;
export const SaleDetailCardInner = styled(Grid)`
  padding: 10px;
  padding-top: 20px;
  padding-left: 40px;
  // margin-bottom: 20px;
`;
export const CardHeader = styled(Grid)`
  font-size: 12px;
  color: #A3A3A3;
`;
export const CardBody = styled(Grid)`
  color: #424242;
  overflow: Hidden;
  text-overflow: ellipsis;
`;
export const HR = styled(Grid)`
  height: 1px;
  background: #CCCCCC80;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  padding-left: 0;
  &:first-child, :last-child {
    width: 2%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 10.33%;
  }
  &:nth-child(4) {
    width: 10.33%;
  }
  &:nth-child(5) {
    width: 13.33%;
  }
  &:nth-child(6) {
    width: 10.33%;
  }
  &:nth-child(7) {
    width: 13.33%;
  }
`;

export const ReturnHeaderCell = styled(TableCell)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  padding-left: 0;
  &:first-child, :last-child {
    width: 2%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 10.33%;
  }
  &:nth-child(4) {
    width: 10.33%;
  }
  &:nth-child(5) {
    width: 13.33%;
  }
  &:nth-child(6) {
    width: 10.33%;
  }
  &:nth-child(7) {
    width: 10.33%;
  }
  &:nth-child(8) {
    width: 10.33%;
  }
  &:nth-child(9) {
    width: 13.33%;
  }
`;
export const SalesItemBodyCell = styled(TableCell)`
  font-size: 2rem;
  &:nth-child(2), :nth-child(3), :nth-last-child(2) {
    padding: 0;
  }

  @media(min-width: 992px) {
    font-size: 1.2rem;
    padding: 16px;
    padding-left: 0;
  }
`;
export const ArrowLeft = styled(ArrowLeftIcon)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const ArrowRight = styled(ArrowRightIcon)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;
export const SwitchBtn = styled(Grid)`
  font-weight: 600;
  margin-top: -6px;
  color: #4D4F5C;
`;
export const SwicthBox = styled(Grid)`
  cursor: pointer;
`;
export const ReceiptIcon = styled(Receipt)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;
export const RecycleIcon = styled(Autorenew)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const ReturnIcon = styled('img')`
    width: 20px;
`;

export const InfoHeaderGrid = styled(Grid)`
  padding: 5px 20px;
  font-weight: 500;
  border-bottom: 1px solid lightgrey;
`;

export const GridWrapper = styled(Grid)`
    min-width: 430px;
`;

export const SaleItemsContainer = styled(Grid)`
    padding: 5px 20px;
    border-bottom: 1px solid lightgrey;
`;
export const ReturnPackSize = styled(Grid)`
    font-size: 10px;
`;
export const CancelReturnText = styled(Grid)`
    cursor: pointer;
`;
export const FooterContainer = styled(Grid)`
    padding: 15px 20px;
`;
export const CheckIconContainer = styled(Grid)`
    cursor: pointer;
    padding: 8px;
    background-color: #FCED50;
    border-radius: 100px;
    font-size: 5px;
`;
export const ReturnCheckIcon = styled(Check)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;
export const ReturnButton = styled(IconButton)`
  background-color: transparent;
  border: 2px solid black;
  border-radius: 20px;
  padding: 0 25px;
  height: 70px;
  &:active, &:hover {
    background-color: #ccc;
  }

  @media(min-width: 992px) {
    border-radius: 10px;
    padding: 0 25px;
    height: 50px;
  }
`;
