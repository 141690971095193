import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import currencyFormatter from '../../shared/currencyFormatter';
import { UPDATE_PRODUCTS_STATUS_MUTATION } from '../../../mutations/products';
import {
  BodyCell, MainTableRow, ColorField, Status, PromoSign
} from './returnRow.styles';
import ActionPopper from './actionPopper';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import { useStateValue } from '../../../providers/stateProvider';
import SuccessDialog from '../../shared/successDialog';
import ConfirmDialog from '../../shared/confirmDialog';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';
import SingleBatchDialog from '../../products/productDetails/singleBatchDialog';
import { toTitleCase } from '../../../utils/toTitleCase';

const ReturnRow = ({
  row, refetch, handleStore, setActionType, productBatchPreference
}) => {
  const [openModal, setOpenModal] = useState(false);
  const isLarge = useMediaQuery('(min-width:992px)');

  const {
    id, brandName, quantityInStock, resolvedPriceInUseValue, unitOfMeasurement,
    orderCost, status, promoStatus
  } = row;

  const navigate = useNavigate();
  const price = currencyFormatter(resolvedPriceInUseValue);
  const cost = currencyFormatter(orderCost);
  const pLoss = (resolvedPriceInUseValue - orderCost) / orderCost;
  const roundedPLoss = `${Math.round((pLoss + Number.EPSILON) * 100) / 100} %`;
  const [updateProductStatus] = useMutation(UPDATE_PRODUCTS_STATUS_MUTATION);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [confirmText, setConfirmText] = useState({
    title: '', desc: '', confirm: ''
  });

  const [statusValues, setStatusValues] = useState({
    type: false,
    productId: ''
  });

  const [{
    user: {
      allowedPermissionsMap: { userPermissions }, automaticSales, zeroSalesFormat
    }
  }, dispatch] = Object.values(useStateValue());

  const editProduct = () => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: row, refetch }
    });
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const getStatus = () => {
    switch (status) {
      case 'ACTIVE': return (
        <ColorField>
          <Status status="active" />
          Enabled
        </ColorField>
      );
      case 'IN_ACTIVE': return (
        <ColorField>
          <Status status="inactive" />
          Disabled
        </ColorField>
      );

      default: return (
        <ColorField>
          <Status status="soldOut" />
          Sold Out
        </ColorField>
      );
    }
  };

  const cells = [price, roundedPLoss];

  const returnCell = (value) => value || '-';

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell>{isLarge ? returnCell(cell) : ''}</BodyCell>
  ));

  if (confirmStatus) {
    setIsLoading(true);
    updateProductStatus({
      variables: {
        productId: statusValues?.productId,
        status: statusValues?.type ? 'IN_ACTIVE' : 'ACTIVE'
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateProductStatus || {};
        setIsLoading(false);
        setConfirmText({
          title: statusValues?.type ? 'Product Disabled' : 'Product Enabled',
          desc: statusValues?.type
            ? 'Hi pharm, product has been successfully disabled'
            : 'Hi pharm, product has been successfully enabled',
          confirm: ''
        });
        setOpenConfirmDialog(false);
        setSuccessOpenDialog(true);
        refetch();
      })
      .catch((err) => {
        toast.error(err?.message);
        setIsLoading(false);
      });
    setConfirmStatus(false);
  }

  const handleAction = (event, type, productRow, setOpen) => {
    if (type === 'view_product') navigate(`/products/${productRow?.id}/details`);
    else if (type === 'add_batch') {
      if (!productBatchPreference('batch')) return;
      setOpenModal(true);
    } else if (type === 'edit_product') {
      if (!productBatchPreference('product')) return;
      setActionType('individual_prod');
      handleStore({ func: editProduct });
    } else {
      setStatusValues({ type, productId: productRow.id });
      setOpenConfirmDialog(true);
      setConfirmText({
        title: type ? 'Disable Product' : 'Enable Product',
        desc: type
          ? 'Hi pharm, are you sure you want to disable this product?'
          : 'Hi pharm, are you sure you want to enable this product?',
        confirm: type ? 'Yes, Disable Product' : 'Yes, Enable Product'
      });
    }
    setOpen(false);
  };

  const addToCart = () => {
    const wrongNaming = toTitleCase(row?.unitOfMeasurement);
    if (wrongNaming === 'Satchet') return toast.error(`${wrongNaming} is not a valid unit of measurement`);
    if (!userPermissions?.includes('sales_make_sale')) return toast.error('Not Authorized');
    if (status === 'IN_ACTIVE') return;
    if (!row?.batches?.length && !zeroSalesFormat) return toast.error('Not Authorized');
    dispatch({
      type: SaleActionTypes.UPDATE_BATCH_TO_CART,
      payload: {
        product: row, automaticSales, zeroSalesFormat
      }
    });
  };

  const renderNamePromo = (name) => (
    <Grid item container alignItems="center">
      {name}
      &nbsp; &nbsp;
      <PromoSign>Promo</PromoSign>
    </Grid>
  );

  return (
    <>
      <MainTableRow item container key={id} className="erp-uat-sales-tr">
        <BodyCell style={{ cursor: 'pointer' }} onClick={addToCart}>
          {promoStatus === 'Promo Set' ? renderNamePromo(brandName) : brandName}
        </BodyCell>
        <BodyCell>{quantityInStock || 0}</BodyCell>
        { userPermissions?.includes('sales_view_cost_price') && <BodyCell>{cost}</BodyCell> }
        {renderBodyCells()}
        <BodyCell>{toTitleCase(unitOfMeasurement)}</BodyCell>
        <BodyCell>{getStatus()}</BodyCell>
        <BodyCell style={{ cursor: 'pointer' }}>
          <ActionPopper handleAction={handleAction} row={row} />
        </BodyCell>
      </MainTableRow>

      <SingleBatchDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        brandName={brandName}
        productId={row?.id}
        refetch={refetch}
      />
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={confirmText?.title}
        desc={confirmText?.desc}
        option="ok"
        refetch={refetch}
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title={confirmText?.title}
        desc={confirmText?.desc}
        options={['Cancel', confirmText?.confirm]}
        setStatus={setConfirmStatus}
        loading={isLoading}
      />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  refetch: PropTypes.func.isRequired,
  handleStore: PropTypes.func,
  setActionType: PropTypes.func,
  productBatchPreference: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  handleStore: () => null,
  // eslint-disable-next-line no-unused-vars
  setActionType: (val) => null
};

export default ReturnRow;
