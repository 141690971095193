import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import {
  SideNavText, SideGrid, SideNavLine, SideNavButton, TransferValueGrid
} from './sideNavItem.styles';

export const SideNavItem = ({
  sideItem, handleSideClick, stockTransfers
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 991px)');

  const location = useLocation();
  const { pathname } = location;

  const setActiveSvg = (route) => (route === pathname?.slice(1) ? '#235A91' : '#F1F8FF');

  const [route, SvgIco, title] = sideItem;

  return (
    <SideGrid item container className={`navigation-item-${title}`}>
      <SideNavLine
        orientation="vertical"
        flexItem
        active={route === pathname?.slice(1)}
      />
      <SideNavButton
        className={`erp-uat-${route}`}
        disableRipple
        onClick={() => handleSideClick(route)}
        active={route === pathname?.slice(1)}
      >
        <SvgIco
          style={{
            fontSize: isSmallScreen ? '4rem' : '1.5rem', color: setActiveSvg(route),
            marginLeft: isSmallScreen && '2rem', fill: 'none'
          }}
        />
        <SideNavText>{title}</SideNavText>
        {stockTransfers && title === 'stock transfer'
          ? <TransferValueGrid>{stockTransfers}</TransferValueGrid>
          : ''}
      </SideNavButton>
    </SideGrid>
  );
};

SideNavItem.propTypes = {
  sideItem: PropTypes.shape(Array),
  handleSideClick: PropTypes.func.isRequired,
  stockTransfers: PropTypes.number
};

SideNavItem.defaultProps = {
  sideItem: [],
  stockTransfers: 0
};

export default SideNavItem;
