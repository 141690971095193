import {
  Grid, Typography, IconButton, TextField, Box
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';

// the width in pixels for sm breakpoint.
const sm = '960px';
export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const Back = styled('div')`
  display: flex;
  gap: 0.1rem;
  align-items: center;
  cursor: pointer;
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const TextWrapper = styled(Grid)`
  margin-left: 1rem;
`;

export const TitleTextGridContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;

  @media (min-width: 992px) {
    font-size: 1.4rem;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  color: #424242;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const HeaderIcon = styled('img')`
  width: 40px;

  @media (min-width: 992px) {
    width: 18px;
  }
`;

export const StatisticsGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 35px;
  transition: 500ms;
  flex-wrap: nowrap;
  width: calc(100vw - 500px);
  align-items: center;
  @media (max-width: ${sm}) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const StatisticsGridContainerWrapper = styled(Grid)`
  overflow: hidden;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
`;

export const OrderSubHeader = styled(Grid)`
  margin-bottom: 2rem;
  justify-content: space-between;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding: 30px 0px;
  align-items: center;

  @media (min-width: 992px) {
    padding: 20px 0px;
  }
`;

export const ProductItemsTotal = styled(Typography)`
  font-size: 30px;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const ProductItemsContainer = styled(Grid)`
  align-items: center;

  @media (max-width: ${sm}) {
  }
`;

export const SearchContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 2rem;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const SearchTextField = styled(TextField)`
  background: #ffffff;
  border-radius: 10px;

  & .MuiOutlinedInput-input {
    padding: 25px;
  }
  & .MuiOutlinedInput-root {
    font-size: 2.2rem;
    border-radius: 10px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
    border-width: 0;
  }

  @media (min-width: 992px) {
    & .MuiOutlinedInput-input {
      padding: 15px 14px;
    }

    & .MuiOutlinedInput-root {
      font-size: 1rem;
      border-radius: 10px;
    }
  }
`;

export const SearchButton = styled(IconButton)`
  padding: 14px 20px;
  background-color: #fced50;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #d2c647;
  }

  &:disabled {
    color: #424242;
    background-color: #fced50;
  }

  @media (min-width: 992px) {
    padding: 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 64px;

  @media (min-width: 992px) {
    width: 30px;
  }
`;

export const ViewCartButton = styled(IconButton)`
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 50px;
  background-color: #fced50;
  color: #424242;
  border-radius: 10px;
  &:hover {
    background-color: #d2c647;
  }

  &.disabled {
    color: #848483;
    background-color: #cccccb;
  }

  @media (min-width: 992px) {
    height: 50px;
  }
`;

export const ViewCartButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
`;

export const BoxStyled = styled(Box)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;
