import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import MainContent from '../customComponents/mainContent';
import { SC_DASHBOARD } from '../../queries/sc-dashboard';
import OrderActivityCards from './components/orderActivityCards';
import ProductActivityCards from './components/productActivityCards';
import { ContainerBody } from './dashboardContainer.styles';
import Header from './components/header';

const ScDashboard = () => {
  const [getDate, setGetDate] = useState({
    from: '',
    to: ''
  });

  const response = useQuery(SC_DASHBOARD, {
    variables: {
      dateFrom: getDate.from,
      dateTo: getDate.to
    },
    fetchPolicy: 'cache-and-network'
  });

  return (
    <MainContent>
      <ContainerBody>
        <Header setGetDate={setGetDate} getDate={getDate} />
        <OrderActivityCards response={response} />
        <ProductActivityCards response={response} getDate={getDate} />
      </ContainerBody>
    </MainContent>
  );
};
export default ScDashboard;
