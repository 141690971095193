import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, CircularProgress } from '@mui/material';
import { MainContainer } from './mainContent.styles';
import { useStateValue } from '../../providers/stateProvider';
import NavigationContainer from '../navigation/navigationContainer';

const useStyles = makeStyles((theme) => ({
  topSpacing: {
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins,
    minHeight: '135px',
    justifyContent: 'flex-end',
    [theme?.breakpoints?.up('md')]: {
      minHeight: '72px',
    },
  },
}));

const MainContent = ({ children }) => {
  const [{ navbar: { openSideDrawer } }] = Object.values(useStateValue());
  const classes = useStyles();

  const navigate = useNavigate();
  const theme = useTheme();

  const authToken = localStorage.getItem('oga_auth_token');
  const restToken = localStorage.getItem('oga_rest_token');

  const isAuthenticated = useMemo(() => {
    let authenticated = true;
    if (
      !authToken || !restToken
    ) {
      authenticated = false;
    }
    return authenticated;
  }, [authToken, restToken]);

  if (!isAuthenticated) {
    navigate('/');
    return (
      <Box width="100%" height="100vh" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress style={{ color: '#245A91' }} size={50} />
      </Box>
    );
  }

  return (
    <MainContainer theme={theme} open={openSideDrawer}>
      <NavigationContainer />
      <div className={classes.topSpacing} />
      {children}
    </MainContainer>
  );
};

MainContent.propTypes = {
  children: PropTypes.instanceOf(Object)
};

MainContent.defaultProps = {
  children: {}
};

export default MainContent;
