import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import currencyFormatter from '../shared/currencyFormatter';
import {
  BodyCell, MainTableRow, CustomCheckbox
} from './returnRow.styles';
import { Product } from '../../providers/reducers/product/classes/Product';

const ReturnRow = ({
  row, rowIndx, selected, handleSelect
}) => {
  const isSelected = () => selected.findIndex((x) => x?.id === row?.id) !== -1;
  const isLarge = useMediaQuery('(min-width:992px)');
  const {
    brandName, quantityInStock, resolvedPriceInUseValue, packSize, orderCost
  } = row;

  const price = `₦ ${currencyFormatter(resolvedPriceInUseValue)}`;
  const cost = `₦ ${currencyFormatter(orderCost)}`;
  const pLoss = (resolvedPriceInUseValue - orderCost) / orderCost;
  const roundedPLoss = `${Math.round((pLoss + Number.EPSILON) * 100) / 100} %`;

  const cells = [brandName, quantityInStock, cost, price, roundedPLoss, packSize];

  const returnCell = (value) => value || '-';

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      disabled={!quantityInStock}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row);
      }}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell>{isLarge ? returnCell(cell) : ''}</BodyCell>
  ));

  return (
    <MainTableRow item container>
      <BodyCell>{renderCheckbox()}</BodyCell>
      <BodyCell>{rowIndx + 1}</BodyCell>
      {renderBodyCells()}
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  rowIndx: PropTypes.number.isRequired,
  selected: PropTypes.shape(Array),
  handleSelect: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  selected: [],
};

export default ReturnRow;
