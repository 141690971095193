import { styled } from '@mui/material/styles';
import {
  Grid, Paper, TableCell, Typography, Box,
  TableContainer, Button
} from '@mui/material';

export const TitleGridWrapper = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const OutletContentWrapper = styled(Grid)`
  justify-content: space-between;
  flex-wrap: no-wrap;
  padding: 0 30px 10px;
`;

export const TableContainerWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 30px;
`;

export const DivFullWidth = styled('div')`
  width: 100%;
`;

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const HeaderGridItem = styled(Grid)``;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const HeaderCell = styled(TableCell)`
  background-color: #F3F9FF;
  border: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 2.5rem;
  padding: 10px;

  @media (min-width: 992px) {
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 10%;
    }
  }

  @media(max-width: 991px) {
    font-size: 1.4rem;
  }
`;

export const TableBox = styled(Box)`
  margin-top: 20px;
`;

export const TableContent = styled(TableContainer)`
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 2rem;
`;

export const BackButton = styled(Button)`
  padding-left: 0;
  font-size: 14px;
  text-transform: lowercase;

  &.MuiButton-root {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:hover{
    background: none;
  }
`;

export const ArrowImg = styled('img')`
  width: 8px;
`;

export const TitleGridContainer = styled(Grid)`
  margin-bottom: 1.5rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const CreateUser = styled(Button)`
  min-width: 13rem;
  height: 45px;
  background: #303030;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  margin-left: 5px;

  &:hover {
    background-color: #303030;
    color: #ffffff;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const TextWrapper = styled(Typography)`
  font-weight: 550;
`;
