import React, { useState } from 'react';
import MainContent from '../../customComponents/mainContent';

import {
  TableColumnRows, TableColumnRowText, TableWrapper,
  TableColumnHeaders, TableColumnHeaderTitle, TableContainer,
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderSubHeader, ProductItemsTotal, ViewCartButton, ViewCartButtonText,
  TextWrapper, HeaderIcon, SearchImage, SearchContainer, SearchTextField,
  SearchButton, ProductItemsContainer, OrderItemsGrid, OrderCountText, TabIcon,
} from './reconcileTable.styles';

const TABLE_HEADERS = [
  'batch_id',
  'product_id',
  'product_name',
  'expected_quantity_left',
  'actual_quantity_left'
];
const COLUMN_WIDTH = `${100 / TABLE_HEADERS.length}%`;

export default function ReconcileTable({ tableData }) {
  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item>
            <TextWrapper>
              <PrimaryTitle variant="h5">Product Reconciliation</PrimaryTitle>
              <SecondaryTitle>
                Flag Products whose quantity do not tally
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
        </TitleGridContainer>
        <OrderSubHeader container item>
          <ProductItemsContainer container item xs={12} md={6}>
            <ProductItemsTotal>{`${tableData.length} Product(s)`}</ProductItemsTotal>
          </ProductItemsContainer>
        </OrderSubHeader>
        <OrderItemsGrid>
          <TableWrapper>
            <TableContainer>

              <TableColumnHeaders>
                {
                  TABLE_HEADERS.map((oneHeader) => (
                    <TableColumnHeaderTitle style={{ width: COLUMN_WIDTH }}>
                      {oneHeader}
                    </TableColumnHeaderTitle>
                  ))
                }
              </TableColumnHeaders>
              {
                  tableData.map((row, index) => {
                    const bgColor = String(row.expected_quantity_left) !== String(row.actual_quantity_left) ? 'yellow' : 'white';
                    return (
                      <TableColumnRows
                        style={{ background: bgColor }}
                      >
                        {
                          TABLE_HEADERS.map((oneHeader) => (
                            <TableColumnRowText style={{ width: COLUMN_WIDTH }}>
                              {row[oneHeader]}
                            </TableColumnRowText>
                          ))
                        }
                      </TableColumnRows>
                    );
                  })
                }
            </TableContainer>
          </TableWrapper>
        </OrderItemsGrid>
      </OrderGridContainer>
    </MainContent>
  );
}
