import {
  TableRow, TableCell, Checkbox,
  InputBase
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  background: ${({ background }) => (background)};
  border-radius: 50%;
  width: 9px;
  height: 9px;
`;

export const CustomInputNumberBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 40%;
  border-radius: 10px;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 16px 12px;
    padding-right: 5px;
    line-height: normal;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;
