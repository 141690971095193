import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import moment from 'moment';
import {
  BodyCell, MainTableRow, ColorField, Status, TextWrapper
} from './returnRow.styles';
import { NewDownloadIcon } from '../../../assets/svgs';

const ReturnRow = ({ row }) => {
  const {
    id,
    status,
    dateCreated,
    downloadType,
    businessUser,
    downloadUrl
  } = row;
  const dategenerated = moment(dateCreated).format('DD MMM YYYY h:mm a');

  const getStatus = () => (
    <ColorField>
      <Status status={status} />
      {status.toLowerCase()}
    </ColorField>
  );

  const { fullName } = businessUser;

  const cells = [id, dategenerated, downloadType, fullName];

  const returnCell = (value) => value || '-';

  const handleDownload = () => {
    window.open(
      `${downloadUrl}`,
      '_blank'
    );
  };

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell key={cell}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow item container key={id}>
      {renderBodyCells()}
      <BodyCell>{getStatus()}</BodyCell>
      <BodyCell>
        <Button
          variant="contained"
          data-testid="create-product"
          disabled={status === 'PENDING' || status === 'FAILED'}
          onClick={handleDownload}
        >
          <NewDownloadIcon style={{ fontSize: '1rem', }} />
          <TextWrapper>
            Download
          </TextWrapper>
        </Button>
      </BodyCell>
      <BodyCell />
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
