import {
  Grid, Typography, IconButton, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const SideGrid = styled(Grid)`
  align-items: center;
  margin-bottom: 1rem;

  &:hover {
    & > hr {
      background-color: #F1F8FF;
    }
  }
  @media(max-width: 991px) {
    margin-bottom: 3rem;
  }
`;

export const SideNavLine = styled(Divider)`
  height: 30px;
  align-self: auto;
  width: 3%;
  border: none;
  background-color: ${({ active }) => (active ? '#F1F8FF' : '#235A91')};

  @media(max-width: 991px) {
    height: 120px;
    width: 17px;
  }
`;

export const SideNavButton = styled(IconButton)`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  margin-left: 4%;
  width: 93%;
  justify-content: flex-start;
  border-radius: 15% / 80%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: ${({ active }) => (active ? '#235A91' : '#F1F8FF')};
  background-color: ${({ active }) => (active ? '#F1F8FF' : '#235A91')};

  &:hover {
    background-color: #F1F8FF;
    color: #235A91;

    & > svg {
      color: #235A91 !important;
    }
  }

  @media(max-width: 991px) {
    width: 85%;
    height: 160px;
    // border-radius: 20px;
    margin: auto auto auto 3rem;
    padding: 2rem 25px;
    // color: ${({ active }) => (active ? '#FAF33E' : '#24292E')};
    // background-color: ${({ active }) => (active ? '#000000;' : '#fff')};
  }
`;

export const SideNavText = styled(Typography)`
  margin-left: 2rem;
  font-size: 35px;
  font-weight: 500;
  text-transform: capitalize;

  @media(min-width: 992px) {
    margin-left: 20px;
    font-size: 14px;
  }
`;

export const TransferValueGrid = styled('div')`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-left: 30px;
  background: #EE4223;
  color: #fff;
  text-align: center;
  font-size: 12px;
`;
