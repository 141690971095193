import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RenderPopper } from '../../utils/utils';
import {
  CList, CListItem, CListItemButton, CListItemText
} from './actionPopper.styles';
import { useStateValue } from '../../providers/stateProvider';

export default function ActionPopper({
  action, row, handleDelete, handleClose
}) {
  const [{ user: { allowedPermissionsMap: { userPermissions } } }] = Object.values(useStateValue());
  const navigate = useNavigate();
  const { id } = row;
  const open = Boolean(action);

  return (
    <RenderPopper
      open={open}
      anchorEl={action}
      popperPlacement="bottom-start"
      onClickAway={handleClose}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      <Grid container item style={{ padding: '0 .5rem' }}>
        <CList>
          <CListItem disableGutters>
            <CListItemButton onClick={() => navigate(`/promotions/details/${id}`)}>
              <CListItemText primary="View Promo Details" />
            </CListItemButton>
          </CListItem>
          <CListItem disableGutters>
            <CListItemButton
              onClick={handleDelete}
              disabled={!userPermissions?.includes('promotions_delete_promotions')}
            >
              <CListItemText primary="Delete Promo" />
            </CListItemButton>
          </CListItem>
        </CList>
      </Grid>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
  handleClose: PropTypes.func.isRequired
};
