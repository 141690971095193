import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Grid from '@mui/material/Grid';
import { RenderPopper } from '../../../utils/utils';
import {
  GridWrapper, InfoText, ItemTypo, ItemTypoBox
} from './salesFilterPopper.styles';

const salesFilterPopper = ({
  filterButton, handleOptionToggle,
  handleTriggerButtonClick, selectedOptionFilter, selectedFilter, filterToggle
}) => {
  const open = Boolean(filterButton);

  const options = ['With returns', 'All'];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <RenderPopper
      open={open}
      anchorEl={filterButton}
      onClickAway={() => handleTriggerButtonClick()}
      popperPlacement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
      style={{ position: 'absolute', top: '0px', right: '0px' }}
    >
      <GridWrapper container>
        <InfoText>Filter sales</InfoText>
        {
            options.map((option) => (
              <ItemTypoBox onClick={() => handleOptionToggle(option)} item container direction="row" alignItems="center" key={option}>
                <Grid item xs={1}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ color: '#FCED50' }}
                    checked={option === 'All' && filterToggle === false ? !selectedOptionFilter.get(option) : !!selectedOptionFilter.get(option)}
                  />
                </Grid>
                <ItemTypo item xs={11}>
                  {option}
                </ItemTypo>
              </ItemTypoBox>
            ))
          }
      </GridWrapper>
    </RenderPopper>
  );
};

salesFilterPopper.propTypes = {
  filterButton: PropTypes.instanceOf(Object),
  selectedFilter: PropTypes.instanceOf(Object),
  handleTriggerButtonClick: PropTypes.func.isRequired,
  handleOptionToggle: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

salesFilterPopper.defaultProps = {
  filterButton: {},
  selectedFilter: []
};

export default salesFilterPopper;
