import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Grid, Button, CircularProgress, useMediaQuery
} from '@mui/material';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import NotificationDialog from '../shared/notificationDialog';
import { FORGOT_PASSWORD } from '../../mutations/auth';
import CustomInputBase from '../customComponents/customInputBase';
import { HaveAccountTypo, FooterGrid } from './passwordReset.styles';
import { validateEmail } from './utils';

const PasswordReset = () => {
  const initialState = {
    email: '',
    validated: false
  };
  const initialErrorState = {
    emailError: false
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [openDialog, setOpenDialog] = useState(false);
  const isSmall = useMediaQuery('(max-width: 991px)');

  const { email, validated } = state;
  const { emailError } = errorState;

  const navigate = useNavigate();

  const validateState = (name, value) => {
    if (name === 'email') {
      return setErrorState({
        ...errorState,
        emailError: validateEmail(value)
      });
    }
    return null;
  };

  useEffect(() => {
    setState({
      ...state, validated: !emailError
    });
  }, [email]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);
  const sendResetPassword = (e) => {
    e.preventDefault();
    forgotPassword({
      variables: { email }
    }).then(() => {
      setState((s) => ({ ...s, email: '' }));
      setOpenDialog(true);
    }).catch((err) => {
      toast.error(err.message);
    });
  };

  return (
    <form onSubmit={sendResetPassword} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInputBase
            label="Email Address"
            name="email"
            value={email}
            type="email"
            error={email ? emailError : false}
            helperText={emailError ? 'Invalid email' : ' '}
            required
            disabled={loading}
            onChange={handleChange}
            cSize="lg"
            data-testid="auth-email"
            className="signin-email"
            id="signin-email"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ margin: '2rem 0 0' }}>
        <Button
          data-testid="auth-button"
          disabled={!validated}
          type="submit"
          variant="contained"
          style={{
            width: '100%', height: isSmall ? '5.7rem' : '', fontSize: isSmall ? '2rem' : '', borderRadius: isSmall ? '1rem' : ''
          }}
          className="signin-button"
          id="signin-button"
        >
          {loading ? <CircularProgress size={18} /> : 'Submit'}
        </Button>
      </Grid>
      <FooterGrid item container xs={12}>
        <HaveAccountTypo variant="subtitle2" display="inline">
          Remember your password?
          &nbsp;
          &nbsp;
          <Link to="/" style={{ color: '#235A91', fontWeight: 800 }}>
            Sign In
          </Link>
        </HaveAccountTypo>
      </FooterGrid>

      <NotificationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        action="Ok"
        actionFunc={() => navigate('/')}
        title="Link sent!"
        desc="Hi User, please check your email for link sent"
      />
    </form>
  );
};

export default PasswordReset;
