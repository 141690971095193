import React from 'react';
import PropTypes from 'prop-types';
import {
  GridWrapper, Image, AffiliateName, LogoWrapper, ImageWrapper, PharmacyName,
} from './initialImage.styles';

const initialImage = ({
  image, name, contactName
}) => (
  <GridWrapper container item>
    <LogoWrapper>
      <ImageWrapper container>
        <Image
          src={image}
          alt="image"
        />
        <AffiliateName>
          {contactName}
        </AffiliateName>
        <PharmacyName>
          {name}
        </PharmacyName>
        <PharmacyName>Ogapharmacy Affiliate</PharmacyName>
      </ImageWrapper>
    </LogoWrapper>
  </GridWrapper>
);
initialImage.propTypes = {
  image: PropTypes.string.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleImageDelete: PropTypes.func.isRequired,
};
export default initialImage;
