import { TableRow, TableCell } from '@mui/material';
import styled, { css } from 'styled-components';

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #f3f9ff;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

const deactivatedStyles = css`
  background-color: #ff0f0f;
`;

const approvedStyles = css`
  background-color: #00b588;
`;

const pendingStyles = css`
  background-color: #f29053;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'De-activated':
      return deactivatedStyles;
    case 'Pending':
      return pendingStyles;
    default:
      return approvedStyles;
  }
};

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  border-radius: 50%;
  width: 9px;
  height: 9px;

  ${getStyles}
`;
