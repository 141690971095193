import {
  Grid, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UploadPic } from '../../../assets/svgs';

export const GridWrapper = styled(Grid)`
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`;

export const UploadPicIcon = styled(UploadPic)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const Image = styled('img')`
  height: 20vh;
  max-height: 100%;
  border-radius: 50%;
`;

export const ChangeImgButton = styled(IconButton)`
  position: absolute;
  bottom: -6px;
  right: -6px;
  background-color: #ECF6FF;
  padding: 15px;

  &:hover {
    background-color: #ECF6FF;
  }
`;
