import React from 'react';
import PropTypes from 'prop-types';
import {
  GridContainer, ItemTypo, ItemWrapper, GridWrapper
} from './affiliateCreatePopper.styles';
import { RenderPopper } from '../../../utils/utils';

const AffiliatesPopper = ({
  affiliateButton,
  handleAffiliateButtonClick,
  csvDialogHandler,
  individualDialogHandler
}) => {
  const open = Boolean(affiliateButton);

  const handleManualSupplierAdd = () => {
    handleAffiliateButtonClick();
    individualDialogHandler();
  };
  const handleCsvUpload = () => {
    handleAffiliateButtonClick();
    csvDialogHandler(true);
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={affiliateButton}
      onClickAway={() => handleAffiliateButtonClick()}
      popperPlacement="bottom-right"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer container item onClick={handleManualSupplierAdd}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Add individually
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>

        <GridContainer container item onClick={handleCsvUpload}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Upload CSV file
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>

      </GridWrapper>
    </RenderPopper>
  );
};

AffiliatesPopper.propTypes = {
  affiliateButton: PropTypes.instanceOf(Object),
  handleAffiliateButtonClick: PropTypes.func.isRequired,
  csvDialogHandler: PropTypes.func.isRequired,
  individualDialogHandler: PropTypes.func.isRequired
};

AffiliatesPopper.defaultProps = {
  affiliateButton: {},
};

export default AffiliatesPopper;
