import React from 'react';
import MapCsvDialog from '../../toBeDeleted/uploadSupplier/mapCsvDialog';

function MapOrderCsvDialog() {
  const fields = [
    ['Product Brand Name', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Generic Name', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Pack Size', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Supplier', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Item Price', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Manufacturer', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Order Qty', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Qty/Ctn', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Market RRP', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Unit Of Measurement', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
  ];
  const experimentalFields = [];

  return (
    <MapCsvDialog
      experimentalFields={experimentalFields}
      fields={fields}
      model="order"
      endpoint="orders"
      backRoute=""
    />
  );
}

MapOrderCsvDialog.propTypes = {
};
export default MapOrderCsvDialog;
