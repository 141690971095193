import {
  TableCell, Grid, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConnectMpStoreIcon
} from '../../../../assets/svgs';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: #606060;
  line-height: 2.8;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 7%;
      padding-left: 20px;
    }
    &:nth-child(2) {
      width: 73%;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }
`;

export const ConnectedMPIcon = styled(ConnectMpStoreIcon)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 2.8rem;
  }
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 30px;
  font-weight: 600;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 24px;
  color: #606060;

  @media(min-width: 992px) {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const TitleTextGridContainer = styled(Grid)`
  flex-direction: column;
`;
