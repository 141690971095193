import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
import { Navigation } from 'swiper';
import {
  useMediaQuery, Hidden, Box, Grid
} from '@mui/material';
import NextButton from '../../../../assets/images/NextButton.png';
import allImg from '../../../../assets/images/marketOrder/all.png';
import consumableImg from '../../../../assets/images/marketOrder/consumables.png';
import counterImg from '../../../../assets/images/marketOrder/counter.png';
import beautyImg from '../../../../assets/images/marketOrder/beauty.png';
import medicalImg from '../../../../assets/images/marketOrder/medical.png';
import prescriptionImg from '../../../../assets/images/marketOrder/prescription.png';
import sexualImg from '../../../../assets/images/marketOrder/sexual.png';
import supplementsImg from '../../../../assets/images/marketOrder/supplements.png';

import {
  CategoryGrid, CardPaper, CategoryImg, CategoryText, CardBox, NextImage, AllCategoriesPaper
} from './categoriesContainer.styles';

export default function CategoriesContainer({
  categories, handleSelect, isSelected
}) {
  const categoryImg = [
    consumableImg, counterImg, beautyImg, medicalImg, prescriptionImg, sexualImg, supplementsImg
  ];
  const swiperRef = useRef();
  const allCategories = { id: 1, therapeuticType: 'All Categories', img: allImg };
  const smallScreen = useMediaQuery('(max-width: 991px)');

  return (
    <Box>
      {!smallScreen ? (
        <CategoryGrid>
          <AllCategoriesPaper active={isSelected === 'All Categories'} onClick={() => handleSelect('All Categories')}>
            <CardBox>
              <CategoryImg src={allCategories.img} alt={allCategories.therapeuticType} />
              <CategoryText>
                {allCategories.therapeuticType}
              </CategoryText>
            </CardBox>
          </AllCategoriesPaper>
          {categories.map((category, index) => (
            <CardPaper
              active={isSelected === category.therapeuticType}
              key={category.id}
              onClick={() => handleSelect(category.id, category.therapeuticType)}
            >
              <CardBox>
                <CategoryImg src={categoryImg[index]} alt={category.therapeuticType} />
                <CategoryText>
                  {category.therapeuticType}
                </CategoryText>
              </CardBox>
            </CardPaper>
          ))}
        </CategoryGrid>
      )
        : (
          <Box>
            <CategoryGrid container>
              <Swiper
                slidesPerView={3}
                spaceBetween={5}
                pagination={{
                  clickable: true,
                }}
                modules={[Navigation]}
                className="mySwiper"
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                <SwiperSlide>
                  <CardPaper active={isSelected === 'All Categories'} onClick={() => handleSelect('All Categories')}>
                    <CardBox>
                      <CategoryImg src={allCategories.img} alt={allCategories.therapeuticType} />
                      <CategoryText>
                        {allCategories.therapeuticType}
                      </CategoryText>
                    </CardBox>
                  </CardPaper>
                </SwiperSlide>
                {categories.map((category, index) => (
                  <SwiperSlide>
                    <CardPaper
                      active={isSelected === category.therapeuticType}
                      key={category.id}
                      onClick={() => handleSelect(category.id, category.therapeuticType)}
                    >
                      <CardBox>
                        <CategoryImg src={categoryImg[index]} alt={category.therapeuticType} />
                        <CategoryText>
                          {category.therapeuticType}
                        </CategoryText>
                      </CardBox>
                    </CardPaper>
                  </SwiperSlide>
                ))}
              </Swiper>
              <NextImage
                src={NextButton}
                onClick={() => swiperRef.current?.slideNext()}
              />
            </CategoryGrid>
          </Box>
        )}
    </Box>
  );
}

CategoriesContainer.propTypes = {
  categories: PropTypes.instanceOf(Array).isRequired,
  handleSelect: PropTypes.func.isRequired,
  // handleCategories: PropTypes.func.isRequired,
  isSelected: PropTypes.isRequired
};
