import {
  MenuItem, Button, Grid, Typography, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 2rem 0.9rem 1rem;
  font-size: 15px;

  &:hover {
    background: #f3f9ff;
  }
`;

export const ButtonDarkStyled = styled(Button)`
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 0.75rem;

  width: ${(props) => (props.addProduct ? '8.25rem' : '14.188rem')};
  height: 48px;

  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);

  &:hover {
    background: #235A91;
    color: #ffffff;
  }
`;

export const GridContainer = styled(Button)`
    padding: 15px 25px;
    width: 20rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 16rem;
      height: 3.063rem;
      margin-bottom: .5rem;
    }
    &:hover {
      background-color: #F3F9FF;
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 2rem;
`;

export const ItemWrapper = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
`;
