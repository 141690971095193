import { styled } from '@mui/material/styles';
import {
  TableCell, Typography, Grid, IconButton, Paper
} from '@mui/material';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem;
`;

export const SearchGrid = styled(Grid)`
  padding: 2rem 1.5rem .5rem;
  align-items: center;
`;

export const ButtonWrapper = styled(Grid)`
  width: 29%;
  margin-left: 1%;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 2px;
`;

export const FilterText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #606060;
  opacity: 0.5;
`;

export const TableGrid = styled(Grid)`
  margin-top:  1.5rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`

`;

export const MainTableHead = styled(Grid)`

`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 4%;
    }
    &:nth-child(2) {
      width: 16%;
    }
    &:nth-child(3) {
      width: 17%;
    }
    &:nth-child(4) {
      width: 12%;
    }
    &:nth-child(5) {
      width: 10%;
    }
    &:nth-child(6) {
      width: 14%;
    }
    &:nth-child(7) {
      width: 11%;
    }
    &:nth-child(8) {
      width: 9%;
    }
    &:nth-child(9) {
      width: 7%;
    }
  }
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const FooterWrapper = styled(Grid)`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
`;
