import { useEffect, useState } from 'react';
import { JSONParse } from './json';

export function generateRandomNumber(n) {
  if (n <= 0) {
    return 0;
  }
  const min = 10 ** (n - 1); // Minimum number with n digits
  const max = 10 ** n - 1; // Maximum number with n digits

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function removeLeadingZeros(number) {
  let numString = number.toString();
  numString = numString.replace(/^0+/, '');
  return parseInt(numString, 10);
}

export function preferenceJsonFormat(jsonString) {
  if (!jsonString) {
    return {};
  }

  const correctedJsonString = jsonString.replace(/'([^']+)':/g, '"$1":')
    .replace(/True/g, 'true')
    .replace(/False/g, 'false');

  return correctedJsonString;
}

export function usePreferencePermissionProductBatch(jsonString) {
  const localBusinessId = localStorage.getItem('ogarx_business_id');

  const [allowed, setAllowed] = useState(false);

  const preference = preferenceJsonFormat(jsonString?.business?.settings);
  useEffect(() => {
    if (Object.keys(preference).length === 0) return;
    const res = JSONParse(preference);
    const check = res?.restrict_product_and_batch_cu_on_child_stores === 1
    && res?.business_excluded_from_restriction === Number(localBusinessId);
    setAllowed(check);
  }, [preference, localBusinessId]);

  return allowed;
}

export function containsLowerCase(str) {
  return /[a-z]/.test(str);
}

export function containsUpperCase(str) {
  return /[A-Z]/.test(str);
}

export function containsNumber(str) {
  return /[0-9]/.test(str);
}

export function minStrLength(str, n) {
  if (str.length < n) return false;
  return true;
}

export function containsSpecialCharacter(str) {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(str);
}

export function maskEmail(email) {
  const atIndex = email?.indexOf('@');

  if (atIndex === -1 || atIndex <= 3) {
    return email;
  }

  const localPart = email?.slice(0, atIndex);
  const domainPart = email?.slice(atIndex);

  const maskedLocalPart = localPart?.length > 3
    ? `${localPart.slice(0, -3)}***`
    : localPart;

  return `${maskedLocalPart}${domainPart}`;
}

export function marketplaceEnvRoute() {
  const location = window.location.href;

  if (location.includes('localhost')) {
    return 'https://marketplace.uat.ogapharmacy.com/';
  } if (location.includes('uat')) {
    return 'https://marketplace.uat.ogapharmacy.com/';
  } if (location.includes('staging')) {
    return 'https://marketplace.staging.ogapharmacy.com/';
  } if (location.includes('canary')) {
    return 'https://marketplace.canary.ogapharmacy.com/';
  }
  return 'https://marketplace.ogapharmacy.com/';
}

export function marketplaceApiEnvRoute() {
  const location = window.location.href;
  if (location.includes('localhost')) {
    return 'https://mp-api.uat.ogapharmacy.com/api/';
  } if (location.includes('uat')) {
    return 'https://mp-api.uat.ogapharmacy.com/api/';
  } if (location.includes('staging')) {
    return 'https://mp-api.staging.ogapharmacy.com/api/';
  } if (location.includes('canary')) {
    return 'https://mp-api.canary.ogapharmacy.com/api/';
  }
  return 'https://mp-api.ogapharmacy.com/api/';
}
