import React, { useEffect, useState } from 'react';
import { TableBody, TableContainer, Table } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import {
  TableHeader,
  GridContainer,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  SubTitle,
  ButtonOutlined,
} from './business.styles';
import CustomSearchField from '../shared/CustomSearchField';
import TablePagination from '../shared/tablePagination';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import FilterButtonsPopper from './filterPopper';
import { ADMIN_BUSINESS } from '../../queries/businesses';
import { UPDATE_BUSINESS_STATUS } from '../../mutations/business';
import ConfirmDialog from '../shared/confirmDialog';
import { OgaToken } from '../../graphql/token';

const headers = [
  'S/N',
  'Date Created',
  'Business Name',
  'Business Owner\'s Name',
  'Business Address',
  'Email Address',
  'Billing Plan',
  'Billing Amount',
  'Phone Number',
  'Parent Business',
  'Status',
  'Action'
];

const initialState = {
  searchText: '',
  search: '',
  status: ''
};

const initialConfirmDialog = {
  title: '',
  description: '',
  option: '',
  type: '',
  id: null,
};

const AdminBusiness = () => {
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(initialConfirmDialog);

  const { searchText, search, status } = state;

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, search: searchText });
    } else if (!searchText) {
      setState({ ...state, search: '' });
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  const actionHandler = (action) => {
    setState((s) => ({ ...s, status: action }));
  };

  // Export as CSV
  const handleExport = () => {
    window.open(`${OgaToken.SERVER_LINK}export-businesses/?status=${status}&name=${search}`, '_blank');
  };

  // Load preloaded products
  const {
    loading, error, data, refetch
  } = useQuery(ADMIN_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      name: search,
      status,
      pageCount,
      pageNumber
    }
  });

  const [updateBusinessStatus] = useMutation(UPDATE_BUSINESS_STATUS);
  const updateBusinessStatusHandler = () => {
    updateBusinessStatus({
      variables: { businessId: confirmMessage.id, status: confirmMessage.type }
    }).then(({ data: { updateBusinessStatus: { message } } }) => {
      toast.success(message);
      setConfirmMessage(initialConfirmDialog);
      setConfirmDialog(false);
      refetch();
    }).catch((err) => toast.error(err.message));
  };

  useEffect(() => {
    if (!confirmStatus) return;
    setConfirmStatus(false);
    updateBusinessStatusHandler();
  }, [confirmStatus]);

  const handleAction = (event, type, row) => {
    const { id } = row;
    setConfirmDialog(true);
    if (type === 'ACTIVE') {
      setConfirmMessage({
        title: 'Activate Account',
        description: 'By clicking yes, this business account will be activated.',
        option: 'Yes, Activate',
        type: 'ACTIVE',
        id: Number(id)
      });
    }
    if (type === 'IN_ACTIVE') {
      setConfirmMessage({
        title: 'De-activate Account?',
        description: 'By clicking yes, this business account will be de-activated',
        option: 'Yes, De-activate',
        type: 'IN_ACTIVE',
        id: Number(id)
      });
    }
  };

  if (error) return <div>{error.message}</div>;
  const {
    allBusinesses = [], allBusinessesTotalNumber = 0
  } = data || {};

  const rows = allBusinesses || [];

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <TitleTextGridContainer>
            <div>
              <PrimaryTitle variant="h5">Businesses</PrimaryTitle>
              <SubTitle>View and manage all Businesses</SubTitle>
            </div>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchFlex>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search for Business"
              handleChange={(e) => handleSearch(e.target.value)}
              style={{ width: '100%' }}
              data-testid="searchInput"
            />

            <FilterButtonsPopper actionHandler={actionHandler} />

            <ButtonOutlined onClick={handleExport}>
              Export as CSV
            </ButtonOutlined>
          </SearchFlex>
          <TableGrid item container>
            {loading ? (
              <ProductsTableLoader />
            ) : (
              <TableContainer>
                <Table>
                  <MainTableHead>
                    {returnHeaders()}
                    <TableHeader />
                  </MainTableHead>
                  <TableBody>
                    {
                    rows?.length
                      ? rows?.map((item, index) => {
                        const idx = item?.id;
                        return <ReturnRow key={idx} row={item} sn={index + 1} handleAction={handleAction} />;
                      })
                      : (
                        <MainTableRow>
                          <BodyCell colspan={12} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                        </MainTableRow>
                      )
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TableGrid>
          <FooterWrapper item container>
            {allBusinessesTotalNumber > 0 && (
              <TablePagination
                total={allBusinessesTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </GridContainer>

      <ConfirmDialog
        openDialog={confirmDialog}
        setOpenDialog={setConfirmDialog}
        title={confirmMessage.title}
        desc={confirmMessage.description}
        setStatus={setConfirmStatus}
        options={['Cancel', confirmMessage.option]}
      />

    </MainContent>
  );
};

export default AdminBusiness;
