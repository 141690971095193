import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';

export const HaveAccountTypo = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
`;

export const FooterGrid = styled(Grid)`
  margin: 3vw 0;
  justify-content: center;

  @media (min-width: 992px) {
    margin: 2vw 0;
  }
`;

export const BillingContainer = styled(Grid)`
  color: #344054;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
`;

export const BillingBody = styled(Box)`
  padding: 1rem;

  @media (max-width: 991px) {
    padding: 1.5rem;
  }
`;

export const BillingContainerHeader = styled(Box)`
  border-bottom: 1px solid #eaecf0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    padding: 1.5rem;
  }
`;

export const BillingTitle = styled(Typography)`
  color: #344054;
  font-weight: 700;
  font-size: 16px;

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const BillingPlan = styled(Typography)`
  color: #344054;
  font-weight: 700;
  font-size: 18px;

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const BillingDescription = styled(Typography)`
  color: #344054;
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;

  @media (max-width: 991px) {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
`;

export const AdornmentStyled = styled('div')`
  font-size: 14px;
  padding-left: 1rem;
  margin-right: -1rem;
  font-weight: 500;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const RadioButton = styled('input')`
  @media (max-width: 991px) {
    width: 2rem;
    height: 2rem;

    appearance: none;
    position: relative;
    border-radius: 50%;
    border: 2px solid #000;
    background: #fff;
    cursor: pointer;
    outline: none;

    &:checked {
      background: #007bff;
      border-color: #007bff;
    }
    &:checked::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: #fff;
      transform: translate(-50%, -50%);
    }
  }
`;
