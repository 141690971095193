import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';

export const MenuItemStyled = styled(MenuItem)`
  width: 100%;
  padding: 0.7rem 1rem;
  font-size: 15px;
  color: #606060;

  &:hover {
    background: #f3f9ff;
  }
`;

export const MeasurementContainer = styled(MenuItem)`
  font-size: 15px;
  color: #606060;
  background: transparent;
`;
