import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import moment from 'moment';
import BatchPopper from '../batchPopper';
import {
  BodyCell, MainTableRow, ColorGrid, DownIcon
} from './returnRowLogs.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';

const ReturnRow = ({ row, rowIndx }) => {
  const [buttonEl, setButtonEl] = useState(null);
  const isLarge = useMediaQuery('(min-width:992px)');
  const {
    date, productName, qtyTransferred, batchNos = [], totalProductValue,
    transferLocation, handler, status
  } = row;
  const dateCreated = moment(date).format('DD MMM YYYY h:mm a');

  const handleClick = (event) => {
    if (event?.currentTarget === buttonEl) return;
    setButtonEl(buttonEl ? null : event.currentTarget);
  };

  const getStatus = () => {
    switch (status) {
      case 'CONFIRMED':
        return <ColorGrid item container status="sent">sent</ColorGrid>;
      case 'REJECTED':
        return <ColorGrid item container status="rejected">rejected</ColorGrid>;
      default:
        return <ColorGrid item container status="pending">pending</ColorGrid>;
    }
  };

  const cellsTop = [dateCreated, productName, qtyTransferred];
  const cellsBottom = [totalProductValue, transferLocation, handler, getStatus()];

  const returnCell = (value) => value || '-';

  const renderBodyCells = (cells) => cells.map((cell) => (
    <BodyCell>{isLarge ? returnCell(cell) : ''}</BodyCell>
  ));

  return (
    <>
      <MainTableRow item container>
        <BodyCell>{rowIndx + 1}</BodyCell>
        {renderBodyCells(cellsTop)}
        <BodyCell>
          <Grid item container>
            {batchNos[0]}
            {batchNos.length > 1
              ? <DownIcon onClick={handleClick} />
              : ''}
          </Grid>
        </BodyCell>
        {renderBodyCells(cellsBottom)}
      </MainTableRow>

      <BatchPopper
        buttonEl={buttonEl}
        handleClick={handleClick}
        batchNos={batchNos}
      />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  rowIndx: PropTypes.number.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
