import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  TableContainerWrapper, TableComponent, TableHeader, PrimaryTitle,
  MainTableHead, TableGrid, MainTableBody
} from './outlets.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import Filter from './filter';
import { useStateValue } from '../../../../providers/stateProvider';

const headers = [
  'S/N', 'Outlet Name', 'Email Address', 'Location', 'Actions'
];

const Outlets = ({
  state, rows, handleSearch, loading, refetch
}) => {
  const [open, setOpen] = useState(false);
  const [createUserDialog, setCreateUserDialog] = useState(false);
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());
  const navigate = useNavigate();

  const handleUserDialog = () => {
    setCreateUserDialog(!createUserDialog);
  };

  const toggleDialog = () => {
    setOpen(!open);
  };

  const handleViewUsers = () => navigate('/business-users');
  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  return (
    <Grid item container justifyContent="flex-end" xs={9}>
      <TableContainerWrapper elevation={0}>
        <PrimaryTitle>
          All Outlets
        </PrimaryTitle>
        <Filter
          state={state}
          handleSearch={handleSearch}
        />
        <TableGrid item container>
          {loading
            ? <ProductsTableLoader />
            : (
              <TableComponent item container>
                <MainTableHead item container>
                  {returnHeaders()}
                </MainTableHead>
                <MainTableBody item container>
                  {rows.map((_row, indx) => (
                    <ReturnRow
                      indx={indx}
                      key={_row.id}
                      row={_row}
                      refetch={refetch}
                    />
                  ))}
                </MainTableBody>
              </TableComponent>
            )}
        </TableGrid>
      </TableContainerWrapper>
    </Grid>
  );
};

Outlets.propTypes = {
  state: PropTypes.shape(Object),
  rows: PropTypes.shape(Array),
  loading: PropTypes.bool,
  handleSearch: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

Outlets.defaultProps = {
  state: {},
  rows: [],
  loading: false,
};

export default Outlets;
