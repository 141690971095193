import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { ALL_BUSINESS_ROLES_QUERY } from '../../../../queries/businesses';
import MainContent from '../../../customComponents/mainContent';
import TitleGrid from './titleGrid';
import SideNav from '../../shared/sideNav/sideNav';
import Users from './users';
import { TitleGridWrapper, OutletContentWrapper } from './usersContainer.styles';

const UsersContainer = () => {
  const { state } = useLocation();
  const [pageCount, setPageCount] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const variables = {
    pageCount,
    pageNumber,
    search: ''
  };

  const {
    data, refetch
  } = useQuery(ALL_BUSINESS_ROLES_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  const {
    allSystemRoles = [],
  } = data || {};

  return (
    <MainContent>
      <TitleGridWrapper>
        <TitleGrid />
      </TitleGridWrapper>
      <OutletContentWrapper container spacing={3}>
        <SideNav />
        <Users
          userRoles={allSystemRoles}
          routeState={state}
        />
      </OutletContentWrapper>
    </MainContent>
  );
};

export default UsersContainer;
