import {
  Grid, Checkbox, TableContainer, Table, TableHead, TableCell
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const DivFullWidth = styled('div')`
  margin-top: 1rem;
  width: 100%
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const ErpHeader = styled(TableCell)`
  text-transform: uppercase;
  padding: 10px;
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;
