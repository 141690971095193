import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableContainer, Button
} from '@mui/material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import {
  TableHeader,
  GridContainer,
  PaperWrapper,
  TableGrid,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MainTableHead,
  ButtonContainer,
  GoBack
} from './purchaseOrder.styles';
import ReturnRow from './returnRow';
import { BodyCell } from './returnRow.styles';
import { SEARCH_PO_QUERY } from '../../../queries/products';
import SearchPopper from './searchPopper';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { PLACE_ORDER_PRODUCTS_MUTATION } from '../../../mutations/cart';
import { GET_MY_BUSINESS } from '../../../queries/affiliates';
import { ORDER_QUERY } from '../../../queries/cart';
import { generateRandomNumber } from '../../../utils/funcs';
import { CustomCheckbox } from '../mangePurchaseOrder/returnRow.styles';
import { CircularProgressLoader } from '../../customComponents/customInputBase.styles';
import { useStateValue } from '../../../providers/stateProvider';

const poHeaders = [
  'S/N',
  'SKU',
  'Product Name',
  'Supplier',
  'Pack Size',
  'Qty',
  'PO Unit Cost',
  'Price',
  'Action'
];

function PurchaseOrderContainer() {
  let bizId = 0;
  const location = useLocation();
  const autoOrder = location?.state?.autoOrder;
  const existingProducts = location?.state?.autoProducts;
  const returnHeaders = () => poHeaders.map((header) => <TableHeader key={header}>{header}</TableHeader>);
  const navigate = useNavigate();
  const localBusinessId = localStorage.getItem('ogarx_business_id');
  const [{ user: { businessId } }] = Object.values(useStateValue());

  const adminOrderingForBusiness = localStorage.getItem('oga_ordering_for_affiliate');
  if (adminOrderingForBusiness) bizId = JSON.parse(adminOrderingForBusiness).id;
  else bizId = businessId;
  const variables = {
    bizId, isManualOrder: false
  };

  const { data: orderData, loading: orderLoading } = useQuery(ORDER_QUERY, {
    variables,
    fetchPolicy: 'no-cache'
  });

  const { data: businessData } = useQuery(GET_MY_BUSINESS, {
    variables: { id: localBusinessId }
  });

  const deliveryLocationId = businessData?.business?.deliveryLocations[0]?.id || 0;

  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [pId, setPId] = useState('');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [savedEl, setSavedEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState(null);
  const [currentRowSearch, setCurrentRowSearch] = useState(0);
  const [reload, setReload] = useState(false);
  const reloadHandler = () => setReload(!reload);

  const [getSearchedProducts, { data: poData, loading }] = useLazyQuery(
    SEARCH_PO_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        search: '',
        productName: search,
        productId: pId,
        status: 'enabled'
      }
    }
  );

  const createData = (product) => {
    const prod = new Product(product, 'erp');
    return {
      productId: prod?.id,
      sku: prod?.sku,
      brandName: prod?.brandName,
      quantityInStock: prod?.quantityInStock,
      supplier: prod?.supplier,
      packSize: prod?.packSize,
      itemPrice: prod?.orderCost,
    };
  };

  const handlePopperClose = () => {
    setSearchedProducts([]);
    setSearchFieldEl(null);
  };

  useEffect(() => {
    if (poData && poData.erpProducts) {
      const sResults = poData.erpProducts.map((product) => createData(product));
      setSearchedProducts(sResults);
      setSearchFieldEl(savedEl);
    }
  }, [poData]);

  const retrieveProducts = (orderSet) => {
    let autoProducts = [];
    orderSet?.forEach((result) => {
      autoProducts.push(...result.orderproductSet);
    });
    autoProducts = autoProducts.map((prod) => {
      const {
        quantity,
        supplierOrder: { supplier: { name: supplierName } },
        product: { sku, id: productId, erpVersion: { orderCost, brandName, packSize } }
      } = prod;
      const next = generateRandomNumber(10);
      const prodData = {
        sn: next,
        productId,
        sku,
        productName: brandName,
        supplier: supplierName,
        quantity,
        packSize,
        newUnitCost: orderCost,
        price: orderCost * quantity
      };
      return prodData;
    });
    setProducts(autoProducts);
  };

  useEffect(() => {
    if (autoOrder && orderData && orderData.order) {
      const { order: { supplierorderSet } } = orderData;
      retrieveProducts(supplierorderSet);
    }
  }, [autoOrder, orderData]);

  const retrieveAutoProducts = (autoProd) => {
    const updateAuto = autoProd.map((prod) => {
      const {
        quantity,
        supplierOrder: { supplier: { name: supplierName } },
        product: { sku, id: productId, erpVersion: { orderCost, brandName, packSize } }
      } = prod;
      const next = generateRandomNumber(10);
      const prodData = {
        sn: next,
        productId,
        sku,
        productName: brandName,
        supplier: supplierName,
        quantity,
        packSize,
        newUnitCost: orderCost,
        price: orderCost * quantity
      };
      return prodData;
    });
    setProducts(updateAuto);
  };

  const handleSearch = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set search field
    setPId('');
    setSearch(value);
    // Update the product name for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productName = value;
    setProducts([...products]);

    if (value && value.length > 2) {
      getSearchedProducts();
      setSavedEl(event.target);
    } else {
      handlePopperClose();
    }
  };

  const handleSearchByPId = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set product id search field
    setSearch('');
    setPId(value);
    // Update the product id for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.sku = value;
    setProducts([...products]);

    if (value) {
      getSearchedProducts();
      setSavedEl(event.target);
    } else {
      handlePopperClose();
    }
  };

  const addMoreHandler = () => {
    const next = generateRandomNumber(10);
    const newData = {
      sn: next,
      productId: '',
      sku: '',
      productName: '',
      supplier: '',
      packSize: '',
      quantity: 0,
      newUnitCost: '',
      price: 0
    };
    setProducts([...products, newData]);
  };

  const removeRowHandler = (sn) => {
    const newProducts = products.filter((p) => p.sn !== sn);
    setProducts(newProducts);
  };

  const handleProductSelection = (row) => {
    const {
      sku, productId, brandName, supplier, itemPrice, packSize
    } = row;

    const newProduct = products.find(
      ({ productName, productId: pid }) => productName === brandName && pid !== ''
    );
    if (newProduct) return toast.error('Product already exists');
    const newData = products.find(({ sn }) => sn === currentRowSearch);
    newData.productId = productId;
    newData.sku = sku;
    newData.productName = brandName;
    newData.supplier = supplier.name;
    newData.packSize = packSize;
    newData.quantity = 1;
    newData.newUnitCost = itemPrice;
    newData.price = itemPrice;
    setProducts([...products]);
    handlePopperClose();
  };

  useEffect(() => {
    setProducts([...products]);
  }, [reload]);

  const counterHandler = (type, row, val) => {
    const { sn, quantity, newUnitCost } = row;
    switch (type) {
      case 'increment': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) + 1;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;

        break;
      }
      case 'decrement': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) - 1;
        if (newQuantity < 0) return;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;

        break;
      }
      case 'change': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        if (val <= 0) return;
        newData.quantity = Number(val);
        newData.price = val * newUnitCost;
        break;
      }
      default:
        break;
    }
    reloadHandler();
  };

  const updateCostPriceHandler = (val, row) => {
    const { sn, quantity } = row;
    const newData = products.find(({ sn: sid }) => sn === sid);
    newData.newUnitCost = val;
    newData.price = quantity * val;

    setProducts([...products]);
    reloadHandler();
  };

  const [placePoOrder, { loading: poloading }] = useMutation(PLACE_ORDER_PRODUCTS_MUTATION);

  // ================= Save Draft Handler ===================
  const saveDraftHandler = async () => {
    const filterItems = products.filter((prod) => prod.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((prod) => {
      const { productId, quantity, newUnitCost } = prod;
      return {
        productId,
        quantity,
        newUnitCost
      };
    });

    if (!cart.length) return toast.error('Purchase order is empty');

    const request = {
      cart,
      isManualOrder: false,
      deliveryLocationId: Number(deliveryLocationId),
      creditScoreBusiness: localBusinessId,
      isADraft: true,
      extraActions: [],
      extraProductsMeta: [],
      affectedSuppliers: []
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        toast.success('Purchase order successfully saved as draft');
        navigate('/manage-purchase-order');
      })
      .catch(() => {});
  };

  // ================= Save PO Handler ===================
  const createPOHandler = () => {
    // Remove row that does not have product id
    const filterItems = products.filter((prod) => prod.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((prod) => {
      const { productId, quantity, newUnitCost } = prod;
      return {
        productId: Number(productId),
        quantity,
        newUnitCost
      };
    });

    if (cart.length < 1) return toast.error('Purchase order is empty');

    const request = {
      cart,
      isManualOrder: false,
      deliveryLocationId: Number(deliveryLocationId),
      creditScoreBusiness: localBusinessId,
      isADraft: false,
      extraActions: [],
      extraProductsMeta: [],
      affectedSuppliers: []
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        toast.success('Purchase order successfully create');
        navigate('/manage-purchase-order');
      })
      .catch(() => {});
  };

  const deleteHandler = () => {
    if (selected.length < 1) return toast.error('Kindly select an item to delete');
    const result = products.filter((obj) => !selected.includes(obj.sn));
    setProducts(result);
    setSelected([]);
  };

  const handleSelect = (_, sn) => {
    if (_.target.checked) {
      const list = selected.filter((item) => item !== sn);
      setSelected([...list, sn]);
    } else {
      const list = selected.filter((item) => item !== sn);
      setSelected(list);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = products.map((product) => product.sn);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === products.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  let rowCounter = 0;
  useEffect(() => {
    if (existingProducts && existingProducts.length > 0) retrieveAutoProducts(existingProducts);
  }, [existingProducts]);

  return (
    <MainContent>
      <GridContainer container>
        <GoBack onClick={() => navigate('/inventory/orders')}>
          <KeyboardArrowLeft style={{ fontSize: '1.8rem', cursor: 'pointer' }} />
          back
        </GoBack>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5">Create Purchase Order</PrimaryTitle>
            <ButtonContainer>
              <Button
                variant="outlined"
                disabled={poloading}
                style={{ width: '11rem', height: '3rem', border: '2px solid' }}
                onClick={deleteHandler}
              >
                Delete Item(
                {selected.length}
                )
              </Button>
              <Button
                variant="outlined"
                disabled={poloading}
                style={{ width: '8rem', height: '3rem', border: '2px solid' }}
                onClick={saveDraftHandler}
              >
                Save Draft
              </Button>
              <Button
                onClick={createPOHandler}
                variant="contained"
                disabled={poloading}
                style={{ width: '8rem', height: '3rem' }}
              >
                Create PO
              </Button>
            </ButtonContainer>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <TableGrid item container>
            <TableContainer>
              <Table>
                <MainTableHead>
                  <TableHeader>
                    {renderCheckbox()}
                  </TableHeader>
                  {returnHeaders()}
                  <TableHeader />
                </MainTableHead>
                <TableBody>
                  {
                  (autoOrder && orderLoading) ? (
                    <BodyCell colSpan={9} style={{ textAlign: 'center' }}>
                      <CircularProgressLoader
                        disableShrink
                        size={40}
                        thickness={5}
                      />
                    </BodyCell>
                  )
                    : !products?.length ? (
                      <BodyCell colSpan={9} style={{ textAlign: 'center' }}>
                        Purchase order is empty
                      </BodyCell>
                    ) : (
                      products?.map((item) => {
                        const { sn } = item;
                        rowCounter += 1;
                        return (
                          <ReturnRow
                            key={sn}
                            row={item}
                            index={rowCounter}
                            loading={loading}
                            counterHandler={counterHandler}
                            handleSearch={handleSearch}
                            removeRowHandler={removeRowHandler}
                            handleSelect={handleSelect}
                            updateCostPriceHandler={updateCostPriceHandler}
                            handleSearchByPId={handleSearchByPId}
                            poLoading={poloading}
                            currentRowSearch={currentRowSearch}
                            selected={selected}
                          />
                        );
                      })
                    )
}
                </TableBody>
              </Table>
            </TableContainer>
          </TableGrid>
          <Button
            variant="outlined"
            onClick={addMoreHandler}
            style={{
              width: '11rem',
              height: '3rem',
              border: '2px solid',
              marginTop: '1rem'
            }}
          >
            Add New Product
          </Button>
        </PaperWrapper>
      </GridContainer>
      <SearchPopper
        searchFieldEl={searchFieldEl}
        handleClose={handlePopperClose}
        searchedProducts={searchedProducts}
        handleProductSelection={handleProductSelection}
      />
    </MainContent>
  );
}

export default PurchaseOrderContainer;
