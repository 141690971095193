import { Grid, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogHeaderContainer = styled(Grid)`
  padding:20px 30px;
  background: #FFF;
`;

export const DialogSearchContainer = styled(Grid)`
  display: flex;
  padding: 2rem 3rem 1rem;
  background: #F0F0F0;
  position: relative;

  @media(min-width: 992px) {
    padding: 2rem 10rem 1rem;
  }
`;

export const RelativeAffiliatesContainer = styled(Grid)`
  width: 100%;
  position: relative;
`;

export const AffiliatesContainer = styled(Grid)`
  background: #F0F0F0;
  border: 3px solid #fff;
  max-height: 15rem;
  width: 100%;
  z-index: 999;
  overflow-y: scroll;
`;

export const AffiliatesCardContainer = styled(Grid)`
display: flex;
`;

export const ModalAffiliateCard = styled(Grid)`
padding:5px;
background: grey;
`;
export const Loading = styled(Grid)`
display: flex;
`;
export const CenterTextGrid = styled(Grid)`
text-align: center;
`;
export const DialogFooterContainer = styled(Grid)`
  padding: 20px;
  background: #fff;
`;
export const DialogDoneButton = styled(IconButton)`
  height: 50px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 5px;
  margin-right: 25px;
  background-color: #FCED50!important;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }

  @media(min-width: 992px) {
    height: 40px;
  }
`;
export const DialogCancelButton = styled(DialogDoneButton)`
  background-color: #fff!important;
  border: 1px solid black;
  &:hover {
    background-color: #fff;
  }
`;

export const DialogActionButtonText = styled('p')`
  font-size: 25px;
  margin: 0;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: 30px;
  color: #4D4F5C;
  font-weight: 600;
  margin-bottom: 6px;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const SubHeaderText = styled(Typography)`
  font-size: 20px;
  color: #4D4F5C;

  @media(min-width: 992px) {
    font-size: 15px;
  }
`;
