import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { RenderPopper } from '../../../utils/utils';
import {
  ProductItem,
  SearchPaper,
  ProdName,
  NotfoundContainer, NotfoundTitle, NotfoundDescription, DFlex,
  Preloaded,
  UserAdded
} from './searchPopper.styles';
import { SearchIcon } from '../../../assets/svgs';

const SearchPopper = ({
  searchFieldEl,
  handleClose,
  searchedProducts,
  handleProductSelection,
  toggleIndividualDialog
}) => {
  const open = Boolean(searchFieldEl);

  const preloadedStatus = (status) => (
    <DFlex>
      {status ? <Preloaded /> : <UserAdded />}
      {status ? 'Preloaded' : 'User-Added'}
    </DFlex>
  );

  return (
    <RenderPopper
      open={open}
      anchorEl={searchFieldEl}
      onClickAway={handleClose}
      popperPlacement="bottom-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '-3vw, 1vh'
        }
      }}
    >
      <SearchPaper elevation={0}>
        {
        searchedProducts?.length
          ? searchedProducts?.map((prod) => (
            <ProductItem
              key={prod}
              container
              onClick={() => handleProductSelection(prod)}
            >
              <Grid item md={8} xs={4} style={{ width: '40rem', display: 'flex', justifyContent: 'space-between' }}>
                <ProdName>
                  {prod.brandName}
                </ProdName>
                <ProdName>
                  {preloadedStatus(prod.isPreloaded)}
                </ProdName>
              </Grid>
            </ProductItem>
          )) : (
            <NotfoundContainer>
              <SearchIcon style={{ fontSize: '5rem' }} />
              <NotfoundTitle>Product Not Found!</NotfoundTitle>
              <NotfoundDescription>Proceed to create new product by clicking the add product button below</NotfoundDescription>
              <Button
                onClick={toggleIndividualDialog}
                variant="contained"
                style={{ width: '7rem', height: '2.6rem', fontSize: '11px' }}
              >
                Add Product
              </Button>
            </NotfoundContainer>
          )
}
      </SearchPaper>
    </RenderPopper>
  );
};

SearchPopper.propTypes = {
  searchFieldEl: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  searchedProducts: PropTypes.instanceOf(Array).isRequired,
  handleProductSelection: PropTypes.func.isRequired,
  toggleIndividualDialog: PropTypes.func.isRequired
};

export default SearchPopper;
