import {
  Grid, Typography, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PrimaryTitle = styled(Typography)`
  font-size: 50px;
  font-weight: 600;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 27px;
  color: #606060;

  @media(min-width: 992px) {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const TitleTextGridContainer = styled(Grid)`
  flex-direction: column;
`;

export const ButtonsGrid = styled(Grid)`
  display: flex;
`;

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px);
    border-radius: 12px;
  }
`;
