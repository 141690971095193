import {
  Dialog, TableCell, TableRow, TableHead, Button,
  MenuItem, DialogContent, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    height: 100vh;
    max-height: 100vh;
    /* width: 40rem; */
  }
`;

export const CardTitle = styled(Typography)`
  font-weight: bold;
  color: #606060;
  font-size: 20px;
`;

export const CardSubTitle = styled(Typography)`
  font-weight: 600;
  color: #606060;
  font-size: 14px;
`;

export const CardSubTitle2 = styled(Typography)`
  font-weight: 600;
  color: #606060;
  font-size: 16px;
  line-height: 2;
`;

export const PrevNextFooter = styled('div')`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Back = styled('div')`
  display: flex;
  gap: 0.1rem;
  margin-bottom: 1rem;
  align-items: center;
  cursor: pointer;
`;

export const ContainerBody = styled('div')`
  margin-top: 10px;
  padding: 20px 40px;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #f4f5f6;
`;

export const TableRowStyled = styled(TableRow)`
  overflow-x: scroll;
`;

export const TableCellStyled = styled(TableCell)`
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  border-bottom: none;
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 170px;
  height: 45px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #303030 !important;
`;

export const PrevButtonStyled = styled(Button)`
  width: 170px;
  height: 45px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #303030 !important;
`;

export const ButtonDarkStyled = styled(Button)`
  width: 170px;
  height: 45px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #fff !important;
`;

export const NextButtonStyled = styled(Button)`
  width: 170px;
  height: 45px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #fff !important;
`;

export const ButtonGrayStyled = styled(Button)`
  width: 170px;
  height: 45px;
  background: #c0c0c1 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #fff !important;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const TableCellBodyStyled = styled(TableCell)`
  white-space: nowrap;
  font-size: 15px;
  border-bottom: none;
`;

export const DialogContentStyled = styled(DialogContent)`
  max-width: 42rem;
`;

export const Hbg = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0 1rem 0;
  width: 100%;
  height: 60px;
  line-height: 100px;
  background: #235A91;
  color: #fff;
  padding: 0 2rem;
`;

export const HbgSmall = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
`;

export const CustomerDetails = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem 0 2rem;
`;

export const CustomerDetail = styled('span')`
  font-weight: 600;
  font-size: 15px;
  color: #606060;
`;

export const CustomerDetailLt = styled('span')`
  font-weight: 400;
  font-size: 15px;
  color: #606060;
`;

export const DefaultTable = styled('table')`
  width: 100%;
  margin-top: 1rem;
`;

export const DefaultTrHead = styled('tr')`
  background: #F3F9FF;
`;

export const DefaultTr = styled('tr')``;

export const DefaultTd = styled('td')`
  padding: 0.6rem 2rem !important;
  font-weight: 600;
  font-size: 16px;
  color: #606060;
`;

export const DefaultTdBody = styled('td')`
  padding: 0.9rem 2rem 0 2rem !important;
  font-size: 14px;
  color: #606060;
`;

export const Separator = styled('div')`
  background: #f4f5f7;
  height: 0.1rem;
  width: 100%;
  width: 90%;
  margin: 1rem 2rem 0 2rem !important;
`;

export const ReceiptFooter = styled('div')`
  padding: 1.5rem 0;
  text-align: center;
`;

export const SmallText = styled('span')`
  font-size: 14px;
`;

export const SmallerText = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  color: #808080;
  font-style: italic;
`;
