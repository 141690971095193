import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 2rem 0.9rem 1rem;
  font-size: 16px;
  font-weight: 400;

  &:hover{
    background: #F3F9FF;
  }
`;
