import {
  Button,
  Box,
  Grid,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from '../customComponents/customButton';

export const DivFullWidth = styled('div')`
  width: 100%;
`;

export const TableGrid = styled(Grid)`
  margin: 0.5rem 0;
  overflow-x: auto;
`;

export const TCustomButton = styled(CustomButton)`
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 3rem;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
`;

export const FooterWrapper = styled(Grid)`
  width: 100%;
  margin: 2rem 0;
`;

export const SearchFlex = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1.3rem 0;
`;

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
  align-items: center;
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
  padding: 0 4rem 4rem;
  overflow-x: hidden;
  margin-bottom: 1rem;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
  margin-bottom: 2rem;
`;

export const MainTableHead = styled(TableHead)``;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #f3f9ff;
  padding: 16px 10px;
  border: 0;
  /**text-transform: capitalize;**/

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;

  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const ButtontGrid = styled(Grid)`
  justify-content: space-between;
  margin: 10px 10px 5px 10px;
  @media (min-width: 992px) {
    margin: 3rem 5px 5px;
  }
`;

export const ButtonWrapper = styled(Button)`
  background-color: #000000;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 0.9rem;
  &.MuiButton-text:hover {
    background-color: #424242;
  }
`;

export const ButtonText = styled(Typography)`
  color: #ffffff;
`;

export const SearchContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 2rem;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const SearchTextField = styled(TextField)`
  background: #ffffff;
  border-radius: 12px;
  border: 1.5px solid #d9dbe9;
  & ::placeholder {
    color: #24292e;
    opacity: 1;
  }

  & .MuiOutlinedInput-input {
    padding: 25px;
  }
  & .MuiOutlinedInput-root {
    font-size: 2.2rem;
    border-radius: 10px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
    border-width: 0;
  }

  @media (min-width: 992px) {
    & .MuiOutlinedInput-input {
      padding: 15px 14px;
    }

    & .MuiOutlinedInput-root {
      font-size: 1rem;
      border-radius: 10px;
    }
  }

  @media (max-width: 991px) {
    border-radius: 20px;
  }
`;

export const SearchButton = styled(IconButton)`
  cursor: pointer;
  padding: 14px 20px;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #d2c647;
  }

  &:disabled {
    color: #424242;
  }

  @media (min-width: 992px) {
    padding: 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 64px;

  @media (min-width: 992px) {
    width: 30px;
  }
`;

export const DownloadBox = styled(Box)`
  text-align: right;
  margin-bottom: 10px;
`;

export const DownloadWrapper = styled(Button)`
  background-color: #000000;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 0.9rem;
  width: 210px;
  &.MuiButton-text:hover {
    background-color: #424242;
  }
`;

export const MobileContainer = styled(Box)`
  width: 100%;
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 24px;
    margin-top: 1rem;
  }
`;

export const SubTitle = styled(Typography)`
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
`;

export const MenuButton = styled(IconButton)`
  background-color: none;
  color: #235a91;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;
