import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  ConnectedMPIcon,
  Description,
  RowContainer,
  RowTitle,
  RadioButton
} from './marketplaceDialog.styles';

const MarketPlaceReturnRow = ({ row, setMarketPlace }) => {
  const { name, linkCode } = row;

  return (
    <RowContainer>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Box>
          <ConnectedMPIcon />
        </Box>
        <Box>
          <RowTitle>{name}</RowTitle>
          <Description>OGApharmacy Marketplace</Description>
        </Box>
      </Box>
      <Box>
        <RowTitle><RadioButton onChange={(e) => setMarketPlace(e.target.value)} type="radio" value={linkCode} name="marketplace" style={{ fontSize: '4rem' }} /></RowTitle>
      </Box>
    </RowContainer>
  );
};

MarketPlaceReturnRow.propTypes = {
  row: PropTypes.shape(Object),
  setMarketPlace: PropTypes.func.isRequired,
};

MarketPlaceReturnRow.defaultProps = {
  row: {}
};

export default MarketPlaceReturnRow;
