import {
  Grid, Typography, IconButton, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PercentageWapper = styled(Box)`
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transition: 500ms;
  left: 0;
  top: 0;
`;

export const SampleCsvWrapper = styled(Grid)`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: flex-start;
  text-align: left;
  position: relative;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5rem 1rem;
`;
// box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const CsvFileImage = styled('img')`
  height: 42px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  color: #000;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
`;
export const CsvSubtitle = styled(Typography)`
  color: #000;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
`;
export const DownloadIconWrapper = styled(IconButton)`
  background: none;
  text-align: center;
`;
export const DownloadIconImage = styled('img')`
  height: 22px;
`;
export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;
