import React from 'react';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle
} from './titleGrid.styles';

export function TitleGrid() {
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item md={6}>
        <PrimaryTitle>
          Connect Marketplace
        </PrimaryTitle>
        <SecondaryTitle>
          View and manage connected marketplace
        </SecondaryTitle>
      </TitleTextGridContainer>
    </TitleGridContainer>
  );
}

export default TitleGrid;
