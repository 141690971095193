import {
  Grid,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Typography,
  Paper,
  Box
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: none;

  @media (min-width: 992px) {
    display: table-header-group;
  }
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const BackButton = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  color: #255B91;
  padding: 0.5rem 0;
  margin-bottom: .5rem;
`;

export const GridContainer = styled(Grid)`
  padding: 30px;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 25px;
`;

export const DivFullWidth = styled('div')`
  width: 100%;
  margin-top: 2rem;
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const ErpHeader = styled(TableCell)`
  text-transform: uppercase;
  padding: 10px;

  :first-child,
  :last-child {
    width: 3%;
  }

  @media (min-width: 992px) {
    &:nth-child(5) {
      width: 25%;
    }
    &:nth-child(2),
    :nth-child(4),
    :nth-child(6) {
      width: 15%;
    }
    &:nth-child(3),
    :nth-child(7) {
      width: 12%;
    }
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;
