import {
  Grid, Box, TableContainer, Table, TableHead, TableCell
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const ErpHeader = styled(TableCell)`
  text-transform: uppercase;
  padding: 15px 2px;
  background: #F4F5F7;
  & > * {
    border-bottom: unset;
  }

  &:first-child, :last-child {
    width: 2%
  }
  @media(min-width: 992px) {
    &:first-child {
      width: 0%
    }
    &:last-child {
      width: 2%
    }
    &:nth-child(3) {
      width: 12%;
    }
    &:nth-child(2), :nth-child(4), :nth-child(6), :nth-child(7) {
      width: 15%;
    }
    &:nth-child(5) {
      width: 20%;
    }
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

export const MobileContainer = styled(Box)`
  width: 100%;
  background-color: #fff;
`;

export const SortImg = styled('img')`
  margin-left: 15px;
  padding-bottom: 2px;
`;
