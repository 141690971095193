import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Hidden, Grid, Button } from '@mui/material';
import moment from 'moment';
import currencyFormatter from '../../shared/currencyFormatter';
import ReturnRow from './returnRow';
import {
  CIconButton, KeyboardArrowLeft, PrimaryTitle, SecondaryTitle, GridContainer, PaperCard,
  CardLogo, CardText, CardHeaderGrid, CardHeaderText, NameHeaderText,
  TableHeaderCell, TotalsGrid
} from './closedOrders.styles';
import { SUPPLIER_ORDER, PO_LIST } from '../../../queries/orders';
import PoPopper from './poPopper';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import GoToTop from '../../customComponents/scrollToTop';
import { JSONParse } from '../../../utils/json';
import { OgaToken } from '../../../graphql/token';

const headers = [
  'product items', 'qty ordered', 'qty received', 'unit cost', 'price'
];

const AffiliateFormDetail = () => {
  const [totalPrice, setTotalPrice] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleDownload = () => {
    window.open(
      `${OgaToken.SERVER_LINK}download_order_invoice/${id}`,
      '_blank'
    );
  };

  const {
    loading, error, data
  } = useQuery(SUPPLIER_ORDER, {
    fetchPolicy: 'no-cache',
    variables: { id }
  });

  const {
    data: poData
  } = useQuery(PO_LIST, {
    fetchPolicy: 'no-cache',
    variables: { supplierOrderId: id }
  });

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;

  const items = poData?.supplierOrderPurchaseOrders || [];

  const {
    orderproductSet, dateCreated, deliveryDate, id: supplierId, supplier, order
  } = data?.supplierOrder || {};
  const { logo, name } = supplier || {};
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { contacts } = order?.business?.deliveryLocations[0];

  const businessContact = JSONParse(contacts.replace(/'/g, '"').replace('None', '"Lagos"'));

  const {
    mobile_number: mobileNumber, primary_email_address: primaryEmailAddress,
    address_line_1: addressLineOne, address_line_2: addressLineTwo,
    region, city, country
  } = businessContact ?? '';

  const returnHeaders = () => headers.map((header) => (
    <TableHeaderCell key={header}>{header}</TableHeaderCell>
  ));

  const actionButtonHandler = (poId) => {
    window.open(`${OgaToken.SERVER_LINK}export_purchase_order/${poId}`, '_blank');
  };

  return (
    <MainContent>
      <GridContainer>
        <Grid container item style={{ marginTop: '20px' }}>
          <Grid container item xs={12} md={6}>
            <CIconButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
            </CIconButton>
            <Grid item alignItems="center">
              <PrimaryTitle variant="h5">Order Detail</PrimaryTitle>
              <Hidden lgDown>
                <SecondaryTitle variant="caption">View details of an order sent to a supplier edited</SecondaryTitle>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} spacing={3} justifyContent="flex-end" alignItems="center">
            <PoPopper actionHandler={actionButtonHandler} items={items} />
            <Button
              variant="contained"
              onClick={handleDownload}
              style={{ width: '12rem', height: '2.9rem', marginLeft: '1rem' }}
            >
              Download Invoice
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" spacing={3} style={{ marginTop: '20px' }}>
          <Grid item container md={12} lg={5}>
            <PaperCard>
              <CardLogo src={logo} />
              <NameHeaderText>{name}</NameHeaderText>
              <CardText>{`ID${supplierId}`}</CardText>
              <CardText>
                Placed on:
                <b>{` ${moment(dateCreated).format('DD/MM/YYYY')}`}</b>
              </CardText>
              <CardText>
                Estimated Delivery:
                <b>{` ${deliveryDate || 'N/A'}`}</b>
              </CardText>
            </PaperCard>
          </Grid>
          <Grid item container md={12} lg={7}>
            <PaperCard>
              <CardHeaderGrid item container>
                <CardHeaderText>Delivery Location</CardHeaderText>
              </CardHeaderGrid>
              <CardText>Mobile</CardText>
              <CardText>
                <b>{mobileNumber || 'N/A'}</b>
              </CardText>
              <CardText>
                Email Address
              </CardText>
              <CardText>
                <b>{primaryEmailAddress || 'N/A'}</b>
              </CardText>
              <CardText>
                Address
              </CardText>
              <CardText>
                <b>{addressLineOne && `${addressLineOne},`}</b>
                <b>{addressLineTwo && ` ${addressLineTwo},`}</b>
                <b>{region && ` ${region},`}</b>
                <b>{city && ` ${city},`}</b>
                <b>{country && ` ${country}.`}</b>
              </CardText>
            </PaperCard>
          </Grid>
        </Grid>
        <Grid item container style={{ marginTop: '2rem' }}>
          <PaperCard style={{ padding: '2rem 1rem' }}>
            <Hidden lgDown>
              <Grid item container>
                {returnHeaders()}
              </Grid>
            </Hidden>
            {orderproductSet.map((prod) => (
              <ReturnRow
                key={prod}
                prod={prod}
                setTotalPrice={setTotalPrice}
              />
            ))}
            <TotalsGrid item container>
              <CardHeaderText>SubTotal</CardHeaderText>
                &nbsp;&nbsp;&nbsp;
              <CardHeaderText>{`₦ ${currencyFormatter(totalPrice)}`}</CardHeaderText>
            </TotalsGrid>
          </PaperCard>
        </Grid>
        <GoToTop />
      </GridContainer>
    </MainContent>
  );
};

export default AffiliateFormDetail;
