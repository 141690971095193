import {
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExportIcon } from '../../../assets/svgs';

export const ExportIconStyled = styled(ExportIcon)`
  margin-right: 0.5rem;
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
`;

export const TabImage = styled('img')`
  width: 26px;
`;
