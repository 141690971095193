import React from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery
} from '@mui/material';
import {
  ResultBox, ContentGrid, CPagination, SelectStyled
} from './tablePagination.styles';

const TablePagination = ({
  pageCount, setPageCount, pageNumber, setPageNumber, total,
  hideRowsPerPage, displayShowResult
}) => {
  const number = pageNumber === 1 ? pageNumber : ((pageNumber - 1) * pageCount) + 1;
  const count = pageNumber === 1 ? pageCount : pageCount * pageNumber;
  const isSmall = useMediaQuery('(max-width: 991px)');

  const handleChange = (event, page) => {
    setPageNumber(page);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageCount(parseInt(event.target.value));
  };

  return (
    <ContentGrid item container>
      {!hideRowsPerPage ? (
        <div style={{
          marginBottom: isSmall ? '2rem' : '',
          marginLeft: isSmall ? '2rem' : ''
        }}
        >
          <span>Rows Per Page: </span>
          <SelectStyled
            value={pageCount}
            onChange={handleChangeRowsPerPage}
          >
            <option>5</option>
            <option>10</option>
            <option>20</option>
          </SelectStyled>
        </div>
      ) : <div />}
      {displayShowResult && (
        <ResultBox>
          {`Showing Results for ${number}-${count} of ${total.toLocaleString()}`}
        </ResultBox>
      )}
      <CPagination
        onChange={handleChange}
        count={Math.ceil(total / pageCount)}
        variant="outlined"
        color="primary"
        shape="round"
        page={pageNumber}
      />
    </ContentGrid>
  );
};

TablePagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  hideRowsPerPage: PropTypes.bool,
  displayShowResult: PropTypes.bool
};

TablePagination.defaultProps = {
  hideRowsPerPage: false,
  displayShowResult: false
};

export default TablePagination;
