import React from 'react';
import PropTypes from 'prop-types';

const ValidText = ({ text, fallBackText }) => {
  const defaultValue = 'N/A';
  if (!text || text === null || text === undefined || text === 'NaN') {
    return fallBackText ? (
      <span>{fallBackText}</span>
    ) : (
      <span>{defaultValue}</span>
    );
  }
  return <span>{text}</span>;
};

ValidText.propTypes = {
  text: PropTypes.string.isRequired,
  fallBackText: PropTypes.string,
};

ValidText.defaultProps = {
  fallBackText: 'N/A',
};

export default ValidText;
