import {
  Grid, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleTextGridContainer = styled(Grid)`
  flex-direction: column;
`;

export const ButtonsGrid = styled(Grid)`
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 50px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 27px;
  color: #606060;

  @media(min-width: 992px) {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const SupplierOrderImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: .9rem;
  }
`;

export const ManageProductImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: .9rem;
  }
`;
