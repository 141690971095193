import { Grid, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonsGrid = styled(Grid)`
  padding: 0 1.8rem 1.8rem;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0 1.8rem 1.8rem;
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 2rem 0.9rem 1rem;
  font-size: 15px;

  &:hover {
    background: #f3f9ff;
  }
  &:active {
    background: #f3f9ff !important;
  }
`;
