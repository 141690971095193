import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import SideNav from '../shared/sideNav/sideNav';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import { GET_MY_BUSINESS } from '../../../queries/affiliates';
import TitleGrid from './titleGrid';
import BusinessSettingCard from './businessInformation/businessSettingCard';
import {
  TitleGridWrapper, OutletContentWrapper
} from './business.styles';
import { JSONParse } from '../../../utils/json';
import { UPDATE_PREFERENCE_SETTINGS_MUTATION } from '../../../mutations/business';
import { useStateValue } from '../../../providers/stateProvider';
import { preferenceJsonFormat } from '../../../utils/funcs';
import SuccessDialog from '../../shared/successDialog';

const PreferencesContainer = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [successProps, setSuccessProps] = useState({
    title: '',
    description: ''
  });

  const [preferences, setPreferences] = useState({
    automaticOrdering: 'automatic',
    orderCostFormat: 'average_order_cost',
    orderEmailingPolicy: 'suppliers',
    productPriceFormat: 'default',
    centralProcurementEmail: null,
    localCurrency: 1,
    loyaltyPoints: null,
    topMovingProduct: null,
    slowMovingProduct: null,
    automaticSales: null,
    zeroSalesFormat: null,
    restrictProductAndBatchCuOnChildStores: null,
    businessExcludedFromRestriction: '',
    dateRangeField: 'Last 1 months',
    vatSetting: {
      vatName: null,
      vatPercent: null
    }
  });

  const [{ user: { getUserInfoRefetch } }] = Object.values(useStateValue());

  const [updatePreferencesSetting, { loading }] = useMutation(UPDATE_PREFERENCE_SETTINGS_MUTATION);

  const {
    dataLoading, error, data, refetch
  } = useQuery(GET_MY_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(localStorage.getItem('ogarx_business_id'))
    },
  });

  useEffect(() => {
    if (!dataLoading && data && data.business) {
      const {
        settings, productSalesFormat, automaticOrderingFormat, loyaltyPointValue: loyaltyPoints, orderCostFormat,
        automaticSales, zeroSalesFormat, vatSetting: { vatName, vatPercent }
      } = data?.business ?? {};
      const parsedSettings = JSONParse(preferenceJsonFormat(settings));

      const {
        orderEmailingPolicy, centralProcurementEmail, productPriceFormat, localCurrency, restrict_product_and_batch_cu_on_child_stores, business_excluded_from_restriction
      } = parsedSettings || {};

      const {
        topMovingProductLimit: topMovingProduct,
        slowMovingProductLimit: slowMovingProduct
      } = productSalesFormat || {};

      setPreferences({
        ...preferences, orderCostFormat, orderEmailingPolicy, centralProcurementEmail, productPriceFormat,
        automaticSales, zeroSalesFormat, localCurrency, loyaltyPoints, topMovingProduct, slowMovingProduct, vatSetting: { vatName, vatPercent },
        dateRangeField: `Last ${automaticOrderingFormat} months`,
        automaticOrdering: automaticOrderingFormat === 3 ? 'automatic' : 'date_range', restrictProductAndBatchCuOnChildStores: restrict_product_and_batch_cu_on_child_stores !== 0,
        businessExcludedFromRestriction: business_excluded_from_restriction
      });
    }
  }, [dataLoading, data]);

  const returnRange = (range) => {
    const val = range.split(' ')[1];
    return Number(val);
  };

  const handleUpdatePreference = (pref = null) => {
    const {
      orderCostFormat, loyaltyPoints, topMovingProduct, slowMovingProduct, dateRangeField, vatSetting,
      automaticSales, zeroSalesFormat, restrictProductAndBatchCuOnChildStores, businessExcludedFromRestriction
    } = pref || preferences;

    updatePreferencesSetting({
      variables: {
        automaticOrderingRangeInMonths: returnRange(dateRangeField),
        loyaltyPointValue: loyaltyPoints,
        orderCostFormat,
        slowMovingProductLimit: slowMovingProduct,
        topMovingProductLimit: topMovingProduct,
        zeroSalesFormat,
        automaticSales,
        restrictProductAndBatchCuOnChildStores: restrictProductAndBatchCuOnChildStores ? 'YES' : 'NO',
        businessExcludedFromRestriction,
        vatSetting
      }
    }).then(() => {
      setOpenDialog(true);
      refetch();
      getUserInfoRefetch();
    });
  };

  if (dataLoading || !data) return <Loader />;
  if (error) return <div>{error.message}</div>;

  const preferenceProps = {
    loading,
    handleUpdatePreference,
    preferences,
    setPreferences,
    setSuccessProps
  };

  return (
    <MainContent>
      <TitleGridWrapper>
        <TitleGrid handleUpdatePreference={handleUpdatePreference} setSuccessProps={setSuccessProps} />
      </TitleGridWrapper>
      <OutletContentWrapper container spacing={3}>
        <SideNav />
        <BusinessSettingCard
          preferenceProps={preferenceProps}
        />
      </OutletContentWrapper>

      <SuccessDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={successProps.title}
        desc={successProps.description}
        option="ok"
      />
    </MainContent>
  );
};

export default PreferencesContainer;
