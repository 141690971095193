import { gql } from '@apollo/client';

export const GET_CUSTOMER_PRESCRIPTIONS = gql`
  query customerPrescriptions(
    $customerId: Int!
    $search: String
    $pageCount: Int
    $pageNumber: Int
  ) {
    customerPrescriptions(
      customerId: $customerId
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      id
      saleItem {
        product {
          brandName
        }
      }
      businessUser {
        fullName
      }
      customer{
        name
      }
      dateIssued
      dosePerDay
      usageDays
      numTablets
      unit
      tabletsPerUnit
      status
    }
    businessTotalNumber
  }
`;
