import {
  Dialog, Grid, Typography, IconButton, Paper, TableCell,
  InputBase
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const BatchDialog = styled(Dialog)`
& .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
}
`;

export const DialogWrapper = styled(Grid)`
    width: 40rem;
    padding: 0 1.5rem;
`;

export const DialogHeader = styled(Grid)`
    width: 37rem;
    padding: 1.5rem 0;
    background: #fff;
    position: fixed;
    z-index: 1;
`;

export const DialogBody = styled(Grid)`
    height: max-content;
    margin-top: 4.5rem;
`;

export const DialogActions = styled(Grid)`
    justify-content: flex-end;
    padding: 1rem 0 1.5rem;
    background: #fff;
    position: sticky;
    bottom: 0;
    z-index: 1;
`;

export const SubText = styled(Typography)`
    margin-right: 15px;
    font-size: 17px;
    font-weight: 400;
`;

export const CustomInputBase = styled(InputBase)`
    border: 0.5px solid #d8d8d8;
    width: 60%;
    border-radius: 6px;
    font-size: .8rem;
    color: #606060;
    line-height: 3.2;

    & .MuiInputBase-input {
        padding: 12px;
        padding-right: 5px;
    }
    & .MuiInputAdornment-positionEnd {
        margin-right: 5px;
        margin-left: 0px;
    }
`;

export const HeaderGrid = styled(Grid)`
    height: max-content;
    margin-top: 2rem;
    font-size: 18px;
    font-weight: 700;
`;

export const TableHeader = styled(TableCell)`
    font-size: 16px;
    font-weight: 700;
    line-height: 3.2;
    padding: 5px;
    border-bottom: 0;
    background: #F3F9FF;

    @media(min-width: 992px) {
        &:nth-child(1) {
        width: 8%;
        padding-left: 15px;
        }
        &:nth-child(2), &:nth-child(4), :nth-child(5) {
        width: 22%;
        padding-left: 20px;
        }
        &:nth-child(3) {
        width: 24%;
        }
    }
`;

export const MainTableRow = styled(Grid)`
    height: max-content;
    align-items: center;

    & > * {
        border-bottom: unset;
    }
`;

export const BodyCell = styled(TableCell)`
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 400;
    color: #606060;
    line-height: 3.2;
    padding: 5px;

    @media(min-width: 992px) {
        &:nth-child(1) {
        width: 8%;
        padding-left: 15px;
        }
        &:nth-child(2), &:nth-child(4), :nth-child(5) {
        width: 22%;
        padding-left: 20px;
        }
        &:nth-child(3) {
        width: 24%;
        }
    }
`;

export const CloseIcon = styled(Close)`
    cursor: pointer;
    width: 22px;
`;

export const DialogOkButton = styled(IconButton)`
    height: 50px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    border-radius: 10px;
    margin-right: 30px;
    background-color: #FCED50;
    color: #424242;
    margin-bottom: 40px;

    &:hover {
        background-color: #D2C647;
    }
`;

export const ProductName = styled(Typography)`
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
`;
export const AddButton = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    background: #FCED50;
    margin-left: 25px;
    color: #424242;
    padding: 20px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
`;
export const OutOfStockContainer = styled(Paper)`
    padding: 20px;
    background: #E4E4E4;
    max-width: 320px;
    margin-top: 17px;
`;
export const InfoText = styled(Typography)`
    font-size: 10px;
    color: #887F23;
`;
export const OutOfStockText = styled(Typography)`
    font-weight: 600;
    color: #424242;
`;
