import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import Stepper from './verticalStepper';
import {
  TitleWrapper, TitleText,
  DateText, DialogFooter, RiderDetails,
  DividerLine, RiderImage, RiderTitle,
  RiderName, SortingContainer, Heading,
  SubHeading
} from './styles';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

export default function Index({
  dialogOpen,
  closeDialog,
  status,
  deliveryId
}) {
  const orderStatusMap = () => {
    switch (status) {
      case 'ORDER_SORTED':
        return 'Sorted';
      case 'ORDER_DISPATCHED':
        return 'Out for Delivery';
      case 'ORDER_CLOSED':
        return 'Delivered';
      case 'ORDER_CANCELED':
        return 'Cancelled';
      default:
        return 'New';
    }
  };

  const todaysDate = moment().format('DD/MM/YYYY');
  const mappedStatus = orderStatusMap();

  const [dispatched, setDispatched] = useState(0);

  useEffect(() => {
    setDispatched(mappedStatus !== 'New');
  }, [status]);

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
      fileslimit={1}
    >
      <DialogTitle id="alert-dialog-slide-title">
        <TitleWrapper>
          <TitleText>Delivery #4565</TitleText>
          <DateText>{todaysDate}</DateText>
        </TitleWrapper>
        <DividerLine />
      </DialogTitle>

      <DialogContent>
        {
                dispatched
                  ? <Stepper status={mappedStatus} deliveryId={deliveryId} />
                  : (
                    <SortingContainer>
                      <Heading>
                        Thank you for your patience!
                      </Heading>
                      <SubHeading>
                        Your order is still being sorted we’ll let you know when it is ready for dispatch
                      </SubHeading>
                    </SortingContainer>
                  )
            }
      </DialogContent>

      <DividerLine />

      <DialogFooter>
        <RiderImage />

        <RiderDetails>
          <RiderTitle>
            Rider
          </RiderTitle>
          <RiderName>
            N/A
          </RiderName>
          <RiderName>
            -
          </RiderName>
        </RiderDetails>

      </DialogFooter>
    </Dialog>
  );
}

Index.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  deliveryId: PropTypes.string.isRequired
};
