import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import AppBar from '@mui/material/AppBar';
import {
  Grid
} from '@mui/material';
import moment from 'moment';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import {
  AntTabs, MainContainer, OrderGridContainer, StyledTab
} from '../../orders/admin/order.styles';
import { useStateValue } from '../../../providers/stateProvider';
import AffiliatesIcon from '../../../assets/images/dashboard/affiliates-icon.png';
import SuppliersIcon from '../../../assets/images/dashboard/suppliers-icon.png';
import ProductsIcon from '../../../assets/images/dashboard/products-icon.png';
import TotalOrderIcon from '../../../assets/images/order/Total Orders Icon.png';
import GrossOrderIcon from '../../../assets/images/order/Gross Order Value Icon.png';
import {
  TotalMsProducts, NoOfTransactions, TotalQtyMissed, TotalValueofOs, TotalValueOfMs
} from '../../../assets/svgs';
import SummaryController from '../../orders/admin/dashboard/summaryController';
import { GET_SALES_RECONCILIATION_SUMMARY } from '../../../queries/sales';
import OutOfStockTab from './outOfStockTab';
import TabPanel from '../../shared/tabPanel';
import MissedSaleTab from './missedSaleTab';
import ToggleBusiness from '../../shared/busnesss';
import currencyFormatter from '../../shared/currencyFormatter';
import CardLoader from '../../shared/cardLoader';
import CStatisticCard from './CStatisticCard';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle, SecondaryTitle,
  CIconButton, StatisticsGrid, PaperWrapper
} from './salesReconciliationContainer.style';
import Filter from './filter';

const SalesReconciliationContainer = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [outOfStockReFetchCount, setOutOfStockReFetchCount] = useState(1);
  const [{ navbar: { openSideDrawer } }, dispatch] = Object.values(useStateValue());
  const [productState, setProductState] = useState([]);
  const navigate = useNavigate();
  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);
  const handleReFetchOutOfStock = (message, data = '') => {
    if (message && data) {
      toast.success(message);
      setOutOfStockReFetchCount(0);
      setTimeout(() => setOutOfStockReFetchCount(1), 0);
    } else {
      toast.error(message);
    }
  };
  const startDate = moment().startOf('day');
  const endDate = moment().endOf('day');
  const [dateFilter, setDateFilter] = useState({
    dateFrom: startDate,
    dateTo: endDate
  });

  const initialState = {
    totalMissedSaleProduct: 0,
    totalQuantityMissed: 0,
    totalOutOfStockSales: 0,
    totalOutOfStockTransactions: 0,
    totalMissedSales: 0
  };
  const [state, setState] = useState(initialState);
  const { tab } = useParams() || '';
  const [tabVal, setTabVal] = useState('out-of-stock');

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDateFilter({
      dateFrom,
      dateTo
    });
  };

  const { dateFrom, dateTo } = dateFilter;
  const variables = {
    dateFrom,
    dateTo,
    period: 'day'
  };

  const {
    data, loading, error
  } = useQuery(GET_SALES_RECONCILIATION_SUMMARY, {
    fetchPolicy: 'no-cache',
    variables
  });

  useEffect(() => {
    if (tab) setTabVal(tab);
  }, [tab]);

  useEffect(() => {
    if (data) {
      const { salesReconciliationSummary } = data;

      setState((_s) => ({
        ..._s,
        totalMissedSaleProduct: salesReconciliationSummary.totalMissedSaleProduct,
        totalQuantityMissed: salesReconciliationSummary.totalQuantityMissed,
        totalOutOfStockSales: salesReconciliationSummary.totalOutOfStockSales,
        totalOutOfStockTransactions: salesReconciliationSummary.totalOutOfStockTransactions,
        totalMissedSales: salesReconciliationSummary.totalMissedSales
      }));
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;

  const statistics = [{
    id: 1,
    icon: TotalMsProducts,
    title: 'Total M/S Products',
    value: state.totalMissedSaleProduct,
    type: 'number'
  }, {
    id: 2,
    icon: NoOfTransactions,
    title: 'Total O/S Transactions',
    value: state.totalOutOfStockTransactions,
    type: 'number'
  }, {
    id: 3,
    icon: TotalQtyMissed,
    title: 'Total Qty Missed',
    value: state.totalQuantityMissed,
    type: 'number'
  }, {
    id: 4,
    icon: TotalValueofOs,
    title: 'Total Value of O/S',
    value: state.totalOutOfStockSales,
    type: 'amount'
  }, {
    id: 5,
    icon: TotalValueOfMs,
    title: 'Total Value of M/S',
    value: state.totalMissedSales,
    type: 'amount'
  }];

  const getStatistics = (statistic) => {
    const outputStatistic = {};
    if (statistic.type && statistic.type === 'amount') {
      outputStatistic.value = `₦ ${currencyFormatter(statistic.value)}`;
    }
    if (statistic.type && statistic.type === 'number') {
      outputStatistic.value = `${currencyFormatter(statistic.value)}`;
    }
    return { ...statistic, ...outputStatistic };
  };
  // const loading = false;

  const handleTabChange = (val) => {
    setTabVal(val);
  };

  const currentTab = () => {
    switch (tabVal) {
      case 'out-of-stock':
        return (
          <OutOfStockTab
            setProductState={setProductState}
            handleReFetch={handleReFetchOutOfStock}
            reFetchCount={outOfStockReFetchCount}
            dateFilter={dateFilter}
          />
        );
      case 'missed-sales':
        return (
          <MissedSaleTab dateFilter={dateFilter} />
        );
      default:
        return (
          <OutOfStockTab
            setProductState={setProductState}
            handleReFetch={handleReFetchOutOfStock}
            reFetchCount={outOfStockReFetchCount}
            dateFilter={dateFilter}
          />
        );
    }
  };

  return (
    <MainContent>
      <MainContainer container style={{ padding: '30px' }}>
        <TitleGridContainer container item>
          <TitleTextGridContainer item md={7}>
            <CIconButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft style={{ fontSize: '1.9rem' }} />
              <p style={{ fontSize: '16px', marginLeft: '10px' }}>back</p>
            </CIconButton>
            <PrimaryTitle variant="h5" style={{ marginTop: '1rem' }}>Sales Reconciliation</PrimaryTitle>
            <SecondaryTitle>
              Track and update sales
            </SecondaryTitle>
          </TitleTextGridContainer>
          <Grid item>
            <CustomDateRanges
              handleSubmit={handleSubmit}
              xs={12}
            />
          </Grid>
        </TitleGridContainer>
        <StatisticsGrid item container spacing={2}>
          {loading
            ? <CardLoader cols={4} />
            : statistics.map((statistic) => (
              <CStatisticCard
                key={statistic.id}
                statistic={getStatistics(statistic)}
                admin
              />
            ))}
        </StatisticsGrid>
        <PaperWrapper elevation={0}>
          <Filter
            tabVal={tabVal}
            handleTabChange={handleTabChange}
          />
          {currentTab()}
        </PaperWrapper>
        {/* <OrderGridContainer container>
          <SummaryController
            statistics={statistics}
            allowChainManager
            querySchema={GET_SALES_RECONCILIATION_SUMMARY}
            queryName="salesReconciliationSummary"
            primaryTitle="Sales Reconciliation"
            secondaryTitle="Track and update sales"
            backRoute="sales"
            reFetchCount={outOfStockReFetchCount}
          />
          <AppBar elevation={0} position="static">
            <AntTabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="Reconciliation tabs">
              <StyledTab label="Out of Stock" />
              <StyledTab label="Missed Sales" />
              <ToggleBusiness />
            </AntTabs>
          </AppBar>
          <TabPanel value={tabIndex} index={0} style={{ padding: 0 }}>
            {outOfStockReFetchCount ? (
              <OutOfStockTab
                reFetchCount={outOfStockReFetchCount}
                handleReFetch={handleReFetchOutOfStock}
              />
            ) : null}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} style={{ padding: 0 }}>
            <MissedSaleTab />
          </TabPanel>

            </OrderGridContainer> */ }
      </MainContainer>
    </MainContent>
  );
};

export default SalesReconciliationContainer;
