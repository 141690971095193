const reconciliation = {
  batchModalData: {
    saleItemBatchId: null,
    openedBatchModal: false,
    product: null,
    productName: '',
    packSize: '',
    batches: new Map(),
    targetQuantity: null
  }
};

export default reconciliation;
