import React from 'react';
import PropTypes from 'prop-types';
import { SwitchStyled } from './customDataSwitch.styles';

const CustomDataSwitch = ({ checked, handleChange, sliderValue }) => (
  <SwitchStyled
    checked={checked}
    onChange={handleChange}
    name="checkedA"
    sliderValue={sliderValue}
    inputProps={{ 'aria-label': 'secondary checkbox' }}
  />
);

CustomDataSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  sliderValue: PropTypes.string.isRequired,
};

export default CustomDataSwitch;
