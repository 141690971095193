import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyCell, MainTableRow,
} from './returnRow.styles';
import currencyFormatter from '../../shared/currencyFormatter';

const ReturnRow = ({
  row, index
}) => {
  const {
    brandName, packSize, price, totalSales, qtyInStock, qtySold
  } = row;

  const salesTotal = currencyFormatter(totalSales);
  const productPrice = currencyFormatter(price);

  const tableCells = [brandName, productPrice, packSize, salesTotal, qtyInStock, qtySold];

  const returnCell = (val) => val ?? 'N/A';

  const renderBodyCells = () => (
    tableCells.map((cell) => (
      <BodyCell style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
    ))
  );

  return (
    <MainTableRow style={index % 2 ? { backgroundColor: ' #F4F7FC' } : { backgroundColor: '#FFF' }}>
      {renderBodyCells()}
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.instanceOf(Number),
};

ReturnRow.defaultProps = {
  row: {},
  index: 0,
};

export default ReturnRow;
