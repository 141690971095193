import { gql } from '@apollo/client';
import { PRODUCT_VERSIONS } from './products';

export const ORDER_QUERY = gql`
${PRODUCT_VERSIONS}
  query($bizId: Int!, $isManualOrder: Boolean) {
    isBusinessLoanEligible
    business(id: $bizId) {
      id
      name
      contacts
      dateCreated
      tax
      deliveryLocations {
        id
        name
        contacts
      }
    }
    order(isManualOrder: $isManualOrder){
      id
      meta
      supplierorderSet {
        orderproductSet {
          id
          name
          price
          unitCost
          quantity
          savings
          hybrid
          alternativeSupplier {
            id
            name
          }
          supplierOrder {
            supplier {
              name
              settings
            }
          }
          product {
            id
            sku
            meta
            price
            business{
                id
            }
           ...productVersions
          }
        }
      }
    }
  }
`;

export const SAVED_CART = gql`
query saveCartOrder($pageCount: Int, $pageNumber: Int){
  saveCartOrder(pageCount: $pageCount, pageNumber: $pageNumber){
    id
    name
    meta
  }
}
`;
