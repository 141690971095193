import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import {
  Grid, Hidden, TableBody, Button, useMediaQuery
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import TablePagination from '../shared/tablePagination';
import CustomSearchField from '../shared/CustomSearchField';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import CStatisticCard from '../orders/shared/cStatisticCard';
import CardLoader from '../shared/cardLoader';
import currencyFormatter from '../shared/currencyFormatter';
import StatusDropdown from './statusDropdown';
import {
  TableComponent, MainTableHead, ExportIconStyled, FooterWrapper, TableHeader, TitleGridContainer,
  TitleTextGridContainer, TextWrapper, PrimaryTitle, TCustomButton, StatisticsGrid, MainTableRow,
  SecondaryTitle, TableGrid, BodyCell, PaperWrapper, SearchGrid, NCustomButton,
  ColorField, Status, MobileContainer, DetailText, HeaderText, TextBox, DateText, ContentBox
} from './stockReport.styles';
import Arrow from '../../assets/images/marketOrder/Arrow.png';
import filterSearch from '../../assets/images/marketOrder/filter-search.png';
import MainContent from '../customComponents/mainContent';
import {
  BarChart, MoneyStack, TimeCaution, TotalDamage
} from '../../assets/svgs';
import ReportExportModel from '../../utils/reportExportModel';
import { useStateValue } from '../../providers/stateProvider';
import { GET_ALL_REPORTS_INVENTORY } from '../../queries/reports';
import { CREATE_NEW_REPORT_MUTATION } from '../../mutations/reports';

const headers = ['Last Updated', 'Business', 'Created By', 'Status'];

const StockReport = ({
  stockReports, stockReportsLoading, setPageCount, setPageNumber, setFilterStatus,
  handleStockReportClicked, currentPageCount, page, totalCount, params, endpoint,
  searchText, setSearchText
}) => {
  const initialState = {
    damagedTotals: [],
    expiredTotals: [],
    totalInventoryValue: 0,
    totalInStock: 0,
    totalExpired: 0,
    totalDamaged: 0
  };
  const [state, setState] = useState(initialState);
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const isSmall = useMediaQuery('(max-width: 991px)');

  const [{
    user: { businessId, allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());
  const navigate = useNavigate();

  const [openExportModel, setOpenExportModel] = useState(false);
  const [mutateCreateNewReport, { loading: cnrloading }] = useMutation(CREATE_NEW_REPORT_MUTATION);

  const {
    data, loading, error
  } = useQuery(GET_ALL_REPORTS_INVENTORY, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data) {
      const { allStockReportInventory } = data;

      setState((_s) => ({
        ..._s,
        totalInventoryValue: allStockReportInventory.totalInventoryValue,
        totalInStock: allStockReportInventory.totalQuantityInStock,
        totalExpired: allStockReportInventory.totalExpiredProducts,
        totalDamaged: allStockReportInventory.totalDamagedProducts
      }));
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;

  const statistics = [
    {
      id: 1, icon: MoneyStack, title: 'Total Inventory Value', value: state.totalInventoryValue, type: 'amount'
    },
    {
      id: 2, icon: BarChart, title: 'Total in Stock', value: state.totalInStock
    },
    {
      id: 3, icon: TimeCaution, title: 'Total Expired', value: state.totalExpired
    },
    {
      id: 4, icon: TotalDamage, title: 'Total Damaged', value: state.totalDamaged
    }
  ];

  const getStatistics = (statistic) => {
    const outputStatistic = {};
    if (statistic.type && statistic.type === 'amount') {
      outputStatistic.value = `₦ ${currencyFormatter(statistic.value)}`;
    }
    return { ...statistic, ...outputStatistic };
  };

  const handleOpenExport = () => {
    setOpenExportModel(true);
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const renderStatus = (_status) => (
    <ColorField>
      <Status
        status={_status}
        style={{ marginLeft: isSmall ? (_status === 'reconcile Qty' ? '8.5rem' : _status === 'complete' ? '12.5rem' : '0') : undefined }}
      />
      {_status}
    </ColorField>
  );

  const getStatus = (status) => {
    switch (status) {
      case 'COMPLETE':
        return renderStatus('complete');
      default:
        return renderStatus('reconcile Qty');
    }
  };

  const handleStatusChange = (_status) => {
    setDotsButtonEl(null);
    setFilterStatus(_status);
  };

  const options = [
    { name: 'all', disabled: false },
    { name: 'complete', disabled: false },
    { name: 'reconcile Qty', disabled: false },
  ];

  const maxLength = 16;

  const createNewReportHandler = () => {
    mutateCreateNewReport({
      variables: {
        business: businessId,
        status: 'RECONCILE_QUANTITY'
      }
    }).then((response) => {
      const reportId = response?.data?.createReport?.stockReport?.id;
      navigate(`/manual-stock-report/${reportId}`);
    }).catch((e) => console.log(e));
  };

  return (
    <MainContent>
      <Grid container style={{ padding: '30px' }}>
        <TitleGridContainer container item>
          <TitleTextGridContainer item md={7}>
            {!isSmall ? (
              <PrimaryTitle variant="h5">Stock Management</PrimaryTitle>
            )
              : (
                <PrimaryTitle variant="h5">
                  Stock Management
                </PrimaryTitle>
              )}
            <SecondaryTitle>
              Report For Product Inventory
            </SecondaryTitle>
          </TitleTextGridContainer>
          <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={5}>
            <Button
              variant="contained"
              style={{
                width: isSmall ? '60rem' : '11rem',
                height: isSmall ? '5rem' : '3rem',
                marginTop: isSmall ? '3rem' : '',
                fontSize: isSmall ? '35px' : '12px',
                fontWeight: isSmall ? '600' : '700',
                borderRadius: isSmall ? '20px' : '5px'
              }}
              onClick={createNewReportHandler}
              disabled={!userPermissions?.includes('stock_count_report_create_new_report') || cnrloading}
            >
              Create New Report
            </Button>
            <Button
              variant="outlined"
              style={{
                marginLeft: isSmall ? '0' : '1rem',
                width: isSmall ? '60rem' : '9rem',
                marginTop: isSmall ? '1rem' : '',
                height: isSmall ? '5rem' : '3rem',
                fontSize: isSmall ? '35px' : '12px',
                fontWeight: isSmall ? '600' : '700',
                borderRadius: isSmall ? '20px' : '5px'
              }}
              disabled={!userPermissions?.includes('stock_count_report_export')}
              onClick={handleOpenExport}
            >
              <Hidden mdDown>
                <ExportIconStyled />
              </Hidden>
              Export
            </Button>
          </Grid>
        </TitleGridContainer>
        <StatisticsGrid item container spacing={2}>
          {loading
            ? <CardLoader cols={4} />
            : statistics.map((statistic) => (
              <CStatisticCard
                key={statistic.id}
                statistic={getStatistics(statistic)}
                admin
              />
            ))}
        </StatisticsGrid>
        {!isSmall ? (
          <PaperWrapper elevation={0}>
            <SearchGrid item container spacing={2}>
              <Grid item container xs={11}>
                <CustomSearchField
                  name="search"
                  value={searchText}
                  placeholder="Search  for created by"
                  handleChange={(e) => setSearchText(e?.target?.value)}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item container xs={1}>
                <Button
                  variant="outlined"
                  onClick={(e) => setDotsButtonEl(e.currentTarget)}
                  disabled={!userPermissions?.includes('batch_details_set_filter')}
                >
                  <FilterList style={{ width: '20px', marginRight: '.4rem' }} />
                  Filter
                </Button>
              </Grid>
            </SearchGrid>
            <TableGrid item container>
              {stockReportsLoading
                ? <ProductsTableLoader />
                : (
                  <TableComponent item container>
                    <MainTableHead item container>
                      {returnHeaders()}
                    </MainTableHead>
                    <TableBody style={{ width: '100%' }}>
                      {stockReports?.map((_row) => (
                        <MainTableRow key={_row.id} item container onClick={() => handleStockReportClicked(_row.id)}>
                          <BodyCell>{moment(_row.lastUpdated).format('lll')}</BodyCell>
                          <BodyCell>{_row.business.name}</BodyCell>
                          <BodyCell>{_row.createdBy.user.username}</BodyCell>
                          <BodyCell>{getStatus(_row.status)}</BodyCell>
                        </MainTableRow>
                      ))}
                    </TableBody>
                  </TableComponent>
                )}
            </TableGrid>
            <FooterWrapper item container>
              {totalCount > 0 && (
              <TablePagination
                total={totalCount}
                pageCount={currentPageCount}
                setPageCount={setPageCount}
                pageNumber={page}
                setPageNumber={setPageNumber}
              />
              )}
            </FooterWrapper>
          </PaperWrapper>
        )
          : (
            <>
              <PaperWrapper elevation={0} style={{ borderRadius: '30px' }}>
                <SearchGrid item container spacing={2} style={{ height: '12rem' }}>
                  <Grid item container xs={10.5} style={{ marginBottom: '1rem' }}>
                    <CustomSearchField
                      name="search"
                      value={searchText}
                      placeholder="Search  for created by"
                      handleChange={(e) => setSearchText(e?.target?.value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item container xs={1.5}>
                    <Button
                      type="secondary"
                      header
                      onClick={(e) => setDotsButtonEl(e.currentTarget)}
                      disabled={!userPermissions?.includes('batch_details_set_filter')}
                      style={{
                        marginBottom: '1rem', height: '100px', width: '90%', borderRadius: '20px', border: '1px solid #E7E8E9'
                      }}
                    >
                      <img src={filterSearch} alt="filter" height="100" width="100" />
                    </Button>
                  </Grid>
                </SearchGrid>
              </PaperWrapper>
              <TableGrid item container>
                {stockReportsLoading ? (
                  <ProductsTableLoader />
                ) : (
                  <>
                    {stockReports?.map((_row) => (
                      <MobileContainer data-testid="routeBtn" onClick={() => handleStockReportClicked(_row.id)} key={_row.id} style={{ marginTop: '2rem', borderRadius: '30px' }}>
                        <DateText container style={{ padding: '2rem 2rem 1rem 2rem' }}>
                          <HeaderText xs={6}>Last Updated</HeaderText>
                          <DetailText xs={6} style={{ fontSize: '1.8rem', fontWeight: '700' }}>
                            {moment(_row.lastUpdated).format('DD/MM/YY (h:mm)')}
                          </DetailText>
                        </DateText>
                        <hr />
                        <ContentBox>
                          <TextBox container>
                            <HeaderText xs={6}>Business</HeaderText>
                            <DetailText xs={6}>
                              {
                              _row.business.name.length > maxLength
                                ? `${_row.business.name.substring(0, maxLength)}...`
                                : _row.business.name
                            }
                            </DetailText>
                          </TextBox>
                          <TextBox container>
                            <HeaderText xs={6}>Created by</HeaderText>
                            <DetailText xs={6}>
                              {
                            _row.createdBy.user.username.length > maxLength
                              ? `${_row.createdBy.user.username.substring(0, maxLength)}...`
                              : _row.createdBy.user.username
                          }
                            </DetailText>
                          </TextBox>
                          <TextBox container>
                            <HeaderText xs={6}>Status</HeaderText>
                            <DetailText xs={6}>
                              {getStatus(_row.status)}
                            </DetailText>
                          </TextBox>
                        </ContentBox>
                      </MobileContainer>
                    ))}

                    <FooterWrapper item container>
                      {totalCount > 0 && (
                      <TablePagination
                        total={totalCount}
                        pageCount={currentPageCount}
                        setPageCount={setPageCount}
                        pageNumber={page}
                        setPageNumber={setPageNumber}
                      />
                      )}
                    </FooterWrapper>
                  </>
                )}
              </TableGrid>
            </>
          )}

        <ReportExportModel
          open={openExportModel}
          handleClose={() => setOpenExportModel(false)}
          endpoint={endpoint}
          params={params}
        />
        <StatusDropdown
          dotsButtonEl={dotsButtonEl}
          setDotsButtonEl={setDotsButtonEl}
          options={options}
          action={handleStatusChange}
        />
      </Grid>
    </MainContent>
  );
};

StockReport.propTypes = {
  stockReports: PropTypes.instanceOf(Array).isRequired,
  stockReportsLoading: PropTypes.bool,
  handleStockReportClicked: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPageCount: PropTypes.number.isRequired,
  setPageNumber: PropTypes.number.isRequired,
  currentPageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  endpoint: PropTypes.string.isRequired,
  params: PropTypes.instanceOf(Object).isRequired,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func.isRequired,
  setFilterStatus: PropTypes.func.isRequired
};

StockReport.defaultProps = {
  stockReportsLoading: true,
  searchText: ''
};

export default StockReport;
