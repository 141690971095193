import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { Grid, TableBody, Button } from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import { CHAIN_MANAGER_DASHBOARD, STORE_SALE_ANALYTICS_GRAPH } from '../../queries/cmDashboard';
import { EXPORT_MUTATION } from '../../mutations/reports';
import { GET_BUSINESS_SIBLINGS, GET_BUSINESS_AND_SIBLINGS } from '../../queries/affiliates';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import SwitchPopper from './switchPopper';
import CustomSearchField from '../shared/CustomSearchField';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';
import TablePagination from '../shared/tablePagination';
import Charts from './charts';
import {
  TitleGrid, TitleTextGridContainer, PrimaryTitle,
  PaperWrapper, TableGrid, TableHeader, TableComponent, TableColumnHeaders,
  FooterWrapper, FilterGrid, SwitchGrid
} from './chainManagerDashboard.styles';
import { useStateValue } from '../../providers/stateProvider';

const headers = [
  { name: 'S/N', width: '70px' }, { name: 'Store Names', width: '280px' }, { name: 'Total Sale Amount', width: '200px' },
  { name: 'Number of sales', width: '150px' }, { name: 'Gross Margin', width: '200px' }, { name: 'Cash Sales', width: '200px' },
  { name: 'Bank Transfer Sales', width: '200px' }, { name: 'Credit Sales', width: '200px' }, { name: 'Card Sales', width: '200px' }
];

const defaultFilter = {
  range: 'month',
  store: 'All Stores',
  storeIds: []
};

const ChainManagerDashboard = () => {
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [switchGridEl, setSwitchGridEl] = useState(null);
  const [filter, setFilter] = useState(defaultFilter);
  const [dates, setDates] = useState({
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day')
  });
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }, dispatch] = Object.values(useStateValue());

  const handleSubmit = (dateRange) => {
    setDates(dateRange);
  };

  const { data: businessAndSiblingsData } = useQuery(GET_BUSINESS_AND_SIBLINGS, {
    fetchPolicy: 'cache-and-network',
  });
  const { businessAndSiblings } = businessAndSiblingsData ?? {};

  // const returnId = (storeName) => {
  //   const name = storeName.split(' ')[0];
  //   const business = businessAndSiblings.filter((biz) => biz.name?.includes(name))[0];
  //   return business?.id;
  // };

  const handleFilter = (name, value, id) => {
    let val = value;
    let ids = [];
    if (name === 'range') {
      switch (value) {
        case 'Monthly': val = 'month';
          break;
        default:
          val = 'week';
      }
    } else if (value === 'All Stores') ids = [];
    else ids = [id];

    return setFilter((f) => ({
      ...f,
      [name]: val,
      storeIds: ids
    }));
  };

  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const sd = moment(dates?.dateFrom).format('YYYY-M-DD');
  const ed = moment(dates?.dateTo).format('YYYY-M-DD');
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    const newFileName = `Chain Manager Dashboard ${randomNum}`;
    exportMutation({
      variables: {
        type: 'chain_manager_dashboard',
        name: newFileName,
        dateFrom: sd,
        dateTo: ed
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ width }}>{name}</TableHeader>
  ));

  const { range, storeIds } = filter;

  const response = useQuery(STORE_SALE_ANALYTICS_GRAPH, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filterBy: range,
      businessIds: storeIds
    }
  });

  const { data: siblingsData, refetch: siblingRefetch } = useQuery(GET_BUSINESS_SIBLINGS, {
    fetchPolicy: 'cache-and-network',
  });
  const { businessSiblings } = siblingsData ?? {};

  const variables = {
    pageCount,
    pageNumber,
    dateFrom: dates?.dateFrom,
    dateTo: dates?.dateTo,
    ...(search.length >= 3 ? { search } : { search: '' }),
  };

  const {
    loading, error, data
  } = useQuery(CHAIN_MANAGER_DASHBOARD, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    chainManagerDashboard = [], chainManagerDashboardTotalNumber = 0
  } = data || {};

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={4}>
            <PrimaryTitle variant="h5">Chain Manager&apos;s Dashboard</PrimaryTitle>
          </TitleTextGridContainer>
          <SwitchGrid
            item
            container
            xs={3}
            onClick={(e) => setSwitchGridEl(e.currentTarget)}
            style={{ pointerEvents: !userPermissions?.includes('cm_dashboard_switch_stores') ? 'none' : '' }}
          >
            Switch Stores
            <KeyboardArrowDownRounded style={{ fontSize: '1.5rem', color: '#606060' }} />
          </SwitchGrid>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <FilterGrid item container spacing={1}>
            <Grid item xs={8}>
              <CustomSearchField
                name="search"
                value={search}
                fullWidth
                placeholder="Search"
                handleChange={(e) => setSearch(e?.target?.value)}
                disabled={!userPermissions?.includes('cm_dashboard_search')}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                pointerEvents: !userPermissions?.includes('cm_dashboard_date_filter') ? 'none' : ''
              }}
            >
              <CustomDateRanges
                handleSubmit={handleSubmit}
                xs={12}
                styles={{
                  height: '47px', width: '100%', border: '1px solid #bababa', fontSize: '11.8px'
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                style={{ width: '100%', height: '3rem' }}
                onClick={handleDownloadCSV}
              >
                Download CSV
              </Button>
            </Grid>
          </FilterGrid>
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <TableColumnHeaders item container>
                    {returnHeaders()}
                  </TableColumnHeaders>
                  <TableBody>
                    {chainManagerDashboard.map((_row, indx) => (
                      <ReturnRow
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
          </TableGrid>
          <FooterWrapper item container>
            {chainManagerDashboardTotalNumber > 0 && (
              <TablePagination
                total={chainManagerDashboardTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>

        <Charts
          response={response}
          handleFilter={handleFilter}
          filter={filter}
          businessAndSiblings={businessAndSiblings}
        />

        <SwitchPopper
          switchGridEl={switchGridEl}
          setSwitchGridEl={setSwitchGridEl}
          businessSiblings={businessSiblings}
          siblingRefetch={siblingRefetch}
        />
      </Grid>
    </MainContent>
  );
};

export default ChainManagerDashboard;
