import React from 'react';
import {
  Button, DialogActions, DialogContent, DialogContentText,
  Slide, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import success from '../../../assets/images/checkmark-animation.gif';
import {
  ButtonsGrid, TitleTextGridContainer, PrimaryTitle, SecondaryTitle, CDialog
} from './confirmDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  openConfirm,
  handleCloseConfirm,
  title,
  discreption,
  disc
}) => (
  <CDialog
    open={openConfirm}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCloseConfirm}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <Close
        fontSize="small"
        style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
        onClick={handleCloseConfirm}
      />
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box style={{ paddingTop: '40px', paddingBottom: '20px' }} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
            <img src={success} alt="success" width={155} />
          </Box>
          <TitleTextGridContainer item>
            <PrimaryTitle>
              {title}
            </PrimaryTitle>
            <SecondaryTitle>
              {discreption}
            </SecondaryTitle>
            <SecondaryTitle>
              {disc}
            </SecondaryTitle>
          </TitleTextGridContainer>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        <ButtonsGrid item container>
          <Button
            variant="contained"
            fullWidth
            style={{ width: '100%' }}
            onClick={handleCloseConfirm}
          >
            Ok
          </Button>
        </ButtonsGrid>
      </DialogActions>
    </DialogContent>
  </CDialog>
);

ConfirmDialog.propTypes = {
  openConfirm: PropTypes.func.isRequired,
  handleCloseConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  discreption: PropTypes.string.isRequired,
  disc: PropTypes.string.isRequired,
};

export default ConfirmDialog;
