import {
  Dialog, Grid, Typography, TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const CustomerDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
  }
`;

export const DialogWrapper = styled(Grid)`
  width: 45rem;
  padding: 0 1.5rem;
`;

export const DialogHeader = styled(Grid)`
  width: 42rem;
  padding: 1.5rem 0 .5rem;
  background: #fff;
  position: fixed;
  z-index: 1;
`;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const DialogBody = styled(Grid)`
  height: max-content;
  margin-top: 8.5rem;
  overflow-y: auto;
`;

export const DialogActions = styled(Grid)`
  justify-content: flex-end;
  padding: 1rem 0 1.5rem;
  background: #fff;
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

export const HeaderGrid = styled(Grid)`
  height: max-content;
`;

export const TableHeader = styled(TableCell)`
  font-size: .9rem;
  font-weight: 600;
  line-height: 3.2;
  padding: 5px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2), :nth-child(3), :nth-child(4) {
      width: 23%;
    }
    &:nth-child(5) {
      width: 21%;
    }
  }
`;

export const MainTableRow = styled(Grid)`
  height: max-content;
  align-items: center;
  cursor: pointer;

  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 12px;
  color: #606060;
  padding: 12px 5px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 10%;
      font-size: 14px;
    }
    &:nth-child(2), :nth-child(3), :nth-child(4) {
      width: 23%;
    }
    &:nth-child(5) {
      width: 21%;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const CenterTextGrid = styled(Grid)`
  padding: 1rem 0;
`;
