import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Hidden, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  ProductDetail, ProductDetailImage, ProductDetailBrandNameText,
  ProductDetailPackSizeText, ShowMd, ProductDetailSavingsText, ProductCounter,
  ProductDetailItemPriceText, ProductCounterRemoveButton, ProductCounterAddButton,
  ProductDetailNameMainContainer, ProductCounterQuantityInput, Delete, GreySpan, IsHybrid, AddRemoveIcon,
  ProductDetailBrandTypo, ProductCounterWrapper, MinQtyTypo, BodyCell, ImageWrapper, MobileText, MobileButttonGrid, RemoveText
} from './styles';
import { PromoInfo } from '../../orders/newOrder/promoInfo';
import { useStateValue } from '../../../providers/stateProvider';
import { toTitleCase } from '../../../utils/toTitleCase';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { MainTableRow } from '../cart.styles';
import DeleteIcon from '../../../assets/images/DeleteIcon.png';
// import { DownvoteIcon, UpvoteIcon } from '../../../assets/svgs';
import DownvoteIcon from '../../../assets/images/marketOrder/Downvote.png';
import UpvoteIcon from '../../../assets/images/marketOrder/Upvote.png';

const ProductItem = ({
  product,
  platform,
  incrementProductQuantity,
  decrementProductQuantity,
  openDeleteDialog,
  changeInputQuantity,
  switchOrderProductSupplier
}) => {
  const {
    id, name: brandName, meta, quantity, hybrid, productBusinessId, productVariant,
    alternativeSupplierName, manualCart, supplier, minimumOrderQuantity,
  } = product;
  const isSmall = useMediaQuery('(max-width: 991px)');
  const isMarketPlace = platform === 'marketplace';
  const {
    image, market_rpp: marketRpp, pack_size: packSize, price_updated
  } = meta;
  const [promo, setPromo] = useState(0);
  const [{ affiliate: { pricePolicyFactor } }] = Object.values(useStateValue());

  const productObject = new Product(product, platform);
  const unitPrice = platform === 'erp' ? productObject.orderCost : productObject.mktPrice;
  const productPrice = Number(unitPrice * pricePolicyFactor);

  // const productPrice = Number(mktPrice);
  const switchSupplier = (useAlternativeSupplier) => {
    switchOrderProductSupplier({
      quantity,
      productId: id,
      useAlternativeSupplier,
      isManualOrder: manualCart
    });
  };
  const renderAlternativeGuide = () => {
    if (!manualCart || (supplier === alternativeSupplierName)) {
      return null;
    }
    if (alternativeSupplierName.trim() === 'OGApharmacy') {
      return (
        <Grid>
          <GreySpan onClick={() => switchSupplier(false)}><u>Order from OGApharmacy</u></GreySpan>
        </Grid>
      );
    }
    return (
      <Grid>
        <i>
          {alternativeSupplierName}
          {' '}
          <GreySpan onClick={() => switchSupplier(true)}><u>(Use this supplier)</u></GreySpan>
        </i>
      </Grid>
    );
  };
  const isHybrid = () => {
    if (manualCart && hybrid && hybrid === 'YES') return (<IsHybrid>Hybrid</IsHybrid>);
    return null;
  };
  const savings = (Number(marketRpp) || Number(productPrice)) - Number(productPrice);
  const priceColorChange = price_updated ? '#F50057' : '#000';
  return (
    <>
      <Hidden mdDown>
        <MainTableRow>
          <ProductDetail container>
            {/* {(productVariant === 'onRequest')
            ? (
              <ProductVariantText>On Request</ProductVariantText>
            )
            : (
              <ProductVariantText>Readily Available</ProductVariantText>
            )} */}
            <ImageWrapper><ProductDetailImage src={image} alt="" /></ImageWrapper>
            <ProductDetailNameMainContainer>
              {isHybrid()}
              <ProductDetailBrandNameText style={{ color: `${priceColorChange}` }}>
                { toTitleCase(brandName) }
              </ProductDetailBrandNameText>
              <ProductDetailPackSizeText>
                Pack Size:&nbsp;
                { packSize }
                {promo < 1 ? '' : <PromoInfo info="First Order Promotion" />}
                {/* {renderAlternativeGuide()} */}
              </ProductDetailPackSizeText>
              {(productVariant === 'onRequest')
                ? (
                  <MinQtyTypo variant="body1">
                    Minimum Quantity:
                    {' '}
                  &nbsp;
                    {minimumOrderQuantity}
                  </MinQtyTypo>
                ) : '' }
            </ProductDetailNameMainContainer>
          </ProductDetail>
          <BodyCell>
            <ProductCounter>
              <ProductCounterRemoveButton
                onClick={() => decrementProductQuantity(id)}
                disabled={productVariant === 'onRequest' ? (quantity === minimumOrderQuantity) : ''}
                className="mpAdmin-uat-cartPage-addRemoveIcon"
              >
                <AddRemoveIcon src={DownvoteIcon} alt="Remove" />
              </ProductCounterRemoveButton>

              <ProductCounterQuantityInput
                value={Number(quantity)}
                onChange={(event) => changeInputQuantity(id, event.target.value)}
                className="mpAdmin-uat-cartPage-quantityInput"
              />

              <ProductCounterAddButton
                onClick={() => incrementProductQuantity(id)}
                className="mpAdmin-uat-cartPage-addRemoveIcon"
              >
                <AddRemoveIcon src={UpvoteIcon} alt="Add" />
              </ProductCounterAddButton>
            </ProductCounter>
            {/* {(outOfStock === true && role === 'oga-pharmacy-admin') ? <OutOfStock>Out of stock</OutOfStock> : ' ' } */}
          </BodyCell>
          <BodyCell>
            <ProductDetailItemPriceText>
              ₦
              { currencyFormatter(productPrice) }
            </ProductDetailItemPriceText>

            {isMarketPlace && `${productBusinessId}` === '1' && !!savings && (
            <ProductDetailSavingsText>
              Savings: ₦&nbsp;
              { currencyFormatter(savings) }
            </ProductDetailSavingsText>
            )}
          </BodyCell>
          <BodyCell style={{ color: `${priceColorChange}` }}>
            ₦
            { currencyFormatter(Number(productPrice) * Number(quantity)) }
          </BodyCell>

          <BodyCell>
            <Button
              onClick={() => openDeleteDialog(id)}
              className="mpAdmin-uat-cartPage-deleteCart"
            >
              <Delete alt="Delete" src={DeleteIcon} />
            </Button>
          </BodyCell>
        </MainTableRow>
      </Hidden>
      <ShowMd container item>
        <Grid item container xs={4}>
          <ImageWrapper><ProductDetailImage src={image} alt="" /></ImageWrapper>
        </Grid>
        <Grid item container xs={8} direction="column">
          <Grid item container style={{ marginBottom: isSmall && '1rem' }}>
            <ProductDetailBrandTypo style={{ color: `${priceColorChange}` }}>
              { toTitleCase(brandName) }
            </ProductDetailBrandTypo>
          </Grid>
          <Grid item container alignItems="center" style={{ marginBottom: isSmall && '1rem' }}>
            <Grid item container xs={12}>
              <ProductDetailPackSizeText>
                Pack Size:&nbsp;
                {packSize}
              </ProductDetailPackSizeText>
            </Grid>
          </Grid>
          {/* {(productVariant === 'onRequest')
          ? (
              <MinQtyTypo variant="body1">
                Minimum Quantity:
                {' '}
                &nbsp;
                {minimumOrderQuantity}
              </MinQtyTypo>
          ) : '' } */}
          <Grid item container alignItems="center" style={{ width: '70%', marginBottom: isSmall && '1rem' }} justifyContent="space-between">
            <Grid item xs={6} md={4}>
              <MobileText>Unit Cost</MobileText>
              <ProductDetailItemPriceText style={{ color: `${priceColorChange}` }}>
                {`₦${currencyFormatter(productPrice)}`}
              </ProductDetailItemPriceText>
            </Grid>
            <Grid item xs={4} md={4}>
              <MobileText>Price</MobileText>
              <ProductDetailItemPriceText style={{ color: '#000' }}>
                {`₦${currencyFormatter(Number(productPrice) * Number(quantity))}`}
              </ProductDetailItemPriceText>
            </Grid>
          </Grid>
        </Grid>
        {/* {isMarketPlace && `${productBusinessId}` === '1' && !!savings && (
        <ProductDetailSavingsText>
          Savings: ₦&nbsp;
          { currencyFormatter(savings) }
        </ProductDetailSavingsText>
      )} */}
        <MobileButttonGrid container>
          <ProductCounterWrapper>
            <ProductCounter>
              <ProductCounterRemoveButton
                onClick={() => decrementProductQuantity(id)}
                disabled={productVariant === 'onRequest' ? (quantity === minimumOrderQuantity) : ''}
              >
                <AddRemoveIcon src={DownvoteIcon} alt="Remove" />
              </ProductCounterRemoveButton>
              <ProductCounterQuantityInput
                value={Number(quantity)}
                onChange={(event) => changeInputQuantity(id, event.target.value)}
              />
              <ProductCounterAddButton
                onClick={() => incrementProductQuantity(id)}
              >
                <AddRemoveIcon src={UpvoteIcon} alt="Add" />
              </ProductCounterAddButton>
            </ProductCounter>
            {/* {(outOfStock === true && role === 'oga-pharmacy-admin') ? <OutOfStock>Out of stock</OutOfStock> : ' ' } */}
          </ProductCounterWrapper>
          <div>
            <Button
              onClick={() => openDeleteDialog(id)}
              className="mpAdmin-uat-cartPage-deleteCart"
            >
              <Delete alt="Delete" src={DeleteIcon} />
              <RemoveText>Remove</RemoveText>
            </Button>
          </div>
        </MobileButttonGrid>
      </ShowMd>
    </>
  );
};

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  platform: PropTypes.string,
  incrementProductQuantity: PropTypes.func.isRequired,
  decrementProductQuantity: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  switchOrderProductSupplier: PropTypes.func.isRequired,
  changeInputQuantity: PropTypes.func.isRequired
};

ProductItem.defaultProps = {
  platform: 'marketplace'
};

export default ProductItem;
