import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, ButtonsGrid,
} from './titleGrid.styles';

export function TitleGrid({ handleUpdatePreference, setSuccessProps }) {
  const handleSave = () => {
    setSuccessProps({
      title: 'Preferences Updated!',
      description: 'Your preferences have been successfully updated!'
    });
    handleUpdatePreference();
  };

  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          Preferences
        </PrimaryTitle>
        <SecondaryTitle>
          Set and manage your preferences
        </SecondaryTitle>
      </TitleTextGridContainer>
      <ButtonsGrid item>
        <Button
          variant="contained"
          onClick={handleSave}
        >
          Update Preferences
        </Button>
      </ButtonsGrid>

    </TitleGridContainer>
  );
}

TitleGrid.propTypes = {
  setSuccessProps: PropTypes.func.isRequired,
  handleUpdatePreference: PropTypes.func.isRequired
};

export default TitleGrid;
