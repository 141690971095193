import React from 'react';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import {
  Grid, Select, MenuItem, Button
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_BUSINESS_SIBLINGS } from '../../queries/affiliates';
// import CustomButton from '../customComponents/customButton';
import { ExclamationIcon } from '../../assets/svgs';
import {
  CDialog, DialogWrapper, SubText, HeaderText, DialogHeader, DialogContent, DialogActions,
  CloseIcon, CFormControl, CustomCheckbox, SideGrid, MainGrid, NoteHeaderGrid, NoteGrid
} from './transferLocationDialog.styles';
import StockTransferTypes from '../../providers/reducers/stockTransfer/stockTransferTypes';

import { useStateValue } from '../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const TransferLocationDialog = ({
  openDialog, closeDialog, handleTransferStock
}) => {
  const [{
    stockTransfer: { transferLocation, inheritConfig },
  }, dispatch] = Object.values(useStateValue());

  const { data } = useQuery(GET_BUSINESS_SIBLINGS, {
    fetchPolicy: 'cache-and-network',
  });
  const { businessSiblings } = data ?? {};

  const handleConcent = () => {
    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER,
      payload: { inheritConfig: !inheritConfig }
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const location = businessSiblings.find((biz) => biz.id === value);
    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER,
      payload: { transferLocation: location }
    });
  };

  return (
    <CDialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <Grid item container xs={6}>
            <HeaderText>Transfer Location</HeaderText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={closeDialog} />
          </Grid>
          <SubText variant="caption">
            You are about to transfer products, you are required to enter the location of transfer
          </SubText>
        </DialogHeader>
        <DialogContent item container>
          <SubText variant="caption" style={{ fontWeight: 500 }}>Location</SubText>
          <CFormControl
            fullWidth
            variant="filled"
            size="small"
          >
            <Select
              // variant="standard"
              id="select-transfer-business"
              value=""
              displayEmpty
              renderValue={(val) => (
                transferLocation
                  ? transferLocation?.name
                  : <span style={{ color: '#bdbdbd' }}>Select Address</span>
              )}
              onChange={handleChange}
              name="transferAddress"
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
                getContentAnchorEl: null
              }}
            >
              {businessSiblings?.map((business) => (
                <MenuItem key={business.id} value={business.id}>
                  {business.name}
                </MenuItem>
              ))}
            </Select>
          </CFormControl>
          <Grid item container style={{ marginTop: '1rem' }}>
            <SideGrid item container>
              <CustomCheckbox
                size="small"
                checked={inheritConfig}
                onChange={handleConcent}
                inputProps={{ 'aria-label': 'select product' }}
                sx={{ color: '#78AADA' }}
              />
            </SideGrid>
            <MainGrid item container>
              By ticking this box, you consent that the transferred products will have the exact same product properties as the sender’s store,
              (e.g. price and supplier information) when they are received by the recipient.
            </MainGrid>
          </Grid>
          <NoteGrid item container>
            <SideGrid item container style={{ width: '6%' }}>
              <ExclamationIcon />
            </SideGrid>
            <MainGrid item container style={{ width: '94%' }}>
              <NoteHeaderGrid item container>Note</NoteHeaderGrid>
              If you do not tick the box, the receiving store will only retain the product properties of their own store if it already exists in the receiving store.
              (The only properties that will remain the same as the sender’s store are the product name and pack size).
            </MainGrid>
          </NoteGrid>
        </DialogContent>
        <DialogActions item container>
          <Button
            variant="outlined"
            onClick={closeDialog}
            style={{ marginRight: '1.2rem' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!transferLocation}
            onClick={handleTransferStock}
          >
            Transfer
          </Button>
        </DialogActions>
      </DialogWrapper>
    </CDialog>
  );
};

TransferLocationDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleTransferStock: PropTypes.func.isRequired,
};

export default TransferLocationDialog;
