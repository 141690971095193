import {
  Dialog, Typography, DialogActions, Checkbox, InputBase,
  TableCell, Box, TableContainer, TableBody, TableRow, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';

export const CustomDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px;
    min-width: 53rem;
    max-height: 35rem;
  }
`;

export const Header = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const DialogAction = styled(DialogActions)`
  margin-bottom: 1rem;
  margin-left: auto;
  width: 55%;
`;

export const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked{
    color: #F1EB42;
  }
  &.MuiIconButton-colorSecondary{
    color: #303030;
  }
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.6rem;
  }
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 55%;
  border-radius: 6px;
  font-size: 16px;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0;
  }
  & .MuiInputAdornment-positionStart {
    margin-right: 0;
    margin-left: 16px;
  }
`;

export const HeaderCell = styled(TableCell)`
  background-color: #F4F5F7;
  font-size: 1rem;
  font-weight: 700;
  line-height: 164.2%;
  padding: 10px;

  @media(max-width: 991px) {
    line-height: 2.5rem;
    font-size: 1.4rem;
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 25%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 10%;
    }
  }
`;

export const TableBox = styled(Box)`
  width: 100%;
`;

export const TableContent = styled(TableContainer)`
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 2rem;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const MainTableRow = styled(TableRow)`
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 1rem;
    padding: 10px;
    line-height: 164.2%;
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 19%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 25%;
    }
    &:nth-child(6) {
      width: 5%;
    }
  }
`;

const sellableStyle = css`
  background-color: #DCFEDD;
  color: #07DE10;
`;

const getStyles = ({ status }) => {
  if (status === 'sellable') {
    return sellableStyle;
  }
  return sellableStyle;
};

export const ColorGrid = styled(Grid)`
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  padding: 12px 0;
  width: 6.063rem;
  height: 2.875rem;
  background-color: #DCFEDD;
  color: #07DE10;

  // ${getStyles}
`;
