import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Grid, TableBody, TableContainer, Table, Button
} from '@mui/material';
import toast from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import TablePagination from '../shared/tablePagination';
import CustomSearchField from '../shared/CustomSearchField';
import ToggleBusiness from '../shared/busnesss';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';
import NotificationDialog from '../shared/notificationDialog';
import { ALL_PROMOTIONS_QUERY } from '../../queries/promotions';
import { DELETE_PROMOTION_MUTATION } from '../../mutations/promotions';
import {
  CartText, TitleGrid, TitleTextGridContainer, PrimaryTitle, PaperWrapper, TableGrid,
  TableHeader, MainTableHead, FooterWrapper, SearchGrid, SecondaryTitle
} from './promotions.styles';
import { useStateValue } from '../../providers/stateProvider';

const headers = [
  'Promo Name', 'Promo Value', 'Date Created', 'Start Date', 'End Date', 'Promo Status', 'Action',
];

const Promotions = () => {
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [dates, setDates] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [promoToDelete, setPromoToDelete] = useState({});

  const [{
    navbar: { openSideDrawer },
    user: { allowedPermissionsMap: { userPermissions } },
  }] = Object.values(useStateValue());

  const navigate = useNavigate();

  const handleSubmit = (dateRange) => {
    setDates(dateRange);
  };

  const {
    loading, error, data, refetch
  } = useQuery(ALL_PROMOTIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...(searchText.length > 2 && { searchName: searchText }),
      pageCount,
      pageNumber,
      ...(dates && { dateFrom: dates?.dateFrom }),
      ...(dates && { dateTo: dates?.dateTo }),
    }
  });
  const promotions = data?.allPromotions || [];
  const allPromotionsTotalNumber = data?.allPromotionsTotalNumber || 0;

  const handleDeleteAction = (promo) => {
    setOpenDeleteDialog(true);
    setPromoToDelete(promo);
  };

  const [deletePromotion, { loading: deleteLoading }] = useMutation(DELETE_PROMOTION_MUTATION);
  const handleDeletePromo = () => {
    deletePromotion({
      variables: {
        promotionId: promoToDelete?.id,
      }
    }).then(() => {
      refetch();
      setOpenDeleteDialog(false);
      setOpenSuccessDialog(true);
    }).catch((e) => {
      toast.error(e?.message);
    });
  };

  if (error) return error.message;

  const returnHeaders = () => headers.map((header) => <TableHeader>{header}</TableHeader>);

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={8}>
            <PrimaryTitle variant="h5">Promotions</PrimaryTitle>
            <SecondaryTitle>
              Create all promotions for suppliers, manufacturers, pricing category and individual products
            </SecondaryTitle>
          </TitleTextGridContainer>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            xs={12}
            md={4}
          >
            <Button
              variant="contained"
              style={{ width: '9.5rem', height: '3rem' }}
              onClick={() => navigate('/promotions/create')}
              disabled={!userPermissions?.includes('promotions_create_promotions')}
            >
              Create Promo
            </Button>
          </Grid>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchGrid item container spacing={openSideDrawer ? 2 : 1}>
            <Grid item container xs={openSideDrawer ? 9.5 : 10}>
              <CustomSearchField
                name="search"
                value={searchText}
                placeholder="Search for Promotion"
                handleChange={(e) => setSearchText(e?.target?.value)}
                style={{ width: '100%' }}
              />
            </Grid>
            <CustomDateRanges
              handleSubmit={handleSubmit}
              xs={openSideDrawer ? 2.5 : 2}
              styles={{ height: '3rem', width: '100%', border: '1px solid #bababa' }}
            />
          </SearchGrid>
          <TableGrid item container>
            {loading ? (
              <ProductsTableLoader />
            ) : (
              <TableContainer>
                <Table>
                  <MainTableHead>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody>
                    {promotions.map((promo) => (
                      <ReturnRow
                        key={promo?.id}
                        row={promo}
                        handleDeleteAction={() => handleDeleteAction(promo)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TableGrid>
          <ToggleBusiness />
          <FooterWrapper item container>
            {allPromotionsTotalNumber > 0 && (
              <TablePagination
                total={allPromotionsTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>

        <NotificationDialog
          openDialog={openDeleteDialog}
          setOpenDialog={setOpenDeleteDialog}
          action="Cancel"
          action2="Yes, Delete Promo"
          action2Func={handleDeletePromo}
          title="Delete Promo?"
          desc="Are you sure you want to delete promo"
          type="warning"
          loading={deleteLoading}
        />
        <NotificationDialog
          openDialog={openSuccessDialog}
          setOpenDialog={setOpenSuccessDialog}
          action="Ok"
          title="Promo Deleted!"
          desc={`${promoToDelete?.name} has been deleted successfully`}
        />
      </Grid>
    </MainContent>
  );
};

export default Promotions;
