import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  FiltersGrid, FilterContainer, ButtonOutlined
} from './inboundsFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';

const InboundsFilter = ({
  search, setSearch, setState
}) => {
  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD')
    }));
  };

  return (
    <FilterContainer item container xs={12}>
      <FiltersGrid container item justifyContent="flex-end">
        <Grid item container style={{ width: '73.5%' }}>
          <CustomSearchField
            name="search"
            value={search}
            handleChange={(e) => setSearch(e.target.value)}
            fullWidth
            placeholder="Search"
          />
        </Grid>
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            xs={12}
            styles={{ height: '47px', width: '15rem', border: '1px solid #bababa' }}
          />
        </Grid>
        <ButtonOutlined>
          Export CSV
        </ButtonOutlined>
      </FiltersGrid>
    </FilterContainer>
  );
};

InboundsFilter.propTypes = {
  setState: PropTypes.isRequired,
  setSearch: PropTypes.isRequired,
  search: PropTypes.string.isRequired,
};

export default InboundsFilter;
