import React, { useState } from 'react';
import {
  Button, DialogContent, DialogContentText, InputAdornment,
  Slide, Box, Grid, Paper, TableRow, Table, TableHead
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  CustomDialog, Header, DialogAction, CustomCheckbox,
  CustomInputBase, TableBox, TableContent, MainTableBody,
  HeaderCell, MainTableRow, BodyCell, ColorGrid
} from './batchDialog.styles';
import {
  CaretUpIcon, CaretDownIcon
} from '../../../../assets/svgs';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const BatchDialog = ({
  open, close, disableField, handleChange, rowId, orderBatch,
  handleBatchUpdate
}) => {
  const [selected, setSelected] = useState([]);
  const [qtyReturned, setQtyReturned] = useState({});
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleSelect = (__, id) => {
    const newSelected = [...selected];
    const idx = newSelected.indexOf(id);
    if (newSelected.includes(id)) newSelected.splice(idx, 1);
    else newSelected.push(id);
    setSelected(newSelected);
  };

  const handleIncrementBatchReceived = (index, qty_to_sell, id) => {
    setQtyReturned((current) => {
      const copy = { ...current };
      copy[index] = (!+copy[index] && qty_to_sell !== 0) ? 1 : (+copy[index] < qty_to_sell) ? +copy[index] + 1 : +copy[index];
      handleBatchUpdate(id, copy[index], rowId);
      return copy;
    });
  };

  const handleContinue = () => {
    const sumValues = Object.values(qtyReturned).reduce((a, b) => +a + +b, 0);
    handleChange(rowId, sumValues);
    close();
  };

  const handleChangeBatchReceivedValue = (value, index, qty_to_sell, id) => {
    if (value > 0) {
      handleBatchUpdate(id, value, rowId);
    }
    setQtyReturned((current) => {
      const copy = { ...current };
      copy[index] = value <= qty_to_sell ? value : qty_to_sell;
      return copy;
    });
  };

  const handleDecrementBachReceived = (index, id) => {
    setQtyReturned((current) => {
      const copy = { ...current };
      copy[index] = (+copy[index] !== 0) && +copy[index] - 1;
      handleBatchUpdate(id, copy[index], rowId);
      return copy;
    });
  };
  const renderCheckbox = (id) => (
    <CustomCheckbox
      size="small"
      checked={isSelected(id)}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, id);
      }}
      inputProps={{ 'aria-label': 'select batch' }}
    />
  );

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = orderBatch.map((order) => order.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const renderSelectAllCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === orderBatch?.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select batch' }}
    />
  );

  const returnQtyReturned = (value, index) => {
    const { batch_id: id, qty_to_sell } = value;
    return (
      <CustomInputBase
        type="number"
        placeholder="0"
        value={qtyReturned[index]}
        disabled={disableField}
        min={1}
        size="medium"
        onChange={(event) => handleChangeBatchReceivedValue(event.target.value, index, qty_to_sell, id)}
        endAdornment={(
          <InputAdornment position="end">
            <Grid item container direction="column">
              <CaretUpIcon
                style={{
                  width: '1.1rem', height: '1.1rem', cursor: 'pointer',
                  pointerEvents: disableField ? 'none' : ''
                }}
                onClick={() => handleIncrementBatchReceived(index, qty_to_sell, id)}
              />
              <CaretDownIcon
                style={{
                  width: '1.1rem', height: '1.1rem', cursor: 'pointer',
                  pointerEvents: disableField ? 'none' : ''
                }}
                onClick={() => handleDecrementBachReceived(index, id)}
              />
            </Grid>
          </InputAdornment>
        )}
      />
    );
  };

  const headers = [
    renderSelectAllCheckbox(), 'Batch No.', 'Expiry Date', 'Qty Delivered', 'Qty Returned', 'Status'
  ];

  const handleClose = () => {
    setQtyReturned({});
    close();
  };

  return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Grid container style={{ marginTop: '2rem' }}>
          <Grid item md={8}>
            <Header>Batches</Header>
          </Grid>
          <Grid item md={4} style={{ textAlign: 'right' }}>
            <Close fontSize="medium" onClick={handleClose} />
          </Grid>
        </Grid>

        <DialogContentText style={{ marginTop: '1.5rem', marginBottom: '0' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <TableBox>
              <TableContent component={Paper}>
                <Table aria-label="batch table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <HeaderCell key={header}>{header}</HeaderCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <MainTableBody item container>
                    {orderBatch?.map((value, index) => (
                      <MainTableRow key={value}>
                        <BodyCell>{renderCheckbox(value.batch_id)}</BodyCell>
                        <BodyCell>{value.batch_id}</BodyCell>
                        <BodyCell>{moment(value.expiry_date).format('DD/MM/YYYY')}</BodyCell>
                        <BodyCell>{value.qty_to_sell}</BodyCell>
                        <BodyCell>{returnQtyReturned(value, index)}</BodyCell>
                        <BodyCell>
                          <ColorGrid item container status="sellable">{value.status}</ColorGrid>
                        </BodyCell>
                      </MainTableRow>
                    ))}
                  </MainTableBody>
                </Table>
              </TableContent>
            </TableBox>
          </Box>
        </DialogContentText>
        <DialogAction>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                style={{
                  padding: '1rem', fontWeight: '600', borderRadius: '5px',
                  border: '1px solid #000000'
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={handleContinue}
                style={{
                  background: '#303030', color: '#FFF', padding: '1rem', fontWeight: '600'
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </DialogAction>
      </DialogContent>
    </CustomDialog>
  );
};

BatchDialog.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disableField: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  rowId: PropTypes.number.isRequired,
  orderBatch: PropTypes.instanceOf(Array).isRequired,
  handleBatchUpdate: PropTypes.func.isRequired
};

BatchDialog.defaultProps = {
  disableField: false
};

export default BatchDialog;
