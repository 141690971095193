import {
  Grid, TableContainer, Table, TableHead, TableCell
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
    background: #F4F7FC;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const ErpHeader = styled(TableCell)`
  :first-child, :last-child {
    width: 5%;
  }
  text-transform: uppercase;
  padding: 10px;
  width: 23%;

`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

export const MobileWrapper = styled(Grid)`
  margin: 2rem 1rem 1rem 1rem;
  width: 100%;
`;
