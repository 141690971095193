import {
  TableRow, TableCell, Checkbox, Grid, Button, Typography, Dialog, DialogContent, TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px 2px;

  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const ColorGrid = styled(Grid)`
  background: ${({ background }) => (background)};
  /* background: ${({ active }) => (active ? '#008400' : '#BF0505')}; */
  border-radius: 5px;
  text-transform: capitalize;
  color: ${({ color }) => (color)};
  width: 65%;
  font-size: .7rem;
  font-weight: 500;
  justify-content: center;
  padding: 12px 0;
`;

export const ActionButton = styled(Button)`
  width: 150px;
  height: 45px;
  background: none;
  border-radius: 5px;
  color: #606060;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #C0C0C1;

  &:hover {
    background-color: none;
    color: #606060;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: none;
    border: 1px solid #C0C0C1;
  }

  @media(max-width: 991px) {
    margin: 12px 22px;
    width: 93%;
    height: 125px;
  }
`;

export const TextWrapper = styled(Typography)`
  font-size: 1rem;
  @media(max-width: 991px) {
    font-size: 2.1rem;
  }

`;

export const NoteDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 15px;
    padding-bottom: 20px;
    width: 375px;

    @media (max-width: 991px) {
      width: 90%;
      height: 600px;
    }
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-height: 15rem;
`;

export const NoteTitle = styled('p')`
  margin: 0;
  color: #424242;
  font-weight: 600;
  font-size: 2.5rem;
  margin: 10px 0px;
  padding-bottom: 10px;
  @media (min-width: 992px) {
    font-size: 18px
  }
`;

export const NoteContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const NoteTextField = styled(TextField)`
  background-color: #F5F5F7;
  height: 65px;

  label + .MuiInput-formControl {
    margin-top: 21px;
  }

  label {
    left: 20px;
    top: 5px;
    color: #A3A3A3;
    font-size: 1rem;
  }

  input, textarea {
    padding-left: 20px;
    background-color: #F5F5F7;
    color: #424242;
    font-size: 18px;
    padding-top: 10px;
  }
  & .MuiInputBase-multiline {
      padding: 0;
    }
  & .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 991px) {
    height: 200px;

    label + .MuiInput-formControl {
      margin-top: 23px;
    }

    label {
      font-size: 2rem;
    }

    input, textarea {
      font-size: 2rem;
    }
  }
`;

export const SaveNoteButton = styled(Button)`
  background-color: #333333;
  border-radius: 5px;
  color: #fff;
  padding: 15px 10px;
  margin-top: 20px;
  @media (max-width: 991px) {
    font-size: 2rem;
    margin-top: 5rem;
  }
`;
