import { styled } from '@mui/material/styles';
import {
  TableCell, Typography, Grid, Paper
} from '@mui/material';
import CustomButton from '../customComponents/customButton';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;

export const FilterGrid = styled(Grid)`
  padding: 2rem 1.5rem .5rem;
  align-items: center;
`;

export const StyledCustomButton = styled(CustomButton)`
  width: 100%;
  height: 47px;
  border-radius: 5px;

  & .label {
    font-weight: 700;
    font-size: 9.16854px;
  }
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`

`;

export const TableColumnHeaders = styled('div')`
  display: flex;
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #F3F9FF;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const SwitchGrid = styled(Grid)`
  padding: 7px 10px;
  color: #24292E;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  justify-content: space-between;
  cursor: pointer;

  width: 15rem;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #E7E8E9;
  border-radius: 8px;
  align-items: center;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const FooterWrapper = styled(Grid)`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
`;
