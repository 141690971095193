import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Badge from '@mui/material/Badge';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#EE4223',
    color: '#fff',
    top: '3px',
    right: '6px',
    fontSize: '1.75rem',
    height: '32px',
    borderRadius: '18px',
    [theme.breakpoints.up('md')]: {
      top: '3px',
      right: '6px',
      fontSize: '.75rem',
      height: '20px',
      borderRadius: '10px',
    }
  }
}));

const SimpleBadge = ({
  children, value
}) => {
  const classes = useStyles();
  return (
    <Badge
      overlap="rectangular"
      badgeContent={value}
      classes={{ badge: classes.root }}
    >
      { children }
    </Badge>
  );
};

SimpleBadge.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.number.isRequired,
};

export default SimpleBadge;
