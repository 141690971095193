import { createTheme, styled, adaptV4Theme } from '@mui/material/styles';
import {
  Typography, Grid
} from '@mui/material';

export const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%',
        paddingTop: '20px'
      },
      root: {
        backgroundColor: '#fff',
        border: '1px dashed #F3F9FF',
        borderColor: '#78AADA'
      }
    }
  }
}));

export const TitleText = styled(Typography)`
  font-size: 10.31px;
  font-family: Montserrat,medium,sans-serif;
  color: #000;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

export const SubTitleText = styled(Typography)`
  font-size: 9.52px;
  font-family: Montserrat,medium,sans-serif;
  color: #00000066;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

export const BrowseFileButton = styled(Grid)`
  width: 120px;
  height: 35px;
  line-height: 35px;
  color: #235A91;
  border: 1px solid #235A91;
  border-radius: 3.96671px;
  font-weight: 600;
  font-size: 12px;
  margin: 0 auto;
`;

export const DropZoneContainer = styled(Grid)`
  padding: 0 4rem;
  align-items: center;
`;
