import { styled } from '@mui/material/styles';
import { MenuItem, Paper } from '@mui/material';

export const PaperWrapper = styled(Paper)`
  width: 9rem;
  padding: 1rem 0;
  box-shadow: -1px 2px 8px 1px #00000017;
  transition: transform 0.3s;
  z-index: 500;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 1rem;

  &:hover {
    background: #f3f9ff;
  }
`;
