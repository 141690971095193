import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { isERP } from '../../../../utils/utils';
import {
  FilterBox, FilterContainer, FilterHandle, FilterCreditTypo, FilterNameTypo,
  FilterGrid, FilterTypo, HeaderGrid, FilterHeader, SearchGrid, CustomCheckbox,
  FilterSubHeader, ListItems, ItemContentGrid, ContainerHeader, ProductFilterHeader
} from './newOrderFilter.styles';
import { useStateValue } from '../../../../providers/stateProvider';

const initialState = {
  suppliersDrop: true,
  therapeuticsDrop: true,
  productsDrop: true,
  statusDrop: true,
  priceDrop: true,
};
const NewOrderFilter = (props) => {
  const {
    suppliers, filteredSupplier, therapeuticTypes,
    filteredPromo, handleFilteredPromo, handleTherapyOption, priceRange,
    handleFilteredSupplier, promos, renderSearchField, handlePriceFilter,
    containerState, handleProductsOption, selectedFilterProviders, handleToggleProvider
  } = props;

  const [state, setState] = useState(initialState);
  const [searchText, setSearchText] = useState('');
  const [sortedSuppliers, setSortedSuppliers] = useState([]);
  const [allPromoType, setAllPromoType] = useState([]);

  const [{ affiliate: { usablePromos } }] = Object.values(useStateValue());

  const sortSuppliers = (_suppliers) => {
    const sorted = _suppliers.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setSortedSuppliers(sorted);
  };

  useEffect(() => {
    if (suppliers) {
      sortSuppliers(suppliers);
      // get al the different promo types we have
      setAllPromoType([...new Set(
        suppliers.map((supplier) => {
          let supplierPromo;
          if (supplier.providerPromo) {
            supplierPromo = supplier.providerPromo;
          }
          return supplierPromo;
        })
      )]);
    }
  }, [suppliers]);

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      const results = sortedSuppliers.filter(
        ({ name }) => name.toLowerCase().includes(searchText.toLowerCase())
      );
      setSortedSuppliers(results);
    } else if (!searchText) {
      sortSuppliers(suppliers);
    }
  }, [searchText]);

  const nameTrim = (name) => (name.length > 24
    ? `${name.substring(0, 24 - 3)}...`
    : name);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const renderSupplier = (supplier) => (
    <FilterHandle container justifyContent="space-between" onClick={() => handleFilteredSupplier(supplier.name)}>
      <Grid item xs={10}>
        <FilterNameTypo>{nameTrim(supplier.name.replace(/['"]+/g, ''))}</FilterNameTypo>
        <FilterCreditTypo variant="caption">{supplier.creditDays}</FilterCreditTypo>
      </Grid>
      <Grid item xs={2}>
        <CustomCheckbox
          sx={{ color: '#78AADA' }}
          checked={!!filteredSupplier.get(supplier.name)}
        />
      </Grid>
    </FilterHandle>
  );

  const {
    productType, therapyIds, priceMin, priceMax
  } = containerState;

  const renderProductOptions = () => {
    const options = [
      { key: 'only_business_products', value: 'My Business Products' },
      { key: 'oga_pharmacy_market_product', value: 'OGApharmacy Marketplace Products' },
      { key: 'other', value: 'Other Products' },
    ];
    return options.map((item) => (
      <Grid key={item.key} xs={12}>
        <FilterHandle container justifyContent="space-between" onClick={() => handleProductsOption(item)}>
          <Grid item container xs={10} style={{ paddingLeft: '20px', alignContent: 'center' }}>
            <FilterNameTypo>{item.value}</FilterNameTypo>
          </Grid>
          <Grid item xs={2}>
            <CustomCheckbox
              sx={{ color: '#78AADA' }}
              checked={item.key === productType}
            />
          </Grid>
        </FilterHandle>
      </Grid>
    ));
  };

  const renderTherapeutics = () => therapeuticTypes.map((item) => {
    const saved = therapyIds.filter((_item) => _item.id === item.id)[0];
    return (
      <Grid key={item.id} xs={12}>
        <FilterHandle container justifyContent="space-between" onClick={() => handleTherapyOption(item)}>
          <Grid item container xs={10} style={{ paddingLeft: '20px', alignContent: 'center' }}>
            <FilterNameTypo>{item.therapeuticType}</FilterNameTypo>
          </Grid>
          <Grid item xs={2}>
            <CustomCheckbox
              sx={{ color: '#78AADA' }}
              checked={item.id === saved?.id}
            />
          </Grid>
        </FilterHandle>
      </Grid>
    );
  });

  const renderPriceFilter = () => priceRange.map((price) => (
    <Grid key={price} xs={12}>
      <FilterHandle container justifyContent="space-between" onClick={() => handlePriceFilter(price)}>
        <Grid item container xs={10} style={{ paddingLeft: '20px', alignContent: 'center' }}>
          <FilterNameTypo>{price.displayPrice}</FilterNameTypo>
        </Grid>
        <Grid item xs={2}>
          <CustomCheckbox
            sx={{ color: '#78AADA' }}
            checked={price.priceMin === priceMin && price.priceMax === priceMax}
          />
        </Grid>
      </FilterHandle>
    </Grid>
  ));

  const renderUsablePromos = (promo) => (
    <FilterHandle container justifyContent="space-between" onClick={() => handleFilteredPromo(promo)}>
      <Grid item xs={10}>
        <FilterNameTypo>{promos.get(promo)}</FilterNameTypo>
      </Grid>
      <Grid item xs={2}>
        <CustomCheckbox
          sx={{ color: '#78AADA' }}
          checked={!!filteredPromo.get(promo)}
        />
      </Grid>
    </FilterHandle>
  );

  const handleDrop = (type) => setState((_state) => ({
    ..._state,
    [type]: !_state[type]
  }));

  const renderProviderOptions = () => {
    const providers = ['PharmIQ', 'GPS', 'GMV_Boost', 'Disabled'];
    return providers.map((provider) => (
      <Grid key={provider.key} xs={12}>
        <FilterHandle container justifyContent="space-between" onClick={() => handleToggleProvider(provider)}>
          <Grid item container xs={10} style={{ paddingLeft: '20px', alignContent: 'center' }}>
            <FilterNameTypo>{provider}</FilterNameTypo>
          </Grid>
          <Grid item xs={2}>
            <CustomCheckbox
              sx={{ color: '#78AADA' }}
              checked={!!selectedFilterProviders.get(provider)}
            />
          </Grid>
        </FilterHandle>
      </Grid>
    ));
  };

  const {
    suppliersDrop, therapeuticsDrop, productsDrop, statusDrop, priceDrop
  } = state;
  return (
    <FilterContainer container>
      {handleToggleProvider
        ? <ProductFilterHeader>Filter By:</ProductFilterHeader>
        : <ContainerHeader>Filter by:</ContainerHeader>}
      {!isERP() && (
        <ListItems container item style={{ borderTop: '1px solid #CCCCCC' }}>
          <HeaderGrid container item onClick={() => handleDrop('suppliersDrop')}>
            <FilterGrid item>
              <FilterTypo>Suppliers</FilterTypo>
            </FilterGrid>
            <Grid item style={{ paddingRight: '20px' }}>
              {suppliersDrop
                ? <KeyboardArrowUp style={{ color: '#235A91' }} />
                : <KeyboardArrowDown style={{ color: '#235A91' }} />}
            </Grid>
          </HeaderGrid>
          <ItemContentGrid container item drop={suppliersDrop}>
            <SearchGrid item container>
              <Grid item container className="joy-filter-text">
                {renderSearchField('Search ...', searchText, handleSearch, handleClearSearch, { small: true })}
              </Grid>
            </SearchGrid>
            <FilterBox sm={12}>
              {allPromoType.map((onePromoType) => (
                <>
                  <FilterSubHeader>{onePromoType}</FilterSubHeader>
                  <Grid className="joy-filter-supplier">
                    { sortedSuppliers
                      .filter((sortedSupplier) => sortedSupplier.providerPromo === onePromoType)
                      .map(renderSupplier) }
                  </Grid>
                </>
              ))}
            </FilterBox>
            <Grid sm={12}>
              {usablePromos.length ? <FilterHeader text="Offers" /> : ''}
              {usablePromos.length
                ? (
                  <FilterBox xs={12}>
                    { usablePromos.map(renderUsablePromos) }
                  </FilterBox>
                ) : ''}
            </Grid>
          </ItemContentGrid>
        </ListItems>
      )}
      {handleToggleProvider && (
        <ListItems container item>
          <HeaderGrid container item onClick={() => handleDrop('statusDrop')}>
            <FilterGrid item>
              <FilterTypo>Status</FilterTypo>
            </FilterGrid>
            <Grid item style={{ paddingRight: '20px' }}>
              {statusDrop
                ? <KeyboardArrowUp style={{ color: '#235A91' }} />
                : <KeyboardArrowDown style={{ color: '#235A91' }} />}
            </Grid>
          </HeaderGrid>
          <ItemContentGrid container item drop={statusDrop}>
            {renderProviderOptions()}
          </ItemContentGrid>
        </ListItems>
      )}
      {isERP() && (
        <ListItems container item>
          <HeaderGrid container item onClick={() => handleDrop('productsDrop')}>
            <FilterGrid item>
              <FilterTypo>Products</FilterTypo>
            </FilterGrid>
            <Grid item style={{ paddingRight: '20px' }}>
              {productsDrop
                ? <KeyboardArrowUp style={{ color: '#235A91' }} />
                : <KeyboardArrowDown style={{ color: '#235A91' }} />}
            </Grid>
          </HeaderGrid>
          <ItemContentGrid container item drop={productsDrop}>
            {renderProductOptions()}
          </ItemContentGrid>
        </ListItems>
      )}
      <ListItems container item>
        <HeaderGrid container item onClick={() => handleDrop('therapeuticsDrop')}>
          <FilterGrid item>
            <FilterTypo>Products</FilterTypo>
          </FilterGrid>
          <Grid item style={{ paddingRight: '20px' }}>
            {therapeuticsDrop
              ? <KeyboardArrowUp style={{ color: '#235A91' }} />
              : <KeyboardArrowDown style={{ color: '#235A91' }} />}
          </Grid>
        </HeaderGrid>
        <ItemContentGrid container item drop={therapeuticsDrop}>
          {renderTherapeutics()}
        </ItemContentGrid>
      </ListItems>

      <ListItems container item>
        <HeaderGrid container item onClick={() => handleDrop('priceDrop')}>
          <FilterGrid item>
            <FilterTypo>Price</FilterTypo>
          </FilterGrid>
          <Grid item style={{ paddingRight: '20px' }}>
            {priceDrop
              ? <KeyboardArrowUp style={{ color: '#235A91' }} />
              : <KeyboardArrowDown style={{ color: '#235A91' }} />}
          </Grid>
        </HeaderGrid>
        <ItemContentGrid container item drop={priceDrop}>
          {renderPriceFilter()}
        </ItemContentGrid>
      </ListItems>
    </FilterContainer>
  );
};

NewOrderFilter.propTypes = {
  containerState: PropTypes.instanceOf(Object),
  suppliers: PropTypes.instanceOf(Array),
  therapeuticTypes: PropTypes.instanceOf(Array),
  promos: PropTypes.instanceOf(Object),
  filteredSupplier: PropTypes.instanceOf(Object),
  handleFilteredPromo: PropTypes.func.isRequired,
  renderSearchField: PropTypes.func.isRequired,
  handleProductsOption: PropTypes.func.isRequired,
  handleTherapyOption: PropTypes.func.isRequired,
  filteredPromo: PropTypes.instanceOf(Object),
  handleFilteredSupplier: PropTypes.func.isRequired,
  priceRange: PropTypes.instanceOf(Object),
  selectedFilterProviders: PropTypes.func.isRequired,
  handleToggleProvider: PropTypes.func.isRequired,
  handlePriceFilter: PropTypes.func.isRequired
};

NewOrderFilter.defaultProps = {
  containerState: [],
  suppliers: [],
  therapeuticTypes: [],
  promos: new Map(),
  filteredSupplier: new Map(),
  filteredPromo: new Map(),
  priceRange: []
};

export default NewOrderFilter;
