import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  DialogActions, DialogContent, Button, Slide, Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { KeyboardArrowDown } from '@mui/icons-material';
import { JSONParse } from '../../../../utils/json';
import CustomInputBase from '../../../customComponents/customInputBase';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import {
  CDialog, DialogTitleText, DialogTitleSubText, CircularProgressLoader,
  GridWrapper, CloseIcon, AdornmentStyled
} from './addBusinessDialog.styles';
import LogoutDialogue from '../../../categories/logoutDialog';
import NotificationDialog from '../../../shared/notificationDialog';
import {
  CREATE_BUSINESS_MUTATION, UPDATE_BUSINESS_MUTATION
} from '../../../../mutations/business';
import { LOGOUT_MUTATION } from '../../../../mutations/auth';
import { validateEmail } from '../../../auth/utils';
import AffiliateActionTypes from '../../../../providers/reducers/affiliate/affiliateTypes';
import { useStateValue } from '../../../../providers/stateProvider';
import { STORAGE_KEYS } from '../../../../utils/constants';
import { removeCountryCode } from '../../../shared/methods';
import { blockInvalidChar } from '../../../../utils/mobileCheck';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const initialErrorState = {
  nameError: false,
  emailError: false,
};

const AddBusinessDialog = ({
  dialogOpen, closeDialog, fresh
}) => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [logoutDialogue, setLogoutDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const navigate = useNavigate();

  const [updateBusiness] = useMutation(UPDATE_BUSINESS_MUTATION);
  const [createBusiness] = useMutation(CREATE_BUSINESS_MUTATION);
  const [logoutUser] = useMutation(LOGOUT_MUTATION);

  const [
    { affiliate: { loggedInAffiliate, loggedInAffiliateRefetch } }, dispatch
  ] = Object.values(useStateValue());

  useEffect(() => {
    const { business: biz } = loggedInAffiliate || {};
    const {
      name: initialLegalName, contacts
    } = biz || {};
    const parsedContacts = JSONParse(contacts);

    setState({
      legalName: initialLegalName,
      mobile: parsedContacts.mobile_number,
      primaryContactName: parsedContacts.primary_contact_name,
      email: parsedContacts.primary_email_address,
      secondaryEmailAddress: parsedContacts.secondary_email_address,
      addressLine1: parsedContacts.address_line_1,
      addressLine2: parsedContacts.address_line_2,
      city: parsedContacts.city,
      country: parsedContacts.country,
      phoneNumber1: removeCountryCode(parsedContacts.phone_number_1),
      phoneNumber2: removeCountryCode(parsedContacts.phone_number_2)
    });
  }, [loggedInAffiliate]);

  const handleLogout = () => {
    const authToken = localStorage.getItem('oga_auth_token');
    const restToken = localStorage.getItem('oga_rest_token');
    logoutUser({
      variables: {
        restToken,
        authToken
      }
    }).then((resp) => {
      dispatch({
        type: AffiliateActionTypes.UPDATE_AFFILIATE,
        payload: { isBusinessVirtualStore: false }
      });
      const { status } = resp.data.logoutUser;
      const storageKeys = [...STORAGE_KEYS];
      if (status) {
        storageKeys.forEach((storageKey) => {
          localStorage.removeItem(storageKey);
        });
        setTimeout(() => navigate('/'), 2000);
        toast.success('Logout successful!');
        // setTimeout(() => { document.location = ''; }, 2000);
      }
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const { nameError } = errorState;

  const handleCloseDialog = () => {
    closeDialog();
  };
  const addFreshAffiliate = () => {
    const {
      legalName, mobile, email, secondaryEmailAddress,
      addressLine1, addressLine2, primaryContactName, city, country,
    } = state;
    createBusiness({
      variables: {
        legalName,
        contactName: primaryContactName,
        mobile,
        email,
        secondaryEmailAddress,
        addressLine1,
        addressLine2,
        city,
        country,
        terms: false
      }
    })
      .then(({ data }) => {
        setOpenSuccessDialog(true);
        handleCloseDialog();
        handleLogout();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const addAffiliate = () => {
    const {
      legalName, email, secondaryEmailAddress,
      addressLine1, addressLine2, primaryContactName, city, country,
      phoneNumber1, phoneNumber2
    } = state;
    const business = localStorage.getItem('ogarx_business_id');

    updateBusiness({
      variables: {
        id: business,
        businessName: legalName,
        // businessType: userType,
        primaryContactName,
        // mobile,
        primaryEmailAddress: email,
        secondaryEmailAddress,
        addressLine1,
        addressLine2,
        city,
        country,
        terms: false,
        phoneNumber1: phoneNumber1 ? `+234${state.phoneNumber1}` : '',
        phoneNumber2: phoneNumber2 ? `+234${state.phoneNumber2}` : ''
      }
    })
      .then(({ data }) => {
        setOpenSuccessDialog(true);
        loggedInAffiliateRefetch();
        handleCloseDialog();
        handleLogout();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    const { legalName } = state;
    if (legalName.length < 1) {
      return setErrorState({
        ...errorState,
        nameError: true
      });
    }
    setLoading(true);
    return fresh ? addFreshAffiliate() : addAffiliate();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const phoneAdornment = (
    <AdornmentStyled>
      <span>+234</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const topFields = [
    {
      name: 'legalName', label: 'Legal Name', helperText: 'Legal Name required', placeholder: 'Enter Legal Name'
    },
    { name: 'primaryContactName', label: 'Primary Contact Name', placeholder: 'Enter Primary Contact' },
    { name: 'email', label: 'Primary Email Address', placeholder: 'Enter Primary Email Address' },
    { name: 'secondaryEmailAddress', label: 'Secondary Email Address', placeholder: 'Enter Secondary Email Address' },
    { name: 'addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line 1' },
    { name: 'addressLine2', label: 'Address Line 2', placeholder: 'Enter Address Line 2' },
    {
      name: 'phoneNumber1', label: 'Phone Number 1', required: true, placeholder: 'Enter your phone number',
      helperText: 'Phone number is required', secured: false, show: true, sAdornment: phoneAdornment
    },
    {
      name: 'phoneNumber2', label: 'Phone Number 2', required: true, placeholder: 'Enter your phone number',
      helperText: 'Phone number is required', secured: false, show: true, sAdornment: phoneAdornment
    },
    { name: 'city', label: 'City', placeholder: 'Enter City' },
    {
      name: 'country',
      label: 'Country',
      placeholder: 'Enter Country',
      options: ['Nigeria', 'Uganda', 'Kenya']
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, placeholder, helperText, sAdornment
    } = field;
    const value = state[fieldName];
    if (fieldName === 'country') {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          placeholder={placeholder}
          handleChange={handleChange}
        />
      );
    }

    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={(fieldName === 'phoneNumber1' || fieldName === 'phoneNumber2') ? 'number' : 'text'}
        onKeyDown={(fieldName === 'phoneNumber1' || fieldName === 'phoneNumber2') && blockInvalidChar}
        placeholder={placeholder}
        error={fieldName === 'legalName' ? nameError : false}
        helperText={nameError && helperText}
        name={fieldName}
        onChange={handleChange}
        cSize="lg"
        sAdornment={sAdornment}
      />
    );
  };

  return (
    <>
      <CDialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <GridWrapper container item>
          <Grid container>
            <Grid item container direction="column" xs={9}>
              <DialogTitleText>
                { fresh ? 'Add Business Profile' : 'Edit Business Profile' }
              </DialogTitleText>
              <DialogTitleSubText>
                { fresh ? 'Edit the form to add your business information' : 'Edit the form to update your business information' }
              </DialogTitleSubText>
            </Grid>
            <Grid item container xs={3} justifyContent="flex-end">
              <CloseIcon onClick={handleCloseDialog} />
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={3}>
              {topFields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => (fresh ? handleSave() : setLogoutDialog(true))}
              style={{ marginLeft: '15px' }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : 'Update'}
            </Button>
          </DialogActions>
        </GridWrapper>
      </CDialog>
      <LogoutDialogue
        handleOkay={handleSave}
        openDialogue={logoutDialogue}
        setOpenDialogue={setLogoutDialog}
      />

      <NotificationDialog
        openDialog={openSuccessDialog}
        setOpenDialog={setOpenSuccessDialog}
        title="Business Profile Updated!"
        desc="Your business profile has been successfully update!"
      />
    </>
  );
};

AddBusinessDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  fresh: PropTypes.bool
};
AddBusinessDialog.defaultProps = {
  fresh: false
};

export default AddBusinessDialog;
