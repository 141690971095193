import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useMutation, useApolloClient } from '@apollo/client';
import { Dots } from '../../assets/svgs';
import ActionPopper from './actionPopper';
import {
  BodyCell, MainTableRow,
} from './categoryCard.styles';
import { DELETE_PRICE_CATEGORY_MUTATION } from '../../mutations/categories';
import { FIND_CATEGORY_QUERY } from '../../queries/categories';
import IndividualCategoryDialog from './individualCategoryDialog';
import SuccessDialog from '../shared/successDialog';
import ConfirmDialog from '../shared/confirmDialog';

const ReturnRow = ({ row, index }) => {
  const {
    categoryName, vatStatus, loyaltyWeight, markup, noOfProducts
  } = row;
  const [action, setAction] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [successState, setSuccessState] = useState({ title: '', desc: '' });
  const [catId, setCatId] = useState('');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const client = useApolloClient();

  const [deleteCategory] = useMutation(DELETE_PRICE_CATEGORY_MUTATION);

  const deletePriceCategoryhandel = () => {
    deleteCategory({
      variables: {
        categoryId: catId
      },
      refetchQueries: [{ query: FIND_CATEGORY_QUERY }]
    })
      .then(() => {
        setSuccessDialog(true);
        setSuccessState({ title: 'Category Deleted Successfully!', desc: 'Category has been successfully deleted!' });
        client.refetchQueries({ include: [FIND_CATEGORY_QUERY] });
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    if (confirmStatus) {
      setConfirmDialog(false);
      deletePriceCategoryhandel();
    }
  }, [confirmStatus]);

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const handleAction = (event, type, categoryId) => {
    if (type === 'delete') {
      setCatId(String(categoryId?.id));
      setConfirmDialog(true);
    } else {
      setOpenDialog(true);
      handleOptions(event);
      setCatId(String(categoryId.id));
    }
  };

  return (
    <>
      <MainTableRow>
        <BodyCell data-testid="rowIndex">{index + 1}</BodyCell>
        <BodyCell>
          {categoryName}
        </BodyCell>
        <BodyCell>
          {noOfProducts}
        </BodyCell>
        <BodyCell>
          {markup}
        </BodyCell>
        <BodyCell>
          {loyaltyWeight}
        </BodyCell>
        <BodyCell>
          {vatStatus}
        </BodyCell>
        <BodyCell>
          <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} data-testid="dotsOption" />
          <ActionPopper
            action={action}
            handleAction={handleAction}
            row={row}
            handleClose={handleCloseModal}
          />
        </BodyCell>
      </MainTableRow>
      <IndividualCategoryDialog
        dialogOpen={openDialog}
        closeDialog={() => setOpenDialog(false)}
        edit
        catEditId={catId}
        catData={row}
      />
      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title={successState.title}
        desc={successState.desc}
        option="Ok"
      />
      <ConfirmDialog
        openDialog={confirmDialog}
        setOpenDialog={setConfirmDialog}
        title="Delete Category?"
        desc="Are you sure you want to delete this category?"
        setStatus={setConfirmStatus}
        options={['Cancel', 'Yes Delete']}
      />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
