import {
  Dialog, Grid, Typography, IconButton, CircularProgress, TextField,
  Button, DialogContent
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px);
    border-radius: 12px;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 45rem !important;
  padding: 4rem 1.5rem 3rem 1.5rem;
  position: relative;
`;

export const CloseStyled = styled('div')`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
`;

export const GridStyled = styled(Grid)`
  display: flex;
  gap: 1.5rem;
  padding: 1rem 0 0 0;
`;

export const ContainerStyled = styled('div')`
  padding: 1.5rem;
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 0.5rem 1.5rem;
    width: 100%;

    &:first-child {
      padding: 1.5rem;
    }
    &:last-child {
      padding: 2.5rem 1.5rem;
    }
  }
`;

export const ButtonDarkStyled = styled(Button)`
  width: 170px;
  height: 45px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 12px;
`;

export const DialogTitleStyled = styled('span')`
  font-weight: 700;
  font-size: 18px;
  color: #303030;
`;

export const HeaderStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonOutlineStyled = styled(Button)`
  width: 170px;
  height: 45px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #303030 !important;
`;

export const FooterStyled = styled('div')`
  display: flex;
  gap: 1.2rem;
  justify-content: flex-end;
  padding: 2.5rem 0 0 0;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 20px;
  margin-bottom: 4px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 12px;
  color: #858383;
`;

export const DividerLine = styled('hr')`
  border: 1px solid #cccccc;
  width: 96%;
  min-width: 300px;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  justify-content: flex-start;
  text-align: left;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  background: #e0e0e0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 30px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)``;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;
export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;
export const DownloadIconWrapper = styled(IconButton)``;
export const DownloadIconImage = styled('img')`
  height: 30px;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  background-color: #fced50;
  color: #424242;

  &:hover {
    background-color: #d2c647;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #424242;
  border-radius: 10px;
  border: 2px solid #424242;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const UploadGrid = styled(Grid)`
  /* border-radius: 10px; */
  align-items: center;
  /* padding: 5px; */
  margin-bottom: 1.5rem;

  & > * {
    border: 1px dashed #00000033;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    max-height: 32vh;
    min-height: 32vh;
  }
`;

export const SupplierTextField = styled(TextField)``;

export const TopGrid = styled(Grid)`
  & > * {
    width: 100%;
    margin: 0 0 1.5rem;
  }
`;

export const BottomGrid = styled(Grid)`
  & > * {
    width: calc(50% - 0.75rem);
    margin: 0 1.5rem 1.5rem 0;

    :nth-child(2n) {
      margin-right: 0;
    }
  }
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CurrencyTypo = styled(Typography)`
  font-size: 0.7rem;
  color: #909090;
`;

export const SearchSupplierResults = styled('div')`
  background: #ffffff;
  width: 21rem;
  position: absolute;
  right: 2rem;
  top: 43rem;
  z-index: 12;
  border: 1px solid #00000021;
`;

export const SupplierItem = styled('div')`
  padding: 5%;
  cursor: pointer;

  &:hover {
    background: #e8e8e8;
  }
`;

export const AddSupplierButton = styled('div')`
  background: #fced4f;
  padding: 4%;
  text-align: center;
  margin-top: 5%;
  font-size: 15px;
  font-weight: 400;
  border-radius: 10px;
  cursor: pointer;
`;

export const SearchImg = styled('img')`
  width: 25px;
`;
