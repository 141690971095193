import React from 'react';
import PropTypes from 'prop-types';
import CustomNewTextField from '../shared/CustomNewTextField';
import { MenuItemStyled } from './lowOnStockFilter.styles';

const LowOnStockFilter = ({ state, handleFilterChange }) => {
  const status = [
    { name: 'All', value: 'ALL' },
    { name: 'Low on stock', value: 'LOW' },
    { name: 'Out of stock', value: 'OUT' }
  ];

  const duration = [
    { name: 'All', value: 'all' },
    { name: 'Over 1 month', value: 'ONE_MONTH' },
    { name: '3 months', value: 'THREE_MONTH' },
    { name: '6 months', value: 'SIX_MONTH' },
    { name: '12 months', value: 'TWELVE_MONTH' }
  ];

  return (
    <>
      <CustomNewTextField
        field={{ name: 'tab', label: 'Filter by status' }}
        state={state}
        select
        handleChange={handleFilterChange}
        style={{ width: '15%' }}
      >
        {status.map(({ name, value }) => (
          <MenuItemStyled key={value} value={value}>
            {name}
          </MenuItemStyled>
        ))}
      </CustomNewTextField>

      <CustomNewTextField
        field={{ name: 'filterBy', label: 'Filter by fast-moving' }}
        state={state}
        select
        handleChange={handleFilterChange}
        style={{ width: '15%' }}
      >
        {duration.map(({ name, value }) => (
          <MenuItemStyled key={value} value={value}>
            {name}
          </MenuItemStyled>
        ))}
      </CustomNewTextField>
    </>
  );
};

LowOnStockFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired
};

LowOnStockFilter.defaultProps = {
  state: {}
};

export default LowOnStockFilter;
