import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Box,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import exclamation from '../../assets/images/exclamation.png';
import { CustomDialog } from '../suppliers/individual/individualSupplierDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  openDialogue,
  handleClose,
  handleConfirmation,
  loading,
}) => (
  <CustomDialog
    open={openDialogue}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => handleClose}
    aria-describedby="alert-dialog-slide-description"
    maxWidth="sm"
  >
    <DialogContent>
      <Close
        fontSize="large"
        style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
        onClick={handleClose}
      />
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
            <img src={exclamation} alt="exclamation" width={120} />
          </Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Confirm Choice?
          </Typography>
          <Typography variant="subtitle1" style={{ paddingTop: '1rem' }}>
            Please confirm your choice, note that you will be logged out after submitting this information.
          </Typography>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          style={{
            padding: '1rem 2rem',
            background: '#fff',
            color: '#303030',
            border: '1px solid #606060',
            borderRadius: '5px',
            boxShadow: 'none',
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            disabled
            variant="contained"
            size="large"
            style={{
              background: '#303030',
              color: '#FFF',
              padding: '1rem 2rem'
            }}
          >
            <Box display="flex" alignItems="center">
              <span>Loading...</span>
            </Box>
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={handleConfirmation}
            variant="contained"
            style={{
              background: '#303030',
              color: '#FFF',
              padding: '1rem 2rem'
            }}
            size="large"
          >
            Yes, Confirm
          </Button>
        )}
      </DialogActions>
    </DialogContent>
  </CustomDialog>
);

ConfirmDialog.propTypes = {
  openDialogue: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConfirmDialog;
