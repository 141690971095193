import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Grid, Button } from '@mui/material';
import { toast } from 'react-hot-toast';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import useSearchParamsState from '../../helpers/ulrSearchParams';
import TablePagination from '../../shared/tablePagination';
import CustomSearchField from '../../shared/CustomSearchField';
import CustomExportButton from '../../downloads/exportModule/exportComponent';
import { DeleteIcon, PrimarySortIcon } from '../../../assets/svgs';
import ConfirmDialog from '../../shared/confirmDialog';
import { DELETE_THERAPEUTIC_TYPE } from '../../../mutations/therapeuticType';
import {
  PaperExport,
  TotalRecord,
  FooterWrapper,
  FilterGrid,
  AccordionStyled,
  AccordionHeader,
  ImageContainer,
  HeaderBody,
  HeaderText,
  HeaderDesc,
  ContentContainer,
  SubCategoryContainer,
  SubCategoryHeader,
  DelContainer, FlexCenter, ExpandText,
  AccordionDetailStyled, Img
} from './therapeuticTypes.style';
import { THERAPEUTIC_TYPE_QUERY } from '../../../queries/therapeuticType';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';

const TherapeuticTypes = () => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [therapeuticTypeId, setTherapeuticTypeId] = useState('');

  const [sort, setSort] = useSearchParamsState('sort', 'random');
  const [search, setSearch] = useSearchParamsState('search', '');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');

  const { loading, data } = useQuery(THERAPEUTIC_TYPE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageCount,
      pageNumber,
      search: search?.length > 2 ? search : '',
      sort
    }
  });

  const [deleteType, { loading: deloading }] = useMutation(DELETE_THERAPEUTIC_TYPE);

  const delSubCategoryHandler = (id) => {
    setOpenConfirmDialog(true);
    setTherapeuticTypeId(id);
  };

  if (confirmStatus) {
    deleteType({
      variables: {
        therapeuticTypeId
      },
      refetchQueries: ['allTherapeuticTypes']
    }).then(({ data: d }) => {
      const { message } = d.deleteTherapeuticType;
      setOpenConfirmDialog(false);
      toast.success(message);
    })
      .catch((err) => toast.error(err?.message));
    setConfirmStatus(false);
  }

  const sortHandler = () => {
    switch (sort) {
      case 'random': return setSort('ascend');
      case 'ascend': return setSort('descend');
      case 'descend': return setSort('ascend');
      default:
        return setSort('random');
    }
  };

  const types = data?.allTherapeuticTypes || [];
  const totalNumber = data?.allTherapeuticTypesTotalNumber || 0;

  return (
    <>
      <PaperExport elevation={0}>
        <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Grid lg={10}>
            <TotalRecord>{`${totalNumber} Product Type(s)`}</TotalRecord>
          </Grid>
          <Grid style={{ width: '7rem' }}>
            <CustomExportButton
              title="Export"
              color="bordered"
              fileName="therapeutic_type"
              large="large"
              icon="withIcon"
              name="therapeutic_type"
            />
          </Grid>
        </Grid>
      </PaperExport>

      <FilterGrid item container spacing={2}>
        <Grid item container xs={11}>
          <CustomSearchField
            name="search"
            value={search}
            handleChange={(e) => setSearch(e.target.value)}
            fullWidth
            placeholder="Search"
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="outlined"
            onClick={sortHandler}
            style={{
              width: '100%',
              border: '1px solid #E7E8E9',
              color: '#606060',
              boxShadow: 'none',
              borderRadius: '8px',
              fontSize: '14px',
              fontWeight: '500'
            }}
          >
            <PrimarySortIcon style={{ marginRight: '2px' }} />
            Sort
          </Button>
        </Grid>
      </FilterGrid>

      <ContentContainer>
        {
          loading
            ? <ProductsTableLoader />
            : types?.map((type, index) => {
              const { therapeuticType, therapeuticTypeProductCount, subTherapeuticType } = type;
              return (
                <AccordionStyled
                  key={type}
                  elevation={0}
                  sx={{
                    '&:before': {
                      background: 'none',
                    }
                  }}
                >
                  <AccordionSummary
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                  >
                    <AccordionHeader>
                      <FlexCenter>
                        <ImageContainer><Img src="https://res.cloudinary.com/health-id/image/upload/v1594134267/Placeholders/Product_Placeholder.png" alt="placeholder" /></ImageContainer>
                        <HeaderBody>
                          <HeaderText>{therapeuticType}</HeaderText>
                          <HeaderDesc>
                            {therapeuticTypeProductCount}
                            {' '}
                            Product(s)
                          </HeaderDesc>
                        </HeaderBody>
                      </FlexCenter>
                      <ExpandText>
                        View Sub Category
                        <ExpandMoreIcon />
                      </ExpandText>
                    </AccordionHeader>
                  </AccordionSummary>
                  <AccordionDetailStyled>
                    {
                    subTherapeuticType?.map((subtheraType) => {
                      const { id, therapeuticType: subTheraType } = subtheraType;
                      return (
                        <SubCategoryContainer key={id}>
                          <SubCategoryHeader>{subTheraType}</SubCategoryHeader>
                          <DelContainer onClick={() => delSubCategoryHandler(id)}>
                            <DeleteIcon />
                            Remove
                          </DelContainer>
                        </SubCategoryContainer>
                      );
                    })
                  }
                  </AccordionDetailStyled>
                </AccordionStyled>
              );
            })
        }

      </ContentContainer>

      <FooterWrapper item container>
        {totalNumber > 0 && (
        <TablePagination
          total={totalNumber}
          pageCount={+pageCount}
          setPageCount={setPageCount}
          pageNumber={+pageNumber}
          setPageNumber={setPageNumber}
        />
        )}
      </FooterWrapper>
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Remove Sub Category"
        desc="Are you sure you want to remove this sub category?"
        options={['Cancel', 'Yes Remove']}
        setStatus={setConfirmStatus}
        loading={deloading}
      />
    </>
  );
};

export default TherapeuticTypes;
