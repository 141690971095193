import React from 'react';
import {
  Slide, Button
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  DialogContentStyled,
  ContentHeader,
  TextAreaStyled,
  HeaderText,
  FlexStyled,
  FooterStyled,
  DialogStyled,
  CloseStyled, NameStyled, TimeStyled
} from './addNoteDialog.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddNoteDialog = ({
  modalStatus, modalHandler
}) => (
  <DialogStyled
    open={modalStatus}
    TransitionComponent={Transition}
    keepMounted
    onClose={modalHandler}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContentStyled>
      <ContentHeader>
        <HeaderText>Add Note</HeaderText>
        <CloseStyled onClick={modalHandler} />
      </ContentHeader>
      <FlexStyled>
        <NameStyled>George Jumoke</NameStyled>
        <TimeStyled>5:27 PM</TimeStyled>
      </FlexStyled>
      <TextAreaStyled />
      <FooterStyled>
        <Button variant="contained">
          Save Note
        </Button>
      </FooterStyled>
    </DialogContentStyled>

  </DialogStyled>
);

AddNoteDialog.propTypes = {
  modalStatus: PropTypes.bool.isRequired,
  modalHandler: PropTypes.func,
};

AddNoteDialog.defaultProps = {
  modalHandler: () => {},
};

export default AddNoteDialog;
