import axios from 'axios';
import { OgaToken } from '../graphql/token';

const GQL_API_HOST = OgaToken.APP_LINK;
// convert localhost:8000/pharmiq => localhost:8000
const items = GQL_API_HOST?.split('/');
const API_HOST = items?.slice(0, items.length - 1)?.join('/');
// convert localhost:8000/pharmiq => localhost:8000

export function getAxios() {
  const token = localStorage.getItem('oga_rest_token') || OgaToken.restToken;
  return axios.create({
    baseURL: API_HOST,
    headers: {
      Authorization: `Token ${token}`,
    },
  });
}

export default (getAxios())();
