import { gql } from '@apollo/client';

export const TOP_SELLING_PRODUCTS = gql`
  query topSellingProducts($pageNumber: Int, $pageCount: Int, $dateFrom: String, $dateTo: String, $sort: String, $top: Boolean) {
    topSellingProducts(pageNumber: $pageNumber, pageCount: $pageCount, dateFrom: $dateFrom, dateTo: $dateTo, sort: $sort, top: $top) {
      brandName
      packSize
      frequency
      averageSellingPrice
      createdDate
      price
      qtyInStock
      qtySold
    }
    topSellingProductsTotalNumber
  }
`;

export const INVENTORY_DASHBOARD = gql`
  query inventoryDashboard{
    inventoryDashboard{
      totalProductsInInventory
      totalEnabledProducts
      totalDisabledProducts
      totalSoldOutProducts
    }
  }
`;

export const ALL_TOP_SELLING_PRODUCTS = gql`
  query allTopSellingProducts($pageNumber: Int, $pageCount: Int, $filterBy: String, $dateFrom: String, $dateTo: String) {
    allTopSellingProducts(pageNumber: $pageNumber, pageCount: $pageCount, filterBy: $filterBy, dateFrom: $dateFrom, dateTo: $dateTo) {
      brandName
      price
      packSize
      totalSales
      qtyInStock
      qtySold
    }
    allTopSellingProductsTotalNumber
  }
`;

export const SALES_ANALYTIC_GRAPH = gql`
  query salesAnalyticsGraph($filterBy: String){
    salesAnalyticsGraph(filterBy: $filterBy){
      totalSalesValueGraph
      totalSalesCostOfGoodsSoldGraph
    }
  }
`;
