import {
  Grid, IconButton, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainWrapper = styled(Grid)`
  @media(max-width: 991px) {
    background-color: white;
    background-image: linear-gradient(white, #F0F0F0);
  }
`;

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 5px 30px;
  background-color: #fff;
`;

export const TitleGridContainer = styled(Grid)`
  margin: .5rem 0 1rem;
  align-items: center;
`;

export const ButtonsPaper = styled(Paper)`
  padding: 1rem 3rem;
  border-radius: 1rem;

  @media(max-width: 991px) {
    padding: 2rem 5rem;
  }
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding-right: 30px;
  @media(max-width: 991px) {
    .MuiSvgIcon-fontSizeLarge {
      font-size: 5rem;
  }
  }
`;
