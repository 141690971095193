import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Button } from '@mui/material';
import moment from 'moment';
import CustomNewTextField from '../../shared/CustomNewTextField';
import { FiltersGrid } from './productsFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import { useStateValue } from '../../../providers/stateProvider';

const ProductFilter = ({
  state, setState, handleFilterChange, handleSetFilter
}) => {
  const { categories } = state;

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const handleSubmit = (dateRange) => {
    const { dateFrom: from, dateTo: to } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(from).format('YYYY-MM-DD'),
      dateTo: moment(to).format('YYYY-MM-DD')
    }));
  };

  const FilterFields = [
    { name: 'productName', label: 'Enter Product Name' },
    { name: 'productId', label: 'Enter SKU' },
    { name: 'supplier', label: 'Supplier' },
  ];

  const productStatuses = [
    { name: 'Enabled', value: 'enabled' },
    { name: 'Disabled', value: 'disabled' },
  ];

  return (
    <Grid item container xs={12}>
      <FiltersGrid container item justifyContent="flex-end" flexWrap="nowrap">
        <CustomNewTextField
          field={{ name: 'status', label: 'Product Status' }}
          state={state}
          select
          handleChange={handleFilterChange}
          style={{ width: '10rem' }}
        >
          {productStatuses.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </CustomNewTextField>
        {FilterFields.map((field) => (
          <CustomNewTextField
            key={field?.name}
            field={field}
            state={state}
            handleChange={handleFilterChange}
          />
        ))}
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            styles={{
              height: '47px', width: '15rem', border: '1px solid #bababa', marginRight: '8px'
            }}
            xs={12}
          />
        </Grid>
        <CustomNewTextField
          field={{ name: 'categoryId', label: 'Category' }}
          state={state}
          select
          handleChange={handleFilterChange}
          style={{ width: '10rem' }}
        >
          {categories.map(({ categoryName, id }) => (
            <MenuItem key={id} value={id}>
              {categoryName}
            </MenuItem>
          ))}
        </CustomNewTextField>
        <Button
          variant="contained"
          className="erp-uat-set-filter"
          onClick={handleSetFilter}
          disabled={!userPermissions?.includes('inventory_set_filter')}
          style={{ width: '7rem' }}
        >
          Set Filter
        </Button>
      </FiltersGrid>
    </Grid>
  );
};

ProductFilter.propTypes = {
  state: PropTypes.shape(Object),
  setState: PropTypes.shape(Object).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
};

ProductFilter.defaultProps = {
  state: {},
};

export default ProductFilter;
