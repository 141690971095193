import { Button, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 2rem 0.9rem 1rem;
  font-size: 15px;

  &:hover{
    background: #F3F9FF;
  }
`;

export const DivFlex = styled('div')`
  display: flex;
  gap: 1rem;
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 170px;
  margin-right: 1rem;
  height: 45px;
  border: 1.5px solid;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  font-weight: 700;
  /* color: #303030 !important; */
  font-size: 12px;
`;

export const ButtonDarkStyled = styled(Button)`
  width: 170px;
  height: 45px;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;

  disabled:hover {
    cursor:not-allowed
 }
`;
