export function rmL(str) {
  return str.replace(/_+/g, ' ').toLowerCase();
}

export function removeHyphen(str) {
  return str.replace(/-+/g, ' ').toLowerCase();
}

export function convertToCapitalization(str) {
  const result = str.replace(/_+/g, ' ').toLowerCase().concat(', ');
  const arr = result.split(',');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(',');
}

export function convertToCPnocomma(str) {
  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
}

export function dateFormat(inputDate) {
  return inputDate.split('-').reverse().join('-');
}

export function addHyphen(str) {
  return str.toLowerCase().replace(/\s+/g, '-');
}

export function removeCountryCode(phoneNumber) {
  if (phoneNumber?.includes('+234')) {
    return phoneNumber.slice(4);
  }
  return phoneNumber;
}
