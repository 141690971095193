import {
  List, ListItem, ListItemButton, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CList = styled(List)`
  width: 100%;
  padding-bottom: 15px;
`;

export const CListItem = styled(ListItem)`
  padding: 0;
`;

export const CListItemButton = styled(ListItemButton)`
  padding-left: 32px;
  padding-right: 32px;
`;

export const CListItemText = styled(ListItemText)`
  & .MuiListItemText-primary {
    font-size: 16px;
    font-weight: 400;
    color: #606060;
    text-transform: capitalize;
  }
`;
