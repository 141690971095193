import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Box,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import exclamation from '../../assets/images/exclamation.png';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  deleteAddressDialog,
  handleClose,
  handleDelete,
  selectedDeliveryLocationInfo,
  loading,
}) => (
  <Dialog
    open={deleteAddressDialog}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <Close
        fontSize="large"
        style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
        onClick={handleClose}
      />
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
            <img src={exclamation} alt="exclamation" width={120} />
          </Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Delete Address
          </Typography>
          <Typography variant="subtitle1" style={{ paddingTop: '1rem' }}>
            Hi, are you sure you want to Delete the address
          </Typography>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          style={{
            padding: '1rem 2rem'
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            disabled
            variant="outlined"
            size="large"
            style={{
              background: '#303030',
              color: '#FFF',
              padding: '1rem 2rem'
            }}
          >
            <Box display="flex" alignItems="center">
              <span>Loading...</span>
            </Box>
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={() => handleDelete(selectedDeliveryLocationInfo)}
            variant="contained"
            size="large"
          >
            Yes, Delete
          </Button>
        )}
      </DialogActions>
    </DialogContent>
  </Dialog>
);

ConfirmDialog.propTypes = {
  deleteAddressDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedDeliveryLocationInfo: PropTypes.func.isRequired,
};

export default ConfirmDialog;
