import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BodyCell,
  MainTableRow, ColorField, Status
} from './returnRow.styles';
import ActionPopper from './actionPopper';
import { JSONParse } from '../../utils/json';
import { toTitleCase } from '../../utils/toTitleCase';

const ReturnRow = ({
  row, sn, handleAction
}) => {
  const {
    name, dateCreated, subscriptionPlan, parentBusiness, createdBy: { profile }, contacts, billingAmount
  } = row;

  const renderStatus = (_status) => (
    <ColorField>
      <Status status={_status} />
      {_status}
    </ColorField>
  );

  const firstName = JSONParse(profile)?.first_name ?? '';
  const lastName = JSONParse(profile)?.last_name ?? '';
  const businessOwnerName = `${firstName} ${lastName}`;
  const businessAddress = JSONParse(contacts)?.address_line_1 ?? 'N/A';
  const emailAddress = JSONParse(contacts)?.primary_email_address ?? 'N/A';
  const mobileNumber = JSONParse(contacts)?.mobile_number ?? 'N/A';

  const getStatus = () => {
    switch (row.status) {
      case 'IN_ACTIVE':
        return renderStatus('De-activated');
      case 'UNVERIFIED':
        return renderStatus('Pending');
      default:
        return renderStatus('Approved');
    }
  };

  const renderDate = moment(dateCreated).format('DD MMMM YYYY h:mm a');

  return (
    <MainTableRow>
      <BodyCell>{sn}</BodyCell>
      <BodyCell>{renderDate}</BodyCell>
      <BodyCell style={{ whiteSpace: 'wrap', minWidth: '16rem' }}>{name}</BodyCell>
      <BodyCell>{businessOwnerName}</BodyCell>
      <BodyCell style={{ whiteSpace: 'wrap', minWidth: '23rem' }}>{businessAddress}</BodyCell>
      <BodyCell>{emailAddress}</BodyCell>
      <BodyCell>
        {toTitleCase(subscriptionPlan)}
        {' '}
        Plan
      </BodyCell>
      <BodyCell>{ billingAmount ? `NGN ${billingAmount}` : 'N/A'}</BodyCell>
      <BodyCell>{mobileNumber || 'N/A'}</BodyCell>
      <BodyCell>{parentBusiness}</BodyCell>
      <BodyCell>{getStatus()}</BodyCell>
      <BodyCell>
        <ActionPopper
          handleAction={handleAction}
          row={row}
        />
      </BodyCell>
      <BodyCell />
    </MainTableRow>
  );
};
ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  sn: PropTypes.number.isRequired,
  handleAction: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
