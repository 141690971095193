import React from 'react';
import {
  Button, DialogActions, DialogContent, DialogContentText,
  Slide, Box, Typography, Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import exclamation from '../../../../assets/images/exclamation.png';
import { CancelDialog } from './cancelReturnDialog.styles';
import { CANCEL_RETURN_ORDER } from '../../../../mutations/orders';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CancelReturnDialog = ({
  open,
  close,
  reviewPage,
  editReturn,
  orderId
}) => {
  const navigate = useNavigate();
  const [cancelReturn] = useMutation(CANCEL_RETURN_ORDER);

  const handleBack = () => {
    if (reviewPage) return navigate(-2);
    if (editReturn) return navigate('/orders-admin');
    return navigate(-1);
  };

  const handleCancelOrderDialog = () => {
    cancelReturn({
      variables: {
        returnOrderId: orderId
      }
    }).then((response) => handleBack()).catch((err) => {
      toast.error(err?.message);
    });
  };

  return (
    <CancelDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent style={{ minWidth: '33rem', padding: '2rem' }}>
        <Close
          fontSize="medium"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={close}
        />
        <DialogContentText style={{ marginTop: '2.5rem', marginBottom: '0' }}>
          <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <img src={exclamation} alt="exclamation" width={120} />
            </Box>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Cancel Return?
            </Typography>
            <Typography variant="subtitle1" style={{ paddingTop: '1rem', color: '#606060', fontSize: '1.1rem' }}>
              Hi User, are you sure you want to cancel return
            </Typography>
          </Box>
        </DialogContentText>
        <DialogActions style={{ marginBottom: '2rem' }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                style={{
                  padding: '1rem', fontWeight: '600'
                }}
                onClick={close}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                style={{
                  background: '#303030', color: '#FFF', padding: '1rem', fontWeight: '600'
                }}
                size="large"
                onClick={handleCancelOrderDialog}
              >
                Yes, Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogContent>
    </CancelDialog>
  );
};

CancelReturnDialog.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reviewPage: PropTypes.bool.isRequired,
  editReturn: PropTypes.bool.isRequired,
  orderId: PropTypes.number.isRequired
};

export default CancelReturnDialog;
