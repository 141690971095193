import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

export const CategoryGrid = styled('div')`
    display: flex;
    padding: 0 2rem 0 .2rem;

    @media (min-width: 992px) {
      gap: 1rem;
    }
    @media (max-width: 991px) {
      overflow: hidden;
      overflow-x: scroll;
  }
`;

export const CardPaper = styled(Paper)`
  border-radius: .4rem;
  cursor: pointer;
  height: 260px;
  margin-bottom: 20px;
  width: 270px;
  margin-left: 15px;
  background: #FFFFFF;
  border: 0.602329px solid #E7E8E9;
  box-shadow: 0px 4.81864px 13.2512px rgba(118, 117, 117, 0.08);

    ${({ active }) => active && `
        border: 3px solid #FFDF00;
    `}

  @media (min-width: 992px) {
    width: 12.5%;
    height: 180px;
    margin-left: auto;
  }

  @media (max-width: 991px) {
    border-radius: 1.5rem;
    height: 330px;
  }
`;

export const AllCategoriesPaper = styled(Paper)`
  border-radius: .4rem;
  cursor: pointer;
  height: 260px;
  margin-bottom: 20px;
  margin-left: 15px;
  width: 270px;
  background: #FFFFFF;
  border: 0.602329px solid #E7E8E9;
  box-shadow: 0px 4.81864px 13.2512px rgba(118, 117, 117, 0.08);

    ${({ active }) => active && `
        border: 3px solid #FFDF00;
    `}

  @media (min-width: 992px) {
    width: 12.5%;
    height: 180px;
    margin-left: 1rem;
  }

  @media (max-width: 991px) {
    border-radius: 1.5rem;
    height: 330px;
  }
`;

export const CategoryImg = styled('img')`
    display: block;
    margin: 0 auto 10px auto;
    @media (max-width: 991px) {
      width: 7rem;
      margin: 25px auto 10px auto;
    }
`;

export const CardBox = styled('div')`
    padding: 20px;
`;

export const CategoryText = styled(Typography)`
    font-size: .9em;
    text-align: center;
    @media(max-width: 991px) {
      font-size: 1.9em;
      margin-top: 2rem;
    }

`;

export const NextImage = styled('img')`
  width: 7rem;
  position: absolute;
  right: 1px;
  top: 12rem;
  z-index: 5;
  cursor: pointer;
  @media(min-width: 992px) {
    width: 5.5rem;
  }
  @media (max-width: 991px) {
    top: 18rem;
  }
`;
