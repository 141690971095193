import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, DialogTitle, Slide,
  Grid, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import SavePopper from '../shared/crudModel/uploadModel/individual/savePopper';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleWrapper, CircularProgressLoader, GridWrapper, TopGrid, DialogContainer
} from '../suppliers/individual/individualSupplierDialog.styles';
import ModelActionTypes from '../../providers/reducers/model/modelTypes';
import { useStateValue } from '../../providers/stateProvider';
import { parseCategoryFields } from '../shared/utils';
import { CREATE_CATEGORY_MUTATION, UPDATE_CATEGORY_MUTATION } from '../../mutations/categories';
import SaleActionTypes from '../../providers/reducers/sales/salesTypes';
import { getCustomerObject } from '../../utils/utils';
import CustomSelectInputBase from '../customComponents/customSelectInputBase';
import CustomInputBase from '../customComponents/customInputBase';
import SuccessDialog from '../shared/successDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualCategoryDialog = ({
  dialogOpen, closeDialog, autoAddCustomerToSale,
  edit, catData, catEditId
}) => {
  const initialState = {
    categoryName: catData.categoryName || '',
    vatStatus: catData.vatStatus || '',
    markup: catData.markup || '',
    loyaltyWeight: catData.loyaltyWeight || ''
  };

  const initialErrorState = {
    nameError: false,
    vatError: false,
    markupError: false
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [editing, setEditing] = useState(edit);
  const [successDialog, setSuccessDialog] = useState(false);
  const [successState, setSuccessState] = useState({ title: '', desc: '' });

  const [{
    model: { model }
  }, dispatch] = Object.values(useStateValue());

  const [createCategory] = useMutation(CREATE_CATEGORY_MUTATION);
  const [updateCategory] = useMutation(UPDATE_CATEGORY_MUTATION);

  const { nameError, vatError, markupError } = errorState;
  useEffect(() => {
    if (model) {
      const {
        categoryId, categoryName, markup, loyaltyWeight, vatStatus
      } = parseCategoryFields(model);

      setEditing(true);

      setState((_state) => ({
        ..._state, categoryId, categoryName, markup, loyaltyWeight, vatStatus
      }));
    }
  }, [model]);

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: { category: null, reFetch: true }
    });
    setTimeout(() => dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: { reFetch: false }
    }), 0);
    closeDialog();
  };

  const addCategory = (addMore) => {
    const {
      categoryName, vatStatus, markup, loyaltyWeight
    } = state;
    createCategory({
      variables: {
        categoryName,
        vatStatus,
        markup,
        loyaltyWeight
      }
    })
      .then(({ data }) => {
        const { category: newCustomer } = data?.createCategory || {};
        setSuccessDialog(true);
        setSuccessState({ title: 'Category Added!', desc: 'Your category has been added successfully!' });
        if (!addMore) {
          handleCloseDialog();
        }
        if (autoAddCustomerToSale) {
          dispatch({
            type: SaleActionTypes.UPDATE_CUSTOMER,
            payload: {
              openedCustomerModal: false,
              ...getCustomerObject(newCustomer)
            }
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCategory = () => {
    const {
      categoryName, vatStatus, markup, loyaltyWeight
    } = state;
    updateCategory({
      variables: {
        categoryId: catEditId,
        categoryName,
        vatStatus,
        markup,
        loyaltyWeight
      }
    })
      .then(() => {
        setSuccessDialog(true);
        setSuccessState({ title: 'Category Updated!', desc: 'Your category has been updated successfully!' });
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async (addMore = false) => {
    setSaveButtonEl(null);
    setLoading(true);
    if (editing) return editCategory();
    return addCategory(addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
  };

  const handleSaveButtonClick = (event) => {
    const { categoryName, vatStatus, markup } = state;
    if (!categoryName || !vatStatus || !markup) {
      return setErrorState({
        ...errorState,
        nameError: !categoryName,
        vatError: !vatStatus,
        markupError: !markup
      });
    }
    if (categoryName.length > 1 && !editing) {
      return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
    } if (categoryName.length > 1 && editing) {
      return handleSave();
    }
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'categoryName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'vatStatus':
        return setErrorState({
          ...errorState,
          vatError: !(value.length > 1)
        });
      case 'markup':
        return setErrorState({
          ...errorState,
          markupError: !value
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== 'categoryName' && name !== 'vatStatus') {
      if (value > 100) {
        toast.error('Sorry, 100% is the maximum');
        return;
      }
      if (value < 0) {
        toast.error('Sorry, 0% is the minimum');
        return;
      }
    }
    validateState(name, value);
    setState({ ...state, [name]: value });
  };
  const topFields = [
    {
      name: 'categoryName', label: 'Category Name', helperText: 'Category Name is required', placeholder: 'Enter Category Name',
      testid: 'catNameInput', required: true
    },
    {
      name: 'vatStatus', label: 'VAT Status', options: ['YES', 'NO'], placeholder: 'Select', testid: 'vatStatusInput',
      helperText: 'VAT Status is required', required: true
    },
  ];

  const secondLineField = [
    {
      name: 'markup', label: 'Markup (%)', placeholder: 'Enter Markup', testid: 'markupInput',
      helperText: 'Markup is required', required: true
    },
    {
      name: 'loyaltyWeight', label: 'Loyalty Weight (%)', placeholder: 'Enter Loyalty Weight', testid: 'loyaltyWeightInput'
    }
  ];

  const returnTextField = (field, index) => {
    const {
      name: fieldName, label, helperText, placeholder, testid, required
    } = field;
    const value = state[fieldName] || '';

    const selectFieldNames = ['term', 'country', 'vatStatus', 'reorderUnit'];
    const fieldError = fieldName === 'categoryName' ? nameError
      : fieldName === 'markup' ? markupError : false;

    if (selectFieldNames.includes(fieldName)) {
      return (
        <Grid item xs={6}>
          <CustomSelectInputBase
            key={index}
            field={field}
            value={value}
            placeholder={placeholder}
            handleChange={handleChange}
            handleCreditDaysOpen={() => ({})}
            creditDays={() => ({})}
            showCheckBox={false}
            style={{ width: '320px', height: '51px' }}
            data-testid={testid}
            error={fieldName === 'vatStatus' && vatError}
            helperText={vatError && helperText}
            required={required}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={6}>
        <CustomInputBase
          variant="filled"
          label={label}
          value={value}
          placeholder={placeholder}
          size="small"
          step={0.01}
          type={fieldName === 'categoryName' ? 'text' : 'number'}
          error={fieldError}
          helperText={(nameError || markupError) && helperText}
          name={fieldName}
          onChange={handleChange}
          style={{ width: '320px', height: '51px' }}
          data-testid={testid}
          required={required}
        />
      </Grid>
    );
  };

  return (
    <>
      <DialogContainer
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fileslimit={1}
        disableEnforceFocus
      >
        <Grid container>
          <GridWrapper container item xs={12}>
            <DialogTitle id="alert-dialog-slide-title">
              <Box style={{ textAlign: 'right' }}>
                <Close
                  fontSize="small"
                  style={{
                    position: 'absolute', right: '2rem', cursor: 'pointer',
                    marginTop: '14px', color: '#235A91'
                  }}
                  onClick={handleCloseDialog}
                />
              </Box>
              <Grid container style={{ marginTop: '1rem' }}>
                <Grid item xs={8}>
                  <DialogTitleWrapper container>
                    <DialogTitleText>
                      {editing ? 'Edit Category' : 'Add Category' }
                    </DialogTitleText>
                  </DialogTitleWrapper>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <TopGrid container spacing={2}>
                {topFields.map((field, index) => returnTextField(field, index))}
              </TopGrid>
              <TopGrid container spacing={4}>
                {secondLineField.map((field, index) => returnTextField(field, index))}
              </TopGrid>
            </DialogContent>

            <DialogActions style={{ margin: '.5rem 0' }}>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    {editing ? 'Save' : 'Save & ...'}
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </DialogContainer>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />

      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title={successState.title}
        desc={successState.desc}
        option="Ok"
      />
    </>
  );
};

IndividualCategoryDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  autoAddCustomerToSale: PropTypes.bool,
  edit: PropTypes.bool,
  catData: PropTypes.instanceOf(Object),
  catEditId: PropTypes.string
};

IndividualCategoryDialog.defaultProps = {
  autoAddCustomerToSale: false,
  edit: false,
  catData: {},
  catEditId: ''
};

export default IndividualCategoryDialog;
