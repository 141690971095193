import React from 'react';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';

export const StatisticWrapper = styled(Grid)`

`;

export const StatisticCard = styled(Grid)`
  box-shadow: 3px 3px 10px 4px rgb(194 194 194 / 9%);
  border-radius: 12px;
  padding: 1.5rem;
  background-color: #fff;

  @media(max-width: 991px) {
    height: 15rem;
    border-radius: 30px;
    margin-bottom: 1rem;
  }
`;

export const StatisticGrid = styled(Grid)`
  margin-bottom: 10px;
  align-items: center;
`;

export const StatisticHeader = styled(Grid)`
  font-size: 20px;
  color: #303030;
  font-weight: 600;
  margin-left: 15px;

  @media(min-width: 992px) {
    font-size: 14px;
  }

  @media(max-width: 991px) {
    margin-left: 1rem;
    font-size: 30px;
  }
`;

export const StatisticContent = styled(Grid)`
  color: #000;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 24px;
  }

  @media(max-width: 991px) {
    font-size: 40px;
    font-weight: 800;
    margin-top: 1rem;
  }
`;

export const DiffGrid = styled(Grid)`
  color: ${({ diff }) => (diff < 0 ? '#FF4142' : '#3CC480')};
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const DiffImage = styled('img')`
  height: 5px;
  width: 5px;
  margin-right: 2px;

  @media(min-width: 992px) {
    height: 11px;
    width: 11px;
  }
`;

export const IconImage = styled('img')`
  height: 40px;

  @media(min-width: 992px) {
    height: 50px;
  }
`;
