import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import currencyFormatter from '../../shared/currencyFormatter';
import { BodyCell, MainTableRow } from './returnRow.styles';

const ReturnRow = ({
  id, name, dateCreated, total, supplierName, handleOrderItemCardClicked, status
}) => {
  const isLarge = useMediaQuery('(min-width:992px)');

  const dateFormat = isLarge ? 'MMM Do YY h:mm a' : 'l';
  const datePlaced = moment(dateCreated).format(dateFormat);
  const amount = currencyFormatter(total);

  const orderCells = [
    datePlaced, id, name, amount, supplierName, status
  ];

  const renderBodyCells = () => orderCells.map((cell) => (
    <BodyCell onClick={() => handleOrderItemCardClicked(id)} key={cell}>
      {cell || 'N/A'}
    </BodyCell>
  ));

  return (
    <MainTableRow>
      {renderBodyCells()}
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  dateCreated: PropTypes.string,
  total: PropTypes.number,
  supplierName: PropTypes.string,
  handleOrderItemCardClicked: PropTypes.func.isRequired,
  status: PropTypes.string
};

ReturnRow.defaultProps = {
  id: '',
  name: '',
  dateCreated: '',
  total: 0,
  supplierName: '',
  status: '',
};

export default ReturnRow;
