import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Slide
} from '@mui/material';
import {
  DialogActionButtonText, DialogCancelButton, DialogOkButton
} from '../cart/cart.styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const RemoveDataDialog = ({
  open, setOpen, deleteLocalData
}) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    s
    keepMounted
    onClose={() => setOpen(false)}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">Delete Local Data</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Are you sure? This action will log you out!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <DialogCancelButton
        onClick={() => setOpen(false)}
      >
        <DialogActionButtonText>
          Cancel
        </DialogActionButtonText>
      </DialogCancelButton>
      <DialogOkButton onClick={deleteLocalData}>
        <DialogActionButtonText>
          OK
        </DialogActionButtonText>
      </DialogOkButton>
    </DialogActions>
  </Dialog>
);

RemoveDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  deleteLocalData: PropTypes.func.isRequired,
};

export default RemoveDataDialog;
