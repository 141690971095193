import React from 'react';
import PropTypes from 'prop-types';
import {
  SupplierDetailCardHeader, SupplierDetailCardTitle, OrderDeliveryDetailInfoWrapperRow, OrderDeliveryInfoLabel,
  OrderDeliveryInfoText, DeliveryStatusText, OrderBusinessName
} from './deliveryInfoCard.styles';

const DeliveryInfoCard = ({ businessContact, openDetailsPage, businessName }) => (
  <>
    <SupplierDetailCardHeader>
      <SupplierDetailCardTitle>Delivery Location</SupplierDetailCardTitle>
      {/* {openDetailsPage
        ? (
          <DeliveryStatusText
            onClick={openDetailsPage}
          >
            View Delivery Status
          </DeliveryStatusText>
        ) : ''} */}
    </SupplierDetailCardHeader>
    <OrderDeliveryDetailInfoWrapperRow>
      {businessName && (
        <OrderBusinessName data-testid="business-name">{businessName}</OrderBusinessName>
      )}
      <div>
        <OrderDeliveryInfoLabel>Mobile</OrderDeliveryInfoLabel>
        <OrderDeliveryInfoText>{ businessContact && businessContact.mobile_number ? businessContact.mobile_number : 'N/A' }</OrderDeliveryInfoText>
      </div>
      <div>
        <OrderDeliveryInfoLabel>Email</OrderDeliveryInfoLabel>
        <OrderDeliveryInfoText>{ businessContact && businessContact.primary_email_address ? businessContact.primary_email_address : 'N/A' }</OrderDeliveryInfoText>
      </div>
    </OrderDeliveryDetailInfoWrapperRow>
    <div style={{ marginTop: '20px' }}>
      <OrderDeliveryInfoLabel>Address</OrderDeliveryInfoLabel>
      {businessContact && (
        <OrderDeliveryInfoText>
            {`${businessContact.address_line_1 ?? 'N/A'} ${businessContact.address_line_2 ?? ''},`}
            { `${businessContact?.region ?? ''} ${businessContact?.city},`}
            {`${businessContact?.country ?? ''}.`}
        </OrderDeliveryInfoText>
      )}
    </div>
  </>
);

DeliveryInfoCard.propTypes = {
  businessContact: PropTypes.instanceOf(Object),
  openDetailsPage: PropTypes.func.isRequired,
  businessName: PropTypes.instanceOf(String),
};

DeliveryInfoCard.defaultProps = {
  businessContact: {},
  businessName: ''
};

export default DeliveryInfoCard;
