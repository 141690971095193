import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';

export const AutoCompleteStyled = styled(Autocomplete)`
  margin-top: 5px;
`;

export const AutocompleteField = styled(TextField)`
  .MuiOutlinedInput-root {
    font-weight: 400;
    font-size: 14px;
    border-radius: 12px;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #000;
  }
`;

export const List = styled('li')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
