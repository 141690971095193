import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PlaceholderInput, WrapperInput } from './customDatePicker.styles';
import { Calender } from '../../../assets/svgs';

export const CustomDatePicker = ({
  handleSubmit, xs, startDate
}) => {
  const [date, setDate] = useState();

  function dateChangeHandler(eventDate, event) {
    setDate(eventDate);
    handleSubmit(moment(eventDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
  }

  // const CustomInput = ({ value, onClick }) => (
  //   <PlaceholderInput item container onClick={onClick}>
  //     <Calender style={{ fontSize: '.9rem', margin: '0 0.3rem' }} />
  //     {` ${moment(startDate).format('MMM D, YYYY')}`}
  //   </PlaceholderInput>
  // );

  return (
    <WrapperInput item container xs={xs} alignItems="center" justifyContent="space-between">
      <div className="date-picker-wrapper">
        <DatePicker
          selected={date}
          onChange={(eventDate, event) => dateChangeHandler(eventDate, event)}
          // customInput={<CustomInput />}
        />
      </div>
    </WrapperInput>
  );
};

CustomDatePicker.propTypes = {
  xs: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
};

CustomDatePicker.defaultProps = {
  xs: 5,
};

export default CustomDatePicker;
