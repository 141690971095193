import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { HeaderStyled } from '../dashboardContainer.styles';
import CustomNewTextField from '../../shared/CustomNewTextField';
import { useStateValue } from '../../../providers/stateProvider';

const Header = ({ setGetDate }) => {
  const FilterFields = [
    { name: 'dateFrom', label: 'From', shrink: true },
    { name: 'dateTo', label: 'To', shrink: true }
  ];

  const [{
    user: { allowedPermissionsMap: { userPermissions } },
  }] = Object.values(useStateValue());
  const [state, setState] = useState({
    dateFrom: '',
    dateTo: ''
  });

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const filterDateHandler = () => {
    setGetDate({
      from: state?.dateFrom,
      to: state?.dateTo
    });
  };

  return (
    <Grid container justifyContent="flex-end">
      <HeaderStyled>
        {FilterFields.map((field) => (
          <CustomNewTextField
            key={field?.name}
            field={field}
            state={state}
            handleChange={handleDateChange}
          />
        ))}
        <Button
          variant="contained"
          data-testid="custom-element"
          disabled={!userPermissions?.includes('sc_dashboard_set_filter')}
          onClick={filterDateHandler}
          style={{ width: '110px' }}
        >
          Set Filter
        </Button>
      </HeaderStyled>
    </Grid>
  );
};

Header.propTypes = {
  setGetDate: PropTypes.isRequired
};

export default Header;
