import { HelpOutlineRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TooltipTitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 700;
  color: #f5f5f5;
`;

export const ToolTipContent = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
  color: #ecf6ff;
`;

export const HelpOutlineStyled = styled(HelpOutlineRounded)`
  width: 15px;
  height: 15px;
  color: #78aada;
`;
