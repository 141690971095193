import {
  Typography, Checkbox, FormControl, Select
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CFormControl = styled(FormControl)`
  & .MuiFilledInput-root {
    background-color: transparent;
    border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #d8d8d8')};
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #606060;

    &::before {
      content: ' ';
      border-bottom: 0;
    }
    &:hover:not(.Mui-disabled,.Mui-error):before {
      border-bottom: 0;
    }
  }
  & .MuiSelect-select {
    padding: ${({ cSize }) => (cSize === 'lg' ? '16px' : '12px')};
  }

  &:active, &:hover, &:focus, &:focus-within {
    & .MuiFilledInput-root {
      border: 1.3px solid #78AADA;
      border-radius: 8px;
    }

    & .MuiSvgIcon-root {
      color: #78AADA;
    }
    &::before {
      content: " ";
      border-bottom: 0;
    }
  }
`;

export const Label = styled(Typography)`
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
  margin-bottom: .2rem;
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #fced50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;

export const SelectStyled = styled(Select)`
  background-color: transparent;
  border: 0.5px solid #d8d8d8;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #606060;
  height: 3.4rem;
  padding-left: 1rem;
`;

export const HelperText = styled(Typography)`
  font-size: 10px;
  color: #ff0000;
  opacity: 0.8;
  margin-left: 10px;
`;
