import React from 'react';
import {
  Slide
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  DialogContentStyled,
  ContentHeader,
  HeaderText,
  FlexStyled,
  DialogStyled, NoteBody,
  CloseStyled, NameStyled, TimeStyled
} from './viewNoteDialog.style';
import { stringShortner } from '../../../utils/currencyFormatter';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ViewNoteDialog = ({
  modalStatus, modalHandler
}) => (
  <DialogStyled
    open={modalStatus}
    TransitionComponent={Transition}
    keepMounted
    onClose={modalHandler}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContentStyled>
      <ContentHeader>
        <HeaderText>All Notes</HeaderText>
        <CloseStyled onClick={modalHandler} />
      </ContentHeader>
      <FlexStyled>
        <NameStyled>George Jumoke</NameStyled>
        <TimeStyled>5:27 PM</TimeStyled>
      </FlexStyled>
      <NoteBody>
        {
          stringShortner('Lorem ipsum dolor sit amet consectetur. Cursus facilisis dui laoreet gravida malesuada fermentum proin mollis. Natoque faucibus felis sagittis morbi orci eleifend blandit amet. Sed et egestas', 170)
        }

      </NoteBody>
    </DialogContentStyled>

  </DialogStyled>
);

ViewNoteDialog.propTypes = {
  modalStatus: PropTypes.bool.isRequired,
  modalHandler: PropTypes.func,
};

ViewNoteDialog.defaultProps = {
  modalHandler: () => {},
};

export default ViewNoteDialog;
