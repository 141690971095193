import { styled } from '@mui/material/styles';
import {
  Grid, Typography, IconButton, Paper
} from '@mui/material';

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  flex-direction: column;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 24px;
    margin-bottom: 0.5rem;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #606060;

  @media(max-width: 991px) {
    font-size: 32px;
    margin-bottom: 1rem;
  }
`;

export const CIconButton = styled(IconButton)`
//   width: 2rem;
  width: 3.2rem;
  height: 2rem;
  padding: 0;
  color: #235A91;


  @media(min-width: 992px) {
  }

  @media(max-width: 991px) {
    background-color: #ECF6FF;
    width: 7rem;
    height: 7rem;
  }
`;

export const StatisticsGrid = styled(Grid)`
  overflow: hidden;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media (min-width: 992px) {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 0 20px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;

