import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatusesGrid = styled(Grid)`
  padding: 0.5rem 0rem;
  align-items: center;
  margin-bottom: .8rem;

  /* @media(min-width: 992px) {
    padding: 1.8rem;
  } */
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  border-bottom: ${({ active }) => (active ? '3px solid #EDE300' : '3px solid #EEEEEE')};
  width: ${({ width }) => (width)};
`;

export const Typo = styled(Typography)`
  font-size: 17px;
  font-weight: 500;
  padding: 2px 8px;
  color: ${({ active }) => (active ? '#1A0E00' : '#C0C0C1')};
`;
