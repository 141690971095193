import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Menu
} from '@mui/material';
import { MenuItemStyled } from './returnRow.styles';

export default function PoPopper({ actionHandler, items }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ width: '8rem', height: '2.9rem' }}
      >
        View PO
      </Button>

      <Menu
        elevation={1}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {
          items?.length > 0
            ? items?.map((item) => (
              <MenuItemStyled key={item} onClick={() => { actionHandler(item); handleClose(); }}>{`# ${item}`}</MenuItemStyled>
            ))
            : <MenuItemStyled>List is empty</MenuItemStyled>
        }
      </Menu>
    </>
  );
}

PoPopper.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
};
