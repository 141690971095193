import { gql } from '@apollo/client';

// export const SC_DASHBOARD = gql`
//   query supplyChainDashboard {
//     supplyChainDashboard {
//       totalProductsInInventory
//       totalEnabledProducts
//       totalDisabledProducts
//       totalSoldOutProducts
//       totalOrderValueInHouse
//       totalOrderCountInHouse
//       totalOrderValueAffiliate
//       totalOrderCountAffiliate
//     }
//   }
// `;

export const SC_DASHBOARD = gql`
  query supplyChainDashboard($dateFrom: String, $dateTo: String) {
    supplyChainDashboard(dateFrom: $dateFrom, dateTo: $dateTo) {
      totalProductsInInventory
      totalEnabledProducts
      totalDisabledProducts
      totalSoldOutProducts
      totalOrderValueInHouse
      totalOrderCountInHouse
      totalOrderValueAffiliate
      totalOrderCountAffiliate
    }
  }
`;
