import {
  Dialog, Grid, Typography, IconButton, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check, Add } from '@mui/icons-material';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
    max-width: 37rem;
  }

  @media(max-width: 991px) {
    & .MuiDialog-paperScrollPaper {
    border-radius: 20px;
    max-width: 90vw;
   }
  }
`;

export const TCustomButton = styled(Button)`
  border: 1px solid #303030;
  border-radius: 8px;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  height: 3rem;
  width: 7.5rem;
  margin-right: 0.8rem;
  color: #7b7b7b;
  font-weight: 500;
  font-size: 14px;

  @media(max-width: 991px) {
    border: 1.5px solid #235A91;
    box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
    margin-right: 0.8rem;
    color: #235A91;
  }
`;

export const BatchDialogHeader = styled(Grid)`
  padding: 30px 25px;
  justify-content: space-between;
  align-items: center;
`;

export const BatchDialogBody = styled(Grid)`
  padding: 0 25px 30px;
`;

export const BatchItem = styled(Grid)`
    background: #fff;
    padding: 20px 0;
    box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
    border-radius: 12px;
    margin-bottom: 10px;
  `;

export const ColumnHeader = styled(Grid)`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-transform: capitalize;
  color: #606060;
  margin-bottom: 10px;

  @media(max-width: 991px) {
    font-size: 25px;
  }
`;

export const ColumnValue = styled(Grid)`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-transform: capitalize;
  color: #303030;

  @media(max-width: 991px) {
    font-size: 25px;
    margin-top: 3rem
  }
`;

export const PackSize = styled(Grid)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #606060;
`;

export const CheckIcon = styled(Check)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const AddIcon = styled(Add)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const CheckIconContainer = styled(Grid)`
    cursor: pointer;
    padding: 8px;
    background-color: transparent;
    border: 1px solid;
    border-radius: 50%;
    font-size: 5px;
`;

export const ButtonContainer = styled(Grid)`

`;

export const DialogOkButton = styled(IconButton)`
    height: 50px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    border-radius: 10px;
    margin-right: 30px;
    background-color: #FCED50;
    color: #424242;
    margin-bottom: 40px;

    &:hover {
      background-color: #D2C647;
    }
  `;

export const ProductName = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #24292E;
`;

export const FilledCustomButton = styled(Button)`
  width: 178px;
  height: 48px;
  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;

  &:disabled {
    background: #235A91;
    color: #ffffff;
  }

  &:hover {
    background: #235A91;
    color: #ffffff;
  }

  &.MuiButton-root.Mui-disabled {
    cursor: not-allowed !important;
  }

  @media (max-width: 991px) {
    margin-top: 3rem;
    width: 60rem;
    font-size: 35px;
    border-radius: 20px;
    height: 5rem;
    font-weight: 600;
  }
`;

export const OutlinedCustomButton = styled(Button)`
  width: 178px;
  height: 48px;
  border: 2px solid #235A91;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  color: #235A91;
  
  &:disabled {
    background: #F4F5F7;
    border: none;
    box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  }

  &:hover {
    color: #235A91;
    background: none;
  }

  &.MuiButton-root.Mui-disabled {
    cursor: not-allowed !important;
  }

  @media (max-width: 991px) {
    margin-top: rem;
    width: 60rem;
    font-size: 25px;
    border-radius: 20px;
    height: 5rem;
  }
`;
