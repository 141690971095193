import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from '@mui/material';
import CloseIcon from '../../assets/images/Close.png';
import { OrderDialog, AddImage, ButtonIcon } from './OrderPrintDialog.styles';
import { OgaToken } from '../../graphql/token';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const OrderPrintDialog = ({ openPrintDialog, orderId, closePrint }) => (
  <OrderDialog
    open={openPrintDialog}
    fullWidth
    maxWidth="md"
    TransitionComponent={Transition}
    keepMounted
    onClose={closePrint}
  >
    <div style={{ position: 'relative', height: '100%', padding: '0 6rem' }}>
      <iframe
        title="receipt"
        src={`${OgaToken.SERVER_LINK}download_order/${orderId}.pdf`}
        width="100%"
        height="100%"
        style={{ border: 0 }}
      />
      <ButtonIcon size="small" onClick={closePrint}>
        <AddImage alt="Close Receipt" src={CloseIcon} />
      </ButtonIcon>
    </div>
  </OrderDialog>
);

OrderPrintDialog.propTypes = {
  openPrintDialog: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  closePrint: PropTypes.func.isRequired,
};

export default OrderPrintDialog;
