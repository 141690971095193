import { styled } from '@mui/material/styles';
import {
  Typography, Grid, Paper, Button
} from '@mui/material';

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OptionWrapper = styled(Grid)`
  margin-top: 2rem;
  padding: 0 0.8rem;
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const Desc = styled(Typography)`
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #606060;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 2rem;
`;

export const SaveChanges = styled(Button)`
  width: 170px;
  height: 50px;
  background: #303030;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;

  &:hover {
    background-color: #303030;
  }
`;

export const FooterSec = styled('div')`
  width: 780px;
  display: flex;
  justify-content: flex-end;
  margin: 4rem 0 3rem 0;
`;
