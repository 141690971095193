import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import CreateUserDialog from './createUserDialog/CreateUserDialog';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle
} from './titleGrid.styles';
import { useStateValue } from '../../../../providers/stateProvider';

export function TitleGrid({ refetch }) {
  const [userDialog, setUserDialog] = useState(false);

  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const handleDialog = () => {
    setUserDialog(!userDialog);
  };

  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item md={6}>
        <PrimaryTitle>
          Roles & Permissions
        </PrimaryTitle>
        <SecondaryTitle>
          Manage your users and give permissions
        </SecondaryTitle>
      </TitleTextGridContainer>
      <Grid container md={6} spacing={2} justifyContent="flex-end">
        <Button
          variant="contained"
          data-testid="export"
          onClick={handleDialog}
          disabled={!userPermissions?.includes('role_permissions_add_user')}
        >
          Create User
        </Button>
      </Grid>

      <CreateUserDialog
        open={userDialog}
        close={handleDialog}
        refetch={refetch}
      />
    </TitleGridContainer>
  );
}

TitleGrid.propTypes = {
  refetch: PropTypes.func.isRequired
};

export default TitleGrid;
