import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid, Select, MenuItem, InputLabel, FormControl, Checkbox,
} from '@mui/material';

import {
  DialogActionButtonText,
  DialogCancelButton, DialogOkButton, TopGrid,
  GridWrapper, DialogTitleText, DialogTitleWrapper
} from './busniess.style';

import { JSONParse } from '../../../utils/json';
import { useStateValue } from '../../../providers/stateProvider';
import BusinessActionTypes from '../../../providers/reducers/business/businessTypes';
import { GET_SUB_BUSINESSES } from '../../../queries/affiliates';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const DialogBox = ({ openDialog, closeDialog }) => {
  const [{ business: { businessId: contextBusinessId, businessUser: contextBusinessUserId } }, dispatch] = Object.values(useStateValue());
  const [state, setState] = useState({
    businessId: contextBusinessId || '',
    businessUserId: contextBusinessUserId || ''
  });
  const [canAutoUpdateBusinessUser, setCanAutoUpdateBusinessUser] = useState(true);
  const [businessUsers, setBusinessUsers] = useState([]);
  const handleCloseDialog = () => {
    closeDialog();
  };
  const setSubBusinesses = (businesses, busId) => {
    const business = businesses.find((bus) => bus.id === busId);
    if (business) {
      setBusinessUsers(business.businessuserSet || []);
      setCanAutoUpdateBusinessUser(false);
    }
  };
  const {
    loading, error, data
  } = useQuery(GET_SUB_BUSINESSES, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return null;
  if (error) return error;

  const { subBusinesses } = data;
  if ((contextBusinessId) && !businessUsers.length && canAutoUpdateBusinessUser) {
    setSubBusinesses(subBusinesses, contextBusinessId);
  }
  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case 'businessId':
        setState({ ...state, businessId: value, businessUserId: '' });
        setSubBusinesses(subBusinesses, value);
        break;
      case 'businessUserId':
        setState({ ...state, businessUserId: value });
        break;
      default:
        break;
    }
  };

  const proceesFilter = () => {
    dispatch({ type: BusinessActionTypes.ADD_BUSINESS, payload: state.businessId });
    dispatch({ type: BusinessActionTypes.ADD_BUSINESS_USER, payload: state.businessUserId });
    closeDialog();
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
      fileslimit={1}
      disableEnforceFocus
    >
      <Grid container>
        <GridWrapper container item xs={12}>
          <DialogTitle id="alert-dialog-slide-title">
            <DialogTitleWrapper container>
              <DialogTitleText>
                Select Business
              </DialogTitleText>
            </DialogTitleWrapper>
          </DialogTitle>
          <DialogContent style={{ background: '#F0F0F0' }}>
            <TopGrid item>
              <FormControl variant="standard">
                <InputLabel id="select-busniess-label">Select Business Outlet</InputLabel>
                <Select
                  variant="standard"
                  labelId="sselect-busniess-label"
                  id="select-busniess-label"
                  value={state.businessId}
                  onChange={handleChange}
                  name="businessId"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }}>
                  <MenuItem key="empty_business" value="">All</MenuItem>
                  {
                      subBusinesses?.map((business) => (
                        <MenuItem key={business.id} value={business.id}>
                          {business.name}
                        </MenuItem>
                      ))
                    }
                </Select>
              </FormControl>
              <FormControl variant="standard">
                <InputLabel id="select-buser-label">Select Business User</InputLabel>
                <Select
                  variant="standard"
                  labelId="select-buser-label"
                  id="select-buser-label"
                  value={state.businessUserId}
                  onChange={handleChange}
                  name="businessUserId"
                  disabled={businessUsers.length < 1}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }}>
                  <MenuItem key="empty_business_user" value="">All</MenuItem>
                  {businessUsers?.filter((bUser) => bUser.role.name === 'store-manager').map((b) => {
                    const { first_name: firstName } = JSONParse(b.user.profile);
                    const user = firstName || b.user.username;
                    return (
                      <MenuItem key={b.id} value={b.id}>
                        { user }
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TopGrid>
          </DialogContent>

          <DialogActions>
            <DialogCancelButton
              onClick={handleCloseDialog}
            >
              <DialogActionButtonText>
                Cancel
              </DialogActionButtonText>
            </DialogCancelButton>

            <DialogOkButton
              onClick={() => proceesFilter()}
            >

              <DialogActionButtonText>
                Proceed
              </DialogActionButtonText>
            </DialogOkButton>
          </DialogActions>
        </GridWrapper>
      </Grid>
    </Dialog>
  );
};

DialogBox.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

DialogBox.defaultProps = {

};

export default DialogBox;
