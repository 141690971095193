import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Grid, Slide,
} from '@mui/material';
import {
  HeaderGrid, ContentGrid, ActionsGrid, DoneButton, InputGrid,
  HeaderText, CancelButton, SmallText, SubHeaderText, TextArea
} from './ticketModal.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const TicketModel = ({ open, handleClose, handleLogTicket }) => {
  const [details, setDetails] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDone = () => {
    setLoading(false);
    setDetails('');
  };

  const handleTicket = () => {
    setLoading(true);
    handleLogTicket(details, handleDone);
  };

  const username = localStorage.getItem('oga_username');

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <HeaderGrid container>
        <Grid item container xs={12} wrap="nowrap" alignItems="center">
          <HeaderText>Add Ticket</HeaderText>
        </Grid>
        <SmallText>
          Please add your query below, we will get to it as soon as possible
        </SmallText>
      </HeaderGrid>
      <ContentGrid item container>
        <SubHeaderText variant="caption">
          Adding Ticket as:
        </SubHeaderText>
        &nbsp; &nbsp;
        <SmallText variant="caption">{username}</SmallText>
        <InputGrid item container>
          <TextArea
            label="Details"
            multiline
            fullWidth
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            minRows={4}
            variant="filled"
          />
        </InputGrid>
      </ContentGrid>
      <ActionsGrid container justifyContent="flex-end">
        <CancelButton onClick={handleClose}>
          Close
        </CancelButton>
        <DoneButton
          disabled={!details || loading}
          onClick={handleTicket}
        >
          Log ticket
        </DoneButton>
      </ActionsGrid>
    </Dialog>
  );
};

TicketModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLogTicket: PropTypes.func.isRequired,
};

export default TicketModel;
