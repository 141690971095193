import {
  TableCell, Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1) {
      width: 75%;
      padding-left: 20px;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 10%;
      cursor: pointer;
    }
  }
`;
