import React, { useState } from 'react';
import toast from 'react-hot-toast';
import AppBar from '@mui/material/AppBar';
import MainContent from '../../customComponents/mainContent';
import {
  AntTabs, MainContainer, OrderGridContainer, StyledTab
} from '../../orders/admin/order.styles';
import { useStateValue } from '../../../providers/stateProvider';
import AffiliatesIcon from '../../../assets/images/dashboard/affiliates-icon.png';
import SuppliersIcon from '../../../assets/images/dashboard/suppliers-icon.png';
import ProductsIcon from '../../../assets/images/dashboard/products-icon.png';
import TotalOrderIcon from '../../../assets/images/order/Total Orders Icon.png';
import GrossOrderIcon from '../../../assets/images/order/Gross Order Value Icon.png';
import SummaryController from '../../orders/admin/dashboard/summaryController';
import { GET_SALES_RECONCILIATION_SUMMARY } from '../../../queries/sales';
import TabPanel from '../../shared/tabPanel';
import ToggleBusiness from '../../shared/busnesss';
import MissedSaleTab from '../reconciliation/missedSaleTab';
import OutOfStockTab from '../reconciliation/outOfStockTab';

const SalesReport = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [outOfStockReFetchCount, setOutOfStockReFetchCount] = useState(1);
  const [{ navbar: { openSideDrawer } }, dispatch] = Object.values(useStateValue());
  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);
  const handleReFetchOutOfStock = (message) => {
    if (message) {
      toast.success(message);
      setOutOfStockReFetchCount(0);
      setTimeout(() => setOutOfStockReFetchCount(1), 0);
    } else {
      toast.error('Something went wrong!');
    }
  };
  const statistics = [{
    id: 1,
    icon: AffiliatesIcon,
    title: 'Total M/S Products',
    value: 'totalMissedSaleProduct'
  }, {
    id: 2,
    icon: SuppliersIcon,
    title: 'No of Transactions',
    value: 'totalNoOfTransactions',
  }, {
    id: 3,
    icon: ProductsIcon,
    title: 'Total Qty Missed',
    value: 'totalQuantityMissed',
  }, {
    id: 4,
    icon: TotalOrderIcon,
    title: 'Total Out Of Stock Sales',
    value: 'totalOutOfStockSales',
    type: 'amount'
  }, {
    id: 5,
    icon: GrossOrderIcon,
    title: 'Total Sale Amount',
    value: 'totalSaleAmount',
    type: 'amount'
  }];
  return (
    <MainContent>
      <MainContainer item container xs>
        <OrderGridContainer container>
          <SummaryController
            statistics={statistics}
            allowChainManager
            querySchema={GET_SALES_RECONCILIATION_SUMMARY}
            queryName="salesReconciliationSummary"
            primaryTitle="Sales Reconciliation"
            secondaryTitle="Track and update sales"
            backRoute="sales"
            reFetchCount={outOfStockReFetchCount}
          />
          <AppBar elevation={0} position="static">
            <AntTabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="Reconciliation tabs">
              <StyledTab label="Aggregated By Name" />
              <StyledTab label="Aggregated By Product Type" />
              <ToggleBusiness />
            </AntTabs>
          </AppBar>
          <TabPanel value={tabIndex} index={0} style={{ padding: 0 }}>
            {outOfStockReFetchCount ? (
              <OutOfStockTab
                reFetchCount={outOfStockReFetchCount}
                handleReFetch={handleReFetchOutOfStock}
              />
            ) : null}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} style={{ padding: 0 }}>
            <MissedSaleTab />
          </TabPanel>

        </OrderGridContainer>
      </MainContainer>
    </MainContent>
  );
};

export default SalesReport;
