import { Version } from './Version';

export class MarketplaceVersion extends Version {
  resolvedPriceInUseValue = 0;

  supplierCost = 0;

  priceInUse = '';

  itemPrice = 0;

  fixedItemPrice = 0;

  firstOrderPromoPrice = 0;

  supplierMarkupPrice = 0;

  marketRrp = 0;

  quantityInStock = 0;

  outOfStock = false;

  promo = [];

  constructor(rawVersion = {}) {
    super();
    const rawKeys = Object.keys(rawVersion);
    for (let key = 0; key < rawKeys.length; key++) {
      this[rawKeys[key]] = rawVersion[rawKeys[key]];
    }
  }
}
