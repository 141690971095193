import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import {
  ReceiptWrap, ItemWrap, ReceiptHeader, ReceiptP,
  ReceiptH3, ReceiptNumDate, IconWrap,
} from './sales.styles';
import ReceiptPrint from './receiptPrint';
import { JSONParse } from '../../utils/json';
import currencyAmount from '../../utils/currencyAmount';
import Print from '../../assets/images/Print.png';
import Email from '../../assets/images/Email.png';
import Close from '../../assets/images/Close.png';
import { renderPoints } from '../../utils/utils';

const Receipt = ({
  cart, returns, subTotal, total, saleReturnData, onClose,
  changeDue, customer, loyaltyPoints
}) => {
  const componentRef = useRef();
  const { businessUser, receiptNumber } = saleReturnData;
  const date = new Date().toLocaleDateString();
  const [printing, setPrinting] = useState(false);
  const { business, user } = businessUser;
  const { contacts = '', name } = business || {};
  const {
    mobile_number: mobileNumber, address_line_1: addressLine1, country, city
  } = JSONParse(contacts) || {};
  const { last_name: lastName, first_name: firstName } = JSONParse(user?.profile) || {};

  const paymentMethod = { cash: 0, card: 0, bankTransfer: 0 };
  returns.forEach((item) => {
    if (item.sale.paymentMethod === 'CASH') paymentMethod.cash += item.total;
    if (item.sale.paymentMethod === 'CARD') paymentMethod.card += item.total;
    if (item.sale.paymentMethod === 'BANK_TRANSFER') paymentMethod.bankTransfer += item.total;
  });

  const getTime = () => {
    const dt = new Date();
    let hours = dt.getHours();
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    const minutes = dt.getMinutes();
    return `${hours}:${minutes} ${AmOrPm}`;
  };
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const handlePrint = () => {
    setPrinting(true);
  };
  return (
    <div style={{ height: '1030px', background: '#F0F0F0' }}>
      <h1 style={{ marginLeft: '2%' }}>Sales Receipt</h1>
      <Grid container>
        <Grid style={{ overflow: 'scroll', height: '769px' }} ref={componentRef} item md={9}>
          {printing ? (
            <ReceiptPrint
              receiptNumber={receiptNumber}
              closePrint={() => setPrinting(false)}
              type="returns"
            />
          ) : (
            <ReceiptWrap>
              <ReceiptHeader>
                <ReceiptH3>{name}</ReceiptH3>
                <ReceiptP>
                  {addressLine1}
                  ,
                  {' '}
                  <br />
                  {city}
                  ,
                  {' '}
                  {country}
                  .
                  {' '}
                  <br />
                  Telephone:
                  {' '}
                  {mobileNumber}
                </ReceiptP>
              </ReceiptHeader>
              <ReceiptNumDate>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ border: 0 }}>
                        <ReceiptH3>
                          Receipt #
                          {receiptNumber}
                        </ReceiptH3>
                      </TableCell>
                      <TableCell style={{ textAlign: 'right', border: 0 }}>
                        {date}
                        {' '}
                        {getTime()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ReceiptNumDate>
              {customer && (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ border: 0, padding: '5px' }}>
                        Customer Name:
                        {` ${customer.name}`}
                      </TableCell>
                      <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}>
                        Loyalty points:
                        {` ${renderPoints(loyaltyPoints)}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              <ItemWrap style={{ borderBottom: 0 }}>
                <Table>
                  <TableBody>
                    { cart.map((item) => (item.missedSale ? null : (
                      <TableRow>
                        <TableCell style={{ border: 0, padding: '5px' }}>
                          {item.brandName}
                        </TableCell>
                        <TableCell style={{ border: 0, padding: '5px' }}>
                          {item.quantity > 1
                            ? (
                              <div>
                                {item.quantity}
                                {' '}
                                X
                                {' '}
                                {currencyAmount(item.itemPrice)}
                              </div>
                            ) : ''}
                        </TableCell>
                        <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}>
                          { currencyAmount(item.quantity * item.itemPrice) }
                        </TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </ItemWrap>

              <ReceiptNumDate>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ border: 0 }}>
                        <ReceiptH3>
                          Return
                        </ReceiptH3>
                      </TableCell>
                      <TableCell style={{ textAlign: 'right', border: 0 }}>
                        {date}
                        {' '}
                        {getTime()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ReceiptNumDate>

              <ItemWrap>
                <Table>
                  <TableBody>
                    { returns.map((item) => (item.missedSale ? null : (
                      <TableRow>
                        <TableCell style={{ border: 0, padding: '5px' }}>
                          {item.name}
                        </TableCell>
                        <TableCell style={{ border: 0, padding: '5px' }}>
                          {item.quantity > 1
                            ? (
                              <div>
                                {item.quantity}
                                {' '}
                                X
                                {' '}
                                {currencyAmount(item.unitCost)}
                              </div>
                            ) : ''}
                        </TableCell>
                        <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}>
                          { currencyAmount(item.quantity * item.unitCost) }
                        </TableCell>
                      </TableRow>
                    )))}
                  </TableBody>
                </Table>
              </ItemWrap>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ border: 0, paddingLeft: '15%' }} rowSpan={8} />
                    <TableCell style={{ border: 0, padding: '5px' }} colSpan={2}>Sale Amount (Incl. Vat)</TableCell>
                    <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}>{currencyAmount(subTotal)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ border: 0, padding: '5px' }}><b>Amount To Refund</b></TableCell>
                    <TableCell style={{ border: 0, padding: '5px' }} />
                    <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}>{ currencyAmount(Math.abs(returns.reduce((a, b) => a + b.total, 0))) }</TableCell>
                  </TableRow>
                  { paymentMethod.cash !== 0 ? (
                    <TableRow>
                      <TableCell style={{ border: 0, padding: '5px' }}>Cash</TableCell>
                      <TableCell style={{ border: 0, padding: '5px' }} />
                      <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}>{currencyAmount(Math.abs(paymentMethod.cash))}</TableCell>
                    </TableRow>
                  ) : <> </> }
                  { paymentMethod.card !== 0 ? (
                    <TableRow>
                      <TableCell style={{ border: 0, padding: '5px' }}>Card</TableCell>
                      <TableCell style={{ border: 0, padding: '5px' }} />
                      <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}>{currencyAmount(Math.abs(paymentMethod.card))}</TableCell>
                    </TableRow>
                  ) : <> </> }
                  { paymentMethod.bankTransfer !== 0 ? (
                    <TableRow>
                      <TableCell style={{ border: 0, padding: '5px' }}>Bank Transfer</TableCell>
                      <TableCell style={{ border: 0, padding: '5px' }} />
                      <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}>{currencyAmount(Math.abs(paymentMethod.bankTransfer))}</TableCell>
                    </TableRow>
                  ) : <> </> }
                  <TableRow>
                    <TableCell style={{ border: 0, padding: '5px' }}><b>Change Due</b></TableCell>
                    <TableCell style={{ border: 0, padding: '5px' }} />
                    <TableCell style={{ border: 0, padding: '5px', textAlign: 'right' }}><b>{currencyAmount(changeDue)}</b></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div style={{ borderTop: '1px solid #ddd', marginBottom: '2%', borderBottom: '1px solid #ddd' }}>
                <h3>
                  Your Cashier Today:
                  {' '}
                  {firstName ? `${firstName} ${lastName}` : user.username }
                </h3>
              </div>
              <div style={{ textAlign: 'center' }}>
                Thank You For Shopping With Us. Please Come Again.
              </div>
            </ReceiptWrap>
          )}
        </Grid>
        <Grid item md={3}>
          <IconWrap onClick={handlePrint}>
            <img src={Print} style={{ width: '60%', marginTop: '38px' }} alt="print" />
            <p style={{ margin: '47px', fontSize: '18px' }}>Print</p>
          </IconWrap>
          <br />
          <br />
          <IconWrap className="disabled" style={{ background: '#ececec' }}>
            <img src={Email} style={{ width: '60%', marginTop: '38px' }} alt="email" />
            <p style={{ margin: '47px', color: '#c1b7b7', fontSize: '18px' }}>Email</p>
          </IconWrap>
          <br />
          <br />
          <IconWrap onClick={() => onClose()}>
            <img src={Close} style={{ width: '60%', marginTop: '38px' }} alt="email" />
            <p style={{ margin: '47px 0', fontSize: '18px' }}>Close Transaction</p>
          </IconWrap>
        </Grid>
      </Grid>
    </div>
  );
};

Receipt.propTypes = {
  cart: PropTypes.instanceOf(Object),
  returns: PropTypes.instanceOf(Object),
  saleReturnData: PropTypes.instanceOf(Object),
  subTotal: PropTypes.number,
  total: PropTypes.number,
  changeDue: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.instanceOf(Object),
  loyaltyPoints: PropTypes.number
};

Receipt.defaultProps = {
  cart: [],
  returns: [],
  subTotal: 0,
  total: 0,
  changeDue: 0.00,
  saleReturnData: {},
  customer: {},
  loyaltyPoints: 0
};

export default Receipt;
