import {
  Grid, Typography, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { NoNotification } from '../../assets/svgs';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const TitleGridContainer = styled(Grid)`
  margin: 0 0 1.5rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #606060;
`;

export const CList = styled(List)`
  width: 100%;
  padding-bottom: 15px;
`;

export const NNotificationBody = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0 2rem 0;
`;

export const NotificationIcon = styled(NoNotification)`
  width: 110px;
  height: 109px;
  padding-bottom: 1rem;
`;

export const DescTypo = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  padding-bottom: .5rem
`;

export const FooterWrapper = styled(Grid)`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
`;
