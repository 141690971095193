import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  Slide,
  Grid,
  Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
// import MapCsvDialog from './mapCsvDialog';
import MapCsvDialog from '../sales/mapCsvDialog';
import {
  CardTitle,
  DialogContentStyled,
  TitleDesc,
  DialogContainer
} from './customerCSVUpload.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CustomerCSVUpload = ({ openDialog, toggleHandler, refetch }) => {
  const fields = [
    ['Customer Name', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str,required'
    }],
    ['Mobile', {
      required: true, unique: true, value: null, empty: true, touched: false, hover: false,
      rules: 'required'
    }],
    ['Email', {
      required: false, unique: true, value: null, empty: true, touched: false, hover: false,
      rules: 'email'
    }],
    ['Address Line 1', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Address Line 2', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Region', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['City', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Notes', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Country', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str'
    }],
    ['Loyalty Point', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'pointvalue'
    }],

    ['Store Credit', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'pointvalue'
    }]
  ];

  return (
    <DialogContainer
      data-testid="customer-dialog"
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>Import CSV</CardTitle>
              <Close
                fontSize="small"
                onClick={toggleHandler}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <TitleDesc>Upload CSV File To Add multiple customers</TitleDesc>
            <MapCsvDialog
              fields={fields}
              model="customer"
              endpoint="customers"
              backRoute="customers"
              refetch={refetch}
            />
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
};

CustomerCSVUpload.propTypes = {
  openDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

CustomerCSVUpload.defaultProps = {
  openDialog: false
};

export default CustomerCSVUpload;
