import {
  Typography, Box, TableCell, Paper, TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Content = styled(Paper)`
    padding: 20px;
    margin: 40px 0;
    box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
    border-radius: 10px;

    @media(max-width: 991px) {
      padding: 50px 30px;
      margin: 40px 0;
      border-radius: 15px;
    }
`;

export const ProductTypo = styled(Typography)`
    font-size: 1.3rem;
    font-weight: 600;
    @media(max-width: 991px) {
      padding: 1.5rem 0;
      font-size: 3rem;
      font-weight: 700;
    }
`;

export const TableBox = styled(Box)`
  margin-top: 20px;
`;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }

  &:nth-of-type(even) {
    background-color: #F4F7FC;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 2.5rem;
  padding: 15px;
  white-space: nowrap;

  @media(min-width: 992px) {
    font-size: .9rem;
    padding: 10px;
    cursor: pointer;
  }
`;

export const HeaderCell = styled(TableCell)`
  background-color: #F4F7FC;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.5rem;
  padding: 10px;

  @media(max-width: 991px) {
    font-size: 2.6rem;
    white-space: nowrap;
    padding: 30px 25px;
    font-weight: 610;
  }
`;
