import React, { useState } from 'react';
import toast from 'react-hot-toast';
import {
  DialogActions,
  DialogContent,
  Slide,
  Grid,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { CDialog, CustomDialog, DialogText } from './renderPopper.styles';
import CustomInputBase from '../customComponents/customInputBase';
import CustomButton from '../customComponents/customButton';
import { CloseIcon } from '../shared/uploadProduct/individual/individualProductDialog.styles';
import {
  SAVE_CART_ORDER
} from '../../mutations/cart';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SaveDialog = ({
  saveDialog,
  handleClose,
  handleSave,
  handleAction,
}) => {
  const initialState = {
    comment: '',
  };
  const initialErrorState = {
    commentError: false
  };
  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const { commentError } = errorState;
  const [saveOrderCart] = useMutation(SAVE_CART_ORDER);
  // const handleSaveCart = () => {
  //   if (commentError);
  // };

  const validateState = (name, value) => {
    switch (name) {
      case 'comment':
        return setErrorState({
          ...errorState,
          commentError: !(value.length > 3)
        });
      default:
        return null;
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    // if (name === 'comment' && value === '') return;
    setState({ ...state, [name]: value });
  };

  const handleSaveCart = () => {
    handleAction();
    handleSave();
  };

  const handleSaveCartOrder = () => {
    const { comment } = state;
    const variables = { comment };
    saveOrderCart({ variables })
      .then(({ data }) => {
        const { message } = data?.saveOrderCart || {};
        toast.success(message);
        handleSaveCart();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const handleCartSaved = () => {
    const { comment } = state;
    if (comment.length < 3) {
      toast.error('Comment is  required');
    } else if (comment.length > 3) {
      return handleSaveCartOrder();
    } else {
      return null;
    }

    return setErrorState({
      ...errorState,
      commentError: comment.length < 3,
    });
  };

  return (
    <CustomDialog
      open={saveDialog}
      ransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: 15 }
      }}
      fullWidth
    >
      <Grid container>
        <Grid item container xs={6}>
          <CDialog>
            Save Cart
          </CDialog>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon
              onClick={handleClose}
              style={{
                position: 'absolute',
                right: '1.5rem',
                top: '1rem'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <DialogContent>
        <DialogText
          style={{
            color: '#606060',
          }}
        >
          You are about to save this cart. This note will be a quick identifier when you
          <br />
          decide to continue with this cart.
        </DialogText>
        <CustomInputBase
          multiline
          maxRows={2}
          label="Add Note"
          placeholder="This note will be a quick identifier"
          name="comment"
          required
          value={state.comment}
          helperText="Comment required"
          error={commentError}
          onChange={handleChange}
          PaperProps={{
            style: { borderRadius: 15 }
          }}
        />
      </DialogContent>
      <DialogActions
        style={{
          marginTop: '1.2rem',
          marginBottom: '1.2rem'
        }}
      >
        <CustomButton
          type="secondary"
          header
          style={{ right: '2rem' }}
          onClick={handleClose}
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={handleCartSaved}
          type="tertiary"
          header
          style={{ right: '1.1rem' }}
        >
          Save Cart
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};

SaveDialog.propTypes = {
  saveDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired
};

export default SaveDialog;
