import {
  Grid, Typography, Paper, ListItem, ListItemAvatar, Avatar, ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { NoNotification } from '../../assets/svgs';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const TitleGridContainer = styled(Grid)`
  margin: 0 0 1.5rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #606060;
`;

export const RedBadge = styled('div')`
  position: absolute;
  top: 2px;
  right: 19px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  z-index: 10;
  background: #FF0909;
`;

export const CListItem = styled(ListItem)`
  padding-left: 1.5rem;
`;

export const CListItemAvatar = styled(ListItemAvatar)`
  position: relative;
`;

export const CAvatar = styled(Avatar)`
  background: #F1F8FF;
`;

export const CListItemText = styled(ListItemText)`
  & .MuiListItemText-primary {
    font-size: 16px;
    font-weight: 700;
    color: #303030;
    text-transform: capitalize;
  }
  & .MuiListItemText-secondary {
    font-size: 14px;
    font-weight: 400;
    color: #606060;
  }
`;

export const CListItemSecondaryAction = styled(ListItemSecondaryAction)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  right: 1.5rem;
`;

export const TimingText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  color: #303030;
`;

export const SmallText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #606060;
`;

export const NNotificationBody = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0 2rem 0;
`;

export const NotificationIcon = styled(NoNotification)`
  width: 110px;
  height: 109px;
  padding-bottom: 1rem;
`;

export const DescTypo = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  padding-bottom: .5rem
`;
