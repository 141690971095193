import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../../../providers/stateProvider';
import { SummaryPlaceHolder } from '../../shared/summaryPlaceholder';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import { DatePicker } from '../../orders/admin/dashboard/dashboard.styles';
import CStatisticCard from '../../orders/shared/cStatisticCard';
import currencyFormatter from '../../shared/currencyFormatter';
import BusinessActionTypes from '../../../providers/reducers/business/businessTypes';
import {
  StatisticsGridWrapper, TitleTextGridContainer, KeyboardArrowLeft, CIconButton, TextWrapper,
  PrimaryTitle, SecondaryTitle
} from './statisticsComponent.styles';

const StatisticsComponent = (props) => {
  const {
    statistics, querySchema, queryName, primaryTitle, secondaryTitle,
    extraCharts, backRoute, allowChainManager, filterOption
  } = props;
  const navigate = useNavigate();
  const [{
    business: {
      businessId, businessUser:
      businessUserId,
      dashboardState: {
        startDate,
        endDate,
      }
    }
  }, dispatch] = Object.values(useStateValue());
  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    dispatch({
      type: BusinessActionTypes.UPDATE_DASHBOARD_STATE,
      payload: {
        startDate: dateFrom,
        endDate: dateTo,
      }
    });
  };

  const variables = {
    dateFrom: startDate,
    dateTo: endDate,
  };
  if (allowChainManager && businessId) {
    variables.businesses = [businessId];
  }
  if (allowChainManager && businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  if (filterOption) {
    let option = false;
    if (filterOption === 'With returns') {
      option = true;
    }
    variables.filterOption = option;
  }
  const {
    data, loading, error
  } = useQuery(querySchema, {
    fetchPolicy: 'no-cache',
    variables
  });

  if (error) return `Error! ${error.message}`;
  const dashboardSummary = data ? data[queryName] : {};
  const getStatistics = (statistic) => {
    const outputStatistic = {};
    outputStatistic.value = dashboardSummary[statistic.value];
    if (statistic.type && statistic.type === 'amount') {
      outputStatistic.value = `₦ ${currencyFormatter(Number(dashboardSummary[statistic.value]))}`;
    }
    if (statistic.diff) {
      outputStatistic.diff = `${dashboardSummary[statistic.diff]}`;
    }
    return { ...statistic, ...outputStatistic };
  };
  return (
    <>
      <Grid container justifyContent="space-between">
        {backRoute && (
          <TitleTextGridContainer container item xs={12} md={5} lg={6}>
            <CIconButton onClick={() => navigate(`/${backRoute}`)}>
              <KeyboardArrowLeft />
            </CIconButton>
            <Grid item>
              <PrimaryTitle variant="h5">{primaryTitle}</PrimaryTitle>
              <SecondaryTitle variant="caption">{secondaryTitle}</SecondaryTitle>
            </Grid>
          </TitleTextGridContainer>
        )}
        {!backRoute && (
          <TextWrapper item xs={12} md={5} lg={6}>
            <PrimaryTitle variant="h5">{primaryTitle}</PrimaryTitle>
            <SecondaryTitle variant="caption">{secondaryTitle}</SecondaryTitle>
          </TextWrapper>
        )}
        <DatePicker item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            defaultRange={variables}
            xs={12}
          />
        </DatePicker>
      </Grid>
      {loading ? (
        <SummaryPlaceHolder />
      ) : (
        <StatisticsGridWrapper item container spacing={2}>
          {statistics.map((statistic) => (
            <CStatisticCard
              key={statistic.id}
              statistic={getStatistics(statistic)}
            />
          ))}
        </StatisticsGridWrapper>
      )}
      {extraCharts(dashboardSummary, startDate, endDate)}
    </>
  );
};

StatisticsComponent.propTypes = {
  statistics: PropTypes.instanceOf(Array).isRequired,
  querySchema: PropTypes.string.isRequired,
  queryName: PropTypes.string,
  primaryTitle: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string.isRequired,
  backRoute: PropTypes.string,
  filterOption: PropTypes.string,
  allowChainManager: PropTypes.bool,
  extraCharts: PropTypes.func
};

StatisticsComponent.defaultProps = {
  queryName: 'dashboardSummary',
  backRoute: null,
  filterOption: '',
  allowChainManager: false,
  extraCharts: (dashboardSummary, startDate, endDate) => <Grid />
};

export default StatisticsComponent;
