import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  MobileContainer, DetailText, HeaderText, TextBox, ColorGrid, DateText,
  ContentBox, StatusBox
} from './mobileReturnRow.styles';
import { JSONParse } from '../../../utils/json';

export default function MobileReturnRow({
  row
}) {
  const navigate = useNavigate();
  const {
    id, name, contacts, status: statuses, dateCreated, salesRepresentative
  } = row;
  const { mobile_number: mobile } = JSONParse(String(contacts));
  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');
  const handleClick = () => {
    navigate(`/manage-affiliates/${id}/details`);
  };

  const getColorGrid = (color, bkg, statusName) => (
    <StatusBox>
      <ColorGrid item container color={color} background={bkg}>
        {statusName}
      </ColorGrid>
    </StatusBox>
  );

  const getStatus = () => {
    switch (statuses) {
      case 'VERIFIED':
        return getColorGrid('#07DE10', '#DCFEDD', 'Verified');
      case 'UNVERIFIED':
        return getColorGrid('#606060', '#FBF777', 'Unverified');
      case 'SUSPENDED':
        return getColorGrid('#FA3636', '#FBDADA', 'Suspended');
      default:
        return getColorGrid('white', '#BF0505', 'N/A');
    }
  };
  return (
    <MobileContainer data-testid="routeBtn" onClick={() => handleClick()}>
      <DateText>
        {renderDate}
      </DateText>
      <hr />
      <ContentBox>
        <TextBox container>
          <HeaderText xs={6}>Affiliate name</HeaderText>
          <DetailText xs={6}>
            {name || 'N/A'}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Affiliate handler</HeaderText>
          <DetailText xs={6}>
            {salesRepresentative || 'N/A'}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Phone number</HeaderText>
          <DetailText xs={6}>
            {mobile || 'N/A'}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Status</HeaderText>
          <DetailText xs={6}>
            {getStatus()}
          </DetailText>
        </TextBox>
      </ContentBox>
    </MobileContainer>
  );
}

MobileReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

MobileReturnRow.defaultProps = {
  row: {},
};
