import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Paper, Grid, Box, Typography, Hidden, useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import MainContent from '../customComponents/mainContent';
import {
  ContentBox, TopPaper, TrackGrid, HeaderTypo, SubHeader, TrackImg, CTextField,
  TrackOrderButton, TrackOrderButtonText, TrackText, BottomPaper, EmptyBox, SearchGif, Typo,
  HeaderText, TitleText, DateText
} from './trackOrderContainer.styles';
import trackOrder from '../../assets/images/marketOrder/trackOrder.png';
import searchGif from '../../assets/images/marketOrder/search.gif';
import { GET_SINGLE_ORDER } from '../../queries/orders';
import TrackOrderStepper from './trackOrderStepper';
import { parseJson } from '../affiliates/utils';

export default function TrackOrderContainer(props) {
  const state = props?.location?.state;
  const [textInput, setTextInput] = useState('');
  const [variable, setVariable] = useState(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (state) {
      setTextInput(state.orderId);
      setVariable(state.orderId);
    }
  }, []);

  const {
    loading, error, data
  } = useQuery(GET_SINGLE_ORDER, {
    fetchPolicy: 'no-cache',
    variables: { id: variable }
  });
  let dataMeta;
  if (data?.singleOrder && !error) {
    const { meta } = data?.singleOrder ?? '';
    try {
      dataMeta = parseJson(meta.replace(/'/g, '"'));
    } catch (e) {
      return null;
    }
  }
  const { status, tracking_information } = dataMeta || {};
  const {
    date_placed, date_approved, date_sorted, date_ready_for_dispatch, date_dispatched,
    partially_fulfilled, date_completed
  } = tracking_information || {};

  const handleActive = () => {
    switch (status) {
      case 'ORDER_CLOSED':
        return setActiveStep(5);
      case 'ORDER_SENT':
        return setActiveStep(0);
      case 'ORDER_DISPATCHED':
        return setActiveStep(4);
      case 'ORDER_RECEIVED':
        return setActiveStep(1);
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return setActiveStep(3);
      case 'ORDER_SORTED':
        return setActiveStep(2);
      default:
        return setActiveStep(0);
    }
  };

  const handleTrackOrderButton = () => {
    setVariable(textInput);
  };

  const returnTextField = () => (
    <CTextField
      variant="outlined"
      placeholder="Enter Tracking Order"
      value={textInput}
      size="large"
      name="orderId"
      onChange={(event) => setTextInput(event.target.value)}
      type="number"
      fullWidth
      InputProps={{
        style: { fontSize: isSmall ? '2rem' : '.9rem', borderRadius: '.5rem', height: isSmall && '100px' }
      }}
      InputLabelProps={{
        style: { fontSize: isSmall ? '1.1rem' : '.8rem', color: '#606060' },
      }}
    />
  );

  const initialSteps = [
    {
      header: 'Order Placed',
      title: 'Your order has been placed! sit back and relax while we take care of the rest.',
      date: date_placed ? moment(date_placed).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
    {
      header: 'Order Approved',
      title: 'Congratulations! Your order has been approved and is now on its way to been sorted.',
      date: date_approved ? moment(date_approved).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
    {
      header: 'Order Sorted',
      title: 'Hi, your package sorted based on its destination and other factors such as weight, size and delivery speed.',
      date: date_sorted ? moment(date_sorted).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
    {
      header: 'Order Ready For Dispatch',
      title: 'Hi, your package will be on its way soon, its ready for delivery.',
      date: date_ready_for_dispatch ? moment(date_ready_for_dispatch).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
    {
      header: 'Order Completed',
      title: 'Hi, Your order has been confirmed and completed. Thanks for your patronage!',
      date: date_completed ? moment(date_completed).add(60, 'minutes').format('D MMMM YYYY hh:mm a') : ''
    },
  ];

  const getNextChar = (char) => String.fromCharCode(char.charCodeAt(0) + 1);
  const defaultStep = {
    header: 'Order Dispatched',
    title: 'Hi, your package has been delivered to the "recipient\'s address or a designated pickup location".',
    date: (partially_fulfilled === 'true' || partially_fulfilled === 'false') && moment(Object.values(date_dispatched)[0]).add(60, 'minutes').format('D MMMM YYYY hh:mm a'),
  };
  const handlePartialFulfilled = () => {
    if (partially_fulfilled === 'true') {
      let orderIndex = 4;
      let currentChar = 'A';
      for (let i = 0; i < Object.keys(date_dispatched).length; i++) {
        const orderDates = moment(Object.values(date_dispatched)[i]).add(60, 'minutes').format('D MMMM YYYY hh:mm a');
        const char = currentChar;
        const setValue = () => {
          const value = {
            header: `Order Batch (${char}) Dispatched`,
            title: 'Hi, your package has been delivered to the "recipient\'s address or a designated pickup location".',
            date: orderDates || ''
          };
          return value;
        };
        const nextChar = getNextChar(currentChar);
        currentChar = nextChar;
        initialSteps.splice(orderIndex, 0, setValue());
        orderIndex++;
      }
      if (status === 'ORDER_CLOSED') setActiveStep(Number(initialSteps.length) - 1);
      if (status === 'ORDER_DISPATCHED') setActiveStep(Number(initialSteps.length) - 2);
      return setSteps(initialSteps);
    }
    return initialSteps.splice(4, 0, defaultStep);
  };

  // useEffect(() => {
  //   if (data?.singleOrder && !error) {
  //     setSteps(initialSteps);
  //     handleActive(status);
  //     handlePartialFulfilled();
  //   }
  // }, [loading, data]);

  return (
    <MainContent>
      <ContentBox>
        <TopPaper container component={Paper}>
          <TrackGrid item lg={7} xs={12}>
            <HeaderTypo>Track Your Order</HeaderTypo>
            <SubHeader>Enter your tracking ID below to start tracking your orders</SubHeader>
            <TrackText>Tracking ID</TrackText>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                {returnTextField()}
              </Grid>
              <Grid item lg={5} xs={12}>
                <TrackOrderButton onClick={handleTrackOrderButton}>
                  <TrackOrderButtonText>Track Order</TrackOrderButtonText>
                </TrackOrderButton>
              </Grid>
            </Grid>
          </TrackGrid>
          <Hidden lgDown>
            <Grid item xs={5}>
              <TrackImg src={trackOrder} alt="Track Order" />
            </Grid>
          </Hidden>
        </TopPaper>

        <BottomPaper>
          {(!loading && !error) ? (
            <Box>
              <Typography
                style={{
                  fontSize: isSmall ? '2rem' : '1.1rem', fontWeight: '700', marginBottom: '.7rem'
                }}
              >
                Tracking Number
              </Typography>
              <Typography
                style={{
                  fontSize: isSmall ? '2.5rem' : '1.5rem', fontWeight: '700', marginBottom: '1rem'
                }}
              >
                {`LIFSTR 0${variable}`}
              </Typography>
              <TrackOrderStepper
                activeStep={activeStep}
                steps={steps}
              />
            </Box>
          )
            : (
              <EmptyBox>
                <SearchGif src={searchGif} alt="search" />
                <Typo>Nothing to display yet!</Typo>
                <TrackText>
                  Kindly enter your tracking ID to be able to track
                  <br />
                  your order
                </TrackText>
              </EmptyBox>
            )}
        </BottomPaper>
      </ContentBox>
    </MainContent>
  );
}

TrackOrderContainer.propTypes = {
  location: PropTypes.instanceOf(Object),
};

TrackOrderContainer.defaultProps = {
  location: {},
};
