import React, { useState, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import {
  InputAdornment, Hidden, useMediaQuery
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import filter from '../../../assets/images/marketOrder/filter.png';
import NewOrder from './newOrder';
import OrderFilter from './filters/orderFilter';
import MainContent from '../../customComponents/mainContent';
import CategoriesContainer from './categories/categoriesContainer';
import SearchIcon from '../../../assets/images/marketOrder/searchIcon.png';
import {
  OrderGridContainer, OrderSubHeader, CategoriesBox, SearchContainer, BoxGrid,
  MenuButton, ClearIcon, CategoriesTitle, SearchImage, SearchTextField, SearchButton,
  FilterImg, LeftDrawer, ProductsBox, SearchBox, ContentBox, ProductSide, FilterSide
} from './marketOrderContainer.styles';
import { useStateValue } from '../../../providers/stateProvider';
import AffiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { MARKETPLACE_CATEGORIES } from '../../../queries/categories';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MarketOrderContainer = () => {
  const priceRange = [
    {
      priceMin: '0.00',
      priceMax: '5000.00',
      displayPrice: '0 - 5,000'
    },
    {
      priceMin: '5000.00',
      priceMax: '10000.00',
      displayPrice: '5,000 - 10,000 and above'
    }
  ];

  const { pathname } = useLocation;
  const isSmall = useMediaQuery('(max-width: 991px)');

  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    searchText: '',
    searchValue: '',
    currentPage: 1,
    currentPageCount: pageCount,
    filteredSupplier: new Map(),
    suppliers: '',
    drawer: false,
    priceMin: false,
    priceMax: false,
    availabilityStatus: '',
    manufacturer: []
  };
  const [state, setState] = useState(initialState);
  const {
    searchText, drawer
  } = state;

  const {
    loading, data: categoriesList
  } = useQuery(MARKETPLACE_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });

  const categories = categoriesList?.categories || [];

  const [, dispatch] = Object.values(useStateValue());
  useLayoutEffect(() => {
    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        addAffiliateDialogOpen: true
      }
    });
    dispatch({
      type: CartActionTypes.UPDATE_IS_MANUAL,
      payload: pathname?.startsWith('/manual-order')
    });
    if (searchText && searchText.length >= 3) {
      setState({ ...state, searchValue: searchText });
    } else if (!searchText) {
      setState({ ...state, searchValue: '' });
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handlePriceFilter = (price) => {
    const { priceMin, priceMax } = state;
    if (priceMin === price.priceMin && priceMax === price.priceMax) {
      setState({ ...state, priceMin: null, priceMax: null });
      return;
    }
    setState({ ...state, priceMin: price.priceMin, priceMax: price.priceMax });
  };

  const handleBrandFilter = (brand) => {
    const { manufacturer } = state;
    if (manufacturer?.includes(brand)) {
      const brandFilter = manufacturer.filter((item) => item !== brand);
      return setState({ ...state, manufacturer: brandFilter });
    }
    return setState({ ...state, manufacturer: [...manufacturer, Number(brand)] });
  };

  const handleProductsAvailability = (variant) => {
    const { availabilityStatus } = state;
    setState({ ...state, availabilityStatus: availabilityStatus === variant ? '' : variant });
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleClearFilter = (type) => {
    switch (type) {
      case 'manufacturer':
        return setState({ ...state, manufacturer: [] });
      case 'therapeuticType':
        return setState((current) => {
          const copy = { ...current };
          delete copy.therapeuticTypeIds;
          return copy;
        });
      default:
        dispatch({ type: 'hideNavbar', payload: false });
        return setState(initialState);
    }
  };

  const toggleDrawer = (close) => {
    setState((_state) => ({ ..._state, drawer: !_state.drawer }));
    if (close) return dispatch({ type: 'hideNavbar', payload: false });
    return dispatch({ type: 'hideNavbar', payload: true });
  };

  const handleClearSearch = () => {
    setState({ ...state, searchText: '' });
  };

  const handleSuppliers = (id) => {
    setState({ ...state, suppliers: id });
  };

  const renderSearchField = (placeholder, searchValue, handleChange, ClearSearch, small = false, joyClass = 'joy-search-product') => (
    <SearchContainer className={joyClass}>
      <SearchTextField
        variant="outlined"
        label=""
        autoFocus={placeholder === 'Search product item'}
        value={searchValue}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        fullWidth
        InputProps={{
          startAdornment:
  <InputAdornment position="start" style={{ marginRight: '25px' }}>
    <SearchButton
      aria-label={placeholder}
      edge="start"
      disabled={!searchValue}
      small={small}
      onClick={ClearSearch}
    >
      {searchValue ? (
        <ClearIcon small={small} />
      ) : (
        <SearchImage alt="search icon" src={SearchIcon} small={small} />
      )}
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const [subcategories, setSubcategories] = useState([]);
  const [isSelected, setIsSelected] = useState('All Categories');
  const [displaySubCategory, setDisplaySubCategory] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleSelectCategory = (id, name) => {
    setIsSelected(name || 'All Categories');
    setSelectedId({ id, name });
    const subData = categories.find((data) => data.id === id);
    if (subData && subData.subTherapeuticType.length) {
      const ids = subData.subTherapeuticType.map((type) => type.id);
      setState({ ...state, therapeuticTypeIds: ids });
      setDisplaySubCategory(true);
      return setSubcategories(subData.subTherapeuticType);
    }
    handleClearFilter('therapeuticTypeIds');
    setDisplaySubCategory(false);
    return setSubcategories([]);
  };

  const handleSubProducts = (id) => {
    const { therapeuticTypeIds } = state;
    if (therapeuticTypeIds === id) {
      const { id: checkedId, name } = selectedId;
      handleSelectCategory(checkedId, name);
      return;
    }
    setState({ ...state, therapeuticTypeIds: id });
  };

  const renderCategories = () => {
    if (!loading) {
      return (
        <CategoriesContainer
          categories={categories}
          handleSelect={handleSelectCategory}
          isSelected={isSelected}
        />
      );
    }
    return null;
  };

  return (
    <MainContent>
      <ContentBox>
        <CategoriesBox>
          {renderCategories()}
        </CategoriesBox>
        <OrderGridContainer container>
          <LeftDrawer anchor="left" open={drawer} onClose={() => toggleDrawer()}>
            <OrderFilter
              className="order-area"
              checkedIcon={checkedIcon}
              icon={icon}
              subcategories={subcategories}
              priceRange={priceRange}
              containerState={state}
              handlePriceFilter={handlePriceFilter}
              handleSubProducts={handleSubProducts}
              handleSuppliers={handleSuppliers}
              handleClearFilter={handleClearFilter}
              handleBrandFilter={handleBrandFilter}
              displaySubCategory={displaySubCategory}
              handleProductsAvailability={handleProductsAvailability}
              open={drawer}
              handleClose={toggleDrawer}
            />
          </LeftDrawer>
          <BoxGrid container>
            <FilterSide>
              <Hidden mdDown>
                <OrderFilter
                  checkedIcon={checkedIcon}
                  icon={icon}
                  subcategories={subcategories}
                  priceRange={priceRange}
                  containerState={state}
                  handlePriceFilter={handlePriceFilter}
                  handleSubProducts={handleSubProducts}
                  handleSuppliers={handleSuppliers}
                  handleClearFilter={handleClearFilter}
                  handleBrandFilter={handleBrandFilter}
                  displaySubCategory={displaySubCategory}
                  handleProductsAvailability={handleProductsAvailability}
                  open={drawer}
                  handleClose={toggleDrawer}
                />
              </Hidden>
            </FilterSide>

            <ProductSide>
              <ProductsBox>
                <CategoriesTitle>
                  {isSelected}
                </CategoriesTitle>
                <OrderSubHeader container spacing={isSmall ? 3 : ''}>
                  <SearchBox item xs={2} md={2}>
                    <Hidden mdUp>
                      <MenuButton onClick={() => toggleDrawer()}>
                        <FilterImg src={filter} alt="filter button" />
                      </MenuButton>
                    </Hidden>
                  </SearchBox>
                  <SearchBox item lg={12} md={10} xs={10}>
                    <Hidden mdUp>
                      {renderSearchField('Search', searchText, handleSearch, handleClearSearch, false, 'joy-search-product')}
                    </Hidden>
                    <Hidden mdDown>
                      {renderSearchField('Search', searchText, handleSearch, handleClearSearch)}
                    </Hidden>
                  </SearchBox>
                </OrderSubHeader>

                <NewOrder
                  state={state}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </ProductsBox>
            </ProductSide>
          </BoxGrid>
        </OrderGridContainer>
      </ContentBox>
    </MainContent>
  );
};

export default MarketOrderContainer;
