import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-hot-toast';
import { AUTOMATIC_ORDERING_MUTATION, BULK_BUY_MUTATION, CREATE_ORDER_MUTATION } from '../../mutations/orders';
import Products from './products/products';
import Orders from './orders/orders';
import Inbounds from './inbounds/inbounds';
import MainContent from '../customComponents/mainContent';
import { EXPORT_PRODUCT_MUTATION } from '../../mutations/reports';
import Filter from './filter';
import BatchDetails from './batchDetails/batchDetails';
import {
  OrderGridContainer, TitleTextGridContainer, PrimaryTitle, TitleGrid, PaperWrapper,
  TCustomButton, ButtonDarkCustomButton,
  OutOfStockButton,
} from './inventoryContainer.styles';
import IndividualProductDialog from '../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import { useStateValue } from '../../providers/stateProvider';
import SuppliersModel from '../shared/orderDropdown/suppliersModel/suppliersModel';
import ProductButtonsPopper from './productButtonsPopper';
import TherapeuticPopper from './therapeuticPopper';
import CreateProduct from '../sales/createProduct';
import CreateBatches from '../sales/createBatches';
import TherapeuticTypes from './therapeuticTypes/therapeuticType';
import SubCategoryDialog from './therapeuticTypes/categoryDialog';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import OrderButtonsPopper from './orderButtonsPopper';
import ConfirmDialog from '../shared/confirmDialog';
import { ORDER_QUERY } from '../../queries/cart';
import { DELETE_CART_ORDER } from '../../mutations/cart';
import StoresDialog from '../shared/stores/storesDialog';
import MoreActionPopper from './moreActionPopper';
import SideBadge from './sideBadge';
import { marketplaceEnvRoute, usePreferencePermissionProductBatch } from '../../utils/funcs';
import AppToast from '../shared/appToast';
import WarningComp from './warningComp';
import OrdersList from './orderList/orders';
import MarketPlaceDialog from './orderList/marketPlaceDialog';
import { UPDATE_ORDER_LIST } from '../../mutations/products';
import SuccessDialog from '../shared/successDialog';
import { LOW_OUT_OF_STOCK_TOTAL_NUMBER } from '../../queries/products';
import ModalRedirectDialog from '../shared/modalRedirectDialog';

// confirm dialog initial state
const confirmDialogInitialState = {
  title: '',
  desc: '',
  options: [
    'No',
    'Yes'
  ]
};

const InventoryContainer = () => {
  const { tab } = useParams() || '';
  const [tabVal, setTabVal] = useState('products');
  const [openSuppliersModal, setOpenSuppliersModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedOrderList, setSelectedOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadCsvDialog, setUploadCsvDialog] = useState(false);
  const [uploadBatchesCsvDialog, setUploadBatchesDialog] = useState(false);
  const [productState, setProductState] = useState([]);
  const [select, setSelect] = useState([]);
  const [subCategoryOpenDialog, setSubCategoryOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [autoProducts, setAutoProducts] = useState([]);
  const [orderId, setOrderId] = useState('');
  const [storeConfirmDialog, setStoreConfirmDialog] = useState(false);
  const [confirmStoreStatus, setConfirmStoreStatus] = useState(false);
  const [openStoreModal, setOpenStoreModal] = useState(false);
  const [confirmDialogState, setConfirmDialogState] = useState(confirmDialogInitialState);
  const [storeIds, setStoreIds] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [successDialog, setSuccessDialog] = useState(false);
  const [successState, setSuccessState] = useState({ title: '', desc: '' });
  const [openToast, setOpenToast] = useState(false);
  const [openMarketPlace, setOpenMarketPlace] = useState(false);
  const [orderListConfirmDialog, setOrderListConfirmDialog] = useState(false);
  const [orderDeleteStatus, setOrderDeleteStatus] = useState(false);
  const [openModalRedirectDialog, setOpenModalRedirectDialog] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: '',
    description: ''
  });

  const location = useLocation();
  const marketplaceUrl = marketplaceEnvRoute();

  const [{
    cart: { isManualOrder },
    user: { businessPreference, businessId: bizId, allowedPermissionsMap: { userPermissions } },
    product: { addProductDialogOpen }
  }, dispatch] = Object.values(useStateValue());

  const allowed = usePreferencePermissionProductBatch(businessPreference);
  const navigate = useNavigate();

  const handleTabChange = (val) => {
    navigate(`/inventory/${val}`);
    setTabVal(val);
  };

  useEffect(() => {
    if (tab) setTabVal(tab);
    else navigate('/inventory/products');
  }, [tab]);

  const handleSuppliersModal = () => {
    setOpenSuppliersModal(true);
  };

  const hybrid = () => {
    if (selected.length === 1 && selected.includes('YES')) return 'YES';
    if (selected.length === 1 && selected.includes('NO')) return 'NO';
    if ((selected.length < 1) || (selected.length > 1 && selected.includes('YES') && selected.includes('NO'))) return 'ALL';
  };

  useEffect(() => { hybrid(); }, [selected]);

  const handleSelected = (e) => {
    const { value, checked } = e.target;
    const exist = selected.find((x) => x === value);
    if (checked && !exist) {
      setSelected([...selected, value]);
    }
    if (!checked && exist) {
      const newVal = selected.filter((x) => x !== value);
      setSelected(newVal);
    }
  };

  const { data: lowOnStock } = useQuery(LOW_OUT_OF_STOCK_TOTAL_NUMBER);
  const totalLowOnStock = lowOnStock?.lowOnOrOutOfStockProductsTotalNumber || 0;

  const [automaticOrdering] = useMutation(AUTOMATIC_ORDERING_MUTATION);
  const handleAutomaticOrdering = (supplierIds) => {
    setLoading(true);
    automaticOrdering({
      variables: {
        isManualOrder,
        supplierIds,
        hybrid: hybrid()
      }
    })
      .then(() => {
        setLoading(false);
        navigate('/purchase-order', { state: { autoOrder: true } });
      })
      .catch((error) => console.log(error));
  };

  const {
    status, categoryId, dateFrom, dateTo, productId, productName, supplier
  } = productState;

  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_PRODUCT_MUTATION);
  const randomNum = Math.floor(Math.random() * 10000);
  const newFileName = `inventory products ${randomNum}`;

  const variables = {
    type: 'inventory',
    name: newFileName,
    category: categoryId,
    status,
    dateFrom,
    dateTo,
    productId,
    productName,
    supplier
  };

  const handleExport = async () => {
    exportMutation({
      variables
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleCsvDialog = () => setUploadCsvDialog(!uploadCsvDialog);
  const handleBatchesCsvDialog = () => setUploadBatchesDialog(!uploadBatchesCsvDialog);

  const updateCartTotal = (currentOrder) => {
    const { supplierorderSet } = currentOrder?.order || {};

    dispatch({
      type: CartActionTypes.CART_COUNTER,
      payload: supplierorderSet
    });
  };

  const [createOrder] = useMutation(CREATE_ORDER_MUTATION, {
    onCompleted(data) {
      updateCartTotal(data?.createOrder);
    }
  });

  const cartStateUpdate = () => {
    const { pathname: pname } = location;
    const role = localStorage.getItem('oga_user_role');
    if (role === 'store-manager' || role === 'chain-manager') {
      createOrder({
        variables: { isManualOrder: true }
      });
    } else if (pname !== '/') createOrder();
  };

  const [bulkBuy] = useMutation(BULK_BUY_MUTATION);
  const handleBulkBuy = () => {
    if (!select.length) return toast.error('Select at least one product');
    const productIds = select.map((prodId) => ({
      productId: prodId,
      quantity: 1
    }));
    bulkBuy({
      variables: { productIds }
    })
      .then(({ data }) => {
        const { message } = data?.bulkBuy || {};
        cartStateUpdate();
        toast.success(message);
      });
  };

  const { data: orderData, loading: orderLoading } = useQuery(ORDER_QUERY, {
    variables: { bizId, isManualOrder: false },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!orderLoading && orderData && orderData.order) {
      const { order: { id, supplierorderSet: supplierSet } } = orderData;
      const autoProductSet = [];
      supplierSet?.forEach((result) => {
        autoProductSet.push(...result.orderproductSet);
      });
      if (autoProductSet.length > 0) setOrderId(id); setAutoProducts(autoProductSet);
    }
  }, [orderLoading, orderData]);

  if (confirmStatus) {
    navigate('/purchase-order', { state: { autoProducts } });
    setConfirmStatus(false);
  }

  const actionButtonHandler = (pref) => {
    switch (pref) {
      case 'bulk_buy': return handleBulkBuy();
      case 'add_batches_manually': return navigate('/manual-batches');
      case 'add_batches': return handleBatchesCsvDialog();
      case 'export_table': return handleExport();
      default: break;
    }
  };

  const toggleIndividualDialog = () => {
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const handleDeclineConfirmation = () => {
    if (actionType === 'individual_prod') {
      toggleIndividualDialog();
    } else {
      handleCsvDialog();
    }
    setStoreIds([]);
  };

  const handleStore = (type) => {
    if (userPermissions?.includes('cm_dashboard_enable_product_sync_to_stores')) {
      setConfirmDialogState({
        ...confirmDialogState,
        title: 'Enable Cross-Store Product Creation',
        desc: 'Clicking yes, means that the product will be created across all stores with unified pricing.',
        options: ['No, Proceed', 'Yes, Proceed']
      });
      setStoreConfirmDialog(true);
    } else {
      if (type === 'individual_prod') {
        toggleIndividualDialog();
      } else {
        handleCsvDialog();
      }
      setStoreIds([]);
    }
  };

  const productActionHandler = (action) => {
    setActionType(action);
    switch (action) {
      case 'individual_prod': return handleStore(action);
      case 'upload_order_csv':
        return handleStore(action);
      default:
        break;
    }
  };

  if (confirmStoreStatus) {
    setOpenStoreModal(true);
    setStoreConfirmDialog(false);
    // Set confirmation status to false to avoid infinite loop
    setConfirmStoreStatus(false);
    // set selected stores back to empty array
    setStoreIds([]);
  }

  const toggleSubCategory = () => setSubCategoryOpenDialog(!subCategoryOpenDialog);

  const therapeuticHandler = (action) => {
    switch (action) {
      case 'individual_prod':
        return toggleSubCategory();
      default:
        break;
    }
  };

  const productBatchPreference = (type) => {
    if (!allowed) {
      setOpenToast(true);
      if (type === 'product') {
        setToastMessage({
          title: 'Product Creation Disabled!',
          description: 'Oops! You can\'t create products at this time. Please reach out to your central store for authorization.'
        });
      } else {
        setToastMessage({
          title: 'Batch Creation Disabled!',
          description: 'Oops! You can\'t upload product batches at this time. Please reach out to your central store for authorization.'
        });
      }
      return false;
    }
    return true;
  };

  // Remove order list product(s)
  const [deleteOrderList, { loading: deleteOrderLoading }] = useMutation(UPDATE_ORDER_LIST);

  useEffect(() => {
    if (orderDeleteStatus) {
      const request = selectedOrderList.map((item) => ({ productId: Number(item), quantity: 0 }));
      deleteOrderList({
        variables: { orderListProducts: request },
        refetchQueries: ['businessOrderListProducts', 'businessOrderListProductsTotalNumber']
      }).then(() => {
        setSuccessDialog(true);
        setSuccessState({ title: 'Product(s) Removed!', desc: 'Your products has been removed successfully!' });
        setSelectedOrderList([]);
        setOrderDeleteStatus(false);
      }).catch((err) => toast.error(err.message));
    }
  }, [orderDeleteStatus]);

  const removeProductHandler = () => {
    if (!selectedOrderList.length) return toast.error('Kindly select at least one product');
    setConfirmDialogState({
      title: 'Remove Product from Order list',
      desc: 'Do you confirm that you wish to remove this product from your order list?',
      options: ['Cancel', 'Yes, Proceed']
    });
    setOrderListConfirmDialog(true);
  };

  const currentTab = () => {
    switch (tabVal) {
      case 'products':
        return <Products setProductState={setProductState} setSelected={setSelect} selected={select} productBatchPreference={productBatchPreference} />;
      case 'orders':
        return <Orders />;
      case 'batch-details':
        return <BatchDetails />;
      case 'therapeutic-types':
        return <TherapeuticTypes />;
      case 'inbound':
        return <Inbounds />;
      case 'my-order-list':
        return <OrdersList selected={selectedOrderList} setSelected={setSelectedOrderList} />;
      default:
        return <Products setProductState={setProductState} setSelected={setSelect} selected={select} productBatchPreference={productBatchPreference} />;
    }
  };

  const [deleteCart] = useMutation(DELETE_CART_ORDER);
  const handleClearCart = () => {
    deleteCart({
      variables: { orderId },
      optimisticResponse: { __typename: 'Mutation' }
    }).then(() => {
      navigate('/purchase-order');
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const actionHandler = (action) => {
    switch (action) {
      case 'create-individually':
        if (autoProducts.length > 0) return setOpenConfirmDialog(true);
        return navigate('/purchase-order');
      case 'automatic_order':
        return handleSuppliersModal();
      default:
        break;
    }
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={3}>
            <PrimaryTitle variant="h5">Inventory</PrimaryTitle>
          </TitleTextGridContainer>
          {tabVal === 'orders' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              <TCustomButton onClick={() => navigate('/manage-purchase-order')}>
                Open PO
              </TCustomButton>
              <OrderButtonsPopper actionHandler={actionHandler} autoLoading={loading} />
            </Grid>
          )}
          {tabVal === 'products' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              <MoreActionPopper actionHandler={actionButtonHandler} productBatchPreference={productBatchPreference} />
              <OutOfStockButton
                variant="outlined"
                className="erp-uat-out-of-stock"
                style={{ width: '260px' }}
                onClick={() => navigate('/low-on-stock')}
              >
                Out & Low on stock Products
                <SideBadge value={totalLowOnStock} />
              </OutOfStockButton>
              <ProductButtonsPopper actionHandler={productActionHandler} autoLoading={loading} productBatchPreference={productBatchPreference} />
            </Grid>
          )}
          {tabVal === 'therapeutic-types' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={7}>
              <TherapeuticPopper actionHandler={therapeuticHandler} autoLoading={loading} />
            </Grid>
          )}
          {tabVal === 'inbound' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              <ButtonDarkCustomButton>
                View Inbound History
              </ButtonDarkCustomButton>
            </Grid>
          )}
          {tabVal === 'my-order-list' && (
          <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9} gap={1.5}>
            <Button variant="outlined" onClick={() => navigate('/order-history')}>
              Order History
            </Button>
            <Button disabled={deleteOrderLoading} onClick={removeProductHandler} variant="outlined">
              { deleteOrderLoading ? 'loading...' : 'Remove Product(s)' }
            </Button>
            <ButtonDarkCustomButton onClick={() => setOpenMarketPlace(true)}>
              Send to Marketplace
            </ButtonDarkCustomButton>
          </Grid>
          )}
        </TitleGrid>

        {/* Warning component  */}
        <WarningComp />

        <PaperWrapper elevation={0}>
          <Filter
            tabVal={tabVal}
            handleTabChange={handleTabChange}
          />
          {currentTab()}
        </PaperWrapper>
      </OrderGridContainer>

      <CreateProduct
        openProdDialog={uploadCsvDialog}
        toggleHandler={handleCsvDialog}
        storeIds={storeIds}
      />
      <CreateBatches
        openDialog={uploadBatchesCsvDialog}
        toggleHandler={handleBatchesCsvDialog}
      />

      <IndividualProductDialog
        dialogOpen={addProductDialogOpen}
        closeDialog={() => toggleIndividualDialog()}
        storeIds={storeIds}
      />

      <SuppliersModel
        open={openSuppliersModal}
        handleClose={() => setOpenSuppliersModal(false)}
        handleAutomaticOrdering={handleAutomaticOrdering}
        handleSelected={handleSelected}
        selected={selected}
      />

      <SubCategoryDialog
        openDialog={subCategoryOpenDialog}
        handleClose={toggleSubCategory}
      />

      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="You have unsaved products on automatic ordering !"
        desc="To continue purchase order creation individually, please note that your unsaved products from automatic ordering will be added to your individual order list"
        options={['No, clear order list', 'Yes, continue']}
        setStatus={setConfirmStatus}
        loading={false}
        handleClearCart={handleClearCart}
        fromInventory
      />

      <ConfirmDialog
        openDialog={storeConfirmDialog}
        setOpenDialog={setStoreConfirmDialog}
        title={confirmDialogState.title}
        desc={confirmDialogState.desc}
        options={[confirmDialogState.options[0], confirmDialogState.options[1]]}
        setStatus={setConfirmStoreStatus}
        loading={loading}
        declineConfirmation
        declineConfirmationAction={handleDeclineConfirmation}
      />

      <ConfirmDialog
        openDialog={orderListConfirmDialog}
        setOpenDialog={setOrderListConfirmDialog}
        title={confirmDialogState.title}
        desc={confirmDialogState.desc}
        options={[confirmDialogState.options[0], confirmDialogState.options[1]]}
        setStatus={setOrderDeleteStatus}
        loading={deleteOrderLoading}
      />

      <StoresDialog
        openDialog={openStoreModal}
        setOpenDialog={setOpenStoreModal}
        storeIds={storeIds}
        setStoreIds={setStoreIds}
        toggleDialog={toggleIndividualDialog}
        actionType={actionType}
        toggleCSVDialog={handleCsvDialog}
      />

      <MarketPlaceDialog
        openDialog={openMarketPlace}
        setOpenDialog={setOpenMarketPlace}
        setOpenModalRedirectDialog={setOpenModalRedirectDialog}
      />

      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title={successState.title}
        desc={successState.desc}
        option="Ok"
      />

      <ModalRedirectDialog
        openDialog={openModalRedirectDialog}
        setOpenDialog={setOpenModalRedirectDialog}
        title="Order Sent to Marketplace!"
        desc="Your order has been successfully sent to the marketplace while a purchase order has been created for the products without a match. "
        options={['Ok', 'Redirect to marketplace', marketplaceUrl]}
      />

      <AppToast openToast={openToast} setOpenToast={setOpenToast} toastMessage={toastMessage} />

    </MainContent>
  );
};

export default InventoryContainer;
