import {
  Grid, Typography, Button, TextField,
  CircularProgress, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainGridContainer = styled(Grid)`
`;
export const BusinessSetupGridContainer = styled(Grid)`
  padding: 1rem 5rem 3rem;

  @media (min-width: 992px) {
    padding: 1rem 5rem 6rem;
  }
`;
export const InputsGridContainer = styled(BusinessSetupGridContainer)`
  flex-direction: column;
`;

export const Logo = styled('img')`
  width: 35%;
  margin: 0;

  @media (min-width: 992px) {
    width: 25%;
  }
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  // padding: .5rem 0 4rem;

  @media(min-width: 992px) {
    font-size: 2rem;
    // padding: .5rem 0;
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export const TopGrid = styled(Grid)`
    & > * {
      width: calc(50% - 0.75rem);
      margin: 0 1.5rem 1.5rem 0;

      :nth-child(2n) {
        margin-right: 0;
      }
    }
`;

export const MiddleGrid = styled(Grid)`
    & > * {
      width: 100%;
      margin: 0 0 1.5rem;
    }
`;

export const BottomGrid = styled(Grid)`
    & > * {
      width: calc(33.33% - 1rem);
      margin: 0 1.5rem 1.5rem 0;

      :last-child {
        margin-right: 0;
      }
    }
`;

export const SupplierTextField = styled(TextField)`
`;

export const ContinueButton = styled(Button)`
  height: 80px;
  width: 20rem;
  border-radius: 10px;
  background-color: #FCED50;
  float: right;

  &:hover {
    background-color: #D2C647;
  }

  @media (min-width: 992px) {
    height: 50px;
    width: 180px;
  }
`;

export const ButtonText = styled(Typography)`
  font-size: 26px;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const CheckboxContainer2 = styled(Grid)`
  padding: 0 0 2rem;
`;

export const AcknowledgeTypo = styled(Typography)`
  font-size: 1.28rem;

  @media (min-width: 992px) {
    font-size: 0.75rem;
  }
`;

export const ButtonGrid = styled(Grid)`
  justify-content: center;

  @media (min-width: 992px) {
    justify-content: flex-start;
  }
`;

export const UploadGrid = styled(Grid)`
  background-color: #E0E0E0;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const UploadMobileGrid = styled(Grid)`
  margin: 2rem 0 3rem;
`;

export const CreateButton = styled(Button)`
  background-color: #000000;
  border-radius: 10px;
  margin-top: 20px;
  font-size: .9rem;
  color: #fff;
  height: 50px;

  &:hover {
    background-color: #000000;
  }
`;

export const ContentBox = styled(Box)`
  border-radius: 15px;
  padding: 2rem;
  width: 70%;
  display: block;
  margin: auto;
`;

export const CustomField = styled(TextField)`

  & .MuiOutlinedInput-root {
    background-color: #F5F5F7;
    border-radius: 15px;
  }
  & .MuiInputBase-input {
    height: 2rem;
  }
`;
