import {
  Grid, Typography, Paper, Button, CircularProgress,
  TextField, ButtonGroup
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardPaper = styled(Paper)`
  border-radius: .4rem;
  height: 35rem;
  min-width: 55.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 3.2rem 3rem;
  margin-bottom: 20px;

  @media(min-width: 992px) {
    height: 17rem;
    min-width: 16.3rem;
    max-width: 33rem;
    padding: 1.2rem 1rem;
  }
`;

export const PromoGrid = styled(Grid)`
  margin: 10px 0;

  @media(min-width: 992px) {
    margin: 15px;
    margin-left: 0px;
  }
`;

export const PromoImg = styled('img')`
  width: 25px;
  margin-right: 10px;
  margin-bottom: -7px;

  @media(min-width: 992px) {
    width: 18px;
  }
`;

export const PromoOffer = styled(Typography)`
  font-size: 25px;
  font-weight: 500;
  color: #AF1839;

  @media(min-width: 992px) {
    font-size: 15px;
  }
`;

export const HeaderContainer = styled(Grid)`
  width: 100%;
  height: 12rem;

  @media(min-width: 992px) {
    height: 8rem;
  }
`;

export const DetailsContainer = styled(Grid)`
  width: 100%;
  height: 9.5rem;

  @media(min-width: 992px) {
    height: 6.5rem;
  }
`;

export const ActionsContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 10rem;
  justify-items: start;
  justify-content: flex-end;

  @media(min-width: 992px) {
    height: 4.5rem;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const TypoContainer = styled(Grid)`

`;

export const ImageContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const PackSizeTypo = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 500;
  color: #424242;

  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const MinQtyTypo = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;
  color: #424242;
  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const PricingAndSaving = styled(Grid)`
  margin-top: .5rem;
}
`;

export const PricingWrapper = styled(Grid)`
  border-right: 1px solid #E0E0E0;
`;

export const SavingsWrapper = styled(Grid)`
  padding-left: 1.2rem;
`;

export const PricingTypo = styled(Typography)`
  font-size: 1.7rem;
  color: #909090;

  @media(min-width: 992px) {
    font-size: .7rem;
  }
`;

export const Pricing = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  color: #3e3e3e;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const SavingPricing = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 600;
  color: #909090;

  @media(min-width: 992px) {
    font-size: .8rem;
  }
`;

export const ProductImage = styled('div')`
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

export const PromoImage = styled('div')`
  width: 25px;
`;

export const ActionButton = styled(Button)`
  font-size: 3rem;
  padding: 0 5px;
  border: 2px solid #E2E2E2;
  width: 2rem;

  &:disabled {
    color: #424242;
    border: 2px solid #E2E2E2;
    border-right: #E2E2E2;
  }

  @media(min-width: 992px) {
    font-size: 1.2rem;
    padding: 5px 13px;
    border: 1px solid #F0F0F0;
    width: 2rem;

    &:disabled {
      border: 1px solid #F0F0F0;
    }
  }
`;

export const InputButton = styled(Button)`
  font-size: 1rem;
  padding: 5px 24px;
  font-weight: 400;
  background-color: #F0F0F0;
  width: 3.7rem;

  &:disabled {
    color: #424242;
    border: 1px solid #F0F0F0;
    border-right: #F0F0F0;
  }
`;

export const InputButtonGroup = styled(ButtonGroup)`
  width: 50%;

  @media(min-width: 992px) {
    width: 60%;
    margin-right: 5%;
  }
`;

export const ActionTextField = styled(TextField)`
  background: #FFFFFF;
  & ::placeholder {
    margin-left: 8px
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
    background-color: #E2E2E2;
  }
  & .MuiOutlinedInput-input {
    padding: 15.5px 5px;
    text-align: center;
    font-size: 3rem;
    width: 11rem;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #F0F0F0;
    border-width: 0;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #F0F0F0;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #F0F0F0;
  };

  @media(min-width: 992px) {
    .MuiOutlinedInput-root {
      border-radius: 0;
      background-color: #F0F0F0;
    }
    & .MuiOutlinedInput-input {
      padding: 13px 5px;
      text-align: center;
      font-size: 1rem;
      width: 3.2rem;
    };
  }
`;

export const AddButton = styled(Button)`
    width: 50%;
    padding: 15.5px 18px;
    font-size: 2rem;
    border-radius: 8px;
    height: 5.5rem;

  &.MuiButton-contained.Mui-disabled {
    color: #424242;
    background-color: #FCE850;
    opacity: 0.5;
  }

  @media(min-width: 992px) {
    width: 35%;
    padding: 9.5px 18px;
    font-size: 0.9375rem;
    border-radius: 4px;
    height: 2.8rem;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #C5C5C5;
  margin: 1px;
`;

export const PromoText = styled('p')`
  font-size: 1.3rem;
  text-align: right;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  padding: 0 1.5%;
  border-radius: 5px;

  @media(min-width: 992px) {
    font-size: .7rem;
    top: 5px;
  }
`;

export const ProductVariantText = styled('p')`
  font-size: 1.3rem;
  text-align: right;
  margin: 0;
  padding: 0;
  color: #424242;
  padding: 0 1.5%;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 5px;
  background-color: #FCE850;

  @media(min-width: 992px) {
    font-size: .7rem;
    top: 5px;
  }
`;
