import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const sm = '960px';

export const ProductCardsContainer = styled(Grid)`
  // @media (min-width: 992px) {
  //   margin: auto;
  // }
`;

export const ProductCardGrid = styled(Grid)`
  @media (max-width: 991px) {
    margin: auto;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: center;
  margin-bottom: 30px;

  @media(min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const NoResultsText = styled(Typography)`
  font-size: 20px;
  color: #4D4F5C;
  padding: 2rem;

  @media(max-width: ${sm}) {
    font-size: 14px;
  }
`;
