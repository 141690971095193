import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomSearchField from '../../../shared/CustomSearchField';
import {
  StatusesGrid,
} from './filter.styles';

const Filter = ({
  state, handleSearch
}) => {
  const { searchText } = state ?? '';

  return (
    <Grid item container xs={12}>
      <StatusesGrid container item>
        <CustomSearchField
          name="search"
          value={searchText}
          fullWidth
          placeholder="Search for Roles"
          handleChange={(e) => handleSearch(e?.target?.value)}
        />
      </StatusesGrid>
    </Grid>
  );
};

Filter.propTypes = {
  state: PropTypes.shape(Object),
  handleSearch: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  state: {}
};

export default Filter;
