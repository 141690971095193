import React from 'react';
import { Dialog, Slide } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'qs';

import {
  GridWrapper, GridContainer, ItemWrapper, ItemTypo,
} from './reportExportModel.styles';

import { getAxios } from '../axios';
import { OgaToken } from '../graphql/token';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ReportExportModel = ({
  open, handleClose, endpoint, params,
}) => {
  const downloadData = async (url, fileType, end) => {
    const serverUrl = `${OgaToken.SERVER_LINK}`;
    const token = localStorage.getItem('oga_rest_token');
    getAxios().get(`${serverUrl}${url}/${endpoint}`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      params: {
        ...params,
      },

      paramsSerializer: (_params) => qs.stringify(_params, { arrayFormat: 'repeat' }),
      responseType: 'blob',
    })
      .then((res) => {
        const name = `${endpoint}-${moment().format('DD/MMM/YYYY')}-${moment().format('h:mm')}`;
        const filename = `${name}.${end}`;
        const blob = new Blob([res.data], { type: fileType });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log('err :>> ', err);
      });
  };

  const handleCsvData = () => {
    downloadData('download_data_export_csv', 'text/csv', 'csv');
  };

  const handlePdfData = () => {
    downloadData('download_data_export_pdf', 'application/pdf', 'pdf');
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
    >
      <div>
        <GridWrapper container item onClick={handleCsvData}>
          <GridContainer container item>
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Export to CSV
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        </GridWrapper>
      </div>

      <div>
        <GridWrapper container item onClick={handlePdfData}>
          <GridContainer container item>
            <ItemWrapper>
              <ItemTypo variant="subtitle1">Download as PDF</ItemTypo>
            </ItemWrapper>
          </GridContainer>
        </GridWrapper>
      </div>
    </Dialog>
  );
};

ReportExportModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired,
  params: PropTypes.instanceOf(Object)
};

ReportExportModel.defaultProps = {
  params: {}
};

export default ReportExportModel;
