import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
  Slide, Box, Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import success from '../../../../assets/images/Checksuccesful.gif';
import warning from '../../../../assets/images/Warning.gif';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  openConfirmation,
  handleClose,
  handleConfirm,
  loading,
  title,
  discreption,
  buttonTyle,
  imgType,
  buttonTitle
}) => (
  <Dialog
    open={openConfirmation}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <Close
        fontSize="large"
        style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
        onClick={handleClose}
      />
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
            {imgType === 'succes' ? (
              <img src={success} alt="success" width={155} />
            ) : (
              <img src={warning} alt="exclamation" width={120} />
            )}
          </Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography variant="subtitle1" style={{ paddingTop: '1rem' }}>
            {discreption}
          </Typography>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        {buttonTyle === 'single' ? (
          <Button
            fullWidth
            onClick={handleClose}
            variant="contained"
          >
            ok
          </Button>
        ) : (
          <>
            <Button
              fullWidth
              variant="contained"
              size="large"
              style={{
                padding: '1rem 2rem'
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            {loading ? (
              <Button
                disabled
                variant="contained"
                size="large"
                style={{
                  background: '#303030',
                  color: '#FFF',
                  padding: '1rem 2rem'
                }}
              >
                <Box display="flex" alignItems="center">
                  <span>Loading...</span>
                </Box>
              </Button>
            ) : (
              <Button
                fullWidth
                onClick={() => handleConfirm()}
                variant="contained"
                style={{
                  background: '#303030',
                  color: '#FFF',
                  padding: '1rem 2rem'
                }}
                size="large"
              >
                {buttonTitle || 'Yes, change Role' }
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </DialogContent>
  </Dialog>
);

ConfirmDialog.propTypes = {
  openConfirmation: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConfirmDialog;
