import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMediaQuery, Grid } from '@mui/material';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  TableColumnRowText, TableColumnRows, CustomCheckbox, ColorField, Status
} from './returnRow.styles';
import ActionPopper from './actionPopper';
import { Product } from '../../../../providers/reducers/product/classes/Product';

const ReturnRow = ({ row, rowIndx }) => {
  const {
    productName, productSupplier, batchNo, quantityReceived, uom, packSize, costPrice, expiryDate, matchStatus
  } = row;
  const isLarge = useMediaQuery('(min-width:992px)');
  const _expiryDate = moment(expiryDate).format('MM/YY');
  const { name: _name } = productSupplier;

  const renderStatus = (_status) => (
    <Grid
      item
      container
      style={{
        cursor: 'pointer'
      }}
    >
      <ColorField>
        <Status status={_status} />
        {_status}
      </ColorField>
    </Grid>
  );

  const getStatus = () => {
    switch (matchStatus) {
      case 'MATCHED':
        return renderStatus('MATCHED');
      case 'UNMATCHED':
        return renderStatus('UNMATCHED');
      default:
        return null;
    }
  };

  const cells = [
    { name: productName, width: '250px' }, { name: _name, width: '250px' }, { name: batchNo, width: '200px' },
    { name: quantityReceived, width: '200px' }, { name: uom, width: '150px' }, { name: packSize, width: '200px' },
    { name: `₦ ${currencyFormatter(costPrice)}`, width: '250px' }, { name: _expiryDate, width: '200px' }, { name: getStatus(), width: '150px' }
  ];

  const returnCell = (value) => value || '-';

  const renderBodyCells = () => cells.map(({ name, width }) => (
    <TableColumnRowText style={{ width, lineHeight: '40px' }} key={name}>{isLarge ? returnCell(name) : ''}</TableColumnRowText>
  ));

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '70px', lineHeight: '40px' }}>{renderCheckbox()}</TableColumnRowText>
      <TableColumnRowText style={{ width: '70px', lineHeight: '40px' }}>{rowIndx + 1}</TableColumnRowText>
      {renderBodyCells()}
      <TableColumnRowText style={{ width: '120px', lineHeight: '40px' }}>
        <ActionPopper />
      </TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  rowIndx: PropTypes.number.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
