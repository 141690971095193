import { gql } from '@apollo/client';

export const BATCH_QUERY = gql`
  query manualBatchProducts {
    manualBatchProducts {
      product{
        id
        sku
        brandName
        erpVersion{
          unitOfMeasurement
          noOfUom
          sellingPrice
          fixedSellingPrice
          category {
            id
            categoryName
          }
        }
      }
      manualBatchMeta {
        sellingPrice
        categoryName
      }
      batchNo
      dateRecieved
      expiryDate
      orderCost
      uom
      quantityInStock
      quantityRecieved
      supplier {
        id
        name
      }
    }
  }
`;
