import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import { RenderPopper } from '../../../utils/utils';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  ProductItem, SearchPaper, ValueText, ProdName, PackSizeGrid
} from './searchPopper.styles';

const SearchPopper = ({
  searchFieldEl, handleClose, open, searchedProducts,
  handleBatchSelection
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <RenderPopper
      open={open}
      anchorEl={searchFieldEl}
      onClickAway={handleClose}
      popperPlacement="bottom-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '-3vw, 1vh',
        }
      }}
    >
      <SearchPaper elevation={0}>
        {searchedProducts?.map((prod) => (
          <ProductItem key={prod} container onClick={() => handleBatchSelection(prod)}>
            {!isSmall ? (
              <>
                <Grid item md={8}>
                  <ProdName variant="caption">{prod.brandName}</ProdName>
                  <PackSizeGrid item container>
                    <span style={{ marginRight: 15 }}>
                      {`Pack Size: ${prod.packSize}`}
                    </span>
                    {/*! !prod.quantityInStock && (
      <span>{`Qty in Stock: ${prod.quantityInStock}`}</span>
    ) */}
                  </PackSizeGrid>
                </Grid>
                <Grid style={{ textAlign: 'right' }} item md={4}>
                  <ValueText variant="caption">
                    {`₦ ${currencyFormatter(prod.itemPrice)}`}
                  </ValueText>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                  <ProdName variant="caption" item xs={6} style={{ fontSize: '25px', width: '80%' }}>
                    {prod.brandName}
                  </ProdName>
                  <ProdName variant="caption" item xs={4} style={{ textAlign: 'right', fontSize: '25px' }}>{`₦ ${currencyFormatter(prod.itemPrice)}`}</ProdName>
                </Grid>
                <PackSizeGrid item container>
                  <span style={{ marginRight: 15, fontSize: '20px', fontWeight: '700' }}>
                    {`Pack Size: ${prod.packSize}`}
                  </span>
                </PackSizeGrid>
              </Grid>
            )}
          </ProductItem>
        ))}
      </SearchPaper>
    </RenderPopper>
  );
};

SearchPopper.propTypes = {
  open: PropTypes.bool,
  searchFieldEl: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  searchedProducts: PropTypes.instanceOf(Array).isRequired,
  handleBatchSelection: PropTypes.func.isRequired
};

SearchPopper.defaultProps = {
  open: false
};

export default SearchPopper;
