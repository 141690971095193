import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import { InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import toast from 'react-hot-toast';
import CustomButton from '../../../customComponents/customButton';
import { CaretUpIcon, CaretDownIcon } from '../../../../assets/svgs';
import {
  BatchDialog, DialogWrapper, SubText, ProductName, DialogHeader, DialogBody,
  HeaderGrid, BatchItems, CustomCheckbox, CustomInputBase, ColumnHeader, Details, ItemGrid
} from './selectBatchDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

export default function SelectBatchDialog({
  open, batches, toggleBatchDialog, product, handleBatchSelection, selectedBatches, qtyConfirmed
}) {
  const [batchItem, setBatchItem] = useState([]);
  const updatedQuantity = (batchId) => {
    const qtyToSell = selectedBatches?.find((item) => item.batch_id === Number(batchId))?.qty_to_sell;
    return qtyToSell ? parseInt(qtyToSell, 10) : 0;
  };

  const handleSelect = (batchId) => {
    if (batchItem.some((item) => item.batchId === batchId)) {
      const newBatchItems = batchItem.filter((item) => item.batchId !== batchId);
      setBatchItem(newBatchItems);
    } else {
      const newBatchItems = [...batchItem, { batchId, qtyToSell: 0 }];
      setBatchItem(newBatchItems);
    }
  };

  const totalQtyToSell = batchItem?.reduce((accumulator, currentValue) => accumulator + currentValue.qtyToSell, 0);
  const handleChange = (e, batchId) => {
    if (totalQtyToSell >= qtyConfirmed) return toast.error('Quantity Received cannot be more than Quantity Confirmed');
    const newBatchItem = { batchId, qtyToSell: e?.target.value };
    const index = batchItem.findIndex((item) => item.batchId === batchId);

    if (index === -1) {
      setBatchItem((prevBatchItem) => [...prevBatchItem, newBatchItem]);
    } else {
      const updatedBatchItem = [...batchItem];
      updatedBatchItem[index] = newBatchItem;
      setBatchItem(updatedBatchItem);
    }
  };
  const handleIncrement = (batchId) => {
    const existingItem = selectedBatches?.find((item) => item.batch_id === Number(batchId));
    if (totalQtyToSell >= qtyConfirmed) return toast.error('Quantity Received cannot be more than Quantity Confirmed');
    let newValue = 0;

    if (existingItem) {
      newValue = existingItem?.qty_to_sell;
    }
    const index = batchItem?.findIndex((item) => item.batchId === batchId);
    if (index === -1) {
      setBatchItem((prevBatchItem) => [...prevBatchItem, { batchId, qtyToSell: newValue + 1 }]);
    } else {
      const updatedBatchItem = [...batchItem];
      const updatedBatch = { ...updatedBatchItem[index] };
      updatedBatch.qtyToSell += 1;
      updatedBatchItem[index] = updatedBatch;
      setBatchItem(updatedBatchItem);
    }
  };

  const handleDecrement = (batchId) => {
    const existingItem = selectedBatches?.find((item) => item.batch_id === Number(batchId));
    let newValue = 1;
    if (existingItem) {
      newValue = existingItem?.qty_to_sell;
    }
    const index = batchItem?.findIndex((item) => item.batchId === batchId);
    if (index === -1) {
      setBatchItem((prevBatchItem) => [...prevBatchItem, { batchId, qtyToSell: newValue - 1 }]);
    } else {
      const updatedBatchItem = [...batchItem];
      const updatedBatch = { ...updatedBatchItem[index] };
      updatedBatch.qtyToSell -= 1;
      updatedBatchItem[index] = updatedBatch;
      setBatchItem(updatedBatchItem);
    }
  };

  const handleDone = () => {
    handleBatchSelection(product?.id, batchItem);
    toggleBatchDialog();
  };

  const value = (batchId) => batchItem.find((item) => item.batchId === batchId)?.qtyToSell || '';

  const renderBatchItems = () => {
    const batchList = [];
    batches?.forEach((batch) => {
      batchList.push(batch);
    });
    return (
      <DialogBody item container>
        {batchList?.map((batch) => (
          <BatchItems key={batch.orderProductId} item container>
            <ItemGrid item xs={1}>
              <CustomCheckbox
                size="small"
                onClick={() => handleSelect(batch.id)}
                name="terms"
                checked={batch.checked}
              />
            </ItemGrid>
            <ItemGrid item xs={3}>
              <ColumnHeader>Batch #</ColumnHeader>
              <Details>{batch?.batchNo}</Details>
            </ItemGrid>
            <ItemGrid item xs={2}>
              <ColumnHeader>Expiry Date</ColumnHeader>
              <Details>{moment(batch?.expiryDate).format('YYYY-MM-DD')}</Details>
            </ItemGrid>
            <ItemGrid item xs={2}>
              <ColumnHeader>Qty in Stock</ColumnHeader>
              <Details>{batch?.quantityInStock}</Details>
            </ItemGrid>
            <ItemGrid item xs={2}>
              <ColumnHeader>Qty Sent</ColumnHeader>
              <Details>
                <CustomInputBase
                  type="number"
                  placeholder="0"
                  min={1}
                  size="medium"
                  value={Number(value(batch?.id)) || updatedQuantity(batch?.id)}
                  onChange={(e) => handleChange(e, batch?.id)}
                  endAdornment={(
                    <InputAdornment position="end">
                      <Grid item container direction="column">
                        <CaretUpIcon
                          style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                          onClick={() => handleIncrement(batch?.id)}
                        />
                        <CaretDownIcon
                          style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                          onClick={() => handleDecrement(batch?.id)}
                        />
                      </Grid>
                    </InputAdornment>
                  )}
                />
              </Details>
            </ItemGrid>
            <ItemGrid item xs={2}>
              <ColumnHeader>Order cost</ColumnHeader>
              <Details>{batch?.orderCost}</Details>
            </ItemGrid>
          </BatchItems>
        ))}
      </DialogBody>
    );
  };
  return (
    <BatchDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleBatchDialog}
      maxWidth="sm"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <HeaderGrid item xs={6}>
            <ProductName item xs={12}>{product?.brandName}</ProductName>
            <SubText item xs={12} variant="caption">
              Pack Size:
              &nbsp;
              {product?.packSize}
            </SubText>
          </HeaderGrid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CustomButton
              type="tertiary"
              header
              onClick={handleDone}
              style={{ width: '9rem', height: '3.2rem' }}
            >
              Continue
            </CustomButton>
            {/* <CloseIcon onClick={toggleBatchDialog} /> */}
          </Grid>
        </DialogHeader>
        {renderBatchItems()}
      </DialogWrapper>
    </BatchDialog>
  );
}

SelectBatchDialog.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
  batches: PropTypes.instanceOf(Array).isRequired,
  toggleBatchDialog: PropTypes.func.isRequired,
  handleBatchSelection: PropTypes.func.isRequired,
  selectedBatches: PropTypes.instanceOf(Array).isRequired,
  qtyConfirmed: PropTypes.instanceOf(Number).isRequired,
};
