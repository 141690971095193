import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { useQuery } from '@apollo/client';
// import dayjs from 'dayjs';
import moment from 'moment';
import { TOP_SELLING_PRODUCTS } from '../../../queries/dashboard';
import TableLoader from '../../shared/tableLoader';
import TablePagination from '../../shared/tablePagination';
import { numberFormater } from '../../shared/utils';
import ActionButton from './actionButton';
import {
  TableHeadStyled, TableRowStyled, TableCellStyled,
  TableCellBStyled, BoxStyled, UnderlineGrid, Typo
} from '../dashboardContainer.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';

const ProductTable = () => {
  // Begin pagination props states
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [sort, setSort] = useState('value');
  const [top, setTop] = useState(true);
  const [currentDisplay, setCurrentDisplay] = useState('top-moving');
  const startDate = moment().startOf('day');
  const endDate = moment().endOf('day');

  const [dateFilter, setDateFilter] = useState({
    dateFrom: startDate,
    dateTo: endDate
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDateFilter({
      dateFrom,
      dateTo
    });
  };
  // End pagination props states

  const { dateFrom, dateTo } = dateFilter;

  // sort = value or count

  const { loading, error, data } = useQuery(TOP_SELLING_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageNumber,
      pageCount,
      dateFrom,
      dateTo,
      sort,
      top
    }
  });

  const sortHandler = (val, setOpen) => {
    setSort(val);
    setOpen(false);
  };

  const tableOption = [
    { label: 'Top Moving', width: '11%', type: 'top-moving' },
    { label: 'Slow Moving', width: '11%', type: 'slow-moving' },
  ];
  const totalRecord = data?.topSellingProductsTotalNumber || 0;

  const handleOptionClick = (type) => {
    setTop(!top);
    setCurrentDisplay(type);
  };

  return (
    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
      <Grid item xl={12}>
        <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={8}>
              <Box fontWeight="fontWeightBold" fontSize={18} color="inherit">Product Sales</Box>
            </Grid>
            <Grid item container xs={3.5} justifyContent="flex-end">
              <Grid item xs={8}>
                <CustomDateRanges handleSubmit={handleSubmit} styles={{ height: '48px' }} />
              </Grid>
              <Grid item xs={3} fontSize={16} color="inherit">
                <ActionButton sortHandler={sortHandler} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} style={{ marginTop: '1rem' }}>
            {tableOption.map(({ label, width, type }) => (
              <UnderlineGrid
                className={`erp-uat-${type}`}
                key={label}
                item
                onClick={() => handleOptionClick(type)}
                active={type === currentDisplay}
                width={width}
              >
                <Typo active={type === currentDisplay}>{label}</Typo>
              </UnderlineGrid>
            ))}
            <UnderlineGrid item width="78%">
              <Typo />
            </UnderlineGrid>
          </Grid>

          {loading || error ? (
            <TableLoader rows={5} />
          ) : (
            <>
              <TableContainer style={{ marginTop: '2rem' }}>
                <Table>
                  <TableHeadStyled>
                    <TableRow>
                      {/* <TableCellStyled>Created Date</TableCellStyled> */}
                      <TableCellStyled>Product Name</TableCellStyled>
                      <TableCellStyled>Unit Price</TableCellStyled>
                      <TableCellStyled>Pack Size</TableCellStyled>
                      <TableCellStyled>Total Value</TableCellStyled>
                      <TableCellStyled>Qty In Stock</TableCellStyled>
                      <TableCellStyled>Qty Sold In Pcks</TableCellStyled>
                    </TableRow>
                  </TableHeadStyled>
                  <TableBody>
                    {data?.topSellingProducts.length > 0 ? (
                      data?.topSellingProducts?.map((product, index) => (
                        <TableRowStyled
                          cond={index % 2 !== 0}
                          key={product.createdDate}
                        >
                          {/* const createdate = new Date(product.createdDate); */}

                          {/* <TableCellBStyled>
                              {dayjs(createdate).format('DD MM, YY h:mm A')}
                            </TableCellBStyled> */}
                          <TableCellBStyled>{product?.brandName}</TableCellBStyled>
                          <TableCellBStyled>
                            NGN
                            {' '}
                            {` ${numberFormater(product?.price)}`}
                          </TableCellBStyled>
                          <TableCellBStyled>{product?.packSize}</TableCellBStyled>
                          <TableCellBStyled>
                            NGN
                            {' '}
                            {` ${numberFormater(product.averageSellingPrice * parseInt(product?.frequency))}`}
                          </TableCellBStyled>
                          <TableCellBStyled>{product?.qtyInStock}</TableCellBStyled>
                          <TableCellBStyled>{product?.qtySold}</TableCellBStyled>
                        </TableRowStyled>
                      ))

                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>No Product Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box style={{ marginTop: '1.5rem' }}>
                {data?.topSellingProducts.length > 0 && (
                  <TablePagination
                    total={totalRecord}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                )}
              </Box>
            </>
          )}
        </BoxStyled>
      </Grid>
    </Grid>
  );
};

export default ProductTable;
