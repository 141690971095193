import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { IconWrapper, LeftContainer } from './storesDialog.styles';
import { DrugIcon } from '../../../assets/svgs';
import { CustomCheckbox } from '../../inventory/products/products.styles';

const ReturnRow = ({ row, storeIds, handleSelect }) => {
  const { id, name } = row;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '0.7rem'
      }}
    >
      <LeftContainer>
        <IconWrapper>
          <DrugIcon />
        </IconWrapper>
        {name}
      </LeftContainer>
      <CustomCheckbox
        size="small"
        inputProps={{ 'aria-label': 'select outlet' }}
        sx={{ color: '#78AADA' }}
        value={id}
        checked={storeIds.includes(id)}
        onChange={() => handleSelect(id)}
      />
    </Box>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Object),
  storeIds: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func
};

ReturnRow.defaultProps = {
  row: {},
  storeIds: [],
  handleSelect: () => null
};

export default ReturnRow;
