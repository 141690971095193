import { styled } from '@mui/material/styles';
import {
  TableCell, Box, Typography, Paper, Button, Grid
} from '@mui/material';

export const ExportButton = styled(Button)`
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e7e8e9;
  padding: 10px;
  width: 7rem;
  height: 3rem;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
`;

export const GraphTypo = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  color: #303030;
`;

export const LineBox = styled(Box)`
  text-align: right;
  height: 90%;
  width: ${({ width }) => (width)};
`;

export const DataBox = styled(Box)`
  height: 90%;
  width: 100%;
  overflow: scroll;
  margin-top: 1rem;

  ::-webkit-scrollbar {
    height: 83%;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    background: #f3f9ff;
  }
  ::-webkit-scrollbar-thumb {
    background: #235a91;
  }
`;

export const PaperGrid = styled(Paper)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  width: 100%;
  border-radius: 12px;
  height: 30rem;
  padding: 1rem 1rem 2rem;
  overflow: ${({ filter }) => filter && 'scroll hidden'};

  ::-webkit-scrollbar {
    width: 100%;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    background: #f3f9ff;
  }

  ::-webkit-scrollbar-thumb {
    background: #235a91;
  }
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #F3F9FF;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const TableColumnHeaders = styled(Grid)`
  margin-top: 1rem;
  background: #F3F9FF;
`;

export const TableBodyComponent = styled(Grid)`
  height: 83%;
  overflow: scroll;

  ::-webkit-scrollbar {
    height: 83%;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    background: #f3f9ff;
  }
  ::-webkit-scrollbar-thumb {
    background: #235a91;
  }
`;

export const TableColumnRows = styled(Grid)`

  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;
