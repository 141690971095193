import React from 'react';
import PropTypes from 'prop-types';
import { BodyCell, MainTableRow } from './returnRow.styles';

const ReturnRow = ({ dispErr }) => {
  const {
    product_id: PID, product_name: PName, quantity_available: QAvailable,
    quantity_requested: QRequested
  } = dispErr;

  const orderCells = [PID, PName, QRequested, QAvailable];

  const renderBodyCells = () => orderCells.map((cell) => (
    <BodyCell key={cell}>
      {cell}
    </BodyCell>
  ));

  return (
    <MainTableRow>{renderBodyCells()}</MainTableRow>
  );
};

ReturnRow.propTypes = {
  dispErr: PropTypes.shape(Object),
};

ReturnRow.defaultProps = {
  dispErr: {},
};

export default ReturnRow;
