import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Popover } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Grid from '@mui/material/Grid';
import {
  GridWrapper, InfoText, ItemTypo, ItemTypoBox
} from './filterPopper.styles';

const filterPopper = ({
  columns, open, anchorEl, handleOptionToggle,
  handleClose, selectedOptionFilter
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (

    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <GridWrapper container>
        <InfoText>Filter by columns</InfoText>
        {
            columns?.map((column) => (
              <ItemTypoBox onClick={() => handleOptionToggle(column)} item container direction="row" alignItems="center">
                <Grid item xs={1}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ color: '#235A91' }}
                    checked={!selectedOptionFilter.get(column)}
                  />
                </Grid>
                <ItemTypo item xs={11}>
                  {column}
                </ItemTypo>
              </ItemTypoBox>
            ))
          }
      </GridWrapper>
    </Popover>
  );
};

filterPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.bool.isRequired,
  filterButton: PropTypes.instanceOf(Object),
  handleClose: PropTypes.func.isRequired,
  handleOptionToggle: PropTypes.func.isRequired,
};

filterPopper.defaultProps = {
  filterButton: {},
};

export default filterPopper;
