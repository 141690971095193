const StockTransfer = {
  transferItems: [],
  transferLocation: '',
  inheritConfig: false,
  transferDetails: {},
  receivedSelectedItems: [],
  transferredData: {}
};

export default StockTransfer;
