import {
  Grid,
  Typography,
  Paper,
  Divider,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';

const SoldOutStyles = css`
  background-color: #fbf777;
  color: #606060;
`;

const activeStyles = css`
  background-color: #dcfedd;
  color: #07de10;
`;

const inactiveStyles = css`
  background-color: #fbdada;
  color: #fa3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'active':
      return activeStyles;
    case 'inactive':
      return inactiveStyles;
    default:
      return SoldOutStyles;
  }
};

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  background: ${({ active }) => (active ? '#00B588' : '#FF0F0F')};
  border-radius: 50%;
  width: 9px;
  height: 9px;
`;

export const ColorGrid = styled(Grid)`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: 0.85rem;
  font-weight: 500;
  justify-content: center;
  padding: .7rem .5rem;

  ${getStyles}
`;

export const HeaderWrapper = styled(Grid)`
  margin-bottom: 0.1rem;

  @media (min-width: 992px) {
  }
`;

export const NameGrid = styled(Grid)``;

export const ImgContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
`;

export const CardTitle = styled('span')`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 1rem;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #F3F9FF;
`;

export const TableRowStyled = styled(TableRow)`
  overflow-x: scroll;
`;

export const TableCellStyled = styled(TableCell)`
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  border-bottom: none;
`;
export const TableCellBodyStyled = styled(TableCell)`
  white-space: nowrap;
  font-size: 15px;
  border-bottom: none;
`;
export const ContactGrid = styled(Grid)``;

export const SupplierPaper = styled(Paper)`
  position: relative;
  border-radius: 8px;
  padding-bottom: 15px;
  min-height: 22.3rem;

  @media (min-width: 992px) {
    border-radius: 6px;
  }
`;

export const ContactPaper = styled(Paper)`
  border-radius: 8px;
  padding: 18px 30px;
  height: 29rem;
  shadow: none;

  @media (min-width: 992px) {
    border-radius: 6px;
    height: 22.3rem;
  }
`;

export const SubHeaderText = styled(Typography)`
  color: #a3a3a3;
  padding: 18px 20px;

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }
`;

export const ContactHeaderText = styled(Typography)`
  font-weight: 600;
  padding-bottom: 14px;

  @media (max-width: 991px) {
    font-size: 1.6rem;
  }
`;

export const LightDivider = styled(Divider)`
  color: #f0f0f0;
`;

export const BottomContainer = styled(Grid)`
  margin: 8rem 3rem 0;

  @media (min-width: 992px) {
    margin: 4.5rem 3rem 0;
  }
`;

export const ItemImage = styled('img')`
  width: 12rem;
  position: absolute;
  top: 5px;
  left: 2rem;

  @media (min-width: 992px) {
    width: 8rem;
  }
`;
export const ContainerFlex = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
`;

export const NormalText = styled(Typography)`
  font-size: 20px;
  margin-bottom: 10px;

  @media (min-width: 992px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const NormalText2 = styled(Typography)`
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 17px;
    margin-bottom: 20px;
  }
`;

export const NameText = styled(Typography)`
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 600;

  @media (min-width: 992px) {
    font-size: 25px;
    margin-bottom: 20px;
  }
`;

export const LowerGrids = styled(Grid)`
  align-items: center;
  margin-bottom: 10px;
`;

export const LowerLighterText = styled(Typography)`
  margin: 0 10px;
  color: #a3a3a3;

  @media (max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const LowerLighter2Text = styled(Typography)`
  margin-left: 10px;
  margin-right: 26px;
  color: #a3a3a3;

  @media (max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const LowerDarkerText = styled(Typography)`
  margin-left: 5px;
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const IconImage = styled('img')`
  height: 55px;

  @media (min-width: 992px) {
    height: 30px;
  }
`;

export const ContactsGrid = styled(Grid)`
  margin-top: 15px;
`;

export const SmallHeading = styled(Typography)`
  color: #a3a3a3;
  font-size: 1rem;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;
