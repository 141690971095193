export const validateEmail = (email) => {
  const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,3}$/;
  if (regex.test(email)) return false;
  return true;
};

export const validateMobile = (phone) => {
  const regex = /^\d{9,16}$/;
  if (regex.test(phone)) return false;
  return true;
};

export const validatePassword = (password) => {
  const regex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
  const regexTest = regex.test(password);
  if (regexTest) return false;
  return true;
};

export const validateNigerianNumber = (phone) => {
  const regex = /^(?:(?:(?:\+?234(?:\h1)?|01)\h*)?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/;
  if (regex.test(phone)) return false;
  return true;
};
