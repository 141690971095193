import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Paper, TableRow, TableBody, useMediaQuery, Box, Grid
} from '@mui/material';
import moment from 'moment';
import CTablePagination from '../../customComponents/cTablePagination';
import { AFFILIATE_QUERY } from '../../../queries/affiliates';
import {
  TableContainerWrapper, TableComponent, ErpHeader, FooterGrid,
  MainTableHead, MobileContainer, FilterBox, FilterImg, TitleHeader
} from './affiliates.styles';
import ReturnRow from './returnRow';
import AffiliateFilter from './affiliateFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import affiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import MobileReturnRow from './mobileReturnRow';
import { useStateValue } from '../../../providers/stateProvider';
import filterImg from '../../../assets/images/marketOrder/filter-search.png';
import { addHyphen } from '../../shared/methods';

const headers = [
  'created date', 'Affiliate name', 'account handler', 'Email Address', 'phone number', 'status',
];

const Affiliates = () => {
  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);
  const [{ affiliate: { affiliateStatus } }, dispatch] = Object.values(useStateValue());

  const isSmall = useMediaQuery('(max-width: 991px)');

  const initialState = {
    currentPage: 1,
    currentPageCount: 20,
    phoneNumber: '',
    status: affiliateStatus || '',
    affiliateName: '',
    setStatus: affiliateStatus || '',
    dateFrom: '',
    dateTo: '',
    filters: {},
    email: ''
  };

  const [state, setState] = useState(initialState);
  const [selected, setSelected] = useState([]);
  // const [stateRows, setStateRows] = useState([]);

  const {
    currentPage, currentPageCount, phoneNumber, status,
    affiliateName, dateFrom, dateTo, filters, setStatus, email
  } = state;

  const updateAffiliateState = (affiliates, affiliatesCount, refetch) => {
    dispatch({
      type: affiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        affiliates,
        affiliatesCount,
        refetch,
        affiliateStatus: ''
      }
    });
  };

  const handleSetFilter = () => {
    setState({
      ...state,
      setStatus: status,
      filters: {
        phoneNumber, name: affiliateName, dateFrom, dateTo,
        email
      }
    });
  };

  const handleClearFilter = () => {
    setState(initialState);
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     const newSelections = stateRows.map((product) => product.id);
  //     return setSelected(newSelections);
  //   }
  //   return setSelected([]);
  // };

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const renderCheckbox = () => (
  //   <CustomCheckbox
  //     size="small"
  //     checked={selected.length === stateRows.length}
  //     onChange={handleSelectAll}
  //     inputProps={{ 'aria-label': 'select product' }}
  //   />
  // );

  const returnHeaders = () => headers.map((header) => (
    <ErpHeader className={`mpAdmin-uat-manageAffiliatePage-${addHyphen(header)}`} key={header}>{header}</ErpHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };
  const handleDateFilter = (dateRange) => {
    const { dateFrom: _dateFrom, dateTo: _dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(_dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(_dateTo).format('YYYY-MM-DD')
    }));
  };
  const variables = {
    pageCount: currentPageCount,
    pageNumber: currentPage,
    status: setStatus,
    ...filters
  };

  const {
    loading, error, data, refetch
  } = useQuery(AFFILIATE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.allBusinesses) {
      const { allBusinesses, businessTotalNumber } = data;
      // setStateRows(allBusinesses);
      updateAffiliateState(allBusinesses, businessTotalNumber, refetch);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    allBusinesses = [], businessTotalNumber = 0,
  } = data || {};

  const handleMobileFilter = () => {
    setDisplayMobileFilter(!displayMobileFilter);
  };

  return (
    <>
      {!displayMobileFilter && (
        <Box sx={{ width: '100%' }}>
          {!isSmall ? (
            <TableContainerWrapper component={Paper}>
              <TitleHeader>Manage affiliates</TitleHeader>
              <Grid container xs={12}>
                {/* <Grid item xs={3}></Grid> */}
                <Grid item xs={12} style={{ marginLeft: 'auto' }}>
                  <AffiliateFilter
                    state={state}
                    selected={selected}
                    handleFilterChange={handleFilterChange}
                    handleSetFilter={handleSetFilter}
                    handleDateFilter={handleDateFilter}
                  />
                </Grid>
              </Grid>
              {loading ? <ProductsTableLoader /> : (
                <TableComponent aria-label="affiliates table">
                  <MainTableHead>
                    <TableRow>
                      <ErpHeader className="mpAdmin-uat-manageAffiliatePage-created-date" />
                      {/* <ErpHeader>{renderCheckbox()}</ErpHeader> */}
                      {returnHeaders()}
                    </TableRow>
                  </MainTableHead>
                  <TableBody>
                    {allBusinesses.map((business, index) => (
                      <ReturnRow
                        key={business.id}
                        row={business}
                        selected={selected}
                        handleSelect={handleSelect}
                        index={index}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
            </TableContainerWrapper>
          )
            : (
              <MobileContainer>
                <FilterBox>
                  <FilterImg src={filterImg} alt="filter" onClick={handleMobileFilter} />
                </FilterBox>
                {loading ? <ProductsTableLoader /> : (
                  allBusinesses.map((business, index) => (
                    <MobileReturnRow
                      key={business.id}
                      row={business}
                      index={index}
                      selected={selected}
                      handleSelect={handleSelect}
                    />
                  ))
                )}
              </MobileContainer>
            )}
          <FooterGrid item container>
            <CTablePagination
              rowsPerPageOptions={isSmall ? [100] : [20, 50, 100, 150, 200]}
              component="div"
              count={businessTotalNumber}
              page={currentPage - 1}
              onChangePage={handleChangePage}
              rowsPerPage={currentPageCount}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </FooterGrid>
        </Box>
      )}
      {displayMobileFilter && (
        <AffiliateFilter
          state={state}
          selected={selected}
          handleFilterChange={handleFilterChange}
          handleSetFilter={handleSetFilter}
          handleDateFilter={handleDateFilter}
          mobile={displayMobileFilter}
          handleMobile={handleMobileFilter}
          handleClear={handleClearFilter}
        />
      )}
    </>
  );
};

export default Affiliates;
