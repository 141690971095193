import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Paper, TableContainer, Table, TableRow, TableBody, TableHead, useMediaQuery
} from '@mui/material';
import MainContent from '../customComponents/mainContent';
import { AFFILIATE_INVOICE_QUERY } from '../../queries/affiliates';
import {
  PaymentText, ContentBox, HeaderCell, TableBox, BackgroundBox, DetailBox,
  FooterGrid, MobileContainer, FilterBox, FilterImg
} from './paymentsContainer.styles';
import CTablePagination from '../customComponents/cTablePagination';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import PaymentsFilter from './filter/paymentsFilter';
import filterImg from '../../assets/images/marketOrder/filter-search.png';
import MobileReturnRow from './mobileReturnRow';
import MobileFilter from './mobileFilter';

const PaymentsContainer = () => {
  const headers = [
    'Affiliate name', 'Order Number', 'Payment Status', 'Invoice', 'Amount Paid', 'Invoice Number', 'Credit days'
  ];
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const isSmall = useMediaQuery('(max-width: 991px)');

  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
  };

  const initialFilter = {
    invoiceNo: null,
    orderNo: null,
    affiliateName: '',
    status: ''
  };

  const [state, setState] = useState(initialState);
  const [filters, setFilters] = useState(initialFilter);

  const {
    currentPage, currentPageCount, orderNo, invoiceNo, affiliateName, status
  } = state;

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (!value && [name] in filters) {
      setFilters((_state) => {
        const copy = { ..._state };
        delete copy[name];
        return copy;
      });
      return;
    }
    setFilters((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount: currentPageCount,
    pageNumber: currentPage,
    status,
    orderNo,
    invoiceNo,
    affiliateName
  };

  const {
    loading, error, data
  } = useQuery(AFFILIATE_INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  const handleSetfilter = () => {
    setState({ ...initialState, ...filters });
  };

  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);

  const handleMobileFilter = () => {
    setDisplayMobileFilter(!displayMobileFilter);
  };

  const handleClearFilter = () => {
    setState(initialState);
    setFilters(initialFilter);
  };

  const affiliateInvoices = data?.affiliateInvoices || [];
  const invoicesTotalNumber = data?.invoicesTotalNumber || 0;

  if (error) return <div>{error.message}</div>;

  return (
    <MainContent>
      <ContentBox>
        {!isSmall && (
          <BackgroundBox>
            <DetailBox>
              <PaymentsFilter
                handleFilterChange={handleFilterChange}
                handleSetFilter={handleSetfilter}
              />
              <PaymentText> Payments </PaymentText>
              {loading ? <ProductsTableLoader /> : (
                <TableBox>
                  <TableContainer component={Paper}>
                    <Table aria-label="payments table">
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <HeaderCell>{header}</HeaderCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {affiliateInvoices.map((paymentItem) => (
                          <ReturnRow
                            key={paymentItem?.id}
                            paymentItem={paymentItem}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TableBox>
              )}
            </DetailBox>
          </BackgroundBox>
        )}

        {isSmall && (
          displayMobileFilter ? (
            <MobileFilter
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleSetFilter={handleSetfilter}
              handleMobile={handleMobileFilter}
              handleClear={handleClearFilter}
            />
          )
            : (
              <MobileContainer>
                <FilterBox>
                  <FilterImg src={filterImg} alt="filter" onClick={handleMobileFilter} />
                </FilterBox>
                {loading ? <ProductsTableLoader /> : (
                  affiliateInvoices.map((paymentItem) => (
                    <MobileReturnRow
                      key={paymentItem?.id}
                      paymentItem={paymentItem}
                    />
                  ))
                )}
              </MobileContainer>
            )
        )}
        {!displayMobileFilter && (
          <FooterGrid item container>
            <CTablePagination
              rowsPerPageOptions={isSmall ? [100] : [20, 50, 100, 150, 200]}
              component="div"
              count={invoicesTotalNumber}
              page={currentPage - 1}
              onChangePage={handleChangePage}
              rowsPerPage={currentPageCount}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </FooterGrid>
        )}
      </ContentBox>
    </MainContent>
  );
};

export default PaymentsContainer;
