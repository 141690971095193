import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import NotificationDialog from '../../shared/notificationDialog';
import { CREATE_REPORT_MUTATION, UPDATE_REPORT_MUTATION } from '../../../mutations/reports';

const NewReportDialog = ({
  openDialog, setOpenDialog, stockReportId, business, productsWithoutCount,
  reportStatus
}) => {
  const navigate = useNavigate();

  const [createReport, { loading: createLoading }] = useMutation(CREATE_REPORT_MUTATION);
  const handleCreateReport = () => {
    let reports = localStorage.getItem('report');
    reports = reports && JSON.parse(reports);
    const products = reports.map((report) => {
      const {
        id, quantityInStock, batchId, batchNo, expiryDate, orderCost
      } = report;
      const isNew = batchId.includes('newbatch');
      return {
        productId: Number(id),
        quantityCounted: quantityInStock,
        ...(batchId && !isNew && { batchId }),
        ...(batchNo && isNew && { batchNo }),
        ...(expiryDate && isNew && { expiryDate }),
        ...(orderCost && isNew && { unitCost: orderCost })
      };
    });
    createReport({
      variables: { business, products, status: reportStatus }
    }).then((response) => {
      const { message } = response?.data?.createReport || {};
      toast.success(message);
    }).then(() => setOpenDialog(false))
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        localStorage.removeItem('report');
        localStorage.removeItem('report_businessId');
        setTimeout(() => navigate('/stock-count-report'), 1000);
      });
  };

  const [updateReport, { loading: updateLoading }] = useMutation(UPDATE_REPORT_MUTATION);
  const handleUpdateReport = () => {
    let reports = localStorage.getItem('report');
    reports = reports && JSON.parse(reports);
    const products = reports.map((report) => {
      const {
        id, inventoryId, quantityInStock, batchId, batchNo, expiryDate, orderCost
      } = report;
      const isNew = batchId.includes('newbatch');
      return {
        inventoryId: Number(inventoryId),
        productId: Number(id),
        quantityCounted: quantityInStock,
        ...(batchId && !isNew && { batchId }),
        ...(batchNo && isNew && { batchNo }),
        ...(expiryDate && isNew && { expiryDate }),
        ...(orderCost && isNew && { unitCost: orderCost })
      };
    });
    updateReport({
      variables: {
        stockReportId: Number(stockReportId),
        business,
        products,
        status: reportStatus
      }
    }).then((response) => {
      const { message } = response?.data?.updateReport || {};
      toast.success(message);
    }).then(() => setOpenDialog(false))
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        localStorage.removeItem('report');
        localStorage.removeItem('report_businessId');
        setTimeout(() => navigate('/stock-count-report'), 1000);
      });
  };

  return (
    <NotificationDialog
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      type="warning"
      title="Save Report?"
      desc={productsWithoutCount
        ? `You have ${productsWithoutCount} item(s) with 0 count. Would you like to proceed?`
        : 'Are you sure you want to save this report?'}
      action="cancel"
      action2="Yes, Save Report"
      action2Func={stockReportId ? handleUpdateReport : handleCreateReport}
      loading={createLoading || updateLoading}
    />
  );
};

NewReportDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.bool.isRequired,
  business: PropTypes.number.isRequired,
  stockReportId: PropTypes.string,
  productsWithoutCount: PropTypes.number.isRequired,
  reportStatus: PropTypes.string.isRequired,
};

NewReportDialog.defaultProps = {
  openDialog: false,
  stockReportId: '',
};

export default NewReportDialog;
