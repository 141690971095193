import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Grid, DialogActions, DialogContent, DialogContentText, TableBody,
  DialogTitle, Slide, TableContainer, Table, TableRow, TableHead
} from '@mui/material';
import toast from 'react-hot-toast';
import { Close } from '@mui/icons-material';
import {
  DISPATCH_ORDER_PRODUCTS_MUTATION, CLOSE_ORDER,
  UPDATE_ORDER_STATUS, DISPATCH_MORE_ORDER, CREATE_NOTE
} from '../../../mutations/orders';
import ReturnRow from './returnRow';
import { JSONParse } from '../../../utils/json';
import MainContent from '../../customComponents/mainContent';
import SupplierItems from './supplierItems';
import Stepper from './stepper';
import { parseJson } from '../../affiliates/utils';
import NotificationDialog from '../../shared/notificationDialog';
import {
  HeaderText, HeaderSubText, HeaderCell, CardWrapper, SupplierDetailCardWrapper,
  DeliveryAddressCard, NoteButton, NoteContainer, NoteTextField, SaveNoteButton,
  DeliveryAddressCardHeader, NoteCardHeader, NoteDialog, DialogContentStyled, CircularProgressLoader,
  CloseStyled, CDialog, SortButton, ViewAllNotes, CartGridContainer, DispatchButton, OverviewFooter
} from './dispatch.styles';
import {
  OrderButton, OrderButtonText, DialogActionButtonText,
  BackArrowIconContainer, PageTitleText, PageTitleSubText,
  DeliveryAddressCardHeaderTitle, DeliveryAddressCardHeaderSubTitle,
  PageTitleContainer, CartGridContainerHeader, DialogCancelButton, DialogOkButton,
  KeyboardArrowLeft, ChangeOutletRow, SelectOutletText, OutletInfoContainer,
  OutletContactLine,
  OutletHeader,
} from '../../cart/cart.styles';

import {
  DeliveryAddressCardSection, SaveChangesText,
} from './dispatchContainer.styles';
import LabelPrintDialog from './labelPrint/labelPrintDialog';
import OrderOverview from './orderOverview';

import { Product } from '../../../providers/reducers/product/classes/Product';
import OrderInfoCard from '../../shared/orderInfoCard/orderInfoCard';
import { OgaToken } from '../../../graphql/token';
import { useStateValue } from '../../../providers/stateProvider';
import ReturnSelectField from './returnSelectField';
import NoteRow from './notes';
import AllNoteRow from './AllNotesRow';
import ActionPopper from './actionPopper';
import ConfirmDialog from '../shared/confirmDialog';
import { DropDown } from '../../../assets/svgs';
import { CancelButton, OverviewFooterText } from './orderOverview.styles';

const INITIAL_STATE = {
  loading: false,
  businessId: null,
  addNewDeliveryLocation: true,
  selectedDeliveryLocation: '',
  name: '', // businessName
  logo: '', // businessLogo
  businessDateCreated: null,
  businessDateDelivered: null,
  deliveryLocations: [],
  cart: [],
  placeLoading: false,
  showPlaceOrderDialog: false,
  showErrorDialog: false,
  showPrintOrderDialog: false,
  showCancelOrderDialog: false,
  dateCreated: null,
  serviceFee: 0,
  deliveryFee: 0,
  isOrderDispatched: false,
  done: false,
  sortStatuses: {},
  orderStatus: '',
  freshlyClosed: false,
  logisticsId: '',
  dispatchErrors: [],
  searchText: '',
  noteText: '',
  notes: [],
  cancellationReason: '',
  returnStatus: false,
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
export default function Dispatch({
  businessDeliveryLocations, orderData, refetch, ordId
}) {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_STATE);
  const [activeStep, setActiveStep] = useState(0);
  const [quantityRecieve, setQuantityRecieve] = useState([]);
  const [noteState, setNoteState] = useState(false);
  const [open, setOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [dispatchMoreLoading, setDispatchMoreLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [cancelOrderType, setCancelOrderType] = useState('');
  const [qtyConfirmed, setQtyConfirmed] = useState([]);
  const [viewProduct, setViewProduct] = useState(false);

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const [dispatchOrder] = useMutation(DISPATCH_ORDER_PRODUCTS_MUTATION);
  const [closeOrder] = useMutation(CLOSE_ORDER);
  // const [updateSupplierOrderMeta] = useMutation(UPDATE_SUPPLIER_ORDER_META_MUTATION);
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS);
  const [dispatchMoreOrder] = useMutation(DISPATCH_MORE_ORDER);
  const [createNote] = useMutation(CREATE_NOTE);

  //   utility function to build up the page
  const handleChangeDeliveryLocation = (event) => {
    const { value } = event.target;
    setState({ ...state, selectedDeliveryLocation: value });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setLoading = (loading) => {
    setState({
      ...state,
      loading
    });
  };

  const handleMetaStatusUpdate = (id) => {
    setState((_state) => {
      const { sortStatuses } = _state;
      const sort = sortStatuses[id];
      sortStatuses[id] = !sort;
      return ({ ..._state, sortStatuses });
    });
    // updateSupplierOrderMeta({
    //  variables: {
    //    supplierOrderId: id,
    //    keyValues: [{
    //      key: 'sorted',
    //      value: value.toString()
    //    }]
    //  }
    // }).then(() => {
    //  if (!silent) toast.success('Supplier order successfully updated');
    //  return 'success';
    // }).catch((err) => {
    //  if (!silent) toast.error(err?.message);
    // });
  };

  // update the condition dropdown
  const handleChangeCondition = (id, value, key) => {
    const {
      cart
    } = state;
    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.recieved && key !== 'recieved') return;

    setState((prevState) => ({
      ...prevState,
      cart: prevState.cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, [key]: value, quantityRecieved: value ? cartItem.quantity : cartItem.quantityRecieved }
        : cartItem))
    }));
    state.cart.forEach((cartItem) => {
      if (cartItem.id === id) {
        if (quantityRecieve.some((item) => item.productId === id)) {
          const items = quantityRecieve.map(({ quantityReceived, productId }) => {
            if (productId === id && value) return ({ productId, quantityReceived: cartItem.quantity });
            if (productId === id) return ({ productId, quantityReceived: 0 });
            return ({ productId, quantityReceived });
          });
          setQuantityRecieve(items);
        } else {
          setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: !cartItem.quantityRecieved ? cartItem.quantity : 0 }]);
        }
      }
    });
  };
  // update the received checkbox
  const handleChangeProductQuantityInputValue = (id, value) => {
    const {
      cart
    } = state;
    let newValue = 0;

    if (Number(value)) {
      newValue = Number(value);
    } else {
      newValue = 1;
    }
    const existingCartItem = cart.find((item) => item.id === id);
    const updatedQtyConfirmed = qtyConfirmed.find(({ productId }) => productId === id);
    if (!existingCartItem.recieved) return;
    // eslint-disable-next-line consistent-return
    if (newValue > (updatedQtyConfirmed?.quantityConfirmed || existingCartItem?.quantityConfirmed || existingCartItem?.initialQuantity)) return toast.error('Quantity Received cannot be more than Quantity Confirmed');
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, quantity: newValue }
        : cartItem))
    });
    if (quantityRecieve.some((item) => item.productId === id)) {
      const items = quantityRecieve.map(({ quantityReceived, productId }) => {
        if (productId === id) return ({ productId, quantityReceived: newValue });
        return ({ productId, quantityReceived });
      });
      setQuantityRecieve(items);
    } else {
      setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: newValue }]);
    }
  };
  const handleIncrementProductQuantityInCart = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find((item) => item.id === id);
    const updatedQtyConfirmed = qtyConfirmed.find(({ productId }) => productId === id);
    if (!existingCartItem.recieved) return;
    // eslint-disable-next-line consistent-return
    if (existingCartItem?.quantityRecieved >= (updatedQtyConfirmed?.quantityConfirmed || existingCartItem?.quantityConfirmed || existingCartItem?.initialQuantity)) return toast.error('Quantity Received cannot be more than Quantity Confirmed');
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, quantity: cartItem.quantity + 1, quantityRecieved: cartItem.quantityRecieved + 1 }
        : cartItem))
    });
    state.cart.forEach((item) => {
      if (item.id === id) {
        if (quantityRecieve.some((i) => i.productId === id)) {
          const items = quantityRecieve.map(({ productId, quantityReceived }) => {
            if (productId === id) return ({ productId, quantityReceived: quantityReceived + 1 });
            return ({ productId, quantityReceived });
          });
          setQuantityRecieve(items);
        } else {
          setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: (item.quantityRecieved ?? item.quantity) + 1 }]);
        }
      }
    });
  };

  const handleDecrementProductQuantityInCart = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find(
      (cartItem) => cartItem.id === id
    );
    if (!existingCartItem.recieved) return;

    if (existingCartItem.quantity > 1) {
      setState({
        ...state,
        cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
          ? { ...cartItem, quantity: cartItem.quantity - 1, quantityRecieved: cartItem.quantityRecieved - 1 }
          : cartItem))
      });
    }
    state.cart.forEach((item) => {
      if (item.id === id) {
        if (quantityRecieve.some((i) => i.productId === id)) {
          const items = quantityRecieve.map(({ quantityReceived, productId }) => {
            if (productId === id) return ({ productId, quantityReceived: quantityReceived - 1 });
            return ({ productId, quantityReceived });
          });
          setQuantityRecieve(items);
        } else {
          setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: (item.quantityRecieved ?? item.quantity) - 1 }]);
        }
      }
    });
  };
  const handleBatchSelection = (id, value) => {
    if (!value.length) return;
    const {
      cart
    } = state;
    let qtyReceived = 0;
    if (value?.length) {
      qtyReceived = value?.reduce((acc, b) => acc + b.qtyToSell, 0);
    }
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === id
        ? { ...cartItem, quantity: qtyReceived }
        : cartItem))
    });
    if (quantityRecieve.some((item) => item.productId === id)) {
      const items = quantityRecieve.map(({ quantityReceived, productId, batches }) => {
        if (productId === id) return ({ productId, quantityReceived: qtyReceived, batches: value });
        return ({ productId, quantityReceived, batches });
      });
      setQuantityRecieve(items);
    } else {
      setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: qtyReceived, batches: value }]);
    }
  };
  const handleConfirmedQtyInputValue = (id, value) => {
    let newValue = 0;
    const {
      cart
    } = state;

    if (Number(value)) {
      newValue = Number(value);
    } else {
      newValue = 1;
    }
    const existingCartItem = cart.find((item) => item.id === id);
    const updatedQuantity = quantityRecieve?.find(({ productId }) => productId === id);
    if (!existingCartItem.recieved) return;
    // eslint-disable-next-line consistent-return
    if ((newValue) >= (existingCartItem?.initialQuantity)) return toast.error('Quantity Confirmed Cannot be more than Quantity Ordered');
    // eslint-disable-next-line consistent-return
    if (newValue <= (updatedQuantity?.quantityReceived || existingCartItem?.quantityRecieved)) return toast.error('Quantity Confirmed cannot be less than Quantity Sent');

    if (qtyConfirmed.some((item) => item.productId === id)) {
      const items = qtyConfirmed.map(({ quantityConfirmed, productId }) => {
        if (productId === id) return ({ productId, quantityConfirmed: newValue });
        return ({ productId, quantityConfirmed, });
      });
      setQtyConfirmed(items);
    } else {
      setQtyConfirmed([...qtyConfirmed, { productId: id, quantityConfirmed: newValue }]);
    }
  };

  const handleQtyConfirmedIncrement = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find((item) => item.id === id);
    const updatedQuantity = quantityRecieve?.find(({ productId }) => productId === id);
    const updatedQtyConfirmed = qtyConfirmed.find(({ productId }) => productId === id);
    // eslint-disable-next-line consistent-return
    if ((updatedQtyConfirmed?.quantityConfirmed || existingCartItem?.quantityConfirmed || existingCartItem?.initialQuantity) >= (existingCartItem?.initialQuantity)) return toast.error('Quantity Confirmed cannot be more than Quantity Ordered');
    // eslint-disable-next-line consistent-return
    if ((updatedQtyConfirmed?.quantityConfirmed || existingCartItem?.quantityConfirmed || existingCartItem?.initialQuantity) < (updatedQuantity?.quantityReceived || existingCartItem?.quantityRecieved)) return toast.error('Quantity Confirmed cannot be less than Quantity Sent');
    state.cart.forEach((item) => {
      if (item.id === id) {
        if (qtyConfirmed.some((i) => i.productId === id)) {
          const items = qtyConfirmed.map(({ productId, quantityConfirmed }) => {
            if (productId === id) return ({ productId, quantityConfirmed: quantityConfirmed + 1 });
            return ({ productId, quantityConfirmed });
          });
          setQtyConfirmed(items);
        } else {
          setQtyConfirmed([...qtyConfirmed, { productId: id, quantityConfirmed: (item.quantityConfirmed ?? item.initialQuantity) + 1 }]);
        }
      }
    });
  };

  const handleQtyConfirmedDecrement = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find((item) => item.id === id);
    const updatedQuantity = quantityRecieve?.find(({ productId }) => productId === id);
    const updatedQtyConfirmed = qtyConfirmed.find(({ productId }) => productId === id);
    if (!existingCartItem.recieved) return;
    // eslint-disable-next-line consistent-return
    if ((updatedQtyConfirmed?.quantityConfirmed || existingCartItem?.quantityConfirmed || existingCartItem?.initialQuantity) <= (updatedQuantity?.quantityReceived || existingCartItem?.quantityRecieved)) return toast.error('Quantity Confirmed cannot be less than Quantity Sent');
    state.cart.forEach((item) => {
      if (item.id === id) {
        if (qtyConfirmed.some((i) => i.productId === id)) {
          const items = qtyConfirmed.map(({ productId, quantityConfirmed }) => {
            if (productId === id) return ({ productId, quantityConfirmed: quantityConfirmed - 1 });
            return ({ productId, quantityConfirmed });
          });
          setQtyConfirmed(items);
        } else {
          setQtyConfirmed([...qtyConfirmed, { productId: id, quantityConfirmed: (item.quantityConfirmed ?? item.initialQuantity) - 1 }]);
        }
      }
    });
  };

  const handleSearch = (text) => {
    setState({
      ...state,
      searchText: text
    });
  };

  const toggleViewProduct = () => {
    setViewProduct(!viewProduct);
  };
  // utility function to retrieve the products
  const retrieveProducts = (orderSet) => {
    let products = [];
    orderSet.forEach((result) => {
      const filterOrder = result.orderproductSet.filter((orderProductObj) => {
        const { status } = JSONParse(orderProductObj?.meta) || '';
        if (status !== 'DISPATCHED') return orderProductObj;
        return null;
      });
      const orderproductSet = filterOrder?.map(
        (orderproduct) => {
          const orderProduct = orderproduct;
          orderProduct.orderSupplierSetId = result.id;
          return orderProduct;
        }
      );
      products.push(...orderproductSet);
    });

    products = products.map((p) => {
      const {
        id, // orderProductId not Product Id
        name,
        quantity,
        quantityRecieved,
        quantityConfirmed,
        orderSupplierSetId,
        meta: orderproductSetMeta,
        supplierOrder: {
          status: supplierOrderStatus,
          supplier: {
            name: supplierName,
          }
        },
        product: {
          meta,
        }
      } = p;

      return {
        ...new Product(p.product), // We might need some real product information, but it was placed above so that the product Id doesnt conflict with the intended orderProductId
        id, // orderProductId not Product Id
        name,
        quantity,
        quantityRecieved,
        orderSupplierSetId,
        supplier: supplierName,
        meta: JSON.parse(meta),
        recieved: quantityRecieved > 0,
        condition: 'Good',
        initialQuantity: quantity,
        supplierOrderStatus,
        quantityConfirmed,
        orderproductSetMeta: JSONParse(orderproductSetMeta)
      };
    });
    setState((prev) => ({
      ...prev,
      cart: products,
    }));
  };

  const supplierOrderSetSort = (supplierOrders) => {
    const { sortStatuses } = state;
    supplierOrders.forEach((supplierOrder) => {
      const { id, meta } = supplierOrder;
      let { sorted } = JSONParse(meta);
      sorted = sorted ? sorted.localeCompare('true') : false;
      if (sorted === -1) sorted = false;
      else if (sorted === 0) sorted = true;
      sortStatuses[id] = sorted;
      setState((prev) => ({
        ...prev,
        sortStatuses,
      }));
    });
  };

  const routeStatuses = (metaStatus) => {
    switch (metaStatus) {
      case 'ORDER_RECEIVED':
        return null;
      case 'ORDER_SENT':
        return null;
      case 'ORDER_SORTED':
        return handleNext();
      case 'ORDER_DISPATCHED':
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
        return setState({
          ...state,
          isOrderDispatched: true,
        });
      case 'ORDER_QUEUED_FOR_DISPATCH':
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
        return setState({
          ...state,
          isOrderDispatched: true,
        });
      case 'ORDER_CLOSED':
        setActiveStep((prevActiveStep) => prevActiveStep + 3);
        return setState({
          ...state,
          isOrderDispatched: true,
          done: true
        });
      case 'ORDER_CANCELED':
        setActiveStep((prevActiveStep) => prevActiveStep);
        return setState({
          ...state,
          isOrderDispatched: true,
          done: true
        });
      default:
        toast.error('Order has not been recieved yet');
        return setTimeout(() => {
          navigate('/orders-admin');
        }, 1500);
    }
  };

  // initialize the state with props
  useEffect(() => {
    const {
      business: { id: businessId, name, logo }, dateCreated, id, meta,
      supplierorderSet, metaStatus, paymentSummary, logisticsId: trackingId, returned
    } = orderData;

    supplierOrderSetSort(supplierorderSet);
    routeStatuses(metaStatus);
    const { delivery_location_id: deliveryLocationId, notes, cancellation_reason: cancellationReason } = JSONParse(orderData.meta);
    const defaultDeliveryLocation = businessDeliveryLocations.find((bo) => String(bo.id) === String(deliveryLocationId));
    const { returned_status: returnStatus } = JSONParse(meta);
    const returnStat = (returnStatus && returnStatus === 'COMPLETED' && returned === true) && true;

    const {
      service_fee: serviceFee, delivery_fee: deliveryFee
    } = JSONParse(paymentSummary);

    setState((prev) => ({
      ...prev,
      id,
      businessId,
      deliveryLocations: businessDeliveryLocations || [],
      selectedDeliveryLocation: defaultDeliveryLocation?.name,
      name,
      logo,
      businessDateCreated: moment(dateCreated).format('DD/MM/YYYY'),
      businessDateDelivered: moment(dateCreated).add(2, 'days').format('DD/MM/YYYY'),
      dateCreated,
      serviceFee,
      deliveryFee,
      orderStatus: metaStatus,
      trackingId,
      notes,
      cancellationReason,
      returnStatus: returnStat
    }));
    retrieveProducts(supplierorderSet);
  }, [orderData, businessDeliveryLocations]);
  //   utility function to build up the page
  const getDeliveryLocationOption = () => {
    const optionsBox = { name: 'deliverTo', label: 'Deliver To', options: [] };
    const { deliveryLocations } = state;
    deliveryLocations.forEach((deliveryLocation) => {
      optionsBox.options.push(deliveryLocation.name);
    });
    return optionsBox;
  };

  //   utility function to build up the page
  const getSelectedDeliveryLocationInfo = () => {
    const { selectedDeliveryLocation, deliveryLocations } = state;
    return deliveryLocations.find((deliveryLocation) => deliveryLocation.name === selectedDeliveryLocation);
  };

  //   utility function to build up the page
  const renderSelectDeliveryLocationInfo = () => {
    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    if (selectedDeliveryLocationInfo) {
      let { contacts } = selectedDeliveryLocationInfo;
      try {
        contacts = parseJson(contacts.replace(/'/g, '"'));
      } catch (e) {
        return null;
      }
      return (
        <OutletInfoContainer>
          <OutletHeader>Delivery Address</OutletHeader>
          <br />
          <OutletContactLine>{ contacts.address_line_1 }</OutletContactLine>
          <OutletContactLine>
            { contacts.city }
            ,
            { contacts.country }
          </OutletContactLine>
        </OutletInfoContainer>
      );
    }
    return null;
  };

  const handleOrderStatus = (status) => {
    const { id } = state;

    updateOrderStatus({
      variables: {
        orderId: id,
        status,
        changes: quantityRecieve
      }
    }).then(({ data }) => {
      const { message } = data?.updateOrderStatus ?? '';
      toast.success(message);
      setState((prev) => ({
        ...prev, orderStatus: status,
      }));
      handleNext();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleSaveOrdersChanges = (status) => {
    const { id } = state;

    const merged = quantityRecieve.concat(qtyConfirmed);

    const result = merged.reduce((acc, item) => {
      const existingItem = acc.find((i) => i.productId === item.productId);
      if (existingItem) {
        existingItem.quantityConfirmed = item.quantityConfirmed || existingItem.quantityConfirmed;
        existingItem.quantityReceived = item.quantityReceived || existingItem.quantityReceived;
      } else {
        acc.push(item);
      }
      return acc;
    }, []);

    updateOrderStatus({
      variables: {
        orderId: id,
        status,
        changes: result
      }
    }).then(() => {
      toast.success('Order successfully saved');
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };
  // on cancel order
  const handleCancelOrder = () => {
    const { id } = state;
    updateOrderStatus({
      variables: {
        orderId: id,
        status: cancelOrderType,
        changes: [],
      }
    }).then(() => {
      toggleSuccessModal();
      setOpenModal(false);
      toast.success('Order successfully cancel');
      setState((prev) => ({
        ...prev, orderStatus: 'ORDER_CANCELED',
      }));
      setTimeout(() => {
        navigate('/orders-admin');
      }, 1500);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleAction = (event, type) => {
    if (type === 'replace') {
      setOpenModal(true);
      setCancelOrderType('ORDER_CANCELED_REPLACE_ORDER');
    } else if (type === 'changed mind') {
      setCancelOrderType('ORDER_CANCELED_AFFILIATE_CHANGED_MIND');
      setOpenModal(true);
    }
    handleOptions(event);
  };

  const handleCloseModal = () => {
    setAction(false);
  };
  // on submit
  /**
   * use this interface to mark an order as ready to be dispatched.
  */
  const handleDispatchOrder = async () => {
    try {
      const {
        cart, id, businessId
      } = state;
      setLoading(true);
      const productItems = cart.map((item) => ({
        productId: Number(item.id), quantity: item.quantityRecieved,
        condition: item.condition, recieved: item.recieved
      }));

      const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();

      const mutationVariables = {
        cart: productItems,
        businessId,
        orderId: id,
        deliveryLocationId: +selectedDeliveryLocationInfo.id,
      };
      dispatchOrder({
        variables: mutationVariables
      }).then(({ data: { dispatchOrder: dispOrder } }) => {
        if (dispOrder && dispOrder.failed && dispOrder.failed.length) {
          const dispatchErrs = dispOrder?.failed.map((failed) => JSONParse(failed));
          setState({
            ...state,
            dispatchErrors: dispatchErrs,
            showPlaceOrderDialog: false,
            showErrorDialog: true,
          });
          throw Error(dispOrder?.message);
        }
        toast.success('Order Ready For Dispatch');
        setState({
          ...state,
          orderStatus: 'ORDER_QUEUED_FOR_DISPATCH',
          isOrderDispatched: true,
          showPlaceOrderDialog: false
        });
        handleNext();
      }).catch((err) => {
        toast.error(err?.message);
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message);
    }
  };

  const handleReverseDispatch = () => {
    setState((prev) => ({
      ...prev,
      orderStatus: 'ORDER_RECEIVED',
      showErrorDialog: false
    }));
    setActiveStep(0);
  };

  const handleCloseOrder = () => {
    const { id } = state;
    closeOrder({
      variables: {
        orderId: id
      }
    }).then(() => {
      toast.success('Order successfully closed');
      setState({
        ...state,
        orderStatus: 'ORDER_CLOSED',
        freshlyClosed: true,
        isOrderDispatched: true,
        done: true
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleOpenPlaceOrderDialog = () => {
    const {
      isOrderDispatched, done, freshlyClosed
    } = state;
    if (isOrderDispatched && !done) {
      return handleCloseOrder();
    }
    if (isOrderDispatched && done && freshlyClosed) {
      return navigate('/orders-admin');
    }
    if (isOrderDispatched && done) {
      setActiveStep(0);
      return setState((_state) => ({
        ..._state,
        freshlyClosed: false,
        isOrderDispatched: false,
        done: false
      }));
    }
    setViewProduct(false);
    return setState({
      ...state,
      showPlaceOrderDialog: true
    });
  };

  const handleOpenPrintOrderDialog = () => {
    window.open(`${OgaToken.SERVER_LINK}download_invoice/${ordId}.pdf`, '_blank');
    // setState({
    //   ...state,
    //   showPrintOrderDialog: true
    // });
  };

  const handleCancelOrderDialog = (e) => {
    handleOptions(e);
  };

  const handleReturnOrder = async () => {
    const {
      supplierorderSet,
    } = orderData;
    let products = [];
    supplierorderSet.forEach((result) => {
      const filterOrder = result.orderproductSet.filter((orderProductObj) => {
        if (orderProductObj?.quantityRecieved) return orderProductObj;
        return null;
      });
      const orderproductSet = filterOrder?.map(
        (orderproduct) => {
          const orderProduct = orderproduct;
          orderProduct.orderSupplierSetId = result.id;
          return orderProduct;
        }
      );
      products.push(...orderproductSet);
    });

    products = products.map((p) => {
      const {
        id, // orderProductId not Product Id
        name,
        quantity,
        quantityRecieved,
        orderSupplierSetId,
        meta: orderproductSetMeta,
        supplierOrder: {
          status: supplierOrderStatus,
          supplier: {
            name: supplierName,
          }
        },
        product: {
          meta,
        }
      } = p;

      return {
        ...new Product(p.product), // We might need some real product information, but it was placed above so that the product Id doesnt conflict with the intended orderProductId
        id, // orderProductId not Product Id
        name,
        quantity,
        quantityRecieved,
        orderSupplierSetId,
        supplier: supplierName,
        meta: JSON.parse(meta),
        recieved: quantityRecieved > 0,
        condition: 'Good',
        initialQuantity: quantity,
        supplierOrderStatus,
        orderproductSetMeta: JSONParse(orderproductSetMeta)
      };
    });
    navigate({
      pathname: '/return-order',
      state: { ...state, cart: products }
    });
  };

  const handleDispatchMoreItems = async () => {
    const { id: orderId } = state;
    setDispatchMoreLoading(true);

    try {
      const { data } = await dispatchMoreOrder({
        variables: { orderId }
      });

      let checkForOrders;
      const { supplierorderSet, message } = data?.dispatchMoreOrder ?? '';
      supplierorderSet.forEach((result) => {
        checkForOrders = result?.orderproductSet.some((order) => {
          const { status } = JSONParse(order?.meta) || '';
          return status !== 'DISPATCHED';
        });
      });

      if (!checkForOrders) {
        setDispatchMoreLoading(false);
        return toast.error('All items have been dispatched');
      }

      retrieveProducts(supplierorderSet);
      setState((prev) => ({ ...prev, isOrderDispatched: false }));
      toast.success(message);
      setActiveStep(0);
      setDispatchMoreLoading(false);
    } catch (err) {
      toast.error(err?.message);
      setDispatchMoreLoading(false);
    }
  };

  const handleProformaInvoice = () => {
    const { id: orderId } = state;
    window.open(`${OgaToken.SERVER_LINK}download_proforma_with_product_qty_xlsx/${orderId}`, '_blank');
    // window.open(`${OgaToken.SERVER_LINK}download_proforma_with_product_qty/${orderId}.pdf`, '_blank');
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setState({ ...state, noteText: value });
  };

  const handleSaveNote = () => {
    const { id: orderId, noteText } = state;
    const created = moment();
    createNote({
      variables: {
        orderId,
        content: noteText,
        created: created.toString()
      }
    }).then(({ data }) => {
      const { message } = data?.createNote ?? '';
      toast.success(message);
      setNoteState(false);
      setState({ ...state, noteText: '' });
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };
  const handleAddNote = () => {
    setNoteState(!noteState);
  };
  const handleToggleNotes = () => {
    setOpen(!open);
  };
  const handleNote = () => {
    handleToggleNotes();
    handleAddNote();
  };

  const renderActiveComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <SupplierItems
            state={state}
            handleChangeCondition={handleChangeCondition}
            handleIncrement={handleIncrementProductQuantityInCart}
            handleDecrement={handleDecrementProductQuantityInCart}
            handleChangeProductQuantity={handleChangeProductQuantityInputValue}
            handleMetaStatusUpdate={handleMetaStatusUpdate}
            quantityRecieve={quantityRecieve}
            handleSearch={handleSearch}
            handleCancelOrderDialog={handleCancelOrderDialog}
            handleBatchSelection={handleBatchSelection}
            handleConfirmedQtyInputValue={handleConfirmedQtyInputValue}
            handleQtyConfirmedIncrement={handleQtyConfirmedIncrement}
            handleQtyConfirmedDecrement={handleQtyConfirmedDecrement}
            qtyConfirmed={qtyConfirmed}
          />
        );
      case 1:
      case 2:
        return (
          <OrderOverview
            state={state}
            handleOpenPrintOrderDialog={handleOpenPrintOrderDialog}
            handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
            handleCancelOrderDialog={handleCancelOrderDialog}
            handleReturnOrder={handleReturnOrder}
            handleBack={handleBack}
            activeStep={activeStep}
            handleDispatchMoreItems={handleDispatchMoreItems}
            dispatchMoreLoading={dispatchMoreLoading}
            toggleViewProduct={toggleViewProduct}
            viewProduct={viewProduct}

          />
        );
      default:
        return (
          <OrderOverview
            state={state}
            handleOpenPrintOrderDialog={handleOpenPrintOrderDialog}
            handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
            handleCancelOrderDialog={handleCancelOrderDialog}
            handleReturnOrder={handleReturnOrder}
            handleBack={handleBack}
            activeStep={activeStep}
            handleDispatchMoreItems={handleDispatchMoreItems}
            dispatchMoreLoading={dispatchMoreLoading}
            toggleViewProduct={toggleViewProduct}
            viewProduct={viewProduct}
          />
        );
    }
  };

  const headers = [
    'PID', 'Product Name', 'Qty Requested', 'Qty in Stock',
  ];

  const noteIndex = noteState ? 1 : 3;
  // main return of the component
  return (
    <MainContent>
      <CartGridContainer style={{ paddingBottom: '10rem' }}>
        <BackArrowIconContainer onClick={() => navigate('/orders-admin')}>
          <KeyboardArrowLeft />
          back
        </BackArrowIconContainer>
        {state?.orderStatus === 'ORDER_CANCELED'
          ? (
            <CartGridContainerHeader>
              <PageTitleText>{state.cancellationReason === 'replace_order' ? 'Cancelled Order - Replace Order' : 'Cancelled Order - Affiliate Changed Mind'}</PageTitleText>
              <PageTitleSubText>View summary</PageTitleSubText>
            </CartGridContainerHeader>
          ) : (
            <>
              <Stepper activeStep={activeStep} />
              <CartGridContainerHeader container md={12}>
                <PageTitleContainer>
                  <PageTitleText>Order Detail</PageTitleText>
                  <PageTitleSubText>View Order summary</PageTitleSubText>
                </PageTitleContainer>
                <Grid item container md={7} style={{ justifyContent: 'space-between' }}>
                  {(activeStep === 0) && (
                    <>
                      <OrderButton
                        onClick={() => handleProformaInvoice()}
                        disabled={state.orderStatus === 'ORDER_SENT'}
                      >
                        <SaveChangesText>Generate Proforma</SaveChangesText>
                      </OrderButton>
                      <OrderButton
                        onClick={() => handleSaveOrdersChanges('ORDER_RECEIVED')}
                        disabled={state.orderStatus === 'ORDER_SENT'}
                      >
                        <SaveChangesText>Save Changes</SaveChangesText>
                      </OrderButton>
                      <OrderButton
                        onClick={() => handleOrderStatus('ORDER_SORTED')}
                        disabled={!userPermissions?.includes('manage_orders_complete_sorting') || state.orderStatus === 'ORDER_SENT'}
                      >
                        <OrderButtonText>Complete Sorting</OrderButtonText>
                      </OrderButton>
                    </>
                  )}
                </Grid>
              </CartGridContainerHeader>
            </>
          )}
        <CardWrapper container style={{ marginTop: '.5rem' }}>
          <SupplierDetailCardWrapper item container>
            <OrderInfoCard
              id={String(state.id).padStart(6, 0)}
              business={state}
              dateCreated={state.businessDateCreated}
              businessDateDelivered={state.businessDateDelivered}
              displayId="hide"
              affiliateInfoCard="order"
            />
          </SupplierDetailCardWrapper>
          <DeliveryAddressCard item container>
            <DeliveryAddressCardSection>
              <DeliveryAddressCardHeader>
                <DeliveryAddressCardHeaderTitle>Delivery Location</DeliveryAddressCardHeaderTitle>
                <DeliveryAddressCardHeaderSubTitle
                  onClick={() => setState({ ...state, addNewDeliveryLocation: true })}
                  type="button"
                >
                  Add Outlet
                </DeliveryAddressCardHeaderSubTitle>
              </DeliveryAddressCardHeader>
              <Grid style={{ marginBottom: '20px' }}>
                <SelectOutletText>
                  Select the outlet you want your order to be delivered to
                </SelectOutletText>
                <ChangeOutletRow>
                  <ReturnSelectField
                    field={getDeliveryLocationOption()}
                    value={state.selectedDeliveryLocation}
                    showCheckBox={false}
                    label=""
                    handleCreditDaysOpen={() => ({})}
                    fullWidth
                    handleChange={handleChangeDeliveryLocation}
                    showLabel={false}
                  />
                </ChangeOutletRow>
              </Grid>
              {renderSelectDeliveryLocationInfo()}
            </DeliveryAddressCardSection>
          </DeliveryAddressCard>
          <SupplierDetailCardWrapper item container>
            <DeliveryAddressCardSection>
              <NoteCardHeader>
                <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>
                <NoteButton
                  onClick={handleAddNote}
                  type="button"
                >
                  Add Note
                </NoteButton>
              </NoteCardHeader>
              {state?.notes && state?.notes?.slice(0, noteIndex).map((note) => (
                <NoteRow
                  note={note}
                  handleAddNote={handleAddNote}
                  key={note}
                />
              ))}
              {!state.notes?.length || noteState ? (
                <Grid>
                  <NoteContainer>
                    <NoteTextField
                      value={state?.noteText}
                      multiline
                      minRows={5}
                      placeholder="Enter note here"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ backgroundColor: '#f0f0f0', marginBottom: '4em' }}
                    />
                  </NoteContainer>
                  <SaveNoteButton
                    variant="contained"
                    fullWidth
                    onClick={handleSaveNote}
                  >
                    Save Note
                  </SaveNoteButton>
                </Grid>
              ) : '' }
              {state.notes?.length && !noteState ? <ViewAllNotes onClick={handleToggleNotes}>View All</ViewAllNotes> : '' }
            </DeliveryAddressCardSection>
          </SupplierDetailCardWrapper>
        </CardWrapper>
        {renderActiveComponent()}
        {/* {state.orderStatus !== 'ORDER_SENT' && (
          <UpdateOrder
            orderId={state.id}
            handleOrderStatus={handleOrderStatus}
            refetch={refetch}
          />
        )}
        <GoToTop /> */}
        {viewProduct && (
          <>
            <SupplierItems
              state={state}
              handleChangeCondition={handleChangeCondition}
              handleIncrement={handleIncrementProductQuantityInCart}
              handleDecrement={handleDecrementProductQuantityInCart}
              handleChangeProductQuantity={handleChangeProductQuantityInputValue}
              handleMetaStatusUpdate={handleMetaStatusUpdate}
              quantityRecieve={quantityRecieve}
              handleSearch={handleSearch}
              handleCancelOrderDialog={handleCancelOrderDialog}
              handleBatchSelection={handleBatchSelection}
              handleConfirmedQtyInputValue={handleConfirmedQtyInputValue}
              handleQtyConfirmedIncrement={handleQtyConfirmedIncrement}
              handleQtyConfirmedDecrement={handleQtyConfirmedDecrement}
              qtyConfirmed={qtyConfirmed}
              viewProduct={viewProduct}
            />
            <Grid container>
              <OverviewFooter container sm={12} md={12} style={{ justifyContent: 'space-between' }}>
                <CancelButton
                  onClick={(e) => handleCancelOrderDialog(e)}
                  disabled={state.orderStatus === 'ORDER_DISPATCHED' || state.done}
                  md={3}
                >
                  <OverviewFooterText>
                    Cancel Order
                  </OverviewFooterText>
                  <DropDown style={{ fontSize: '.9rem' }} />
                </CancelButton>
                <DispatchButton
                  onClick={() => handleOpenPlaceOrderDialog()}
                  disabled={state.orderStatus === 'ORDER_QUEUED_FOR_DISPATCH'
                    || !userPermissions?.includes('manage_orders_ready_for_dispatch')}
                  style={{ marginLeft: '1.5rem' }}
                >
                  <OrderButtonText>Ready for Dispatch</OrderButtonText>
                </DispatchButton>
              </OverviewFooter>
            </Grid>
          </>
        )}
        <ActionPopper
          action={action}
          handleAction={handleAction}
          handleClose={handleCloseModal}
        />
        <ConfirmDialog
          openDialog={openModal}
          setOpenDialog={setOpenModal}
          title="Cancel Order"
          desc="Are you sure you want to cancel this order?"
          options={['Cancel', 'Yes, Cancel Order']}
          confirmHanlder={handleCancelOrder}
        />
      </CartGridContainer>

      <CDialog
        open={state.showPlaceOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState({ ...state, showPlaceOrderDialog: false })}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Place Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to Mark this order ready for Dispatch?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <DialogCancelButton
            onClick={() => setState({ ...state, showPlaceOrderDialog: false })}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton
            onClick={() => !state.loading && handleDispatchOrder()}
            disabled={!userPermissions?.includes('manage_orders_dispatch')}
          >
            <DialogActionButtonText>
              {
                state.loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={16}
                    thickness={3}
                  />
                ) : 'OK'
              }
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </CDialog>

      <CDialog
        open={state.showCancelOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState({ ...state, showCancelOrderDialog: false })}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to Cancel this order?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <DialogCancelButton
            onClick={() => setState({ ...state, showCancelOrderDialog: false })}
          >
            <DialogActionButtonText>
              No
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton
            onClick={() => handleCancelOrder()}
          >
            <DialogActionButtonText>
              Yes, Cancel Order
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </CDialog>

      <CDialog
        open={state.showErrorDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleReverseDispatch()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <HeaderText>Issues Encountered</HeaderText>
          <HeaderSubText>Requested quantities below are higher than stock available</HeaderSubText>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TableContainer>
              <Table aria-label="orders table">
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <HeaderCell key={header}>{header}</HeaderCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state?.dispatchErrors.map((dispErr) => (
                    <ReturnRow
                      key={dispErr?.id}
                      dispErr={dispErr}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <SortButton
            onClick={() => !state.loading && handleReverseDispatch()}
          >
            <DialogActionButtonText>
              {
                state.loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={16}
                    thickness={3}
                  />
                ) : 'Sort'
              }
            </DialogActionButtonText>
          </SortButton>
        </DialogActions>
      </CDialog>

      <NoteDialog
        open={open}
        onClose={handleToggleNotes}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <DialogContentStyled>
          <CloseStyled>
            <Close
              fontSize="small"
              onClick={handleToggleNotes}
              style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }}
            />
          </CloseStyled>
          <DeliveryAddressCardHeader>
            <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>
            <NoteButton
              onClick={handleNote}
              type="button"
            >
              Add Note
            </NoteButton>
          </DeliveryAddressCardHeader>
          {state?.notes && state?.notes?.map((note) => (
            <AllNoteRow
              key={note}
              note={note}
            />
          ))}
        </DialogContentStyled>
      </NoteDialog>
      <LabelPrintDialog
        dialogOpen={state.showPrintOrderDialog}
        closeDialog={() => setState({ ...state, showPrintOrderDialog: false })}
        state={state}
      />
      <NotificationDialog
        openDialog={successModal}
        setOpenDialog={toggleSuccessModal}
        title="Order cancelled Successfully!"
        desc="Hi Pharm, Order has been successfully Cancelled!"
      />
    </MainContent>
  );
}

Dispatch.propTypes = {
  businessDeliveryLocations: PropTypes.instanceOf(Array).isRequired,
  orderData: PropTypes.instanceOf(Array).isRequired,
  ordId: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired
};
