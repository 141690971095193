import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { JSONParse } from '../../../../utils/json';
import {
  BodyCell, MainTableRow,
} from './returnRow.styles';
import ActionPopper from './actionPopper';
import { DELETE_BUSINESS_ROLE } from '../../../../mutations/business';

const ReturnRow = ({ indx, row, refetch }) => {
  const [action, setAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const navigate = useNavigate();
  const [deleteSystemRole] = useMutation(DELETE_BUSINESS_ROLE);

  const { id, name, contacts } = row;

  const {
    primary_email_address: primaryEmailAddress, city
  } = JSONParse(contacts);

  const cells = [name, primaryEmailAddress, city];

  const returnCell = (value) => value || '-';

  const handleOptions = (event) => {
    setAction(action ? null : true);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const toggleConfirmation = () => {
    setOpen(!open);
  };
  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const handleDelete = () => {
    toggleConfirmation();
    // if (businessUserCount) return toast.error('Can not delete role assigned to users');
    const variables = {
      roleId: id,
    };
    deleteSystemRole({ variables })
      .then(({ data }) => {
        const { message } = data?.createSystemRole || {};
        toggleSuccessModal();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  const toggleDialog = () => {
    setOpenModal(!openModal);
  };

  const handleAction = (event, type, userRow) => {
    if (type === 'view users') {
      navigate('/settings/users', {
        state: { id, name }
      });
    } else if (type === 'edit role') {
      setOpenModal(true);
    } else {
      toggleConfirmation();
    }
    handleOptions(event);
  };

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow item container key={id}>
      <BodyCell>{indx + 1}</BodyCell>
      {renderBodyCells()}
      <BodyCell>
        <ActionPopper
            // handleAction={handleAction}
          row={row}
        />
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Object),
  refetch: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
