import { Box, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TableColumnRowText } from '../../../shared/tableGlobalStyle.styles';
import { DeleteIcon, EditIcon } from '../../../../assets/svgs';
import ConfirmDialog from '../../../shared/confirmDialog';

const VatRows = ({ row, preferenceProps }) => {
  const { vatName, vatPercent } = row;
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);

  const {
    handleUpdatePreference,
    preferences,
    setSuccessProps,
    vatDialogHandler,
  } = preferenceProps;

  useEffect(() => {
    if (!confirmStatus) return;
    setConfirmStatus(false);
    const updatedPreferences = {
      ...preferences,
      vatSetting: { vatName: '', vatPercent: 0 }
    };
    setSuccessProps({
      title: 'Tax Deleted!',
      description: 'Your have successfully deleted Tax Value'
    });
    handleUpdatePreference(updatedPreferences);
  }, [confirmStatus]);

  const handleDelete = () => {
    setConfirmDialog(true);
  };

  return (
    <>
      <TableRow>
        <TableColumnRowText>{vatName}</TableColumnRowText>
        <TableColumnRowText>{vatPercent}</TableColumnRowText>
        <TableColumnRowText sx={{ width: '30%' }}>
          <Box sx={{ display: 'flex', gap: '30px' }}>
            <Box
              sx={{
                display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer'
              }}
              onClick={() => vatDialogHandler(false)}
            >
              <EditIcon />
              Edit
            </Box>
            <Box
              sx={{
                display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer'
              }}
              onClick={handleDelete}
            >
              <DeleteIcon />
              Delete
            </Box>
          </Box>
        </TableColumnRowText>
      </TableRow>
      <ConfirmDialog
        openDialog={confirmDialog}
        setOpenDialog={setConfirmDialog}
        title="Delete Tax?"
        desc="Are you sure you want to delete created Tax"
        setStatus={setConfirmStatus}
        options={['Cancel', 'Yes Delete']}
      />
    </>
  );
};

VatRows.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  preferenceProps: PropTypes.instanceOf(Object).isRequired,
};

export default VatRows;
