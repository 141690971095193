import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import MainContent from '../../customComponents/mainContent';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderSubHeader, ProductItemsTotal, ViewCartButton, ViewCartButtonText,
  TextWrapper, HeaderIcon, MenuButton, SearchImage, SearchContainer, SearchTextField,
  SearchButton, ProductItemsContainer
} from '../../toBeDeleted/supplierDetails/supplierDetailsContainer.styles';
import {
  HeadWrapper, NameGrid, DetailPaper, SubHeaderText,
  LightDivider, BottomContainer, NameText
} from './individualCategory.style';
import CategoryProduct from './categoryProducts';
import { CATEGORY_DETAIL } from '../../../queries/categories';
import Loader from '../../shared/loader';
import SearchIcon from '../../../assets/images/Search Icon.png';
import EditIcon from '../../../assets/images/Edit Icon.png';
import { JSONParse } from '../../../utils/json';
import CategoryDialog from '../individualCategoryDialog';

const CategoryDetails = () => {
  const initialState = {
    searchText: '',
    currentPage: 1,
    currentPageCount: 20,
  };

  const [state, setState] = useState(initialState);
  const [edit, setEdit] = useState(false);

  const {
    searchText, currentPage
  } = state;

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    loading, error, data, refetch
  } = useQuery(CATEGORY_DETAIL, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  const { category = {} } = data;
  const meta = JSONParse(category.meta);

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const renderSearchField = () => (
    <SearchContainer container item xs={12} md={4}>
      <SearchTextField
        variant="outlined"
        label=""
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search product item"
        fullWidth
        InputProps={{
          endAdornment:
  <InputAdornment position="end">
    <SearchButton
      aria-label="search for product"
      edge="end"
      disabled
    >
      <SearchImage alt="search icon" src={SearchIcon} />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const EditCat = () => {
    setEdit(true);
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item>
            <MenuButton onClick={() => navigate('/categories')}>
              <KeyboardArrowLeft fontSize="large" />
            </MenuButton>
            <TextWrapper>
              <PrimaryTitle variant="h5">
                Category Detail
              </PrimaryTitle>
              <SecondaryTitle>
                View details of a specified category
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
          <ViewCartButton
            onClick={() => EditCat()}
          >
            <HeaderIcon alt="cart icon" src={EditIcon} />
            <Hidden mdDown>
              <ViewCartButtonText>
                Edit
              </ViewCartButtonText>
            </Hidden>
          </ViewCartButton>
        </TitleGridContainer>
        <HeadWrapper container spacing={6}>
          <NameGrid item xs={12} md={6}>
            <DetailPaper elevation={2}>
              <SubHeaderText variant="subtitle1" align="right">
                #
                {' '}
                {category.id}
              </SubHeaderText>
              <LightDivider light />
              <BottomContainer>
                <NameText>{category.categoryName}</NameText>
                <p>
                  Loyalty Weight :
                  {' '}
                  {category.loyaltyWeight}
                </p>
                <p>
                  Vat Status :
                  {' '}
                  {category.vatStatus}
                </p>
                <p>
                  Markup :
                  {' '}
                  {category.markup}
                </p>
              </BottomContainer>
            </DetailPaper>
          </NameGrid>
        </HeadWrapper>
        <Hidden mdUp>
          {renderSearchField()}
        </Hidden>
        <OrderSubHeader container item>
          <ProductItemsContainer container item xs={12} md={2} xl={2}>
            <ProductItemsTotal />
          </ProductItemsContainer>
          <Hidden mdDown>
            {renderSearchField()}
          </Hidden>
        </OrderSubHeader>
        <CategoryProduct
          state={state}
          categoryId={id}
          handleChangePage={handleChangePage}
          page={currentPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSearch={handleSearch}
        />
        <CategoryDialog
          edit
          dialogOpen={edit}
          closeDialog={() => setEdit(false)}
          categoryRefetch={refetch}
          catData={{ ...category, pricingValue: meta.pricing_value, pricingType: meta.pricing_type }}
        />
      </OrderGridContainer>
    </MainContent>
  );
};

export default CategoryDetails;
