import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const PromoHeaderLoader = () => {
  const isEven = (n) => n % 2 === 0;

  return (
    <Grid container item spacing={3}>
      {Array.from(new Array(6)).map((_, indx) => (
        <Grid item container key={indx} xs={isEven(indx) ? 4 : 8}>
          <Skeleton variant="text" animation="wave" height="28px" width="40%" />
          <Skeleton variant="text" animation="wave" height="22px" width="80%" />
        </Grid>
      ))}
    </Grid>
  );
};

export default PromoHeaderLoader;
