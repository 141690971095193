import { DialogContent, Dialog, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 0.75rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 600px;
  height: auto;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
`;

export const Title = styled('h2')`
  margin: 1rem 0 0 0;
`;

export const Description = styled('p')`
  color: #858383;
  margin: 0.5rem 0 0 0;
  font-size: 13px;
`;

export const IconWrapper = styled('div')`
  background: #DDEEFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.7rem;
  width: fit-content;
`;

export const LeftContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  font-weight: 400;
`;

export const ButtonBorder = styled(Button)`
  border: 1px solid #235A91;
  min-width: 6.5rem;
  height: 3rem;
  margin-top: 14px;
  font-size: 13px;
  border-radius: 8px;
`;
