import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import MainContent from '../../customComponents/mainContent';
import TitleGrid from './titleGrid';
import SideNav from '../shared/sideNav/sideNav';
import {
  TitleGridWrapper, OutletContentWrapper,
} from './connectMarketplaceContainer.styles';
import { GET_BUSINESS_MP_ACCOUNTS } from '../../../queries/businesses';
import { useStateValue } from '../../../providers/stateProvider';
import ConnectMarketplace from './connectMarketplace/connectMarketplace';

const initialState = {
  searchText: '',
};

const RolesContainer = () => {
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const { searchText } = state;
  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };


  const variables = {
    name: searchValue
  };

  const {
    loading, data, refetch
  } = useQuery(GET_BUSINESS_MP_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const {
    businessMpAccounts = [], businessMpAccountsTotalNumber = 0
  } = data || {};

  return (
    <MainContent data-testid="main-component">
      <TitleGridWrapper>
        <TitleGrid />
      </TitleGridWrapper>
      <OutletContentWrapper container spacing={3}>
        <SideNav />
        <ConnectMarketplace
          state={state}
          rows={businessMpAccounts}
          businessMpAccountsTotalNumber={businessMpAccountsTotalNumber}
          loading={loading}
          refetch={refetch}
          handleSearch={handleSearch}
        />
      </OutletContentWrapper>
    </MainContent>
  );
};

export default RolesContainer;
