import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';

export const MenuItemStyled = styled(MenuItem)`
  padding: 1rem;

  &:hover {
    background: #f3f9ff;
  }
`;
