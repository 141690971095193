import {
  TableRow,
  TableCell,
  InputBase,
  MenuItem,
  Select
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #f3f9ff;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  padding: 10px;
  border: 1px solid #e9e9e9;
  text-transform: capitalize;

  @media (min-width: 992px) {
    font-size: 15px;
  }
`;

export const CustomInputNumberBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 40%;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 16px 12px;
    padding-right: 5px;
    line-height: normal;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const InputField = styled('input')`
  width: 100%;
  font-family: Montserrat;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  color: #606060;
  background: transparent;
`;

export const MenuItemStyled = styled(MenuItem)``;

export const SelectStyled = styled(Select)`
  width: 100%;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  color: #606060;
  background: transparent;
  height: 1.5rem;

  & .MuiOutlinedInput-notchedOutline {
    border-width: 0;
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 0;
  }
`;
