import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import Products from './products/products';
import Cart from './cart/cart';
import IndividualProductDialog from '../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import SelectBatchDialog from './products/selectBatchDialog';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
} from './salesContainer.styles';
import CreateBatches from './createBatches';
import CreateProduct from './createProduct';
import ActionButtonsPopper from './actionButtonsPopper';

import { useStateValue } from '../../providers/stateProvider';
import ConfirmDialog from '../shared/confirmDialog';
import StoresDialog from '../shared/stores/storesDialog';
import AppToast from '../shared/appToast';
import { usePreferencePermissionProductBatch } from '../../utils/funcs';

// confirm dialog initial state
const confirmDialogInitialState = {
  title: '',
  desc: '',
  options: [
    'No',
    'Yes'
  ]
};

const SalesContainer = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openProdDialog, setOpenProdDialog] = useState(false);
  const [confirmStoreStatus, setConfirmStoreStatus] = useState(false);
  const [openStoreModal, setOpenStoreModal] = useState(false);
  const [confirmDialogState, setConfirmDialogState] = useState(confirmDialogInitialState);
  const [storeIds, setStoreIds] = useState([]);
  const [storeConfirmDialog, setStoreConfirmDialog] = useState(false);
  const [editFunctionObj, setEditFunctionObj] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: '',
    description: ''
  });

  const [{
    user, product: { addProductDialogOpen }
  }, dispatch] = Object.values(useStateValue());
  const {
    businessPreference, allowedPermissionsMap: { userPermissions }
  } = user;
  const allowed = usePreferencePermissionProductBatch(businessPreference);

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, [dispatch]);

  const toggleIndividualDialog = () => {
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const productBatchPreference = (type) => {
    if (!allowed) {
      setOpenToast(true);
      if (type === 'product') {
        setToastMessage({
          title: 'Product Creation Disabled!',
          description: 'Oops! You can\'t create products at this time. Please reach out to your central store for authorization.'
        });
      } else {
        setToastMessage({
          title: 'Batch Creation Disabled!',
          description: 'Oops! You can\'t upload product batches at this time. Please reach out to your central store for authorization.'
        });
      }
      return false;
    }
    return true;
  };

  const toggleCreateBatchHandler = () => {
    if (!productBatchPreference('batch')) return;
    setOpenDialog(!openDialog);
  };

  const toggleCreateProductHandler = () => {
    setOpenProdDialog(!openProdDialog);
  };

  const handleStore = (obj) => {
    if (userPermissions?.includes('cm_dashboard_enable_product_sync_to_stores')) {
      setEditFunctionObj(obj);
      setConfirmDialogState({
        ...confirmDialogState,
        title: 'Enable Cross-Store Product Creation',
        desc: 'Clicking yes, means that the product will be created across all stores with unified pricing.',
        options: ['No, Proceed', 'Yes, Proceed']
      });
      setStoreConfirmDialog(true);
    } else {
      obj.func();
      setStoreIds([]);
    }
  };

  const handleStoreCreate = (type) => {
    setEditFunctionObj(null);
    if (userPermissions?.includes('cm_dashboard_enable_product_sync_to_stores')) {
      setConfirmDialogState({
        ...confirmDialogState,
        title: 'Enable Cross-Store Product Creation',
        desc: 'Clicking yes, means that the product will be created across all stores with unified pricing.',
        options: ['No, Proceed', 'Yes, Proceed']
      });
      setStoreConfirmDialog(true);
    } else {
      if (type === 'individual_prod') {
        toggleIndividualDialog();
      } else {
        toggleCreateProductHandler();
      }
      setStoreIds([]);
    }
  };

  const handleAction = (type, setOpen) => {
    setActionType(type);
    handleStoreCreate(type);
    setOpen(false);
  };

  if (confirmStoreStatus) {
    setOpenStoreModal(true);
    setStoreConfirmDialog(false);
    // Set confirmation status to false to avoid infinite loop
    setConfirmStoreStatus(false);
    // set selected stores back to empty array
    setStoreIds([]);
  }

  const handleDeclineConfirmation = () => {
    if (actionType === 'individual_prod') {
      if (editFunctionObj) editFunctionObj.func();
      else toggleIndividualDialog();
    } else {
      toggleCreateProductHandler();
    }
    setEditFunctionObj(null);
    setStoreIds([]);
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item justifyContent="space-between">
          <TitleTextGridContainer item>
            <PrimaryTitle variant="h5">Sales</PrimaryTitle>
          </TitleTextGridContainer>
          <Grid item>
            <ActionButtonsPopper
              data-testid="action-popper"
              handleAction={handleAction}
              createBatchHandler={toggleCreateBatchHandler}
              productBatchPreference={productBatchPreference}
            />
          </Grid>
        </TitleGridContainer>
        <Grid item container spacing={2}>
          <Grid item container xs={7}>
            <Products handleStore={handleStore} setActionType={setActionType} productBatchPreference={productBatchPreference} />
          </Grid>
          <Grid item container xs={5}>
            <Cart />
          </Grid>
        </Grid>
      </OrderGridContainer>

      <IndividualProductDialog
        dialogOpen={addProductDialogOpen}
        closeDialog={() => toggleIndividualDialog()}
        storeIds={storeIds}
        setStoreIds={setStoreIds}
      />
      <SelectBatchDialog />
      <CreateBatches
        openDialog={openDialog}
        toggleHandler={toggleCreateBatchHandler}
      />
      <CreateProduct
        openProdDialog={openProdDialog}
        toggleHandler={toggleCreateProductHandler}
        storeIds={storeIds}
      />
      <ConfirmDialog
        openDialog={storeConfirmDialog}
        setOpenDialog={setStoreConfirmDialog}
        title={confirmDialogState.title}
        desc={confirmDialogState.desc}
        options={[confirmDialogState.options[0], confirmDialogState.options[1]]}
        setStatus={setConfirmStoreStatus}
        declineConfirmation
        declineConfirmationAction={handleDeclineConfirmation}
      />
      <StoresDialog
        openDialog={openStoreModal}
        setOpenDialog={setOpenStoreModal}
        storeIds={storeIds}
        setStoreIds={setStoreIds}
        actionType={actionType}
        toggleDialog={toggleIndividualDialog}
        toggleCSVDialog={toggleCreateProductHandler}
        editFunction={editFunctionObj}
      />
      <AppToast openToast={openToast} setOpenToast={setOpenToast} toastMessage={toastMessage} />

    </MainContent>
  );
};

export default SalesContainer;
export { SalesContainer as PureSalesContainer };
