import React from 'react';
import { styled } from '@mui/material/styles';
import { Switch, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

const SwitchMethod = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 65,
  height: 33,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    color: '#C5E2FF',
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: '#235A91',
      '& + .MuiSwitch-track': {
        backgroundColor: '#F3F9FF'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 28,
    height: 28
  },
  '& .MuiSwitch-track': {
    border: '1px solid #E7E8E9',
    borderRadius: 28 / 2,
    backgroundColor: '#C5E2FF'
  }
}));

const SwitchToggle = ({ checkedState, setCheckedState }) => {
  const switchHandler = (event) => {
    setCheckedState(event.target.checked);
  };
  return (
    <FormControlLabel
      onChange={switchHandler}
      control={<SwitchMethod sx={{ m: 1 }} />}
      checked={checkedState}
      data-testid="switch-toggle"
      // label={label}
    />
  );
};

SwitchToggle.propTypes = {
  checkedState: PropTypes.bool,
  setCheckedState: PropTypes.func.isRequired,
};

SwitchToggle.defaultProps = {
  checkedState: false,
};

export default SwitchToggle;
