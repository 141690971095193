import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Fade } from '@mui/material';
import currencyFormatter from '../../../utils/currencyFormatter';
import { EyeOutline } from '../../../assets/svgs';
import CustomButton from '../../customComponents/customButton';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  PartiallyReceivedDialog,
  DialogWrapper,
  DialogHeader,
  DialogBody,
  // DialogActions,
  CloseIcon,
  TableHeader,
  TableHeaderRow,
  MainTable,
  // HeaderGrid,
  CenterTextGrid,
  MainTableRow,
  BodyCell,
  Header,
  BrandName,
  PackSize,
  DFlex,
  DColumn
} from './reconciledOrderModal.styles';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const ReconciledOrderModal = ({ open, close, cartItem }) => {
  const { order } = cartItem || {};
  const headers = [
    'Product Name',
    'Qty Received',
    'Unit Cost',
    'Price',
    'Action'
  ];

  // const [filteredList, setF]
  const [search, setSearch] = useState('');

  return (
    <PartiallyReceivedDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      maxWidth="sm"
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <Grid item container xs={6}>
            <Header variant="h5">Reconciled Orders</Header>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={close} />
          </Grid>
        </DialogHeader>
        <DialogBody item container>
          <CustomSearchField
            name="search"
            value={search}
            fullWidth
            placeholder="Search"
            handleChange={(e) => setSearch(e.target.value)}
            style={{ marginBottom: '1.2rem' }}
          />
          <MainTable>
            <TableHeaderRow item container>
              {headers.map((header) => (
                <TableHeader>{header}</TableHeader>
              ))}
            </TableHeaderRow>
            {order?.length ? (
              order
                ?.filter((item) => (search.toLowerCase() === ''
                  ? item
                  : item?.brandName.toLowerCase().includes(search.toLowerCase())))
                ?.map((item) => {
                  const {
                    orderStatus,
                    image,
                    brandName,
                    quantityRecieved,
                    orderCost,
                    packSize
                  } = item;
                  if (orderStatus === 'MANUAL_ITEM_RECONCILED' || orderStatus === 'AUTOMATIC_ITEM_RECONCILED') {
                    return (
                      <MainTableRow key={item}>
                        <BodyCell>
                          <DFlex>
                            <img
                              src={image}
                              alt="logo"
                              width="60"
                              height="60"
                            />
                            <DColumn>
                              <BrandName>{brandName}</BrandName>
                              <PackSize>{`Pack size ${packSize}`}</PackSize>
                            </DColumn>
                          </DFlex>
                        </BodyCell>
                        <BodyCell>{quantityRecieved}</BodyCell>
                        <BodyCell
                          style={{ fontWeight: '600', fontSize: '15px' }}
                        >
                          {currencyFormatter(orderCost)}
                        </BodyCell>
                        <BodyCell
                          style={{ fontWeight: '600', fontSize: '15px' }}
                        >
                          {currencyFormatter(
                            Number(quantityRecieved) * Number(orderCost)
                          )}
                        </BodyCell>
                        <BodyCell>
                          <CustomButton type="secondary" style={{ width: '8rem' }} header>
                            <EyeOutline style={{ marginRight: '.3rem', width: '1.1rem' }} />
                            <span style={{ fontSize: '11px', fontWeight: '500' }}>View Batches</span>
                          </CustomButton>
                        </BodyCell>
                      </MainTableRow>
                    );
                  }
                })
            ) : (
              <MainTableRow>
                <BodyCell className="text-center" colSpan={5}>No Record Found</BodyCell>
              </MainTableRow>
            )}
          </MainTable>
        </DialogBody>
      </DialogWrapper>
    </PartiallyReceivedDialog>
  );
};

ReconciledOrderModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  cartItem: PropTypes.instanceOf(Array).isRequired
};

ReconciledOrderModal.defaultProps = {
  open: false
};

export default ReconciledOrderModal;
