import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { useStateValue } from '../../../providers/stateProvider';
import { editCustomDateRange } from '../dateRangeConverter';
import { DateWrapper } from './dateRangeSelector.styles';
import BusinessActionTypes from '../../../providers/reducers/business/businessTypes';

const DateRange = ({ open, setOpen }) => {
  const [dateRange, setDateRange] = React.useState({});
  const [, dispatch] = Object.values(useStateValue());

  const toggle = () => setOpen(!open);

  const onChange = (range) => {
    const {
      start, end,
      event, dateRange: timeRange
    } = editCustomDateRange(range);
    setDateRange(range);
    setOpen(false);
    dispatch({
      type: BusinessActionTypes.UPDATE_DASHBOARD_STATE, payload: {
        active: event,
        startDate: start,
        endDate: end,
        range: timeRange
      }
    });
    // dispatch the action to update the state with the start from and start to
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateWrapper>
        <DateRangePicker
          open={open}
          toggle={toggle}
          onChange={onChange}
        />
      </DateWrapper>
    </LocalizationProvider>
  );
};

DateRange.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default DateRange;
