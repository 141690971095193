import { gql } from '@apollo/client';

export const CREATE_BUSINESS_MUTATION = gql`
  mutation createBusiness(
    $legalName: String!
    $contactName: String!
    $businessType: String
    $logo: String
    $mobile: String!
    $needsLoan: Boolean
    $email: String!
    $secondaryEmailAddress: String
    $tax: Float
    $addressLine1: String!
    $addressLine2: String
    $createdBy: String
    $city: String!
    $country: String!
    $terms: Boolean!
    $creditDays: Int
    $salesRepresentative: String
    $landmark: String
    $state: String
  ) {
    createBusiness(
      legalName: $legalName
      businessType: $businessType
      primaryContactName: $contactName
      logo: $logo
      mobileNumber: $mobile
      needsLoan: $needsLoan
      primaryEmailAddress: $email
      secondaryEmailAddress: $secondaryEmailAddress
      tax: $tax
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      country: $country
      terms: $terms
      createdBy: $createdBy
      creditDays: $creditDays
      salesRepresentative: $salesRepresentative
      landmark: $landmark
      state: $state
    ) {
      message
      business {
        id
        status
      }
      user {
        id
        businessuserSet {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_BUSINESS_MUTATION = gql`
  mutation UpdateBusiness(
    $id: Int!
    $businessName: String
    $primaryContactName: String
    $phoneNumber1: String
    $phoneNumber2: String
    $primaryEmailAddress: String
    $secondaryEmailAddress: String
    $addressLine1: String
    $addressLine2: String
    $state: String
    $city: String
  ) {
    updateBusiness(
      id: $id
      businessName: $businessName
      primaryContactName: $primaryContactName
      phoneNumber1: $phoneNumber1
      phoneNumber2: $phoneNumber2
      primaryEmailAddress: $primaryEmailAddress
      secondaryEmailAddress: $secondaryEmailAddress
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      state: $state
      city: $city
    ) {
      message
      business {
        id
        status
      }
    }
  }
`;
export const UPDATE_PREFERENCE_SETTINGS_MUTATION = gql`
  mutation updatePreferencesSetting(
    $automaticOrderingRangeInMonths: Int
    $loyaltyPointValue: Int
    $orderCostFormat: String
    $slowMovingProductLimit: Int
    $topMovingProductLimit: Int
    $zeroSalesFormat: Boolean
    $automaticSales: Boolean
    $restrictProductAndBatchCuOnChildStores: YesOrNoEnum
    $businessExcludedFromRestriction: Int
    $vatSetting: VATPreferenceInput
  ) {
    updatePreferencesSetting(
      automaticOrderingRangeInMonths: $automaticOrderingRangeInMonths
      loyaltyPointValue: $loyaltyPointValue
      orderCostFormat: $orderCostFormat
      slowMovingProductLimit: $slowMovingProductLimit
      topMovingProductLimit: $topMovingProductLimit
      zeroSalesFormat: $zeroSalesFormat
      automaticSales: $automaticSales
      restrictProductAndBatchCuOnChildStores: $restrictProductAndBatchCuOnChildStores
      businessExcludedFromRestriction: $businessExcludedFromRestriction
      vatSetting: $vatSetting
    ) {
      message
    }
  }
`;

export const DELETE_SUB_BUSINESS_PRODUCT_VERSIONS = gql`
  mutation deleteSubBusinessProductVersions {
    deleteSubBusinessProductVersions {
      message
    }
  }
`;

export const OFFLINE_SYNC = gql`
  mutation sync {
    sync {
      message
    }
  }
`;

export const CREATE_SUB_BUSINESS_MUTATION = gql`
  mutation createSubBusiness(
    $name: String!
    $dateLaunched: String!
    $businessType: String!
    $primaryContactName: String!
    $mobileNumber: String!
    $primaryEmailAddress: String!
    $addressLine1: String!
    $city: String!
    $country: String!
  ) {
    createSubBusiness(
      name: $name
      primaryContactName: $primaryContactName
      businessType: $businessType
      dateLaunched: $dateLaunched
      mobileNumber: $mobileNumber
      primaryEmailAddress: $primaryEmailAddress
      addressLine1: $addressLine1
      city: $city
      country: $country
    ) {
      subBusiness {
        name
        meta
      }
      message
    }
  }
`;

export const UPDATE_BUSINESS_ROLE_PERMISSIONS = gql`
  mutation updateBusinessRolePermissions(
    $businessRoleId: Int!
    $permissions: [Int]!
    $reasonForUpdate: String!
  ) {
    updateBusinessRolePermissions(
      businessRoleId: $businessRoleId
      permissions: $permissions
      reasonForUpdate: $reasonForUpdate
    ) {
      message
    }
  }
`;

export const CREATE_BUSINESS_ROLE = gql`
  mutation createSystemRole($permissionNames: [String]!, $name: String!) {
    createSystemRole(permissionNames: $permissionNames, name: $name) {
      message
    }
  }
`;

export const DELETE_BUSINESS_ROLE = gql`
  mutation deleteSystemRole($roleId: Int!) {
    deleteSystemRole(roleId: $roleId) {
      message
    }
  }
`;

export const UPDATE_BUSINESS_ROLE = gql`
  mutation editSystemRole(
    $roleId: Int!
    $updatedName: String!
    $permissionNames: [String]
  ) {
    editSystemRole(
      roleId: $roleId
      updatedName: $updatedName
      permissionNames: $permissionNames
    ) {
      message
    }
  }
`;

export const SIGNUP_FLOW = gql`
  mutation SignUp(
    $businessName: String!
    $businessOwnerFullName: String!
    $businessAddress: String!
    $emailAddress: String!
    $phoneNumber: String
    $businessPlan: String
  ) {
    signup(
      businessName: $businessName
      businessOwnerFullname: $businessOwnerFullName
      businessAddress: $businessAddress
      emailAddress: $emailAddress
      phoneNumber: $phoneNumber
      businessPlan: $businessPlan
    ) {
      message
      user {
        id
        username
        profile
      }
      business {
        id
        name
        contacts
        profile
        subscriptionPlan
        createdBy {
          username
        }
        businessuserSet {
          id
          user {
            username
          }
        }
      }
      token
      restToken
    }
  }
`;

export const GENERATE_OTP = gql`
  mutation GenerateOTP($email: String!) {
    generateOtp(email: $email) {
      message
      otp
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP($otp: String!) {
    verifyOtp(otp: $otp) {
      message
    }
  }
`;

export const CREATE_SUB_BUSINESS = gql`
  mutation CreateSubBusiness(
    $businessName: String!
    $businessAddress: String
    $emailAddress: String
    $phoneNumber: String
    $businessPlan: String
  ) {
    createSubBusiness(
      businessName: $businessName
      businessAddress: $businessAddress
      emailAddress: $emailAddress
      phoneNumber: $phoneNumber
      businessPlan: $businessPlan
    ) {
      message
      business {
        id
        name
        meta
        status
        contacts
        parentBusiness
        subscriptionPlan
        billingAmount
        parentId
        createdBy {
          id
          username
        }
      }
    }
  }
`;

export const UPDATE_BUSINESS_STATUS = gql`
  mutation UpdateBusinessStatus(
    $businessId: Int!
    $status: ApprovalStatusEnum!
  ) {
    updateBusinessStatus(businessId: $businessId, status: $status) {
      message
      business {
        id
        name
        status
      }
    }
  }
`;

export const LINK_ACCOUNT_TO_MP = gql`
  mutation linkMPAccount($linkMpAccountData: LinkMPAccountInputType!){
  linkMpAccount(linkMpAccountData: $linkMpAccountData){
    message
  }
}
`;
