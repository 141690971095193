import { gql } from '@apollo/client';

export const MANUAL_BATCH_CREATION = gql`
  mutation addManualBatch($batchProducts: [ManualBatchProductInputType]!) {
    updateManualBatchProducts(batchProducts: $batchProducts) {
      message
    }
  }
`;

export const ADD_BATCH = gql`
  mutation addManualBatchToInventory {
    addManualBatchToInventory {
      message
    }
  }
`;
