import navbar from './reducers/navbar/navbarState';
import cart from './reducers/cart/cartState';
import catalogue from './reducers/catalogue/catalogueState';
import supplier from './reducers/supplier/supplierState';
import affiliate from './reducers/affiliate/affiliateState';
import product from './reducers/product/productState';
import returns from './reducers/returns/returnsState';
import sale from './reducers/sales/salesState';
import model from './reducers/model/modelState';
import reconciliation from './reducers/reconciliation/reconciliationState';
import offline from './reducers/offline/offlineState';
import business from './reducers/business/businessState';
import user from './reducers/user/userState';
import order from './reducers/order/orderState';
import ordersReport from './reducers/ordersReport/ordersReportState';
import helpDesk from './reducers/helpDesk/helpDeskState';
import stockTransfer from './reducers/stockTransfer/stockTransferState';
import backOrder from './reducers/backOrder/backOrderState';
import receiveManualOrder from './reducers/receiveManualOrders/receiveManualOrderState';
import promotions from './reducers/promotions/promotionsState';
import imageUpload from './reducers/imageUpload/imageUploadState';
import notifications from './reducers/notifications/notificationsState';

const initialState = {
  navbar,
  cart,
  catalogue,
  supplier,
  affiliate,
  product,
  returns,
  sale,
  reconciliation,
  model,
  offline,
  business,
  user,
  order,
  ordersReport,
  helpDesk,
  stockTransfer,
  backOrder,
  receiveManualOrder,
  promotions,
  imageUpload,
  notifications
};

export default initialState;
