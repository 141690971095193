import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox
} from '@mui/material';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  SupplierOrderFormsGridItem,
  SupplierOrderFormsDateCheckContainer,
  DateCreatedText,
  OrderIdText,
  OrderQuantityText,
  PharmacyNameText,
  OrderAmountText,
  SupplierOrderFormsBottomWrapper,
  Box,
} from './supplierOrders.styles';

import currencyFormatter from '../../../utils/currencyFormatter';

const SupplierOrderRow = ({
  order,
  toggleItemInCart,
  active
}) => {
  const navigate = useNavigate();
  const {
    id: orderId,
    business: { name },
    products,
    totalPrice,
    payload
  } = order;

  const returnDate = ({ lastUpdated }) => moment(lastUpdated).format('DD/MM/YYYY');
  const returnDateTime = ({ lastUpdated }) => moment(lastUpdated).format('HH:mm a');

  return (
    <Box>
      <SupplierOrderFormsGridItem
        onClick={() => {
          navigate(`/orders-admin/dispatch/${orderId}`);
        }}
      >
        <SupplierOrderFormsDateCheckContainer>
          <DateCreatedText>
            {returnDate(order)}
            &nbsp;
            &nbsp;
            &nbsp;
            {returnDateTime(order)}
          </DateCreatedText>
          <Checkbox
            onClick={(event) => {
              event.stopPropagation();
              toggleItemInCart(payload);
            }}
            name="terms"
            color="secondary"
            checked={active}
          />
        </SupplierOrderFormsDateCheckContainer>

        <PharmacyNameText>
          { name }
        </PharmacyNameText>

        <OrderIdText>
          ID:&nbsp;
          {orderId}
        </OrderIdText>

        <OrderQuantityText>
          { products.length }
          &nbsp;Product(s)
        </OrderQuantityText>

        <SupplierOrderFormsBottomWrapper>
          <OrderAmountText>
            { currencyFormatter(totalPrice) }
            {' '}
          </OrderAmountText>
        </SupplierOrderFormsBottomWrapper>
      </SupplierOrderFormsGridItem>
    </Box>
  );
};

SupplierOrderRow.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  toggleItemInCart: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired
};

export default SupplierOrderRow;
