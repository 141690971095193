import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  DialogActions, DialogContent, Slide, Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import CustomInputBase from '../../../customComponents/customInputBase';
import CustomButton from '../../../customComponents/customButton';
import {
  DialogTitleText, DialogTitleSubText, CircularProgressLoader, GridWrapper,
  CDialog, CloseIcon
} from './individualManufacturerDialog.styles';
import {
  CREATE_MANUFACTURER_MUTATION, UPDATE_MANUFACTURER_MUTATION
} from '../../../../mutations/manufacturers';
import { blockInvalidChar } from '../../../../utils/mobileCheck';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualManufacturerDialog = ({
  dialogOpen, closeDialog, fromProduct
}) => {
  const initialState = {
    name: '',
    term: 'Cash on delivery',
    city: 'Lagos',
    country: 'Nigeria',
    manufacturerId: '',
    providerPromo: '',
  };

  const initialErrorState = {
    nameError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [creditDays, setCreditDays] = useState(14);
  const [editing, setEditing] = useState(false);

  const { nameError } = errorState;

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    closeDialog();
  };

  const [createManufacturer] = useMutation(CREATE_MANUFACTURER_MUTATION);
  const addManufacturer = () => {
    const {
      name, term, city, country, providerPromo,
    } = state;

    const newTerm = term === 'On-credit payment'
      ? `${creditDays} Credit days` : term;

    createManufacturer({
      variables: {
        name,
        term: newTerm,
        city,
        country,
        providerPromo,
      }
    })
      .then(({ data }) => {
        const { message } = data?.createManufacturer || {};
        toast.success(message);
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [updateManufacturer] = useMutation(UPDATE_MANUFACTURER_MUTATION);
  const editManufacturer = () => {
    const {
      manufacturerId, name, term, city, country, providerPromo
    } = state;

    const newTerm = term === 'On-credit payment'
      ? `${creditDays} Credit days` : term;

    updateManufacturer({
      variables: {
        manufacturerId,
        name,
        term: newTerm,
        city,
        country,
        providerPromo,
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateManufacturer || {};
        toast.success(message);
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setLoading(true);
    if (editing) return editManufacturer();
    return addManufacturer();
  };

  const handleSaveButtonClick = () => {
    const { name } = state;
    if (name.length < 3) {
      return setErrorState({
        ...errorState, nameError: true
      });
    }
    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'name':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 3)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'mobile' && value.length > 11) return;
    setState({ ...state, [name]: value });
  };

  const fields = [
    {
      name: 'name', label: "Manufacturer's Name", error: nameError, helperText: 'Manufacturer name requires more than 3 letters', required: true,
      placeholder: "Enter Manufacturer's Name",
    },
    {
      name: 'term', label: 'Payment Term', options: ['Payment before delivery', 'Cash on delivery', 'On-credit payment'], placeholder: 'Select'
    },
    {
      name: 'providerPromo', label: 'Provider Promo', options: ['GPS'], placeholder: 'Select'
    },
    { name: 'city', label: 'City', placeholder: 'Select' },
    {
      name: 'country', label: 'Country', options: ['Nigeria', 'Uganda', 'Kenya'], placeholder: 'Select'
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, error, required, placeholder
    } = field;
    const value = state[fieldName];
    const numberFields = ['mobile', 'markup'];
    if (['term', 'country', 'providerPromo'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          placeholder={placeholder}
          handleChange={handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
        />
      );
    }
    return (
      <CustomInputBase
        variant="filled"
        label={label}
        value={value}
        size="small"
        type={numberFields.includes(fieldName) ? 'number' : 'text'}
        error={error}
        helperText={error && helperText}
        required={required}
        name={fieldName}
        placeholder={placeholder}
        onKeyDown={fieldName === 'mobile' && blockInvalidChar}
        onChange={handleChange}
        cSize="lg"
      />
    );
  };

  return (
    <CDialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
      fileslimit={1}
      disableEnforceFocus
      data-testid="manufacturer-dialog"
    >
      <GridWrapper container item>
        <Grid container style={{ paddingTop: '1.5rem' }}>
          <Grid item container xs={6}>
            <DialogTitleText>
              {editing ? 'Edit Manufacturer' : 'Add Manufacturer'}
            </DialogTitleText>
            <DialogTitleSubText>
              {editing
                ? 'Edit the form to update Manufacturer information'
                : 'Add Manufacturer information to the fields in the form'}
            </DialogTitleSubText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingTop: '8px' }}>
            {fields.map((field) => (
              <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: '2rem' }}>
          <CustomButton
            type="secondary"
            header
            style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }}
            onClick={handleCloseDialog}
          >
            Cancel
          </CustomButton>
          <CustomButton
            type="tertiary"
            header
            disabled={loading}
            onClick={handleSaveButtonClick}
            style={{ width: '11rem', height: '2.7rem' }}
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : editing ? 'Save Changes' : 'Save'}
          </CustomButton>
        </DialogActions>
      </GridWrapper>
    </CDialog>
  );
};

IndividualManufacturerDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  fromProduct: PropTypes.bool
};

IndividualManufacturerDialog.defaultProps = {
  fromProduct: false,
};

export default IndividualManufacturerDialog;
