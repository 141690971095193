import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyCell, MainTableRow
} from './returnRow.styles';
import currencyFormatter from '../shared/currencyFormatter';

const ReturnRow = ({
  row, index, pageNumber, pageCount, toggleTab
}) => {
  const {
    name, supplierProductsCount, manufacturerProductCount, totalProductValue, supplierProductsValue
  } = row;

  const value = currencyFormatter(toggleTab ? supplierProductsValue : totalProductValue);
  const serialNumber = pageNumber === 1 ? index + 1 : (pageNumber - 1) * pageCount + index + 1;
  const itemCells = [
    serialNumber, name, toggleTab ? supplierProductsCount : manufacturerProductCount, value
  ];

  const returnCell = (val) => val ?? 'N/A';

  const renderBodyCells = () => (
    itemCells.map((cell) => (
      <BodyCell style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
    ))
  );

  return (
    <MainTableRow>
      {renderBodyCells()}
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.instanceOf(Number),
  pageCount: PropTypes.instanceOf(Number).isRequired,
  pageNumber: PropTypes.instanceOf(Number).isRequired,
  toggleTab: PropTypes.bool.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  index: 0,
};

export default ReturnRow;
