import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid, InputAdornment
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import DropZone from '../../../shared/logoDropzone';
import ReturnSelectField from './returnSelectField';
import CropImageContainer from '../../../shared/logoProcess/cropImageContainer';
import InitialImage from '../../../shared/logoProcess/initialImage';
import SavePopper from './savePopper';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader, UploadGrid,
  GridWrapper, TopGrid, MiddleGrid, BottomGrid, SupplierTextField, FormLabel,
  MessageBoxTextField, MessageBoxContainer, ReasonGrid
} from './individualAffiliateDialog.styles';
import {
  CREATE_BUSINESS_MUTATION, UPDATE_BUSINESS_MUTATION
} from '../../../../mutations/business';
import { validateEmail, validateNigerianNumber } from '../../../auth/utils';
import { parseAffiliateFields } from '../../utils';
import AffiliateActionTypes from '../../../../providers/reducers/affiliate/affiliateTypes';

import { useStateValue } from '../../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualAffiliateDialog = ({
  dialogOpen, closeDialog
}) => {
  const initialState = {
    legalName: '',
    contactName: '',
    businessType: 'affiliate',
    mobile: '',
    email: '',
    secondaryEmailAddress: '',
    tax: '',
    status: '',
    addressLine1: '',
    addressLine2: '',
    city: 'Lagos',
    country: 'Nigeria',
    id: '',
    logo: '',
    reasonForUpdate: '',
    creditDays: '',
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
    reasonError: false,
    mobileError: false,
    contactNameError: false,
    addressLine1Error: false,
    creditDaysError: false
  };

  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [editing, setEditing] = useState(false);
  const [showInitialImage, setShowInitialImage] = useState(false);
  const [reasonBox, setReasonBox] = useState(false);
  const [initialTax, setInitialTax] = useState();
  const [initialStatus, setInitialStatus] = useState();

  const [{
    affiliate: { affiliate, refetch, affiliateDetailRefetch }
  }, dispatch] = Object.values(useStateValue());

  const role = localStorage.getItem('oga_user_role');
  const isAdmin = role === 'oga-pharmacy-admin' || role === 'chain-manager';

  const [createBusiness] = useMutation(CREATE_BUSINESS_MUTATION);
  const [updateBusiness] = useMutation(UPDATE_BUSINESS_MUTATION);

  const {
    nameError, reasonError, contactNameError, addressLine1Error, mobileError, emailError
  } = errorState;
  useEffect(() => {
    if (affiliate) {
      const {
        id, legalName, logo, city, country, mobile, addressLine1,
        addressLine2, contactName, email, secondaryEmailAddress, tax, status, creditDays
      } = parseAffiliateFields(affiliate);
      setEditing(true);
      setInitialTax(tax);
      setInitialStatus(status);
      setShowInitialImage(true);
      setState((_state) => ({
        ..._state, legalName, contactName, email, secondaryEmailAddress, status,
        mobile, addressLine1, addressLine2, city, country, id, logo, tax, creditDays
      }));
    }
  }, [affiliate]);

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: { affiliate: null }
    });
    setFinalImage(null);
    closeDialog();
  };

  const handleFile = (filesData) => {
    setFiles(filesData);
  };

  const handleChangeInitialImage = () => {
    setShowInitialImage(false);
  };

  const handleDeleteInitialImage = () => {
    setShowInitialImage(false);
    setState({ ...state, logo: '' });
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const addAffiliate = (logo, addMore) => {
    const {
      legalName, contactName, mobile, email, secondaryEmailAddress,
      businessType, addressLine1, addressLine2, city, country, tax, creditDays
    } = state;

    createBusiness({
      variables: {
        legalName,
        contactName,
        businessType,
        logo,
        mobile,
        email,
        secondaryEmailAddress,
        tax: parseFloat(tax),
        addressLine1,
        addressLine2,
        city,
        country,
        terms: false,
        createdBy: email,
        creditDays: parseInt(creditDays)
      }
    })
      .then(({ data }) => {
        const { message } = data?.createBusiness || {};
        toast.success(message);
        refetch();
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editAffiliate = (logo) => {
    const {
      id, legalName, contactName, mobile, email, secondaryEmailAddress,
      addressLine1, addressLine2, city, country, tax, status, reasonForUpdate, creditDays
    } = state;
    updateBusiness({
      variables: {
        id,
        legalName,
        contactName,
        logo,
        mobile,
        email,
        secondaryEmailAddress,
        tax: parseFloat(tax),
        status,
        addressLine1,
        addressLine2,
        city,
        country,
        terms: false,
        reasonForUpdate,
        creditDays: parseInt(creditDays)
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateBusiness || {};
        toast.success(message);
        affiliateDetailRefetch();
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async (addMore = false) => {
    setSaveButtonEl(null);
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594134434/Placeholders/Affliate_Placeholder.png';
    const alreadyUploadedImage = state.logo || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;

    if (editing) return editAffiliate(logo);
    return addAffiliate(logo, addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
    setFiles(null);
    setFinalImage(null);
  };

  const lagal = () => {
    const {
      legalName, contactName, mobile, addressLine1, email, creditDays
    } = state;
    if (legalName.length < 3) return setErrorState({ ...errorState, nameError: true });
    if (contactName.length < 3) return setErrorState({ ...errorState, contactNameError: true });
    if (validateNigerianNumber(mobile)) return setErrorState({ ...errorState, mobileError: true });
    if (addressLine1.length < 3) return setErrorState({ ...errorState, addressLine1Error: true });
    if (validateEmail(email)) return setErrorState({ ...errorState, emailError: true });
    // if (!creditDays 0) return setErrorState({ ...errorState, creditDaysError: true });
    return true;
  };

  const handleSaveButtonClick = (event) => {
    const {
      reasonForUpdate
    } = state;
    const details = lagal();
    if (details && !editing) {
      return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
    } if (details && editing && reasonForUpdate.length > 1) {
      return handleSave();
    } if (details && editing && !reasonBox) {
      return handleSave();
    } if (reasonBox && reasonForUpdate.length < 1) {
      return setErrorState({
        ...errorState,
        reasonError: true
      });
    }
    return null;
  };
  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length >= 3)
        });
      case 'contactName':
        return setErrorState({
          ...errorState,
          contactNameError: !(value.length >= 3)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: validateNigerianNumber(value)
        });

      case 'addressLine1':
        return setErrorState({
          ...errorState,
          addressLine1Error: !(value.length >= 3)
        });
      // case 'creditDays':
      //   return setErrorState({
      //     ...errorState,
      //     nameError: !(value > 0)
      //   });
      case 'email':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1),
          emailError: validateEmail(value)
        });
      // case 'creditDays':
      //   return setErrorState({
      //     ...errorState,
      //     creditDaysError: !(value > 0)
      //   });
      case 'reasonForUpdate':
        return setErrorState({
          ...errorState,
          reasonError: !(value.length > 1)
        });
      default:
        return null;
    }
  };

  const displayreasonBox = (name, value) => {
    const newValue = name === 'tax' ? parseFloat(value) : value;
    if ((name === 'status' && initialStatus !== value) || (name === 'tax' && initialTax !== newValue)) {
      setReasonBox(true);
    } else if ((name === 'status' && initialStatus === value) || (name === 'tax' && initialTax === newValue)) {
      setReasonBox(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
    displayreasonBox(name, value);
  };

  const topFields = [
    {
      name: 'legalName',
      label: 'Legal Name',
      required: true,
      helperText: 'Affiliate name required and should be 3 or more characters'
    },
    {
      name: 'contactName',
      required: true,
      label: 'Primary Contact Name',
      helperText: 'Contact name required and should be 3 or more characters'
    },
    {
      name: 'mobile',
      label: 'Mobile',
      required: true,
      helperText: 'Please provide a valid mobile number'
    },
    {
      name: 'email',
      label: 'Primary Email Address',
      required: true,
      helperText: 'Please provide a valid email address'
    },
    { name: 'secondaryEmailAddress', label: 'Secondary Email Address' },
  ];

  const middleFields = [
    {
      name: 'addressLine1',
      label: 'Address Line 1',
      required: true,
      helperText: 'Address Line 1 required and should be 3 or more characters'
    },
    { name: 'addressLine2', label: 'Address Line 2' }
  ];

  const bottomFields = [
    { name: 'city', label: 'City', required: true, },
    { name: 'country', label: 'Country', options: ['Nigeria', 'Uganda', 'Kenya'] },
    { name: 'status', label: 'Status', options: ['PENDING', 'ACTIVE', 'IN_ACTIVE'] },
    { name: 'tax', label: 'Tax', endIcon: '%' },
    {
      name: 'creditDays',
      label: 'Credit Days',
      // helperText: 'Please provide a minimum day of 0'
    },

  ];

  const reasonField = [
    { name: 'reasonForUpdate', label: 'Reason For Update', helperText: 'This field is required' },
  ];

  const renderError = (name) => {
    switch (name) {
      case 'legalName': return nameError;
      case 'contactName': return contactNameError;
      case 'addressLine1': return addressLine1Error;
      case 'mobile': return mobileError;
      case 'email': return emailError;
      // case 'creditDays': return creditDaysError;
      default:
        return false;
    }
  };

  const renderHelperText = (fieldName) => {
    switch (fieldName) {
      case 'legalName': return nameError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'contactName': return contactNameError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'addressLine1': return addressLine1Error
        ? middleFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'mobile': return mobileError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'email': return emailError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      // case 'creditDays': return creditDaysError
      //   ? bottomFields.find(({ name }) => name === fieldName)?.helperText : '';
      default:
        return false;
    }
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, endIcon, required
    } = field;
    const value = state[fieldName];
    const disableStatus = (fieldName === 'status' && !affiliate) || !isAdmin;
    if (fieldName === 'country' || fieldName === 'status') {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
          disabled={disableStatus}
        />
      );
    }
    if (fieldName === 'reasonForUpdate') {
      return (
        <Grid>
          <FormLabel>Reason For Update*</FormLabel>
          <MessageBoxContainer>
            <MessageBoxTextField
              name={fieldName}
              value={value}
              multiline
              minRows={5}
              error={reasonError}
              helperText={reasonError && helperText}
              required={reasonBox}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ backgroundColor: '#f0f0f0', marginBottom: '4em' }}
            />
          </MessageBoxContainer>
        </Grid>
      );
    }
    if (fieldName === 'creaditDays' && isAdmin) {
      return (
        <SupplierTextField
          variant="filled"
          size="small"
          label={label}
          value={value}
          type="number"
          name={fieldName}
          onChange={handleChange}
        />
      );
    }
    return (
      <SupplierTextField
        variant="filled"
        size="small"
        label={label}
        value={value}
        type={fieldName === 'tax' || fieldName === 'creditDays' ? 'number' : 'text'}
        required={required || false}
        error={renderError(fieldName)}
        helperText={renderHelperText(fieldName)}
        name={fieldName}
        onChange={handleChange}
        disabled={fieldName === 'tax' && !isAdmin}
        InputProps={{
          endAdornment: (<InputAdornment position="end">{endIcon}</InputAdornment>)
        }}
      />
    );
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        filesLimit={1}
      >
        <Grid container>
          <GridWrapper container item xs={8}>
            <DialogTitle id="alert-dialog-slide-title">
              <DialogTitleWrapper container>
                <DialogTitleText>
                  {editing ? 'Edit Affiliate' : 'Add Affiliate' }
                </DialogTitleText>
                <DialogTitleSubText>
                  {editing
                    ? 'Edit the form to update affiliate information'
                    : 'Add affiliate information to the fields in the form'}
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>
            <DialogContent>
              <TopGrid item>
                {topFields.map((field) => returnTextField(field))}
              </TopGrid>
              <MiddleGrid item>
                {middleFields.map((field) => returnTextField(field))}
              </MiddleGrid>
              <BottomGrid item>
                {bottomFields.map((field) => returnTextField(field))}
              </BottomGrid>
              {editing && reasonBox ? (
                <ReasonGrid item>
                  {reasonField.map((field) => returnTextField(field))}
                </ReasonGrid>
              ) : ''}
            </DialogContent>

            <DialogActions>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    {editing ? 'Save Changes' : 'Save & ...'}
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
          <UploadGrid container item xs={4}>
            {showInitialImage ? (
              <InitialImage
                image={state.logo}
                handleImageChange={handleChangeInitialImage}
                handleImageDelete={handleDeleteInitialImage}
              />
            ) : (files && files.length ? (
              <CropImageContainer
                files={files}
                handleFile={handleFile}
                handleFinalImage={setFinalImage}
              />
            ) : (
              <DropZone
                handleFile={handleFile}
              />
            ))}
          </UploadGrid>

        </Grid>
      </Dialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

IndividualAffiliateDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default IndividualAffiliateDialog;
