import React from 'react';
import { useNavigate } from 'react-router-dom';
import ModelContainer from '../shared/crudModel/modelsContainer';
import { FIND_THERAPEUTIC_TYPE_QUERY } from '../../queries/therapeuticType';
import TherapeuticTypeCard from './therapeuticTypeCard';
import { SupplierCardGrid } from './therapeuticTypeCard.styles';
import IndividualTherapeuticTypeDialog from './individual/individualTherapeuticTypeDialog';

function TherapeuticTypes() {
  const navigate = useNavigate();
  return (
    <ModelContainer
      findModelsQuery={FIND_THERAPEUTIC_TYPE_QUERY}
      modelName="Product type"
      modelNamePlural="Product types"
      modelTotalNumber="productsTotalNumber"
      modelQueryName="allTherapeuticTypes"
      renderIndividualModel={(addModelDialogOpen, toggleIndividualDialog) => (
        <IndividualTherapeuticTypeDialog
          dialogOpen={addModelDialogOpen}
          closeDialog={toggleIndividualDialog}
        />
      )}
      renderModelCard={(model) => (
        model.map((mod) => (
          <SupplierCardGrid key={mod.id} item xs>
            <TherapeuticTypeCard
              id={mod.id}
              name={mod.therapeuticType}
              productCount={mod.therapeuticTypeProductCount}
            />
          </SupplierCardGrid>
        ))
      )}
      modelCardOnclickAction={(model) => navigate(`/therapeutic-type/${model.id}/details`)}
    />
  );
}

export default TherapeuticTypes;
