import { styled } from '@mui/material/styles';
import {
  Button, Checkbox, Grid, InputBase, TableCell, TableRow, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';

const sm = '1371px';

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const CheckboxImage = styled('img')`
  width: 3rem;

  @media (min-width: 992px) {
    width: 17px;
  }
`;

export const SubItemContent = styled(Grid)`
  display: grid;
      grid-template-columns: 18% 33% 39% 0%;

  align-items: center;
  width: 100%
`;

export const ProductContainer = styled('div')`
  display: flex;
  flex-wrap:wrap;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 0;
  margin: 0 2rem;
  border-bottom: 2px solid #ECECEC;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    padding: 35px 0;
    margin: 0 20px;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const ProductDetail = styled(TableCell)`
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  max-width: 300px !important;
  min-width: 300px !important;

`;

export const ProductDetailImage = styled('img')`
  width: 11rem;
  max-height: 11rem;
  margin-right: 20px;

  @media (min-width: 992px) {
  width: 100px;
  max-height: 100px;
  margin-right: 20px;
  }
`;

export const ProductDetailBrandTypo = styled(Typography)`
  font-size: 35px;
  font-weight: 600;
  text-transform: title-case;
  margin-bottom: 10px;
`;

export const ProductDetailBrandNameText = styled('p')`
  color: #424242;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    width: 15rem;
    line-height: 23px;
    font-size: 14px;
    margin-right: .5rem;
  }

  @media (min-width: 1200px) {
    width: 22rem;
    line-height: 23px;
    font-size: 17px;
    margin-right: 1rem;
  }
`;

export const QuantityText = styled(Typography)`
    font-weight: 400;
`;

export const ProductDetailPackSizeText = styled('p')`
  margin: 0;
  color: #424242;
  font-size: 14px;
  line-height: 23px;

  @media (max-width: 991px) {
    color: #868686;
    margin-right: 20px;
    font-size: 30px;
    font-weight: 300;
  }
`;

export const ProductDetailQuantityText = styled('p')`
  color: #424242;
  font-size: 35px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    width: 140px;
    line-height: 23px;
    font-size: 18px;
    margin-left: 20px;
  }
  @media (min-width: 1200px) {
    width: 340px;
    line-height: 23px;
    font-size: 18px;
    margin: 0;
  }
`;

export const ProductDetailSavingsText = styled('p')`
  margin: 0;
  color: #3cc480;
  font-weight: 600;
  font-size: 13px;

  @media (max-width: 991px) {
    font-size: 24px;
    margin-top: 1rem;
  }
`;

export const ProductDetailItemPriceText = styled('p')`
  margin: 0;
  margin-right: auto;
  color: #424242;
  line-height: 23px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;

  @media (max-width: 991px) {
    font-size: 30px;
    margin: 45px 0;
  }
`;

export const HideMd = styled('div')`
  border-bottom: 1px solid #E7E8E9;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const ShowMd = styled(Grid)`
  position: relative;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 60px;

  @media (max-width: 991px) {
    margin-left: 7.5rem;
    margin-right: 8rem;
  }

  @media (max-width: 991px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ProductCounterStatText = styled('p')`
  display: flex;
  width: 96px;
  height: 60px;
  font-size: 18px;
  line-height: 17px;
  color: #424242;
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  padding: 0;

  @media (max-width: ${sm}) {
    width: 70px;
    height: 35px;
    font-size: 13px;
    line-height: 13px;
  }
`;

export const ProductCounterQuantityInput = styled('input')`
  width: 96px;
  height: 60px;
  font-size: 20px;
  font-family: Montserrat,medium,sans-serif;
  line-height: 17px;
  color: #424242;
  background-color: #f0f0f0;
  outline: none;
  border: none;
  padding-left: 15px;

  @media (max-width: 991px) {
    width: 10rem;
    height: 5.5rem;
    font-size: 2.5rem;
    line-height: 13px;
    background-color: #E6E6E6;
    text-align: center;
    padding: 0;
  }
`;

export const ProductCounterRemoveButton = styled(Button)`
  min-width: 48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #CECECE;
  }
`;

export const ProductCounterAddButton = styled(Button)`
  min-width: 48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #CECECE;
  }
`;

export const ProductCounterMobileContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProductDetailMainContainer = styled('div')`
  width: 250px;
  margin-right: 20px;

  @media (min-width: 992px) {
    width: 100px;
    margin-right: 0px;
  }
`;

export const ProductDetailPriceMobileContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ProductDetailNameMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 991px) {
    height: 15rem;
  }
`;

export const Remove = styled(RemoveIcon)`
  font-size: 2.2rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const Add = styled(AddIcon)`
  font-size: 2.2rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const Delete = styled(DeleteIcon)`
  font-size: 3.5rem;
  color: #A3A3A3;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 60%;
  border-radius: 6px;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
    font-size: 20px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }
`;

export const MainTableRow = styled(TableRow)`
  border-top: 1px solid #E7E8E9;
  border-bottom: 1px solid #E7E8E9;
`;

export const ActionButton = styled(Button)`
  width: 130px;
  height: 45px;
  background: none;
  border-radius: 5px;
  color: #606060;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #C0C0C1;

  &:hover {
    background-color: none;
    color: #606060;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: none;
    border: 1px solid #C0C0C1;
  }

  @media(max-width: 991px) {
    margin: 12px 22px;
    width: 93%;
    height: 125px;
  }
`;

export const TextWrapper = styled(Typography)`
  font-size: .9rem;
  @media(max-width: 991px) {
    font-size: 2.1rem;
  }

`;
