import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, TableRow, TableBody, TableContainer, Table
} from '@mui/material';
import {
  CardTitle, TableCellStyled, TableCellBodyStyled, TableRowStyled,
  TableHeadStyled, Stat, TableCellBorderStyled
} from './productBatchList.styles';
import { BoxStyled } from './productDetailsContainer.style';
import currencyFormatter from '../../shared/currencyFormatter';
import ReturnRow from './returnRow';

const ProductBatchList = ({ info }) => {
  const { batches: rows } = info;
  const grandTotal = rows?.reduce((acc, num) => acc + num.orderPrice, 0.0);
  const qtyInStock = rows?.reduce((acc, num) => acc + num.quantityInStock, 0);

  return (
    <Grid>
      <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px" mt={4}>
        <CardTitle className="erp-uat-batch-details">Batch Details</CardTitle>
        <TableContainer style={{ marginTop: '1rem' }}>
          <Table style={{ width: '100%' }}>
            <TableHeadStyled>
              <TableRowStyled>
                <TableCellStyled>Date Received</TableCellStyled>
                <TableCellStyled>Supplier</TableCellStyled>
                <TableCellStyled>Batch No.</TableCellStyled>
                <TableCellStyled>Expiry Date</TableCellStyled>
                <TableCellStyled>Batch Qty</TableCellStyled>
                <TableCellStyled>Item Cost</TableCellStyled>
                <TableCellStyled>Item Price</TableCellStyled>
              </TableRowStyled>
            </TableHeadStyled>

            {rows?.length > 0 ? (
              <TableBody>
                {rows?.map((row) => (
                  <ReturnRow key={row.id} row={row} />
                ))}
                <TableCellBodyStyled colSpan={3} />
                <TableCellBorderStyled>
                  <Stat>Qty in stock</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>{qtyInStock}</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>Grand Total</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>{`₦ ${currencyFormatter(grandTotal)}`}</Stat>
                </TableCellBorderStyled>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCellBodyStyled
                    colSpan={7}
                    style={{ textAlign: 'center' }}
                  >
                    No Batches for this product yet
                  </TableCellBodyStyled>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </BoxStyled>
    </Grid>
  );
};

ProductBatchList.propTypes = {
  info: PropTypes.instanceOf(Object),
};

ProductBatchList.defaultProps = {
  info: {}
};

export default ProductBatchList;
