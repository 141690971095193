import { styled } from '@mui/material/styles';
import {
  Button,
  Grid,
  TableCell,
  IconButton,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const sm = '1371px';

export const ProductContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 0;
  margin: 0 2rem;
  border-bottom: 2px solid #ececec;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    padding: 35px 0;
    margin: 0 10px;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const ProductDetail = styled(TableCell)`
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  max-width: 300px !important;
  min-width: 300px !important;
`;

export const PartialReceived = styled('span')`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: .7rem;
  font-weight: 500;
  justify-content: center;
  background-color: #FAF400;
  color: #606060;
  text-align: center;
  margin-bottom: 5px;
  width: 7rem;
`;

export const ProductDetailImage = styled('img')`
  width: 8rem;
  max-height: 8rem;

  @media (min-width: 992px) {
    width: 95px;
    max-height: 70px;
  }
`;
export const ImageWrapper = styled(Grid)`
  width: 11rem;
  max-height: 11rem;
  margin-right: 20px;
  background: #ebebeb;
  border-radius: 10px;
  text-align: center;

  @media (min-width: 992px) {
    width: 100px;
    max-height: 100px;
    margin-right: 10px;
    margin-top: 0rem;
  }
`;

export const BrandNameText = styled(Typography)`
  color: #24292e;
  font-size: 20px;
  font-weight: 700;
  text-transform: title-case;

  @media (min-width: 992px) {
    line-height: 20px;
    font-size: 14px;
  }
`;

export const PackSizeText = styled(Typography)`
  color: #606060;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;

  @media (max-width: 991px) {
    font-size: 20px;
  }
`;

export const ProductDetailBrandNameText = styled('p')`
  color: #424242;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  text-transform: title-case;
  margin-bottom: 10px;

  @media (min-width: 992px) {
    line-height: 20px;
    font-size: 15px;
  }
`;

export const ProductDetailBrandTypo = styled(Typography)`
  font-size: 35px;
  font-weight: 600;
  text-transform: title-case;
  margin-bottom: 10px;
`;

export const ProductDetailPackSizeText = styled('p')`
  margin: 0;
  color: #424242;
  font-size: 16px;
  line-height: 23px;

  @media (max-width: 991px) {
    color: #868686;
    margin-right: 20px;
    font-size: 30px;
    font-weight: 600;
  }
`;

export const ProductDetailSavingsText = styled('p')`
  margin: 0;
  color: #3cc480;
  font-weight: 600;
  font-size: 13px;

  @media (max-width: 991px) {
    font-size: 24px;
    position: absolute;
    top: 8rem;
    left: 12.8rem;
  }
`;

export const PromoText = styled('p')`
  margin: 0;
  color: #3cc480;
  font-weight: 600;
  font-size: 13px;

  @media (max-width: 991px) {
    font-size: 24px;
    margin-top: 5px;
  }
`;

export const ProductDetailItemPriceText = styled('p')`
  margin: 0;
  margin-right: auto;
  color: #424242;
  line-height: 23px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;

  @media (max-width: 991px) {
    font-size: 35px;
    // margin-top: 5.2rem;
    margin-bottom: 15px;
  }
`;

export const SmallScreenRowGrid = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const MainTableRow = styled(Grid)`
  background: ${(props) => (props.isReconciled ? '#F5F5F5' : '')};
  align-items: center;
  border-bottom: 1px solid #e7e8e9;

  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media (min-width: 992px) {
    font-size: 0.9rem;

    &:nth-child(1) {
      width: 33%;
    }
    &:nth-child(2),
    &:nth-child(3),
    :nth-child(4) {
      width: 16%;
    }
    :nth-child(5) {
      width: 14%;
    }
    &:nth-child(6) {
      width: 5%;
    }
  }
`;

export const GreySpan = styled('span')`
  color: #a3a3a3;
  cursor: pointer;
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;
`;
export const ProductCounterWrapper = styled('div')``;

export const OutOfStock = styled('p')`
  font-size: 15px;
  color: red;
  font-weight: 600;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 27px;
    padding-left: 17rem;
  }
`;

export const ValuesGrid = styled(Grid)`
  align-items: center;
  font-size: 1.5rem;
  margin-top: 1rem;
`;

export const ProductCounterStatText = styled('p')`
  display: flex;
  width: 96px;
  height: 60px;
  font-size: 18px;
  line-height: 17px;
  color: #424242;
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  padding: 0;

  @media (max-width: ${sm}) {
    width: 70px;
    height: 35px;
    font-size: 13px;
    line-height: 13px;
  }
`;

export const ProductCounterQuantityInput = styled('input')`
  width: 60px;
  height: 50px;
  font-size: 18px;
  line-height: 17px;
  color: #424242;
  outline: none;
  border: none;
  text-align: center;

  @media (max-width: 991px) {
    width: 120px;
    font-size: 2.5rem;
  }
`;

export const ProductCounterRemoveButton = styled(Button)`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #faa9a3;
  border-radius: 50%;
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;
  cursor: pointer;
  &.MuiButton-root {
    min-width: 0px;
  }
  @media (max-width: 991px) {
    width: 45px;
    height: 45px;
  }
`;

export const ProductCounterAddButton = styled(Button)`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a9d3ab;
  border-radius: 50%;
  // border-top-right-radius: 5px;
  // border-bottom-right-radius: 5px;
  cursor: pointer;
  &.MuiButton-root {
    min-width: 0px;
  }
  @media (max-width: 991px) {
    width: 45px;
    height: 45px;
  }
`;

export const ProductCounterMobileContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileCounterGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 14rem;
  left: 13rem;
  width: 36rem;
`;

export const ProductDetailMainContainer = styled('div')`
  width: 145px;
  margin-right: 3rem;

  @media (min-width: 2560px) {
    margin-right: 12%;
  }
`;

export const ProductDetailPriceMobileContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ProductDetailNameMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Remove = styled(RemoveIcon)`
  font-size: 2.5rem;
  color: #faa9a3;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const Add = styled(AddIcon)`
  font-size: 2.5rem;
  color: #a9d3ab;
  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const Delete = styled('img')`
  font-size: 3.5rem;
  height: 3rem;
  color: #a3a3a3;

  @media (min-width: 992px) {
    font-size: 1.5rem;
    height: 1.5rem;
  }
`;

export const EditImg = styled('img')``;

export const TickImg = styled('img')`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const EditButton = styled(IconButton)`
  background-color: #235A91;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  outline: none;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

export const MinQtyTypo = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;
  color: #424242;
  @media (min-width: 992px) {
    font-size: 0.9rem;
  }
`;

export const ProductVariantText = styled('p')`
  font-size: 1.3rem;
  text-align: right;
  margin: 0;
  padding: 0;
  color: #424242;
  padding: 0 1.5%;
  position: absolute;
  left: 12px;
  top: -15px;
  border-radius: 5px;
  background-color: #fce850;
  @media (min-width: 992px) {
    font-size: 0.9rem;
    top: -15px;
  }
`;

export const ShopNowButton = styled(Button)`
  background-color: #333333;
  border-radius: 5px;
  color: #fff;
  padding: 15px 3rem;
  margin-top: 25px;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const MobileText = styled(Typography)`
  margin: 1rem 0 0.5rem 0;
  font-size: 1.4rem;
`;

export const MobileButttonGrid = styled(Grid)`
  justify-content: space-between;
  margin-top: 2rem;
  padding-left: 0.7rem;
  margin-right: 4rem;
`;

export const RemoveText = styled(Typography)`
  font-size: 1.7rem;
  padding: 0px 10px;
`;
