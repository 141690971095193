import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Grid, Slide, TextField
} from '@mui/material';
import ReturnSelectField from './returnSelectField';
import {
  HeaderGrid, ContentGrid, ActionsGrid, DoneButton, InputGrid,
  HeaderText, CancelButton, SmallText
} from './devTicketModal.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const DevTicketModel = ({
  open, handleClose, ticket, handleUpdateTicket
}) => {
  const [status, setStatus] = useState();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ticket && ticket.status) {
      setStatus(ticket?.status);
    }
    setLoading(false);
  }, [ticket]);

  const handleReset = () => {
    setComment('');
    setLoading(false);
    setStatus();
  };

  const handleTicket = () => {
    setLoading(true);
    handleUpdateTicket(status, comment, handleReset);
  };

  const statuses = {
    name: 'status',
    label: 'Status',
    options: ['LOGGED', 'FIXING', 'REVIEW', 'RESOLVED']
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <HeaderGrid container>
        <Grid item container xs={12} wrap="nowrap" alignItems="center">
          <HeaderText>Update Ticket</HeaderText>
        </Grid>
        <SmallText variant="caption">
          Update ticket status and add a comment (optional)
        </SmallText>
      </HeaderGrid>
      <ContentGrid item container alignItems="center">
        <ReturnSelectField
          fields={statuses}
          value={status}
          handleChange={(e) => setStatus(e.target.value)}
        />
        <InputGrid item container>
          <TextField
            label="Comment (Optional)"
            multiline
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            minRows={4}
            variant="filled"
          />
        </InputGrid>
      </ContentGrid>
      <ActionsGrid container justifyContent="flex-end">
        <CancelButton onClick={handleClose}>
          Close
        </CancelButton>
        <DoneButton
          disabled={loading}
          onClick={handleTicket}
        >
          Update ticket
        </DoneButton>
      </ActionsGrid>
    </Dialog>
  );
};

DevTicketModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  ticket: PropTypes.instanceOf(Object).isRequired,
  handleUpdateTicket: PropTypes.func.isRequired,
};

export default DevTicketModel;
