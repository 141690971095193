import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  StepContent, useMediaQuery,
  Grid, Step, StepLabel
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  StepperIcon, HeaderText, TitleText, DateText, CustomStepper
} from './trackOrderContainer.styles';
import CompleteIcon from '../../assets/images/marketOrder/completeIcon.png';
import IncompleteIcon from '../../assets/images/marketOrder/checkedOutline.png';

const StepIcon = ({ completed, active }) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  return (
    <div>
      {completed
        ? <StepperIcon src={IncompleteIcon} alt="process" />
        : active ? <StepperIcon src={CompleteIcon} alt="complete" />
          : <RadioButtonUncheckedIcon style={{ fontSize: isSmall ? '60px' : '30px', color: '#07DE10' }} />}
    </div>
  );
};
StepIcon.propTypes = {
  completed: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired
};

const TrackOrderStepper = ({ activeStep, steps }) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  return (
    <Grid item container style={{ width: isSmall && '85%' }}>
      <CustomStepper activeStep={activeStep} orientation="vertical" style={{ width: '100%', padding: '0', marginTop: '1rem' }}>
        {steps.map(({ header, title, date }) => (
          <Step>
            <StepLabel StepIconComponent={StepIcon}>
              <HeaderText>{header}</HeaderText>
              <Grid container>
                <Grid item md={9} style={{ textAlign: 'left' }}>
                  <TitleText>{title}</TitleText>
                </Grid>
                <Grid item md={3} style={{ textAlign: 'right' }}>
                  <DateText>{date}</DateText>
                </Grid>
              </Grid>
            </StepLabel>
            {/* <StepContent /> */}
            {/* </StepContent> */}
          </Step>
        ))}
      </CustomStepper>
    </Grid>
  );
};

TrackOrderStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.instanceOf(Object).isRequired,
};

export default TrackOrderStepper;
