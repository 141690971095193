import { gql } from '@apollo/client';

export const PRODUCTS_TOTAL_QUERY = gql`
  query products(
    $onlyBusinessProducts: Boolean
    $search: String!
    $suppliers: [String]!
  ) {
    products(
      search: $search
      suppliers: $suppliers
      onlyBusinessProducts: $onlyBusinessProducts
    ) {
      id
    }
    productsTotalNumber
  }
`;
export const PRODUCT_VERSIONS = gql`
  fragment productVersions on ProductType {
    marketplaceVersion {
      quantityInStock
      outOfStock
      resolvedPriceInUseValue
      supplierCost
      priceInUse
      itemPrice
      fixedItemPrice
      firstOrderPromoPrice
      supplierMarkupPrice
      marketRrp
      productVariant
      minimumOrderQuantity
      status
      mktPrice
      promoValue
      category {
        categoryName
        id
        loyaltyWeight
        markup
        vatStatus
      }
      supplier {
        id
        name
      }
      brandName
      genericName
      packSize
      manufacturer {
        id
        name
      }
      image
      description
      therapeuticType {
        id
        therapeuticType
      }
      barcode
      unitOfMeasurement
    }
    erpVersion {
      noOfUom
      dateCreated
      fixedSellingPrice
      resolvedPriceInUseValue
      priceInUse
      sellingPrice
      promoPrice
      orderCost
      reorderPoint
      reorderUnit
      reorderMax
      isAVersion
      safetyStock
      maxLeadTime
      productVariant
      minimumOrderQuantity
      status
      category {
        categoryName
        id
        loyaltyWeight
        markup
        vatStatus
      }
      supplier {
        id
        name
      }
      brandName
      buffer
      reorderMin
      reorderQuantity
      genericName
      packSize
      manufacturer {
        id
        name
      }
      image
      description
      therapeuticType {
        id
        therapeuticType
      }
      barcode
      unitOfMeasurement
    }
    promoStatus
    promoEndDate
    promoCondition
    productClass
  }
`;

export const ERP_PRODUCT_QUERY = gql`
  query erpProducts(
    $search: String!
    $status: String
    $supplier: String
    $business: Int
    $priceMin: Float
    $priceMax: Float
    $productType: String
    $therapeuticTypeIds: [String]
    $pageCount: Int
    $pageNumber: Int
    $onlyMarketProducts: Boolean
    $onlyBusinessProducts: Boolean
    $productName: String
    $productId: String
    $dateFrom: String
    $dateTo: String
    $category: Int
    $manufacturer: String
  ) {
    erpProducts(
      search: $search
      status: $status
      supplier: $supplier
      pageCount: $pageCount
      pageNumber: $pageNumber
      productType: $productType
      therapeuticTypeIds: $therapeuticTypeIds
      onlyMarketProducts: $onlyMarketProducts
      onlyBusinessProducts: $onlyBusinessProducts
      business: $business
      priceMin: $priceMin
      priceMax: $priceMax
      productName: $productName
      productId: $productId
      dateFrom: $dateFrom
      dateTo: $dateTo
      category: $category
      manufacturer: $manufacturer
    ) {
      id
      sku
      status
      business {
        id
      }
      brandName
      weeklyAverage
      quantityInStock
      genericName
      molecules
      productRef
      supplier {
        id
        name
      }
      meta
      manufacturer {
        id
        name
      }
      promo
      category {
        id
      }
      barcode
      batches {
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      promoStatus
      ...productVersions
    }
    productsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const BATCH_PRODUCT_QUERY = gql`
  query erpProducts($search: String!, $productId: String) {
    erpProducts(search: $search, productId: $productId) {
      id
      sku
      erpVersion {
        brandName
        packSize
      }
      supplier {
        id
      }
      batches {
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
    }
  }
`;

export const SEARCH_PRODUCT_QUERY = gql`
  query erpProducts(
    $search: String!
    $status: String
    $productName: String
    $productId: String
  ) {
    erpProducts(
      search: $search
      status: $status
      productName: $productName
      productId: $productId
    ) {
      id
      sku
      erpVersion {
        brandName
        resolvedPriceInUseValue
        packSize
      }
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query products(
    $search: String!
    $suppliers: [String]!
    $status: String
    $business: Int
    $priceMin: Float
    $priceMax: Float
    $productType: String
    $therapeuticTypeIds: [String]
    $pageCount: Int
    $pageNumber: Int
    $onlyMarketProducts: Boolean
    $onlyBusinessProducts: Boolean
    $productName: String
    $productId: String
    $dateFrom: String
    $dateTo: String
    $category: Int
    $productVariant: String
  ) {
    products(
      search: $search
      suppliers: $suppliers
      pageCount: $pageCount
      pageNumber: $pageNumber
      productType: $productType
      therapeuticTypeIds: $therapeuticTypeIds
      onlyMarketProducts: $onlyMarketProducts
      onlyBusinessProducts: $onlyBusinessProducts
      status: $status
      business: $business
      priceMin: $priceMin
      priceMax: $priceMax
      productName: $productName
      productId: $productId
      dateFrom: $dateFrom
      dateTo: $dateTo
      category: $category
      productVariant: $productVariant
    ) {
      id
      status
      business {
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      molecules
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches {
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    productsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const PRODUCT_DETAILS_QUERY_MP = gql`
  query product($id: Int, $pageCount: Int, $pageNumber: Int) {
    product(id: $id) {
      id
      brandName
      genericName
      productClass
      meta
      ...productVersions
      dateCreated
      supplier {
        id
        name
      }
      business {
        name
        id
      }
      batches {
        id
        name
        meta
        dateRecieved
        batchNo
        quantityInStock
        quantityRecieved
        expiryDate
        orderPrice
        orderCost
        supplier {
          id
          name
        }
      }
      category {
        id
        categoryName
        markup
        vatStatus
        loyaltyWeight
      }
    }
    modelHistory(
      model: "Product"
      modelId: $id
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      message
      model
      modelGroup
      modelId
      business {
        name
      }
      businessUser {
        user {
          username
        }
      }
    }
    modelHistoryTotalNumber
  }
  ${PRODUCT_VERSIONS}
`;

export const PRODUCT_DETAILS_QUERY = gql`
  query product($id: Int, $pageCount: Int, $pageNumber: Int) {
    product(id: $id) {
      id
      sku
      brandName
      genericName
      molecules
      productClass
      meta
      manufacturer {
        id
        name
      }
      ...productVersions
      dateCreated
      supplier {
        id
        name
      }
      business {
        name
        id
      }
      batches {
        id
        name
        meta
        dateRecieved
        batchNo
        quantityInStock
        quantityRecieved
        expiryDate
        orderPrice
        orderCost
        supplier {
          id
          name
        }
      }
      category {
        id
        categoryName
        markup
        vatStatus
        loyaltyWeight
      }
    }
    modelHistory(
      model: "Product"
      modelId: $id
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      message
      model
      modelGroup
      modelId
      business {
        name
      }
      businessUser {
        user {
          username
        }
      }
    }
    modelHistoryTotalNumber
  }
  ${PRODUCT_VERSIONS}
`;

export const GET_LATEST_PRODUCTS = gql`
  query lastestProducts($lastQueryTimestamp: DateTime) {
    latestProducts(lastQueryTimestamp: $lastQueryTimestamp) {
      id
      business {
        id
      }
      brandName
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches {
        id
        meta
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        quantityInStock
      }
      ...productVersions
    }
    productsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const SIMILAR_PRODUCTS_QUERY = gql`
  query similarProducts(
    $searchProductNames: [String]!
    $therapeuticTypes: [Int]!
  ) {
    similarProducts(
      therapeuticTypes: $therapeuticTypes
      searchProductNames: $searchProductNames
    ) {
      id
      status
      business {
        id
      }
      brandName
      weeklyAverage
      quantityInStock
      genericName
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches {
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    similarProductsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const SALES_PRODUCTS_QUERY = gql`
  query salesPageProducts(
    $search: String!
    $productType: String
    $searchType: String
    $pageCount: Int
    $pageNumber: Int
    $status: String
  ) {
    salesPageProducts(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
      productType: $productType
      searchType: $searchType
      status: $status
    ) {
      id
      status
      business {
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      molecules
      productRef
      supplier {
        id
        name
      }
      meta
      manufacturer {
        id
        name
      }
      promo
      category {
        id
      }
      barcode
      batches {
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    salesPageTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const STOCK_TRANSFER_PRODUCT_QUERY = gql`
  query stockTransferProducts(
    $search: String!
    $suppliers: [String]
    $business: Int
    $pageCount: Int
    $pageNumber: Int
  ) {
    stockTransferProducts(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
      suppliers: $suppliers
      business: $business
    ) {
      id
      status
      business {
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      molecules
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches {
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    stockTransferProductsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const NEW_ORDER_PRODUCTS_PAGE_QUERY = gql`
  query newOrderPageProducts(
    $search: String
    $priceMin: Float
    $priceMax: Float
    $manufacturer: [Int]
    $therapeuticTypeIds: [String]
    $pageCount: Int
    $pageNumber: Int
    $availabilityStatus: String
  ) {
    newOrderPageProducts(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
      therapeuticTypeIds: $therapeuticTypeIds
      priceMin: $priceMin
      priceMax: $priceMax
      availabilityStatus: $availabilityStatus
      manufacturer: $manufacturer
    ) {
      id
      business {
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      molecules
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches {
        id
        batchNo
        quantityRecieved
        orderCost
        dateRecieved
        expiryDate
        meta
        quantityInStock
      }
      ...productVersions
    }
    newOrderPageProductsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const STOCK_COUNT_PRODUCTS_QUERY = gql`
  query stockCount(
    $search: String!
    $suppliers: [String]!
    $onlyMarketProducts: Boolean
    $onlyBusinessProducts: Boolean
    $business: Int
    $pageCount: Int
    $pageNumber: Int
  ) {
    stockCount(
      search: $search
      business: $business
      suppliers: $suppliers
      pageCount: $pageCount
      pageNumber: $pageNumber
      onlyMarketProducts: $onlyMarketProducts
      onlyBusinessProducts: $onlyBusinessProducts
    ) {
      id
      business {
        id
      }
      brandName
      productClass
      weeklyAverage
      quantityInStock
      genericName
      molecules
      productRef
      supplier {
        id
        name
      }
      meta
      promo
      category {
        id
      }
      barcode
      batches {
        id
        batchNo
        expiryDate
        quantityInStock
        orderCost
      }
      ...productVersions
    }
    productsTotalNumber
    queryTimestamp
  }
  ${PRODUCT_VERSIONS}
`;

export const PRODUCT_ACTION_TIMELINE = gql`
  query productActionTimelines(
    $productId: Int!
    $pageCount: Int
    $pageNumber: Int
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    productActionTimelines(
      productId: $productId
      dateFrom: $dateFrom
      dateTo: $dateTo
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      lastUpdatedBy
      lastUpdatedDate
      lastUpdatedField
      previousValue
      updatedValue
    }
    productActionTimelinesTotalNumber
  }
`;

export const ALL_PRODUCT_BATCHES_QUERY = gql`
  query allProductBatches(
    $searchProductName: String
    $searchBatchNumber: String
    $pageCount: Int
    $pageNumber: Int
    $dateFrom: DateTime
    $dateTo: DateTime
    $status: String
  ) {
    allProductBatches(
      searchProductName: $searchProductName
      searchBatchNumber: $searchBatchNumber
      pageCount: $pageCount
      pageNumber: $pageNumber
      dateFrom: $dateFrom
      dateTo: $dateTo
      status: $status
    ) {
      id
      dateCreated
      dateRecieved
      expiryDate
      batchNo
      brandName
      product {
        id
        brandName
      }
      supplier {
        id
        name
      }
      orderCost
      quantityInStock
      lastUpdatedField
      lastUpdatedBy
      lastUpdatedDate
      previousInfo
      currentInfo
      meta
      status
    }
    allProductBatchesTotalNumber
  }
`;

export const PRODUCT_BATCH_LOGS_QUERY = gql`
  query allProductBatchLogs(
    $batchId: String
    $pageCount: Int
    $pageNumber: Int
  ) {
    allProductBatchLogs(
      batchId: $batchId
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      date
      orderCost
      expiryDate
      quantity
      updatedField
      updatedBy
      updateDate
      previousValue
      currentValue
      productName
      batchNo
    }
    allProductBatchLogsTotalNumber
  }
`;

export const SEARCH_PO_QUERY = gql`
  query erpProducts(
    $search: String!
    $status: String
    $productName: String
    $productId: String
    $manualBatch: Boolean
  ) {
    erpProducts(
      search: $search
      status: $status
      productName: $productName
      productId: $productId
      manualBatch: $manualBatch
    ) {
      id
      sku
      brandName
      isPreloaded
      erpVersion {
        packSize
        brandName
        orderCost
        resolvedPriceInUseValue
        supplier {
          name
          id
        }
        unitOfMeasurement
        noOfUom
        sellingPrice
        fixedSellingPrice
        category {
          id
          categoryName
        }
      }
    }
  }
`;

export const GET_PO_ORDERS = gql`
  query POOrderProducts($orderId: Int!, $pageCount: Int, $pageNumber: Int) {
    poOrderProducts(
      orderId: $orderId
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      id
      unitCost
      price
      quantity
      product {
        id
        sku
        brandName
        meta
      }
      supplierOrder {
        id
        meta
        order {
          id
        }
        supplier {
          name
        }
      }
    }
    poOrderProductsCount
  }
`;

export const LOW_OUT_OF_STOCK = gql`
  query lowOnOrOutOfStockProducts(
    $search: String
    $tab: LowOutOfStockTabEnum!
    $pageCount: Int
    $pageNumber: Int
  ) {
    lowOnOrOutOfStockProducts(
      search: $search
      tab: $tab
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      productId
      packSize
      status
      sku
      brandName
      quantityInStock
    }
    lowOnOrOutOfStockProductsTotalNumber(search: $search, tab: $tab)
  }
`;

export const LOW_OUT_OF_STOCK_TOTAL_NUMBER = gql`
  query lowOnOrOutOfStockProductsTotalNumber {
    lowOnOrOutOfStockProductsTotalNumber
  }
`;
