import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, TableRow, TableBody, Paper, Checkbox, Button, Hidden, Table
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import GoToTop from '../../customComponents/scrollToTop';
import CTablePagination from '../../customComponents/cTablePagination';
import {
  OrderItemsGrid, OrderCountText,
} from '../../orders/admin/order.styles';
import paginationShowing from '../../../utils/pagination';
import {
  MainTableHead
} from '../../toBeDeleted/supplierDetails/supplierProducts.styles';
import {
  HeaderCell,
  AffiliateTableContainerWrapper,
  FooterGrid,
  SortCart, SortImage, PaddingBottom
} from '../dashboard/dashboard.styles';
import { useStateValue } from '../../../providers/stateProvider';
import ascImg from '../../../assets/images/Sort Ascending Icon.png';
import descImg from '../../../assets/images/Sort Descending Icon.png';
import Loader from '../../shared/loader';
import { GET_MISSED_SALES } from '../../../queries/sales';
import { EXPORT_MUTATION } from '../../../mutations/reports';
import ReturnMissedSalesRow from './returnMissedSalesRow';
import CustomSearchField from '../../shared/CustomSearchField';
import { TableComponent, TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';
import {
  TableContainerWrapper, ExportIconStyled, SearchGrid, DivFullWidth
} from './outOfStockTab.styles';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../shared/tablePagination';

const MissedSaleTab = ({
  history, dateFilter
}) => {
  // const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: 20,
    data: {},
  };
  const [
    {
      business: { businessId, businessUser: businessUserId },
      navbar: { openSideDrawer },
    },
    dispatch,
  ] = Object.values(useStateValue());

  const [state, setState] = useState(initialState);
  const [allData, setAllData] = useState({});
  const [sortCart, setSortCart] = useState(false);
  const [asc, setAsc] = useState(false);
  const [desc, setDesc] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [exportMutation] = useMutation(EXPORT_MUTATION);

  const headers = [
    { name: 'Date Sold', width: '200px' }, { name: 'Products', width: '200px' }, { name: 'Qty Missed', width: '150px' },
    { name: 'Pack Size', width: '150px' }, { name: 'Sold By', width: '200px' },
    { name: 'Sale Amount', width: '150px' }
  ];

  const initialSearchState = {
    productName: ''
  };
  const [searchState, setSearchState] = useState(initialSearchState);

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} className={`erp-uat-${name.replace(/ /g, '-')}`} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const {
    currentPage, currentPageCount
  } = state;

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const sortByCart = (direction) => {
    if (direction === 'asc') {
      setDesc(false);
      setAsc(!asc);
      // eslint-disable-next-line no-use-before-define
      data.missedSales.sort((a, b) => ((a.total > b.total) ? 1 : -1));
    } else if (direction === 'desc') {
      setDesc(!desc);
      setAsc(false);
      // eslint-disable-next-line no-use-before-define
      data.missedSales.sort((a, b) => ((a.total < b.total) ? 1 : -1));
    }
    // eslint-disable-next-line no-use-before-define
    setAllData(data);
  };
  const variables = {
    pageCount,
    pageNumber,
    dateFrom: dateFilter.dateFrom,
    dateTo: dateFilter.dateTo,
  };
  if (businessId) {
    variables.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
  }

  if (searchState.productName.length >= 3) {
    variables.search = searchState.productName;
  }
  const {
    data, loading, error, refetch
  } = useQuery(GET_MISSED_SALES, {
    variables,
  });

  useEffect(() => {
    if (data && data.missedSales) {
      setAllData(data);
    }
  }, [data]);

  // if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;
  const {
    missedSales = [], missedSalesTotalNumber = 0
  } = allData;
  const showing = paginationShowing(missedSales, currentPageCount);
  const handleDownloadCSV = (type) => {
    const randomNum = Math.floor(Math.random() * 10000);
    const newFileName = `Missed_sales_report ${randomNum}`;
    exportMutation({
      variables: {
        name: newFileName,
        type,
        dateFrom: moment(dateFilter.dateFrom).format('YYYY-M-DD'),
        dateTo: moment(dateFilter.dateTo).format('YYYY-M-DD')
      }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };
  refetch();

  return (
    <>
      <Grid
        container
        style={{ marginBottom: '1rem' }}
      >
        <CustomSearchField
          name="search"
          value={searchState.productName}
          placeholder="Search Products"
          handleChange={(e) => setSearchState({ productName: e?.target?.value })}
          style={{ width: openSideDrawer ? '80.2%' : '85%', marginRight: '.8rem' }}
        />
        <Button
          variant="outlined"
          key="out-of-stock"
          className="erp-uat-out-of-stock"
          style={{ fontSize: '16px', fontWeight: '700' }}
          onClick={() => handleDownloadCSV('missed_sale')}
        >
          <Hidden mdDown>
            <ExportIconStyled />
          </Hidden>
          Export
        </Button>
      </Grid>
      <TableContainerWrapper component={Paper}>
        {loading ? <ProductsTableLoader /> : (
          <TableComponent>
            <Table>
              <TableColumnHeaders className="erp-uat-product-table-header">
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {missedSales.map((missedSale) => (
                  <ReturnMissedSalesRow
                    key={missedSale.id}
                    missedSale={missedSale}
                    history={history}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
      </TableContainerWrapper>
      <DivFullWidth>
        {missedSalesTotalNumber > 0 && (
          <TablePagination
            total={missedSalesTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </DivFullWidth>
      {
        (missedSales.length)
          ? <GoToTop /> : ''

      }
    </>
  );
};

MissedSaleTab.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  dateFilter: PropTypes.func.isRequired,
};

export default MissedSaleTab;
