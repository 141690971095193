import React, { useState, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Grid, TableBody } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import { GET_SEARCHABLE_STOCK_TRANSFERS } from '../../../queries/stockTransfer';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import { TableExport } from '../../../assets/svgs';
import CustomButton from '../../customComponents/customButton';
import ReturnRowLogs from './returnRowLogs';
import TablePagination from '../../shared/tablePagination';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  TitleGrid, TitleTextGridContainer, PrimaryTitle, FilterText,
  PaperWrapper, TableGrid, TableHeader, TableComponent, MainTableHead,
  FooterWrapper, MenuButton, SearchGrid, ButtonWrapper
} from './stockTransferLogs.styles';
import { useStateValue } from '../../../providers/stateProvider';
import { OgaToken } from '../../../graphql/token';
import CustomExportButton from '../../downloads/exportModule/exportComponent';

const headers = [
  'S/N', 'Sate', 'Product Name', 'Qty Transferred', 'Batch#', 'Total Product Value', 'Location',
  'Handler', 'Status'
];

const StockTransferLogs = () => {
  const [prodBatches, setProdBatches] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [dates, setDates] = useState({
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day')
  });

  const navigate = useNavigate();

  const [{
    user: { businessId },
  }] = Object.values(useStateValue());

  const handleSubmit = (dateRange) => {
    setDates(dateRange);
  };

  const handleExport = () => {
    const dateFrom = moment(dates?.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(dates?.dateTo).format('YYYY-MM-DD');
    window.open(`${OgaToken.SERVER_LINK}download_stock_transfer_csv/?business_from=${businessId}&date_from=${dateFrom}&date_to=${dateTo}&search=${searchText}`);
  };

  const variable = {
    dateFrom: moment(dates?.dateFrom).format('YYYY-M-DD'),
    dateTo: moment(dates?.dateTo).format('YYYY-M-DD')
  };
  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const variables = {
    ...(searchText.length >= 3 ? { search: searchText } : { search: '' }),
    dateFrom: dates?.dateFrom,
    dateTo: dates?.dateTo,
    pageCount,
    pageNumber,
  };

  const { loading, error, data } = useQuery(GET_SEARCHABLE_STOCK_TRANSFERS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useLayoutEffect(() => {
    if (data && data.searchableStockTransfer) {
      const pBatches = [];
      setProdBatches(data?.searchableStockTransfer.forEach(({ result }) => {
        result.forEach((info) => pBatches.push(info));
      }));
      setProdBatches(pBatches);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    stockTransferTotalNumber = 0
  } = data || {};

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12}>
            <MenuButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            </MenuButton>
            <PrimaryTitle variant="h5">Stock Transfer Logs</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={9}>
              <CustomSearchField
                name="search"
                value={searchText}
                placeholder="Search"
                handleChange={(e) => setSearchText(e?.target?.value)}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomDateRanges
                handleSubmit={handleSubmit}
                xs={12}
                styles={{
                  height: '47px', fontSize: '11.8px', border: '1px solid #bababa', width: '100%'
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <CustomExportButton
                color="bordered"
                title="Export"
                fileName="stock_transfer"
                // large="large"
                // icon="withIcon"
                name="Stock transfer"
                variable={variable}
              />
            </Grid>
          </Grid>
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <MainTableHead item container>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody style={{ width: '100%' }}>
                    {prodBatches?.map((_row, indx) => (
                      <ReturnRowLogs
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
          </TableGrid>
          <FooterWrapper item container>
            {stockTransferTotalNumber > 0 && (
              <TablePagination
                total={stockTransferTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                hideRowsPerPage
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default StockTransferLogs;
