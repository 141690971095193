import React, { useEffect, useState } from 'react';
import {
  Box, DialogContent, Slide
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  DialogContainer,
  DialogContentStyled,
  Title,
} from './mapProductDialog.styles';
import CustomSearchField from '../../shared/CustomSearchField';
import MapProductReturnRow from './mapProductReturnRow';
import NoDisplay from './noDisplay';
import { marketplaceApiEnvRoute } from '../../../utils/funcs';
import { MAP_PRODUCT_WITH_MP_INSTANCE } from '../../../mutations/orders';
import ConfirmDialog from '../../shared/confirmDialog';
import SuccessDialog from '../../shared/successDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const MapProductDialog = ({
  id, openDialog, setOpenDialog
}) => {
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState([]);
  const [mapStatus, setMapStatus] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [productRow, setProductRow] = useState({});
  const [loading, setLoading] = useState(false);
  const marketPlaceUrl = marketplaceApiEnvRoute();
  const [successDialog, setSuccessDialog] = useState(false);

  const fetchProductData = async () => {
    try {
      const response = await axios.get(`${marketPlaceUrl}remote-actions/search-product/?search=${search}`);
      setRows(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search.length >= 3) {
      setLoading(true);
      fetchProductData();
    }
  }, [search]);

  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  const [mapProduct, { loading: mapLoading }] = useMutation(MAP_PRODUCT_WITH_MP_INSTANCE);

  useEffect(() => {
    if (mapStatus) {
      const request = {
        orderListProductId: Number(id),
        mappingData: {
          brandName: productRow.brand_name,
          mktPrice: productRow.mkt_price.toString(),
          sku: productRow.sku
        }
      };
      mapProduct({
        variables: { mpProductData: { ...request } },
        refetchQueries: ['businessOrderListProducts']
      }).then(() => {
        setSuccessDialog(true);
        setMapStatus(false);
      }).catch((err) => toast.error(err.message));
    }
  }, [mapStatus]);

  const mapMpInstanceHandler = (row) => {
    setProductRow(row);
    setOpenConfirmDialog(true);
    handleClose();
  };

  const renderRowComp = () => {
    if (loading) return <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>Loading...</Box>;
    if (!rows.length) {
      return (
        <Box sx={{
          margin: '1.5rem 0'
        }}
        >
          <NoDisplay />
        </Box>
      );
    }
    if (rows.length > 0) {
      return (
        <Box sx={{
          margin: '1.5rem 0', width: '100%', height: '35rem', overflow: 'scroll'
        }}
        >
          { rows.map((item) => (
            <MapProductReturnRow
              key={item.id}
              row={item}
              mapHandler={mapMpInstanceHandler}
            />
          ))}
        </Box>
      );
    }
  };

  return (
    <>
      <DialogContainer
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <Close
            fontSize="small"
            style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
            onClick={handleClose}
          />
          <Title>Map to marketplace products</Title>
          <DialogContent sx={{ marginTop: '0', paddingX: 0 }}>
            <Box>
              <CustomSearchField
                name="search"
                value={search}
                placeholder="Search marketplace for products"
                handleChange={(e) => setSearch(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>
            {renderRowComp()}
          </DialogContent>
        </DialogContentStyled>
      </DialogContainer>
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Map Product?"
        desc="Are you sure you want to map product? the product you mapped to, will be the product you see on your marketplace cart"
        options={['Cancel', 'Yes, Map']}
        setStatus={setMapStatus}
        loading={mapLoading}
      />
      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title="Product Mapped"
        desc="Your product mapping was successful! the quantity of the product will be updated accordingly when added to your inventory"
        option="Ok"
      />
    </>

  );
};

MapProductDialog.propTypes = {
  id: PropTypes.string.isRequired,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
};

MapProductDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
};

export default MapProductDialog;
