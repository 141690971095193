import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@mui/material';
import { MenuItemStyled } from './actionPopper.styles';
import { Dots } from '../../assets/svgs';

export default function ActionPopper({
  row, handleAction
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { status } = row;

  return (
    <div>
      <Dots
        fontSize="large"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <Menu
        elevation={0}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {
          status !== 'ACTIVE'
            ? (
              <MenuItemStyled
                onClick={(e) => { handleAction(e, 'ACTIVE', row); handleClose(); }}
              >
                Activate Account
              </MenuItemStyled>
            )
            : (
              <MenuItemStyled
                onClick={(e) => { handleAction(e, 'IN_ACTIVE', row); handleClose(); }}
              >
                De-activate Account
              </MenuItemStyled>
            )
        }
      </Menu>
    </div>
  );
}

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
};
