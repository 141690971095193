import {
  Grid,
  TableContainer,
  Paper,
  Table,
  Typography,
  TableRow,
  TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderGridContainer = styled(Grid)`
  padding: 30px;
`;

export const CartGridContainerHeader = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const PageTitleContainer = styled('div')`
  margin-right: auto;
`;

export const PageTitleText = styled('p')`
  color: #303030;
  font-size: 4rem;
  font-weight: 700;
  margin: 0;
  padding-top: 1rem;
  @media (min-width: 992px) {
    font-size: 25px;
    padding-top: 0;
  }
`;

export const PageTitleSubText = styled('p')`
  color: #606060;
  margin: 0;
  font-size: 2.1rem;
  font-weight: 500;
  padding-bottom: 1.5rem;

  @media (min-width: 992px) {
    font-size: 16px;
    margin-top: 5px;
  }
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px rgba(163, 171, 185, 0.12);
`;

export const MainTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const TotalRecord = styled(Typography)`
  font-size: 16px;
  color: #303030;
  font-weight: 600;
`;

export const PaperExport = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 2rem 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px rgba(163, 171, 185, 0.12);
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0.5rem;
  box-shadow: none;
  padding: 10px 26px 26px 26px;
`;

export const FilterGrid = styled(Grid)`
  padding: 2rem 1.5rem 0.5rem;
  align-items: center;
`;

export const TableComponent = styled(Table)`
  margin-bottom: 1rem;
`;

export const TableCellStyled = styled(TableCell)`
  text-transform: capitalize;
  color: #24292e;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding: 20px 10px;
  border-bottom: none;
`;
