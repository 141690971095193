import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InputAdornment, Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  TableColumnRows,
  TableColumnRowText
} from '../../shared/tableGlobalStyle.styles';
import { ColorField, Status, CustomInputNumberBase } from './returnRow.styles';
import { CustomCheckbox } from './orders.styles';
import { CaretDownIcon, CaretUpIcon } from '../../../assets/svgs';
import ActionPopper from './actionPopper';
import MapProductDialog from './mapProductDialog';
import { UPDATE_ORDER_LIST } from '../../../mutations/products';
import currencyFormatter from '../../shared/currencyFormatter';

const ReturnRow = ({
  row,
  handleSelect,
  selected,
  counterHandler,
  minMax,
  changedValue
}) => {
  const {
    id: orderListProductId,
    quantity,
    dateCreated,
    product: {
      id,
      sku,
      quantityInStock,
      erpVersion: { brandName, packSize, reorderMax }
    },
    mpProductName,
    mpProductPrice,
    matchStatus
  } = row;

  const [openMapProduct, setOpenMapProduct] = useState(false);
  const [value, setValue] = useState(quantity);

  useEffect(() => {
    if (minMax) {
      setValue(changedValue ?? reorderMax);
    } else {
      setValue(changedValue ?? quantity);
    }
  }, [minMax, reorderMax, quantity, changedValue]);

  const getColorGrid = (bkg, name) => (
    <ColorField>
      <Status background={bkg} />
      {name}
    </ColorField>
  );

  const getStatus = () => {
    if (matchStatus === 'UNMATCHED') return getColorGrid('#F29053', 'Unmatched');
    return getColorGrid('#01B588', 'Matched');
  };

  const renderDate = () => moment(dateCreated).format('DD/MM/YYYY HH:mm');
  const isSelected = () => selected.indexOf(id) !== -1;

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  // Remove order list product(s)
  const [deleteOrderList] = useMutation(UPDATE_ORDER_LIST);

  const removeProductHandler = () => {
    deleteOrderList({
      variables: {
        orderListProducts: [{ productId: Number(id), quantity: 0 }]
      },
      refetchQueries: ['businessOrderListProducts', 'businessOrderListProductsTotalNumber']
    })
      .then(
        () => {
          toast.success('Product deleted successfully');
        }
      )
      .catch((err) => toast.error(err.message));
  };

  const handleAction = (event, type, _) => {
    if (type === 'map-product') {
      setOpenMapProduct(true);
    } else {
      removeProductHandler();
    }
  };

  return (
    <>
      <TableColumnRows>
        <TableColumnRowText>{renderCheckbox()}</TableColumnRowText>
        <TableColumnRowText>{renderDate()}</TableColumnRowText>
        <TableColumnRowText>{sku}</TableColumnRowText>
        <TableColumnRowText>{brandName}</TableColumnRowText>
        <TableColumnRowText>{packSize}</TableColumnRowText>
        <TableColumnRowText>{quantityInStock}</TableColumnRowText>
        <TableColumnRowText>
          <CustomInputNumberBase
            type="number"
            placeholder="0"
            min={0}
            size="medium"
            required
            value={value}
            onChange={(e) => {
              const newValue = e.target.value;
              setValue(newValue);
              counterHandler('change', row, newValue);
            }}
            endAdornment={(
              <InputAdornment position="end">
                <Grid item container direction="column">
                  <CaretUpIcon
                    style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                    onClick={() => {
                      const newValue = Number(value) + 1;
                      setValue(newValue);
                      counterHandler('increment', row, 1);
                    }}
                  />
                  <CaretDownIcon
                    style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                    onClick={() => {
                      const newValue = Math.max(Number(value) - 1, 0);
                      setValue(newValue);
                      counterHandler('decrement', row, 1);
                    }}
                  />
                </Grid>
              </InputAdornment>
            )}
            style={{ width: '100px' }}
          />
        </TableColumnRowText>
        <TableColumnRowText>{mpProductName ?? '-'}</TableColumnRowText>
        <TableColumnRowText>
          { mpProductPrice ? `₦ ${currencyFormatter(mpProductPrice)}` : '₦ 0'}
        </TableColumnRowText>
        <TableColumnRowText>{getStatus()}</TableColumnRowText>
        <TableColumnRowText>
          <ActionPopper handleAction={handleAction} row={row} />
        </TableColumnRowText>
      </TableColumnRows>
      <MapProductDialog
        id={orderListProductId}
        openDialog={openMapProduct}
        setOpenDialog={setOpenMapProduct}
      />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  selected: PropTypes.instanceOf(Array).isRequired,
  handleSelect: PropTypes.func.isRequired,
  counterHandler: PropTypes.func,
  minMax: PropTypes.bool.isRequired,
  changedValue: PropTypes.number
};

ReturnRow.defaultProps = {
  row: {},
  counterHandler: () => null,
  changedValue: null
};

export default ReturnRow;
