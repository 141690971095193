import React from 'react';
import {
  Box,
  Slide,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  DialogStyled,
  DialogContentStyled,
  DescStyled, TitleStyled
} from './appToast.styles';
import { ComplianceIcon, SuccessToastIcon } from '../../assets/svgs';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AppToast = ({
  openToast, setOpenToast, toastMessage, isWarning
}) => {
  const handleClose = async () => {
    setOpenToast(false);
  };

  const { title, description } = toastMessage;

  return (
    <DialogStyled
      open={openToast}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          boxShadow: '0px 2.96px 66.64px rgba(163, 171, 185, 0.12)',
          position: 'absolute',
          top: '5rem',
          margin: 0,
        },
      }}
    >
      <DialogContentStyled>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {
            isWarning ? <ComplianceIcon style={{ fontSize: '50px' }} /> : <SuccessToastIcon style={{ fontSize: '50px' }} />
          }
          <Box>
            <TitleStyled>{title}</TitleStyled>
            <DescStyled>{description}</DescStyled>
          </Box>
        </Box>
        <Close
          fontSize="small"
          style={{ color: '#2E90FA', marginRight: '1rem' }}
          onClick={handleClose}
        />
      </DialogContentStyled>
    </DialogStyled>
  );
};

AppToast.propTypes = {
  openToast: PropTypes.bool,
  setOpenToast: PropTypes.func,
  toastMessage: PropTypes.instanceOf(Object),
  isWarning: PropTypes.bool
};

AppToast.defaultProps = {
  openToast: false,
  setOpenToast: () => false,
  toastMessage: {
    title: '',
    description: ''
  },
  isWarning: true
};

export default AppToast;
