import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, ListItemIcon } from '@mui/material';
import {
  CListMb, CListItem, CListItemButton, CListItemText
} from './affiliatesPopper.styles';
import { RenderPopper } from '../../utils/utils';
import {
  CartIco, BoxStore, Timer, BellIco
} from '../../assets/svgs';
import { useStateValue } from '../../providers/stateProvider';
import SimpleBadge from '../shared/simpleBadge';

const TopNavigationPopperMobile = ({
  businessName, CloseShift, handleBellClick, openNavDialogue, handlMobileMenuClick, closeDialog,
  erpNotificationsTotalNumber
}) => {
  const open = Boolean(openNavDialogue);
  const [{
    cart: { cartCounter },
  }] = Object.values(useStateValue());

  const navigate = useNavigate();
  const [manualOrder, setManualOrder] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const { pathname: pname } = location;
    const role = localStorage.getItem('oga_user_role');
    if (role === 'store-manager' || role === 'chain-manager') {
      setManualOrder(true);
    } else if (pname !== '/') {
      setManualOrder(false);
    }
  }, [location]);

  return (
    <RenderPopper
      showArrow
      open={open}
      anchorEl={openNavDialogue}
      onClickAway={() => handlMobileMenuClick()}
      popperPlacement="bottom-end"
      modifiers={[
        { name: 'offset', options: { offset: [15, 15] } },
      ]}
    >
      <Grid container item>
        <CListMb>
          <CListItem disableGutters>
            <CListItemButton onClick={() => {
              handleBellClick();
              handlMobileMenuClick();
            }}
            >
              <ListItemIcon>
                <SimpleBadge value={erpNotificationsTotalNumber}>
                  <BellIco style={{ fontSize: '3rem' }} />
                </SimpleBadge>
              </ListItemIcon>
              <CListItemText primary="Notifications" />
            </CListItemButton>
          </CListItem>
          <CListItem disableGutters>
            <CListItemButton onClick={() => {
              closeDialog();
              handlMobileMenuClick();
            }}
            >
              <ListItemIcon>
                <BoxStore style={{ fontSize: '3rem' }} />
              </ListItemIcon>
              <CListItemText primary={businessName} />
            </CListItemButton>
          </CListItem>
          <CListItem disableGutters>
            <CListItemButton onClick={() => {
              closeDialog();
              handlMobileMenuClick();
            }}
            >
              <ListItemIcon>
                <Timer style={{ fontSize: '3rem' }} />
              </ListItemIcon>
              <CListItemText primary={CloseShift} />
            </CListItemButton>
          </CListItem>
          <CListItem disableGutters>
            <CListItemButton onClick={() => navigate(manualOrder ? '/manual-cart' : '/cart')}>
              <ListItemIcon>
                <SimpleBadge value={cartCounter}>
                  <CartIco style={{ fontSize: '3rem' }} />
                </SimpleBadge>
              </ListItemIcon>
              <CListItemText primary="View Cart" />
            </CListItemButton>
          </CListItem>
        </CListMb>
      </Grid>
    </RenderPopper>
  );
};

TopNavigationPopperMobile.propTypes = {
  businessName: PropTypes.string.isRequired,
  CloseShift: PropTypes.string.isRequired,
  handleBellClick: PropTypes.func.isRequired,
  openNavDialogue: PropTypes.instanceOf(Object),
  handlMobileMenuClick: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  erpNotificationsTotalNumber: PropTypes.number.isRequired,
};

TopNavigationPopperMobile.defaultProps = {
  openNavDialogue: {},
};

export default TopNavigationPopperMobile;
