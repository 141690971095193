import {
  DialogContent,
  Typography,
  Checkbox,
  Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    min-width: 984px;
    border-radius: 12px;
    background: #F5FAFF;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 984px;
  height: auto;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  padding: 0.8rem;
  margin: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const TitleStyled = styled('span')`
  font-weight: 600;
  font-size: 16px;
  color: #235A91;
`;

export const DescStyled = styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: #606060;
`;
