import { styled } from '@mui/material/styles';
import {
  TableContainer, Grid
} from '@mui/material';
import { ExportIcon } from '../../../assets/svgs';

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
`;

export const ExportIconStyled = styled(ExportIcon)`
  font-size: 1.5rem;
  color: #235A91;
  margin-right: 5px;
`;

export const SearchGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

export const DivFullWidth = styled('div')`
  margin-top: 1rem;
  width: 100%
`;
