import {
  Paper, Typography, Grid, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MobileContainer = styled(Paper)`
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 1rem;
  width: 100%;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: 12px;
`;

export const DateText = styled(Typography)`
  font-weight: 600;
  font-size: 2.5rem;
  padding: 2rem;
`;

export const HeaderText = styled(Grid)`
  font-weight: 600;
  font-size: 2rem;
`;

export const DetailText = styled(Grid)`
  text-align: right;
  font-size: 2rem;
`;

export const TextBox = styled(Grid)`
  padding: 0 2rem 2rem 2rem;
`;

export const ContentBox = styled(Box)`
  margin-top: 1rem;
`;

export const StatusBox = styled(Box)`
`;

export const ColorGrid = styled(Box)`
  background: ${({ background }) => (background)};
  border-radius: 11px;
  color: ${({ color }) => (color)};
  font-size: 1.5rem;
  font-weight: 500;
  padding: 10px 0;
  width: 65%;
  text-align: center;
  margin-left: auto;
`;
