import React from 'react';
import PropTypes from 'prop-types';
import {
  TableColumnRows,
  TableColumnRowText
} from '../shared/tableGlobalStyle.styles';
import currencyFormatter from '../../utils/currencyFormatter';
import ActionPopper from './actionPopper';

const ReturnRow = ({ row, idx, actionHandler }) => {
  const {
    id, name, loyaltyPoint, storeCredit
  } = row;

  return (
    <TableColumnRows>
      <TableColumnRowText>{idx + 1}</TableColumnRowText>
      <TableColumnRowText>{name}</TableColumnRowText>
      <TableColumnRowText>{loyaltyPoint}</TableColumnRowText>
      <TableColumnRowText>{currencyFormatter(storeCredit)}</TableColumnRowText>
      <TableColumnRowText>{id}</TableColumnRowText>
      <TableColumnRowText>
        <ActionPopper row={row} handleAction={actionHandler} />
      </TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  idx: PropTypes.number.isRequired,
  actionHandler: PropTypes.func.isRequired
};

export default ReturnRow;
