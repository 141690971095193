import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import MainContent from '../../customComponents/mainContent';
import TitleGrid from './titleGrid';
import SideNav from '../shared/sideNav/sideNav';
import {
  TitleGridWrapper, OutletContentWrapper,
} from './rolesContainer.styles';
import { ALL_BUSINESS_ROLES_QUERY } from '../../../queries/businesses';
import { useStateValue } from '../../../providers/stateProvider';
import Roles from './roles/roles';

const startDate = moment().startOf('day').format('YYYY-MM-DD');
const endDate = moment().endOf('day').format('YYYY-MM-DD');
const initialState = {
  searchText: '',
  dateFilter: {
    dateFrom: startDate,
    dateTo: endDate
  }
};

const RolesContainer = () => {
  const [state, setState] = useState(initialState);
  const [key, setKey] = useState(0);
  const [pageCount, setPageCount] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const { searchText } = state;
  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const variables = {
    pageCount,
    pageNumber,
    search: searchValue
  };

  const {
    loading, error, data, refetch
  } = useQuery(ALL_BUSINESS_ROLES_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const {
    allSystemRoles = [],
  } = data || {};

  const rows = allSystemRoles;

  return (
    <MainContent>
      <TitleGridWrapper>
        <TitleGrid
          setContentKey={setKey}
          refetch={refetch}
        />
      </TitleGridWrapper>
      <OutletContentWrapper container spacing={3}>
        <SideNav />
        <Roles
          state={state}
          rows={rows}
          handleSearch={handleSearch}
          loading={loading}
          refetch={refetch}
        />
      </OutletContentWrapper>
    </MainContent>
  );
};

export default RolesContainer;
