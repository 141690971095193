import React, {
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Dialog, Grid, InputAdornment, Slide
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { JSONParse } from '../../../../utils/json';
import {
  DialogHeaderContainer, DialogSearchContainer, DialogFooterContainer,
  DialogDoneButton, DialogActionButtonText, HeaderText, SubHeaderText,
  AffiliatesContainer, DialogCancelButton, Loading, CenterTextGrid,
} from './affiliateModal.styles';
import {
  SearchButton, SearchContainer, SearchImage, SearchTextField
} from '../../newOrder/newOrderContainer.styles';
import SearchIcon from '../../../../assets/images/Search Icon.png';
import { AFFILIATE_QUERY } from '../../../../queries/affiliates';
import AffiliateSearchCard from '../../../affiliates/affiliateSearchCard';
import { useStateValue } from '../../../../providers/stateProvider';
import { UPDATE_CURRENT_ORDER_BUSINESS } from '../../../../mutations/orders';
import VerificationDialog from '../../newOrder/verificationDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

export function AffiliateModal({
  open, handleCloseModal, query, queryName, nextRoute,
  afterMath
}) {
  const initialState = {
    searchText: '',
    placeholderSearchText: '',
    searchValue: '',
    currentPageCount: 25,
    currentPage: 1,
    selectedAffiliate: null
  };
  const [{ cart: { isManualOrder } }] = Object.values(useStateValue());
  const [updateCurrentOrderBusiness] = useMutation(UPDATE_CURRENT_ORDER_BUSINESS);
  const [openDialog, setOpenDialog] = useState(false);
  const [userStatus, setUserStatus] = useState('');
  const [state, setState] = useState(initialState);

  const navigate = useNavigate();

  const {
    searchText, searchValue, currentPageCount,
    currentPage, selectedAffiliate
  } = state;
  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleOnClickSearch = () => {
    setState({
      ...state,
      searchValue: searchText,
      placeholderSearchText: searchText,
    });
  };

  const handleCloseDialog = (event) => {
    setOpenDialog(openDialog ? false : Boolean(event.currentTarget));
  };

  const { loading, error, data } = useQuery(query, {
    fetchPolicy: 'no-cache',
    variables: {
      name: searchValue,
      pageCount: currentPageCount,
      pageNumber: currentPage
    },
  });

  const role = localStorage.getItem('oga_user_role');
  const businesses = data?.[queryName];
  const fallbackRoute = role === 'chain-manager' ? '/manual-order' : '/new-order';
  if (open && !businesses?.length && (role !== 'oga-pharmacy-admin')) {
    navigate(nextRoute || fallbackRoute);
  }

  const filteredData = businesses?.length && businesses
    .map((business) => {
      const parsedContacts = JSONParse(business.contacts);
      const { city = 'Lagos' } = parsedContacts;
      return { ...business, city };
    });

  const handleClickDone = (event) => {
    if (selectedAffiliate.status !== 'VERIFIED') {
      setUserStatus(selectedAffiliate.status);
      setOpenDialog(true);
      return;
    }
    localStorage.setItem('oga_ordering_for_affiliate', JSON.stringify(selectedAffiliate));
    if (afterMath) {
      afterMath(event, isManualOrder ? '/manual-order' : '/new-order');
    } else {
      navigate({
        pathname: isManualOrder ? (nextRoute || '/manual-order') : (nextRoute || '/new-order'),
        state: { businessId: selectedAffiliate.id }
      });
    }
  };

  const handleOnClickCard = (hist, id, affiliate) => {
    updateCurrentOrderBusiness({
      variables: { businessId: affiliate.id }
    })
      .then(() => {
        setState({
          ...state,
          selectedAffiliate: affiliate,
          searchText: affiliate.name,
          placeholderSearchText: searchText,
        });
      })
      .catch(() => {
      })
      .finally(() => {
      });
  };

  const renderDialogBody = () => {
    if (loading) {
      return (
        <Loading container justifyContent="center" alignItems="center">
          <CenterTextGrid item md={6} xs={6}>Loading</CenterTextGrid>
        </Loading>
      );
    }
    if (error) return <div>{error.message}</div>;
    if (!filteredData?.length) {
      return (
        <Loading container justifyContent="center" alignItems="center">
          <CenterTextGrid item md={6} xs={6}>No record found</CenterTextGrid>
        </Loading>
      );
    }
    return (
      <Grid container item>
        {filteredData.map((affiliate) => (
          <AffiliateSearchCard
            key={affiliate.id}
            id={affiliate.id}
            logo={affiliate.logo}
            name={affiliate.name}
            cardSelected={selectedAffiliate && selectedAffiliate.id === affiliate.id}
            handleOnClickCard={(hist, id) => handleOnClickCard(hist, id, affiliate)}
            location={`${affiliate.city}`}
            grossOrder={affiliate.grossOrderAmount}
          />
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => ('')}
        maxWidth="md"
        fullWidth
      >
        <DialogHeaderContainer item container>
          <Grid item container direction="column">
            <HeaderText>Select Affiliate</HeaderText>
            <SubHeaderText>
              Search for the affiliate you want to place an order on behalf of
            </SubHeaderText>
          </Grid>
        </DialogHeaderContainer>
        <DialogSearchContainer direction="column" justifyContent="center" alignItems="center">
          <SearchContainer container item>
            <SearchTextField
              variant="outlined"
              label=""
              autoFocus
              value={searchText}
              onChange={(e) => (handleSearch(e.target.value))}
              placeholder="Search affiliates"
              fullWidth
              InputProps={{
                endAdornment:
  <InputAdornment position="end">
    <SearchButton
      aria-label="search affiliates"
      edge="end"
      onClick={handleOnClickSearch}
    >
      <SearchImage alt="search icon" src={SearchIcon} />
    </SearchButton>
  </InputAdornment>
              }}
            />
          </SearchContainer>
          <AffiliatesContainer container item>
            {renderDialogBody()}
          </AffiliatesContainer>
        </DialogSearchContainer>
        <DialogFooterContainer container justifyContent="center">
          <DialogCancelButton onClick={handleCloseModal}>
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>
          <DialogDoneButton
            disabled={!selectedAffiliate}
            onClick={handleClickDone}
          >
            <DialogActionButtonText>
              Done
            </DialogActionButtonText>
          </DialogDoneButton>
        </DialogFooterContainer>
      </Dialog>
      {openDialog && (
        <VerificationDialog
          openDialog={openDialog}
          handleClose={handleCloseDialog}
          type="affiliateAdmin"
          status={userStatus}
        />
      )}
    </>
  );
}
AffiliateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  query: PropTypes.instanceOf(Object),
  queryName: PropTypes.string,
  nextRoute: PropTypes.string,
  afterMath: PropTypes.func.isRequired
};
AffiliateModal.defaultProps = {
  query: AFFILIATE_QUERY,
  queryName: 'allBusinesses',
  nextRoute: ''
};
export default AffiliateModal;
