import {
  Grid, Typography, Button, LinearProgress, Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  flex-direction: column;
  margin: 30px 0;
`;

export const Image = styled('img')`
  max-width: 60%;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
`;

export const AffiliateName = styled(Typography)`
  margin-top: 10px;
  font-weight: 700;
  color: #303030;
  font-size: 22px;

  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const PharmacyName = styled(Typography)`
  margin-top: 10px;
  font-weight: 400;
  font-size: 1rem;
  color: #303030;

  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const LicenceImage = styled('img')`
  max-width: 100%;
  margin-top: 15px;
`;

export const LogoWrapper = styled(Grid)`
  position: relative;
`;

export const LicenceWrapper = styled(Grid)`
  width: 90%;
  padding-top: 1rem;
  margin-left: 2rem;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;

  @media(max-width: 991px) {
    padding-top: 4rem;
  }
`;

export const LicenceHeader = styled(Typography)`
  font-size: 23px;
  font-weight: 500;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const ImageWrapper = styled(Grid)`
  padding: 0px 0rem 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const EditButton = styled(Button)`
  height: 50px;
  width: 150px;
  padding: 0px 15px;
  color: #FFFFFF;
  background-color: #000000;
  border-radius: 10px;
  margin: 20px 2px;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    background-color: #000000;
  }
  @media(max-width: 991px) {
    font-size: 27px;
    height: 5rem;
    width: 14rem;
  }
`;

export const DocumentsButton = styled(Button)`
  height: 50px;
  width: 80%;
  padding: 15px;
  color: #FFFFFF;
  background-color: #000000;
  border-radius: 10px;
  margin: 20px 2px;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    background-color: #000000;
  }

  @media(max-width: 991px) {
    font-size: 27px;
    width: 80%;
    height: 68px;
  }
  &.MuiButton-root.Mui-disabled {
    color: #FFFFFF;
  }
`;

export const ButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const FileText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #676767;
  margin-top: 1rem;
`;

export const FileName = styled(Link)`
  color: #676767;
  &:hover {
  color: #676767;
    cursor: pointer;
  }
`;

export const UploadWrapper = styled(Grid)`
  position: relative;
  width: 80%;
  margin-top: 2rem;
`;

export const FileContainer = styled(Grid)`
  width: 80%;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
`;

export const FileWrapper = styled(Grid)`
  justify-content: space-between;
  border: 1px solid #11AF22;
  border-radius: 4px;
  padding: 12px 10px;
  margin-top: 1rem;
`;

export const FileUploading = styled(Grid)`
  justify-content: space-between;
  border: 0.5px solid #E3E3E3;
  border-radius: 4px;
  padding: 12px 10px;
  margin-top: 1rem;
`;

export const Delete = styled('img')`
  font-size: 3.5rem;
  color: #A3A3A3;
  &:hover {
      cursor: pointer;
    }

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const ProgressWrapper = styled(LinearProgress)`
  color: #000000;
  position: relative;
`;

export const LinearProgressLoader = styled(LinearProgress)`
  margin-top: 4px;
  position: initial;
  & .MuiCircularProgress-svg {
    width: 1.6rem;
  }
  .MuiLinearProgress-bar1Indeterminate {
    background-color: #FFF;
    & .MuiLinearProgress-barColorPrimary {
      background-color: #FFF;
  }
  }
`;
