import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Button, DialogContent,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { KeyboardArrowDown } from '@mui/icons-material';
import CustomInputBase from '../../../customComponents/customInputBase';
import {
  CDialog, DialogTitleText, CircularProgressLoader, GridWrapper, CloseIcon, Description, AdornmentStyled
} from './createSubBusinessDialog.styles';
import { CREATE_SUB_BUSINESS } from '../../../../mutations/business';
import { validateEmail, validateMobile } from '../../../auth/utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateSubBusinessDialog = ({
  open, close
}) => {
  const initialState = {
    businessName: '', phoneNumber: '', businessAddress: '', emailAddress: ''
  };

  const initialErrorState = {
    businessNameError: false,
    businessAddressError: false,
    emailAddressError: false,
    phoneNumberError: false
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);

  const {
    businessName, phoneNumber, businessAddress, emailAddress
  } = state;

  const {
    businessNameError, businessAddressError, emailAddressError, phoneNumberError
  } = errorState;

  const [createSubBusiness, { loading }] = useMutation(CREATE_SUB_BUSINESS);

  const phoneAdornment = (
    <AdornmentStyled>
      <span>+234</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const fields = [
    {
      name: 'businessName', label: 'Business Name', required: true, placeholder: 'Enter your business name',
      error: businessNameError, helperText: 'Business name required', secured: false, show: true
    },
    {
      name: 'businessAddress', label: 'Business Address', required: true, placeholder: 'Enter your business address',
      error: businessAddressError, helperText: 'Business address required', secured: false, show: true
    },
    {
      name: 'emailAddress', label: 'Email Address', required: true, placeholder: 'Enter your email address',
      error: emailAddressError, helperText: 'Email address required', secured: false, show: true
    },
    {
      name: 'phoneNumber', label: 'Phone Number', required: true, placeholder: 'Enter your phone number',
      error: phoneNumberError, helperText: 'Phone number is required', secured: false, show: true, sAdornment: phoneAdornment
    },
  ];

  const handleCloseDialog = () => {
    setState(initialState);
    close();
  };

  const handleCreateSubBusiness = () => {
    createSubBusiness({
      variables: { ...state, phoneNumber: `+234${state.phoneNumber}` },
      refetchQueries: ['SubBusinesses']
    }).then(({ data: { createSubBusiness: { message } } }) => {
      toast.success(message);
      handleCloseDialog();
    }).catch((err) => toast.error(err.message));
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'businessName':
      case 'businessOwnerFullName':
      case 'businessAddress':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });
      case 'phoneNumber': return setErrorState({
        ...errorState,
        phoneNumberError: validateMobile(value)
      });
      case 'emailAddress':
        return setErrorState({
          ...errorState,
          emailAddressError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState((s) => ({ ...s, [name]: value }));
  };

  const handleSave = () => {
    if (businessName === '') {
      toast.error('Business name is required');
    } else if (businessAddress === '') {
      toast.error('Business address is required');
    } else if (validateEmail(emailAddress)) {
      toast.error('Email address is required');
    } else if (validateMobile(phoneNumber)) {
      toast.error('Phone number address is required');
    } else {
      handleCreateSubBusiness();
    }
    return setErrorState({
      ...errorState,
      businessNameError: businessName === '',
      businessAddressError: businessAddress === '',
      emailAddressError: validateEmail(emailAddress),
      phoneNumberError: validateMobile(phoneNumber),

    });
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, isLoading, secured, placeholder, type, readOnly, sAdornment
    } = field;
    const value = state[fieldName];
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={isLoading}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        sAdornment={sAdornment}
        cSize="lg"
        readOnly={readOnly}
      />
    );
  };

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={8}>
            <DialogTitleText container item xs={12}>
              Add Sub-business
            </DialogTitleText>
            <Description>Add your store information to the fields in the form</Description>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={3}>
            {fields.map((field) => (
              <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            style={{ width: '11rem', height: '3.2rem', marginRight: '1.2rem' }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ width: '11rem', height: '3.2rem' }}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : 'Add' }
          </Button>
        </DialogActions>
      </GridWrapper>
    </CDialog>
  );
};

CreateSubBusinessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default CreateSubBusinessDialog;
