import {
  Grid, Typography, IconButton, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Check, Close, Storefront
} from '@mui/icons-material';

export const BatchDialogHeader = styled(Grid)`
    background: '#fff';
    padding: 15px 60px;
  `;
export const BatchDialogBody = styled(Grid)`
    padding: 20px 60px;
    background: #F0F0F0;
    min-height: 200px;
  `;
export const BatchItem = styled(Grid)`
    background: #fff;
    padding: 10px 10px 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
  `;

export const Input = styled('input')`
  border: 0;
  width: 50px;
  height: 30px;
  & :focus {
    border: 0;
  }
`;

export const ColumnHeader = styled(Grid)`
   margin-bottom: 10px;
   opacity: 0.5;
  `;
export const PackSize = styled(Grid)`

  `;
export const Star = styled(Grid)`
padding-top: 8px;
.red{
  color:red;
}
.yellow{
  color: yellow;
}
.white{
  color: white;
}
`;
export const CheckIcon = styled(Check)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;
export const OutOfStock = styled('img')`
  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const CloseIcon = styled(Close)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;
export const CheckIconContainer = styled(Grid)`
    cursor: pointer;
    padding: 8px;
    background-color: transparent;
    border: 1px solid;
    border-radius: 100px;
    margin-left: 5px;
    font-size: 5px;
`;

export const OutOfStockIconContainer = styled(Grid)`
    cursor: pointer;
    background-color: transparent;
`;

export const ButtonContainer = styled(Grid)`
  `;
export const DialogOkButton = styled(IconButton)`
    height: 50px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 25px;
    border-radius: 10px;
    margin-right: 30px;
    background-color: #FCED50;
    color: #424242;
    margin-bottom: 40px;

    &:hover {
      background-color: #D2C647;
    }
  `;

export const ProductName = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;
export const AddButton = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  background: #FCED50;
  margin-left: 25px;
  color: #424242;
  padding: 20px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
`;
export const OutOfStockContainer = styled(Paper)`
  padding: 20px;
  background: #E4E4E4;
  max-width: 320px;
  margin-top: 17px;
`;
export const InfoText = styled(Typography)`
  font-size: 10px;
  color: #887F23;
`;
export const OutOfStockText = styled(Typography)`
  font-weight: 600;
  color: #424242;
`;
