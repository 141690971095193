import React from 'react';
import PropTypes from 'prop-types';
import {
  GridContainer, ItemTypo, ItemWrapper, GridWrapper
} from '../../../toBeDeleted/uploadSupplier/supplierCreatePopper.styles';
import { RenderPopper } from '../../../../utils/utils';

const CustomerPopper = ({
  customerButton,
  handleCustomerButtonClick,
  individualDialogHandler,
  openDialogHandler
}) => {
  const open = Boolean(customerButton);

  const handleManualCustomerAdd = () => {
    handleCustomerButtonClick();
    individualDialogHandler();
  };

  const csvUploadHandler = () => {
    handleCustomerButtonClick();
    openDialogHandler();
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={customerButton}
      onClickAway={handleCustomerButtonClick}
      popperPlacement="bottom-end"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer container item onClick={handleManualCustomerAdd}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Add individually
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>

        <GridContainer container item onClick={csvUploadHandler} data-testid="uploadCsvButton">
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Upload CSV file
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
};

CustomerPopper.propTypes = {
  customerButton: PropTypes.shape(Element).isRequired,
  handleCustomerButtonClick: PropTypes.func.isRequired,
  individualDialogHandler: PropTypes.func.isRequired,
  openDialogHandler: PropTypes.func.isRequired
};

export default CustomerPopper;
