import React, { useEffect, useState } from 'react';
import {
  TableBody, TableContainer, Table
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MainContent from '../../customComponents/mainContent';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import {
  TableHeader,
  GridContainer,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  SubTitle,
  MenuButton,
} from './orderHistory.styles';
import CustomSearchField from '../../shared/CustomSearchField';
import TablePagination from '../../shared/tablePagination';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import { BUSINESS_ORDER_LISTS } from '../../../queries/orders';

const headers = [
  'Date Sent',
  'Order ID',
  'No. of Products',
  'Marketplace Account',
  'Action'
];

const initialState = {
  searchText: '',
  search: '',
};

const OrderHistory = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);

  const {
    searchText, search
  } = state;

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  useEffect(() => {
    if (searchText && searchText.length >= 1) {
      setState({ ...state, search: searchText });
    } else if (!searchText) {
      setState({ ...state, search: '' });
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const { loading, data } = useQuery(BUSINESS_ORDER_LISTS, {
    variables: {
      orderId: search, pageCount, pageNumber
    },
    fetchPolicy: 'network-only'
  });

  const response = data?.businessOrderLists || [];
  const totalNumber = data?.businessOrderListsTotalNumber || 0;

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <MenuButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            Back
          </MenuButton>
          <TitleTextGridContainer>
            <div>
              <PrimaryTitle variant="h5">Order History</PrimaryTitle>
              <SubTitle>View all the orders you have sent to the marketplace here</SubTitle>
            </div>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchFlex>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search by Order ID"
              handleChange={(e) => handleSearch(e.target.value)}
              style={{ width: '100%' }}
            />
          </SearchFlex>
          <TableGrid item container>
            {loading ? (
              <ProductsTableLoader />
            ) : (
              <TableContainer>
                <Table>
                  <MainTableHead>
                    {returnHeaders()}
                    <TableHeader />
                  </MainTableHead>
                  <TableBody>
                    {
                    response?.length
                      ? response?.map((item, index) => {
                        const idx = item?.id;
                        return <ReturnRow key={idx} row={item} sn={index + 1} />;
                      })
                      : (
                        <MainTableRow>
                          <BodyCell colspan={5} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                        </MainTableRow>
                      )
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TableGrid>
          <FooterWrapper item container>
            {totalNumber > 0 && (
              <TablePagination
                total={totalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default OrderHistory;
