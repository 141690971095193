import {
  TableRow, TableCell, Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  background: ${({ background }) => (background)};
  border-radius: 50%;
  width: 9px;
  height: 9px;
`;
