import React from 'react';
import {
  DialogContent,
  Slide,
  Box,
  Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import success from '../../assets/images/success.gif';
import {
  DialogContainer,
  DialogContentStyled,
  TitleStyled,
  DescStyled,
  FooterStyled
} from './successDialog.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SuccessDialog = ({
  openDialog, setOpenDialog, title, desc, option, refetch
}) => {
  const handleClose = async () => {
    if (refetch) refetch();
    setOpenDialog(false);
  };

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem' }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <img src={success} alt="warning" width={155} />
            </Box>
            <TitleStyled>{title}</TitleStyled>
            <DescStyled>{desc}</DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <Button
            variant="contained"
            onClick={handleClose}
            fullWidth
            style={{ width: '100%' }}
          >
            {option}
          </Button>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

SuccessDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  option: PropTypes.string,
  refetch: PropTypes.func.isRequired,
};

SuccessDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  title: '',
  desc: '',
  option: '',
};

export default SuccessDialog;
