import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Button,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { DivFlex, MenuItemStyled } from './actionButtonsPopper.style';

const ActionButtonsPopper = ({ filter, handleAction }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleFilterUpdate = (type) => {
    handleAction(type);
    setOpen(false);
  };

  return (
    <>
      <DivFlex data-testid="filterUpdate">
        <Button
          variant="outlined"
          style={{ width: '143px', textTransform: 'capitalize' }}
          onClick={handleToggle}
          ref={anchorRef}
        >
          {`${filter}ly`}
          <ExpandMore />
        </Button>
      </DivFlex>
      <Popper
        style={{ zIndex: '100', width: '143px' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled onClick={() => handleFilterUpdate('week')}>
                    Weekly
                  </MenuItemStyled>
                  <MenuItemStyled onClick={() => handleFilterUpdate('month')}>
                    Monthly
                  </MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

ActionButtonsPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
};

export default ActionButtonsPopper;
