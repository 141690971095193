import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Grid, InputAdornment, TableBody, useMediaQuery, Button, Box, TableContainer, Table
} from '@mui/material';
import { toast } from 'react-hot-toast';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import { FIND_SUPPLIERS_QUERY } from '../../queries/suppliers';
import { FIND_MANUFACTURERS_QUERY } from '../../queries/manufacturers';
import IndividualSupplierDialog from './individual/individualSupplierDialog';
import SupplierDropdown from '../toBeDeleted/uploadSupplier/supplierCreatePopper';
import MainContent from '../customComponents/mainContent';
import IndividualManufacturerDialog from '../shared/uploadManufacturer/individual/individualManufacturerDialog';
import {
  TableHeader, FooterGrid, GridContainer, MainTableHead, SearchContainer, SearchTextField, SearchButton, SearchImage,
  MobileContainer, PaperWrapper, TCustomButton, TableGrid, FooterWrapper, SearchGrid, Switch
} from './suppliers.styles';
import { EXPORT_MUTATION } from '../../mutations/reports';
import CustomButton from '../customComponents/customButton';
import CustomSearchField from '../shared/CustomSearchField';
import TablePagination from '../shared/tablePagination';
import ReturnRow from './returnRow';
import CTablePagination from '../customComponents/cTablePagination';
import { useStateValue } from '../../providers/stateProvider';
import SupplierActionTypes from '../../providers/reducers/supplier/supplierTypes';
import SearchStatus from '../../assets/images/marketOrder/searchStatus.png';
import MobileReturnRow from './mobileReturnRow';
import withAuth from '../../utils/withAuth';
import Filter from './filter';

const supplierHeaders = ['S/N', 'Supplier', 'Total Products Per Supplier', 'Total Products Value'];
const manufacturerHeaders = ['S/N', 'Manufacturer', 'Total Products Per Manufacturer', 'Total Products Value'];

const initialState = {
  currentPage: 1,
  currentPageCount: 20,
  searchText: '',
  searchValue: '',
};

function Suppliers() {
  const [state, setState] = useState(initialState);
  const [toggleTab, setToggleTab] = useState(true); // if true select suppliers
  const [supplierButton, setSupplierLogoEl] = useState(null);
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [openManufacturerDialog, setOpenManufacturerDialog] = useState(false);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);

  const [tab, setTab] = useState('suppliers');

  const handleTabChange = (val) => {
    setTab(val);
    setState(initialState); setToggleTab(!toggleTab); setPageNumber(1);
  };

  const [{
    supplier: { addSupplierDialogOpen }, user: { allowedPermissionsMap: { userPermissions } }
  }, dispatch] = Object.values(useStateValue());

  const {
    currentPage, currentPageCount, searchText, searchValue
  } = state;
  const toggleIndividualDialog = () => {
    dispatch({ type: SupplierActionTypes.TOGGLE_DIALOG_OPEN });
  };
  const handleSupplierButtonClick = () => {
    // setSupplierLogoEl(supplierButton ? null : event.currentTarget);
    toggleIndividualDialog();
  };
  const toggleManufacturerDialog = () => setOpenManufacturerDialog(!openManufacturerDialog);

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const csvDownloadVariable = toggleTab
    ? { type: 'suppliers', name: 'Supplier Report', variable: {} }
    : { type: 'manufacturers', name: 'Manufacturer Report', variable: {} };

  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const handleDownload = async () => {
    const { type, name, variable } = csvDownloadVariable;
    const randomNum = Math.floor(Math.random() * 10000);
    const NewfileName = `${name} ${randomNum}`;

    exportMutation({
      variables: {
        type,
        name: NewfileName,
        ...variable
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, searchValue: searchText });
    } else if (!searchText) {
      setState({ ...state, searchValue: '' });
    }
  }, [searchText]);
  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const renderMobileSearch = () => (
    <SearchContainer item xs={12}>
      <SearchTextField
        variant="outlined"
        label=""
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search by Supplier"
        fullWidth
        InputProps={{
          startAdornment:
  <InputAdornment
    position="start"
  >
    <SearchButton
      aria-label="Search Order"
      edge="end"
      disabled
    >
      <SearchImage
        alt="search icon"
        src={SearchStatus}
      />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const returnHeaders = () => {
    switch (toggleTab) {
      case true:
        return supplierHeaders.map((header) => <TableHeader className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}>{header}</TableHeader>);
      case false:
        return manufacturerHeaders.map((header) => <TableHeader className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}>{header}</TableHeader>);
      default:
        return null;
    }
  };

  const variables = {
    pageCount: isSmall ? currentPageCount : pageCount,
    pageNumber: isSmall ? currentPage : pageNumber,
    search: searchValue
  };

  const {
    loading, error, data
  } = useQuery(FIND_SUPPLIERS_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
    onlyBusinessSuppliers: true,
    skip: !isSmall && !toggleTab
  });

  const { loading: manLoading, data: manData, error: manError } = useQuery(FIND_MANUFACTURERS_QUERY, {
    variables,
    fetchPolicy: 'no-cache',
    skip: toggleTab
  });

  if (error || manError) return <div>{error.message || manError.message}</div>;
  const totalNumber = (toggleTab ? data?.supplierTotalNumber : manData?.manufacturersTotalNumber) || 0;
  const rows = (toggleTab ? data?.findSupplier : manData?.manufacturers) || [];

  const mobileTotalNumber = data?.supplierTotalNumber || 0;
  const mobileRows = data?.findSupplier || [];

  return (
    <MainContent>
      <GridContainer container>
        {!isSmall ? (
          <PaperWrapper elevation={0}>
            <Switch>
              <Filter
                tabVal={tab}
                handleTabChange={handleTabChange}
              />
            </Switch>
            <SearchGrid item container spacing={1}>
              <Grid item container xs={8}>
                <CustomSearchField
                  name="search"
                  value={searchText}
                  placeholder="Search"
                  handleChange={(e) => handleSearch(e.target.value)}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  style={{ width: '100%' }}
                  onClick={handleDownload}
                >
                  Download CSV
                </Button>
              </Grid>
              <Grid item xs={2}>
                {
                  toggleTab
                    ? (
                      <Button
                        variant="contained"
                        style={{ width: '100%', height: '3rem' }}
                        onClick={handleSupplierButtonClick}
                      >
                        Create New Supplier
                      </Button>
                    )
                    : (
                      <Button
                        variant="contained"
                        style={{ width: '100%', height: '3rem' }}
                        onClick={toggleManufacturerDialog}
                      >
                        Create New Manufacturer
                      </Button>
                    )
                }
              </Grid>
            </SearchGrid>
            <TableGrid item container>
              {loading || manLoading ? (
                <ProductsTableLoader />
              ) : (
                <TableContainer>
                  <Table>
                    <MainTableHead>
                      {returnHeaders()}
                    </MainTableHead>
                    <TableBody>
                      {rows.map((item, index) => (
                        <ReturnRow
                          key={item.id}
                          row={item}
                          index={index}
                          pageNumber={pageNumber}
                          pageCount={pageCount}
                          toggleTab={toggleTab}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </TableGrid>
            <FooterWrapper item container>
              {totalNumber > 0 && (
                <TablePagination
                  total={totalNumber}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              )}
            </FooterWrapper>
          </PaperWrapper>
        )
          : (
            <MobileContainer>
              <Box>
                {renderMobileSearch()}
              </Box>
              <Button
                variant="contained"
                fullWidth
                onClick={handleSupplierButtonClick}
                disabled={!userPermissions?.includes('suppliers_create_new_supplier')}
                style={{
                  backgroundColor: '#000000', borderRadius: '20px',
                  color: '#fff', height: '7rem', fontSize: '3rem', marginBottom: '3rem'
                }}
              >
                +   Create new supplier
              </Button>
              {loading ? <ProductsTableLoader /> : (
                mobileRows.map((supplier, index) => (
                  <MobileReturnRow
                    key={supplier.id}
                    row={supplier}
                    index={index}
                    pageCount={currentPageCount}
                    pageNumber={currentPage}
                  />
                )))}
              <FooterGrid item container>
                <CTablePagination
                  rowsPerPageOptions={[100]}
                  component="div"
                  count={mobileTotalNumber}
                  page={currentPage - 1}
                  onChangePage={handleChangePage}
                  rowsPerPage={currentPageCount}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </FooterGrid>
            </MobileContainer>
          )}
        <SupplierDropdown
          supplierButton={supplierButton}
          handleSupplierButtonClick={handleSupplierButtonClick}
          individualDialogHandler={toggleIndividualDialog}
        />
        <IndividualManufacturerDialog
          dialogOpen={openManufacturerDialog}
          closeDialog={() => setOpenManufacturerDialog(false)}
          fromProduct
        />
        <IndividualSupplierDialog
          dialogOpen={addSupplierDialogOpen}
          closeDialog={toggleIndividualDialog}
        />
      </GridContainer>
    </MainContent>
  );
}

export default Suppliers;
