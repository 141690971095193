import React from 'react';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle
} from './titleGrid.styles';

export function TitleGrid() {
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item md={6}>
        <PrimaryTitle>
          Outlets
        </PrimaryTitle>
        <SecondaryTitle>
          Add, view and edit outlet(s)
        </SecondaryTitle>
      </TitleTextGridContainer>
    </TitleGridContainer>
  );
}

export default TitleGrid;
