import React from 'react';
import PropTypes from 'prop-types';
import { JSONParse } from '../../../utils/json';
import {
  BodyCell, MainTableRow, ColorField, Status
} from './returnRow.styles';

const ReturnRow = ({ indx, row, }) => {
  const { id, name, contacts } = row;

  const businessAddress = JSONParse(contacts)?.address_line_1 ?? 'N/A';
  const mobileNumber = JSONParse(contacts)?.mobile_number ?? 'N/A';

  const renderStatus = (_status) => (
    <ColorField>
      <Status status={_status} />
      {_status}
    </ColorField>
  );

  const getStatus = () => {
    switch (row.status) {
      case 'IN_ACTIVE':
        return renderStatus('De-activated');
      case 'UNVERIFIED':
        return renderStatus('Pending');
      default:
        return renderStatus('Approved');
    }
  };

  const {
    primary_email_address: primaryEmailAddress, city
  } = JSONParse(contacts);

  const cells = [name, primaryEmailAddress, businessAddress, mobileNumber];

  const returnCell = (value) => value || '-';

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell key={cell}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow item container key={id}>
      <BodyCell>{indx + 1}</BodyCell>
      {renderBodyCells()}
      <BodyCell>{getStatus()}</BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  indx: PropTypes.number.isRequired,
  row: PropTypes.shape(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
