import React, { useState, useEffect } from 'react';
import {
  Grid, CircularProgress, Slide, Button
} from '@mui/material';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';
import CustomInputBase from '../../customComponents/customInputBase';
import { THERAPEUTIC_TYPE_SUB_QUERY } from '../../../queries/therapeuticType';
import { CREATE_SUB_THERAPEUTIC_TYPE } from '../../../mutations/therapeuticType';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';
import {
  DialogContentStyled,
  ContentHeader,
  Description,
  HeaderText,
  FormStyled,
  FooterStyled,
  DialogStyled,
  CloseStyled
} from './categoryDialog.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SubCategoryDialog = ({ openDialog, handleClose }) => {
  const initialState = {
    productTypeName: '',
    categoryId: ''
  };

  const initialErrorState = {
    nameError: false,
    categoryError: false,
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [categories, setCategories] = useState([]);
  const { nameError } = errorState;

  const { loading, data } = useQuery(THERAPEUTIC_TYPE_SUB_QUERY);
  const [crateSubCategory, { loading: cloading }] = useMutation(CREATE_SUB_THERAPEUTIC_TYPE);

  useEffect(() => {
    if (!loading && data) setCategories(data?.allSubTherapeuticTypes);
  }, [data]);

  const handleSave = () => {
    const { productTypeName, categoryId } = state;

    if (categoryId === '') return toast.error('Kindly select category');
    const parentId = categories?.find(({ id, therapeuticType }) => {
      if (therapeuticType === categoryId) { return id; }
    });

    const variables = {
      parentId: parentId?.id,
      therapeuticType: productTypeName
    };

    crateSubCategory({
      variables,
      refetchQueries: ['allTherapeuticTypes']
    }).then(() => {
      toast.success('Subcategory successfully created');
      setState(initialState);
      handleClose();
    }).catch((err) => toast.error(err.message));
  };

  const handleSaveButtonClick = () => {
    const { productTypeName } = state;
    if (productTypeName.length <= 1) {
      return setErrorState({
        ...errorState, nameError: true
      });
    }
    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'productTypeName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const handleChangeCategory = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value
    });
  };

  const topFields = [
    {
      name: 'productTypeName', label: 'Product Type Name', placeholder: 'Enter Product Type Name',
      error: nameError, helperText: 'product type name required.'
    },
    {
      name: 'categoryId', label: 'Select Category', options: categories?.map(({ therapeuticType }) => therapeuticType), secured: false,
      onChange: handleChangeCategory, val: categories?.find(({ id }) => id === state.categoryId)?.name, placeholder: 'Select'
    },
  ];

  const returnTextField = (field, isProtected = false) => {
    const {
      name: fieldName, label, placeholder, error, val, helperText, secured, onChange
    } = field;
    const value = state[fieldName];
    if ([
      'categoryId'
    ].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange || handleChange}
          showCheckBox={false}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value}
        disabled={isProtected}
        size="small"
        type={fieldName === 'mobile' ? 'number' : 'text'}
        error={error}
        helperText={error && helperText}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
      />
    );
  };

  const closeHandler = () => {
    handleClose();
  };
  return (
    <DialogStyled
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <ContentHeader>
          <HeaderText>Add Sub Category</HeaderText>
          <CloseStyled onClick={closeHandler} />
        </ContentHeader>
        <Description>
          Add product type information to the fields in the form
        </Description>
        <FormStyled>
          {topFields.map((field) => (
            <Grid style={{ marginTop: '1rem' }} item xs={12} key={field.name}>
              {returnTextField(field)}
            </Grid>
          ))}
        </FormStyled>
        <FooterStyled>
          <Button
            variant="outlined"
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveButtonClick}
          >
            { cloading ? <CircularProgress size={18} /> : 'Save' }
          </Button>
        </FooterStyled>
      </DialogContentStyled>
    </DialogStyled>
  );
};

SubCategoryDialog.propTypes = {
  openDialog: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

SubCategoryDialog.defaultProps = {
  openDialog: false
};

export default SubCategoryDialog;
