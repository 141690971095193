import React, { useEffect, useState } from 'react';
import {
  TableBody, TableContainer, Table, Button
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import { EXPORT_LOW_ON_STOCK_MUTATION } from '../../mutations/reports';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import {
  TableHeader,
  GridContainer,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  SubTitle,
  MenuButton,
  CustomCheckbox
} from './lowOnStock.styles';
import { FilledCustomButton } from '../customComponents/customButton';
import CustomSearchField from '../shared/CustomSearchField';
import TablePagination from '../shared/tablePagination';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import LowOnStockFilter from './lowOnStockFilter';
import SuccessDialog from './successDialog';
import { LOW_OUT_OF_STOCK } from '../../queries/products';
import { UPDATE_ORDER_LIST } from '../../mutations/products';

const headers = [
  'SKU',
  'Product Name',
  'Pack Size',
  'Quantity',
  'Status',
  'Action'
];

const initialState = {
  searchText: '',
  search: '',
  tab: 'ALL',
};

const LowOnStock = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [selected, setSelected] = useState([]);
  const [stateRows, setStateRows] = useState([]);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);

  const {
    searchText, search, tab
  } = state;

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, search: searchText });
    } else if (!searchText) {
      setState({ ...state, search: '' });
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const { loading, data } = useQuery(LOW_OUT_OF_STOCK, {
    variables: {
      search, pageCount, pageNumber, tab
    },
    fetchPolicy: 'network-only'
  });

  const response = data?.lowOnOrOutOfStockProducts || [];
  const totalNumber = data?.lowOnOrOutOfStockProductsTotalNumber || 0;

  useEffect(() => {
    if (response?.length) {
      setStateRows(response);
    } else {
      setStateRows([]);
    }
  }, [data]);

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = stateRows.map((product) => product.productId);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === stateRows.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  const [updateOrderList, { loading: updateLoading }] = useMutation(UPDATE_ORDER_LIST);

  const handleAddToList = () => {
    if (!selected.length) return toast.error('Kindly select at least one product');
    const request = selected.map((item) => ({ productId: item, quantity: null }));
    updateOrderList({
      variables: { orderListProducts: request },
      refetchQueries: ['businessOrderListProductsTotalNumber']
    }).then(() => {
      setSelected([]);
      setOpenDialog(true);
    }).catch((err) => toast.error(err.message));
  };

  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_LOW_ON_STOCK_MUTATION);
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    exportMutation({
      variables: {
        type: 'download_out_or_low_stock_products', name: `low_and_out_of_stock_report${randomNum}`, tab, search
      }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <MainContent>
      <SuccessDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      <GridContainer container>
        <TitleGrid container item>
          <MenuButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            Back
          </MenuButton>
          <TitleTextGridContainer>
            <div>
              <PrimaryTitle variant="h5">Low & out of Stock Products</PrimaryTitle>
              <SubTitle data-testid="lowOnStockSubTitle">List of products on your inventory that are low on quantity and needs to be restocked</SubTitle>
            </div>
            <FilledCustomButton disabled={updateLoading} onClick={handleAddToList}>
              { updateLoading ? 'loading...' : 'Add to Order List' }
            </FilledCustomButton>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchFlex>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search for products..."
              handleChange={(e) => handleSearch(e.target.value)}
              style={{ width: '55%', marginRight: '10px' }}
            />
            <LowOnStockFilter
              state={state}
              handleFilterChange={handleFilterChange}
              setState={setState}
            />
            <Button
              onClick={handleDownloadCSV}
              disabled={exportLoading}
              variant="outlined"
              style={{ width: '15%' }}
            >
              {exportLoading ? 'loading...' : 'Export as CSV'}
            </Button>
          </SearchFlex>
          <TableGrid item container>
            {loading ? (
              <ProductsTableLoader />
            ) : (
              <TableContainer>
                <Table>
                  <MainTableHead>
                    <TableHeader style={{ minWidth: '50px' }}>
                      {renderCheckbox()}
                    </TableHeader>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody>
                    {
                    response?.length
                      ? response?.map((item, index) => {
                        const idx = item?.id;
                        return <ReturnRow key={idx} row={item} sn={index + 1} selected={selected} handleSelect={handleSelect} />;
                      })
                      : (
                        <MainTableRow>
                          <BodyCell colspan={7} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                        </MainTableRow>
                      )
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TableGrid>
          <FooterWrapper item container>
            {totalNumber > 0 && (
              <TablePagination
                total={totalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default LowOnStock;
