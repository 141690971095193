import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { CTextField } from './styles';

const CustomNewTextField = ({
  state, field, handleChange, select, straight, ...props
}) => {
  const returnType = (name) => {
    if (select) return;
    switch (name) {
      case 'dateFrom':
      case 'dateTo':
        return 'date';
      case 'orderTotalFrom':
      case 'orderTotalTo':
      case 'orderNo':
      case 'invoiceNo':
        return 'number';
      default:
        return 'text';
    }
  };

  const isLarge = useMediaQuery('(min-width:992px)');

  const {
    name: fieldName, label, shrink
  } = field;
  const _value = state[fieldName];

  return (
    <CTextField
      className={`erp-uat-${fieldName}`}
      {...props}
      variant="outlined"
      label={label}
      value={_value}
      size={isLarge ? 'small' : 'medium'}
      type={returnType(fieldName)}
      name={fieldName}
      onChange={handleChange}
      select={select}
      straight
      inputProps={{ 'data-testid': fieldName }}
      InputLabelProps={shrink && { shrink: true }}
      SelectProps={select && {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: '20rem',
              borderRadius: '10px'
            }
          }
        }
      }}
    />
  );
};

CustomNewTextField.propTypes = {
  state: PropTypes.instanceOf(Object),
  field: PropTypes.string,
  select: PropTypes.bool,
  straight: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

CustomNewTextField.defaultProps = {
  state: {},
  field: '',
  select: false,
  straight: false
};

export default CustomNewTextField;
