import { TableCell, TableRow } from '@mui/material';
import styled, { css } from 'styled-components';

export const MainTableRow = styled(TableRow)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #f3f9ff;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  color: #606060;
  line-height: 2.8;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const deactivatedStyles = css`
  background-color: #ff0f0f;
`;

const approvedStyles = css`
  background-color: #00b588;
`;

const pendingStyles = css`
  background-color: #f29053;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'De-activated':
      return deactivatedStyles;
    case 'Pending':
      return pendingStyles;
    default:
      return approvedStyles;
  }
};

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  border-radius: 50%;
  width: 9px;
  height: 9px;

  ${getStyles}
`;
