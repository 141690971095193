import { styled } from '@mui/material/styles';
import { Grid, Typography, Button } from '@mui/material';

export const ForgotContainer = styled(Grid)`
  margin-bottom: 3vw;
  text-align: right;

  @media (min-width: 992px) {
    margin-bottom: 2.5vw;
  }
`;

export const FooterGrid = styled(Grid)`
  margin: 3vw 0;
  justify-content: center;

  @media (min-width: 992px) {
    margin: 2vw 0;
  }
`;

export const ForgotButton = styled(Button)`
  @media (max-width: 991px) {
    font-weight: 600;
    font-size: 1.7rem;
  }
`;

export const HaveAccountTypo = styled(Typography)`
  font-weight: 400;
  font-size: 1.5rem;
  color: #606060;

  @media (min-width: 992px) {
    font-size: 15px;
  }
`;

export const InputContainer = styled('div')`
  margin-bottom: 1vw;
`;
