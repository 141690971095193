import navbarReducer from './reducers/navbar';
import cartReducer from './reducers/cart';
import catalogueReducer from './reducers/catalogue';
import supplierReducer from './reducers/supplier';
import affiliateReducer from './reducers/affiliate';
import productReducer from './reducers/product';
import returnsReducer from './reducers/returns';
import saleReducer from './reducers/sales';
import modelReducer from './reducers/model';
import reconciliationReducer from './reducers/reconciliation';
import offlineReducer from './reducers/offline';
import businessReducer from './reducers/business';
import userReducer from './reducers/user';
import orderReducer from './reducers/order';
import ordersReportReducer from './reducers/ordersReport';
import helpDeskReducer from './reducers/helpDesk';
import stockTransferReducer from './reducers/stockTransfer';
import backOrderReducer from './reducers/backOrder';
import receiveManualOrderReducer from './reducers/receiveManualOrders';
import promotionsReducer from './reducers/promotions';
import imageUploadReducer from './reducers/imageUpload';
import notificationsReducer from './reducers/notifications';

const mainReducer = (state, action) => ({
  navbar: navbarReducer(state.navbar, action),
  cart: cartReducer(state.cart, action),
  catalogue: catalogueReducer(state.catalogue, action),
  supplier: supplierReducer(state.supplier, action),
  affiliate: affiliateReducer(state.affiliate, action),
  product: productReducer(state.product, action),
  sale: saleReducer(state.sale, action),
  model: modelReducer(state.model, action),
  returns: returnsReducer(state.returns, action),
  reconciliation: reconciliationReducer(state.reconciliation, action),
  offline: offlineReducer(state.offline, action),
  business: businessReducer(state.business, action),
  user: userReducer(state.user, action),
  order: orderReducer(state.order, action),
  ordersReport: ordersReportReducer(state.ordersReport, action),
  helpDesk: helpDeskReducer(state.helpDesk, action),
  stockTransfer: stockTransferReducer(state.stockTransfer, action),
  backOrder: backOrderReducer(state.backOrder, action),
  receiveManualOrder: receiveManualOrderReducer(state.receiveManualOrder, action),
  promotions: promotionsReducer(state.promotions, action),
  imageUpload: imageUploadReducer(state.imageUpload, action),
  notifications: notificationsReducer(state.notifications, action)
});

export default mainReducer;
