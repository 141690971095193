import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';
import CustomNewTextField from '../../shared/CustomNewTextField';
import {
  FiltersGrid,
  SetFilter,
  FilterContainer,
  MenuItems,
  FilterText
} from './managePOFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';

const ManagePoFilter = ({
  state,
  handleFilterChange,
  handleSetFilter,
  setState
}) => {
  const orderStatus = [
    { name: 'All Orders', value: 'all' },
    { name: 'Open', value: 'open' },
    { name: 'Closed', value: 'closed' },
    { name: 'Draft', value: 'draft' },
  ];

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD')
    }));
  };

  return (
    <FilterContainer item container xs={12}>
      <FiltersGrid container item spacing={2}>
        <Grid item xs={3}>
          <CustomNewTextField
            field={{ name: 'poNumber', label: 'PO Number' }}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '100%' }}
            type="number"
            data-testid="searchByNum"
          />
        </Grid>
        <Grid item xs={3}>
          <CustomNewTextField
            field={{ name: 'parentId', label: 'Parent ID' }}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '100%' }}
            type="number"
            data-testid="searchById"
          />
        </Grid>
        <Grid item xs={3}>
          <CustomNewTextField
            field={{ name: 'status', label: 'Order Status' }}
            state={state}
            select
            handleChange={handleFilterChange}
            style={{ width: '100%' }}
            maxHeight="20rem"
            marginLeft="2.5rem"
            data-testid="searchByStatus"
          >
            {orderStatus.map(({ name, value }) => (
              <MenuItems key={value} value={value}>
                {name}
              </MenuItems>
            ))}
          </CustomNewTextField>
        </Grid>
        <Grid item xs={2}>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            xs={12}
            styles={{
              height: '47px',
              width: '120%',
              border: '1px solid #bababa',
              fontSize: '12px'
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <SetFilter type="tertiary" onClick={handleSetFilter}>
            <FilterText>Set Filter</FilterText>
          </SetFilter>
        </Grid>
      </FiltersGrid>
    </FilterContainer>
  );
};

ManagePoFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  setState: PropTypes.isRequired
};

ManagePoFilter.defaultProps = {
  state: {}
};

export default ManagePoFilter;
