import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainWrapper = styled(Grid)`
  position: relative;
`;

export const CropContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 28vh;
  background: #fff;
`;
