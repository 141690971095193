import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Grid, Button, CircularProgress, useMediaQuery
} from '@mui/material';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION, SWITCH_ROLE_MUTATION } from '../../mutations/auth';
import PasswordTextField from './passwordTextField';
import CustomInputBase from '../customComponents/customInputBase';
import {
  ForgotContainer, HaveAccountTypo, ForgotButton, FooterGrid
} from './login.styles';
import { validateEmail } from './utils';
import { OgaToken } from '../../graphql/token';
import { requestForToken } from '../../firebase/index';
import { goToModule } from '../shared/utils';
import UserActionTypes from '../../providers/reducers/user/userTypes';

import { useStateValue } from '../../providers/stateProvider';

const Login = () => {
  const [, dispatch] = Object.values(useStateValue());

  const initialState = {
    email: '',
    password: '',
    firebaseToken: '',
    validated: false,
  };
  const initialErrorState = {
    emailError: false,
    mobileError: false,
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const isSmall = useMediaQuery('(max-width: 991px)');

  const {
    email, password, firebaseToken, validated
  } = state;
  const { emailError, passwordError } = errorState;
  const [switchRole] = useMutation(SWITCH_ROLE_MUTATION);
  const [loginUser, { loading }] = useMutation(LOGIN_MUTATION);

  const navigate = useNavigate();

  const validateState = (name, value) => {
    if (name === 'email') {
      return setErrorState({
        ...errorState,
        emailError: validateEmail(value)
      });
    }
    if (name === 'password') {
      return setErrorState({
        ...errorState,
        passwordError: value === ''
      });
    }
    return null;
  };

  useEffect(() => {
    if (!firebaseToken) {
      requestForToken()
        .then((token) => {
          setState({ ...state, firebaseToken: token });
        })
        .catch(() => {
          toast.error('An unexpected error occurred. Please try again later.');
        });
    }
  }, [firebaseToken]);

  React.useEffect(() => {
    setState({
      ...state,
      validated: !emailError && !passwordError && !!email && !!password
    });
  }, [email, password]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email' || name === 'password') {
      validateState(name, value);
      setState({ ...state, [name]: value });
    }
  };

  const redirectUser = (user, feedback, userPermissions) => {
    const { name } = user?.businessuserSet[0]?.role ?? '';

    if (user?.businessuserSet[0]?.business?.isAdmin) {
      localStorage.setItem('oga_user_role', 'admin');
      return navigate('/admin/preloaded-inventory');
    }
    localStorage.setItem('oga_user_role', name);
    if (feedback?.feedbackRequired === 'true') {
      localStorage.setItem('feedback', JSON.stringify(feedback));
      return navigate('/feedback');
    }
    return goToModule(navigate, switchRole, userPermissions, 'erp');
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    loginUser({
      variables: {
        email,
        password,
        firebaseToken
      }
    }).then(({ data }) => {
      const {
        token, message, restToken, user, requireFeedback
      } = data?.loginUser || {};
      const { businessuserSet: [businessUser] } = user;
      const userPermissions = businessUser?.userPermissions || [];
      const role = businessUser.role.name;
      const { status } = businessUser.business;
      dispatch({
        type: UserActionTypes.UPDATE_USER,
        payload: {
          role,
          allPermissionsMap: userPermissions,
        }
      });
      localStorage.setItem('user_role', businessUser?.systemRole?.name || role);
      localStorage.setItem('affiliateStatus', status);
      OgaToken.authToken = token;
      OgaToken.restToken = restToken;
      localStorage.setItem('oga_auth_token', token);
      localStorage.setItem('oga_rest_token', restToken);

      toast.success(message);
      redirectUser(user, requireFeedback, userPermissions);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  return (
    <form onSubmit={handleSignIn} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInputBase
            label="Email Address"
            name="email"
            value={email}
            type="email"
            error={email ? emailError : false}
            helperText={emailError ? 'Invalid email' : ' '}
            required
            disabled={loading}
            onChange={handleChange}
            cSize="lg"
            data-testid="auth-email"
            className="signin-email"
            id="signin-email"
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: isSmall && '1.5rem' }}>
          <PasswordTextField
            password={password}
            handleChange={handleChange}
            passwordError={passwordError}
            loading={loading}
          />
        </Grid>
      </Grid>
      <ForgotContainer item xs={12}>
        <ForgotButton color="inherit" onClick={() => navigate('/forgot-password')}>
          Forgot Password?
        </ForgotButton>
      </ForgotContainer>
      <Button
        data-testid="auth-button"
        disabled={!validated}
        type="submit"
        variant="contained"
        style={{
          width: '100%', height: isSmall ? '5.7rem' : '', fontSize: isSmall ? '2rem' : '', borderRadius: isSmall ? '1rem' : ''
        }}
        className="signin-button"
        id="signin-button"
      >
        {loading ? <CircularProgress size={18} /> : 'Sign In'}
      </Button>
      <FooterGrid item container xs={12}>
        <HaveAccountTypo variant="subtitle2" display="inline" style={{ color: 'black', fontWeight: isSmall ? '600' : '', fontSize: isSmall && '2rem' }}>
          Don&apos;t have an account?
          &nbsp;
          &nbsp;
          <Link to="/register?step=1" style={{ color: '#235A91', fontWeight: 800 }}>
            Sign Up
          </Link>
        </HaveAccountTypo>
      </FooterGrid>
    </form>
  );
};

export default Login;
