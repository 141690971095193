import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Button, DialogContent
} from '@mui/material';
import {
  CDialog, DialogTitleText, DialogSubTitleText,
  GridWrapper, CloseIcon, RoleNameTypo, CustomCheckbox
} from './editRoleDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const EditRoleDialog = ({
  open, userRoles, selectedRole, handleSelectRole, handleChangeRole,
  handleClose
}) => (
  <CDialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    maxWidth="sm"
    fullWidth
  >
    <GridWrapper container>
      <Grid container style={{ padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
        <Grid container item direction="column" xs={6}>
          <DialogTitleText variant="caption">
            Edit Role
          </DialogTitleText>
          <DialogSubTitleText variant="caption">
            You can manage users access here
          </DialogSubTitleText>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <CloseIcon onClick={handleClose} />
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container item spacing={3}>
          {userRoles?.map(({ name, id: roleID }) => (
            <Grid
              container
              item
              xs={4}
              alignContent="center"
              key={roleID}
            >
              <CustomCheckbox
                size="small"
                checked={selectedRole === name}
                onChange={(e) => {
                  e.stopPropagation();
                  handleSelectRole(e, name, roleID);
                }}
                inputProps={{ 'aria-label': 'select product' }}
                sx={{ color: '#78AADA', padding: 0 }}
              />
              <RoleNameTypo>{name}</RoleNameTypo>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          style={{ width: '11rem', height: '3.2rem', marginRight: '1.2rem' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{ width: '11rem', height: '3.2rem' }}
          onClick={handleChangeRole}
        >
          Save
        </Button>
      </DialogActions>
    </GridWrapper>
  </CDialog>
);

EditRoleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userRoles: PropTypes.shape(Array).isRequired,
};

export default EditRoleDialog;
