import { styled } from '@mui/material/styles';
import {
  MenuItem, Typography, Button, Box
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';

export const ContainerBody = styled('div')`
  margin-top: 10px;
  padding: 20px 40px;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const TypographyStyled = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4e4b66;
`;

export const TypographyCountStyled = styled(Typography)`
  font-weight: 800;
  font-size: 22px;
  margin-top: 1rem;
  color: #4e4b66;
`;

export const Title = styled('span')`
  font-size: 22px;
  font-weight: 800;
  color: #4e4b66;
`;

export const ButtonStyled = styled(Button)`
  background: #303030 !important;
  height: 3.2rem;
  border-radius: 10px;
  color: #fff;
  &:disabled {
    color: #fff !important;
  }
`;

export const HeaderStyled = styled('div')`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 12rem;
  border: 0px important;
`;

export const BoxStyled = styled(Box)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;
