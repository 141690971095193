import React from 'react';
import Proptypes from 'prop-types';
import { DropzoneArea } from 'react-mui-dropzone';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// import { AttachFile } from '@material-ui/icons';
// import { Style, VerifiedUser } from '@mui/icons-material';
import { UploadCloud } from '../../assets/svgs';
import {
  theme, TitleText, TitleDesc, DropZoneContainer,
  BrowseFileButton
} from './supplierCvsDropzone.styles';

const DropzoneContent = () => (
  <DropZoneContainer>
    <UploadCloud style={{ fontSize: '2.5rem', fill: 'none' }} />
    <TitleText>
      Select file or drag and drop here
    </TitleText>
    <TitleDesc>
      .CSV file not more than 2MB
    </TitleDesc>
    <BrowseFileButton>
      SELECT FILE
    </BrowseFileButton>
  </DropZoneContainer>
);

export default function SupplierCsvDropzone({
  handleFile
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DropzoneArea
          onChange={handleFile}
          acceptedFiles={['.csv']}
          dropzoneText={<DropzoneContent />}
          fileslimit={1}
          getPreviewIcon={() => null}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

SupplierCsvDropzone.propTypes = {
  handleFile: Proptypes.func.isRequired,
};
