import React from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@mui/material';
import {
  BodyCell, TableContainerWrapper, TableComponent, MainTableHead,
  TableHeader, PaperWrapper
} from './categoryCard.styles';
import ReturnRow from './returnRow';

const CategoryList = ({ categories = [] }) => {
  const headers = [
    'S/N', 'Category Name', 'No. of Products', 'Markup (%)', 'Loyalty Weight (%)', 'VAT Status', 'Actions',
  ];

  const returnHeaders = () => headers.map((header) => <TableHeader key={header}>{header}</TableHeader>);
  return (
    <PaperWrapper elevation={0}>
      <TableContainerWrapper>
        <TableComponent aria-label="supplier products table">
          <MainTableHead>
            {returnHeaders()}
          </MainTableHead>
          { categories?.length > 0
            ? (
              <TableBody>
                {categories?.map((category, index) => (
                  <ReturnRow key={category.id} row={category} index={index} />
                ))}
              </TableBody>
            )
            : (
              <TableBody>
                <BodyCell colSpan={7} style={{ textAlign: 'center' }}>No categories added yet.</BodyCell>
              </TableBody>
            )}
        </TableComponent>
      </TableContainerWrapper>
    </PaperWrapper>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.instanceOf(Object),
};

CategoryList.defaultProps = {
  categories: []
};

export default CategoryList;
