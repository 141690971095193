import React from 'react';
import PropTypes from 'prop-types';
import {
  TabsGrid, UnderlineGrid, Typo
} from './filter.styles';

const Filter = ({
  tabVal, handleTabChange
}) => {
  const inventoryTab = [
    {
      label: 'Out Of Stock', value: 'out-of-stock', width: '11%'
    },
    {
      label: 'Missed Sales', value: 'missed-sales', width: '11%'
    },
  ];

  return (
    <TabsGrid container item>
      {inventoryTab.map(({
        label, value, width
      }) => (
        <UnderlineGrid
          key={value}
          item
          container
          className={`erp-uat-${value}`}
          onClick={() => handleTabChange(value)}
          active={value === tabVal}
          width={width}
        >
          <Typo active={value === tabVal} style={{ whiteSpace: 'nowrap' }}>
            {label}
          </Typo>
        </UnderlineGrid>
      ))}
      <UnderlineGrid width="78%" style={{ padding: '12px 0', cursor: 'auto' }}>
        <Typo />
      </UnderlineGrid>
    </TabsGrid>
  );
};

Filter.propTypes = {
  tabVal: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default Filter;
