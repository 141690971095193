import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  BodyCell, MainTableRow
} from './returnRow.styles';
import ActionPopper from './actionPopper';
import NotificationDialog from '../../../shared/notificationDialog';
import { useStateValue } from '../../../../providers/stateProvider';
import { DISABLE_BUSINESS_USER, UPDATE_USER_ROLE } from '../../../../mutations/user';
import EditRoleDialog from './editRoleDialog/editRoleDialog';

const ReturnRow = ({
  id, index, fullName, user, systemRole, userRoles, refetch
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [roleNames, setRoleName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectRoleId, setSelectRoleId] = useState('');
  const [disable, setDisable] = useState(false);
  const { username } = user;

  const [assignUserRole, { loading: roleLoading }] = useMutation(UPDATE_USER_ROLE);
  const [disableBusinessUser, { loading: disableLoading }] = useMutation(DISABLE_BUSINESS_USER);

  const [{ business: { roleId } }] = Object.values(useStateValue());

  useEffect(() => {
    if (systemRole) {
      setRoleName(systemRole?.name);
    }
  }, [systemRole]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptions = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const toggleDialog = () => {
    setOpen(!open);
  };

  const toggleSuccessModal = () => {
    setOpenModal(false);
    setSuccessModal(!successModal);
  };
  const handleDisableUser = () => {
    const variables = {
      businessUserId: id
    };
    disableBusinessUser({ variables })
      .then(({ data }) => {
        const { message } = data?.disableBusinessUser || {};
        toggleSuccessModal();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const handleAction = (event, type) => {
    if (type === 'disable user') {
      setDisable(true);
      setOpenModal(!openModal);
    } else {
      setOpen(true);
    }
    handleOptions(event);
  };

  const handleSelectRole = (event, name, roleID) => {
    if (name === selectedRole) {
      setSelectedRole('');
      setSelectRoleId('');
    } else {
      setSelectedRole(name);
      setSelectRoleId(roleID);
    }
  };

  const toggleConfirmation = () => {
    setOpenModal(!openModal);
  };

  const handleChangeRole = () => {
    toggleDialog();
    toggleConfirmation();
  };
  const handleSave = () => {
    const variables = {
      roleId: selectRoleId,
      businessUserId: id
    };
    assignUserRole({ variables })
      .then(({ data }) => {
        const { message } = data?.createSystemRole || {};
        toggleSuccessModal();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  const handleConfirm = () => {
    if (disable) return handleDisableUser();
    return handleSave();
  };

  return (
    <>
      <MainTableRow item container key={index}>
        <BodyCell>
          {index + 1}
        </BodyCell>
        <BodyCell>
          {fullName}
        </BodyCell>
        <BodyCell>
          {username}
        </BodyCell>
        <BodyCell>
          {roleNames || '-'}
        </BodyCell>
        <BodyCell style={{ cursor: 'pointer' }}>
          <ActionPopper
            handleAction={handleAction}
          />
        </BodyCell>
      </MainTableRow>

      <EditRoleDialog
        open={open}
        userRoles={userRoles}
        selectedRole={selectedRole}
        handleSelectRole={handleSelectRole}
        handleChangeRole={handleChangeRole}
        handleClose={handleClose}
      />

      <NotificationDialog
        openDialog={openModal}
        setOpenDialog={toggleConfirmation}
        action="Cancel"
        action2={disable ? 'Yes, Disable' : 'Yes, Change Role'}
        action2Func={handleConfirm}
        title={disable ? 'Disable User' : 'Change Role?'}
        desc={disable ? 'Hi Pharm, are you sure you want to disable this user?' : 'Are you sure you want to change this role?'}
        type="warning"
        loading={roleLoading || disableLoading}
      />

      <NotificationDialog
        openDialog={successModal}
        setOpenDialog={toggleSuccessModal}
        action="Ok"
        actionFunc={() => refetch()}
        title={disable ? 'User Disabled successfully!' : 'Role Changed Successfully!'}
        desc={`Hi Pharm, user has been successfully ${disable ? 'disabled' : 'changed'}!`}
      />
    </>
  );
};

ReturnRow.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired
};

ReturnRow.defaultProps = {
};

export default ReturnRow;
