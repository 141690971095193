import React from 'react';
import {
  Grid, Typography, IconButton, MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';

const primaryStyles = css`
  background-color: #FCED50;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }
`;

const secondaryStyles = css`
  background-color: none;
  color: #424242;
  border: 1px solid black;
  margin-right: 2rem;
`;

const getButtonStyles = ({ type }) => {
  switch (type) {
    case 'secondary':
      return secondaryStyles;
    default:
      return primaryStyles;
  }
};

export const OrderButton = styled(({ color, ...otherProps }) => (
  <IconButton
    {...otherProps}
    classes={{ disabled: 'disabled', label: 'label' }}
    size="large" />
))`
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 55px;
    border-radius: 20px;

    &.disabled {
      color: #848483;
      border: 1px solid #CCCCCB;
    }

    @media(min-width: 992px) {
      height: 50px;
      padding: 0px 25px;
      border-radius: 10px;
    }

    ${getButtonStyles}
  `;

export const SupplierOrderImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: .9rem;
  }
`;

export const OrderButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
`;
export const MenuItemsCustomize = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #424242;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 40px;
  border: 2px solid #424242;
  `;

export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  background-color: #FCED50;
  color: #424242;
  margin-bottom: 40px;

  &:hover {
    background-color: #D2C647;
  }
`;

export const TopGrid = styled(Grid)`
    & > * {
      width: calc(50% - 0.75rem);
      margin: 0 1.5rem 1.5rem 0;

      :nth-child(2n) {
        margin-right: 0;
      }
    }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: .5rem 3rem;
    width: 100%;
  }
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 600;
  color: #424242;
  font-size: 30px;
  margin-bottom: 10px;
`;

export const FilterIcon = styled('img')`
  width: 100%;
  margin-top: 4%;
  cursor: pointer;
`;
