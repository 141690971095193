import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import {
  Grid, Dialog, DialogActions, DialogContent, DialogTitle,
  Slide, Checkbox, TableRow, TableBody, TableCell, Button
} from '@mui/material';
import toast from 'react-hot-toast';
import { ShoppingCart, HighlightOffRounded } from '@mui/icons-material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import _ from 'lodash';
import currencyFormatter from '../shared/currencyFormatter';
import OrderPrintDialog from './OrderPrintDialog';
import MainContent from '../customComponents/mainContent';
import {
  CartGridContainer,
  DialogActionButtonText,
  ProductSubTotalHeaders,
  ProductColumnHeaderTitle,
  BackArrowIconContainer,
  PageTitleText,
  CartItemCountContainer,
  CartItemCountText,
  DeliveryAddressCard,
  DeliveryAddressCard2,
  DeliveryAddressCardSection,
  DeliveryAddressCardHeader,
  DeliveryAddressCardHeaderTitle,
  DeliveryAddressCardHeaderSubTitle,
  SupplierCardPaper,
  CartGridContainerHeader,
  KeyboardArrowLeft,
  ChangeOutletRow,
  SelectOutletText,
  OutletInfoContainer,
  OutletContactLine,
  ManualMethodImage,
  ManualDialogDoneButton, EmptyCartImg, Default,
  ManualDialogCancelButton, ManualDialogPadder, ManualPlaceOrder, OptionText, ManualSupplierItem, Span,
  CircularProgressLoader, LoaderGrid, ArrowBackText, ClearBox, DeliveryIcon,
  TitleText, ContentText, TableHeaderRow, CartItemWrapper, CartContainer, Header, TableHeader, OutletContactHeader, ViewMore, GridContainer, GridWrapper, ItemWrapper, ItemTypo, EditContainer, TableWrapper, ProductSubTotalWrapper, EmptyCardPaper, TextWrapper, TextHeader, CartItemContainer, PaymentCardSection, TextBody, IconWrapper
} from './cart.styles';
import ProductItem from './product-item/index';
import CreateOutletModal from '../shared/addSingleOutlet/individualOutletDialog';
import {
  UPDATE_ORDER_PRODUCTS_MUTATION, PLACE_ORDER_PRODUCTS_MUTATION, DELETE_CART_ORDER,
  PLACE_AFFILIATE_ORDER_PRODUCTS_MUTATION, SWITCH_ORDER_PRODUCT_SUPPLIER
} from '../../mutations/cart';
import { ORDER_QUERY, SAVED_CART } from '../../queries/cart';
import PaymentSummary from './paymentSummaryCard/PaymentSummary';
import ReturnSelectField from '../suppliers/individual/returnSelectField';
import { parseJson } from '../affiliates/utils';
import Print from '../../assets/images/Print.png';
import Pdf from '../../assets/images/select.png';
import Email from '../../assets/images/Email.png';
import EmptyCart from '../../assets/images/empty_cart.gif';
import SupplierOrderForm from '../../assets/images/manual_supplier_order.png';
import { SupplierOrderPrint } from './supplierOrderPrint';
import {
  TableContainerWrapper, TableComponent, HeaderCell,
  MainTableHead, AddProductCardSection, AddProductCard, CartItemButton, CartItemButtonContainer
} from './newProduct.styles';
import { Delete } from './product-item/styles';
import Floater from './floater';
import { Product } from '../../providers/reducers/product/classes/Product';
import { DELETE_DELIVERY_LOCATION } from '../../mutations/outlets';
import ReportExportModel from '../../utils/reportExportModel';
import { OgaToken } from '../../graphql/token';
import { RenderPopper } from '../../utils/utils';
import ConfirmDialog from './confirmDialog';
import SimilarProducts from './similarProducts/similarProduct';
import ClearCartDialog from './clearCartDialog';
import { Dots, CartDot } from '../../assets/svgs';
import ActionPopper from './actionPopper';
import SaveDialog from './saveCart';
import CartSaved from './CartSaved';
import SavedCartDialog from './SavedCart';
import NoSavedCart from './NoSavedCart';
import { useStateValue } from '../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const dosages = [
  { name: 'Tablet' }, { name: 'Capsule' }, { name: 'Caplet' }, { name: 'Syrup' }, { name: 'Suspension' }, { name: 'Solution' },
  { name: 'Gel' }, { name: 'Injection' }, { name: 'Infusion' }, { name: 'Suppository' }, { name: 'Pessary' }, { name: 'Cream' },
  { name: 'Ointment' }, { name: 'Lotion' }, { name: 'Paste' }, { name: 'Powder' }, { name: 'Other' }
];

const generateExtraProduct = () => ({
  brandName: '', dosageForm: 'Select', strength: '', packSize: '', quantity: 0
});
const initialState = {
  orderId: null,
  cart: [],
  dataCartLoading: true,
  existingOutlet: null,
  wantExtraProducts: false,
  extraProducts: [generateExtraProduct(), generateExtraProduct(), generateExtraProduct(), generateExtraProduct()],
  acceptedExtraProducts: [],
  toBePrintedManualSupplierOrderForms: new Map(),
  extraPlaceOrderActions: new Map(),
  printSupplierOrder: false,
  serviceFee: 0,
  totalPromo: 0,
  pricePolicyFactor: 1,
  deliveryFee: 0,
  updateLoading: false,
  placeLoading: false,
  showDeleteDialog: false,
  showPlaceOrderDialog: false,
  toBeDeletedItem: null,
  deliveryAddress: '',
  changeLocation: false,
  deliveryLocations: [],
  selectedDeliveryLocation: '',
  addNewOutlet: false,
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: 'Nigeria',
  placeManualOrder: false,
  openMCF: false,
  openPrintDialog: false,
  openExportModel: false,
  businessUserId: 0,
  viewMoreButton: null,
  deleteAddressDialog: false,
  clearCartDialog: false,
  actionPopper: false,
  saveDialog: false,
  cartSaved: false,
  savedCartDialog: false,
  cartOrder: [],
  noSavedCart: false
};

const CartIndex = ({ manualCart, backRoute }) => {
  const [state, setState] = useState(initialState);

  const [{
    business: { subBusinessesIds },
    user: {
      businessId, businessCanUseMastersList, isBusinessVirtualStore, priceFactor
    }
  }] = Object.values(useStateValue());
  const canUseMastersList = businessCanUseMastersList || isBusinessVirtualStore;

  const { alertMessage, alertSeverity } = useLocation();
  const navigate = useNavigate();

  if (alertMessage && alertSeverity === 'success') {
    toast.success(alertMessage);
  } else if (alertMessage && alertSeverity === 'error') {
    toast.error(alertMessage);
  }

  const {
    cart, serviceFee, deliveryFee, placeLoading, updateLoading, showDeleteDialog, showPlaceOrderDialog,
    selectedDeliveryLocation, addNewOutlet, placeManualOrder, toBePrintedManualSupplierOrderForms, orderId,
    printSupplierOrder, extraPlaceOrderActions, extraProducts, wantExtraProducts, showLoan, openPrintDialog,
    existingOutlet, openExportModel, business, viewMoreButton, deleteAddressDialog, clearCartDialog, actionPopper,
    saveDialog, cartSaved, savedCartDialog, cartOrder, noSavedCart
  } = state;

  const [switchOrderProductSupplier] = useMutation(SWITCH_ORDER_PRODUCT_SUPPLIER);
  const [deleteCart] = useMutation(DELETE_CART_ORDER);
  const [deliveryLocation] = useMutation(DELETE_DELIVERY_LOCATION);
  const [updateOrderProducts] = useMutation(UPDATE_ORDER_PRODUCTS_MUTATION);
  const [placeAffiliateOrderProducts] = useMutation(PLACE_AFFILIATE_ORDER_PRODUCTS_MUTATION);
  const [placeOrderProducts] = useMutation(PLACE_ORDER_PRODUCTS_MUTATION);

  const retrieveProducts = (orderSet) => {
    let products = [];
    orderSet.forEach((result) => {
      products.push(...result.orderproductSet);
    });
    products = products.map((p) => {
      const {
        id: orderProductId,
        name,
        quantity,
        hybrid,
        alternativeSupplier: { id: alternativeSupplierId, name: alternativeSupplierName },
        supplierOrder: { supplier: { ame: supplierName, settings } },
        product: { id, meta, business: { id: productBusinessId } }
      } = p;

      const supplierSettings = JSON.parse(settings.replace(/'/g, '"').replace('None', '"N/A"'));
      return {
        id,
        orderProductId,
        name,
        quantity,
        hybrid,
        alternativeSupplierId,
        alternativeSupplierName,
        manualCart,
        ...new Product(p.product, manualCart && supplierName !== 'OGApharmacy' ? 'erp' : 'marketplace'),
        supplier: supplierName,
        supplierPaymentTerm: supplierSettings.payment_term,
        meta: JSON.parse(meta),
        productBusinessId,
      };
    });

    setState((s) => ({ ...s, cart: products }));
  };

  const adminOrderingForBusiness = localStorage.getItem('oga_ordering_for_affiliate');
  // const { businessuserSet: [businessUser] } = session ?? {};
  // const { business: { id: bizId } } = businessUser;
  let bizId = 0;
  if (adminOrderingForBusiness) {
    bizId = JSON.parse(adminOrderingForBusiness).id;
  } else {
    bizId = businessId;
  }
  const variables = {
    bizId
  };
  if (manualCart) {
    variables.isManualOrder = true;
  }

  const { data: orderData, loading } = useQuery(ORDER_QUERY, {
    variables,
    fetchPolicy: 'no-cache',
  });

  const { data: savedCartData, loading: savedCartLoading } = useQuery(SAVED_CART, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (orderData && orderData.order) {
      const {
        order: { id, meta, supplierorderSet },
        isBusinessLoanEligible,
        business: _business
      } = orderData;
      const orderMeta = JSON.parse(meta.replace(/'/g, '"'));
      const { deliveryLocations: deliveryLocs } = _business;
      const {
        addressLine1,
        addressLine2,
        city
      } = orderMeta;

      const deliveryAddressArray = [
        addressLine1,
        addressLine2,
        city
      ];
      setState((s) => ({
        ...s,
        addressLine1,
        addressLine2,
        city,
        selectedDeliveryLocation: deliveryLocs ? deliveryLocs[0]?.name : '',
        deliveryLocations: deliveryLocs,
        business: _business,
        deliveryAddress: deliveryAddressArray.filter((d) => !!d).join(', '),
        orderId: id,
        showLoan: isBusinessLoanEligible,
        businessUserId: bizId,
      }));
      retrieveProducts(supplierorderSet);
    }

    if (savedCartData && savedCartData.saveCartOrder) {
      const { saveCartOrder } = savedCartData;
      setState((s) => ({ ...s, cartOrder: saveCartOrder, dataCartLoading: false }));
    }
  }, [orderData, savedCartData]);

  const handleClosePrintDialog = () => {
    setState((s) => ({ ...s, openPrintDialog: false }));
    const businessRaw = localStorage.getItem('oga_ordering_for_affiliate');
    const _business = businessRaw ? JSON.parse(businessRaw) : null;

    const redirect = _business ? '/suppliers-orders' : '/orders';
    const url = backRoute === '/manual-order' ? '/inventory/orders' : redirect;
    setTimeout(() => {
      navigate(`${url}`);
    }, 1500);
  };

  const getOutletOption = () => {
    const optionsBox = { name: 'deliverTo', label: 'Select Pharmacy', options: [] };
    const { deliveryLocations } = state;
    deliveryLocations.forEach((_deliveryLocation) => {
      optionsBox.options.push(_deliveryLocation.name);
    });
    return optionsBox;
  };

  const selectDosage = () => {
    const optionsBox = { name: 'dosageForm', options: [] };
    dosages.forEach((dosage) => {
      optionsBox.options.push(dosage.name);
    });
    return optionsBox;
  };

  const handleSwitchOrderProductSupplier = (_variables) => {
    switchOrderProductSupplier({
      variables: _variables,
      optimisticResponse: { __typename: 'Mutation' }
    }).then(({ data }) => {
      const {
        switchOrderProductSupplier: {
          order: { id, meta: ordermeta, supplierorderSet }
        }
      } = data;

      const orderMeta = JSON.parse(ordermeta.replace(/'/g, '"'));

      setState((s) => ({
        ...s,
        updateLoading: false,
        deliveryAddress: orderMeta.delivery_address,
        orderId: id
      }));

      retrieveProducts(supplierorderSet);
      toast.success('Items updated successfully.');
      window.location.reload();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleChangeDeliveryLocation = (event) => {
    const { value } = event.target;
    setState((s) => ({ ...s, selectedDeliveryLocation: value }));
  };

  const getSelectedDeliveryLocationInfo = () => {
    const { deliveryLocations } = state;
    return deliveryLocations.find((_deliveryLocation) => _deliveryLocation.name === selectedDeliveryLocation);
  };

  const handleOpenViewModal = (event) => {
    setState((s) => ({ ...s, viewMoreButton: viewMoreButton ? null : event.currentTarget }));
  };

  const handleEditDeliveryLocation = (selectedDeliveryLocationInfo) => {
    handleOpenViewModal();
    setState((s) => ({
      ...s, existingOutlet: selectedDeliveryLocationInfo, addNewOutlet: true
    }));
  };

  const handleDeliveryAddress = (selectedDeliveryLocationInfo) => {
    if (selectedDeliveryLocationInfo) {
      setState((s) => ({
        ...s, existingOutlet: selectedDeliveryLocationInfo, addNewOutlet: true
      }));
    } else {
      setState((s) => ({ ...s, addNewOutlet: true }));
    }
  };

  const handleCartList = () => {
    window.open(`${OgaToken.SERVER_LINK}download_order_save_cart/${orderId}.pdf`, '_blank');
  };

  const handleClearCart = () => {
    deleteCart({
      variables: { orderId },
      optimisticResponse: { __typename: 'Mutation' }
    }).then(() => {
      toast.success('Cart items cleared successfully.');
      window.location.reload();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleDeleteDeliveryLocation = (selectedDeliveryLocationInfo) => {
    deliveryLocation({
      variables: {
        deliveryLocationId: +selectedDeliveryLocationInfo.id
      },
      optimisticResponse: {
        __typename: 'Mutation'
      }
    }).then(() => {
      toast.success('Delivery location deleted successfully.');
      window.location.reload();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleDeleteAddressDialog = (event) => {
    setState((s) => ({
      ...s, deleteAddressDialog: deleteAddressDialog !== false ? false : Boolean(event.currentTarget), viewMoreButton: null
    }));
  };

  const handleClearCartDialog = (event) => {
    setState((s) => ({
      ...s, clearCartDialog: clearCartDialog !== false ? false : Boolean(event.currentTarget)
    }));
  };

  const handleCartDialog = (event) => {
    setState((s) => ({
      ...s, actionPopper: actionPopper !== false ? false : Boolean(event.currentTarget)
    }));
  };

  const renderSelectDeliveryLocationInfo = () => {
    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    if (selectedDeliveryLocationInfo) {
      let { contacts } = selectedDeliveryLocationInfo;
      try {
        contacts = parseJson(contacts.replace(/'/g, '"'));
      } catch (e) {
        return null;
      }
      return (
        <OutletInfoContainer container>
          <Grid item xs={9}>
            <OutletContactHeader>Delivery Address</OutletContactHeader>
            <br />
            <Grid container>
              <OutletContactLine>
                {contacts.address_line_1}
                ,
                &nbsp;
              </OutletContactLine>
              <OutletContactLine>
                { contacts.city}
                ,
                &nbsp;
              </OutletContactLine>
              <OutletContactLine>
                { contacts.country}
                ,
                &nbsp;
              </OutletContactLine>
            </Grid>
          </Grid>
          <Grid container item xs={3} style={{ justifyContent: 'space-around', alignItems: 'center' }}>
            <Default>Default</Default>
            <ViewMore onClick={(event) => handleOpenViewModal(event)}>...</ViewMore>
          </Grid>
        </OutletInfoContainer>
      );
    }
    return null;
  };

  const handleUpdateOrderProducts = () => {
    const { toBeDeletedItem } = state;

    const existingCartItem = cart.find((item) => item.id === toBeDeletedItem);
    const _cart = cart.map((cartItem) => (cartItem.id === existingCartItem.id ? { ...cartItem, quantity: 0 } : cartItem));

    setState((s) => ({ ...s, cart: _cart }));

    const productItems = _cart.map((item) => ({ productId: Number(item.id), quantity: item.quantity }));
    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    const mutationVariables = {
      cart: productItems,
      deliveryLocationId: +selectedDeliveryLocationInfo.id
    };
    if (manualCart) {
      mutationVariables.isManualOrder = true;
    }
    setState((s) => ({ ...s, updateLoading: true }));

    updateOrderProducts({
      variables: mutationVariables,
      optimisticResponse: {
        __typename: 'Mutation'
      }
    }).then(({ data }) => {
      const {
        updateOrderProducts: {
          order: {
            meta: ordermeta,
            supplierorderSet
          }
        }
      } = data;

      const orderMeta = JSON.parse(ordermeta.replace(/'/g, '"'));
      setState((s) => ({
        ...s,
        updateLoading: false,
        deliveryAddress: orderMeta.delivery_address
      }));
      retrieveProducts(supplierorderSet);
      toast.success('Items updated successfully.');
      window.location.reload();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handlePlaceOrderProducts = () => {
    const role = localStorage.getItem('oga_user_role');
    const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
    const businessRaw = localStorage.getItem(ogaOrderingForAffiliateKey);
    if (businessRaw === null && role === 'oga-pharmacy-admin') {
      setState((s) => ({ ...s, showPlaceOrderDialog: false }));
      return toast.error('Select an affiliate');
    }
    const _business = businessRaw ? JSON.parse(businessRaw) : null;
    const {
      acceptedExtraProducts,
      businessUserId
    } = state;

    const productItems = cart.map((item) => ({ productId: Number(item.id), quantity: item.quantity }));
    const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
    const extraActions = [];
    const affectedSuppliers = [];
    const extraProductsMeta = acceptedExtraProducts;
    extraPlaceOrderActions.forEach((action) => extraActions.push(action));
    toBePrintedManualSupplierOrderForms.forEach((affectedSupplier) => affectedSuppliers.push(affectedSupplier));
    const mutationVariables = _business ? {
      cart: productItems,
      businessId: _business.id,
      deliveryLocationId: +selectedDeliveryLocationInfo.id,
      extraActions,
      affectedSuppliers,
      extraProductsMeta,
      creditScoreBusiness: _business.id
    } : {
      cart: productItems,
      deliveryLocationId: +selectedDeliveryLocationInfo.id,
      extraActions,
      affectedSuppliers,
      extraProductsMeta,
      creditScoreBusiness: businessUserId
    };
    if (manualCart) {
      mutationVariables.isManualOrder = true;
    }
    setState((s) => ({ ...s, placeLoading: true }));

    const mutationFunc = _business ? placeAffiliateOrderProducts : placeOrderProducts;
    mutationFunc({
      variables: mutationVariables,
      optimisticResponse: { __typename: 'Mutation' }
    }).then(({ data }) => {
      let message = '';
      if (_business) {
        message = data.placeAffiliateOrderProducts.message;
        localStorage.removeItem(ogaOrderingForAffiliateKey);
      } else {
        message = data.placeOrderProducts.message;
      }
      toast.success(message);
      setState((s) => ({ ...s, showPlaceOrderDialog: false }));
      const redirect = _business ? '/orders-admin' : '/orders';
      const url = backRoute === '/manual-order' ? '/inventory/orders' : redirect;
      setTimeout(() => {
        navigate(`${url}`);
      }, 1500);
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, showPlaceOrderDialog: false }));
    });
  };

  const renderEmptyCart = () => (
    <EmptyCardPaper>
      {loading || savedCartLoading ? (
        <LoaderGrid item container>
          <CircularProgressLoader
            disableShrink
            size={100}
          />
        </LoaderGrid>
      ) : (
        <>
          <TableWrapper>
            <TableComponent aria-label="affiliates table">
              <TableHeader>
                <TableHeaderRow>
                  <Header>Product Item</Header>
                  <Header>Quantity</Header>
                  <Header>Unit Cost</Header>
                  <Header>Price</Header>
                  <Header>Action</Header>
                </TableHeaderRow>
              </TableHeader>
            </TableComponent>
          </TableWrapper>
          <TextWrapper container>
            <Grid>
              <EmptyCartImg alt="empty cart" src={EmptyCart} />
              <TextHeader>Cart is Empty</TextHeader>
            </Grid>
            <TextBody>Hi, Your cart is empty and your product will show here when you add them to cart</TextBody>
            <Button
              variant="contained"
              onClick={() => navigate(`${backRoute}`)}
              cartIndex
            >
              Shop Now
            </Button>
          </TextWrapper>
        </>
      )}
    </EmptyCardPaper>
  );

  const handleCloseDeleteDialog = () => {
    setState((s) => ({
      ...s,
      showDeleteDialog: false,
      toBeDeletedItem: null
    }));
  };

  const handleDeleteProduct = () => {
    const { toBeDeletedItem } = state;

    const existingCartItem = cart.find((item) => item.id === toBeDeletedItem);
    const _cart = cart.map((cartItem) => (cartItem.id === existingCartItem.id ? { ...cartItem, quantity: 0 } : cartItem));

    setState((s) => ({ ...s, cart: _cart }));
    handleCloseDeleteDialog();
    handleUpdateOrderProducts();
  };

  const handleIncrementProductQuantityInCart = (id) => {
    const existingCartItem = cart.find((item) => item.id === id);

    setState((s) => ({
      ...s,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem))
    }));
  };

  const handleChangeProductQuantityInputValue = (id, value) => {
    let newValue = 0;
    if (Number(value)) newValue = value;
    else newValue = 1;

    const existingCartItem = cart.find((item) => item.id === id);

    setState((s) => ({
      ...s,
      cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, quantity: newValue }
        : cartItem))
    }));
  };

  const handleDecrementProductQuantityInCart = (id) => {
    const existingCartItem = cart.find((cartItem) => cartItem.id === id);

    if (existingCartItem.quantity > 1) {
      setState((s) => ({
        ...s,
        cart: cart.map((cartItem) => (cartItem.id === existingCartItem.id
          ? { ...cartItem, quantity: cartItem.quantity - 1 }
          : cartItem))
      }));
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setState((s) => ({
      ...s,
      showDeleteDialog: true,
      toBeDeletedItem: id
    }));
  };

  const handleCloseExportDialog = () => {
    setState((s) => ({ ...s, openExportModel: false }));
  };

  const handleOpenSaveCartDialog = () => {
    setState((s) => ({ ...s, saveDialog: true }));
  };

  const handleCloseSaveCartDialog = () => {
    setState((s) => ({ ...s, saveDialog: false }));
  };

  const handleOpenSaveCartDialogView = () => {
    setState((s) => ({ ...s, savedCartDialog: true }));
  };

  const handleCloseSaveCartDialogView = () => {
    setState((s) => ({ ...s, savedCartDialog: false }));
  };

  const handleSave = () => {
    setState((s) => ({ ...s, cart: [] }));
    handleClearCart();
  };

  const handleOpenCartSavedDialog = () => {
    setState((s) => ({ ...s, cartSaved: true }));
  };

  const handleCloseCartSavedDialog = () => {
    setState((s) => ({ ...s, cartSaved: false }));
  };

  const handleOpenNoSavedCartDialog = () => {
    setState((s) => ({ ...s, noSavedCart: true }));
  };

  const handleCloseNoSavedCartDialog = () => {
    setState((s) => ({ ...s, noSavedCart: false }));
  };

  const handleDirectPlaceOrder = () => {
    setState((s) => ({
      ...s,
      placeManualOrder: false,
      showPlaceOrderDialog: true
    }));
  };

  const handleOpenPlaceOrderDialog = () => {
    const deliveryLocationInfo = getOutletOption();
    const { options } = deliveryLocationInfo;
    if (options.length === 0) return toast.error('Select delivery location');
    if (manualCart) {
      setState((s) => ({ ...s, placeManualOrder: true }));
    } else {
      handleDirectPlaceOrder();
    }
  };

  const handleClosePlaceOrderDialog = () => {
    setState((s) => ({ ...s, showPlaceOrderDialog: false }));
  };

  const closeDialogHandler = () => {
    setState((s) => ({ ...s, addNewOutlet: false }));
    // componentDidMount();
  };

  const handleTogglePrintedManualSupplierOrderForms = (supplier) => {
    const exists = toBePrintedManualSupplierOrderForms.get(supplier);
    if (exists) {
      toBePrintedManualSupplierOrderForms.delete(supplier);
    } else {
      toBePrintedManualSupplierOrderForms.set(supplier, supplier);
    }
    setState((s) => ({ ...s, toBePrintedManualSupplierOrderForms }));
  };

  const getSelectedActionStyles = (action) => {
    const exists = extraPlaceOrderActions.get(action);
    if (!exists) {
      return { paddingTop: 20 };
    }
    return {
      borderRadius: 5,
      paddingTop: 20,
      padding: 5,
      backgroundColor: '#F4F4F4',
      opacity: 0.8,
      minWidth: 100
    };
  };

  const handleToggleExtraPlaceOrderActions = (action) => {
    const exists = extraPlaceOrderActions.get(action);
    if (exists) {
      extraPlaceOrderActions.delete(action);
    } else {
      extraPlaceOrderActions.set(action, action);
    }
    if (action === 'print') {
      setState((s) => ({
        ...s,
        printSupplierOrder: !!toBePrintedManualSupplierOrderForms.size,
        extraPlaceOrderActions
      }));
      return;
    }
    setState((s) => ({ ...s, extraPlaceOrderActions }));
  };

  const handleChangeExtraProduct = (event, index) => {
    const { name, value } = event.target;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index][name] = value;
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const handleDeleteExtraProduct = (index) => {
    const copiedExtraProducts = extraProducts.filter((product, ind) => ind !== index);
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const handleAddExtraProduct = () => {
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts.push(generateExtraProduct());
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const cancelExtraProducts = () => {
    const copiedExtraProducts = [generateExtraProduct(), generateExtraProduct(), generateExtraProduct(), generateExtraProduct()];
    toast.success('Extra products removed successfully.');
    setState((s) => ({
      ...s,
      extraProducts: copiedExtraProducts, acceptedExtraProducts: [], wantExtraProducts: false,
    }));
  };

  const saveExtraProducts = () => {
    const copiedExtraProducts = [...extraProducts];
    toast.success('Extra products successfully added.');
    setState((s) => ({
      ...s,
      extraProducts: copiedExtraProducts, acceptedExtraProducts: copiedExtraProducts
    }));
  };

  const handleWantExtraProduct = () => {
    setState((s) => ({ ...s, wantExtraProducts: !wantExtraProducts }));
  };

  const handleClearAllCart = () => {
    setState((s) => ({ ...s, cart: [] }));
    setState((s) => ({ ...s, clearCartDialog: false }));
    handleClearCart();
  };

  const renderManualModalPlaceOrderSuppliers = () => {
    const uniqueSuppliers = [...new Set([...cart.map((item) => item.supplier)])];
    return uniqueSuppliers.map((supplier) => (
      <ManualSupplierItem item>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <ManualMethodImage src={SupplierOrderForm} />
          </Grid>
          <Grid item>{supplier?.trim().split(' ')[0]}</Grid>
          <Grid>
            <Checkbox
              checked={toBePrintedManualSupplierOrderForms.get(supplier)}
              onChange={() => handleTogglePrintedManualSupplierOrderForms(supplier)}
              color="primary"
            />
          </Grid>
        </Grid>
      </ManualSupplierItem>
    ));
  };

  const ChangeQuantity = (index, value) => {
    if (value < 1) return;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index].quantity = value;
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const changeToInt = (event, index) => {
    const { name, value } = event.target;
    const copiedExtraProducts = [...extraProducts];
    copiedExtraProducts[index][name] = parseInt(value);
    setState((s) => ({ ...s, extraProducts: copiedExtraProducts }));
  };

  const renderExtraProductRow = (row, index) => (
    <TableRow style={{ padding: 0, borderBottom: 0 }} key={`key${index}`}>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="brandName"
            id="brandname"
            value={row.brandName}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="Name of medicine"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <ChangeOutletRow>
            <ReturnSelectField
              field={selectDosage()}
              value={row.dosageForm}
              showCheckBox={false}
              label="Select"
              handleCreditDaysOpen={() => ({})}
              fullWidth
              handleChange={(event) => handleChangeExtraProduct(event, index)}
            />
          </ChangeOutletRow>
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="strength"
            id="strength"
            value={row.strength}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="mg/iu"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <Grid>
          <input
            type="text"
            name="packSize"
            id="packsize"
            value={row.packSize}
            onChange={(event) => handleChangeExtraProduct(event, index)}
            placeholder="e.g 30 Tablets"
            style={{
              background: '#F0F0F0', width: '100%', paddingLeft: '20px', paddingTop: '15px', paddingBottom: '15px', fontSize: '15px', border: 'none',
            }}
          />
        </Grid>
      </TableCell>
      <TableCell style={{
        borderBottom: 0, alignContent: 'center'
      }}
      >
        <Grid>
          <CartItemButtonContainer>
            <CartItemButton onClick={() => ChangeQuantity(index, row.quantity - 1)} style={{ float: 'none' }}>-</CartItemButton>
            <input
              type="number"
              name="quantity"
              id="quantity"
              min={1}
              value={row.quantity}
              onChange={(event) => changeToInt(event, index)}
              style={{
                background: '#F0F0F0', width: '40%', paddingLeft: '10px', paddingTop: '9px', paddingBottom: '15px', fontSize: '20px', border: 'none', marginTop: '5px'
              }}
            />
            <CartItemButton onClick={() => ChangeQuantity(index, row.quantity + 1)} style={{ float: 'none' }}>+</CartItemButton>
          </CartItemButtonContainer>
        </Grid>
      </TableCell>
      <TableCell style={{
        borderBottom: 0, alignContent: 'center'
      }}
      >
        <Grid>
          <Delete onClick={() => handleDeleteExtraProduct(index)} style={{ cursor: 'pointer' }} />
        </Grid>
      </TableCell>
    </TableRow>
  );

  const handleOpenMCF = () => {
    setState((s) => ({ ...s, openMCF: true }));
  };

  const handleCloseDialog = () => {
    setState((s) => ({ ...s, openMCF: false }));
  };

  const renderSupplierContainers = (_priceFactor) => {
    const supplierCategories = _.groupBy(cart, 'supplier');
    return Object.entries(supplierCategories).map(([key, value]) => {
      const isErp = manualCart && (key !== 'OGApharmacy');
      const plat = isErp ? 'erp' : 'marketplace';
      const supplierSubTotal = value.reduce((sum, obj) => {
        const product = new Product(obj, plat);
        const unitPrice = isErp ? product.orderCost : product.mktPrice;
        return sum + obj.quantity * unitPrice;
      }, 0) * _priceFactor;

      return (
        <SupplierCardPaper elevation={2} key={key}>
          <TableWrapper>
            <TableComponent aria-label="affiliates table">
              <TableHeader>
                <TableHeaderRow>
                  <Header>Product Name</Header>
                  <Header>Quantity</Header>
                  <Header>Unit Cost</Header>
                  <Header>Price</Header>
                  <Header>Action</Header>
                </TableHeaderRow>
              </TableHeader>
              {/* <ScrollBox> */}
              <TableBody>
                {value.filter((p) => p.quantity > 0).map((productItem) => (
                  <ProductItem
                    product={productItem}
                    platform={plat}
                    switchOrderProductSupplier={handleSwitchOrderProductSupplier}
                    incrementProductQuantity={handleIncrementProductQuantityInCart}
                    decrementProductQuantity={handleDecrementProductQuantityInCart}
                    openDeleteDialog={handleOpenDeleteDialog}
                    closeDeleteDialog={handleCloseDeleteDialog}
                    changeInputQuantity={handleChangeProductQuantityInputValue}
                  />
                ))}
              </TableBody>
              {/* </ScrollBox> */}
            </TableComponent>
          </TableWrapper>
          <ProductSubTotalWrapper>
            <ProductSubTotalHeaders>
              <ProductColumnHeaderTitle style={{ width: '20%' }}>
                Subtotal
              </ProductColumnHeaderTitle>
              <ProductColumnHeaderTitle style={{ width: '25%', display: 'flex', justifyContent: 'end' }}>
                {`₦ ${currencyFormatter(supplierSubTotal)}`}
              </ProductColumnHeaderTitle>
            </ProductSubTotalHeaders>
          </ProductSubTotalWrapper>
        </SupplierCardPaper>
      );
    });
  };

  const orderTotal = cart.reduce((sum, obj) => sum + obj.quantity * (manualCart && obj.supplier !== 'OGApharmacy' ? obj.orderCost : obj.mktPrice), 0) * priceFactor;
  const groupOrderDiscount = cart.reduce((sum, obj) => sum + (`${obj.productBusinessId}` === '1' ? (Number(obj.quantity) * (Number(obj.marketRrp) - Number(manualCart && obj.supplier !== 'OGApharmacy' ? obj.orderCost : obj.mktPrice))) : 0), 0);
  const taxTotal = business && business.tax ? orderTotal * (business.tax / 100) : 0;
  const grandTotal = serviceFee + deliveryFee + orderTotal + taxTotal;
  const _params = { bizId: subBusinessesIds[0] } ?? null;
  const open = Boolean(viewMoreButton);
  const selectedDeliveryLocationInfo = getSelectedDeliveryLocationInfo();
  const therapeuticTypes = cart.slice(0, 5)?.filter((item) => item.therapeuticType !== null)?.map((item) => item.therapeuticType?.id);
  const similarProductsName = cart.slice(0, 5).map((item) => item.name);

  return (
    <MainContent>
      <CreateOutletModal cart dialogOpen={addNewOutlet} existingOutlet={existingOutlet} closeDialog={closeDialogHandler} />
      <CartGridContainer>
        <CartGridContainerHeader container sm={2} md={1}>
          <BackArrowIconContainer item sm={2} md={1} onClick={() => navigate(-1)} className="mpAdmin-uat-cartPage-back">
            <KeyboardArrowLeft />
            <ArrowBackText>back</ArrowBackText>
          </BackArrowIconContainer>
        </CartGridContainerHeader>
        <CartItemCountContainer container xs={12}>
          <CartItemContainer item container xs={12} lg={8}>
            <PageTitleText>Your Cart</PageTitleText>
            { cart?.length
              ? (
                <CartItemWrapper
                  style={{
                    marginTop: '1rem'
                  }}
                >
                  {/* <CartImage src={Cart} /> */}
                  <ShoppingCart fontSize="large" style={{ color: '#235A91' }} />
                  <CartItemCountText>
                    {cart.length}
                    &nbsp;
                    {cart.length ? 'Items' : 'Item'}
                    &nbsp;
                    in cart
                  </CartItemCountText>
                </CartItemWrapper>
              ) : ''}
          </CartItemContainer>
          <ClearBox xs={12} lg={4} container style={{ justifyContent: 'flex-end' }}>
            { cart?.length
              ? (
                <Grid
                  xs={10}
                  md={8}
                  style={{
                    marginTop: '0.5rem',
                    marginRight: '2rem'
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={(e) => handleClearCartDialog(e)}
                  >
                    Clear Cart
                  </Button>
                </Grid>
              ) : ''}
            {manualCart === false ? (
              <Grid container xs={2} md={4} style={{ justifyContent: 'flex-end' }}>
                <IconWrapper
                  variant="text"
                >
                  <Hidden lgDown><Dots fontSize="large" onClick={(e) => handleCartDialog(e)} /></Hidden>
                  <Hidden mdUp><CartDot style={{ fontSize: '6rem' }} onClick={(e) => handleCartDialog(e)} /></Hidden>
                  <ActionPopper
                    handleClose={handleCartDialog}
                    actionPopper={actionPopper}
                    handleAction={handleOpenSaveCartDialog}
                    handleActionView={handleOpenSaveCartDialogView}
                    handleCartList={handleCartList}
                    cart={cart}
                    handleNoCart={handleOpenNoSavedCartDialog}
                    cartOrder={cartOrder}
                  />
                </IconWrapper>
              </Grid>
            ) : ''}
          </ClearBox>
        </CartItemCountContainer>
        <CartContainer container xs={12}>
          <Grid container spacing={4} style={{ marginLeft: '.5rem' }}>
            <Grid lg={8}>
              {cart.length === 0 || loading || savedCartLoading
                ? renderEmptyCart()
                : renderSupplierContainers(priceFactor) }
            </Grid>
            <Hidden mdDown>
              <DeliveryAddressCard2 xs={12} lg={4}>
                <PaymentCardSection elevation={2}>
                  <PaymentSummary
                    cart={cart}
                    orderTotal={orderTotal}
                    serviceFee={serviceFee}
                    deliveryFee={deliveryFee}
                    taxTotal={taxTotal}
                    groupOrderDiscount={groupOrderDiscount}
                    grandTotal={grandTotal}
                    handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
                    handleDirectPlaceOrder={handleDirectPlaceOrder}
                    placeLoading={placeLoading}
                    updateLoading={updateLoading}
                    backRoute={backRoute}
                  />
                </PaymentCardSection>
              </DeliveryAddressCard2>
            </Hidden>
            {cart?.length
              ? (
                <DeliveryAddressCard md={12} lg={8}>
                  <DeliveryAddressCardSection elevation={2}>
                    <DeliveryAddressCardHeader>
                      <DeliveryAddressCardHeaderTitle>Delivery Location</DeliveryAddressCardHeaderTitle>
                      <Hidden lgDown>
                        <DeliveryAddressCardHeaderSubTitle
                          onClick={() => handleDeliveryAddress(selectedDeliveryLocationInfo)}
                          type="button"
                          className="mpAdmin-uat-cartPage-addDeliveryLocation"
                        >
                          <DeliveryIcon />
                          Add Delivery Location
                        </DeliveryAddressCardHeaderSubTitle>
                      </Hidden>
                    </DeliveryAddressCardHeader>
                    <Grid style={{ marginBottom: '20px' }}>
                      <SelectOutletText>Select the outlet you want your order to be delivered to from the dropdown below</SelectOutletText>
                      <Hidden mdUp>
                        <DeliveryAddressCardHeaderSubTitle
                          onClick={() => handleDeliveryAddress(selectedDeliveryLocationInfo)}
                          type="button"
                          className="mpAdmin-uat-cartPage-addDeliveryLocation"
                        >
                          Add Delivery Location
                        </DeliveryAddressCardHeaderSubTitle>
                      </Hidden>
                      <ChangeOutletRow>
                        <ReturnSelectField
                          field={getOutletOption()}
                          value={selectedDeliveryLocation}
                          showCheckBox={false}
                          // placeholder="Select Pharmacy"
                          handleCreditDaysOpen={() => ({})}
                          fullWidth
                          handleChange={handleChangeDeliveryLocation}
                          cart
                          className="mpAdmin-uat-cartPage-selectDeliveryOutlet"
                        />
                      </ChangeOutletRow>
                    </Grid>
                    {renderSelectDeliveryLocationInfo()}
                  </DeliveryAddressCardSection>
                </DeliveryAddressCard>
              ) : ''}
            <Hidden mdUp>
              <DeliveryAddressCard2 xs={12} lg={4}>
                <PaymentCardSection elevation={2}>
                  <PaymentSummary
                    cart={cart}
                    orderTotal={orderTotal}
                    serviceFee={serviceFee}
                    deliveryFee={deliveryFee}
                    taxTotal={taxTotal}
                    groupOrderDiscount={groupOrderDiscount}
                    grandTotal={grandTotal}
                    handleOpenPlaceOrderDialog={handleOpenPlaceOrderDialog}
                    placeLoading={placeLoading}
                    updateLoading={updateLoading}
                    backRoute={backRoute}
                    handleDirectPlaceOrder={handleDirectPlaceOrder}

                  />
                </PaymentCardSection>
              </DeliveryAddressCard2>
            </Hidden>
          </Grid>
        </CartContainer>
        {canUseMastersList && (
          <Grid>
            <div>
              <h3 style={{ marginBottom: ' 10px' }}>
                Want other products not on our list?
                <Span
                  onClick={handleWantExtraProduct}
                  style={{
                    paddingLeft: '20px', marginTop: '90px', color: '#A3A3A3', width: '80%', cursor: 'pointer'
                  }}
                >
                  <u>Click here</u>
                </Span>
              </h3>
            </div>
            { wantExtraProducts && (
              <AddProductCard>
                <AddProductCardSection>
                  <TableContainerWrapper>
                    <TableComponent aria-label="additional products">
                      <MainTableHead>
                        <TableRow>
                          <HeaderCell style={{ margin: 0, borderBottom: 0 }}>Product Name</HeaderCell>
                          <HeaderCell style={{ borderBottom: 0 }}>Dosage Form</HeaderCell>
                          <HeaderCell style={{ borderBottom: 0 }}>Strength</HeaderCell>
                          <HeaderCell style={{ borderBottom: 0 }}>Pack Size</HeaderCell>
                          <HeaderCell style={{ borderBottom: 0, align: 'center' }}>Qty</HeaderCell>
                          <HeaderCell style={{ borderBottom: 0, align: 'center' }} />
                        </TableRow>
                      </MainTableHead>
                      <TableBody>
                        {extraProducts.map((row, index) => renderExtraProductRow(row, index))}
                      </TableBody>
                    </TableComponent>
                  </TableContainerWrapper>
                  <Span
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => ({})}
                    onClick={handleAddExtraProduct}
                    style={{
                      paddingLeft: '20px', marginTop: '90px', color: '#A3A3A3', width: '80%', cursor: 'pointer'
                    }}
                  >
                    <u>Add Product</u>
                  </Span>
                  {' '}
                  <CheckCircleOutlineRoundedIcon
                    onClick={saveExtraProducts}
                    style={{
                      float: 'right', cursor: 'pointer', paddingLeft: '30px', fontSize: '35px', width: '5%'
                    }}
                  />
                  <HighlightOffRounded
                    onClick={cancelExtraProducts}
                    style={{
                      float: 'right', cursor: 'pointer', paddingLeft: '30px', fontSize: '35px', width: '5%',
                    }}
                  />
                </AddProductCardSection>
              </AddProductCard>
            )}
          </Grid>
        )}
        <SimilarProducts
          similarProductsName={similarProductsName}
          therapeuticTypes={therapeuticTypes}
          cart
        />
      </CartGridContainer>

      <Dialog
        open={showDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <TitleText>Delete Product</TitleText>
        </DialogTitle>
        <DialogContent>
          <ContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this product from the cart?
          </ContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDeleteProduct()}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showPlaceOrderDialog}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <TitleText>Place Order</TitleText>
        </DialogTitle>
        <DialogContent>
          <ContentText id="alert-dialog-slide-description">
            Are you sure you want to place the order for the current products in the cart?
          </ContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClosePlaceOrderDialog}
            disabled={placeLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handlePlaceOrderProducts()}
            disabled={placeLoading}
          >
            {placeLoading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : 'OK'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={placeManualOrder}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ManualDialogPadder>
          <ManualPlaceOrder id="alert-dialog-slide-title">Place order</ManualPlaceOrder>
          <Grid style={{ marginTop: '-10px' }}>
            Choose how you to want to send your order to suppliers
          </Grid>
        </ManualDialogPadder>

        <br />
        <Grid style={{ backgroundColor: '#F4F4F4' }}>
          <ManualDialogPadder container>
            {renderManualModalPlaceOrderSuppliers()}
          </ManualDialogPadder>
        </Grid>

        <ManualDialogPadder>
          <Grid container justifyContent="space-between">
            <Grid style={{ ...getSelectedActionStyles('pdf') }}>
              <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('pdf')} justifyContent="center" alignItems="center">
                <ManualMethodImage src={Pdf} />
                <OptionText>Save as PDF</OptionText>
              </Grid>
            </Grid>
            <Grid item style={{ ...getSelectedActionStyles('email') }}>
              <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('email')} justifyContent="center" alignItems="center">
                <ManualMethodImage src={Email} />
                <OptionText>Email</OptionText>
              </Grid>
            </Grid>
            <Grid item style={{ ...getSelectedActionStyles('print') }}>
              <Grid container direction="column" onClick={() => handleToggleExtraPlaceOrderActions('print')} justifyContent="center" alignItems="center">
                <Grid style={{ minHeight: 75 }}><ManualMethodImage src={Print} /></Grid>
                <OptionText>Print</OptionText>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container justifyContent="center">
            <ManualDialogCancelButton onClick={() => setState((s) => ({ ...s, placeManualOrder: false }))}>
              <DialogActionButtonText>
                Cancel
              </DialogActionButtonText>
            </ManualDialogCancelButton>
            <ManualDialogDoneButton
              // disabled={!selectedAffiliate}
              onClick={handleDirectPlaceOrder}
            >
              <DialogActionButtonText>
                Done
              </DialogActionButtonText>
            </ManualDialogDoneButton>
          </Grid>
          <br />
        </ManualDialogPadder>
      </Dialog>
      <Dialog
        open={printSupplierOrder}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <SupplierOrderPrint
          printSupplierOrder={printSupplierOrder}
          orderId={orderId}
          cart={cart}
          handleSupplierOrderPrint={() => setState((s) => ({ ...s, printSupplierOrder: false }))}
          selectedDeliveryLocationInfo={getSelectedDeliveryLocationInfo()}
          toBePrintedManualSupplierOrderForms={toBePrintedManualSupplierOrderForms}
        />
      </Dialog>
      <RenderPopper
        open={open}
        anchorEl={viewMoreButton}
        onClickAway={() => handleOpenViewModal()}
        popperPlacement="right"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0vw, 2vh',
          }
        }}
      >
        <GridWrapper container item>
          <EditContainer container item>
            <ItemWrapper onClick={() => handleEditDeliveryLocation(selectedDeliveryLocationInfo)}>
              <ItemTypo variant="subtitle1">
                Edit Address
              </ItemTypo>
            </ItemWrapper>
          </EditContainer>

          <GridContainer container item>
            <ItemWrapper onClick={(e) => handleDeleteAddressDialog(e)}>
              <ItemTypo variant="subtitle1">
                Delete Address
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>

        </GridWrapper>
      </RenderPopper>
      <OrderPrintDialog
        openPrintDialog={openPrintDialog}
        orderId={orderId}
        closePrint={handleClosePrintDialog}
      />
      {showLoan && (
        <Floater
          state={state}
          priceFactor={priceFactor}
          handleOpenMCF={handleOpenMCF}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      <ConfirmDialog
        handleClose={handleDeleteAddressDialog}
        deleteAddressDialog={deleteAddressDialog}
        selectedDeliveryLocationInfo={selectedDeliveryLocationInfo}
        handleDelete={handleDeleteDeliveryLocation}
      />
      <ClearCartDialog
        handleClose={handleClearCartDialog}
        clearCartDialog={clearCartDialog}
        handleDelete={handleClearAllCart}
      />
      <ReportExportModel
        open={openExportModel}
        handleClose={handleCloseExportDialog}
        endpoint="cart"
        params={_params}
      />
      <SaveDialog
        saveDialog={saveDialog}
        handleClose={handleCloseSaveCartDialog}
        handleSave={handleSave}
        handleAction={handleOpenCartSavedDialog}
      />
      <CartSaved
        cartSaved={cartSaved}
        handleClose={handleCloseCartSavedDialog}
      />
      <SavedCartDialog
        savedCartDialog={savedCartDialog}
        handleClose={handleCloseSaveCartDialogView}
        cartOrder={cartOrder}
      />
      <NoSavedCart
        noSavedCart={noSavedCart}
        handleClose={handleCloseNoSavedCartDialog}
      />
    </MainContent>
  );
};

CartIndex.propTypes = {
  manualCart: PropTypes.bool,
  backRoute: PropTypes.string,
};

CartIndex.defaultProps = {
  manualCart: false,
  backRoute: ''
};

export default CartIndex;
