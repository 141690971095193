import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  ProductDetailImage, PackSizeText, MainTableRow, BodyCell, BrandNameText
} from './returnRow.styles';

const ReturnRow = ({ prod, setTotalPrice }) => {
  const {
    product, quantity, quantityRecieved, unitCost
  } = prod;
  const { brandName, packSize, image } = product.erpVersion;
  const getPrice = () => currencyFormatter(Number(unitCost) * (Number(quantityRecieved)));

  useEffect(() => {
    setTotalPrice((t) => t + (Number(unitCost) * Number(quantityRecieved)));
  }, [quantityRecieved]);

  return (
    <MainTableRow container item>
      <BodyCell>
        <Grid container item>
          <Grid container item xs={4} alignItems="center">
            <ProductDetailImage src={image} alt="" />
          </Grid>
          <Grid container item xs={8} direction="column" justifyContent="center">
            <BrandNameText>{brandName}</BrandNameText>
            <PackSizeText>{`Pack Size: ${packSize}`}</PackSizeText>
          </Grid>
        </Grid>
      </BodyCell>
      <BodyCell>{Number(quantity)}</BodyCell>
      <BodyCell>{quantityRecieved || 0}</BodyCell>
      <BodyCell>
        {`₦ ${currencyFormatter(unitCost)}`}
      </BodyCell>
      <BodyCell>{`₦ ${getPrice()}`}</BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  prod: PropTypes.instanceOf(Object).isRequired,
  setTotalPrice: PropTypes.func.isRequired,
};

export default ReturnRow;
