import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Select, MenuItem
} from '@mui/material';
import {
  CFormControl, Label, SelectNameTypo, FieldCheckbox, CheckboxImage, HelperText
} from './customSelectInputBase.styles';
import CheckboxIcon from '../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../assets/images/supplier/Checkbox-resting.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const CustomSelectInputBase = ({
  field, value, handleChange, showCheckBox,
  placeholder, error, helperText, required, ...props
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    setSelected([event.target.value]);
    handleChange(event);
  };

  const renderFunc = (val) => val;

  return (
    <Grid item container direction="column">
      {field.label ? (
        <Label error={error}>
          {field.label}
          {' '}
          {required && '*'}
        </Label>
      ) : ''}
      <CFormControl
        fullWidth
        variant="filled"
        cSize="lg"
        ref={selectRef}
        error={error}
        {...props}
      >
        <Select
          className={`erp-uat-${field.label.replace(/ /g, '-')}`}
          labelId="select-filled-label"
          id="select-filled"
          value={value}
          disableUnderline
          displayEmpty
          renderValue={(val) => (
            value !== ''
              ? renderFunc(val)
              : <span style={{ color: '#bdbdbd' }}>{placeholder}</span>
          )}
          onChange={handleInput}
          inputProps={{
            name: field.name,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {field?.options?.map(
            (option) => (typeof option === 'object' && option !== null ? (
              <MenuItem key={option.name} value={option.name}>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <SelectNameTypo>{option.name}</SelectNameTypo>
                  {showCheckBox && (
                    <FieldCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected.indexOf(option) > -1}
                    />
                  )}
                </Grid>
              </MenuItem>
            ) : (
              <MenuItem key={option} value={option}>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <SelectNameTypo>{option}</SelectNameTypo>
                  {showCheckBox && (
                    <FieldCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected.indexOf(option) > -1}
                    />
                  )}
                </Grid>
              </MenuItem>
            ))
          )}
        </Select>
      </CFormControl>
      {(helperText && error) ? <HelperText>{helperText}</HelperText> : ''}
    </Grid>
  );
};

CustomSelectInputBase.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  showCheckBox: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
};

CustomSelectInputBase.defaultProps = {
  field: {},
  value: '',
  placeholder: '',
  showCheckBox: true,
  error: false,
  helperText: '',
  required: false,
};

export default CustomSelectInputBase;
