import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Paper, Table, TableBody, TableContainer, TableRow
} from '@mui/material';
import GoToTop from '../../customComponents/scrollToTop';
import TablePagination from '../../shared/tablePagination';
import ReturnRow from '../shared/returnRow';

import {
  OrderGridContainer, StatisticsGridContainer,
  OrderItemsGrid, FooterGrid, OrderCountText, MainContainer, ArrowLeftIcon,
  StatisticsGridWrapper, ArrowButton, ArrowRightIcon,
  MainTableHead, HeaderCell, NoDataTypo, DivFullWidth
} from '../admin/order.styles';
import Notification from '../../notification';
import StatisticCard from '../shared/statisticCard';
import TitleGrid from '../shared/titleGrid';
import MainContent from '../../customComponents/mainContent';
import { valuesFormatter } from '../../../utils/currencyFormatter';
import paginationShowing from '../../../utils/pagination';
import TotalOrderIcon from '../../../assets/images/order/Total Orders Icon.png';
import ActiveIcon from '../../../assets/images/order/Active Orders Icon.png';

import { useStateValue } from '../../../providers/stateProvider';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import ToggleBusiness from '../../shared/busnesss';

const Order = ({
  supplierOrders,
  setPageNumber,
  setPageCount,
  currentPageCount,
  page,
  totalCount,
  onOrderItemCardClicked
}) => {
  const [disabledButton, setDisabledButton] = useState(false);

  const totalsContainer = useRef(null);
  const element = totalsContainer.current;
  const [{ navbar: { openSideDrawer } }, dispatch] = Object.values(useStateValue());

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
    dispatch({ type: CartActionTypes.UPDATE_IS_MANUAL, payload: true });
  }, []);

  const STATISTICS = [
    {
      id: 1, icon: ActiveIcon, title: 'Total Orders', value: 'N/A'
    },
    {
      id: 2, icon: ActiveIcon, title: 'Active Orders', value: 'N/A'
    },
    {
      id: 3, icon: ActiveIcon, title: 'Total Order Amount', value: ` ${valuesFormatter(0)}`
    },
    {
      id: 4, icon: TotalOrderIcon, title: 'Total Savings Amount', value: ` ${valuesFormatter(0)}`
    }
  ];
  const headers = [
    'Date Placed', 'Order No', 'Affiliate', 'Amount', 'Supplier', 'Payment Status',
  ];

  const scrollRight = () => {
    const containerWidth = STATISTICS.length * 300;
    const translateX = containerWidth - element.clientWidth;
    element.style.transform = `translateX(-${translateX}px)`;
    setDisabledButton(!disabledButton);
  };

  const scrollLeft = () => {
    element.style.transform = 'translateX(0px)';
    setDisabledButton(!disabledButton);
  };

  const showing = paginationShowing(supplierOrders, currentPageCount);

  return (
    <MainContent>
      <MainContainer item container xs>
        <OrderGridContainer container>
          <TitleGrid />
          <Grid item container xs={12} wrap="nowrap" alignItems="center">
            <ArrowButton
              disabled={!disabledButton}
              onClick={scrollLeft}
              component="span"
              show={openSideDrawer}
            >
              <ArrowLeftIcon />
            </ArrowButton>
            <StatisticsGridWrapper item container>
              <StatisticsGridContainer
                ref={totalsContainer}
                container
                item
                wrap="nowrap"
                justifyContent="space-between"
              >
                {STATISTICS.map((statistic) => (
                  <StatisticCard
                    key={statistic.id}
                    statistic={statistic}
                    admin
                  />
                ))}
              </StatisticsGridContainer>
            </StatisticsGridWrapper>
            <ArrowButton
              disabled={disabledButton}
              onClick={scrollRight}
              component="span"
              show={openSideDrawer}
            >
              <ArrowRightIcon />
            </ArrowButton>
          </Grid>

          <OrderItemsGrid xs={12}>
            <OrderCountText>
              Showing
              <b>
                {` ${showing()} - ${showing('end')} `}
              </b>
              of
              <b>
                {` ${totalCount} `}
              </b>
              orders
            </OrderCountText>
            <ToggleBusiness />
            <Grid item container xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="orders table">
                  <MainTableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <HeaderCell key={header}>{header}</HeaderCell>
                      ))}
                    </TableRow>
                  </MainTableHead>
                  <TableBody>
                    {!supplierOrders.length && (
                      <NoDataTypo>Your filter did not return any Order items!</NoDataTypo>
                    )}
                    {supplierOrders.map((supplierOrder) => {
                      const {
                        id, dateCreated, name, total, savings, logo,
                        supplierName, supplierLogo
                      } = supplierOrder;
                      return (
                        <ReturnRow
                          key={supplierOrder.id}
                          id={id}
                          name={name}
                          dateCreated={dateCreated}
                          total={total}
                          savings={savings}
                          logo={logo}
                          handleOrderItemCardClicked={onOrderItemCardClicked}
                          supplierName={supplierName}
                          supplierLogo={supplierLogo}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </OrderItemsGrid>
          <Notification />
          <FooterGrid item container>
            {/* <Hidden smDown>
              <OrderCountText>
                Showing
                <b>
                  {` ${showing()} - ${showing('end')} `}
                </b>
                of
                <b>
                  {` ${totalCount} `}
                </b>
                orders
              </OrderCountText>
            </Hidden> */}
            {/* <CTablePagination
              rowsPerPageOptions={[20, 50, 100, 150, 200]}
              component="div"
              count={totalCount}
              page={page - 1}
              onChangePage={handleChangePage}
              rowsPerPage={currentPageCount}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
            <DivFullWidth>
              {totalCount > 0 && (
              <TablePagination
                total={totalCount}
                pageCount={currentPageCount}
                setPageCount={setPageCount}
                pageNumber={page}
                setPageNumber={setPageNumber}
              />
              )}
            </DivFullWidth>
          </FooterGrid>
          {
              (supplierOrders.length)
                ? <GoToTop /> : ''

            }

        </OrderGridContainer>

      </MainContainer>
    </MainContent>
  );
};

Order.propTypes = {
  supplierOrders: PropTypes.instanceOf(Array).isRequired,
  setPageNumber: PropTypes.isRequired,
  setPageCount: PropTypes.isRequired,
  page: PropTypes.number.isRequired,
  currentPageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onOrderItemCardClicked: PropTypes.func.isRequired
};

export default Order;
