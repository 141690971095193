import React, {
  useState, useEffect, useLayoutEffect, useRef
} from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, useMediaQuery } from '@mui/material';
import toast from 'react-hot-toast';
import MainContent from '../../customComponents/mainContent';
import { BATCH_PRODUCT_QUERY, SEARCH_PRODUCT_QUERY } from '../../../queries/products';
import NewReport from './newReport';
import {
  TitleGridContainer, DetailCard, PrimaryTitle,
  SecondaryTitle, DetailCardText, StatusBullet, CIconButton, KeyboardArrowLeftIcon
} from '../stockReport.styles';
import NewReportDialog from './newReportDialog';
import SearchPopper from './searchPopper';
import BatchDialog from './batchDialog';
import { renderStockReportStatus, handleStockReportStatusColor } from '../utils';
import { useStateValue } from '../../../providers/stateProvider';
import { REMOVE_REPORT_MUTATION, UPDATE_REPORT_MUTATION } from '../../../mutations/reports';
import { GET_STOCK_REPORT_QUERY } from '../../../queries/reports';
import ConfirmDialog from '../../shared/confirmDialog';

const pageCount = sessionStorage.getItem('rowsPerPage') || 10;
const initialState = {
  searchValue: '',
  searchedProducts: [],
  currentPage: 1,
  currentPageCount: pageCount,
  business: 0
};

const NewReportContainer = () => {
  const [state, setState] = useState(initialState);
  const [searchText, setSearchText] = useState('');
  const [openSearchPopper, setOpenSearchPopper] = React.useState(false);
  const [openNewReportDialog, setOpenNewReportDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [batch, setBatch] = useState({});
  const [buttonDisable, setButtonDisable] = useState(true);
  const [updateBatch, setUpdateBatch] = useState(false);
  const [pid, setPid] = useState('');
  const navigate = useNavigate();

  const [{ business: { businessId } }] = Object.values(useStateValue());
  const { id: stockReportId } = useParams();
  const searchFieldRef = useRef(null);

  const reportBusinessId = localStorage.getItem('report_businessId');
  const isSmall = useMediaQuery('(max-width: 991px)');
  const productsWithoutCount = 0;
  const reportStatus = '';
  const detailCardStyle = isSmall ? (reportStatus === 'STARTED' ? '5rem' : reportStatus === 'RECONCILE_QUANTITY' ? '1rem' : '5rem') : undefined;

  const [updateReport] = useMutation(UPDATE_REPORT_MUTATION);
  const [removeReport, { loading: removeLoading }] = useMutation(REMOVE_REPORT_MUTATION);

  const {
    searchedProducts, business
  } = state;

  const createData = (product) => {
    const {
      id, sku, erpVersion: { brandName, resolvedPriceInUseValue, packSize }
    } = product || {};

    return {
      id, sku, brandName, itemPrice: resolvedPriceInUseValue, packSize
    };
  };

  const handlePopperClose = () => {
    setState({ ...state, searchedProducts: [] });
    setOpenSearchPopper(false);
  };

  const [getSearchedProducts, { data, loading }] = useLazyQuery(SEARCH_PRODUCT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: '',
      productName: searchText,
      status: 'enabled',
    },
  });

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      getSearchedProducts();
    } else {
      handlePopperClose();
    }
  }, [searchText]);

  const [getBatches, { data: batchData, loading: batchLoading, refetch: refetchBatch }] = useLazyQuery(BATCH_PRODUCT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      search: '',
      productId: pid
    },
  });

  const handleBatchSelection = ({ sku }) => {
    setPid(sku);
    setSearchText('');
  };

  useEffect(() => {
    if (pid) {
      getBatches();
      setOpenDialog(true);
    }
  }, [pid, getBatches]);

  useEffect(() => {
    if (searchText?.length >= 3 && data?.erpProducts) {
      const sResults = data?.erpProducts.map((product) => createData(product));
      if (updateBatch) {
        const selectResult = sResults?.find((prod) => prod);
        handleBatchSelection(selectResult);
        setUpdateBatch(false);
        handlePopperClose();
        return;
      }
      setState({ ...state, searchedProducts: sResults });
      setOpenSearchPopper(true);
    }
  }, [loading, data]);

  const handleSearch = async (event) => {
    const { value } = event.target;
    if (pid) setPid('');
    setSearchText(value);
  };

  const { loading: reportLoading, data: reportData, refetch } = useQuery(GET_STOCK_REPORT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: Number(stockReportId) },
  });

  const batches = reportData?.stockReportInventory || [];

  const handleAddProductToReport = (nBatch, product) => {
    if (nBatch.length < 1) return toast.error('Select at least one batch');

    const products = [];

    const { id: productId } = product;

    nBatch.map((item) => {
      const {
        id: batchId,
        batchNo
      } = item;

      const newBatch = {
        productId, quantityCounted: 0, batchId, batchNo
      };
      products.push(newBatch);
    });

    const request = {
      stockReportId: Number(stockReportId),
      business: Number(businessId),
      products,
      status: 'RECONCILE_QUANTITY'
    };

    updateReport({
      variables: { ...request }
    }).then((response) => {
      const { message } = response?.data?.updateReport || {};
      refetch();
      toast.success(message);
    }).catch((err) => {
      toast.error('Unable to process request, found a duplicate batch');
    });
  };

  const updateHandler = (row, counter, type, expDate) => {
    if (type === 'UPDATE') {
      const {
        batch: { id: batchId }, product: { id: productId }, id: inventoryId
      } = row;
      const expiryDate = expDate || '';

      const products = [{
        inventoryId,
        productId: Number(productId),
        quantityCounted: counter,
        batchId,
        expiryDate
      }];

      const request = {
        stockReportId: Number(stockReportId),
        business: Number(businessId),
        products,
        status: 'RECONCILE_QUANTITY'
      };

      updateReport({
        variables: { ...request }
      }).then((response) => {
        const { message } = response?.data?.updateReport || {};
        toast.success(message);
        refetch();
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      setBatch(row);
      setOpenConfirmDialog(true);
    }
  };

  // Remove product if removal status is confirmed
  if (confirmStatus) {
    const { id: inventoryId } = batch;
    const request = {
      stockReportId: Number(stockReportId),
      inventoryIds: [Number(inventoryId)]
    };

    removeReport({
      variables: { ...request }
    }).then((response) => {
      setOpenConfirmDialog(false);
      const { message } = response?.data?.removeStockInventory || {};
      toast.success(message);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
    setConfirmStatus(false);
    setBatch({});
  }

  return (
    <MainContent>
      <Grid container style={{ padding: '30px' }}>
        <TitleGridContainer container item>
          <Grid container item md={7}>
            <CIconButton onClick={() => navigate(-1)} style={{ marginTop: isSmall ? '1rem' : undefined }}>
              <KeyboardArrowLeftIcon style={{ fontSize: isSmall ? '4rem' : undefined }} />
            </CIconButton>
            <Grid item>
              <PrimaryTitle variant="h5">
                {stockReportId ? 'Edit Stock Report' : 'Stock Count'}
              </PrimaryTitle>
              <SecondaryTitle variant="caption">Create A New Stock Count</SecondaryTitle>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={5} style={{ marginTop: isSmall ? '0' : undefined, marginLeft: isSmall ? '0.3rem' : undefined }}>
            {!isSmall ? (
              <Button
                variant="contained"
                onClick={() => navigate('/stock-count-report')}
                disabled={buttonDisable}
                style={{ marginLeft: '1rem' }}
              >
                Finish
              </Button>
            )
              : (
                <Button
                  variant="outlined"
                  onClick={() => navigate('/stock-count-report')}
                  disabled={buttonDisable}
                  style={{
                    ...(buttonDisable
                      ? {}
                      : { background: '#F4F5F7', color: 'rgba(0, 0, 0, 0.26)', border: 'none' }),
                    marginTop: '1rem',
                    width: '60rem',
                    fontSize: '35px',
                    borderRadius: '20px',
                    height: '5rem',
                    fontWeight: '600'
                  }}
                >
                  Finish
                </Button>
              )}
          </Grid>
        </TitleGridContainer>

        <DetailCard elevation={0} style={{ borderRadius: isSmall ? '30px' : '12px', height: isSmall ? '10rem' : '' }}>
          <DetailCardText
            variant="caption"
            style={{
              marginTop: isSmall ? '1.5rem' : '0', fontSize: isSmall ? '30px' : '16px',
              marginLeft: detailCardStyle
            }}
          >
            {business.name}
          </DetailCardText>
          <DetailCardText
            variant="caption"
            style={{ marginLeft: '20px', marginTop: isSmall ? '1.5rem' : '0', fontSize: isSmall ? '30px' : '16px' }}
          >
            {`- ${renderStockReportStatus(reportStatus)} `}
            <StatusBullet color={handleStockReportStatusColor(reportStatus)} />
          </DetailCardText>
        </DetailCard>

        <NewReport
          batches={batches}
          searchText={searchText}
          handleSearch={handleSearch}
          prodsLoading={loading}
          updateHandler={updateHandler}
          setButtonDisable={setButtonDisable}
          reportLoading={reportLoading}
          searchFieldRef={searchFieldRef}
        />
      </Grid>

      <NewReportDialog
        openDialog={openNewReportDialog}
        setOpenDialog={setOpenNewReportDialog}
        stockReportId={stockReportId}
        business={reportBusinessId || business.id}
        productsWithoutCount={productsWithoutCount}
        reportStatus={reportStatus}
      />
      <SearchPopper
        open={openSearchPopper}
        searchFieldEl={searchFieldRef.current}
        handleClose={handlePopperClose}
        searchedProducts={searchedProducts}
        handleBatchSelection={handleBatchSelection}
      />
      <BatchDialog
        openDialog={openDialog}
        handleCloseDialog={() => setOpenDialog(false)}
        product={batchData}
        handleAddProductToReport={handleAddProductToReport}
        stockReportId={stockReportId}
        batchLoading={batchLoading}
        refetch={refetchBatch}
      />

      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        options={['Cancel', 'Yes, Remove']}
        title="Remove Product"
        desc="Are you sure you want to remove this product?"
        loading={removeLoading}
        setStatus={setConfirmStatus}
      />
    </MainContent>
  );
};

export default NewReportContainer;
