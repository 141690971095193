import SaleActionTypes from './salesTypes';
import {
  clearCart, updateBatchToCart, updateCartQuantity, openBatchModal, toggleMissedSale,
  toggleSelectProductBatch, updateBatchToSellQuantity, aggregateBatchesToCart,
  addOutOfStockBatch, holdSale, returnHeldSale, addProductDiscount, addCartItemPrescription, removeItemFromCart,
  updateCartUnitOfMeasurement, updateBatchQuantityToSell
} from './salesUtils';
import { defaultCustomerData } from './salesState';

// eslint-disable-next-line default-param-last
const saleReducer = (sale = {}, action) => {
  switch (action.type) {
    case SaleActionTypes.RESET_CUSTOMER:
      return {
        ...sale,
        customerModalData: {
          ...sale.customerModalData,
          ...defaultCustomerData
        }
      };
    case SaleActionTypes.UPDATE_CUSTOMER:
      return {
        ...sale,
        customerModalData: {
          ...sale.customerModalData,
          ...action.payload
        }
      };
    case SaleActionTypes.OPEN_BATCH_MODAL:
      return {
        ...sale,
        ...openBatchModal(sale, action)
      };
    case SaleActionTypes.UPDATE_BATCH_TO_CART:
      return {
        ...sale,
        ...updateBatchToCart(sale, action.payload)
      };
    case SaleActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY:
      return {
        ...sale,
        ...updateBatchToSellQuantity(sale, action)
      };
    case SaleActionTypes.UPDATE_BATCH_QUANTITY_TO_SELL:
      return {
        ...sale,
        ...updateBatchQuantityToSell(sale, action)
      };
    case SaleActionTypes.AGGREGATE_BATCHES_TO_CART:
      return {
        ...sale,
        ...aggregateBatchesToCart(sale, action)
      };
    case SaleActionTypes.TOGGLE_MISSED_SALE:
      return {
        ...sale,
        ...toggleMissedSale(sale, action)
      };
    case SaleActionTypes.ADD_PRODUCT_DISCOUNT:
      return {
        ...sale,
        ...addProductDiscount(sale, action.payload)
      };
    case SaleActionTypes.ADD_OUT_OF_STOCK_BATCH:
      return {
        ...sale,
        ...addOutOfStockBatch(sale, action)
      };
    case SaleActionTypes.TOGGLE_SELECT_PRODUCT_BATCH:
      return {
        ...sale,
        ...toggleSelectProductBatch(sale, action.payload)
      };
    case SaleActionTypes.UPDATE_CART_QUANTITY:
      return {
        ...sale,
        ...updateCartQuantity(sale, action.payload)
      };
    case SaleActionTypes.REMOVE_ITEM_FROM_CART:
      return {
        ...sale,
        ...removeItemFromCart(sale, action.payload)
      };
    case SaleActionTypes.UPDATE_CART_UOM:
      return {
        ...sale,
        ...updateCartUnitOfMeasurement(sale, action.payload)
      };
    case SaleActionTypes.CLEAR_CART:
      return {
        ...sale,
        ...clearCart({ ...sale })
      };
    case SaleActionTypes.ADD_PERC_DISCOUNT:
      return {
        ...sale,
        percDiscount: action.payload
      };
    case SaleActionTypes.UPDATE_SALE_STATE:
      return {
        ...sale,
        ...action.payload
      };
    case SaleActionTypes.HOLD_SALE:
      return {
        ...sale,
        ...holdSale(sale, action.payload),
      };
    case SaleActionTypes.RETURN_HELD_SALE:
      return {
        ...sale,
        ...returnHeldSale(sale, action.payload),
      };
    case SaleActionTypes.ADD_CART_ITEM_PRESCRIPTION:
      return {
        ...sale,
        cart: addCartItemPrescription(sale, action.payload),
      };
    default:
      return sale;
  }
};

export default saleReducer;
