import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { Grid } from '@mui/material';
import {
  MainWrapper, CropContainer
} from './cropImageContainer.styles';
import imageUploadActionTypes from '../../../providers/reducers/imageUpload/imageUploadTypes';
import { useStateValue } from '../../../providers/stateProvider';

const CropImageContainer = ({ files }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [{
    imageUpload: { zoomed, imageSrc }
  }, dispatch] = Object.values(useStateValue());

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    dispatch({
      type: imageUploadActionTypes.UPDATE_IMAGE_UPLOADS,
      payload: { croppedAreaPixels }
    });
  }, []);

  const fileType = 'round';
  const readFile = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

  const setDataUrl = async (_files) => {
    const imageDataUrl = await readFile(_files[0]);
    dispatch({
      type: imageUploadActionTypes.UPDATE_IMAGE_UPLOADS,
      payload: {
        imageSrc: imageDataUrl
      }
    });
  };

  useEffect(() => {
    setDataUrl(files);
  }, [files]);

  return (
    <Grid item container>
      <MainWrapper item container>
        <CropContainer>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoomed}
            aspect={1 / 1}
            cropShape={fileType}
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
          />
        </CropContainer>
      </MainWrapper>
    </Grid>
  );
};

CropImageContainer.propTypes = {
  files: PropTypes.instanceOf(Array).isRequired,
};
export default CropImageContainer;
