import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { MeasurementContainer, MenuItemStyled } from './uomPopper.styles';
import { toTitleCase } from '../../../utils/toTitleCase';

export default function UOMPopper({
  row, handleAction
}) {
  const { uom, unitOfMeasurement } = row;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = (list) => list.map((item) => (
    <MenuItemStyled
      key={item}
      onClick={() => { handleAction(item, row); handleClose(); }}
    >
      {item}
    </MenuItemStyled>
  ));

  const renderUnitOfMeasurement = (measurement) => {
    switch (measurement) {
      case 'SACHET':
        return renderMenuItem(['Sachet', 'Pack']);
      case 'UNIT':
        return renderMenuItem(['Unit', 'Pack']);
      default:
        return renderMenuItem(['Pack']);
    }
  };

  return (
    <div>
      <MeasurementContainer
        onClick={handleClick}
      >
        {toTitleCase(uom)}
        <KeyboardArrowDown />
      </MeasurementContainer>
      <Menu
        elevation={0}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {renderUnitOfMeasurement(unitOfMeasurement?.toUpperCase() ?? 'PACK')}
      </Menu>
    </div>
  );
}

UOMPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
  uom: PropTypes.string.isRequired,
};
