import {
  Dialog,
  Grid,
  Typography,
  DialogContent,
  Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const DialogContentStyled = styled(DialogContent)`
  padding: 0;
`;

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px);
    border-radius: 12px;
  }
`;

export const GridWrapper = styled(Grid)`
  padding: 2.5rem 3rem;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 20px;
  margin-bottom: 4px;
`;

export const DialogTitleSubText = styled(Typography)`
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #606060;
`;

export const TField = styled('input')`
  width: 12rem;
  border: 0;
  &:focus {
    border: 0;
    outline: none;
  }
`;

export const FooterSec = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 2.5rem 0 1rem 0;
`;

export const ButtonStyled = styled(Button)`
  width: 170px;
  height: 50px;
  background: #303030;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;

  &:hover {
    background-color: #303030;
  }
`;
