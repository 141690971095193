import {
  Grid, Typography, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainSectionPaper = styled(Paper)`
  border-radius: 12px;
  padding: 24px 0;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 2.9600000381469727px 66.63999938964844px 0px rgba(163, 171, 185, 0.12);
`;

export const UploadGrid = styled(Grid)`
  border-radius: 4px;
  align-items: center;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const HeaderGrid = styled(Grid)`
  padding: 40px;
  padding-top: 0;
`;

export const MainHeaderText = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const SectionHeaderText = styled(Typography)`
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px;
`;

export const SubTextContent = styled(Typography)`
  color: #000;
  font-size: 16px;
  font-weight: 400;
`;

export const SectionContent = styled(Grid)`
  padding: 0 40px;
`;

export const BusinessDetail = styled(Grid)`
  flex-direction: column;
  margin-bottom: 25px;
`;
