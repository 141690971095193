import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  Grid
} from '@mui/material';
import {
// UPDATE_ORDER_INVOICE_BATCHES_MUTATION
} from '../../../mutations/orders';

import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText
} from '../addInvoiceDialog.styles';

const defaultReceived = moment().format('YYYY-MM-DD');
const defaultExp = moment().add(1, 'years').format('YYYY-MM-DD');

const initialState = {
  batchNo: '',
  quantityReceived: null,
  dateReceived: defaultReceived,
  expiryDate: defaultExp,
  orderCost: null,
  supplierName: '',
  supplierId: ''
};

const SingleBatchDialog = ({
  open, onClose, product, supplier, dateReceived,
  batchToEdit
}) => {
  const { brandName } = product;

  const [batch, setBatch] = useState(initialState);

  useEffect(() => {
    setBatch({
      ...batch,
      supplierName: supplier?.name,
      supplierId: supplier?.id,
      dateReceived: moment(dateReceived).format('YYYY-MM-DD')
    });
  }, [open, supplier, dateReceived]);

  useEffect(() => {
    if (batchToEdit) {
      const {
        batchNo, dateRecieved: dR, expiryDate: eD, supplier: { name },
        quantityInStock: quantity, orderCost: unitCost
      } = batchToEdit;
      const date = moment(dR).format('YYYY-MM-DD');
      const expiryDate = moment(eD).format('YYYY-MM-DD');
      const sup = name.split(' ')[0];
      setBatch({
        ...batch, batchNo, quantity, dateReceived: date, expiryDate,
        unitCost, supplier: sup
      });
    }
  }, [batchToEdit]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBatch({ ...batch, [name]: value });
  };

  const handleClose = () => {
    onClose();
    setBatch(initialState);
  };

  // const [updateOrderInvoiceBatches] = useMutation(UPDATE_ORDER_INVOICE_BATCHES_MUTATION);
  // const handleBatch = () => {
  //   const {
  //     supplierName, supplierId, quantityReceived, orderCost, ..._batch
  //   } = batch;
  //   const newBatch = {
  //     supplierId: Number(supplierId), quantityReceived: Number(quantityReceived),
  //     orderCost: Number(orderCost), ..._batch
  //   };
  //   const batches = [newBatch];
  //   if (!batchToEdit) {
  //     updateOrderInvoiceBatches({
  //       variables: {
  //         orderInvoiceId,
  //         clearInvoiceBatchesBeforeUpdate: false,
  //         productBatchMaps: [{ productId: Number(productId), batches }]
  //       }
  //     }).then(({ data: { updateOrderInvoiceBatches: { message } } }) => {
  //       toast.success(message);
  //       if (refetch) refetch();
  //       handleClose();
  //     }).catch((err) => {
  //       toast.error(err?.message);
  //     });
  //   } else {
  //     const { id: batchId } = batchToEdit;
  //     const {
  //       batchNo, dateRecieved, expiryDate, quantity, unitCost
  //     } = batch;
  //     updateBatch({
  //       variables: {
  //         batchId, batchNo, dateRecieved, expiryDate, quantity, unitCost
  //       }
  //     }).then(({ data: { updateBatch: { message } } }) => {
  //       toast.success(message);
  //       if (refetch) refetch();
  //       handleClose();
  //     }).catch((err) => {
  //       toast.error(err?.message);
  //     });
  //   }
  // };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <DialogTitle>{brandName}</DialogTitle>
      <DialogContent>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <TextField
              variant="filled"
              label="Batch No."
              name="batchNo"
              value={batch.batchNo}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              variant="filled"
              label="Quantity Received"
              name="quantityReceived"
              fullWidth
              value={batch.quantityReceived}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="filled"
              label="Supplier"
              name="supplierName"
              value={batch.supplierName}
              disabled
              fullWidth
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="filled"
              label="Date Received"
              type="date"
              name="dateReceived"
              value={batch.dateReceived}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="filled"
              type="date"
              label="Expiry Date"
              name="expiryDate"
              value={batch.expiryDate}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              variant="filled"
              label="Order Cost"
              name="orderCost"
              value={batch.orderCost}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '16px 24px' }}>
        <DialogCancelButton onClick={handleClose}>
          <DialogActionButtonText>
            Cancel
          </DialogActionButtonText>
        </DialogCancelButton>
        <DialogOkButton>
          <DialogActionButtonText>
            {batchToEdit ? 'Edit batch' : 'Add batch'}
          </DialogActionButtonText>
        </DialogOkButton>
      </DialogActions>
    </Dialog>
  );
};

SingleBatchDialog.propTypes = {
  product: PropTypes.shape(Object).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  supplier: PropTypes.string.isRequired,
  dateReceived: PropTypes.string.isRequired,
  batchToEdit: PropTypes.instanceOf(Object).isRequired,
};

SingleBatchDialog.defaultProps = {
  onClose: () => {},
};

export default SingleBatchDialog;
