import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import {
  BodyCell,
  MainTableRow,
  ViewReport,
  StatusColor,
  StatusContainer
} from './returnRow.styles';
import { BlueEyeIcon, FadeBlueEyeIcon } from '../../assets/svgs';
import FailedProductDialog from './failedProductDialog';
import { parseJson } from '../affiliates/utils';
import { GET_AN_UPLOAD } from '../../queries/reports';

const ReturnRow = ({ row, sn }) => {
  const {
    id, status, initiatedBy, dateCreated, fileName
  } = row;

  const { data } = useQuery(GET_AN_UPLOAD, {
    variables: { fileId: +id },
    fetchPolicy: 'network-only'
  });
  const { logs } = data?.csvUpload || {};
  let errorLogs;
  if (typeof parseJson(logs) === 'object') {
    errorLogs = [];
  }

  if (parseJson(logs)?.length) {
    errorLogs = parseJson(logs);
  }

  const name = parseJson(initiatedBy?.profile)?.first_name || 'N/A';
  const dateTime = moment(dateCreated).format('DD MMM YYYY hh:mm A');

  const uploadedDate = moment(dateCreated).format('MMM YYYY');
  const description = `Product uploaded on ${uploadedDate}`;

  const [openDialog, setOpenDialog] = useState(false);
  const toggleHandler = () => setOpenDialog(!openDialog);

  const renderStatus = (_status) => (
    <StatusContainer>
      <StatusColor status={_status} />
      <span>{status?.toLowerCase()}</span>
    </StatusContainer>
  );

  const getStatus = () => renderStatus(status);

  return (
    <>
      <MainTableRow>
        <BodyCell>{sn}</BodyCell>
        <BodyCell>{dateTime}</BodyCell>
        <BodyCell>{fileName}</BodyCell>
        <BodyCell>{name}</BodyCell>
        <BodyCell>{getStatus()}</BodyCell>
        <BodyCell>
          <ViewReport onClick={toggleHandler}>
            <BlueEyeIcon />
            View Report
          </ViewReport>
        </BodyCell>
        <BodyCell />
      </MainTableRow>
      <FailedProductDialog
        openDialog={openDialog}
        toggleHandler={toggleHandler}
        data={errorLogs}
        title={description}
      />
    </>
  );
};
ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  sn: PropTypes.number.isRequired
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
