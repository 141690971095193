import {
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 0px 0px;
  background-color: #fff;
  @media(max-width: 991px) {
    padding: 0px 35px;
  }
`;
