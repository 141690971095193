import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const FooterGrid = styled(Grid)`
  margin: 3vw 0;
  justify-content: center;

  @media (min-width: 992px) {
    margin: 2vw 0;
  }
`;

export const HaveAccountTypo = styled(Typography)`
  font-weight: 400;
  font-size: 1.5rem;
  color: #606060;

  @media (min-width: 992px) {
    font-size: 15px;
  }

  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const VerificationField = styled('input')`
  width: 60px;
  height: 60px;
  border-radius: 6.5px;
  outline: none;
  border: ${({ error }) => (error ? '0.5px solid #E74C3C' : '0.5px solid #78aada')};
  text-align: center;
  font-size: 35px;
  font-weight: 500;

  @media (max-width: 991px) {
    width: 7rem;
    height: 7rem;
    font-size: 60px;
    font-weight: 700;
  }
`;

export const Gap = styled('div')`
  background: #d0d5dd;
  width: 1rem;
  height: 0.2rem;
`;

export const ErrorField = styled(Typography)`
  color: #e74c3c;
  font-size: 11.3px;
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: 1.7rem;
  }
`;
