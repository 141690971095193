import { styled } from '@mui/material/styles';
import {
  Grid, Typography
} from '@mui/material';

export const FooterGrid = styled(Grid)`
  margin: 3vw 0;
  justify-content: center;

  @media (min-width: 992px) {
    margin: 2vw 0;
  }
`;

export const HaveAccountTypo = styled(Typography)`
  font-weight: 500;
  font-size: 1.5rem;
  color: #606060;

  @media (min-width: 992px) {
    font-size: 15px;
  }
`;
