import React from 'react';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';

export const StatisticPaper = styled(Paper)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: 20px;
  border: 1px solid #79797940;
  min-width: 25rem;
  min-height: 15rem;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  @media(min-width: 992px) {
    min-width: 16rem;
    min-height: 5rem;
    border-radius: 7px;
  }
`;

const getGridStyles = ({ admin, diff }) => {
  if (typeof diff !== 'undefined') {
    return css`
      padding: 5px 15px;
    `;
  }
  if (admin) {
    return css`
      padding: 20px 15px;
    `;
  }
  return css`
    padding: 24px 15px;
    @media(max-width: 991px) {
      padding: 5.5rem 4rem;
    }
  `;
};

export const StatisticWrapper = styled(({ ...otherProps }) => (
  <Grid {...otherProps} />
))`
  align-items: center;

  ${getGridStyles}
`;

export const StatisticGrid = styled(Grid)`
  flex-direction: column;
  margin-left: 30px;
`;

export const StatisticHeader = styled(Grid)`
  font-size: 35px;
  color: #424242;
  margin-bottom: 10px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const StatisticContent = styled(Grid)`
  color: #424242;
  font-size: 3.6rem;
  font-weight: 700;
  text-transform: capitalize;
  padding-top: 14px;

  @media(min-width: 992px) {
    font-size: 20px;
    padding-top: 0px;
  }
`;

export const DiffGrid = styled(Grid)`
  color: ${({ diff }) => (diff < 0 ? '#FF4142' : '#3CC480')};
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const DiffImage = styled('img')`
  height: 5px;
  width: 5px;
  margin-right: 2px;

  @media(min-width: 992px) {
    height: 11px;
    width: 11px;
  }
`;

export const IconImage = styled('img')`
  height: 9rem;

  @media(min-width: 992px) {
    height: 50px;
  }
`;
