import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogTitle, Slide, Grid
} from '@mui/material';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, GridWrapper
} from '../suppliers/individual/individualSupplierDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const LogoutDialog = ({
  handleOkay, openDialogue, setOpenDialogue,
}) => (
  <Dialog
    open={(openDialogue)}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setOpenDialogue(false)}
    maxWidth="md"
    fullWidth
    fileslimit={1}
    disableEnforceFocus
  >
    <Grid container>
      <GridWrapper container item xs={12}>
        <DialogTitle id="alert-dialog-slide-title">
          <DialogTitleWrapper container>
            <DialogTitleText>
              Confirm Choices
            </DialogTitleText>
            <DialogTitleSubText>
              Please confirm your choice,
              note that you will be logged out after submitting this information.
            </DialogTitleSubText>
          </DialogTitleWrapper>
        </DialogTitle>

        <DialogActions>
          <DialogCancelButton
            onClick={() => setOpenDialogue(false)}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton onClick={handleOkay}>
            <DialogActionButtonText>
              <DialogActionButtonText>
                Confirm
              </DialogActionButtonText>
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </GridWrapper>
    </Grid>
  </Dialog>
);

LogoutDialog.propTypes = {
  handleOkay: PropTypes.func.isRequired,
  openDialogue: PropTypes.instanceOf(Object),
  setOpenDialogue: PropTypes.func.isRequired
};

LogoutDialog.defaultProps = {
  openDialogue: {}
};

export default LogoutDialog;
