import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, TableRow, Table, TableHead
} from '@mui/material';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import {
  PaperWrapper, TableGrid, TableBox, TableContent, MainTableBody,
  HeaderCell, CustomTableCheckbox
} from './returns.styles';

const Returns = ({
  state, handleChangeReason, handleIncrement, handleDecrement, handleChange, handleBatchUpdate,
  handleChangeProductQuantity, disableField, handleBatchDialog, reviewOrder, handleSelectedId
}) => {
  const [selected, setSelected] = useState([]);

  const handleSelect = (__, id) => {
    const newSelected = [...selected];
    const idx = newSelected.indexOf(id);
    if (newSelected.includes(id)) newSelected.splice(idx, 1);
    else newSelected.push(id);
    setSelected(newSelected);
  };

  useEffect(() => localStorage.setItem('selectedSupplier', JSON.stringify(selected)));
  const loading = false;
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = state.cart.map((product) => product.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const renderSelectAllCheckbox = () => (
    <CustomTableCheckbox
      size="small"
      checked={selected.length === state.cart.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select affiliate' }}
    />
  );
  const headers = [
    renderSelectAllCheckbox(), 'Product Item', 'Qty Delivered', 'Qty Returned', 'Reasons', !disableField && 'Action'
  ];

  return (
    <PaperWrapper elevation={2}>
      <TableGrid item container>
        {loading
          ? <ProductsTableLoader />
          : (
            <TableBox>
              <TableContent component={Paper}>
                <Table aria-label="users table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <HeaderCell key={header}>{header}</HeaderCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <MainTableBody item container>
                    {state.cart.map((business, index) => (
                      <ReturnRow
                        key={business.id}
                        index={index}
                        row={business}
                        selected={selected}
                        handleSelect={handleSelect}
                        handleReasonChange={handleChangeReason}
                        incrementProductQuantity={handleIncrement}
                        decrementProductQuantity={handleDecrement}
                        handleChangeProductQuantity={handleChangeProductQuantity}
                        changeInputQuantity={handleChangeProductQuantity}
                        disableField={disableField}
                        editReturn={state.editReturn}
                        handleBatchDialog={handleBatchDialog}
                        reviewOrder={reviewOrder}
                        handleSelectedId={handleSelectedId}
                        handleChange={handleChange}
                        orderIsReturned={state.orderIsReturned}
                        handleBatchUpdate={handleBatchUpdate}
                      />
                    ))}
                  </MainTableBody>
                </Table>
              </TableContent>
            </TableBox>
          )}
      </TableGrid>
    </PaperWrapper>
  );
};

Returns.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  handleChangeReason: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  handleChangeProductQuantity: PropTypes.func.isRequired,
  disableField: PropTypes.bool.isRequired,
  handleBatchDialog: PropTypes.bool.isRequired,
  reviewOrder: PropTypes.bool,
  handleSelectedId: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBatchUpdate: PropTypes.func.isRequired,
};

Returns.defaultProps = {
  reviewOrder: false
};

export default Returns;
