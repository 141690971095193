import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Grid, TableRow, TableBody, Paper, useMediaQuery, InputAdornment
} from '@mui/material';
import { AFFILIATE_INVOICE_QUERY } from '../../../../queries/affiliates';
import Loader from '../../../shared/loader';
import CustomTableSearchField from '../../../shared/customTableSearchField';
import CTablePagination from '../../../customComponents/cTablePagination';
import {
  TableGridContainer, PaymentColumnHeaderTitle, PaymentRowText, TableContainerWrapper,
  PaymentTableTitle, TableOptionsTab, TableComponent, IconsGridContainer, MainTableHead,
  NoPaymentText, MainTableRow, FooterGrid, InvoiceButton, SearchContainer, SearchTextField, SearchButton, SearchImage,
} from './affiliatesPaymentHistory.styles';
import currencyFormatter from '../../../shared/currencyFormatter';
import { OgaToken } from '../../../../graphql/token';
import MobileReturnRow from './paymentmobileRow';
import SearchIcon from '../../../../assets/images/searchIcon.png';

const AffiliatesPaymentHistory = () => {
  const { id } = useParams();
  const [searchText, setSearchText] = useState('');
  const [showing, setShowing] = useState([1, 10]);

  const initialState = {
    currentPage: 1,
    currentPageCount: 10,
    data: {},
  };
  const [state, setState] = useState(initialState);
  const viewPort = useMediaQuery('(min-width:992px)');

  const {
    currentPage, currentPageCount
  } = state;

  const handleChangePage = (_, newPage) => {
    if (newPage === currentPage) {
      setShowing([showing[0] + 10, showing[1] + 10]);
    } else {
      setShowing([showing[0] - 10, showing[1] - 10]);
    }
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  };

  const {
    loading, error, data
  } = useQuery(AFFILIATE_INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      affiliateId: id,
      pageCount: currentPageCount,
      pageNumber: currentPage,
      ...(searchText && searchText.length >= 3 && { status: searchText }),
    },
  });
  const affiliateInvoices = data?.affiliateInvoices;
  const invoicesTotalNumber = data?.invoicesTotalNumber;

  const handleDownload = (invoiceId) => {
    window.open(
      `${OgaToken.SERVER_LINK}download_partial_invoice/${invoiceId}`,
      '_blank'
    );
  };

  const renderSearchField = () => (
    <SearchContainer item xs={12}>
      <SearchTextField
        variant="outlined"
        label=""
        // defaultValue={searchText}
        onChange={(e) => handleChangeSearchText(e)}
        placeholder="Search by any product parameter"
        fullWidth
        InputProps={{
          startAdornment:
  <InputAdornment
    position="start"
  >
    <SearchButton
      aria-label="Search Order"
      edge="start"
      disabled
    >
      <SearchImage
        alt="search icon"
        src={SearchIcon}
      />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <TableGridContainer container>
      {viewPort ? (
        <TableContainerWrapper component={Paper}>
          <TableOptionsTab container alignItems="center">
            <Grid item>
              <PaymentTableTitle>
                Invoice
              </PaymentTableTitle>
            </Grid>
            <IconsGridContainer item container md={8}>
              <CustomTableSearchField
                id="payment-search"
                searchText={searchText}
                placeholder="Search payments"
                handleChange={(event) => handleChangeSearchText(event)}
              />
            </IconsGridContainer>
          </TableOptionsTab>
          <TableComponent>
            <MainTableHead>
              <TableRow>
                <PaymentColumnHeaderTitle className="mpAdmin-uat-manageAffiliateDetailsPage-date-created">
                  Date Created
                </PaymentColumnHeaderTitle>
                <PaymentColumnHeaderTitle className="mpAdmin-uat-manageAffiliateDetailsPage-order-id">
                  Order ID
                </PaymentColumnHeaderTitle>
                <PaymentColumnHeaderTitle className="mpAdmin-uat-manageAffiliateDetailsPage-amount">
                  Amount
                </PaymentColumnHeaderTitle>
                <PaymentColumnHeaderTitle className="mpAdmin-uat-manageAffiliateDetailsPage-status">
                  Status
                </PaymentColumnHeaderTitle>
                <PaymentColumnHeaderTitle className="mpAdmin-uat-manageAffiliateDetailsPage-credit-days">
                  Credit Days
                </PaymentColumnHeaderTitle>
                <PaymentColumnHeaderTitle className="mpAdmin-uat-manageAffiliateDetailsPage-tax">
                  Tax
                </PaymentColumnHeaderTitle>
                <PaymentColumnHeaderTitle className="mpAdmin-uat-manageAffiliateDetailsPage-invoice-id">
                  Invoice ID
                </PaymentColumnHeaderTitle>
                <PaymentColumnHeaderTitle />
              </TableRow>

            </MainTableHead>
            <TableBody>
              {
                affiliateInvoices && affiliateInvoices.length ? (
                  affiliateInvoices
                    .sort((a, b) => new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf())
                    .map((payment, index) => (
                      <MainTableRow key={payment.reference} style={index % 2 ? { backgroundColor: ' #F4F7FC' } : { backgroundColor: '#FFF' }}>
                        <PaymentRowText>
                          {moment(payment.dateCreated).format('DD-MMM-YYYY')}
                        </PaymentRowText>
                        <PaymentRowText>{payment?.order?.id}</PaymentRowText>
                        <PaymentRowText>{currencyFormatter(payment?.total)}</PaymentRowText>
                        <PaymentRowText>{payment?.status}</PaymentRowText>
                        <PaymentRowText>{payment?.creditDays}</PaymentRowText>
                        <PaymentRowText>{payment?.tax}</PaymentRowText>
                        <PaymentRowText>{payment?.id}</PaymentRowText>
                        <PaymentRowText>
                          <InvoiceButton onClick={() => handleDownload(payment?.id)}>Open</InvoiceButton>
                        </PaymentRowText>
                      </MainTableRow>
                    ))
                ) : (
                  <Grid container>
                    <NoPaymentText>There are no payments for this affiliate.</NoPaymentText>
                  </Grid>
                )
              }
            </TableBody>
          </TableComponent>
        </TableContainerWrapper>
      ) : (
        <>
          <Grid xs={12}>
            <PaymentTableTitle>
              Invoice
            </PaymentTableTitle>
            {renderSearchField()}
          </Grid>
          {affiliateInvoices && affiliateInvoices.length ? (
            affiliateInvoices
              .sort((a, b) => new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf())
              .map((payment) => (
                <MobileReturnRow
                  key={payment?.id}
                  id={payment?.id}
                  total={payment.total}
                  status={payment?.status}
                  dateCreated={payment?.dateCreated}
                  amountPaid={payment?.amountPaid}
                />
              ))
          ) : (
            <Grid container>
              <NoPaymentText>There are no payments for this affiliate.</NoPaymentText>
            </Grid>
          )}
        </>
      )}
      <FooterGrid item container xs={12} justifyContent="flex-end">
        <Grid item container justifyContent="flex-end">
          <CTablePagination
            component="div"
            count={invoicesTotalNumber}
            page={currentPage - 1}
            onChangePage={handleChangePage}
            rowsPerPage={currentPageCount}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </FooterGrid>
    </TableGridContainer>
  );
};

export default AffiliatesPaymentHistory;
