import React, { useState } from 'react';
import Proptypes from 'prop-types';
import Fade from '@mui/material/Fade';
import { TextField, InputAdornment, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { CaretUpIcon, CaretDownIcon } from '../../../assets/svgs';
import {
  BatchDialog, DialogWrapper, SubText, ProductName, DialogHeader, DialogBody, DialogActions,
  CloseIcon, AddButton, InfoText, OutOfStockText, TableHeader, HeaderGrid, MainTableRow,
  BodyCell, CustomInputBase
} from './selectBatchDialog.styles';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import { RenderPopper } from '../../../utils/utils';

import { useStateValue } from '../../../providers/stateProvider';
import ReconciliationActionTypes from '../../../providers/reducers/reconciliation/reconciliationTypes';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

export default function SelectBatchDialog({ handleReconcileOutOfStockBatch }) {
  const [{
    reconciliation: {
      batchModalData: {
        openedBatchModal, batches, product, saleItemBatchId, targetQuantity
      },
    }
  }, dispatch] = Object.values(useStateValue());

  const closeDialog = () => dispatch({
    type: ReconciliationActionTypes.CLOSE_BATCH_MODAL,
    payload: { open: false, product }
  });

  const handleReconcileBatch = () => {
    const batchList = [];
    batches.forEach((batch) => {
      if (batch.qtyToSell) {
        batchList.push({ batchId: batch.orderProductId, qtyToSell: batch.qtyToSell });
      }
    });
    if (batchList.length === 0) {
      return;
    }
    const variables = {
      saleItemBatchId,
      batches: batchList
    };
    handleReconcileOutOfStockBatch(variables);
  };

  const [totalQuantitySold, setTotalQuantitySold] = useState(0);

  const handleChange = (e, batch) => {
    const { value } = e?.target ?? '';
    if (value && +value < 0) return;

    // Calculate the maximum allowed quantity to sell (remaining balance)
    const maxQuantityToSell = targetQuantity - totalQuantitySold;

    // Calculate the new quantity, ensuring it's within the allowed range
    let newQuantity = +value;

    // Ensure the new quantity doesn't exceed the remaining balance
    if (newQuantity > maxQuantityToSell) {
      newQuantity = maxQuantityToSell;
    }

    // Update the quantity
    dispatch({
      type: ReconciliationActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY,
      payload: {
        orderProductId: batch.orderProductId,
        quantity: newQuantity,
      },
    });
  };

  const handleIncrement = (batch) => {
    const { qtyToSell = 0 } = batch;
    if (qtyToSell === batch.qtyInStock) return;
    dispatch({
      type: ReconciliationActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY,
      payload: {
        orderProductId: batch.orderProductId,
        quantity: qtyToSell + 1,
      }
    });
  };

  const handleDecrement = (batch) => {
    const { qtyToSell = 0 } = batch;
    if (qtyToSell <= 0) return;
    dispatch({
      type: ReconciliationActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY,
      payload: {
        orderProductId: batch.orderProductId,
        quantity: qtyToSell - 1,
      }
    });
  };

  const headers = ['S/N', 'Batch #', 'Expiry Date', 'Qty in Stock', 'Qty to Sold'];
  const batchListBtn = [];
  batches.forEach((batch) => {
    batchListBtn.push(batch);
  });

  const truncateText = (text, maxLength) => (text.length > maxLength ? `${text.substring(0, maxLength)}...` : text);

  React.useEffect(() => {
    // Calculate the sum of qtyToSell values in batchList
    const newTotalQuantitySold = batchListBtn.reduce((total, batch) => total + batch.qtyToSell, 0);
    setTotalQuantitySold(newTotalQuantitySold);
  }, [batchListBtn]);

  const renderBatchItems = () => {
    const batchList = [];
    batches.forEach((batch) => {
      batchList.push(batch);
    });
    if (batchList.length === 0) {
      return (
        <DialogBody item container>
          <HeaderGrid item container>
            {headers.map((header) => (
              <TableHeader key={header}>{header}</TableHeader>
            ))}
          </HeaderGrid>
          <Grid justifyContent="center" item container style={{ fontSize: '16px', fontWeight: '700', margin: '2rem 0' }}>
            You have no batch in stock
          </Grid>
        </DialogBody>
      );
    }
    return (
      <DialogBody item container>
        <HeaderGrid item container>
          {headers.map((header) => (
            <TableHeader key={header}>{header}</TableHeader>
          ))}
        </HeaderGrid>
        {batchList?.map((batch, indx) => (
          <MainTableRow key={batch.orderProductId} item container>
            <BodyCell>{indx + 1}</BodyCell>
            <BodyCell>{truncateText(batch?.batchNo, 6)}</BodyCell>
            <BodyCell>{moment(batch?.expiryDate).format('YYYY-MM-DD')}</BodyCell>
            <BodyCell>{batch?.qtyInStock}</BodyCell>
            <BodyCell>
              <CustomInputBase
                type="number"
                placeholder="0"
                min={1}
                size="medium"
                value={batch.qtyToSell}
                onChange={(e) => handleChange(e, batch)}
                endAdornment={(
                  <InputAdornment position="end">
                    <Grid item container direction="column">
                      <CaretUpIcon
                        style={{
                          width: '1rem',
                          height: '1rem',
                          cursor: 'pointer',
                          pointerEvents: totalQuantitySold >= targetQuantity ? 'none' : 'auto',
                          opacity: totalQuantitySold >= targetQuantity ? 0.5 : 1,
                        }}
                        onClick={() => handleIncrement(batch)}
                      />
                      <CaretDownIcon
                        style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                        onClick={() => handleDecrement(batch)}
                      />
                    </Grid>
                  </InputAdornment>
                )}
              />
            </BodyCell>
          </MainTableRow>
        ))}
      </DialogBody>
    );
  };

  return (
    <BatchDialog
      open={openedBatchModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <DialogHeader item container>
          <Grid item container xs={8}>
            <ProductName>
              {product?.brandName && product.brandName.length > 35
                ? `${product?.brandName.substring(0, 35)}...`
                : product?.brandName}
            </ProductName>
            <Grid item container>
              <SubText variant="caption">
                Pack Size:
                &nbsp;
                <b style={{ fontWeight: '500', fontSize: '17' }}>{product?.packSize}</b>
              </SubText>
            </Grid>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <CloseIcon onClick={closeDialog} />
          </Grid>
        </DialogHeader>
        {renderBatchItems()}
        <DialogActions item container>
          <Button
            onClick={closeDialog}
            variant="outlined"
            style={{
              width: '11rem', marginRight: '1.2rem', fontSize: '16px', fontWeight: '700'
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleReconcileBatch}
            disabled={batchListBtn.length === 0}
            style={{ width: '11rem', fontSize: '16px', fontWeight: '700' }}
          >
            Reconcile
          </Button>
        </DialogActions>
      </DialogWrapper>
    </BatchDialog>
  );
}

SelectBatchDialog.propTypes = {
  handleReconcileOutOfStockBatch: Proptypes.func.isRequired
};
