import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { RenderPopper } from '../../../utils/utils';
import {
  CList, CListItem, CListItemButton, CListItemText
} from './actionPopper.styles';

export default function ActionPopper({
  buttonEl, options, handlePopperChange, handleClose
}) {
  const open = Boolean(buttonEl);

  return (
    <RenderPopper
      open={open}
      anchorEl={buttonEl}
      popperPlacement="bottom-start"
      onClickAway={handleClose}
      modifiers={[
        { name: 'offset', options: { offset: [0, 15] } },
      ]}
    >
      <Grid container item>
        <CList>
          {options.map((option) => (
            <CListItem key={option} disableGutters>
              <CListItemButton onClick={() => handlePopperChange(option)}>
                <CListItemText primary={option} />
              </CListItemButton>
            </CListItem>
          ))}
        </CList>
      </Grid>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  buttonEl: PropTypes.bool.isRequired,
  options: PropTypes.shape(Array).isRequired,
  handlePopperChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};
