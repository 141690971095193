import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Grow, Paper, Popper, MenuList, Button
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { MenuItemStyled } from './buttonsPopper.style';
import { useStateValue } from '../../providers/stateProvider';

export default function MoreActionPopper({ actionHandler, productBatchPreference }) {
  const [{
    user: { allowedPermissionsMap: { userPermissions } },
  },] = Object.values(useStateValue());

  const [open, setOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleSubMenuToggle = () => {
    if (!productBatchPreference('batch')) return;
    setOpenSubMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setOpenSubMenu(false);
  };

  const closePoppers = () => {
    setOpen(false);
    setOpenSubMenu(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        className="erp-uat-add-product"
        onClick={handleToggle}
        ref={anchorRef}
        sx={{ marginRight: '.8rem' }}
      >
        More Action
        <ArrowDropDown />
      </Button>
      <Popper
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled className="erp-uat-add-batches-popper" disabled={!userPermissions?.includes('inventory_add_batches')} onClick={handleSubMenuToggle}> Add Batches</MenuItemStyled>
                  <MenuItemStyled className="erp-uat-export-table-csv" onClick={() => { actionHandler('export_table'); closePoppers(); }}> Export Table as CSV</MenuItemStyled>
                  <MenuItemStyled className="erp-uat-bulk-buy" onClick={() => { actionHandler('bulk_buy'); closePoppers(); }}> Bulk Buy</MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        open={openSubMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'right'
            }}
          >
            <Paper style={{ marginLeft: '27rem' }}>
              <ClickAwayListener>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled className="erp-uat-batch-individually" onClick={() => { actionHandler('add_batches_manually'); }}>Add Batches Manually</MenuItemStyled>
                  <MenuItemStyled className="erp-uat-batch-csv" onClick={() => { actionHandler('add_batches'); }}>Add Batches Via CSV</MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

MoreActionPopper.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  productBatchPreference: PropTypes.func.isRequired
};
