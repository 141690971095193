import { styled } from '@mui/material/styles';
import {
  TableCell, Typography, Grid, Paper, IconButton
} from '@mui/material';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 0;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`

`;

export const TableColumnHeaders = styled('div')`
  display: flex;
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #F3F9FF;
  padding: 16px 10px;
  border: 0;
  /**text-transform: capitalize;**/

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: flex-start;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const FooterWrapper = styled(Grid)`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
`;
