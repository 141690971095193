import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Button,
} from '@mui/material';
import CustomInputBase from '../../../customComponents/customInputBase';
import {
  CDialog, CloseIcon, DialogTitleText, DialogTitleSubText, GridWrapper,
} from './vatDialog.styles';
import SuccessDialog from '../../../shared/successDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const VatDialog = ({
  open, toggle, preferenceProps
}) => {
  const {
    loading,
    handleUpdatePreference,
    preferences,
    setSuccessProps,
    createTax
  } = preferenceProps;

  const { vatSetting: { vatName, vatPercent } } = preferences;

  const initialState = {
    taxName: '',
    taxValue: ''
  };

  const initialErrorState = {
    taxNameError: false,
    taxValueError: false,
  };

  const [state, setState] = useState(initialState);
  const { taxName, taxValue } = state;

  useEffect(() => {
    setState((s) => ({ ...s, taxName: vatName, taxValue: vatPercent }));
  }, [vatName, vatPercent]);

  const [errorState, setErrorState] = useState(initialErrorState);
  const { taxNameError, taxValueError } = errorState;

  const handleCloseDialog = () => {
    toggle();
  };

  const handleSave = () => {
    setSuccessProps({
      title: createTax ? 'Tax Created!' : 'Tax Updated!',
      description: `Your have successfully ${createTax ? 'created' : 'updated'} your Tax Value`
    });
    const updatedPreferences = {
      ...preferences,
      vatSetting: { vatName: state.taxName, vatPercent: Number(state.taxValue) }
    };
    handleUpdatePreference(updatedPreferences);
    handleCloseDialog();
  };

  const handleSaveButtonClick = () => {
    if (!taxName.length) {
      return setErrorState({
        ...errorState, taxNameError: true
      });
    }
    if (!taxValue.toString().length) {
      return setErrorState({
        ...errorState, taxValueError: true
      });
    }
    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'taxName':
        return setErrorState({
          ...errorState,
          taxNameError: !value.length
        });
      case 'taxValue':
        return setErrorState({
          ...errorState,
          taxValueError: !value.length
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'taxValue' && value > 100) return;
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'taxName', label: 'Tax Name', placeholder: 'Sales Tax', val: taxName,
      error: taxNameError, helperText: 'Sales tax is required', required: true
    },
    {
      name: 'taxValue', label: 'Tax Value (%)', placeholder: '7.5', error: taxValueError,
      helperText: 'Tax value is required', required: true, val: taxValue
    },
  ];

  const returnTextField = (field, isProtected = false) => {
    const {
      name: fieldName, val, label, placeholder, error, helperText, required
    } = field;
    return (
      <CustomInputBase
        label={label}
        value={val}
        disabled={isProtected}
        size="small"
        type={fieldName === 'taxValue' ? 'number' : 'text'}
        error={error}
        helperText={error && helperText}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
        required={required}
      />
    );
  };

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper>
        <Grid container>
          <Grid item container xs={8}>
            <DialogTitleText>
              {
                createTax ? 'Create Tax Value' : 'Edit Tax Value'
              }
            </DialogTitleText>
            <DialogTitleSubText>
              Complete the fields to create Tax value
            </DialogTitleSubText>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={2} style={{ marginTop: '-1.5rem' }}>
            {topFields.map((field) => (
              <Grid item xs={12} key={field}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
        </Grid>

        <DialogActions style={{ paddingBottom: '2rem' }}>
          <Button
            variant="outlined"
            style={{ width: '100%' }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ width: '100%' }}
            disabled={loading}
            onClick={handleSaveButtonClick}
          >
            {
              createTax ? 'Create' : 'Update'
            }
          </Button>
        </DialogActions>
      </GridWrapper>

      <SuccessDialog
        openDialog={false}
        setOpenDialog={{}}
        title="Tax Created!"
        desc="Your have successfully created a Tax Value"
        option="ok"
      />
    </CDialog>
  );
};

VatDialog.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  preferenceProps: PropTypes.instanceOf(Object).isRequired,
};

VatDialog.defaultProps = {
  open: false,
  toggle: () => null,
};

export default VatDialog;
