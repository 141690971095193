import {
  Grid, Typography, Paper, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeadWrapper = styled(Grid)`
  margin-bottom: 3rem;

  @media(min-width: 992px) {
  }
`;

export const NameGrid = styled(Grid)`

`;

export const ContactGrid = styled(Grid)`
`;

export const DetailPaper = styled(Paper)`
    position: relative;
    border-radius: 8px;
    padding-bottom: 15px;
    min-height: 22.3rem;

    @media(min-width: 992px) {
    border-radius: 6px;
    }
`;

export const SubHeaderText = styled(Typography)`
  color: #A3A3A3;
  padding: 18px 20px;

  @media(max-width: 991px) {
    font-size: 1.4rem
  }
`;

export const LightDivider = styled(Divider)`
  color: #F0F0F0;
`;

export const BottomContainer = styled(Grid)`
  margin: 2rem 3rem 0;

  @media(min-width: 992px) {
    margin: 1.5rem 3rem 0;
  }
`;

export const NameText = styled(Typography)`
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 25px;
    margin-bottom: 20px;
  }
`;

export const NormalText = styled(Typography)`
  font-size: 20px;
  margin-bottom: 10px;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const OrderSubHeader = styled(Grid)`
  margin-bottom: 2rem;
  justify-content: space-between;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 30px 0px;
  align-items: center;

   @media(min-width: 992px) {
    padding: 20px 0px;
  }
`;
