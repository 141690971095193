import { styled } from '@mui/material/styles';
import {
  MenuItem, TableHead, TableRow, TableCell, Box,
  Typography, Grid, Button, Paper
} from '@mui/material';

export const ContainerBody = styled('div')`
  margin-top: 10px;
  padding: 20px 40px;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #ECF6FF;
  font-weight: bold;
  font-size: 30px;
`;

export const TableRowStyled = styled(TableRow)`
    ${({ cond }) => cond && `
        background: #ECF6FF;
    `}
`;

export const TableCellStyled = styled(TableCell)`
  font-weight: bold;
  border-bottom: none
`;

export const TableCellBStyled = styled(TableCell)`
  border-bottom: none;
`;

export const BoxStyled = styled(Box)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  border-bottom: ${({ active }) => (active ? '3px solid #235A91' : '3px solid #ECF6FF')};
  width: ${({ width }) => (width)};
`;

export const Typo = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  padding: 2px 15px;
  color: ${({ active }) => (active ? '#303030' : '#C0C0C1')};
`;

export const ExportButton = styled(Button)`
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e7e8e9;
  padding: 10px;
  width: 100px;
  height: 43px;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
`;

export const LineBox = styled(Box)`
  text-align: right;
  height: 90%;
  width: ${({ width }) => (width)};
`;

export const PaperBox = styled(Paper)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  width: 100%;
  border-radius: 12px;
  height: 30rem;
  padding: 2rem;
  overflow: ${({ filter }) => filter && 'scroll hidden'};

  ::-webkit-scrollbar {
    width: 100%;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    background: #f3f9ff;
  }

  ::-webkit-scrollbar-thumb {
    background: #235a91;
  }
`;
