import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid, TableBody, Button, Box
} from '@mui/material';
import { BackIcon, Return } from '../../../../assets/svgs';
import useSearchParamsState from '../../../helpers/ulrSearchParams';
import MainContent from '../../../customComponents/mainContent';
import { INBOUND_QUEUE_ORDERS_PRODUCTS } from '../../../../queries/orders';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import TablePagination from '../../../shared/tablePagination';
import SingleInboundsFilter from './singleInboundsFilter';
import {
  TitleGrid, TitleTextGridContainer, PrimaryTitle, SecondaryTitle, PaperWrapper, TableGrid, FooterWrapper, MenuButton, CustomCheckbox,
  TableComponent, TableColumnHeaders, TableHeader, FourthTitle, ThirdTitle
} from './singleInboundDetails.styles';
import InboundWalkImage from '../../../../assets/images/InboundWalk.png';

const headers = [
  { name: 'S/N', width: '70px' }, { name: 'Product Name', width: '250px' }, { name: 'Supplier', width: '250px' }, { name: 'Batch No.', width: '200px' },
  { name: 'Qty Received', width: '200px' }, { name: 'UOM', width: '150px' }, { name: 'Pack Size', width: '200px' },
  { name: 'Cost Price', width: '250px' }, { name: 'Expire Date', width: '200px' }, { name: 'Status', width: '150px' }, { name: 'Action', width: '120px' }
];

const SingleInboundDetails = () => {
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [search, setSearch] = useSearchParamsState('search', '');
  const [status, setStatus] = useSearchParamsState('status', '');
  const [searchProductName, setSearchProductName] = useSearchParamsState('searchProduct', '');
  const initialState = {
    option: ''
  };
  const statusEnumMap = {
    MATCHED: 'MATCHED',
    UNMATCHED: 'UNMATCHED',
    ALL: 'ALL',
  };

  const [state, setState] = useState(initialState);

  const navigate = useNavigate();
  const { inboundQueueOrderId, orderId } = useParams();

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      sx={{ color: '#78AADA' }}
    />
  );

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader style={{ width, lineHeight: '40px' }} key={name}>
      {name}
    </TableHeader>
  ));

  const {
    option
  } = state;

  const handleSetFilter = () => {
    setState({
      ...state,
      filters: {
        option
      }
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    ...(status && { matchStatus: statusEnumMap[status] }),
    ...(searchProductName.length >= 3 ? { productName: searchProductName } : { productName: '' }),
    inboundQueueOrderId
  };

  const {
    loading, error, data
  } = useQuery(INBOUND_QUEUE_ORDERS_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  if (error) return <div>{error.message}</div>;

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer container item xs={12} onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
            <Grid item>
              <MenuButton>
                <BackIcon />
              </MenuButton>
            </Grid>
            <Grid item>
              <PrimaryTitle variant="h5">Back</PrimaryTitle>
            </Grid>
          </TitleTextGridContainer>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <SecondaryTitle variant="h5">{orderId ? `Order ${orderId}` : 'xxxxx'}</SecondaryTitle>
            </Grid>
            <Grid item style={{ display: 'flex', gap: '.6rem' }}>
              <Button
                variant="outlined"
                style={{ cursor: 'pointer' }}
              >
                <Return style={{ fontSize: '1.3rem', marginRight: '.2rem' }} />
                Return
              </Button>
              <Button
                variant="contained"
                style={{ cursor: 'pointer' }}
              >
                Add to Inventory
              </Button>
            </Grid>
          </Grid>
        </TitleGrid>
        <Box style={{ marginBottom: '1.5rem' }}>
          <PaperWrapper elevation={0}>
            <Box style={{ margin: '1.5rem', display: 'flex', gap: '1rem' }}>
              <Box marginBottom={4}>
                <img src={InboundWalkImage} alt="success" width={45} />
              </Box>
              <Grid container item>
                <Grid item>
                  <ThirdTitle variant="h5">Inbound Order Walkthrough</ThirdTitle>
                </Grid>
                <Grid item>
                  <FourthTitle variant="h5">
                    The product(s) on this table are undergoing background checks to
                    identify recently ordered products that correspond to items in your current
                    inventory. The statuses will be updated as &quot;matched&quot; when a match is found and
                    &quot;unmatched&quot; when there is no match, or proceed to map manually. Afterwards,
                    you can proceed to add the products to your inventory.
                  </FourthTitle>
                </Grid>
              </Grid>
            </Box>
          </PaperWrapper>
        </Box>
        <PaperWrapper elevation={0}>
          {loading
            ? <ProductsTableLoader />
            : (
              <>
                <Grid item container style={{ marginTop: '3rem' }}>
                  <SingleInboundsFilter
                    search={search}
                    setSearch={setSearch}
                    handleFilterChange={handleFilterChange}
                    handleSetFilter={handleSetFilter}
                  />
                </Grid>

                <TableGrid item container>
                  <TableComponent item container>
                    <TableColumnHeaders item container>
                      <TableHeader style={{ width: '70px', lineHeight: '40px' }}>
                        {renderCheckbox()}
                      </TableHeader>
                      {returnHeaders()}
                    </TableColumnHeaders>
                    <TableBody>
                      {data?.businessInboundQueueOrderProducts.map((_row, indx) => (
                        <ReturnRow
                          key={_row.id}
                          row={_row}
                          rowIndx={indx}
                        />
                      ))}
                    </TableBody>
                  </TableComponent>
                </TableGrid>
              </>
            )}
          <FooterWrapper item container>
            {data?.businessInboundQueueOrderProductsTotalNumber > 0 && (
              <TablePagination
                total={data?.businessInboundQueueOrderProductsTotalNumber}
                pageCount={+pageCount}
                setPageCount={setPageCount}
                pageNumber={+pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default SingleInboundDetails;
