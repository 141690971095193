import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BodyCell, MainTableRow, ConnectedMPIcon, TitleTextGridContainer, PrimaryTitle, SecondaryTitle
} from './returnRow.styles';

const ReturnRow = ({ row }) => {
  const { id, name, lastUpdated } = row;

  return (
    <MainTableRow item container key={id}>
      <BodyCell style={{ marginTop: '1rem' }}><ConnectedMPIcon /></BodyCell>
      <BodyCell>
        <TitleTextGridContainer>
          <PrimaryTitle>
            {name}
          </PrimaryTitle>
          <SecondaryTitle>
            OGApharmacy Marketplace
          </SecondaryTitle>
        </TitleTextGridContainer>
      </BodyCell>
      <BodyCell>
        <TitleTextGridContainer>
          <SecondaryTitle>
            Date Connected
          </SecondaryTitle>
          <SecondaryTitle>
            {moment(lastUpdated).format('D MMM YYYY h:mm a')}
          </SecondaryTitle>
        </TitleTextGridContainer>
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Object)
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
