import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Grow, Paper, Popper, MenuList,
} from '@mui/material';
import { MenuItemStyled } from './csvPopper.styles';

export default function CsvPopper({ action, close, handleDownload }) {
  const open = Boolean(action);

  return (
    <Popper
      style={{ zIndex: '100' }}
      sx={{ zIndex: 1 }}
      open={open}
      anchorEl={action}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
            placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={close}>
              <MenuList id="split-button-menu" autoFocusItem>
                <MenuItemStyled onClick={() => handleDownload('sales_history')}>
                  Download By Sales
                </MenuItemStyled>
                <MenuItemStyled onClick={() => handleDownload('sales_history_by_product')}>
                  Download By Products
                </MenuItemStyled>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

CsvPopper.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.bool.isRequired,
  handleDownload: PropTypes.func.isRequired
};
