import { styled } from '@mui/material/styles';
import {
  Grid, TableCell, Typography, Paper, IconButton,
  CircularProgress, TextField
} from '@mui/material';

export const Spacer = styled('div')`
  margin: 1em 0;
  width: 100%;
`;
export const MainContainer = styled(Grid)`
  background: #F0F0F0;
`;

export const GridContainer = styled(Grid)`
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 20px;
`;

export const TableColumnHeaders = styled('div')`
  display: flex;
  padding: 0 35px;
`;

export const TableColumnHeaderTitle = styled('div')`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 23px;
  white-space: nowrap;
  padding: 10px 0;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const TableColumnRows = styled('div')`
  display: flex;
  margin: 0 35px;
`;

export const TableColumnRowText = styled('div')`
  padding: 10px 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 23px;
  white-space: nowrap;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const TableContainer = styled(Paper)`
  border: 1px solid #eee;
`;

export const TableHeaderCell = styled(TableCell)`
  padding-left: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;
export const TableBodyCell = styled(TableCell)`
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-transform: capitalize;
  padding: 14px;
  padding-left: 0;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
`;

export const TableWrapper = styled('div')`
  overflow: hidden;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-bottom:2rem;
  }
`;

export const OrderCountText = styled(Typography)`
  font-size: 16px;
  color: #4D4F5C;
  margin: 14px 0;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const PaymentStatusTypeContainer = styled('div')`
  width: 2rem;
  height: 1rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;
export const PaymentType = styled('span')`
  font-size: 0.5rem;
  padding: 3px 8px;
`;
export const PaymentStatus = styled('div')`
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
`;

export const FormGrid = styled(Grid)`
  & > * {
    width: calc(50% - 0.75rem);
    margin: 0 1.5rem 1.5rem 0;

    :nth-child(2n) {
      margin-right: 0;
    }
  }
`;

export const InputTextField = styled(TextField)`

`;

const sm = '960px';
export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 3rem;
  align-items: center;
`;

export const TextWrapper = styled(Grid)`
`;

export const ButtonsWrapper = styled(Grid)`
  display: flex;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  color: #424242;

  @media(max-width: 991px) {
    display: none;
  }
`;

export const HeaderIcon = styled('img')`
  width: 60px;

  @media(min-width: 992px) {
    width: 25px;
  }
`;

export const StatisticsGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 35px;
  transition: 500ms;
  flex-wrap: nowrap;
  width: calc(100vw - 500px);
  align-items: center;
  @media(max-width: ${sm}) {
    flex-wrap: wrap ;
    justify-content: space-around
  }
`;

export const StatisticsGridContainerWrapper = styled(Grid)`
  overflow: hidden;
`;

export const OrderSubHeader = styled(Grid)`
  margin-bottom: 1rem;
  justify-content: space-between;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 30px 0px;
  align-items: center;

   @media(min-width: 992px) {
    padding: 20px 0px;
  }
`;

export const ProductItemsTotal = styled(Typography)`
  font-size: 30px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const ProductItemsContainer = styled(Grid)`
  align-items: center;

  @media(max-width: ${sm}) {

  }
`;

export const SearchContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 2rem;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };

  @media(min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 10px;

  & .MuiOutlinedInput-input {
    padding: 25px;
  };
  & .MuiOutlinedInput-root {
    font-size: 2.2rem;
    border-radius: 10px;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };

  @media(min-width: 992px) {
    & .MuiOutlinedInput-input {
      padding: 15px 14px;
    };

    & .MuiOutlinedInput-root {
    font-size: 1rem;
    border-radius: 10px;
  };
  }
`;

export const SearchButton = styled(IconButton)`
  padding: 14px 20px;
  background-color: #FCED50;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #D2C647;
  }

  &:disabled {
    color: #424242;
    background-color: #FCED50;
  }

  @media(min-width: 992px) {
    padding: 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 64px;

  @media(min-width: 992px) {
    width: 30px;
  }
`;

export const ViewCartButton = styled(IconButton)`
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 45px;
  background-color: #FCED50;
  color: #424242;
  border-radius: 10px;
  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 12px 20px;
  }
`;

export const ManageOffersButton = styled(IconButton)`
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 45px;
  color: #424242;
  border: 1px solid;
  border-radius: 10px;
  margin-right: 3rem;

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 49px;
    padding: 12px 25px;
  }
`;

export const ViewCartButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
`;

export const OrderItemsGrid = styled(Grid)`
  margin-bottom: 1rem;ss
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const SupplierCardsContainer = styled(Grid)`
  display:flex;
  @media(min-width: 992px) {
    min-height: calc(100vh - 450px);
  }
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
`;

export const TabImage = styled('img')`
  width: 26px;
`;
