import React from 'react';
import PropTypes from 'prop-types';
import ReturnRow from '../returnRow';
import {
  TableWrapper, TableColumnHeaders, TableColumnHeaderTitle, TableContainer,
  EmptyRow
} from './productBatches.styles';

import GoToTop from '../../customComponents/scrollToTop';

const ProductBatches = ({
  batches, updatingInvoice, handleRemoveBatch,
}) => (
  <>
    <TableWrapper>
      <TableContainer>
        <TableColumnHeaders>
          <TableColumnHeaderTitle style={{ width: '450px' }}>
            Brand Name
          </TableColumnHeaderTitle>
          <TableColumnHeaderTitle style={{ width: '180px' }}>
            Pack Size
          </TableColumnHeaderTitle>
          <TableColumnHeaderTitle style={{ width: '200px' }}>
            Manufacturer
          </TableColumnHeaderTitle>
          <TableColumnHeaderTitle style={{ width: '150px' }}>
            Batch No.
          </TableColumnHeaderTitle>
          <TableColumnHeaderTitle style={{ width: '200px' }}>
            Expiry Date
          </TableColumnHeaderTitle>
          <TableColumnHeaderTitle style={{ width: '180px' }}>
            Quantity
          </TableColumnHeaderTitle>
          <TableColumnHeaderTitle style={{ width: '50px' }} />
        </TableColumnHeaders>

        {batches?.length ? batches.map((row) => (
          <ReturnRow
            key={row}
            row={row}
            updatingInvoice={updatingInvoice}
            handleRemoveBatch={handleRemoveBatch}
          />
        )) : <EmptyRow>Please search for a product to add.</EmptyRow>}
      </TableContainer>
    </TableWrapper>

    {(batches?.length)
      ? <GoToTop /> : ''}
  </>
);

ProductBatches.propTypes = {
  updatingInvoice: PropTypes.func.isRequired,
  handleRemoveBatch: PropTypes.func.isRequired,
  batches: PropTypes.instanceOf(Object),
};

ProductBatches.defaultProps = {
  batches: []
};

export default ProductBatches;
