import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Loader from '../../shared/loader';

import Dispatch from './dispatch';
import { ORDER_QUERY } from '../../../queries/dispatch';

const DispatchContainer = ({ match: { params } }) => {
  const {
    loading, data, refetch
  } = useQuery(ORDER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { orderId: params.id },
  });

  if (loading) return <Loader />;

  const {
    orderDeliveryLocations, businessOrder
  } = data;
  return (
    <Dispatch
      businessDeliveryLocations={orderDeliveryLocations}
      orderData={businessOrder}
      refetch={refetch}
      ordId={params.id}
    />
  );
};

DispatchContainer.propTypes = {
  match: PropTypes.instanceOf(Object)
};

DispatchContainer.defaultProps = {
  match: {},
};

export default DispatchContainer;
