import { TableCell, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';

export const TableColumnRows = styled('div')`
  display: flex;

  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

const matchedStyles = css`
  background-color: #00B588;
`;

const unmatcheddamagedStyles = css`
  background-color: #F29053;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'MATCHED':
      return matchedStyles;
    case 'UNMATCHED':
      return unmatcheddamagedStyles;
    default:
      return null;
  }
};

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  border-radius: 50%;
  width: 9px;
  height: 9px;

  ${getStyles}
`;
