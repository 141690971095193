import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import {
  TableContainerWrapper, TableComponent, PrimaryTitle, TitleTextGridContainer, ButtonsGrid, ConnectMPIcon,
  TableGrid, MainTableBody, HeaderDivider, MainBox, SecondaryTitle, SecondaryTitleSub, GridContainer, GridContainerTitle
} from './connectMarketplace.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import ProcessMarketplaceDialog from './processMarketplaceConnect';

const ConnectMarketplace = ({
  rows, businessMpAccountsTotalNumber, loading, refetch
}) => {
  const [open, setOpen] = useState(false);

  const toggleDialog = () => {
    setOpen(!open);
  };

  return (
    <>
      <Grid item container justifyContent="flex-end" xs={9}>
        <TableContainerWrapper elevation={0}>
          <PrimaryTitle>
            Connect Marketplace
          </PrimaryTitle>
          <HeaderDivider />
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <MainTableBody item container>
                    <MainBox>
                      <GridContainer item container>
                        <GridContainerTitle item container md={10}>
                          <ConnectMPIcon />
                          <TitleTextGridContainer item md={8}>
                            <SecondaryTitle>
                              {businessMpAccountsTotalNumber > 0 ? businessMpAccountsTotalNumber : 'No'}
                              {' '}
                              Linked Marketplace Account
                            </SecondaryTitle>
                            <SecondaryTitleSub>
                              Click the button to proceed to connect to another marketplace
                            </SecondaryTitleSub>
                          </TitleTextGridContainer>
                        </GridContainerTitle>
                        <ButtonsGrid item container md={2}>
                          <Button
                            data-testid="button-connect-mp-sync"
                            variant="contained"
                            onClick={toggleDialog}
                          >
                            Connect Marketplace
                          </Button>
                        </ButtonsGrid>
                      </GridContainer>
                    </MainBox>
                  </MainTableBody>
                  <MainTableBody item container>
                    {rows.map((_row, indx) => (
                      <ReturnRow
                        indx={indx}
                        key={_row.id}
                        row={_row}
                        refetch={refetch}
                      />
                    ))}
                  </MainTableBody>
                </TableComponent>
              )}
          </TableGrid>
        </TableContainerWrapper>
      </Grid>
      <ProcessMarketplaceDialog
        openConfirmation={open}
        handleClose={toggleDialog}
        title="OGApharmacy POS link code"
        discreption="Enter your generated Marketplace POS link code below"
        refetch={refetch}
      />
    </>
  );
};

ConnectMarketplace.propTypes = {
  rows: PropTypes.shape(Array),
  loading: PropTypes.bool,
  refetch: PropTypes.func.isRequired,
  businessMpAccountsTotalNumber: PropTypes.number
};

ConnectMarketplace.defaultProps = {
  rows: [],
  loading: false,
  businessMpAccountsTotalNumber: 0
};

export default ConnectMarketplace;
