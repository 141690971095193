import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useMediaQuery } from '@mui/material';
import CustomSearchField from '../../shared/CustomSearchField';
import ReturnRow from '../returnRow';
import ReturnRowMobileView from '../returnRowMobileView';
import EmptyCount from '../../../assets/images/empty_count.gif';
import {
  TableHeader, EmptyGrid, EmptyImg, ShowingText, CTableBody
} from './newReport.styles';
import {
  TableComponent, MainTableHead, TableGrid, PaperWrapper, SearchGrid
} from '../stockReport.styles';

const headers = [
  'Batch No.', 'Product name', 'Pack size', 'Expiry Date', 'QTY Counted', 'Action'
];

const NewReport = ({
  batches, searchText, searchFieldRef,
  handleSearch, prodsLoading, updateHandler, setButtonDisable, reportLoading
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const finishArr = [];
  const expireDateArr = [];

  useEffect(() => {
    if (finishArr.includes(0) || expireDateArr.includes(null)) setButtonDisable(true);
    else setButtonDisable(false);
  }, [finishArr]);

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header}>{header}</TableHeader>
  ));

  return (
    <Box style={{ width: '100%' }}>
      {!isSmall ? (
        <PaperWrapper elevation={0}>
          <SearchGrid item container>
            <Grid item container xs={2}>
              <ShowingText variant="caption">
                {`Showing ${batches.length} Product(s)`}
              </ShowingText>
            </Grid>
            <Grid item container xs={10} ref={searchFieldRef}>
              <CustomSearchField
                name="search"
                value={searchText}
                loading={prodsLoading}
                placeholder={!batches?.length ? 'Search' : 'Search inventory to add product item'}
                handleChange={handleSearch}
                style={{ width: '100%' }}
              />
            </Grid>
          </SearchGrid>
          <TableGrid item container>
            <TableComponent item container>
              <MainTableHead item container>
                {returnHeaders()}
              </MainTableHead>
              <CTableBody empty={!batches?.length}>
                {batches?.map((row) => {
                  finishArr.push(row?.quantityInStock);
                  expireDateArr.push(row?.batch?.expiryDate);
                  return (
                    <ReturnRow
                      row={row}
                      key={row.batchId}
                      updateHandler={updateHandler}
                      reportLoading={reportLoading}
                    />
                  );
                })}
              </CTableBody>
            </TableComponent>
            {!batches?.length ? (
              <EmptyGrid item container direction="column">
                <EmptyImg
                  alt="Empty stock count report"
                  src={EmptyCount}
                />
                <Grid item style={{ fontWeight: 600, margin: '1rem 0 .8rem' }}>
                  Nothing to display yet!
                </Grid>
                <Grid item style={{ fontSize: '.7rem' }}>
                  Hi there, all search items will be displayed here
                </Grid>
              </EmptyGrid>
            ) : ''}
          </TableGrid>
        </PaperWrapper>
      ) : (
        <>
          <PaperWrapper elevation={0} style={{ borderRadius: '30px' }}>
            <SearchGrid item container>
              <Grid item container xs={12} ref={searchFieldRef}>
                <CustomSearchField
                  name="search"
                  value={searchText}
                  loading={prodsLoading}
                  placeholder={!batches?.length ? 'Search' : 'Search inventory to add product item'}
                  handleChange={handleSearch}
                  style={{ width: '100%', marginBottom: '1rem' }}
                />
              </Grid>
            </SearchGrid>
          </PaperWrapper>
          {!batches?.length ? (
            <EmptyGrid item container direction="column">
              <EmptyImg
                alt="Empty stock count report"
                src={EmptyCount}
                style={{ width: '9rem' }}
              />
              <Grid item style={{ fontWeight: 700, margin: '1rem 0 .8rem', fontSize: '2rem' }}>
                Nothing to display yet!
              </Grid>
              <Grid item style={{ fontSize: '1.5rem' }}>
                Hi there, all search items will be displayed here
              </Grid>
            </EmptyGrid>
          ) : batches?.map((row) => {
            finishArr.push(row?.quantityInStock);
            expireDateArr.push(row?.batch?.expiryDate);
            return (
              <ReturnRowMobileView
                key={row.batchId}
                row={row}
                updateHandler={updateHandler}
                reportLoading={reportLoading}
              />
            );
          })}
        </>
      )}
    </Box>
  );
};

NewReport.propTypes = {
  batches: PropTypes.instanceOf(Object),
  searchFieldRef: PropTypes.instanceOf(Array),
  searchText: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  prodsLoading: PropTypes.bool,
  updateHandler: PropTypes.func.isRequired,
  setButtonDisable: PropTypes.func.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

NewReport.defaultProps = {
  batches: [],
  searchFieldRef: [],
  searchText: '',
  prodsLoading: false,
};

export default NewReport;
