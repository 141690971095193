import React, { useState, useRef } from 'react';
import {
  Box, Button, CircularProgress, Grid,
  useMediaQuery
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  HaveAccountTypo,
  FooterGrid,
  VerificationField,
  Gap, ErrorField
} from './emailVerification.styles';
import { GENERATE_OTP, VERIFY_OTP } from '../../mutations/business';
import NotificationDialog from '../shared/notificationDialog';

const EmailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('ref');

  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrorMsg] = useState(null);

  const inputs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  // Move focus to a specific index
  const moveFocus = (index) => {
    if (index >= 0 && index < inputs.length) {
      inputs[index].current?.focus();
    }
  };

  // Handle input change
  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value.length === 1 && index < 5) {
      moveFocus(index + 1);
    } else if (value.length === 0 && index > 0) {
      moveFocus(index - 1);
    }

    // Update the rest of the inputs if the value is more than one character
    if (value.length > 1) {
      const code = value.slice(0, 6);
      for (let i = 0; i < 6; i++) {
        if (code[i]) {
          inputs[i].current.value = code[i];
          if (i < 5) {
            moveFocus(i + 1);
          }
        } else {
          inputs[i].current.value = '';
        }
      }
    }
  };

  // Handle the Backspace key
  const handleKeyDown = (e, index) => {
    if (
      e.key === 'Backspace'
      && index > 0
      && inputs[index].current?.value === ''
    ) {
      moveFocus(index - 1);
    }
  };

  // Handle paste events
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text');
    const code = pasteData.slice(0, 6);
    for (let i = 0; i < 6; i++) {
      if (code[i]) {
        inputs[i].current.value = code[i];
        if (i < 5) {
          moveFocus(i + 1);
        }
      } else {
        inputs[i].current.value = '';
      }
    }
  };

  const [generateOtp, { loading: otpLoading }] = useMutation(GENERATE_OTP);
  const generateOtpHandler = () => {
    generateOtp({
      variables: { email }
    }).then(({ data: { generateOtp: { message } } }) => {
      toast.success(message);
    }).catch((err) => toast.error(err.message));
  };

  const [verifyOtp, { loading }] = useMutation(VERIFY_OTP);

  const verifyEmail = () => {
    const otp = inputs.map((input) => input.current?.value).join('');
    verifyOtp({
      variables: { otp }
    }).then(() => {
      setOpenDialog(true);
    }).catch((err) => {
      setError(true);
      setErrorMsg('Incorrect verification code, please check again!');
      toast.error(err.message);
    });
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  return (
    <Box mt={-2}>
      <Grid container style={{ width: isSmall ? '100%' : '90%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={1} style={{ marginTop: isSmall && '2rem' }}>
          {inputs.map((input, index) => (
            <React.Fragment key={index}>
              {index === 3 && <Gap />}
              <VerificationField
                error={error}
                type="text"
                maxLength={1}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={(e) => handlePaste(e, index)}
                ref={input}
              />
            </React.Fragment>
          ))}
        </Box>
        <ErrorField>{errMsg}</ErrorField>
        <Button
          disabled={loading}
          onClick={verifyEmail}
          variant="contained"
          style={{
            width: '100%', marginTop: isSmall ? '2.5rem' : '1.5rem', height: isSmall && '6rem', fontSize: isSmall && '2rem'
          }}
        >
          { loading ? <CircularProgress size={18} /> : 'Verify'}
        </Button>

        <FooterGrid item container xs={12} display="flex" alignItems="center" gap={1}>
          <HaveAccountTypo
            variant="subtitle2"
            display="inline"
            style={{ color: 'black' }}
          >
            Didn&apos;t receive the email?
          </HaveAccountTypo>
          <button
            type="button"
            disabled={otpLoading}
            onClick={generateOtpHandler}
            style={{
              fontWeight: 600, color: '#235A91', cursor: 'pointer', border: 'none', background: 'transparent', fontSize: isSmall && '2rem'
            }}
          >
            { otpLoading ? 'loading...' : 'Click to resend'}
          </button>
        </FooterGrid>
      </Grid>
      <NotificationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        action="Ok"
        actionFunc={() => navigate('/')}
        title="Email Verified"
        desc="Your email address has been successfully verified, an email will be sent to you to reset your password once your account is approved"
      />
    </Box>
  );
};

export default EmailVerification;
