import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import currencyAmount from '../../utils/currencyAmount';
import {
  TableColumnRowText, TableColumnRows
} from './returnRow.styles';
import { Product } from '../../providers/reducers/product/classes/Product';

const ReturnRow = ({ row, rowIndx }) => {
  const {
    storeName, totalSales, numberOfSales, grossMargin, cashSales, bankTransferSales, creditSales, cardSales
  } = row;
  const isLarge = useMediaQuery('(min-width:992px)');

  const cells = [
    { name: storeName, width: '280px' }, { name: `₦ ${currencyAmount(totalSales)}`, width: '200px' }, { name: numberOfSales, width: '150px' },
    { name: `${grossMargin.toFixed(2)} %`, width: '200px' }, { name: `₦ ${currencyAmount(cashSales)}`, width: '200px' },
    { name: `₦ ${currencyAmount(bankTransferSales)}`, width: '200px' }, { name: `₦ ${currencyAmount(creditSales)}`, width: '200px' },
    { name: `₦ ${currencyAmount(cardSales)}`, width: '200px' }
  ];

  const returnCell = (value) => value || '-';

  const renderBodyCells = () => cells.map(({ name, width }) => (
    <TableColumnRowText key={name} style={{ width }}>{isLarge ? returnCell(name) : ''}</TableColumnRowText>
  ));

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '70px' }}>{rowIndx + 1}</TableColumnRowText>
      {renderBodyCells()}
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  rowIndx: PropTypes.number.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
