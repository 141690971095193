import { gql } from '@apollo/client';

export const CREATE_SUPPLIER_MUTATION = gql`
  mutation createSupplier(
    $supplierName: String!
    $term: String!
    $logo: String!
    $mobile: String!
    $email: String
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $country: String!
    $providerPromo: String
    $markup: String
  ) {
    createSupplier(
      supplierName: $supplierName
      paymentTerm: $term
      logo: $logo
      mobileNumber: $mobile
      primaryEmailAddress: $email
      addressLineOne: $addressLine1
      addressLineTwo: $addressLine2
      city: $city
      country: $country
      providerPromo: $providerPromo
      markup: $markup
    ){
      message
      supplier {
        id
        status
      }
    }
  }
`;

export const UPDATE_SUPPLIER_MUTATION = gql`
  mutation updateSupplier(
    $supplierId: String!
    $supplierName: String
    $term: String
    $logo: String
    $mobile: String
    $email: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $country: String
    $providerPromo: String
    $markup: String
  ) {
    updateSupplier(
      supplierId: $supplierId
      supplierName: $supplierName
      paymentTerm: $term
      logo: $logo
      mobileNumber: $mobile
      primaryEmailAddress: $email
      addressLineOne: $addressLine1
      addressLineTwo: $addressLine2
      city: $city
      country: $country
      providerPromo: $providerPromo
      markup: $markup
    ){
      message
      supplier {
        id
        status
      }
    }
  }
`;
