import {
  Dialog,
  TableCell,
  TableRow,
  TableHead,
  DialogContent,
  Button,
  InputBase,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: .75rem;
  }
`;

export const FlexDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CommentHeader = styled('span')`
  font-weight: bold;
  font-size: 14px;
`;

export const CommentSubHeader = styled('div')`
  font-size: 13px;
  margin-top: 0.6rem;
  margin-bottom: 1rem;
`;

export const CommentFieldDesc = styled('div')`
  font-size: 13px;
  margin-bottom: 0.3rem;
`;

export const CardHeader = styled('div')`
  margin-bottom: 0.5rem;
`;

export const CardTitle = styled('span')`
  font-weight: 800;
  color: #303030;
  font-size: 16px;
`;

export const CardSubTitle = styled(Typography)`
  font-weight: 400;
  color: #303030;
  font-size: 14px;
  margin-top: .5rem;
  margin-bottom: 1rem;
`;

export const BatchCardTitle = styled(Typography)`
  display: block;
  font-weight: 700;
  color: #303030;
  font-size: 16px;
  margin-top: 2rem 0 0.9rem 0;
`;

export const CartDesc = styled('div')`
  font-size: 14px;
  font-weight: 500;
  color: ##606060;
  margin-top: 0.6rem;
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 5rem;
  border-radius: 6px;
  font-size: 0.8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }
`;

export const TableHeadStyled = styled(TableHead)`
  background: #f4f5f6;
`;

export const TableRowStyled = styled(TableRow)`
  overflow-x: scroll;
`;

export const TableCellStyled = styled(TableCell)`
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
`;

export const TableCellBodyStyled = styled(TableCell)`
  white-space: nowrap;
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 35rem !important;
  padding: 1rem 1.5rem 3rem 1.5rem;
`;

export const DefaultTable = styled('table')`
  width: 100%;
  margin-top: 1rem;
`;

export const DefaultTrHead = styled('tr')`
  background: #F3F9FF;
  height: 3rem !important;
`;

export const DefaultTr = styled('tr')``;

export const DefaultTd = styled('td')`
  font-weight: 700;
  white-space: nowrap;
  font-size: 16px;
  color: #303030;
  padding: 0.6rem 0.8rem !important;
`;

export const DefaultTdStretch = styled('td')`
  font-weight: 700;
  font-size: 16px;
  color: #303030;
  white-space: nowrap;
  padding: 0.6rem 0.8rem !important;
  width: 20rem !important;
`;

export const DefaultTdBody = styled('td')`
  font-size: 14px;
  font-weight: 500;
  color: #606060;
  padding: 0.6rem 0.8rem !important;
  outline: none !important;
`;

export const NotFound = styled('div')`
  width: 100%;
  padding: 2rem 0;
  text-align: center;
`;

export const ButtonDarkStyled = styled(Button)`
  width: 170px;
  height: 40px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #fff !important;
`;

export const TextAreaStyled = styled('textarea')`
  width: 100%;
  height: 10rem;
  border: 0.5px solid #d8d8d8;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 13px;
  &:focus {
    outline: none;
    border: 0.5px solid #000;
  }
`;

export const DialogActionsStyled = styled('div')`
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
`;

export const ButtonGrayStyled = styled(Button)`
  width: 170px;
  height: 40px;
  background: #c0c0c1 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #fff !important;
`;

export const FooterButtonStyled = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 1rem;
  margin-bottom: -1rem !important;
`;
