import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomSearchField from '../../shared/CustomSearchField';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';

const Filter = ({
  state, handleSubmit, handleSearch
}) => {
  const { searchText } = state ?? '';

  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item xs={10}>
        <CustomSearchField
          name="search"
          value={searchText}
          fullWidth
          placeholder="Search"
          handleChange={(e) => handleSearch(e?.target?.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <CustomDateRanges
          handleSubmit={handleSubmit}
          xs={12}
          styles={{ height: '3rem', width: '100%', fontSize: '11.8px' }}
        />
      </Grid>
    </Grid>
  );
};

Filter.propTypes = {
  state: PropTypes.shape(Object),
  handleSubmit: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  state: {}
};

export default Filter;
