import {
  Grid, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
  padding: 5px 6rem 30px 6rem;
  @media(max-width: 1320px) {
    padding: 5px 2rem 30px 2rem;
  }

  @media(max-width: 1025px) {
  padding: 5px .5rem 30px .5rem;
  }
`;

export const GridWrapper = styled(Grid)`
  background-color: #F5F5F7;
  @media(max-width: 991px) {
    padding-top: 1.4rem;
  }
`;

export const InnerContainer = styled(Grid)`
  margin: 0 2.5rem 2.5rem;
  @media(max-width: 1320px) {
    margin: 2rem 1.5rem 1.5rem;
  }
`;

export const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 20px;
`;

export const FiltersGrid = styled(Grid)`
  padding: 1.8rem 0;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 2rem 0;
  }
`;
