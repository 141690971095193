import React, { useState } from 'react';
import {
  DialogContent,
  Slide,
  Box,
  Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  DialogStyled,
  DialogContentStyled,
  FooterStyled,
  ComplianceContainer,
  ComplianceTitle,
  ComplianceDescription,
  CheckboxStyled
} from './complianceDialog.style';
import { ComplianceIcon } from '../../assets/svgs';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ComplianceDialog = ({
  openDialog, setOpenDialog, ndpr, setNdpr, setProceed
}) => {
  const handleClose = async () => {
    setOpenDialog(false);
    setNdpr(false);
  };

  const ndprHandler = (e) => {
    setNdpr(e.target.checked);
  };

  const proceedHandler = () => {
    if (ndpr) {
      setProceed(true);
    }
  }

  return (
    <DialogStyled
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem' }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: 0, padding: 0 }}>
          <ComplianceIcon style={{ fontSize: '100px' }} />
          <ComplianceContainer>
            <ComplianceTitle>NDPR Compliance</ComplianceTitle>
            <Box sx={{ display: 'flex', marginTop: '0.8rem' }}>
              <CheckboxStyled checked={ndpr} onChange={ndprHandler} />
              <ComplianceDescription>
                By checking the box, you acknowledge that you have obtained permission to share the above customer&apos;s information with us as the software provider.
              </ComplianceDescription>
            </Box>
          </ComplianceContainer>
        </DialogContent>
        <FooterStyled>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ width: '100%' }}
          >
            Cancel
          </Button>
          <Button
            disabled={!ndpr}
            onClick={proceedHandler}
            variant="contained"
            style={{ width: '100%' }}
          >
            Proceed
          </Button>
        </FooterStyled>
      </DialogContentStyled>
    </DialogStyled>
  );
};

ComplianceDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  ndpr: PropTypes.bool.isRequired,
  setNdpr: PropTypes.func.isRequired,
  setProceed: PropTypes.func.isRequired,
};

ComplianceDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
};

export default ComplianceDialog;
