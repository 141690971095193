import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress
} from '@mui/material';
import {
  BodyCell, MainTableRow, InputField, MenuItemStyled,
  SelectStyled
} from './returnRow.styles';
import { DeleteIcon } from '../../../assets/svgs';
import { blockInvalidChar } from '../../../utils/mobileCheck';
import UOMPopper from './uomPopper';

const ReturnRow = ({
  row, index, removeRowHandler, poLoading, handleOnChangeValue, switchUOM, handleDate, allCategoriesData
}) => {
  const {
    sn, sku, productName, dateReceived, supplier, quantity, uomInPack, batchNo, orderCost, expiryDate, pricingCategory, sellingPrice
  } = row;
  const today = new Date().toISOString().split('T')[0];

  const categories = allCategoriesData || [];

  return (
    <MainTableRow>
      <BodyCell>{index}</BodyCell>
      <BodyCell>
        <InputField type="date" value={dateReceived} onChange={(e) => handleDate(e, 'dateReceived', sn)} />
      </BodyCell>
      <BodyCell>
        {`${sku || 'N/A'}`}
      </BodyCell>
      <BodyCell>{productName}</BodyCell>
      <BodyCell>{supplier}</BodyCell>
      <BodyCell>
        <SelectStyled
          value={pricingCategory}
          defaultValue={pricingCategory}
          onChange={(e) => handleOnChangeValue(e, 'pricingCategory', row)}
        >
          {
              categories?.map((category) => {
                const { categoryName, id } = category;
                return (
                  <MenuItemStyled key={id} value={categoryName}>{categoryName}</MenuItemStyled>
                );
              })
            }
        </SelectStyled>
      </BodyCell>
      <BodyCell>
        ₦
        <InputField
          value={sellingPrice}
          type="number"
          onKeyDown={blockInvalidChar}
          onChange={(e) => handleOnChangeValue(e, 'sellingPrice', row)}
        />
      </BodyCell>
      <BodyCell>
        <UOMPopper handleAction={switchUOM} row={row} />
      </BodyCell>
      <BodyCell style={{ maxWidth: '4.5rem' }}>
        <InputField
          type="number"
          onKeyDown={blockInvalidChar}
          value={quantity}
          onChange={(e) => handleOnChangeValue(e, 'quantity', row)}
        />
      </BodyCell>
      <BodyCell>{uomInPack}</BodyCell>
      <BodyCell style={{ maxWidth: '5rem' }}>
        #
        <InputField
          value={batchNo}
          onChange={(e) => handleOnChangeValue(e, 'batchNo', row)}
        />
      </BodyCell>
      <BodyCell style={{ maxWidth: '5rem' }}>
        ₦
        <InputField
          type="number"
          value={orderCost}
          onKeyDown={blockInvalidChar}
          onChange={(e) => handleOnChangeValue(e, 'orderCost', row)}
        />
      </BodyCell>
      <BodyCell>
        <InputField type="date" value={expiryDate} onChange={(e) => handleDate(e, 'expiryDate', sn)} min={today} />
      </BodyCell>
      <BodyCell>
        {
          poLoading
            ? <CircularProgress size={25} />
            : <DeleteIcon style={{ cursor: 'pointer', pointerEvents: poLoading ? 'none' : '' }} onClick={() => removeRowHandler(sn)} />
        }
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  removeRowHandler: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  poLoading: PropTypes.bool,
  handleOnChangeValue: PropTypes.func.isRequired,
  switchUOM: PropTypes.func.isRequired,
  handleDate: PropTypes.func.isRequired,
  allCategoriesData: PropTypes.instanceOf(Array)
};

ReturnRow.defaultProps = {
  poLoading: false,
  allCategoriesData: []
};

export default ReturnRow;
