import { TableCell, Typography, Box } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';
import CustomButton from '../../customComponents/customButton';

export const TableColumnRows = styled('div')`
  display: flex;

  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const ViewContainer = styled(Box)`
  padding-top: 0.2rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  cursor: pointer;
`;

export const ViewText = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  color: #235A91;
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const DropDownArrow = styled(ArrowDropDown)`
  font-size: 2rem;
  width: 18%;
  margin-left: 2%;
`;

export const TCustomButton = styled(CustomButton)`
  color: #606060;
  border: 1px solid #dadada;
  box-shadow: 0px 4px 14px rgb(219 219 219 / 19%);
  border-radius: 4px;
  height: 2.5rem;
  width: 100%;

  & .label {
    font-weight: 500;
    font-size: 10px;
  }
`;

const expiredStyles = css`
  background-color: #FF0F0F;
`;

const sellableStyles = css`
  background-color: #00B588;
`;

const damagedStyles = css`
  background-color: #FF0F0F;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'expired':
      return expiredStyles;
    case 'damaged':
      return damagedStyles;
    default:
      return sellableStyles;
  }
};

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`;

export const Status = styled('div')`
  border-radius: 50%;
  width: 9px;
  height: 9px;

  ${getStyles}
`;
