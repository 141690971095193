import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  BodyCell,
  MainTableRow,
  OrderListButton,
  StatusColor,
  StatusContainer
} from './returnRow.styles';
import { CustomCheckbox } from './lowOnStock.styles';
import AppToast from '../shared/appToast';
import { UPDATE_ORDER_LIST } from '../../mutations/products';

const ReturnRow = ({
  row, sn, handleSelect, selected
}) => {
  const {
    productId,
    sku,
    brandName,
    quantityInStock,
    status,
    packSize
  } = row;

  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: '',
    description: ''
  });

  const renderStatus = (_status) => (
    <StatusContainer>
      <StatusColor status={_status} />
      <span>{status === 'LOW' ? 'Low on stock' : 'Out of stock'}</span>
    </StatusContainer>
  );

  const getStatus = () => renderStatus(status);

  const isSelected = () => selected.indexOf(productId) !== -1;

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, productId);
      }}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  const [updateOrderList, { loading }] = useMutation(UPDATE_ORDER_LIST);

  const addToOrderList = () => {
    updateOrderList({
      variables: { orderListProducts: [{ productId, quantity: null }] },
      refetchQueries: ['businessOrderListProductsTotalNumber']
    }).then(() => {
      setToastMessage({
        title: 'Product Added to your Order List',
        description: 'Your out of stock product have been successfully added to your Order List'
      });
      setOpenToast(true);
    }).catch((err) => toast.error(err.message));
  };

  return (
    <>
      <MainTableRow>
        <BodyCell>{renderCheckbox()}</BodyCell>
        <BodyCell>{sku}</BodyCell>
        <BodyCell>{brandName}</BodyCell>
        <BodyCell>{packSize}</BodyCell>
        <BodyCell>{quantityInStock}</BodyCell>
        <BodyCell>{getStatus()}</BodyCell>
        <BodyCell><OrderListButton onClick={addToOrderList} disabled={loading}>{loading ? 'loading ...' : 'Add to Order List' }</OrderListButton></BodyCell>
      </MainTableRow>
      <AppToast openToast={openToast} setOpenToast={setOpenToast} toastMessage={toastMessage} isWarning={false} />
    </>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  sn: PropTypes.number.isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
