import {
  Grid, Typography, IconButton, Box, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModelGridContainer = styled(Grid)`
  padding: 30px;
`;

export const DivFullWidth = styled('div')`
  width: 100%;
`;

export const CategoryTab = styled(Paper)`
  margin-top: 50px;
  margin-bottom: 30px;
  height: 85px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  display: flex;
  gap: 10px;
  padding: 1.2rem;
`;

export const ModelsTab = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;
  margin-top: 50px;
  margin-bottom: 30px;

  @media (min-width: 992px) {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }
`;

export const ModelsNumber = styled(Typography)`
  font-size: 18px;
  color: #606060;
  font-weight: 600;

  @media (min-width: 992px) {
    font-size: 16px;
    font-weight: 500;
    color: #606060;
  }
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const ModelCardsContainer = styled(Grid)`
  display: flex;
  @media (min-width: 992px) {
    min-height: calc(100vh - 450px);
  }
`;

export const ModelCardGrid = styled(Grid)`
  @media (max-width: 776px) {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-bottom: 30px;
  justify-content: flex-end;
`;

export const OrderItemsGrid = styled(Grid)`
  margin-bottom: 30px;

  @media (max-width: 960px) {
    margin-bottom: 10px;
  }
`;

export const OrderCountText = styled(Typography)`
  font-size: 22px;
  color: #4d4f5c;
  margin: 14px 0;
  margin-bottom: 20px;
  font-size: 15px;
  @media (min-width: 992px) {
    font-size: 18px;
  }
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
`;

export const TabImage = styled('img')`
  width: 26px;
`;

export const GridContainer = styled(Grid)`
  padding: 15px 25px;
  width: 25rem;
  align-items: center;
  cursor: pointer;

  @media (min-width: 992px) {
    padding: 0px;
    width: 231px;
  }
`;

export const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 2rem;

  @media (min-width: 992px) {
    margin-left: 0px;
    font-size: 1.2rem;
    padding: 6px 12px;
  }
`;

export const ItemWrapper = styled(IconButton)`
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const ModelNumberBox = styled(Box)`
  width: 12rem;
  word-wrap: nowrap;
  height: 2.938rem;
  background: #F3F9FF;
  border-radius: 5px;
  padding: .8rem 0;
  text-align: center;
`;

export const SortBox = styled(Box)`
  width: 5.5rem;
  height: 2.938rem;
  background: #FFFFFF;
  border: 1px solid #235A91;
  border-radius: 8px;
  display: flex;
  padding: .8rem;
`;

export const SortImg = styled('img')`
  height: 20px;
  width: 20px;
  fill: #235A91;
`;

export const SortText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  color: #235A91;
  opacity: .5;
`;

export const ExportBox = styled(Box)`
  width: 6.8rem;
  height: 2.938rem;
  background: #FFFFFF;
  border: 1px solid #235A91;
  border-radius: 8px;
  display: flex;
  padding: .8rem;
  margin-left: auto;
`;
