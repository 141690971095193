import {
  TableCell, Typography, TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';

export const MainTableRow = styled(TableRow)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1) {
      width: 26%;
    }
    &:nth-child(2), &:nth-child(3), :nth-child(4), :nth-child(5) {
      width: 12%;
    }
    :nth-child(6), :nth-child(7) {
      width: 9%;
    }
    &:nth-child(8) {
      width: 8%;
    }
  }
`;





// export const ColorGrid = styled(Grid)`
//   border-radius: 7px;
//   text-transform: capitalize;
//   font-size: .85rem;
//   font-weight: 500;
//   justify-content: center;
//   padding: 1px 0;

//   ${getStyles}
// `;

export const PromoSign = styled(Typography)`
  font-size: .75rem;
  font-weight: 500;
  justify-content: center;
  border-radius: 5.778px;
  background-color: #ECF6FF;
  color: #235A91;
  padding: 5px 7px;
  align-items: center;
`;

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const SoldOutStyles = css`
  background-color: #FBF777;
`;

const activeStyles = css`
  background-color: #00B588;
`;

const inactiveStyles = css`
  background-color: #FF0F0F;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'active':
      return activeStyles;
    case 'inactive':
      return inactiveStyles;
    default:
      return SoldOutStyles;
  }
};

export const Status = styled('div')`
  border-radius: 50%;
  width: 9px;
  height: 9px;

  ${getStyles}
`;
