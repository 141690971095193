import {
  Popper,
  Grid,
  ListItemText,
  Paper,
  Typography,
  ListItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CPopper = styled(Popper)`
  z-index: 15000000;
`;

export const ListItemStyled = styled(ListItem)`
  &:hover {
    background: #f3f9ff;
  }
`;

export const PaperWrapper = styled(Paper)`
  width: 9rem;
  padding: 1rem 0;
  box-shadow: -1px 2px 8px 1px #00000017;
  transition: transform 0.3s;
  z-index: 500;
`;

export const CListItemText = styled(ListItemText)`
  margin: 0;
`;

export const TextGrid = styled(Grid)``;

export const Typo = styled(Typography)`
  font-size: 0.9rem;
  color: #606060;
  text-transform: capitalize;
`;
