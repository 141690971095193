import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import currencyFormatter from '../../../utils/currencyFormatter';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { ColorField, Status } from './returnRow.styles';

const ReturnRow = ({ row }) => {
  const navigate = useNavigate();

  const {
    supplier, id, order, lastUpdated, status
  } = row;

  const getColorGrid = (bkg, name) => (
    <ColorField>
      <Status background={bkg} />
      {name}
    </ColorField>
  );

  const getStatus = () => {
    if (status === 'MANUAL_SUPPLIER_ORDER_RECONCILED') return getColorGrid('#FF0F0F', 'Closed orders');
    return getColorGrid('#00B588', 'Open orders');
  };

  const orderVolume = row?.orderproductSet.reduce((previous, current) => previous + current.quantity, 0);
  const orderAmount = row?.orderproductSet.reduce((previous, current) => previous + (current.quantity * current.unitCost), 0);
  const renderDate = () => moment(lastUpdated).format('DD/MM/YYYY HH:mm');

  const handleRoute = () => {
    if (status === 'MANUAL_SUPPLIER_ORDER_RECONCILED') return navigate(`/closed-order/${id}`);
    return navigate(`/receive-manual-order/${id}`, { state: { orderId: order?.id } });
  };

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '190px' }}>{renderDate()}</TableColumnRowText>
      <TableColumnRowText style={{ width: '210px' }}>{supplier?.name}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '170px' }} onClick={handleRoute}>{`#${id}`}</TableColumnRowText>
      <TableColumnRowText style={{ width: '18px' }}>{`#${order?.id}`}</TableColumnRowText>
      <TableColumnRowText style={{ width: '250px' }}>{orderVolume}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{currencyFormatter(orderAmount)}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{getStatus()}</TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
