import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#235A91',
    },
    secondary: {
      main: '#78AADA',
    },
    error: {
      main: '#BA1A1A',
    },
    text: {
      primary: '#303030',
      secondary: '#606060'
    },
    background: {
      default: '#F8FBFF'
    }
  },

  typography: {
    fontFamily: '"Montserrat", "medium", "sans-serif"',
    button: {
      textTransform: 'capitalize'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-contained': {
            border: 'none',
            borderRadius: '5px',
            fontWeight: 700,
            fontSize: '12px',
            boxShadow: '0px 4px 14px rgba(151, 151, 151, 0.19)',
            padding: '10px 0',
            width: '178px',
            height: '48px',

            '&.Mui-disabled': {
              color: '#FFFFFF',
              backgroundColor: '#78AADA',
            }
          },
          '&.MuiButton-outlined': {
            color: '#235A91',
            border: '1.5px solid',
            borderRadius: '5px',
            fontWeight: 700,
            fontSize: '12px',
            boxShadow: '0px 4px 14px rgba(151, 151, 151, 0.19)',
            padding: '10px 0',
            width: '178px',
            height: '48px',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#FFFFFF'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#A3A3A3'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#78AADA'
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: '10000000 !important',
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: '10000001 !important',
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: '10000002 !important',
        },
        tooltip: {
          fontSize: '1rem'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 425,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1400
    }
  },
  mixins: {
    appBar: {
      minHeight: '100px'
    }
  },
});

export default theme;
