import React, { useState } from 'react';
import Proptypes from 'prop-types';
import Fade from '@mui/material/Fade';
import {
  Checkbox,
  Dialog, TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useStateValue } from '../../../providers/stateProvider';
import {
  ButtonContainer,
  BatchDialogHeader,
  PackSize,
  ProductName,
  BatchDialogBody,
  ColumnHeader,
  BatchItem,
  Input,
  CheckIconContainer,
  CheckIcon,
  CloseIcon,
} from '../selectProductBatchDialog.styles';
import ReconciliationActionTypes from '../../../providers/reducers/reconciliation/reconciliationTypes';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

export default function SelectReconciliationProductBatchDialog({ handleReconcileOutOfStockBatch }) {
  const [{
    reconciliation: {
      batchModalData: {
        openedBatchModal, batches, product, saleItemBatchId
      },
    }
  }, dispatch] = Object.values(useStateValue());

  const handleReconcileBatch = () => {
    const batchList = [];
    batches.forEach((batch) => {
      if (batch.qtyToSell) {
        batchList.push({ orderProductId: batch.orderProductId, qtyToSell: batch.qtyToSell });
      }
    });
    if (batchList.length === 0) {
      return;
    }
    const variables = {
      saleItemBatchId,
      batches: batchList
    };
    handleReconcileOutOfStockBatch(variables);
  };
  const handleChangeQty = ({ target: { value } }, batch, min = 0) => {
    let targetValue = +value;
    if (min && +value < min) {
      targetValue = +min;
    }
    dispatch({
      type: ReconciliationActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY, payload: {
        orderProductId: batch.orderProductId,
        quantity: targetValue
      }
    });
  };
  const renderBatchItems = () => {
    const batchList = [];
    batches.forEach((batch) => {
      batchList.push(batch);
    });
    if (batchList.length === 0) {
      return <Grid style={{ textAlign: 'center' }}>You have no batch in stock</Grid>;
    }
    return batchList.map((batch) => (
      <BatchItem key={batch.orderProductId} container direction="row">
        <Grid item xs={1} alignItems="center" justifyContent="center">
          <Checkbox
            onClick={() => {
              dispatch({
                type: ReconciliationActionTypes.TOGGLE_SELECT_PRODUCT_BATCH, payload: {
                  orderProductId: batch.orderProductId
                }
              });
            }}
            name="terms"
            color="primary"
            checked={batch.checked}
          />
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader item>Batch #</ColumnHeader>
          <Grid item>{batch.batchNo}</Grid>
        </Grid>
        <Grid item xs={2}>
          <ColumnHeader item>Expiry date</ColumnHeader>
          <Grid item>{batch.expiryDate}</Grid>
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader item>Qty in stock</ColumnHeader>
          <Grid item>{batch.qtyInStock}</Grid>
        </Grid>
        {batch.checked && (
        <Grid item xs={3}>
          <ColumnHeader item style={{ whiteSpace: 'nowrap' }}>Qty to reconcile</ColumnHeader>
          <Grid item>
            <Input
              min={0}
              size="medium"
              value={`${(batch.qtyToSell > 0) && (batch.qtyToSell < 10) ? '0' : ''}${batch.qtyToSell}`}
              onBlur={(event) => handleChangeQty(event, batch, 1)}
              onChange={(event) => handleChangeQty(event, batch)}
            />
          </Grid>
        </Grid>
        )}
      </BatchItem>
    ));
  };
  return (
    <Dialog
      open={openedBatchModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => ({})}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
    >
      <BatchDialogHeader>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <ProductName>{product?.brandName}</ProductName>
            <PackSize item>
              Pack Size:
              {' '}
              {product?.packSize}
            </PackSize>
          </Grid>
          <ButtonContainer item>
            <Grid container direction="row" justifyContent="space-between">
              <CheckIconContainer onClick={() => dispatch({ type: ReconciliationActionTypes.CLOSE_BATCH_MODAL, payload: { open: false, product } })}>
                <CloseIcon />
              </CheckIconContainer>
              <CheckIconContainer onClick={handleReconcileBatch}>
                <CheckIcon />
              </CheckIconContainer>
            </Grid>
          </ButtonContainer>
        </Grid>
      </BatchDialogHeader>
      <BatchDialogBody>
        {renderBatchItems()}
      </BatchDialogBody>
    </Dialog>
  );
}

SelectReconciliationProductBatchDialog.propTypes = {
  handleReconcileOutOfStockBatch: Proptypes.func.isRequired
};
