import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { Label, FormSelectStyled } from '../fields.styles';

const SelectField = ({ fields }) => {
  const { label, options, required } = fields;
  return (
    <>
      <Label>
        {label ?? ''}
        {' '}
        {required && <span style={{ color: 'red' }}>*</span>}
      </Label>
      <FormSelectStyled disableUnderline {...fields}>
        {options?.map((option) => (
          <MenuItem key={option?.id} value={option?.id}>
            {option?.name}
          </MenuItem>
        ))}
      </FormSelectStyled>
    </>
  );
};

SelectField.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired
};
export default SelectField;
