import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { ColorField, Status } from './prescription.styles';

const ReturnRow = ({ row }) => {
  const {
    saleItem,
    dateIssued,
    businessUser,
    dosePerDay,
    usageDays,
    tabletsPerUnit,
    // numTablets,
    // unit,
    status
  } = row;

  const { fullName } = businessUser;
  const { product } = saleItem;

  const getStatus = () => {
    switch (status) {
      case 'Exhausted': return (
        <ColorField>
          <Status />
          Exhausted
        </ColorField>
      );
      case 'In-Use': return (
        <ColorField>
          <Status active />
          In-Use
        </ColorField>
      );
      default:
        break;
    }
  };

  const returnDate = () => moment(dateIssued).format('DD/MM/YYYY');

  return (
    <TableColumnRows>
      <TableColumnRowText>{product?.brandName || 'N/A'}</TableColumnRowText>
      <TableColumnRowText>{fullName || 'N/A'}</TableColumnRowText>
      <TableColumnRowText>{returnDate()}</TableColumnRowText>
      <TableColumnRowText>{tabletsPerUnit || 'N/A'}</TableColumnRowText>
      <TableColumnRowText>{dosePerDay || 'N/A'}</TableColumnRowText>
      <TableColumnRowText>{usageDays || 'N/A'}</TableColumnRowText>
      <TableColumnRowText>{getStatus()}</TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

export default ReturnRow;
