import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusesGrid, UnderlineGrid, Typo
} from './tabsButtons.styles';

const TabsButtons = ({ active, handleStatusValueChange }) => (
  <StatusesGrid container item>
    <UnderlineGrid
      item
      onClick={() => handleStatusValueChange('open')}
      active={active === 'open'}
      width="12%"
    >
      <Typo active={active === 'open'}>Open Orders</Typo>
    </UnderlineGrid>
    <UnderlineGrid
      item
      onClick={() => handleStatusValueChange('closed')}
      active={active === 'closed'}
      width="12%"
    >
      <Typo active={active === 'closed'}>Closed Orders</Typo>
    </UnderlineGrid>
    <UnderlineGrid width="76%" style={{ padding: '12px 0', cursor: 'auto' }}>
      <Typo />
    </UnderlineGrid>
  </StatusesGrid>
);
TabsButtons.propTypes = {
  active: PropTypes.string,
  handleStatusValueChange: PropTypes.func.isRequired
};

TabsButtons.defaultProps = {
  active: '',
};

export default TabsButtons;
