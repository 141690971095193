import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import UpArrow from '../../../assets/images/dashboard/UpArrow.png';
import DownArrow from '../../../assets/images/dashboard/DownArrow.png';
import {
  StatisticWrapper, StatisticCard, StatisticGrid, StatisticHeader, DiffImage,
  StatisticContent, DiffGrid
} from './cStatisticCard.styles';

export default function CStatisticCard({
  statistic
}) {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const {
    title, value, diff, size
  } = statistic;

  return (
    <StatisticWrapper container item md={size || 3} xs={12}>
      <StatisticCard item container>
        <statistic.icon style={{ fontSize: isSmall ? '9rem' : '4rem', marginTop: isSmall ? '1rem' : '0' }} />
        <StatisticGrid item>
          <StatisticHeader item>
            {title}
          </StatisticHeader>
          <StatisticContent item>
            {value}
          </StatisticContent>
          {diff ? (
            <DiffGrid item diff={diff}>
              {diff * 1 < 0
                ? <DiffImage src={DownArrow} />
                : <DiffImage src={UpArrow} />}
              {`${diff} %`}
            </DiffGrid>
          ) : ''}
        </StatisticGrid>
      </StatisticCard>
    </StatisticWrapper>
  );
}

CStatisticCard.propTypes = {
  statistic: PropTypes.instanceOf(Object),
  value: PropTypes.number,
};
CStatisticCard.defaultProps = {
  statistic: {},
  value: 0,
};
