import { styled } from '@mui/material/styles';
import {
  MenuItem, TableHead, TableRow, TableCell, Box,
  Typography, Grid, Button, Paper
} from '@mui/material';

export const ContainerBody = styled('div')`
  margin-top: 10px;
  padding: 20px 40px;
`;

export const WrapperGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

export const ChartGrid = styled(Grid)`
  height: 550px;
  width: ${({ range, loading }) => (range === 'month' || loading ? '100%' : '100rem')};
`;

export const InputGrid = styled(Grid)`
  padding: 7px 24px;
  justify-content: space-between;
  cursor: pointer;
  /* width: rem; */
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #235A91;
  border-radius: 10px;
  align-items: center;
  text-transform: capitalize;
  margin-left: .5rem;
`;

export const ExportHeaderButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #235A91;
  background-color: #235A91;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 50px;
`;

export const SalesReportButton = styled(Button)`
  justify-content: space-between;
  padding: 7px 18px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #235A91;
  border-radius: 10px;
  width: 100%;
  height: 50px;

  svg {
    font-size: 1.5rem;
    color: rgb(56, 95, 132);
  }

  &.Mui-disabled {
    border: 1px solid #DDD;
    color: #24292E;
    background-color: #EEF2F5;

    svg {
      color: #858383;
    }
  }
`;

export const GridTypo = styled(Typography)`
  font-size: 0.875rem;
  width: 8rem;
`;

export const ChartsTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  color: #303030;
`;

export const ChartPaper = styled(Paper)`
  margin-top: 1.5rem;
  width: 100%;
  height: 600px;
  padding: 1.5rem;
  overflow: scroll;
  border-radius: 10px;
  box-shadow: 0px 2.96px 66.64px rgba(163,171,185,0.24);
`;

export const Dropdown = styled('img')`
  width: 24px;
  height: 24px;
  margin-left: 0.7rem;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #ECF6FF;
  font-weight: bold;
  font-size: 30px;
`;

export const TableRowStyled = styled(TableRow)`
    ${({ cond }) => cond && `
        background: #ECF6FF;
    `}
`;

export const TableCellStyled = styled(TableCell)`
  font-weight: bold;
  border-bottom: none
`;

export const TableCellBStyled = styled(TableCell)`
  border-bottom: none;
`;

export const BoxStyled = styled(Box)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  border-bottom: ${({ active }) => (active ? '3px solid #235A91' : '3px solid #ECF6FF')};
  width: ${({ width }) => (width)};
`;

export const Typo = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  padding: 2px 15px;
  color: ${({ active }) => (active ? '#303030' : '#C0C0C1')};
`;
