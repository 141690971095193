import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  BodyCell, MainTableRow, ColorGrid,
} from './returnRow.styles';
import { JSONParse } from '../../../utils/json';

const ReturnRow = ({
  row, index
}) => {
  const navigate = useNavigate();
  const {
    id, name, contacts, status: statuses, dateCreated, salesRepresentative
  } = row;
  const { mobile_number: mobile, primary_email_address: email } = JSONParse(contacts);
  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');
  const handleClick = () => {
    navigate(`/manage-affiliates/${id}/details`);
  };

  const getColorGrid = (color, bkg, status) => (
    <ColorGrid item container color={color} background={bkg} className={`mpAdmin-uat-manageAffiliatePage-${status}`}>
      {status}
    </ColorGrid>
  );
  const getStatus = () => {
    switch (statuses) {
      case 'VERIFIED':
        return getColorGrid('#07DE10', '#DCFEDD', 'Verified');
      case 'UNVERIFIED':
        return getColorGrid('#606060', '#FBF777', 'Unverified');
      case 'SUSPENDED':
        return getColorGrid('#FA3636', '#FBDADA', 'Suspended');
      default:
        return getColorGrid('white', '#BF0505', 'N/A');
    }
  };

  const erpCells = [name, salesRepresentative, email, mobile];

  const returnCell = (val) => val || 'N/A';

  const renderBodyCells = () => erpCells.map((cell) => (
    <BodyCell onClick={() => handleClick()} style={{ cursor: 'pointer' }} key={cell}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow style={index % 2 ? { backgroundColor: ' #F4F7FC' } : { backgroundColor: '#FFF' }}>
      <BodyCell />
      <BodyCell>{renderDate}</BodyCell>
      {renderBodyCells()}
      <BodyCell>
        {getStatus()}
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.instanceOf(Number),
};

ReturnRow.defaultProps = {
  row: {},
  index: 0,
};

export default ReturnRow;
