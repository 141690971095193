import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, InputAdornment
} from '@mui/material';
import {
  VisIcon, VisOffIcon
} from './passwordTextField.styles';
import CustomInputBase from '../customComponents/customInputBase';

const PasswordTextField = ({
  label, name, password, handleChange, passwordError, loading, ...otherArgs
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <CustomInputBase
      {...otherArgs}
      label={label || 'Password'}
      name={name || 'password'}
      type={showPassword ? 'text' : 'password'}
      required
      value={password}
      onChange={handleChange}
      error={passwordError}
      data-testid="auth-password"
      disabled={loading}
      className="signin-password"
      cSize="lg"
      id="signin-password"
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            size="large"
            disableRipple
            // color="primary"
            style={{ color: '#78AADA' }}
          >
            {showPassword ? <VisIcon /> : <VisOffIcon />}
          </IconButton>
        </InputAdornment>
        )}
      helperText={
        passwordError
          ? 'Should be atleast 8 characters. UPPERCASE, lowercase, letters & numbers'
          : ' '
      }
    />
  );
};

PasswordTextField.propTypes = {
  password: PropTypes.string,
  passwordError: PropTypes.bool,
  loading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
};

PasswordTextField.defaultProps = {
  password: '',
  passwordError: false,
  loading: false,
  label: '',
  name: '',
};

export default PasswordTextField;
