import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Hidden, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Slide, Button
} from '@mui/material';
import { Clear, KeyboardArrowLeft, Check } from '@mui/icons-material';
import moment from 'moment';
import currencyFormatter from '../../shared/currencyFormatter';
import { JSONParse } from '../../../utils/json';
import { GET_SINGLE_SUPPLIER_ORDER } from '../../../queries/orders';
import ReceiveMOActionTypes from '../../../providers/reducers/receiveManualOrders/receiveManaulOrderTypes';
import ConfirmDialog from '../../shared/confirmDialog';
import PartiallyReceivedModal from './reconciledOrderModal';
import {
  CANCEL_SUPPLIER_ORDERS, RECEIVE_SUPPLIER_ORDERS, RECEIVE_MANUAL_SUPPLIER_ORDER,
  RECONCILE_MANUAL_SUPPLIER_ORDER, SAVE_MANUAL_ORDER_PROGRESS, MANUAL_ORDER_PAY_NOW,
  SAVE_AUTOMATIC_ORDER_PROGRESS, RECONCILE_AUTOMATIC_SUPPLIER_ORDER, RECEIVE_AUTOMATIC_SUPPLIER_ORDER, CREATE_NOTE
} from '../../../mutations/orders';
import {
  MainGridContainer, HeaderGrid, MenuButton, BackText, PaperCard, CardLogo,
  CardText, OrderButtonText, CardHeaderGrid, CardHeaderText, NoteHeaderGrid,
  UpdateButton, NoteButton, NotesText, TableHeaderCell, TotalsGrid, DivFlex,
  DialogActionButtonText, PageTitleText, PageTitleSubText, CartItemCountContainer,
  CartItemCountText, PageTitleContainer, DialogCancelButton, DialogOkButton, LocationTextField,
  Divider
} from './manualSupplierOrderDetail.styles';
import MainContent from '../../customComponents/mainContent';
import Stepper from './stepper';
import ReturnRow from './returnRow';
import PaymentSummary from '../../shared/paymentSummaryCard/PaymentSummary';
import AlternativeSupplierDialog from './alternativeSuppliers/alternativeSuppliers';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { useStateValue } from '../../../providers/stateProvider';
import { OgaToken } from '../../../graphql/token';
import AddNoteDialog from './addNoteDialog';
import ViewNoteDialog from './viewNoteDialog';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const headers = [
  'product items', 'qty ordered', 'qty Received', 'unit cost', 'price', ''
];

const initialState = {
  business: null,
  businessContact: null,
  supplier: null,
  deliveryDate: null,
  dateCreated: null,
  cart: [],
  showCancelOrderDialog: false,
  showReceiveOrderDialog: false,
  loading: false,
  editNote: false,
  note: '',
  orderId: null,
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: 'Nigeria',
  source: '',
  primaryTitle: 'Receive Order',
  onlyMarketProducts: false,
  status: '',
  rating: 0,
  message: '',
  all: 'Yes',
  prompt: 'Yes',
  activeStep: 0,
  allBatches: [],
  notReceivedProds: [],
  openAlternativeDialog: false,
  error: false,
  lastUpdated: null
};

const ManualSupplierOrderDetail = (props) => {
  const [{
    receiveManualOrder,
    user: { allowedPermissionsMap: { userPermissions } }
  }, dispatch] = Object.values(useStateValue());

  const [state, setState] = useState(initialState);
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewNote, setOpenViewNote] = useState(false);
  // const [totalPrice, setTotalPrice] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [saveProgressConfirm, setSaveProgressConfirm] = useState(false);
  const [makePaymentConfirm, setMakePaymentConfirm] = useState(false);
  const [closeOrderConfirm, setCloseOrderConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState({ title: '', desc: '', confirm: '' });
  const [openPartialDialog, setOpenPartialDialog] = useState(false);

  const {
    onlyMarketProducts: _onlyMarketProducts,
    primaryTitle: _primaryTitle,
  } = props;

  const navigate = useNavigate();
  const params = useParams();
  const { pathname, state: locationState } = useLocation();
  const pathnameSplit = pathname.split('/');
  const isAutoOrder = pathnameSplit[1] === 'receive-automatic-order';

  const [reconcileManualSupplierOrder] = useMutation(RECONCILE_MANUAL_SUPPLIER_ORDER);
  const [reconcileaAutomaticSupplierOrder] = useMutation(RECONCILE_AUTOMATIC_SUPPLIER_ORDER);
  const [manualOrderPayNow, { loading: paymentLoading }] = useMutation(MANUAL_ORDER_PAY_NOW);

  const {
    businessContact, deliveryDate, dateCreated, supplier, cart, showCancelOrderDialog, activeStep,
    showReceiveOrderDialog, loading, editNote, note, source, status,
    all, prompt, openAlternativeDialog, notReceivedProds, onlyMarketProducts, primaryTitle
  } = state;

  const retrieveProducts = (orderSet) => {
    let products = [];

    products = orderSet.map((p) => {
      const {
        id: opSetId, name, quantity, quantityRecieved, status: _status, paid, unitCost,
        supplierOrder: { supplier: { name: supplierName, settings } }, partiallyFulfilled,
        product: { id, meta, business: { id: productBusinessId }, }
      } = p;

      const supplierSettings = JSON.parse(settings.replace(/'/g, '"').replace('None', '"N/A"'));

      return {
        id, opSetId, name, status: _status, orderStatus: _status, paid, partiallyFulfilled, quantity, quantityRecieved, supplier: supplierName,
        supplierPaymentTerm: supplierSettings.payment_term, meta: JSON.parse(meta),
        productBusinessId, unitCost,
        ...new Product(p.product, 'erp'),
      };
    });
    setState((s) => ({ ...s, cart: products }));
  };

  const {
    data: supplierData, refetch
  } = useQuery(GET_SINGLE_SUPPLIER_ORDER, {
    variables: { id: params?.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (supplierData && supplierData.supplierOrder) {
      const {
        supplierOrder: {
          deliveryDate: _deliveryDate,
          lastUpdated,
          status: _status,
          order: { id: orderId, meta, business: _business },
          orderproductSet,
          supplier: _supplier
        }
      } = supplierData;

      const orderMeta = JSONParse(meta.replace(/'/g, '"'));
      const businessContactMeta = JSONParse(_business.contacts.replace(/'/g, '"').replace('None', '"Lagos"'));

      const {
        note: _note, addressLine1, addressLine2, city
      } = orderMeta;
      const _source = locationState && locationState[0];

      setState((s) => ({
        ...s,
        orderId,
        note: _note,
        business: _business,
        businessContact: businessContactMeta,
        supplier: _supplier,
        lastUpdated,
        deliveryDate: moment(_deliveryDate).format('DD/MM/YYYY'),
        dateCreated: moment(lastUpdated).format('DD/MM/YYYY'),
        addressLine1,
        addressLine2,
        city,
        status: _status,
        source: _source,
        onlyMarketProducts,
        primaryTitle: _primaryTitle,
      }));

      retrieveProducts(orderproductSet);
    }
  }, [supplierData]);

  useEffect(() => {
    setState((s) => ({ ...s, allBatches: [] }));
  }, []);

  useEffect(() => {
    if (status === 'MANUAL_SUPPLIER_ORDER_RECEIVED' || status === 'AUTOMATIC_SUPPLIER_ORDER_RECEIVED') {
      cart.forEach((item) => {
        item.newPrice = item?.unitCost || 0;
      });
      dispatch({
        type: ReceiveMOActionTypes.ADD_TO_LIST,
        payload: {
          order_id: params?.id,
          order: cart,
          listBatches: []
        }
      });
      setState((s) => ({ ...s, activeStep: 1 }));
    } else if (status === 'MANUAL_SUPPLIER_ORDER_RECONCILED' || status === 'AUTOMATIC_SUPPLIER_ORDER_RECONCILED') {
      setState((s) => ({ ...s, activeStep: 2 }));
    }
  }, [status, cart, refetch]);

  const manualOrders = receiveManualOrder.orderList.find((item) => item.order_id === params?.id);

  const [cancelSupplierOrders] = useMutation(CANCEL_SUPPLIER_ORDERS);
  const handleCancelOrder = () => {
    setState((s) => ({
      ...s, loading: true, showCancelOrderDialog: false
    }));
    cancelSupplierOrders({
      variables: { supplierOrderIds: [params?.id] }
    })
      .then(({ data }) => {
        const {
          cancelSupplierOrders: { message }
        } = data ?? {};

        toast.success(message);
        setState((s) => ({ ...s, loading: false }));
        setTimeout(() => {
          navigate('/orders');
        }, 1500);
      })
      .catch((err) => {
        toast.error(err?.message);
        setState((s) => ({ ...s, loading: false }));
      });
  };

  const deleteBatchHandler = (batchNo) => {
    const orderId = params?.id;
    dispatch({
      type: ReceiveMOActionTypes.DELETE_BATCH,
      payload: { batchNo, orderId }
    });
  };

  const addToBatch = (batch) => {
    dispatch({
      type: ReceiveMOActionTypes.ADD_BATCH,
      payload: { batch, orderId: params?.id }
    });
  };

  const addToNotReceived = (prod) => {
    const _notReceivedProds = notReceivedProds;
    const existingProdIndex = _notReceivedProds.findIndex((_prod) => _prod.id === prod.id);

    if (existingProdIndex === -1) _notReceivedProds.push(prod);
    else _notReceivedProds[existingProdIndex] = prod;

    setState((s) => ({ ...s, notReceivedProds: _notReceivedProds }));
  };

  const clearNotReceived = () => {
    setState((s) => ({ ...s, notReceivedProds: [] }));
  };

  const [receiveManualSupplierOrders] = useMutation(RECEIVE_MANUAL_SUPPLIER_ORDER);
  const [receiveAutomaticSupplierOrders] = useMutation(RECEIVE_AUTOMATIC_SUPPLIER_ORDER);
  const [receiveSupplierOrders] = useMutation(RECEIVE_SUPPLIER_ORDERS);
  const handleReceiveOrder = async () => {
    setState((s) => ({
      ...s,
      loading: true,
      showReceiveOrderDialog: false
    }));

    const backRoute = '/order';

    if (_onlyMarketProducts) {
      const { rating, message } = state;
      if (isAutoOrder) {
        receiveAutomaticSupplierOrders({
          variables: {
            supplierOrderId: Number(params?.id),
            keyValues: [
              { key: 'got_everything', value: all },
              { key: 'how_prompt', value: prompt },
              { key: 'rate', value: rating },
              { key: 'message', value: message }
            ],
          }
        })
          .then(() => {
            setState((s) => ({ ...s, loading: false, status: 'AUTOMATIC_SUPPLIER_ORDER_RECEIVED' }));
          });
        return;
      }
      receiveManualSupplierOrders({
        variables: {
          supplierOrderId: Number(params?.id),
          keyValues: [
            { key: 'got_everything', value: all },
            { key: 'how_prompt', value: prompt },
            { key: 'rate', value: rating },
            { key: 'message', value: message }
          ],
        }
      })
        .then(() => {
          setState((s) => ({ ...s, loading: false, status: 'MANUAL_SUPPLIER_ORDER_RECEIVED' }));
        });
    } else {
      receiveSupplierOrders({
        variables: { supplierOrderIds: [params?.id] }
      })
        .then(({ data }) => {
          const {
            receiveSupplierOrders: { message }
          } = data;

          toast.success(message);
          setState((s) => ({ ...s, loading: false }));
          setTimeout(() => {
            navigate(`${backRoute}`);
          }, 1500);
        })
        .catch((err) => {
          toast.error(err?.message);
          setState((s) => ({ ...s, loading: false }));
        });
    }
  };

  const handleEditNoteClicked = () => {
    setState((s) => ({ ...s, editNote: true }));
  };

  const handleEditChange = (e) => {
    e.persist();
    setState((s) => ({ ...s, note: e?.target?.value }));
  };

  const [createNote] = useMutation(CREATE_NOTE);
  const handleEditNote = () => {
    const { orderId } = locationState || {};
    if (note) {
      createNote({
        variables: {
          content: note,
          orderId,
        }
      }).then(({ data }) => {
        const { createNote: { message } } = data ?? {};
        toast.success(message);
        setState((s) => ({ ...s, editNote: false, note }));
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      toast.error('Note cannot be empty.');
    }
  };

  const [saveProgress, { loading: saveLoading }] = useMutation(SAVE_MANUAL_ORDER_PROGRESS);
  const [autoSaveProgress, { loading: autoSaveLoading }] = useMutation(SAVE_AUTOMATIC_ORDER_PROGRESS);
  if (confirmStatus && saveProgressConfirm) {
    setConfirmStatus(false);
    setSaveProgressConfirm(false);
    const batches = receiveManualOrder?.orderList?.find((item) => item?.order_id === params?.id)?.listBatches;
    const unique = [...new Set(batches?.map((item) => item.orderProductId))];
    const result = unique.map((item) => {
      const filteredBatches = batches?.filter((filter) => filter?.orderProductId === item);
      filteredBatches.forEach((extraKey) => {
        delete extraKey.orderProductId;
        delete extraKey.supplierName;
      });
      return { orderProductId: item, batches: filteredBatches };
    });

    if (!result.length) return toast.error('Progress not saved batch list is empty');

    if (isAutoOrder) {
      autoSaveProgress({
        variables: {
          supplierOrderId: Number(params?.id),
          productBatches: result,
        }
      }).then(({ data }) => {
        toast.success(data?.saveAutomaticOrderProgress?.message);
        dispatch({
          type: ReceiveMOActionTypes.DELETE_ORDER,
          payload: { orderId: params?.id }
        });
        refetch();
        setOpenConfirmDialog(false);
      });
      return;
    }

    saveProgress({
      variables: {
        supplierOrderId: Number(params?.id),
        productBatches: result,
      }
    }).then(({ data }) => {
      toast.success(data?.saveManualOrderProgress?.message);
      dispatch({
        type: ReceiveMOActionTypes.DELETE_ORDER,
        payload: { orderId: params?.id }
      });
      refetch();
      setOpenConfirmDialog(false);
    });
  }
  const saveManualOrderHandler = () => {
    setConfirmText({
      title: '',
      desc: 'Do you confirm that you wish to save progress?',
      confirm: 'Proceed'
    });
    setOpenConfirmDialog(true);
    setSaveProgressConfirm(true);
  };

  if (confirmStatus && closeOrderConfirm) {
    setConfirmStatus(false);
    setCloseOrderConfirm(false);
    if (notReceivedProds.length) {
      setState((s) => ({ ...s, openAlternativeDialog: true }));
    } else {
      if (isAutoOrder) {
        reconcileaAutomaticSupplierOrder({
          mutation: RECONCILE_AUTOMATIC_SUPPLIER_ORDER,
          variables: {
            supplierOrderId: Number(params?.id),
          }
        })
          .then(() => {
            setState((s) => ({ ...s, loading: false, status: 'AUTOMATIC_SUPPLIER_ORDER_RECONCILED' }));
            toast.success('Manual order reconciled.');
            dispatch({
              type: ReceiveMOActionTypes.DELETE_ORDER,
              payload: { orderId: params?.id }
            });
            return window.setTimeout(() => navigate(-1), 2000);
          });
        return;
      }
      reconcileManualSupplierOrder({
        mutation: RECONCILE_MANUAL_SUPPLIER_ORDER,
        variables: {
          supplierOrderId: Number(params?.id),
        }
      })
        .then(() => {
          setState((s) => ({ ...s, loading: false, status: 'MANUAL_SUPPLIER_ORDER_RECONCILED' }));
          toast.success('Manual order reconciled.');
          dispatch({
            type: ReceiveMOActionTypes.DELETE_ORDER,
            payload: { orderId: params?.id }
          });
          return window.setTimeout(() => navigate(-1), 2000);
        });
    }
  }
  const closeOrder = async () => {
    setConfirmText({
      title: '',
      desc: 'Do you confirm that you wish to close this order?',
      confirm: 'Proceed'
    });
    setOpenConfirmDialog(true);
    setCloseOrderConfirm(true);
  };

  const handleButton = () => {
    if (status === 'MANUAL_SUPPLIER_ORDER_RECEIVED' || status === 'AUTOMATIC_SUPPLIER_ORDER_RECEIVED') closeOrder();
    else setState((s) => ({ ...s, showReceiveOrderDialog: true }));
  };

  let buttonValue = 'Receive Order';
  if (status === 'MANUAL_SUPPLIER_ORDER_RECEIVED' || status === 'AUTOMATIC_SUPPLIER_ORDER_RECEIVED') buttonValue = 'Close Order';
  const buttonText = buttonValue.toLocaleLowerCase().split(' ').join('_');

  const groupOrderDiscount = cart.reduce((sum, obj) => sum
        + (`${obj.productBusinessId}` === '1' ? (Number(obj.quantityRecieved || 0) * ((Number(obj.marketRrp) || Number(obj.orderCost)) - Number(obj.orderCost))) : 0), 0);

  const reconciledOrdersPrices = cart.map((item) => {
    const {
      quantityRecieved, unitCost, orderStatus, paid
    } = item;
    if ((orderStatus === 'MANUAL_ITEM_RECONCILED' || orderStatus === 'AUTOMATIC_ITEM_RECONCILED') && !paid) {
      return quantityRecieved * unitCost;
    }
    return 0;
  }).reduce((sum, val) => sum + val, 0);
  const localStoragePrices = manualOrders?.listBatches?.map(
    (item) => item.quantityReceived * item.reconciledUnitCost
  )?.reduce((a, b) => a + b, 0);
  const totalSummary = reconciledOrdersPrices + localStoragePrices;

  const returnHeaders = () => headers.map((header) => (
    <TableHeaderCell key={header}>{header}</TableHeaderCell>
  ));

  const {
    mobile_number: mobileNumber, primary_email_address: primaryEmailAddress,
    address_line_1: addressLineOne, address_line_2: addressLineTwo,
    region, city, country
  } = businessContact ?? '';

  if (confirmStatus && makePaymentConfirm) {
    setConfirmStatus(false);
    setMakePaymentConfirm(false);
    const proceedPayment = cart?.map(({ paid, orderStatus }) => {
      if ((orderStatus === 'MANUAL_ITEM_RECONCILED' || orderStatus === 'AUTOMATIC_ITEM_RECONCILED') && !paid) return true;
      return false;
    });
    if (!proceedPayment.includes(true)) return toast.error('No pending payment');
    const supplierOrderId = Number(params?.id);
    manualOrderPayNow({
      variables: {
        supplierOrderId,
      }
    })
      .then(({ data }) => {
        toast.success(data?.manualOrderPayNow?.message);
        refetch();
        setOpenConfirmDialog(false);
      });
  }

  const makePaymentHandler = () => {
    setConfirmText({
      title: '',
      desc: 'Do you confirm that you wish to proceed with this order?',
      confirm: 'Proceed'
    });
    setOpenConfirmDialog(true);
    setMakePaymentConfirm(true);
  };

  const downloadPOHandler = () => {
    window.open(
      `${OgaToken.SERVER_LINK}download_purchase_order/${params?.id}`,
      '_blank'
    );
  };

  const noteDialogHandler = () => setOpenDialog(!openDialog);
  const viewNoteDialogHandler = () => setOpenViewNote(!openViewNote);

  const partiallyDialogHandler = () => setOpenPartialDialog(!openPartialDialog);

  return (
    <MainContent>
      <MainGridContainer container>
        <Grid container item alignItems="center">
          <MenuButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
          </MenuButton>
          <BackText variant="caption">back</BackText>
        </Grid>
        { !onlyMarketProducts ? <Stepper activeStep={activeStep} /> : '' }
        <HeaderGrid container item>
          <PageTitleContainer>
            <PageTitleText>{primaryTitle}</PageTitleText>
            <Hidden lgDown>
              <PageTitleSubText>View details of an order sent to a supplier</PageTitleSubText>
            </Hidden>
          </PageTitleContainer>
          {onlyMarketProducts ? !source && (
            <UpdateButton
              onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: true }))}
              disabled={loading}
            >
              <Clear />
              <Hidden lgDown>
                <OrderButtonText>
                  Cancel Order
                </OrderButtonText>
              </Hidden>
            </UpdateButton>
          ) : ''}

          {!source && (
            <DivFlex>
              <Button variant="outlined" onClick={downloadPOHandler}>Download PO</Button>
              <Button variant="outlined" onClick={partiallyDialogHandler}>Reconciled Order</Button>
              { (status === 'MANUAL_SUPPLIER_ORDER_RECEIVED' || status === 'AUTOMATIC_SUPPLIER_ORDER_RECEIVED')
                && (
                <Button variant="outlined" disabled={saveLoading || autoSaveLoading || (localStoragePrices < 1)} onClick={saveManualOrderHandler}>
                  {(saveLoading || autoSaveLoading) ? 'Loading ...' : 'Save progress' }
                </Button>
                )}
              <Button
                variant="contained"
                onClick={handleButton}
                disabled={loading || !userPermissions?.includes(`inventory_${buttonText}`)}
              >
                {buttonValue}
              </Button>
            </DivFlex>
          )}
        </HeaderGrid>

        <Grid container justifyContent="space-between" spacing={3} style={{ marginTop: '42px' }}>
          <Grid item container md={12} lg={4}>
            <PaperCard>
              <CardLogo src={supplier?.logo} />
              <CardText>{supplier?.paymentTerm}</CardText>
              <CardText>
                Placed on:
                <b>{` ${dateCreated}`}</b>
              </CardText>
              <CardText>
                Estimated Delivery:
                <b>{` ${deliveryDate || 'N/A'}`}</b>
              </CardText>
            </PaperCard>
          </Grid>
          <Grid item container md={12} lg={4}>
            <PaperCard>
              <CardHeaderGrid item container>
                <CardHeaderText>Delivery Location</CardHeaderText>
              </CardHeaderGrid>
              <CardText>Mobile</CardText>
              <CardText>
                <b>{mobileNumber || 'N/A'}</b>
              </CardText>
              <CardText>
                Email Address
              </CardText>
              <CardText>
                <b>{primaryEmailAddress || 'N/A'}</b>
              </CardText>
              <CardText>
                Address
              </CardText>
              <CardText>
                <b>{addressLineOne && `${addressLineOne},`}</b>
                <b>{addressLineTwo && ` ${addressLineTwo},`}</b>
                <b>{region && ` ${region},`}</b>
                <b>{city && ` ${city},`}</b>
                <b>{country && ` ${country}.`}</b>
              </CardText>
            </PaperCard>
          </Grid>
          <Grid item container md={12} lg={4}>
            <PaperCard>
              <PaymentSummary
                cart={cart}
                orderTotal={totalSummary}
                groupOrderDiscount={groupOrderDiscount}
                grandTotal={totalSummary}
                reconciledOrdersPrices={reconciledOrdersPrices}
                localStoragePrices={localStoragePrices}
                paymentHandler={makePaymentHandler}
                loading={paymentLoading}
              />
            </PaperCard>
          </Grid>
        </Grid>
        {onlyMarketProducts
          ? (
            <CartItemCountContainer>
              <CartItemCountText>
                {`${cart.length} ${cart.length ? 'Items' : 'Item'}`}
              </CartItemCountText>
            </CartItemCountContainer>
          ) : '' }
        {(status !== 'MANUAL_SUPPLIER_ORDER_RECEIVED' && status !== 'AUTOMATIC_SUPPLIER_ORDER_RECEIVED') ? '' : (
          <Grid container item spacing={3} style={{ marginTop: '.75rem' }}>
            <Grid item container md={12} lg={9}>
              <PaperCard style={{ padding: '2rem 1rem' }}>
                <Hidden lgDown>
                  <Grid item container>
                    {returnHeaders()}
                  </Grid>
                </Hidden>
                {manualOrders?.order.map((cartItem) => {
                  const { orderStatus } = cartItem;
                  if (orderStatus !== 'MANUAL_ITEM_RECONCILED' && orderStatus !== 'AUTOMATIC_ITEM_RECONCILED') {
                    return (
                      <ReturnRow
                        key={cartItem}
                        cartItem={cartItem}
                        status={status}
                        addToBatch={addToBatch}
                        addToNotReceived={() => addToNotReceived(cartItem)}
                        clearNotReceived={clearNotReceived}
                        lastUpdated={state?.lastUpdated}
                        allBatches={state?.allBatches}
                        deleteBatchHandler={deleteBatchHandler}
                      />
                    );
                  }
                })}
                <TotalsGrid item container>
                  <CardHeaderText>SubTotal</CardHeaderText>
                  &nbsp;&nbsp;&nbsp;
                  <CardHeaderText>{`₦ ${currencyFormatter(totalSummary)}`}</CardHeaderText>
                </TotalsGrid>
              </PaperCard>
            </Grid>
            <Grid item container md={12} lg={3}>
              <PaperCard style={{ maxHeight: '20rem', minHeight: '20rem' }}>
                <NoteHeaderGrid item container>
                  <CardHeaderText>Note</CardHeaderText>
                  {editNote ? (
                    <Grid item>
                      <NoteButton onClick={() => setState((s) => ({ ...s, editNote: false }))}>
                        <Clear style={{ fontSize: '1rem' }} />
                      </NoteButton>
                      <NoteButton onClick={() => handleEditNote()}>
                        <Check style={{ fontSize: '1rem' }} />
                      </NoteButton>
                    </Grid>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={handleEditNoteClicked}
                      style={{
                        width: '7rem', fontSize: '14px', fontWeight: '700', borderRadius: '5px'
                      }}
                    >
                      Add Note
                    </Button>
                  )}
                </NoteHeaderGrid>
                <Divider />
                {editNote ? (
                  <Grid item container>
                    <LocationTextField
                      value={note}
                      multiline
                      minRows={6}
                      fullWidth
                      onChange={handleEditChange}
                      style={{ backgroundColor: '#f0f0f0' }}
                    />
                  </Grid>
                ) : (
                  <NotesText>{note}</NotesText>
                )}
              </PaperCard>
            </Grid>
          </Grid>
        )}
      </MainGridContainer>

      <Dialog
        open={showCancelOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton onClick={() => handleCancelOrder()}>
            <DialogActionButtonText>OK</DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showReceiveOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showReceiveOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Receive Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to receive the order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setState((s) => ({ ...s, showReceiveOrderDialog: false }))}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => handleReceiveOrder()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <AlternativeSupplierDialog
        dialogOpen={openAlternativeDialog}
        closeDialog={() => setState((s) => ({ ...s, openAlternativeDialog: false }))}
        products={notReceivedProds}
        clearNotReceived={clearNotReceived}
        closeOrder={closeOrder}
      />

      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title={confirmText?.title}
        desc={confirmText?.desc}
        options={['Cancel', confirmText?.confirm]}
        setStatus={setConfirmStatus}
        loading={saveLoading || autoSaveLoading || paymentLoading}
      />

      <PartiallyReceivedModal
        open={openPartialDialog}
        close={partiallyDialogHandler}
        cartItem={manualOrders}
      />

      <AddNoteDialog
        modalStatus={openDialog}
        modalHandler={noteDialogHandler}
      />

      <ViewNoteDialog
        modalStatus={openViewNote}
        modalHandler={viewNoteDialogHandler}
      />
    </MainContent>
  );
};

ManualSupplierOrderDetail.propTypes = {
  onlyMarketProducts: PropTypes.bool,
  primaryTitle: PropTypes.string,
};

ManualSupplierOrderDetail.defaultProps = {
  onlyMarketProducts: true,
  primaryTitle: 'Order Detail'
};

export default ManualSupplierOrderDetail;
