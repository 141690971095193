import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { useStateValue } from '../../../providers/stateProvider';
import currencyFormatter from '../currencyFormatter';
import CustomButton from '../../customComponents/customButton';
import {
  CardHeaderGrid, CardHeaderText, GrandTotalGrid, CardGridText
} from './paymentSummary.styles';

const PaymentSummary = ({
  cart, orderTotal, serviceFee, deliveryFee, groupOrderDiscount, grandTotal, taxTotal, promo, paymentHandler, loading,
  reconciledOrdersPrices, localStoragePrices
}) => {
  const [{ affiliate: { usablePromos }, user: { platform } }] = Object.values(useStateValue());
  const getTotalPromoPrice = () => 0;
  const totalPromo = promo ?? getTotalPromoPrice();
  const isMarketplace = platform === 'marketplace';
  const role = localStorage.getItem('oga_user_role');
  const isAdmin = role === 'oga-pharmacy-admin';
  return (
    <>
      <CardHeaderGrid item container>
        <CardHeaderText>Payment Summary</CardHeaderText>
      </CardHeaderGrid>
      <Grid item container>
        <CardGridText item xs={8}>Order Total</CardGridText>
        <CardGridText item xs={4}>
          <b>{`₦ ${currencyFormatter(orderTotal)}`}</b>
        </CardGridText>
        <CardGridText item xs={8}>Delivery Fee</CardGridText>
        <CardGridText item xs={4}>
          <b>{`₦ ${currencyFormatter(deliveryFee)}`}</b>
        </CardGridText>
        <CardGridText item xs={8}>Service Fee</CardGridText>
        <CardGridText item xs={4}>
          <b>{`₦ ${currencyFormatter(serviceFee)}`}</b>
        </CardGridText>
        {taxTotal ? (
          <>
            <CardGridText item xs={8}>Tax</CardGridText>
            <CardGridText item xs={4}>
              <b>{`₦ ${currencyFormatter(taxTotal)}`}</b>
            </CardGridText>
          </>
        ) : ''}
        {totalPromo ? (
          <>
            <CardGridText item xs={8} style={{ color: '#AF1839' }}>Promotional Savings</CardGridText>
            <CardGridText item xs={4} style={{ color: '#AF1839' }}>
              <b>{`₦ -${currencyFormatter(totalPromo)}`}</b>
            </CardGridText>
          </>
        ) : ''}
        {(isMarketplace && isAdmin) && (
          <CardGridText item style={{ color: '#4AA64E' }}>
            You saved&nbsp; ₦&nbsp;
              {currencyFormatter(groupOrderDiscount < 0 ? 0 : groupOrderDiscount)}
                &nbsp; with OGApharmacy group order
          </CardGridText>
        )}
      </Grid>
      <GrandTotalGrid item container>
        <CardGridText item xs={8}>
          <b>Grand Total</b>
        </CardGridText>
        <CardGridText item xs={4}>
          <b>{`₦ ${currencyFormatter(grandTotal - totalPromo)}`}</b>
        </CardGridText>
      </GrandTotalGrid>
      <Button
        variant="contained"
        disabled={!(reconciledOrdersPrices > 0) || (localStoragePrices > 0)}
        style={{ width: '100%', marginTop: '20px' }}
        onClick={paymentHandler}
      >
        {loading ? 'Loading...' : 'Pay Now'}
      </Button>
    </>
  );
};

PaymentSummary.propTypes = {
  cart: PropTypes.instanceOf(Array),
  orderTotal: PropTypes.number,
  serviceFee: PropTypes.number,
  deliveryFee: PropTypes.number,
  groupOrderDiscount: PropTypes.number,
  taxTotal: PropTypes.number,
  grandTotal: PropTypes.number,
  paymentHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  reconciledOrdersPrices: PropTypes.number,
  localStoragePrices: PropTypes.number
};

PaymentSummary.defaultProps = {
  cart: [],
  orderTotal: 0,
  serviceFee: 0,
  deliveryFee: 0,
  groupOrderDiscount: 0,
  taxTotal: 0,
  grandTotal: 0,
  loading: false,
  reconciledOrdersPrices: 0,
  localStoragePrices: 0
};

export default PaymentSummary;
