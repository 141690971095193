import {
  Typography, Checkbox, InputBase, FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CreditNameTypo = styled(Typography)`
  font-size: .7rem;
`;

export const CreditDaysInput = styled(InputBase)`
  font-size: .7rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;

export const SelectFormControl = styled(FormControl)`
  & .MuiSelect-selectMenu {
    height: 2rem;
    background-color: #F5F5F7;
    border-radius: 15px;
  }

  & .MuiOutlinedInput-root {
    border-radius: 15px;
  }
`;
