import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, Slide, Grid, Button, CircularProgress,
  Box
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomInputBase from '../customComponents/customInputBase';
import CustomSelectInputBase from '../customComponents/customSelectInputBase';
import {
  CDialog, CloseIcon, DialogTitleText, DialogTitleSubText, GridWrapper,
  ComplianceContainer,
  ComplianceTitle,
  ComplianceDescription,
  CheckboxStyled
} from './individualCustomerDialog.styles';
import { validateEmail } from '../auth/utils';

import { useStateValue } from '../../providers/stateProvider';
import { blockInvalidChar } from '../../utils/mobileCheck';
import { parseCustomerFields } from '../shared/utils';
import { CREATE_CUSTOMER_MUTATION, UPDATE_CUSTOMER_MUTATION } from '../../mutations/customers';
import SaleActionTypes from '../../providers/reducers/sales/salesTypes';
import { getCustomerObject } from '../../utils/utils';
import ConfirmDialog from '../shared/confirmDialog';
import SuccessDialog from '../shared/successDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualCustomerDialog = ({
  dialogOpen, closeDialog, autoAddCustomerToSale, isFromSaleScreen
}) => {
  const initialState = {
    customerName: '',
    mobile: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    region: '',
    city: 'Lagos',
    country: 'Nigeria',
    customerId: '',
    photo: '',
    notes: '',
    loyaltyPoint: 0,
    storeCredit: 0,
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
    mobileError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [editing, setEditing] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [ndpr, setNdpr] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [successText, setSuccessText] = useState({
    title: '',
    description: '',
  });

  const [{
    sale: { customerModalData },
  }, dispatch] = Object.values(useStateValue());

  const [createCustomer] = useMutation(CREATE_CUSTOMER_MUTATION);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER_MUTATION);

  const { nameError, emailError, mobileError } = errorState;
  const { id } = customerModalData || {};

  useEffect(() => {
    if (id) {
      const {
        customerId, name: customerName, photo, address,
        email, mobile, notes, loyaltyPoint, storeCredit
      } = parseCustomerFields(customerModalData);
      const [
        addressLine1, addressLine2, region, city, country
      ] = address;
      setEditing(true);
      setState((_state) => ({
        ..._state, customerId, customerName, photo,
        addressLine1, addressLine2, region, city, country,
        email, mobile, notes, loyaltyPoint, storeCredit
      }));
    } else setState((_state) => ({ ..._state, ...initialState }));
  }, [id, customerModalData]);

  useEffect(() => {
    if (editing) {
      setNdpr(true);
    }
  }, [editing]);

  const handleCloseDialog = () => {
    setState(initialState);
    setEditing(false);
    setNdpr(false);
    closeDialog();
  };

  const addCustomer = (photo) => {
    const {
      customerName, mobile, email, addressLine1,
      addressLine2, region, city, country, notes, loyaltyPoint, storeCredit
    } = state;
    createCustomer({
      variables: {
        customerName,
        photo,
        mobile,
        email,
        addressLine1,
        addressLine2,
        region,
        city,
        country,
        notes,
        loyaltyPoint: Number(loyaltyPoint) || 0,
        storeCredit: storeCredit || 0,
      },
      refetchQueries: ['customers']
    })
      .then(({ data }) => {
        const { message, customer: newCustomer } = data?.createCustomer || {};
        setSuccessOpenDialog(true);
        setSuccessText({ title: 'Customer Added Successfully', description: `Hi Pharm, ${message}` });
        handleCloseDialog();
        if (autoAddCustomerToSale) {
          dispatch({
            type: SaleActionTypes.UPDATE_CUSTOMER,
            payload: {
              openedCustomerModal: false,
              ...getCustomerObject(newCustomer)
            }
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCustomer = (photo) => {
    const {
      customerId, customerName, mobile, email,
      addressLine1, addressLine2, region, city, country,
      notes, loyaltyPoint, storeCredit
    } = state;
    updateCustomer({
      refetchQueries: ['customers'],
      variables: {
        customerId,
        customerName,
        photo,
        mobile,
        email,
        addressLine1,
        addressLine2,
        region,
        city,
        country,
        notes,
        loyaltyPoint: Number(loyaltyPoint),
        storeCredit
      }
    })
      .then(({ data }) => {
        const { message, customer: updatedCustomer } = data?.updateCustomer || {};
        toast.success(message);
        handleCloseDialog();
        if (isFromSaleScreen) {
          dispatch({
            type: SaleActionTypes.UPDATE_CUSTOMER,
            payload: {
              openedCustomerModal: false,
              ...getCustomerObject(updatedCustomer)
            }
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    if (!ndpr) {
      setOpenConfirmDialog(true);
    } else {
      setLoading(true);
      const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png';
      if (editing) return editCustomer(logoPlaceholder);
      return addCustomer(logoPlaceholder);
    }
  };

  const handleSaveButtonClick = () => {
    const { customerName, mobile } = state;
    if (customerName.length <= 1) {
      return setErrorState({
        ...errorState, nameError: true
      });
    } if ((mobile && (mobile?.length <= 10 || mobile?.length > 11)) || !mobile) {
      return setErrorState({
        ...errorState, mobileError: true
      });
    }
    // if (validateEmail(email)) {
    //   return setErrorState({
    //     ...errorState, emailError: true
    //   });
    // }++
    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'customerName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: (value.length <= 10)
        });
      default:
        return null;
    }
  };

  const ndprHandler = (e) => {
    const { checked } = e.target;
    setNdpr(checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'mobile' && value.length > 11) return;
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'customerName', label: 'Customer Name', placeholder: "Enter Customer's name",
      error: nameError, helperText: 'Customer Name is required.', required: true
    },
    { name: 'addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line' },
    {
      name: 'mobile', label: 'Phone Number', placeholder: 'Enter Phone Number', error: mobileError,
      helperText: 'Invalid Phone Number', required: true
    },
    { name: 'addressLine2', label: 'Address Line 2', placeholder: 'Enter Address Line' },
    {
      name: 'email', label: 'Email Address', placeholder: 'Enter Email Address', error: emailError
    },
    { name: 'region', label: 'Region', placeholder: 'Enter Region' },
    { name: 'storeCredit', label: 'Store Credit' },
    { name: 'city', label: 'City', placeholder: 'Select City' },
    { name: 'loyaltyPoint', label: 'Loyalty Point' },
    {
      name: 'country', label: 'Country', placeholder: 'Write a Note', options: ['Nigeria', 'Uganda', 'Kenya']
    }
  ];

  const returnTextField = (field, isProtected = false) => {
    const {
      name: fieldName, label, placeholder, error, helperText, required
    } = field;
    const value = state[fieldName];
    if (fieldName === 'term' || fieldName === 'country') {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          handleChange={handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value}
        disabled={isProtected}
        size="small"
        type={fieldName === 'mobile' ? 'number' : 'text'}
        onKeyDown={fieldName === 'mobile' && blockInvalidChar}
        error={error}
        helperText={error && helperText}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
        required={required}
      />
    );
  };

  return (
    <CDialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container>
          <Grid item container xs={6}>
            <DialogTitleText>
              {editing ? 'Edit Customer' : 'Add New Customer' }
            </DialogTitleText>
            <DialogTitleSubText>
              {editing
                ? 'Edit the form to update customer information'
                : 'Add customer information to the fields in the form'}
            </DialogTitleSubText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={2}>
            {topFields.map((field) => (
              <Grid item xs={6} key={field}>{returnTextField(field)}</Grid>
            ))}
            <Grid item xs={12}>
              <CustomInputBase
                multiline
                minRows={2}
                label="Note"
                placeholder="Write a Note"
                name="notes"
                value={state.notes}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <ComplianceContainer>
            <ComplianceTitle>NDPR Compliance</ComplianceTitle>
            <Box sx={{ display: 'flex', marginTop: '0.8rem' }}>
              <CheckboxStyled checked={ndpr} onChange={ndprHandler} />
              <ComplianceDescription>
                By checking the box, you acknowledge that you have obtained permission to share the above customer&apos;s information with us as the software provider.
              </ComplianceDescription>
            </Box>
          </ComplianceContainer>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSaveButtonClick}
            style={{ width: '11rem' }}
          >
            {loading
              ? <CircularProgress size={18} />
              : editing ? 'Save Changes' : 'Submit'}
          </Button>
        </DialogActions>

        <ConfirmDialog
          openDialog={openConfirmDialog}
          setOpenDialog={setOpenConfirmDialog}
          options={['Cancel', 'Ok']}
          title="NPDR Compliance not checked"
          desc="Please ensure the compliance field is checked to be able to successfully add a customer."
        />
      </GridWrapper>

      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={successText.title}
        desc={successText.description}
        option="ok"
      />
    </CDialog>
  );
};

IndividualCustomerDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  autoAddCustomerToSale: PropTypes.bool,
  isFromSaleScreen: PropTypes.bool.isRequired
};

IndividualCustomerDialog.defaultProps = {
  autoAddCustomerToSale: false
};

export default IndividualCustomerDialog;
