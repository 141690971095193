import React, { useState, useEffect } from 'react';
import { Grid, Paper, TableBody } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { ERP_PRODUCT_QUERY } from '../../../queries/products';
import { FIND_CATEGORY_QUERY } from '../../../queries/categories';
import { CREATE_PROMOTION_MUTATION, UPDATE_PROMOTION_MUTATION } from '../../../mutations/promotions';
import MainContent from '../../customComponents/mainContent';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { TableComponent, TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';
import CustomButton from '../../customComponents/customButton';
import TablePagination from '../../shared/tablePagination';
import ReturnRow from './returnRow';
import {
  GridContainer, TitleTextGridContainer, PrimaryTitle, TitleGrid, MenuButton,
  PaperWrapper, TableContainerWrapper, CustomCheckbox, DivFullWidth
} from './promoProducts.styles';
import ProductsFilter from './productsFilter';
import { Product } from '../../../providers/reducers/product/classes/Product';

import { useStateValue } from '../../../providers/stateProvider';

const initialState = {
  productName: '',
  supplier: '',
  manufacturer: '',
  categoryId: '',
  categories: [],
};

const headers = [
  { name: 'Product Name', width: '250px' }, { name: 'Promo Status', width: '150px' }, { name: 'Pack Size', width: '200px' },
  { name: 'Manufacturer', width: '250px' }, { name: 'Suppliers', width: '250px' }, { name: 'Cost Price', width: '150px' },
  { name: 'Selling Price', width: '150px' }
];

const PromoProducts = () => {
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [selected, setSelected] = useState([]);
  const [stateRows, setStateRows] = useState([]);

  const [{ user, promotions }] = Object.values(useStateValue());
  const { platform } = user;
  const {
    promoName, description, promoInput, promoValue, startDate, endDate
  } = promotions;

  const navigate = useNavigate();
  const { id: promoId, name: nameParam } = useParams();

  const {
    productName, supplier, manufacturer, categoryId,
  } = state;

  useEffect(() => {
    if (!promoId && (!promoName || !startDate || !endDate)) {
      navigate('/promotions/create');
    }
  }, [promoId, promoName, startDate, endDate]);

  const {
    loading: catLoading, data: allCategoriesData
  } = useQuery(FIND_CATEGORY_QUERY);

  useEffect(() => {
    if (!catLoading && allCategoriesData?.allCategories?.length) {
      const { allCategories } = allCategoriesData ?? {};
      const cats = allCategories.map(({ categoryName, id }) => (
        { categoryName, id }
      ));
      cats.unshift({ categoryName: 'none', id: '' });
      setState({ ...state, categories: cats });
    }
  }, [catLoading, allCategoriesData]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state, [name]: value
    }));
  };

  const [createPromotion, { loading: createLoading }] = useMutation(CREATE_PROMOTION_MUTATION);
  const [updatePromotion, { loading: updateLoading }] = useMutation(UPDATE_PROMOTION_MUTATION);
  const handleProceedReview = () => {
    if (promoId) {
      updatePromotion({
        variables: {
          promotionId: promoId,
          productIds: selected.map((id) => Number(id)),
        }
      }).then(({ data }) => {
        const { updatePromotion: { message, promotion } } = data ?? {};
        toast.success(message);
        navigate(`/promotions/review-confirm/${promotion?.id}/${nameParam || ''}`);
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      createPromotion({
        variables: {
          name: promoName,
          description,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          // endDate: new Date(new Date(endDate).setHours(23, 59, 59, 999)),
          productIds: selected.map((id) => Number(id)),
          unit: promoInput === 'By Amount' ? 'AMOUNT' : 'PERCENTAGE',
          value: promoValue,
        }
      }).then(({ data }) => {
        const { createPromotion: { message, promotion } } = data ?? {};
        toast.success(message);
        navigate(`/promotions/review-confirm/${promotion?.id}`);
      }).catch((err) => {
        toast.error(err?.message);
      });
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = stateRows.map((product) => product.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === stateRows.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const variables = {
    search: '',
    ...(productName.length > 2 && { productName }),
    ...(supplier.length > 2 && { supplier }),
    ...(manufacturer.length > 2 && { manufacturer }),
    status: 'enabled',
    category: Number(categoryId),
    pageCount,
    pageNumber,
  };

  const {
    loading, error, data, refetch
  } = useQuery(ERP_PRODUCT_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  useEffect(() => {
    if (data && data.erpProducts) {
      const { erpProducts } = data;
      setStateRows(erpProducts);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    erpProducts = [], productsTotalNumber = 0
  } = data || {};

  const rows = erpProducts?.map((product) => new Product(product, platform));

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item style={{ marginBottom: '0.5rem' }}>
          <TitleTextGridContainer item xs={12} md={6}>
            <MenuButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft style={{ fontSize: '1.8rem', fill: '#235A91' }} />
            </MenuButton>
            <PrimaryTitle style={{ fontSize: '14px', fontWeight: '500', color: '#235A91' }}>back</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={6}>
            <PrimaryTitle variant="h5" style={{ marginLeft: '0.5rem' }}>Select Products for Promo</PrimaryTitle>
          </TitleTextGridContainer>
          <Grid item container alignItems="center" justifyContent="flex-end" xs={12} md={6}>
            <CustomButton
              onClick={handleProceedReview}
              type="tertiary"
              header
              style={{
                width: '10rem', height: '3rem', background: '#235A91', borderRadius: '5px', border: '1px solid #235A91',
                boxShadow: '0px 4px 14px rgba(151, 151, 151, 0.19)', color: '#ffffff', fontSize: '12px', fontWeight: '700'
              }}
              disabled={!selected.length || createLoading || updateLoading}
            >
              Proceed & Review
            </CustomButton>
          </Grid>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <ProductsFilter
            state={state}
            handleFilterChange={handleFilterChange}
          />
          <TableContainerWrapper component={Paper}>
            {loading ? <ProductsTableLoader /> : (
              <TableComponent>
                <TableColumnHeaders>
                  <TableHeader style={{ minWidth: '50px' }}>
                    {renderCheckbox()}
                  </TableHeader>
                  {returnHeaders()}
                </TableColumnHeaders>
                <TableBody>
                  {rows.map((_row) => (
                    <ReturnRow
                      key={_row.id}
                      row={_row}
                      user={user}
                      selected={selected}
                      handleSelect={handleSelect}
                      refetchData={refetch}
                    />
                  ))}
                </TableBody>
              </TableComponent>
            )}
          </TableContainerWrapper>
          <DivFullWidth>
            {productsTotalNumber > 0 && (
            <TablePagination
              total={productsTotalNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
            )}
          </DivFullWidth>
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default PromoProducts;
