export const JSONParse = (jsonString) => {
  const processedString = jsonString?.replace(/'|None/gi, (matched) => (matched === 'None' ? '""' : '"'));
  try {
    return JSON.parse(processedString);
  } catch (err) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return {};
    }
  }
};
