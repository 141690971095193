import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table, TableContainer, TableBody, Button
} from '@mui/material';
import {
  TableCellStyled,
  TableRowStyled,
  TableHeadStyled,
  PrevNextFooter,
  BoxStyled
} from '../return-details.style';
import SaleItemRow from './saleItemRow';

const Sales = ({ row, isReturn }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { sale } = row;
  const [paginate, setPaginate] = React.useState([]);
  const [disableNext, setDisableNext] = React.useState(false);

  useEffect(() => {
    setPaginate(JSON.parse(localStorage.getItem('__PAGINATION__')));
  }, []);

  const nextHandler = () => {
    const idxOf = paginate?.indexOf(id);
    const increaseByOne = idxOf + 1;
    if (increaseByOne < paginate?.length) {
      const next = paginate[increaseByOne];
      navigate(`/sale-details/${next}`);
    }
    setDisableNext(true);
  };

  const prevHanlder = () => {
    const idxOf = paginate?.indexOf(id);
    const reduceByOne = idxOf - 1;
    if (reduceByOne >= 0) {
      const prev = paginate[reduceByOne];
      navigate(`/sale-details/${prev}`);
    }
  };

  return (
    <>
      <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px" mt={4}>
        <TableContainer style={{ marginTop: '1rem' }}>
          <Table style={{ width: '100%' }}>
            <TableHeadStyled>
              <TableRowStyled>
                <TableCellStyled>
                  {isReturn ? 'Return Items' : 'Sales Items'}
                  (
                  {sale?.saleItems && sale?.saleItems.length}
                  )
                </TableCellStyled>
                <TableCellStyled>Pack Size</TableCellStyled>
                <TableCellStyled>Qty in Stock</TableCellStyled>
                <TableCellStyled>
                  {isReturn ? 'Qty Returned' : 'Qty Sold'}
                </TableCellStyled>
                <TableCellStyled>Item Cost</TableCellStyled>
                <TableCellStyled>Discount (%)</TableCellStyled>
                <TableCellStyled>UOM</TableCellStyled>
                <TableCellStyled>VAT%</TableCellStyled>
                <TableCellStyled>Item Price</TableCellStyled>
                <TableCellStyled>Promo Value (%)</TableCellStyled>
              </TableRowStyled>
            </TableHeadStyled>
            <TableBody>
              {sale?.saleItems.map((saleItem) => (
                <SaleItemRow key={saleItem.id} saleItem={saleItem} sale={sale} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BoxStyled>
      <PrevNextFooter>
        <Button variant="outlined" data-testid="prev-btn" onClick={prevHanlder}>
          Previous
        </Button>
        <Button variant="contained" disabled={disableNext} data-testid="next-btn" onClick={nextHandler}>
          Next
        </Button>
      </PrevNextFooter>
    </>
  );
};

Sales.propTypes = {
  row: PropTypes.instanceOf(Object),
  isReturn: PropTypes.bool
};
Sales.defaultProps = {
  row: {},
  isReturn: false
};

export default Sales;
