import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import CardLoader from '../../shared/cardLoader';
import { numberFormater } from '../../shared/utils';
import {
  TypographyStyled,
  TypographyCountStyled,
  Title,
  BoxStyled
} from '../dashboardContainer.styles';

const ProductActivityCards = ({ response, getDate }) => {
  const { loading, error, data } = response;
  const { from, to } = getDate;

  const activity = [
    {
      id: 0,
      title: 'Total Product',
      total: data?.supplyChainDashboard?.totalProductsInInventory || 0,
      link: `/inventory/products/all${from && to && `/${from}/${to}`}`,
      dataTest: 'totalProduct'
    },
    {
      id: 1,
      title: 'Enabled',
      total: data?.supplyChainDashboard?.totalEnabledProducts || 0,
      link: `/inventory/products/enabled${from && to && `/${from}/${to}`}`,
      dataTest: 'enabled'
    },
    {
      id: 2,
      title: 'Disabled',
      total: data?.supplyChainDashboard?.totalDisabledProducts || 0,
      link: `/inventory/products/disabled${from && to && `/${from}/${to}`}`,
      dataTest: 'disabled'
    },
    {
      id: 3,
      title: 'Sold Out',
      total: data?.supplyChainDashboard?.totalSoldOutProducts || 0,
      link: `/inventory/products/sold_out${from && to && `/${from}/${to}`}`,
      dataTest: 'soldOut'
    }
  ];

  const navigate = useNavigate();
  const onRouteHanlder = (path) => {
    navigate(path);
  };

  if (loading || error) return <CardLoader cols={4} />;
  return (
    <>
      <div style={{ marginTop: '3rem' }} />
      <Title>Product Overview</Title>
      <Grid container spacing={4} style={{ marginTop: '.7rem' }}>
        {activity?.map((card) => (
          <Grid item xs={6} xl={3} key={card?.id} data-testid={card?.dataTest} onClick={() => onRouteHanlder(card?.link)}>
            <BoxStyled
              p={5}
              bgcolor="background.paper"
              borderRadius="20px"
              className="text-center"
              style={{ textAlign: 'center', cursor: 'pointer' }}
            >
              <TypographyStyled>{card?.title}</TypographyStyled>
              <TypographyCountStyled>
                {numberFormater(card?.total || 0)}
              </TypographyCountStyled>
            </BoxStyled>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ProductActivityCards.propTypes = {
  response: PropTypes.isRequired,
  getDate: PropTypes.instanceOf(Object).isRequired
};

export default ProductActivityCards;
