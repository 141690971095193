import React, { useEffect, useState } from 'react';
import { TableBody, TableContainer, Table } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import {
  TableHeader,
  GridContainer,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  SubTitle,
  ButtonOutlined,
  CustomCheckbox
} from './preloaded.styles';
import CustomSearchField from '../shared/CustomSearchField';
import TablePagination from '../shared/tablePagination';
import ReturnRow from './returnRow';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import ProductButtonsPopper from './productButtonsPopper';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import { useStateValue } from '../../providers/stateProvider';
import { DeleteIcon } from '../../assets/svgs';
import IndividualProductDialog from '../shared/uploadProduct/individual/individualProductDialog';
import CreateProduct from '../sales/createProduct';
import { ERP_PRODUCT_QUERY } from '../../queries/products';
import { Product } from '../../providers/reducers/product/classes/Product';
import { DELETE_PRODUCTS } from '../../mutations/products';
import { EXPORT_PRODUCT_MUTATION } from '../../mutations/reports';
import ConfirmDialog from '../shared/confirmDialog';

const headers = [
  'S/N',
  'Created Date',
  'SKU',
  'Product Name',
  'Pack Size',
  'Action'
];

const initialState = {
  searchText: '',
  search: ''
};

const PreloadedInventory = () => {
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [stateRows, setStateRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [uploadCsvDialog, setUploadCsvDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [prodId, setProdId] = useState(null);

  const [
    {
      user,
      product: { addProductDialogOpen }
    }, dispatch] = Object.values(useStateValue());
  const { platform } = user;

  const { searchText, search } = state;

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, search: searchText });
    } else if (!searchText) {
      setState({ ...state, search: '' });
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}
    >
      {header}
    </TableHeader>
  ));

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = stateRows.map((product) => product.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === stateRows.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  const toggleIndividualDialog = () => {
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const handleCsvDialog = () => setUploadCsvDialog(!uploadCsvDialog);

  const productActionHandler = (action) => {
    switch (action) {
      case 'individual_prod': return toggleIndividualDialog();
      case 'upload_order_csv': return handleCsvDialog();
      default:
        break;
    }
  };

  // Load preloaded products
  const {
    loading, error, data, refetch
  } = useQuery(ERP_PRODUCT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: '',
      productName: search,
      pageCount,
      pageNumber
    }
  });

  const [deleteProducts, { loading: deleteLoading }] = useMutation(DELETE_PRODUCTS);
  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_PRODUCT_MUTATION);

  // Export as CSV
  const handleExport = async () => {
    const randomNum = Math.floor(Math.random() * 10000);
    const name = `preloaded_products_${randomNum}`;
    exportMutation({
      variables: {
        search: '',
        productName: search,
        type: 'preloaded_inventory',
        name
      }
    })
      .then(({ data: { exportCsv: { message } } }) => {
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    if (data && data.erpProducts) {
      const { erpProducts } = data;
      setStateRows(erpProducts);
    }
  }, [data]);

  // Deletion of multiple and single products
  const deleteHandler = (pId = null) => {
    setConfirmDialog(true);
    setProdId(pId);
  };

  useEffect(() => {
    if (!confirmStatus) return;
    setConfirmStatus(false);
    let productIds;
    if (prodId.length) {
      productIds = prodId;
    } else {
      productIds = selected.map((str) => parseInt(str, 10));
    }
    if (!productIds.length) return;
    deleteProducts({
      variables: { productIds }
    }).then(({ data: { batchDeleteProducts: { message } } }) => {
      toast.success(message);
      setProdId(null);
      refetch();
    }).catch((err) => toast.error(err.message));
  }, [confirmStatus]);

  if (error) return <div>{error.message}</div>;
  const {
    erpProducts = [], productsTotalNumber = 0
  } = data || {};

  const rows = erpProducts?.map((product) => new Product(product, platform));

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <TitleTextGridContainer>
            <div>
              <PrimaryTitle variant="h5">Preloaded Inventory</PrimaryTitle>
              <SubTitle>View and manage all products added by admin to preload the users inventory</SubTitle>
            </div>
            <ProductButtonsPopper actionHandler={productActionHandler} autoLoading={false} />
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchFlex>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search for Products"
              handleChange={(e) => handleSearch(e.target.value)}
              style={{ width: '100%' }}
              data-testid="searchInput"
            />

            <ButtonOutlined
              disabled={deleteLoading}
              onClick={deleteHandler}
            >
              <DeleteIcon style={{ fontSize: '1.4rem' }} />
              { deleteLoading ? 'Loading ...' : 'Delete' }
            </ButtonOutlined>

            <ButtonOutlined
              disabled={exportLoading}
              onClick={handleExport}
            >
              { exportLoading ? 'Loading ...' : 'Export as CSV' }
            </ButtonOutlined>
          </SearchFlex>
          <TableGrid item container>
            {loading ? (
              <ProductsTableLoader />
            ) : (
              <TableContainer>
                <Table>
                  <MainTableHead>
                    <TableHeader style={{ width: '7rem' }}>
                      {renderCheckbox()}
                    </TableHeader>
                    {returnHeaders()}
                    <TableHeader />
                  </MainTableHead>
                  <TableBody>
                    {
                    rows?.length
                      ? rows?.map((item, index) => {
                        const idx = item?.id;
                        return <ReturnRow key={idx} row={item} sn={index + 1} handleSelect={handleSelect} selected={selected} deleteHandler={deleteHandler} refetch={refetch} />;
                      })
                      : (
                        <MainTableRow>
                          <BodyCell colspan={7} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                        </MainTableRow>
                      )
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TableGrid>
          <FooterWrapper item container>
            {productsTotalNumber > 0 && (
              <TablePagination
                total={productsTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </GridContainer>

      <IndividualProductDialog
        dialogOpen={addProductDialogOpen}
        closeDialog={() => toggleIndividualDialog()}
      />

      <ConfirmDialog
        openDialog={confirmDialog}
        setOpenDialog={setConfirmDialog}
        title="Delete Product?"
        desc="Are you sure you want to delete the selected product(s)?"
        setStatus={setConfirmStatus}
        options={['Cancel', 'Yes Delete']}
      />

      <CreateProduct
        openProdDialog={uploadCsvDialog}
        toggleHandler={handleCsvDialog}
      />
    </MainContent>
  );
};

export default PreloadedInventory;
