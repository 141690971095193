import {
  Grid,
  Typography,
  IconButton,
  MenuItem,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const ButtonEllipsis = styled(Button)`
  padding: 0;
  background: none !important;
  cursor: pointer;
  border: none;
  box-shadow: none;
`;

export const GridContainer = styled(Grid)`
  padding: 15px 25px;
  width: 20rem;
  align-items: center;
  cursor: pointer;

  @media (min-width: 992px) {
    padding: 0px;
    width: 200px;
  }
`;

export const GridWrapper = styled(Grid)`
  shadow: none;
  padding: 0.8rem 0;
  display: flex;
  flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 1.5rem;

  @media (min-width: 992px) {
    margin-left: 0px;
    font-size: 1rem;
    padding: 4px 12px;
  }
`;

export const ItemWrapper = styled(IconButton)`
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
