import { styled } from '@mui/material/styles';
import {
  TableCell, Paper, TextField, Button,
  Typography, Grid, TableBody
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import TelegramIcon from '@mui/icons-material/Telegram';

export const ShowingText = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: #303030;
`;

export const HeaderGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  border-top: 2px solid rgba(224, 224, 224, 1);
  padding: 1em 0;
`;

export const CTableBody = styled(TableBody)`
  width: 100%;
  display: ${({ empty }) => (empty ? 'flex' : 'table-row-group')};
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1), &:nth-child(4) {
      width: 17%;
    }
    &:nth-child(2) {
      width: 21%;
    }
    &:nth-child(3), &:nth-child(5), &:nth-child(6) {
      width: 15%;
    }
  }
`;

export const TableColumnHeaders = styled('div')`
  display: flex;
  padding: 0 35px;
`;

export const TableColumnHeaderTitle = styled('p')`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 23px;
  white-space: nowrap;
  padding-bottom: 12px;
  margin-bottom: 0;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
`;

export const TableContainer = styled(Paper)`
  border: 1px solid #eee;
  padding: 20px 0;
`;

export const TableHeaderCell = styled(TableCell)`
  padding-left: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;
export const TableBodyCell = styled(TableCell)`
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-transform: capitalize;
  padding: 14px;
  padding-left: 0;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
`;

export const TableWrapper = styled('div')`
  overflow: hidden;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-bottom:2rem;
  }
`;
export const InputTextField = styled(TextField)`
  width: '25ch',
`;

export const CancelReportButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  background-color: transparent;
  color: #424242;
  border-radius: 1.5px solid #424242;;
  border-radius: 15px;

  &:disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 15px;
    border-radius: 10px;
    margin-right: 30px;
  }
`;

export const EmptyGrid = styled(Grid)`
  align-items: center;
  padding-top: 8rem;
  color: #606060;
  min-height: 30rem;

`;

export const EmptyImg = styled('img')`
  width: 6rem;
`;

export const SaveProgressIcon = styled(CachedIcon)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const GenerateReportIcon = styled(TelegramIcon)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ProductItemsTotal = styled(Typography)`
  font-size: 30px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const SaleSearchResult = styled('div')`
  background: white;
  width: 50%;
  position: absolute;
  right: 1.5%;
  top: 58%;
  z-index: 200;
  box-shadow: 1px 2px 3px #00000029;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid #eeeeee;
`;
