import {
  Button, Dialog, DialogContent, Grid
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px;
    padding-bottom: 1.5rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 400px;
  height: 450px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  
  @media(max-width: 991px) {
    width: 100%;
    height: 55rem;
  }
`;

// export const WrapperGrid = styled(Grid)`
//   width: 30vw;
//   height: 35vw;
//   padding: 24px;

//   @media(max-width: 991px) {
//     width: 75vw;
//     height: 95vw;
//   }
// `;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;

  @media(max-width: 991px) {
    width: 50px;
    height: 50px;
  }
`;

export const GigImg = styled('img')`
  width: 13rem;
  height: 13rem;

  @media(max-width: 991px) {
    width: 20rem;
    height: 20rem;
  }
`;

export const Heading = styled('span')`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #303030;

   @media(max-width: 991px) {
    font-size: 40px;
    margin-top: 10px;
  }
`;

export const Subheading = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 164.2%;
  color: #606060;
  padding: 1.3rem 0 .8rem 0;

   @media(max-width: 991px) {
    font-size: 30px;
    margin-top: 15px;
  }
`;

export const ContentWrapper = styled(Grid)`
  justify-content: center;
  text-align: center;
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 178px;
  height: 48px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #303030 !important;
  font-weight: 700;
  font-size: 12px;
`;

export const ActionContent = styled(Grid)`
  justify-content: space-between;
  padding: 0 1rem;
`;
