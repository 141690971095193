import React, { useEffect, useState } from 'react';
import { Checkbox, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { AutoCompleteStyled, AutocompleteField, List } from './selectWCheckbox.styles';

export const SelectWCheckbox = ({
  list, placeholder, selectedOptions, setSelectedOptions, handleDialog, disabled,
  extension, automatic, setAutomatic
}) => {
  const handleChange = (event, values) => {
    const selectedVal = values.filter((item) => item?.value !== 'Custom' && item?.value !== 'Automatic');
    setSelectedOptions(selectedVal);
  };

  const [refresh, setRefresh] = useState(false);

  const notShowExtensionList = ['Automatic', 'Zero Products'];

  const removeOption = (value) => {
    const filtered = selectedOptions.filter((option) => option.value !== value);
    setSelectedOptions(filtered);
  };

  const automaticHandler = () => setAutomatic(!automatic);

  const handleSelected = (val) => {
    const chk = selectedOptions.find(({ value }) => value === val);
    if (!chk) { selectedOptions.push({ value: val }); } else {
      const rm = selectedOptions.filter(({ value }) => value !== val);
      setSelectedOptions(rm);
    }
    setRefresh(!refresh); // Force update state on value change
  };

  useEffect(() => {
    if (selectedOptions.length) { setSelectedOptions(selectedOptions); }
  }, [refresh]);

  return (
    <AutoCompleteStyled
      multiple
      options={list}
      disableCloseOnSelect
      getOptionLabel={(option) => option.value}
      value={selectedOptions}
      disabled={!disabled}
      renderTags={(values) => values.map((val) => {
        const { value } = val;
        return (
          <Chip
            style={{ marginRight: '5px' }}
            key={value}
            label={`${value} ${!notShowExtensionList.includes(value) ? extension : ''}`}
            onDelete={() => {
              removeOption(value);
            }}
            disabled={!disabled}
          />
        );
      })}
      getOptionSelected={(option, value) => option.value === value.value}
      renderOption={(props, option, { selected }) => {
        const { value } = option;
        if (value === 'Custom') {
          return (
            <List onClick={handleDialog}>
              <Checkbox />
              {value}
            </List>
          );
        }
        return (
          <List onClick={() => {
            if (value === 'Automatic') automaticHandler();
            else handleSelected(value);
          }}
          >
            <Checkbox checked={value === 'Automatic' ? automatic
              : selectedOptions.map(({ value: chkVal }) => chkVal).includes(value)}
            />
            {`${value === '0' ? 'Zero' : value} ${!notShowExtensionList.includes(value) ? extension : ''}`}
          </List>
        );
      }}
      style={{ width: 700 }}
      onChange={handleChange}
      renderInput={(params) => (
        <AutocompleteField
          {...params}
          variant="outlined"
          placeholder={placeholder}
        />
      )}
    />
  );
};

SelectWCheckbox.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  setSelectedOptions: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.string,
  selectedOptions: PropTypes.instanceOf(Array).isRequired,
  handleDialog: PropTypes.func,
  disabled: PropTypes.bool,
  extension: PropTypes.string,
  automatic: PropTypes.bool,
  setAutomatic: PropTypes.bool
};

SelectWCheckbox.defaultProps = {
  placeholder: '',
  handleDialog: () => null,
  disabled: false,
  extension: '',
  automatic: false,
  setAutomatic: false
};

export default SelectWCheckbox;
