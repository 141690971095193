import {
  Box, Paper, Grid, Typography, TextField, Button, Stepper,
  LinearProgress, Step, StepLabel, StepContent
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentBox = styled(Box)`
  padding: 3rem;
`;

export const TopPaper = styled(Grid)`
  border-radius: 1rem;
`;

export const TrackGrid = styled(Grid)`
  padding: 3rem;
`;

export const HeaderTypo = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  @media(min-width: 992px) {
    font-size: 1.7rem;
  }
  @media(max-width: 991px) {
    margin-bottom: 2rem;
  }
`;

export const SubHeader = styled(Typography)`
  font-size: .9rem;
  color: #606060;
  font-weight: 300;
  margin-bottom: 1rem;

  @media (max-width: 991px) {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
`;

export const TrackImg = styled('img')`
  height: 100%;
`;

export const TrackText = styled(Typography)`
  font-size: .9rem;
  color: #606060;
  font-weight: 300;
  margin-bottom: .5rem;

  @media(max-width: 991px) {
    font-size: 1.7rem;
    font-weight: 400;
  }
`;

export const CTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 2rem;
  }
`;

export const TrackOrderButton = styled(Button)`
  align-items: center;
  padding: 2px 15px;
  background-color: #303030;
  color: #FFFFFF;
  border-radius: 15px;
  height: 70px;

  &:hover {
    background-color: #303030;
  }

  &:disabled {
    color: #FFFFFF;
    background-color: #303030;
  }

  @media(min-width: 992px) {
    height: 3.5rem;
    padding: 0px 15px;
    border-radius: .5rem;
    width: 13rem;
  }

  @media(max-width: 991px) {
    width: 100%;
    height: 100px;
  }
`;

export const TrackOrderButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: .9rem;
    font-weight: 500;
  }
`;

export const BottomPaper = styled(Paper)`
  border-radius: 1rem;
  padding: 3rem;
  margin-top: 2rem;
`;

export const EmptyBox = styled(Box)`
  display: block;
  margin: auto;
  text-align: center;
`;

export const SearchGif = styled('img')`
  width: 10rem;
`;

export const Typo = styled(Typography)`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: .95rem;
  font-weight: 550;
  color: #545454;
  margin-bottom: .5rem;
  margin-left: 13px;

  @media (max-width: 991px) {
    font-size: 2.2rem;
    margin-left: 40px;
  }
`;

export const TitleText = styled(Typography)`
  color: #545454;
  font-size: .8rem;
  margin-left: 13px;

  @media (max-width: 991px) {
    font-size: 1.7rem;
    margin-left: 40px;
    margin-top: 1rem;
  }
`;

export const DateText = styled(Typography)`
  color: #545454;
  font-size: .8rem;

  @media (max-width: 991px) {
    margin-left: 40px;
    margin-top: 2rem;
    font-weight: 500;
    font-size: 1.7rem;
  }
`;

export const DispatchStepper = styled(Stepper)`
  padding: 0rem 0 1rem;
  width: 100%;
  background: none;
  justify-content: space-between;
  &.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    display: none;
}
`;

export const DispatchTypo = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 600;

  @media (min-width: 991px) {
    font-size: 1.2rem;
  }
`;

export const StepperIcon = styled('img')`
  width: 30px;
  @media (max-width: 991px) {
    width: 60px;
  }
`;

export const CustomStepper = styled(Stepper)`
  &.MuiStepper-vertical {
    flex-direction: column-reverse;
  }
  & .MuiStepContent-root {
    border-left: 1px solid #07DE10;
    margin-left: 16px;
  }
  & .MuiStepConnector-line {
    border-color: #07DE10;
    min-height: 50px;
    margin-left: 2px;
  }

  @media (max-width: 991px) {
    & .MuiStepContent-root {
      border-left: 1px solid #07DE10;
      margin-left: 29px;
    }
    & .MuiStepConnector-line {
      border-color: #07DE10;
      min-height: 50px;
      margin-left: 17px;
    }
  }
`;
