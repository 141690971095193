import React from 'react';
import { Grid, Table } from '@mui/material';
import PropTypes from 'prop-types';
import {
  TableContainerWrapper, TableComponent, TableHeader, PrimaryTitle,
  MainTableHead, TableGrid, MainTableBody
} from './businesses.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import Filter from './filter';

const headers = [
  'S/N', 'Sub-business Name', 'Business Email Address', 'Business Address', 'Phone Number', 'Status'
];

const Businesses = ({
  state, rows, handleSearch, loading
}) => {
  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header}>{header}</TableHeader>
  ));

  return (
    <Grid item container justifyContent="flex-end" xs={9}>
      <TableContainerWrapper elevation={0}>
        <PrimaryTitle>
          All Sub - businesses
        </PrimaryTitle>
        <Filter
          state={state}
          handleSearch={handleSearch}
        />
        <TableGrid item container>
          {loading
            ? <ProductsTableLoader />
            : (
              <TableComponent item container>
                <Table>
                  <MainTableHead>
                    {returnHeaders()}
                  </MainTableHead>
                  <MainTableBody item container>
                    {rows.map((_row, indx) => (
                      <ReturnRow
                        indx={indx}
                        key={_row.id}
                        row={_row}
                      />
                    ))}
                  </MainTableBody>
                </Table>
              </TableComponent>
            )}
        </TableGrid>
      </TableContainerWrapper>
    </Grid>
  );
};

Businesses.propTypes = {
  state: PropTypes.shape(Object),
  rows: PropTypes.shape(Array),
  loading: PropTypes.bool,
  handleSearch: PropTypes.func.isRequired,
};

Businesses.defaultProps = {
  state: {},
  rows: [],
  loading: false,
};

export default Businesses;
