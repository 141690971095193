import React, { useState, useEffect } from 'react';
import {
  DialogContentText,
  Slide,
  Grid,
  TableContainer,
  Box,
  InputAdornment,
  Button
} from '@mui/material';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import toast from 'react-hot-toast';
import { JSONParse } from '../../../../../utils/json';
import SuccessDialog from '../../../../shared/successDialog';
import {
  DialogContainer,
  CardTitle,
  CardSubTitle,
  DialogContentStyled,
  DefaultTable,
  DefaultTrHead,
  DefaultTd,
  DefaultTr,
  DefaultTdBody,
  FooterButtonStyled,
  CardHeader,
  BatchCardTitle,
  CustomInputBase,
  DefaultTdStretch
} from '../return-popper.style';
import { CaretUpIcon, CaretDownIcon } from '../../../../../assets/svgs';
import CommentDialog from './commentDialog';
import ConfirmDialog from '../../../../shared/confirmDialog';
import { useCreateReturn } from './createReturn';
import { toTitleCase } from '../../../../../utils/toTitleCase';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function BatchDialog({
  row,
  calDiscount,
  refetch,
  subTotal,
  batchDialog,
  openBatchDialogHandler,
  cartBatch,
  setCartBatch
}) {
  const {
    sale: {
      id: saleId, total, discount
    }
  } = row;

  const totalDiscount = discount / (total / 100);

  const [reason, setReason] = useState('');
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  let sumNQ = 0;

  const {
    createReturnMethod, loading, data, error, complete
  } = useCreateReturn();

  const getOrderId = (json) => {
    const converted = JSON.stringify(json);
    return converted.split(':')[0].split('"')[1];
  };

  const handleCloseDialog = () => {
    setOpenCommentDialog(!openCommentDialog);
  };

  const requestBody = () => {
    const returnItems = [];
    let computeTotalBP = 0;
    cartBatch.map((batches) => {
      let sumNewQuantity = 0;
      let sumBPrice = 0;
      const returnBatches = [];
      batches.itemBatches.map((batch) => {
        let itemPrice = 0;
        if (batch?.newQuantity !== 0 || '') {
          itemPrice = batches.itemPrice * batch.newQuantity;
          sumNewQuantity += batch.newQuantity;
          const orderId = getOrderId(JSONParse(batch?.orderProducts));
          const computeReturnBatch = {
            itemBatchQuantity: batch?.newQuantity,
            orderProductId: orderId,
            saleItemBatchId: batch?.id
          };
          sumBPrice += itemPrice;
          returnBatches.push(computeReturnBatch);
        }
      });

      // -- Begin calcuate discount for each batch
      let eachDiscount = 0;
      calDiscount.map((caldisc) => {
        if (batches?.productId === caldisc?.productId) {
          eachDiscount = caldisc.productDiscount;
        }
      });
      // -- End calculate discount for each batch

      computeTotalBP += (sumBPrice - (eachDiscount / 100) * sumBPrice);

      if (returnBatches.length) {
        const cartDetails = {
          productId: batches?.productId,
          quantity: sumNewQuantity,
          reason,
          resellable: 'True',
          itemBatches: returnBatches,
        };
        returnItems.push(cartDetails);
      }
    });
    const reqBody = {
      cart: returnItems,
      returnSaleId: saleId,
      paymentsMade: [
        {
          amount: computeTotalBP - ((totalDiscount / 100) * computeTotalBP),
          paymentMethod: 'CASH'
        }
      ],
      transactionDate: moment(new Date()).format('YYYY-M-DD'),
      version: 1
    };
    return { reqBody };
  };

  const continueHandler = () => {
    // Close batch dialog
    openBatchDialogHandler();
    // Open comment dialog
    handleCloseDialog();
  };

  const handleReturnSubmit = () => {
    // Close comment dialog
    handleCloseDialog();
    // Open confirm dialog
    setOpenConfirmDialog(true);
  };

  if (confirmStatus) {
    setConfirmStatus(false);
    const { reqBody } = requestBody();
    createReturnMethod(reqBody);
  }

  const [isChanged, setIsChanged] = useState(false);
  const handleChange = (e, batchid) => {
    setIsChanged(!isChanged);
    const { value } = e?.target ?? '';
    // eslint-disable-next-line radix
    if (value && +value < 0) return;
    cartBatch?.map((cart) => cart?.itemBatches.map((batch) => {
      if (batch?.id === batchid) {
        // eslint-disable-next-line radix
        if (parseFloat(value) > batch?.quantity) return;
        // eslint-disable-next-line no-param-reassign, radix
        batch.newQuantity = parseFloat(value);
      }
    }));
  };

  const handleIncrement = (batchid) => {
    setIsChanged(!isChanged);
    cartBatch?.map((cart) => cart?.itemBatches.map((batch) => {
      if (batch?.id === batchid) {
        const newQ = batch.newQuantity;
        // eslint-disable-next-line no-param-reassign, radix
        if (batch.newQuantity === '') batch.newQuantity = 0;
        batch.newQuantity += 1;
        if (batch.newQuantity > batch?.quantity) batch.newQuantity = newQ;
      }
    }));
  };

  const handleDecrement = (batchid) => {
    setIsChanged(!isChanged);
    cartBatch?.map((cart) => cart?.itemBatches.map((batch) => {
      if (batch?.id === batchid) {
        // eslint-disable-next-line no-param-reassign, radix
        if (batch.newQuantity === '') batch.newQuantity = 0;
        if (batch.newQuantity <= 0) return;
        batch.newQuantity -= 1;
      }
    }));
  };
  // Update state if value is quantity value is changed
  useEffect(() => {
    setCartBatch(cartBatch);
  }, [isChanged]);

  if (!loading && data && complete) {
    // refetch();
    setOpenCommentDialog(false);
    setOpenConfirmDialog(false);
    setSuccessOpenDialog(true);
  }

  if (!loading && error && complete) {
    refetch();
    toast.error(error?.message);
    // Close all dialog
    setOpenCommentDialog(false);
    setOpenConfirmDialog(false);
    setSuccessOpenDialog(false);
  }
  return (
    <>
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title="Product on Return List"
        desc="Hi User, your products are currently on return list"
        option="ok"
        refetch={refetch}
      />
      <CommentDialog
        openCommentDialog={openCommentDialog}
        handleCloseDialog={handleCloseDialog}
        setReason={setReason}
        handleReturnSubmit={handleReturnSubmit}
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Are you sure you want to return?"
        desc="Hi User, are you sure you want to return the products"
        options={['Cancel', 'Yes, Return']}
        setStatus={setConfirmStatus}
        loading={loading}
      />
      <DialogContainer
        open={batchDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={openBatchDialogHandler}
      >
        <DialogContentStyled>
          <DialogContentText>
            <Grid style={{ marginTop: '1rem' }}>
              <Box display="flex" justifyContent="space-between">
                <CardHeader>
                  <CardTitle>Product Batches</CardTitle>
                  <CardSubTitle>Select batches of products that you want to return</CardSubTitle>
                </CardHeader>
                <Close
                  fontSize="small"
                  onClick={openBatchDialogHandler}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Grid>
            <TableContainer>
              {cartBatch
                && cartBatch.map((item) => (
                  <div key={item?.id} style={{ marginBottom: '1.2rem' }}>
                    <BatchCardTitle>{item?.brandName}</BatchCardTitle>
                    <DefaultTable>
                      <DefaultTrHead>
                        <DefaultTdStretch>Batch Number</DefaultTdStretch>
                        <DefaultTd>Qty in Sale</DefaultTd>
                        <DefaultTd>Qty to Return</DefaultTd>
                        <DefaultTd>UOM</DefaultTd>
                      </DefaultTrHead>
                      {item?.itemBatches.map((batch) => {
                        if (batch?.quantity !== 0) {
                          sumNQ += batch.newQuantity;
                          const { uom } = JSONParse(batch?.meta);
                          return (
                            <DefaultTr key={batch?.id}>
                              <DefaultTdBody>{batch?.id}</DefaultTdBody>
                              <DefaultTdBody>{batch?.quantity}</DefaultTdBody>
                              <DefaultTdBody>
                                <CustomInputBase
                                  type="number"
                                  placeholder="0"
                                  min={1}
                                  size="medium"
                                  value={batch.newQuantity}
                                  onChange={(e) => handleChange(e, batch?.id)}
                                  endAdornment={(
                                    <InputAdornment position="end">
                                      <Grid item container direction="column">
                                        <CaretUpIcon
                                          style={{
                                            width: '1rem',
                                            height: '1rem',
                                            cursor: 'pointer'
                                          }}
                                          onClick={() => handleIncrement(batch?.id)}
                                        />
                                        <CaretDownIcon
                                          style={{
                                            width: '1rem',
                                            height: '1rem',
                                            cursor: 'pointer'
                                          }}
                                          onClick={() => handleDecrement(batch?.id)}
                                        />
                                      </Grid>
                                    </InputAdornment>
                                  )}
                                />
                              </DefaultTdBody>
                              <DefaultTdBody>{uom ? toTitleCase(uom) : 'N/A'}</DefaultTdBody>
                            </DefaultTr>
                          );
                        }
                      })}
                    </DefaultTable>
                  </div>
                ))}
            </TableContainer>
          </DialogContentText>
          <FooterButtonStyled>
            <Button variant="outlined" onClick={openBatchDialogHandler}>
              Cancel
            </Button>
            {
              (sumNQ > 0 && sumNQ) && (
              <Button variant="contained" onClick={continueHandler}>
                Return
              </Button>
              )
            }
          </FooterButtonStyled>
        </DialogContentStyled>
      </DialogContainer>
    </>
  );
}
BatchDialog.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  calDiscount: PropTypes.instanceOf(Array).isRequired,
  refetch: PropTypes.func.isRequired,
  subTotal: PropTypes.number.isRequired,
  cartBatch: PropTypes.isRequired,
  setCartBatch: PropTypes.isRequired,
  batchDialog: PropTypes.isRequired,
  openBatchDialogHandler: PropTypes.func.isRequired
};
