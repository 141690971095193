const SaleActionTypes = {
  AGGREGATE_BATCHES_TO_CART: 'AGGREGATE_BATCHES_TO_CART',
  UPDATE_BATCH_TO_CART: 'UPDATE_BATCH_TO_CART',
  OPEN_BATCH_MODAL: 'OPEN_BATCH_MODAL',
  UPDATE_CART_QUANTITY: 'UPDATE_CART_QUANTITY',
  REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
  UPDATE_CART_UOM: 'UPDATE_CART_UOM',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  RESET_CUSTOMER: 'RESET_CUSTOMER',
  UPDATE_BATCH_TO_SELL_QUANTITY: 'UPDATE_BATCH_TO_SELL_QUANTITY',
  TOGGLE_SELECT_PRODUCT_BATCH: 'TOGGLE_SELECT_PRODUCT_BATCH',
  CLEAR_CART: 'CLEAR_CART',
  ADD_OUT_OF_STOCK_BATCH: 'ADD_OUT_OF_STOCK_BATCH',
  TOGGLE_MISSED_SALE: 'TOGGLE_MISSED_SALE',
  ADD_PERC_DISCOUNT: 'ADD_PERC_DISCOUNT',
  UPDATE_SALE_STATE: 'UPDATE_SALE_STATE',
  HOLD_SALE: 'HOLD_SALE',
  RETURN_HELD_SALE: 'RETURN_HELD_SALE',
  ADD_PRODUCT_DISCOUNT: 'ADD_PRODUCT_DISCOUNT',
  ADD_CART_ITEM_PRESCRIPTION: 'ADD_CART_ITEM_PRESCRIPTION',
  UPDATE_BATCH_QUANTITY_TO_SELL: 'UPDATE_BATCH_QUANTITY_TO_SELL'
};
export default SaleActionTypes;
