import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Fade, List, ListItemText, Grid, Typography,
} from '@mui/material';
import {
  CPopper, CListItem, PaperWrapper, FieldCheckbox
} from './storesPopper.styles';

const StoresPopper = ({
  storesEl, setStoresEl, businessAndSiblings, filter, handleFilter
}) => {
  const [selected, setSelected] = useState(filter.store);

  const open = Boolean(storesEl);

  const handleSelect = (value, id) => {
    setSelected(value);
    handleFilter('store', value, id);
    setStoresEl(null);
  };

  // const handleSelect = (value) => {
  //   const selectedIndex = selected.indexOf(value);
  //   let newSelected = [];
  //   if (selectedIndex === 0 && selected[0] === 'All Stores') return;
  //   if ((selectedIndex === -1 && selected[0] === 'All Stores') || value === 'All Stores') {
  //     newSelected = newSelected.concat(value);
  //   } else if (selectedIndex === -1 && selected.length === 3) {
  //     newSelected = [...selected];
  //   } else if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, value);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  //   handleFilter('store', newSelected);
  //   setStoresEl(null);
  // };

  const businesses = businessAndSiblings.map((business) => ({
    ...business,
    name: `${business.name.split('Lifestores Pharmacy ')[1]} Store`
  })).filter((business) => !business.name.includes(undefined));

  return (
    <CPopper
      open={open}
      anchorEl={storesEl}
      transition
      disablePortal
      placement="bottom-end"
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <PaperWrapper>
            <ClickAwayListener onClickAway={() => setStoresEl(null)}>
              <List style={{ width: '100%', padding: 0 }}>
                {[{ id: '', name: 'All Stores' }, ...businesses].map(({ id, name: value }) => (
                  <CListItem
                    disableGutters
                    dense
                    button
                    key={id}
                    onClick={() => handleSelect(value, id)}
                    selected={value === selected}
                  >
                    <ListItemText
                      primary={(
                        <Grid container item alignItems="center">
                          <FieldCheckbox checked={value === selected} />
                          <Typography
                            variant="caption"
                            noWrap
                            style={{ fontSize: '1rem', color: '#606060', marginLeft: '.5rem' }}
                          >
                            {value}
                          </Typography>
                        </Grid>
                      )}
                    />
                  </CListItem>
                ))}
              </List>
            </ClickAwayListener>
          </PaperWrapper>
        </Fade>
      )}
    </CPopper>
  );
};

StoresPopper.propTypes = {
  storesEl: PropTypes.shape(Object),
  setStoresEl: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape(Array),
  businessAndSiblings: PropTypes.shape(Array),
  siblingRefetch: PropTypes.func.isRequired,
};

StoresPopper.defaultProps = {
  filter: {},
  storesEl: {},
  businessAndSiblings: [],
};

export default StoresPopper;
