import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_SALE_QUERY } from '../../../../queries/sales';
import { ContainerBody } from './return-details.style';
import MainContent from '../../../customComponents/mainContent';
import SalesDetails from './components/salesDetails';
import PaymentDetails from './components/paymentDetails';
import CustomerRewards from './components/customerRewards';
import Sales from './components/sales';
import Header from './components/header';

const SaleDetail = () => {
  const { id, param } = useParams();
  useEffect(() => {
    window.scroll(1, 1);
  }, []);

  const {
    loading, error, data, refetch
  } = useQuery(GET_SALE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id }
  });

  if (loading) return 'loading...';
  if (error) return `Error! ${error.message}`;
  return (
    <MainContent>
      <ContainerBody>
        <Header row={data} refetch={refetch} isReturn={param} />
        <SalesDetails row={data} isReturn={param} />
        <PaymentDetails row={data} />
        <CustomerRewards row={data} />
        <Sales row={data} isReturn={param} />
      </ContainerBody>
    </MainContent>
  );
};

export default SaleDetail;
