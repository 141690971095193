import React, { useState } from 'react';
import {
  Box, Button, DialogContent, Slide
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  Description,
  DialogContainer,
  DialogContentStyled,
  Title,
} from './mapProductDialog.styles';
import MarketPlaceReturnRow from './marketplaceReturnRow';

import NoDisplay from './noDisplay';
import { GET_BUSINESS_MP_ACCOUNTS } from '../../../queries/businesses';
import { SEND_ORDER_TO_MP } from '../../../mutations/orders';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const MarketPlaceDialog = ({
  openDialog, setOpenDialog, setOpenModalRedirectDialog
}) => {
  const [marketPlace, setMarketPlace] = useState('');

  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  const [sendOrderToMp, { loading: sendLoading }] = useMutation(SEND_ORDER_TO_MP);

  const sendOrderToMpHandler = () => {
    if (!marketPlace.length) return toast.error('Select MP account');

    sendOrderToMp({
      variables: { mpAccountLinkCode: marketPlace },
      refetchQueries: ['businessOrderListProducts', 'businessOrderListProductsTotalNumber']
    }).then(() => {
      handleClose();
      setOpenModalRedirectDialog(true);
    }).catch((err) => toast.error(err.message));
  };

  const {
    loading, data
  } = useQuery(GET_BUSINESS_MP_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables: { name: '' }
  });

  const rows = data?.businessMpAccounts || [];

  const renderRowComp = () => {
    if (loading) return <div>Loading...</div>;
    if (!rows.length) {
      return (
        <Box sx={{
          margin: '1.5rem 0'
        }}
        >
          <NoDisplay />
        </Box>
      );
    }
    if (rows.length > 0) {
      return (
        <Box sx={{
          margin: '1.5rem 0', width: '100%', overflow: 'scroll'
        }}
        >
          { rows.map((item) => (
            <MarketPlaceReturnRow
              key={item.id}
              row={item}
              setMarketPlace={setMarketPlace}
            />
          ))}
        </Box>
      );
    }
  };

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <Title>Send Order to Marketplace</Title>
        <Description>Sure you want to send order to marketplace</Description>
        <DialogContent sx={{ marginTop: '0', paddingX: 0 }}>
          {renderRowComp()}
          {
            rows.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '-1rem' }}>
              <Button onClick={handleClose} variant="outlined" sx={{ minWidth: '49%' }}>Cancel</Button>
              <Button disabled={sendLoading} onClick={sendOrderToMpHandler} variant="contained" sx={{ minWidth: '49%' }}>{sendLoading ? 'loading...' : 'Send' }</Button>
            </Box>
            )
          }
        </DialogContent>
      </DialogContentStyled>
    </DialogContainer>
  );
};

MarketPlaceDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  setOpenModalRedirectDialog: PropTypes.func,
};

MarketPlaceDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  setOpenModalRedirectDialog: () => false,
};

export default MarketPlaceDialog;
