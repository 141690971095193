import React, { useState, useLayoutEffect } from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { abbreviateNumber } from '../shared/utils';
import CustomGraphData from './customGraphData';
import { GET_SALES_ANALYSIS } from '../../queries/reports';

const fontStyle = { size: '16px', weight: 'bolder', color: '#000000' };

const ChartsComponent = ({ range, displayType, handleDownloadCSV }) => {
  const [valueTableData, setValueTableData] = useState();
  const [COGSTableData, setCOGSTableData] = useState();
  const [countValueData, setCountValueData] = useState();
  const [marginValueData, setMarginValueData] = useState();
  const [salesRepsTableData, setSalesRepsTableData] = useState();
  const [pricingCatsTableData, setPricingCatsTableData] = useState();

  const { loading, data } = useQuery(GET_SALES_ANALYSIS, {
    fetchPolicy: 'cache-and-network',
    variables: { filterBy: range }
  });

  const { reportsAnalysisDashboard } = data || {};

  const {
    salesByValueOnReportsGraph,
    salesByCostOfGoodsSoldOnReportsGraph,
    salesByCountOnReportsGraph,
    salesByMarginOnReportsGraph,
    salesByStoreRepOnReportsGraph,
    salesPerPricingCategoryGraph,

    totalSalesValueGraph,
    totalSalesCostOfGoodsSoldGraph
  } = reportsAnalysisDashboard || {};

  const saleByValue = salesByValueOnReportsGraph?.map((val) => val.total) || [];
  const COGS = salesByCostOfGoodsSoldOnReportsGraph?.map((val) => val.total) || [];
  const saleByCount = salesByCountOnReportsGraph?.map((val) => val.total) || [];
  const saleByMargin = salesByMarginOnReportsGraph?.map((val) => val.total) || [];

  const storeReps = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  };
  salesByStoreRepOnReportsGraph?.forEach(({ reps }) => reps?.forEach((rep, indx) => storeReps[indx + 1]?.push(rep?.total)));

  const pricingCats = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  };
  salesPerPricingCategoryGraph?.forEach(({ cats }) => cats?.forEach((cat, indx) => pricingCats[indx + 1]?.push(cat?.total)));

  const getStoreRepsRow = (indx) => {
    const repsDataRow = [];
    Object.values(storeReps)?.map((storeRep) => repsDataRow.push(storeRep[indx]));
    return repsDataRow;
  };

  const getPricingCatsRow = (indx) => {
    const catsDataRow = [];
    Object.values(pricingCats)?.map((pricingCat) => catsDataRow.push(pricingCat[indx]));
    return catsDataRow;
  };

  const SalesTableHeaders = [{ name: 'Period', width: '50%' }, { name: 'Value', width: '50%' }];

  const storeRepsTableHeaders = [{ name: 'Period', width: '6rem' }];
  if (salesByStoreRepOnReportsGraph?.length) {
    salesByStoreRepOnReportsGraph[0]?.reps.forEach((rep) => storeRepsTableHeaders.push({ name: rep?.sales_rep, width: '9rem' }));
  }

  const pricingCatTableHeaders = [{ name: 'Period', width: '6rem' }];
  if (salesPerPricingCategoryGraph?.length) {
    salesPerPricingCategoryGraph[0]?.cats.forEach((cat) => pricingCatTableHeaders.push({ name: cat?.cat_name || 'No-Name', width: '9rem' }));
  }

  const storeRepsLabels = [];
  if (salesByStoreRepOnReportsGraph?.length) {
    salesByStoreRepOnReportsGraph[0]?.reps.forEach((rep) => storeRepsLabels.push(rep?.sales_rep));
  }

  const pricingCatLabels = [];
  if (salesPerPricingCategoryGraph?.length) {
    salesPerPricingCategoryGraph[0]?.cats.forEach((cat) => pricingCatLabels.push(cat?.cat_name));
  }

  const periods = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const returnPeriod = () => {
    const weeks = salesByValueOnReportsGraph?.map((obj) => +obj.week);
    switch (range) {
      case 'week':
        return weeks?.map((week) => `Week ${week}`);
      default:
        return periods;
    }
  };

  useLayoutEffect(() => {
    const valueData = returnPeriod()?.map((period, index) => ({
      period,
      value: saleByValue[index]
    }));
    setValueTableData(valueData);
    const COGSData = returnPeriod()?.map((period, index) => ({
      period,
      value: COGS[index]
    }));
    setCOGSTableData(COGSData);
    const countData = returnPeriod()?.map((period, index) => ({
      period,
      value: saleByCount[index]
    }));
    setCountValueData(countData);
    const marginData = returnPeriod()?.map((period, index) => ({
      period,
      value: saleByMargin[index]
    }));
    setMarginValueData(marginData);
    const salesRepsData = returnPeriod()?.map((period, index) => ({
      period,
      value: getStoreRepsRow(index)
    }));
    setSalesRepsTableData(salesRepsData);
    const pricingCatsData = returnPeriod()?.map((period, index) => ({
      period,
      value: getPricingCatsRow(index)
    }));
    setPricingCatsTableData(pricingCatsData);
  }, [displayType, range, data]);

  const orderValueWeek = [];
  for (let index = 1; index < totalSalesValueGraph?.length; index++) {
    const construct = `Week ${index}`;
    orderValueWeek.push(construct);
  }

  const goodSoldWeek = [];
  for (let index = 1; index < totalSalesCostOfGoodsSoldGraph?.length; index++) {
    const construct = `Week ${index}`;
    goodSoldWeek.push(construct);
  }

  const salesByValueData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'line',
        label: 'Sales by Value',
        backgroundColor: '#ff6b0f',
        borderColor: '#ff6b0f',
        data: saleByValue,
        tension: 0.1
      },
    ]
  };

  const COGSData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'line',
        label: 'Cost of Goods Sold',
        backgroundColor: '#0cb970',
        borderColor: '#0cb970',
        data: COGS,
        tension: 0.1
      },
    ]
  };

  const salesByCountData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'line',
        label: 'Sales by Count',
        backgroundColor: '#117DE7',
        borderColor: '#117DE7',
        data: saleByCount,
        tension: 0.1
      },
    ]
  };

  const salesByMarginData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'line',
        label: 'Sales by Margin',
        backgroundColor: '#ff6b0f',
        borderColor: '#ff6b0f',
        data: saleByMargin,
        tension: 0.1
      },
    ]
  };

  const salesByStoreRepsData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'bar',
        label: storeRepsLabels[0],
        backgroundColor: '#117DE7',
        borderColor: '#117DE7',
        borderRadius: 15,
        data: storeReps[1],
        tension: 0.5
      },
      {
        type: 'bar',
        label: storeRepsLabels[1],
        backgroundColor: '#235A91',
        borderColor: '#235A91',
        borderRadius: 15,
        data: storeReps[2],
        tension: 0.5
      },
      {
        type: 'bar',
        label: storeRepsLabels[2],
        backgroundColor: '#4DA6FF',
        borderColor: '#4DA6FF',
        borderRadius: 15,
        data: storeReps[3],
        tension: 0.5
      },
      {
        type: 'bar',
        label: storeRepsLabels[3],
        backgroundColor: '#8EC4FB',
        borderColor: '#8EC4FB',
        borderRadius: 15,
        data: storeReps[4],
        tension: 0.5
      },
      {
        type: 'bar',
        label: storeRepsLabels[4],
        backgroundColor: '#FFC806',
        borderColor: '#FFC806',
        borderRadius: 15,
        data: storeReps[5],
        tension: 0.5
      },
      {
        type: 'bar',
        label: storeRepsLabels[5],
        backgroundColor: '#FF8754',
        borderColor: '#FF8754',
        borderRadius: 15,
        data: storeReps[5],
        tension: 0.5
      },
    ]
  };

  const salesPerPricingCarData = {
    labels: returnPeriod(),
    datasets: [
      {
        type: 'bar',
        label: pricingCatLabels[0],
        backgroundColor: '#235A91',
        borderColor: '#235A91',
        borderRadius: 15,
        data: pricingCats[1],
        tension: 0.5
      },
      {
        type: 'bar',
        label: pricingCatLabels[1],
        backgroundColor: '#AED6F1',
        borderColor: '#AED6F1',
        borderRadius: 15,
        data: pricingCats[2],
        tension: 0.5
      },
      {
        type: 'bar',
        label: pricingCatLabels[2],
        backgroundColor: '#3C6382',
        borderColor: '#3C6382',
        borderRadius: 15,
        data: pricingCats[3],
        tension: 0.5
      },
      {
        type: 'bar',
        label: pricingCatLabels[3],
        backgroundColor: '#4CA6FF',
        borderColor: '#4CA6FF',
        borderRadius: 15,
        data: pricingCats[4],
        tension: 0.5
      },
      {
        type: 'bar',
        label: pricingCatLabels[4],
        backgroundColor: '#FFC806',
        borderColor: '#FFC806',
        borderRadius: 15,
        data: pricingCats[5],
        tension: 0.5
      },
      {
        type: 'bar',
        label: pricingCatLabels[5],
        backgroundColor: '#FF8754',
        borderColor: '#FF8754',
        borderRadius: 15,
        data: pricingCats[6],
        tension: 0.5
      },
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: { display: true, text: 'Period', font: fontStyle },
        grid: { display: false }
      },
      y: {
        title: { display: true, text: 'Value', font: fontStyle },
        ticks: {
          // stepSize: range === 'month' ? 2500000 : 1500000,
          beginAtZero: true,
          callback(value) {
            return `${abbreviateNumber(value)}`;
          }
        }
      }
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          fontColor: '#474747',
          fontFamily: '20px Montserrat',
        },
        onClick: (e) => e.stopPropagation()
      }
    }
  };

  const marginSoldOptions = JSON.parse(JSON.stringify(options));
  marginSoldOptions.scales.y.title.text = '% Value';

  const pricingCatOptions = JSON.parse(JSON.stringify(options));
  pricingCatOptions.plugins.legend.display = true;
  pricingCatOptions.scales.y.ticks = {
    beginAtZero: true,
    callback(value) {
      return `${abbreviateNumber(value)}`;
    }
  };

  return (
    <Box style={{ marginTop: '1.5rem' }}>
      <Grid container spacing={2}>
        <Grid item container xs={6}>
          <CustomGraphData
            filter={range}
            displayType={displayType}
            heading="Sales  by Value"
            handleDownloadCSV={() => handleDownloadCSV('salesByValue')}
            data={salesByValueData}
            options={options}
            headers={SalesTableHeaders}
            tableData={valueTableData}
          />
        </Grid>
        <Grid item container xs={6}>
          <CustomGraphData
            filter={range}
            displayType={displayType}
            heading="Cost of Goods Sold"
            handleDownloadCSV={() => handleDownloadCSV('COGS')}
            data={COGSData}
            options={options}
            headers={SalesTableHeaders}
            tableData={COGSTableData}
          />
        </Grid>
        <Grid item container xs={6}>
          <CustomGraphData
            filter={range}
            displayType={displayType}
            heading="Sales by Count"
            handleDownloadCSV={() => handleDownloadCSV('salesByCount')}
            data={salesByCountData}
            options={options}
            headers={SalesTableHeaders}
            tableData={countValueData}
          />
        </Grid>
        <Grid item container xs={6}>
          <CustomGraphData
            filter={range}
            displayType={displayType}
            heading="Margin Sold"
            handleDownloadCSV={() => handleDownloadCSV('marginSold')}
            data={salesByMarginData}
            options={marginSoldOptions}
            headers={SalesTableHeaders}
            tableData={marginValueData}
          />
        </Grid>
        <Grid item container xs={6}>
          <CustomGraphData
            filter={range}
            displayType={displayType}
            heading="Sales by Store Reps"
            handleDownloadCSV={() => handleDownloadCSV('salesByStoreReps')}
            data={salesByStoreRepsData}
            options={pricingCatOptions}
            headers={storeRepsTableHeaders}
            tableData={salesRepsTableData}
            isMultipleValuesRow
          />
        </Grid>
        <Grid item container xs={6}>
          <CustomGraphData
            filter={range}
            displayType={displayType}
            heading="Sales Per Pricing Category"
            handleDownloadCSV={() => handleDownloadCSV('salesPerPricingCat')}
            data={salesPerPricingCarData}
            options={pricingCatOptions}
            headers={pricingCatTableHeaders}
            tableData={pricingCatsTableData}
            isMultipleValuesRow
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ChartsComponent.propTypes = {
  range: PropTypes.string,
  displayType: PropTypes.string,
  handleDownloadCSV: PropTypes.func,
};

ChartsComponent.defaultProps = {
  range: '',
  displayType: '',
  handleDownloadCSV: () => {},
};

export default ChartsComponent;
