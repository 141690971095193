import ImageUploadActionTypes from './imageUploadTypes';

const imageUploadReducer = (imageUpload, action) => {
  switch (action.type) {
    case ImageUploadActionTypes.UPDATE_IMAGE_UPLOADS:
      return {
        ...imageUpload,
        ...action.payload
      };
    default:
      return imageUpload;
  }
};

export default imageUploadReducer;
