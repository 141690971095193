import React from 'react';
import MapCsvDialog from '../../toBeDeleted/uploadSupplier/mapCsvDialog';
import { useStateValue } from '../../../providers/stateProvider';

function MapProductCsvDialog() {
  const [{ user: { platform } }, dispatch] = Object.values(useStateValue());
  const onErp = platform === 'erp';
  const fields = [
    ['Product Brand Name', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Generic Name', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Description', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true
    }],
    ['Pack Size', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Supplier', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Manufacturer', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true
    }],
    ['Therapeutic Type', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true
    }],
    ['Barcode', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Category', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,required'
    }],
    ['Unit Of Measurement', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: true,
      rules: 'str,nullable'
    }],
    ['Supplier Cost', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: !onErp,
      rules: 'required,money'
    }],

    ['Market RRP', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false, show: !onErp,
      rules: 'nullable,money'
    }],

    ['Fixed Item Price', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: !onErp,
      rules: 'required,money'
    }],
    ['Use Fixed Item Price', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: !onErp,
      rules: 'str,nullable'
    }],

    ['Order Cost', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,required,money'
    }],

    ['Fixed Selling Price', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,required,money'
    }],

    ['Use Fixed Selling Price', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp
    }],

    ['Reorder Unit', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],

    ['Reorder Point', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Reorder Max', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],

    ['Item Number', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    }],
    ['Create If Not Exist(Yes/No)', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    }],
    ['Product Variant', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,nullable'
    }],
    ['Minimum Order Quantity', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Max Lead Time', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Safety Stock', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'number,nullable'
    }],
    ['Product Class', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,nullable'
    }],
    ['Buffer', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: onErp,
      rules: 'str,nullable'
    }],
  ];
  const experimentalFields = [
    ['Service Provider', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    }],
    // ['Original Name', {
    //   required: false, unique: false, value: null, empty: true, touched: false, hover: false, show: true, adminOnly: true
    // }],
  ];
  if (!`${platform}`) {
    return;
  }
  return (
    <MapCsvDialog
      experimentalFields={experimentalFields}
      fields={fields.filter((item) => item[1].show)}
      model="product"
      endpoint="products"
      backRoute="products"
    />
  );
}

MapProductCsvDialog.propTypes = {
};
export default MapProductCsvDialog;
