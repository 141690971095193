import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableContainer, Button
} from '@mui/material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import {
  TableHeader,
  GridContainer,
  PaperWrapper,
  TableGrid,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MainTableHead,
  ButtonContainer,
  GoBack,
  EmptyRowCell,
  EmptyTitle,
  EmptyDescription
} from './manualBatch.styles';
import ReturnRow from './returnRow';
import { SEARCH_PO_QUERY } from '../../../queries/products';
import SearchPopper from './searchPopper';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { BATCH_QUERY } from '../../../queries/batch';
import { generateRandomNumber } from '../../../utils/funcs';
import { CircularProgressLoader } from '../../customComponents/customInputBase.styles';
import { EmptyList } from '../../../assets/svgs';
import CustomSearchField from '../../shared/CustomSearchField';
import { MANUAL_BATCH_CREATION, ADD_BATCH } from '../../../mutations/batch';
import SuccessDialog from '../../shared/successDialog';
import { toTitleCase } from '../../../utils/toTitleCase';
import ConfirmDialog from './confirmDialog';
import IndividualProductDialog from '../../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';
import AppToast from '../../shared/appToast';
import { useStateValue } from '../../../providers/stateProvider';
import { FIND_CATEGORY_QUERY } from '../../../queries/categories';

const poHeaders = [
  'S/N',
  'Date Received',
  'SKU',
  'Product Name',
  'Supplier',
  'Pricing Category',
  'Fixed Selling Price',
  'UOM',
  'Qty.',
  'UOM in Pack(s)',
  'Batch No',
  'Order Cost',
  'Expiry Date',
  'Action'
];

function ManualBatchContainer() {
  const returnHeaders = () => poHeaders.map((header) => <TableHeader key={header}>{header}</TableHeader>);
  const navigate = useNavigate();
  const [{
    product: { addProductDialogOpen }
  }, dispatch] = Object.values(useStateValue());

  const { data: batches, loading: batchLoading, refetch } = useQuery(BATCH_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [saveBatch, { loading: saveBatchLoading }] = useMutation(MANUAL_BATCH_CREATION);
  const [addBatch, { loading: addBatchLoading }] = useMutation(ADD_BATCH);

  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState(null);
  const [savedEl, setSavedEl] = useState(null);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [saveLimit, setSaveLimit] = useState(0);
  const [reload, setReload] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    title: '',
    description: ''
  });

  const [successModalText, setSuccessModalText] = useState({
    title: '',
    description: '',
  });

  const toggleIndividualDialog = () => {
    setSearchFieldEl(null);
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const perSave = 10;

  const calculateUOMInPack = (uom, quantity, noOfUom) => {
    let uomInPack = quantity;
    if (uom.toUpperCase() !== 'PACK') {
      const nUOM = (!noOfUom || (noOfUom === 0)) ? 1 : noOfUom;
      uomInPack = quantity / nUOM;
    }
    return Number(uomInPack)?.toFixed(2);
  };

  const {
    data: allCategoriesData
  } = useQuery(FIND_CATEGORY_QUERY);

  useEffect(() => {
    if (batches) {
      const autoLoadBatches = batches?.manualBatchProducts.map((batch) => {
        const {
          batchNo, product: {
            id, sku, brandName, erpVersion: {
              unitOfMeasurement, noOfUom
            }
          }, dateRecieved: dateReceived,
          manualBatchMeta: { sellingPrice, categoryName },
          supplier: {
            id: supplierId,
            name: supplierName
          }, uom, orderCost, expiryDate, quantityRecieved
        } = batch;

        const uomInPack = calculateUOMInPack(uom, quantityRecieved, noOfUom);

        return {
          sn: generateRandomNumber(10),
          productId: id,
          sku,
          dateReceived: dateReceived.split('T')[0],
          productName: brandName,
          noOfUom,
          supplier: supplierName,
          supplierId,
          pricingCategory: categoryName,
          sellingPrice,
          uom: toTitleCase(uom || 'Pack'),
          quantity: quantityRecieved,
          unitOfMeasurement,
          uomInPack,
          batchNo,
          orderCost,
          expiryDate: expiryDate ? expiryDate.split('T')[0] : '',
          prevSaved: true,
          changed: false,
        };
      });
      setSaveLimit(autoLoadBatches.length + perSave);
      setProducts(autoLoadBatches);
    }
  }, [batches, reload]);

  const [getSearchedProducts, { data, loading }] = useLazyQuery(
    SEARCH_PO_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        search: '',
        productName: search,
        status: 'enabled',
        manualBatch: true
      }
    }
  );

  const handlePopperClose = () => {
    setSearchedProducts([]);
    setSearchFieldEl(null);
  };

  const handleSearch = async (event) => {
    const { value } = event.target;
    setSearch(value);
    if (value && value.length > 2) {
      getSearchedProducts();
      setSavedEl(event.target);
    } else {
      handlePopperClose();
    }
  };

  const createData = (product) => {
    const prod = new Product(product, 'erp');
    return {
      productId: Number(prod?.id),
      brandName: prod?.brandName,
      sku: prod?.sku,
      quantityInStock: prod?.quantityInStock,
      supplier: prod?.supplier?.name,
      supplierId: Number(prod?.supplier?.id),
      pricingCategory: prod?.category?.categoryName,
      sellingPrice: prod?.fixedSellingPrice,
      packSize: prod?.packSize,
      itemPrice: prod?.orderCost,
      noOfUom: prod?.noOfUom,
      uom: prod?.unitOfMeasurement || 'Pack',
      unitOfMeasurement: prod?.unitOfMeasurement,
      isPreloaded: prod?.isPreloaded
    };
  };

  useEffect(() => {
    if (data?.erpProducts) {
      const retrieveProducts = data.erpProducts.map((product) => createData(product));
      setSearchedProducts(retrieveProducts);
      setSearchFieldEl(savedEl);
    }
  }, [data]);

  const handleProductSelection = (row) => {
    const next = generateRandomNumber(10);
    const today = new Date().toISOString().split('T')[0];
    const {
      productId, brandName, supplier, noOfUom, supplierId, uom, unitOfMeasurement, pricingCategory, sellingPrice, sku
    } = row;

    if (products.length >= saveLimit) return toast.error('Kindly  save current changes to continue process');

    const uomInPack = calculateUOMInPack(uom, 0, noOfUom);

    const newData = {
      sn: next,
      sku,
      productId,
      dateReceived: today,
      productName: brandName,
      noOfUom,
      supplier,
      supplierId,
      pricingCategory,
      sellingPrice,
      uom,
      unitOfMeasurement,
      quantity: 0,
      uomInPack,
      batchNo: '',
      orderCost: '',
      expiryDate: '',
      prevSaved: false,
      changed: false,
    };
    setProducts([...products, newData]);
    setUnsaved(true);
    handlePopperClose();
  };

  const extractVariables = (prods) => {
    const filteredProducts = prods.filter((p) => (p.prevSaved && p.changed) || !p.prevSaved);
    const emptyFieldProduct = filteredProducts.find((p) => !p.batchNo || !p.quantity || !p.orderCost || !p.sellingPrice);

    if (emptyFieldProduct) {
      toast.error('Required Fields: \n ⦾ Quantity \n ⦾ Batch No \n ⦾ Order Cost \n ⦾ Fixed Selling Price');
      return [];
    }

    return filteredProducts.map((product) => {
      const {
        productId, quantity, supplierId, batchNo, uom, orderCost, dateReceived, expiryDate, sellingPrice,
        pricingCategory
      } = product;
      const categoryId = allCategoriesData?.allCategories.find(({ categoryName }) => categoryName === pricingCategory)?.id;
      return {
        productId,
        quantity: Number(quantity),
        supplierId,
        batchNo,
        uom: uom.toUpperCase(),
        orderCost: Number(orderCost),
        dateReceived,
        expiryDate,
        sellingPrice: Number(sellingPrice),
        categoryId
      };
    });
  };

  // ================= Save Changes Handler ===================
  const saveChangesHandler = async () => {
    const request = {
      batchProducts: extractVariables(products)
    };
    if (!request.batchProducts.length) return;
    saveBatch({
      variables: { ...request },
    })
      .then(() => {
        setSuccessModalText({
          title: 'Progress Saved',
          description: 'Your progress while uploading batches has been saved successfully!'
        });
        setSuccessOpenDialog(true);
        refetch();
        setReload(!reload);
        setConfirmDialog(false);
        setUnsaved(false);
      })
      .catch((e) => toast.error(e.message));
  };

  // ================= Save Batch Handler ===================
  const addBatchHandler = () => {
    addBatch()
      .then(() => {
        setSuccessModalText({
          title: 'Batches Added!',
          description: 'Your batches have been successfully added to respective products.'
        });
        setSuccessOpenDialog(true);
        setUnsaved(false);
        setProducts([]);
      })
      .catch((e) => toast.error(e.message));
  };

  const handleOnChangeValue = (e, type, row) => {
    setUnsaved(true); // Indicates changes that are not saved

    const { value } = e.target;
    const {
      noOfUom, sn, uom, uomInPack: UIP
    } = row;
    let uomInPack = UIP;

    if (type === 'quantity') {
      // Calculate new uomInPack based on quantity change
      uomInPack = calculateUOMInPack(uom, value, noOfUom);
    }

    if (type === 'orderCost' || type === 'sellingPrice') {
      // Validate selling price against order cost
      const isSellingPriceValid = type === 'orderCost' ? Number(value) > row.sellingPrice : Number(value) < row.orderCost;

      if (isSellingPriceValid) {
        setToastMessage({
          title: 'Selling price is lower than cost price',
          description: 'The selling price of the product you\'re about to add is lower than its cost price'
        });
        setOpenToast(true); // Display toast message
      }
    }

    // Update products array with changed values
    const updatedProducts = products.map((product) => {
      if (product.sn === sn) {
        if (product.prevSaved) {
          return {
            ...product,
            [type]: value,
            changed: true,
            uomInPack
          };
        }
        return {
          ...product,
          [type]: value,
          uomInPack
        };
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const handleDate = (e, type, serial) => {
    setUnsaved(true);
    const { value } = e.target;
    const updatedProducts = products.map((product) => {
      if (product.sn === serial) {
        if (product.prevSaved) {
          return {
            ...product,
            [type]: value,
            changed: true,
          };
        }
        return {
          ...product,
          [type]: value,
        };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  const removeUnsavedRow = (sn) => {
    const result = products.filter((obj) => obj.sn !== sn);
    setProducts(result);
  };
  const removeRowHandler = (sn) => {
    const foundProduct = products.find((obj) => obj.sn === sn);
    if (foundProduct.prevSaved) {
      const {
        productId, supplierId, batchNo, uom, orderCost, dateReceived, expiryDate, sellingPrice
      } = foundProduct;

      const request = {
        batchProducts: [{
          productId,
          quantity: 0,
          supplierId,
          batchNo,
          uom: uom.toUpperCase(),
          orderCost: Number(orderCost),
          dateReceived,
          expiryDate,
          sellingPrice,
          categoryId: 0
        }]
      };
      saveBatch({
        variables: { ...request }
      })
        .then(() => {})
        .catch((e) => toast.error(e.message));
    }
    removeUnsavedRow(sn);
  };

  const switchUOM = (uom, row) => {
    setUnsaved(true);
    const { quantity, noOfUom, sn } = row;
    const uomInPack = calculateUOMInPack(uom, quantity, noOfUom);
    const updatedProducts = products.map((product) => {
      if (product.sn === sn) {
        if (product.prevSaved) {
          return {
            ...product,
            uom,
            uomInPack,
            changed: true,
          };
        }
        return {
          ...product,
          uom,
          uomInPack
        };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  let rowCounter = 0;

  const renderProducts = () => {
    let content;
    if (batchLoading) {
      content = (
        <EmptyRowCell colSpan={14} style={{ textAlign: 'center' }}>
          <CircularProgressLoader disableShrink size={40} thickness={5} />
        </EmptyRowCell>
      );
    } else if (!products?.length) {
      content = (
        <EmptyRowCell colSpan={14} style={{ textAlign: 'center' }}>
          <EmptyList style={{ fontSize: '8rem' }} />
          <EmptyTitle>No Product Batches Added yet!</EmptyTitle>
          <EmptyDescription>
            To add batches to products, please search for your intended products and select them. They will be automatically added to your table for edits.
          </EmptyDescription>
        </EmptyRowCell>
      );
    } else {
      content = products?.map((item) => {
        const { sn } = item;
        rowCounter += 1;
        return (
          <ReturnRow
            key={sn}
            row={item}
            index={rowCounter}
            handleOnChangeValue={handleOnChangeValue}
            switchUOM={switchUOM}
            removeRowHandler={removeRowHandler}
            handleDate={handleDate}
            allCategoriesData={allCategoriesData?.allCategories}
          />
        );
      });
    }
    return content;
  };

  const onGoBack = () => {
    if ((batches?.manualBatchProducts.length !== products.length) || unsaved) {
      setConfirmDialog(true);
    } else {
      navigate('/inventory/products');
    }
  };

  return (
    <MainContent>
      <GridContainer container>
        <GoBack onClick={onGoBack}>
          <KeyboardArrowLeft style={{ fontSize: '1.8rem', cursor: 'pointer' }} />
          Back
        </GoBack>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5">Add Batches Manually</PrimaryTitle>
            <ButtonContainer>
              <Button
                disabled={!products.length || saveBatchLoading}
                variant="outlined"
                style={{ width: '8rem', height: '3rem', border: '2px solid' }}
                onClick={saveChangesHandler}
              >
                { saveBatchLoading ? 'loading ...' : 'Save Progress' }
              </Button>
              <Button
                disabled={!products.length || addBatchLoading || unsaved}
                onClick={addBatchHandler}
                variant="contained"
                style={{ width: '8rem', height: '3rem' }}
              >
                { addBatchLoading ? 'loading ...' : 'Add Batches' }
              </Button>
            </ButtonContainer>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <CustomSearchField
            name="search"
            value={search}
            fullWidth
            placeholder="Search"
            handleChange={handleSearch}
            loading={loading}
          />
          <TableGrid item container>
            <TableContainer>
              <Table>
                <MainTableHead>
                  {returnHeaders()}
                </MainTableHead>
                <TableBody>
                  {renderProducts()}
                </TableBody>
              </Table>
            </TableContainer>
          </TableGrid>
        </PaperWrapper>
      </GridContainer>
      <SearchPopper
        searchFieldEl={searchFieldEl}
        handleClose={handlePopperClose}
        searchedProducts={searchedProducts}
        handleProductSelection={handleProductSelection}
        toggleIndividualDialog={toggleIndividualDialog}
      />

      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={successModalText.title}
        desc={successModalText.description}
        option="ok"
        refetch={refetch}
      />

      <ConfirmDialog
        openDialog={confirmDialog}
        setOpenDialog={setConfirmDialog}
        title="You have unsaved changes"
        desc="You have unsaved changes. Would you like to return and save them?"
      />
      <IndividualProductDialog
        dialogOpen={addProductDialogOpen}
        closeDialog={() => toggleIndividualDialog()}
      />
      <AppToast openToast={openToast} setOpenToast={setOpenToast} toastMessage={toastMessage} />
    </MainContent>
  );
}

export default ManualBatchContainer;
