import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import useSearchParamsState from '../../../helpers/ulrSearchParams';
import CustomSearchField from '../../../shared/CustomSearchField';
import StatusDropdown from './statusDropdown';
import {
  FiltersGrid, FilterContainer, ButtonOutlined
} from './singleInboundsFilter.styles';

const SingleInboundsFilter = ({
  search, setSearch
}) => {
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [, setStatus] = useSearchParamsState('status', '');

  const handleStatusChange = (_status) => {
    setDotsButtonEl(null);
    setStatus(_status.toUpperCase());
  };

  const options = [
    { name: 'All', disabled: false },
    { name: 'Matched', disabled: false },
    { name: 'Unmatched', disabled: false }
  ];

  return (
    <>
      <FilterContainer item container xs={12}>
        <FiltersGrid container item justifyContent="flex-end">
          <Grid item container style={{ width: '79.77%' }}>
            <CustomSearchField
              name="search"
              value={search}
              handleChange={(e) => setSearch(e.target.value)}
              fullWidth
              placeholder="Search"
            />
          </Grid>
          <Grid item container style={{ width: '9%' }}>
            <Button
              variant="outlined"
              onClick={(e) => setDotsButtonEl(e.currentTarget)}
              style={{ width: '100%' }}
            >
              <FilterList style={{ width: '20px', marginRight: '.4rem' }} />
              Filter
            </Button>
          </Grid>
          <ButtonOutlined>
            Export CSV
          </ButtonOutlined>
        </FiltersGrid>
      </FilterContainer>
      <StatusDropdown
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
        action={handleStatusChange}
      />
    </>
  );
};

SingleInboundsFilter.propTypes = {
  setSearch: PropTypes.isRequired,
  search: PropTypes.string.isRequired,
};

export default SingleInboundsFilter;
