import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { EXPORT_GRAPH_REPORT } from '../../mutations/reports';
import MainContent from '../customComponents/mainContent';
import RangePopper from './rangePopper';
import {
  ContainerBody, WrapperGrid, ChartsTitle, InputGrid,
  ExportHeaderButton, SalesReportButton
} from './reportsContainer.styles';
import CustomDataSwitch from '../shared/CustomDataSwitch/customDataSwitch';
import ChartsComponent from './chartsComponent';

const defaultFilter = {
  range: 'month',
};

const ReportsContainer = () => {
  const [displayType, setDisplayType] = useState('Graph');
  const [rangeEl, setRangeEl] = useState(null);
  const [filter, setFilter] = useState(defaultFilter);

  const { range } = filter;

  const [exportMutation] = useMutation(EXPORT_GRAPH_REPORT);

  useEffect(() => {
    window.scroll(1, 1);
  }, []);

  const startDate = moment().startOf('day');
  const endDate = moment().endOf('day');

  const handleFilter = (name, value) => {
    let val = value;
    switch (value) {
      case 'Monthly': val = 'month';
        break;
      default:
        val = 'week';
    }

    return setFilter((f) => ({ ...f, [name]: val }));
  };

  const handleTypeChange = (event) => {
    setDisplayType(event.target.checked ? 'Data' : 'Graph');
  };

  const [dateFilter, setDateFilter] = useState({
    dateFrom: startDate,
    dateTo: endDate
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDateFilter({
      dateFrom,
      dateTo
    });
  };

  const handleDownloadCSV = (csvType) => {
    const typeValue = { type: '', name: '' };
    const randomNum = Math.floor(Math.random() * 10000);
    switch (csvType) {
      case 'salesByValue':
        typeValue.type = 'sales_by_value_on_reports_graph';
        typeValue.name = `sales_by_value_on_reports_graph ${randomNum}`;
        break;
      case 'COGS':
        typeValue.type = 'sales_by_cost_of_goods_sold_on_reports_graph';
        typeValue.name = `sales_by_cost_of_goods_sold_on_reports_graph ${randomNum}`;
        break;
      case 'salesByCount':
        typeValue.type = 'sales_by_count_on_reports_graph';
        typeValue.name = `sales_by_count_on_reports_graph ${randomNum}`;
        break;
      case 'marginSold':
        typeValue.type = 'sales_by_margin_on_reports_graph';
        typeValue.name = `sales_by_margin_on_reports_graph ${randomNum}`;
        break;
      case 'salesByStoreReps':
        typeValue.type = 'sales_by_store_rep_on_reports_graph';
        typeValue.name = `sales_by_store_rep_on_reports_graph ${randomNum}`;
        break;
      case 'salesPerPricingCat':
        typeValue.type = 'sales_per_pricing_category_graph';
        typeValue.name = `sales_per_pricing_category_graph ${randomNum}`;
        break;
      case 'allReports':
        typeValue.type = 'sales_reports_graph_all';
        typeValue.name = `sales_reports_graph_all ${randomNum}`;
        break;
      default:
        return;
    }

    const { type, name } = typeValue;

    exportMutation({
      variables: { type, name, filterBy: range }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <MainContent>
      <ContainerBody>
        <WrapperGrid container>
          <ChartsTitle>Reports</ChartsTitle>
          <Grid item container xs={6} justifyContent="flex-end">
            <Grid item container xs={2.5} justifyContent="flex-end">
              <CustomDataSwitch
                checked={displayType === 'Data'}
                sliderValue={displayType}
                handleChange={handleTypeChange}
              />
            </Grid>
            <InputGrid
              item
              container
              xs={2.5}
              onClick={(e) => setRangeEl(e.currentTarget)}
              data-testid="range-grid"
            >
              {`${range}ly`}
              <KeyboardArrowDownRounded style={{ fontSize: '1.5rem', color: '#385F84' }} />
            </InputGrid>
            <Grid item container xs={4} justifyContent="flex-end" style={{ marginLeft: '.5rem' }}>
              <SalesReportButton disabled>
                Sales Report
                <KeyboardArrowDownRounded />
              </SalesReportButton>
            </Grid>
            <Grid item container xs={2.5} justifyContent="flex-end" style={{ marginLeft: '.5rem' }}>
              <ExportHeaderButton onClick={() => handleDownloadCSV('allReports')}>
                Export All CSV
              </ExportHeaderButton>
            </Grid>
          </Grid>
        </WrapperGrid>
        <ChartsComponent
          range={range}
          displayType={displayType}
          handleDownloadCSV={handleDownloadCSV}
        />

        <RangePopper
          rangeEl={rangeEl}
          setRangeEl={setRangeEl}
          filter={filter}
          handleFilter={handleFilter}
        />
      </ContainerBody>
    </MainContent>
  );
};

export default ReportsContainer;
