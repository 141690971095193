import React from 'react';
import {
  Grid, Typography, Box, Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';

export const FilterContainer = styled('div')`
    border: 1px solid #E7E8E9;
    border-radius: 20px;

    @media (max-width: 991px) {
      border: none;
      margin-top: 8rem;
    }
`;

export const FilterPaper = styled('div')`
    display: block;
    height: 90%;
    margin: auto;
    width: 90%;
`;

export const FilterClose = styled(Box)`
  margin-bottom: 8rem;
  padding: 3rem;
  margin-right: 3rem;
  text-align: right;
`;

export const FilterGrid = styled(Grid)`
    background: #E1E1E1;;
    border-radius: 15px 15px 0 0;
    display: flex;
    padding: 15px;
    margin-top: 1rem;

    @media (max-width: 991px) {
      margin-top: 0;
    }
`;

export const TextGrid = styled(Grid)`
    padding: 15px;
`;

export const Container = styled('div')`
    padding: 15px;
`;

export const FilterBox = styled(Grid)`
`;

export const FilterText = styled(Typography)`
    text-align: right;
    cursor: pointer;
    font-size: 2rem;

    @media (min-width: 992px) {
      font-size: .9em;
    }
`;

export const FilterHeader = styled(Typography)`
    font-size: 2.3em;
    font-weight: 600;

    @media (min-width: 992px) {
      font-size: 1em;
    }
`;

export const ListGrid = styled('div')`
`;

export const FilterHandle = styled(Grid)`
  cursor: pointer;

  @media (max-width: 991px) {
    margin-left: 30px;
    margin-bottom: 1rem;
    line-height: 6;
  }
`;

export const FilterNameTypo = styled(Typography)`
  font-size: .8rem;

  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const ListItems = styled(Grid)`
`;

export const HeaderGrid = styled(Grid)`
  background: #F3F3F3;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 991px) {
    & .MuiSvgIcon-root {
      font-size: 4rem;
    }
  }
`;

export const PriceHeader = styled(Grid)`
  background: #FAFAFA;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px 15px 0 0;

  @media (max-width: 991px) {
    padding: 1rem 1rem 0 1rem;
    & .MuiSvgIcon-root {
      font-size: 4rem;
    }
  }
`;

export const FilterTypo = styled(Typography)`
   font-size: 1rem;
   font-weight: 600;

  @media (max-width: 991px) {
    font-size: 2.3rem;
    font-weight: 500;
  }
`;

export const ItemContentGrid = styled(Grid)`
  overflow: hidden;
  overflow-y: scroll;
  max-height: 220px;
  background: #F3F3F3;

  @media (max-width: 991px) {
    max-height: 500px;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #303030;
    border-radius: 10px;
  }
`;

export const BrandContentGrid = styled(Grid)`
  overflow: hidden;
  overflow-y: scroll;
  max-height: 220px;

  @media (max-width: 991px) {
    max-height: 500px;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #303030;
    border-radius: 10px;
  }
`;
export const PriceItem = styled(Grid)`
  background: #FAFAFA;
  height: 100%;
  border-radius: 0 0 15px 15px;
`;

export const AvailabilityGrid = styled(Grid)`
  @media (min-width: 992px) {
    margin-top: ${(props) => (props.category ? '2rem' : '1rem')};
  }

  @media (max-width: 991px) {
    // margin-top: ${(props) => (props.category ? '4rem' : '8rem')};
    margin-bottom: 3rem;
  }
`;

export const PriceList = styled('div')`
  @media (min-width: 992px) {
    margin-top: ${(props) => (props.category ? '2rem' : '1rem')};
    margin-bottom: 1.5rem;
  }

  @media (max-width: 991px) {
    // margin-top: ${(props) => (props.category ? '4rem' : '8rem')};
    margin-bottom: 3rem;
  }
  // @media (max-width: 991px) {
  //   margin-top: 4rem;
  // }

  // @media (min-width: 992px) {
  //   margin-top: 30px;
  // }
`;

export const SupplierList = styled('div')`
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    border-radius: 0 0 10px 10px;
  }

  @media (max-width: 991px) {
    margin-top: 5rem;
  }
`;

export const SubCategoriesList = styled('div')`
  border-radius: 0 0 10px 10px;
  @media (max-width: 991px) {
    margin-bottom: 3rem;
  }
  @media (min-width: 992px) {
    border-radius: 0 0 10px 10px;
  }
`;

export const FooterGrid = styled(Box)`
  @media (max-width: 991px) {
    padding-left: 1.5rem;
  }
`;

export const BrandListGrid = styled('div')`
  @media (min-width: 992px) {
    margin-top: ${(props) => (props.category ? '2rem' : '1rem')};
  }

  @media (max-width: 991px) {
    // margin-top: ${(props) => (props.category ? '4rem' : '8rem')};
    margin-bottom: 3rem;
  }
`;

export const ClearText = styled(Typography)`
  margin-left: auto;
  font-size: .7rem;
  font-weight: 500;
  margin-top: .2rem;

  @media (max-width: 991px) {
    font-size: 1.8rem;
    font-weight: 500;
  }
`;
export const CTablePagination = styled(({ ...otherProps }) => (
  <TablePagination {...otherProps} />
))`
  @media (min-width: 992px) {
    & .MuiTablePagination-caption {
      font-size: .8rem;
      margin-left: 10px;
    }
    & .MuiToolbar-gutters {
      padding-left: 0;
    }

    & .MuiTablePagination-actions {
      margin-left: 0;
    }

    & .MuiToolbar-root {
      overflow: hidden;
    }
  }
  @media (max-width: 991px) {
    & .MuiTablePagination-caption,
      .MuiTablePagination-select {
      font-size: 2rem;
    }
    & .MuiIconButton-root {
      overflow: hidden;
      & .MuiIconButton-label {
        & .MuiSvgIcon-root {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #615958;
  @media (max-width: 991px) {
    & .MuiSvgIcon-fontSizeSmall {
      font-size: 3rem;
    }
  }
`;

export const SubScrollBox = styled(Box)`
  padding: 0 .8rem 1.5rem .3rem;
  background: #F3F3F3;
  border-radius: 0 0 15px 15px;
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 3rem 1.5rem .3rem;
  }
`;

export const ScrollBox = styled(Box)`
  padding: 0 .8rem 1.5rem .3rem;
  background: #FAFAFA;
  border-radius: 0 0 15px 15px;
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 3rem 1.5rem .3rem;
  }
`;

export const IconGrid = styled(Grid)`
  @media (min-width: 992px) {
    text-align: center;
  }

  @media (max-width: 991px) {
    text-align: right;
  }
`;

export const SubIconGrid = styled(Grid)`
  @media (min-width: 992px) {
    padding: .4rem;
  }
  @media (max-width: 991px) {
    padding: 1.2rem;
  }
`;

export const FilterByBox = styled(Grid)`
  background-color: #E1E1E1;
  border-radius: 20px;
  padding: 2.5rem 2rem;
  margin-bottom: 3rem;
  height: 130px;
`;

export const FilterByText = styled(Typography)`
  color: #606060;
  font-size: 2.2rem;
  font-weight: 600;
`;

export const ClearAllText = styled(Typography)`
  text-align: right;
  font-size: 2rem;
`;
