import React, { useContext, useReducer, useLayoutEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import mainReducer from './mainReducer';
import initialState from './initialState';
import { GET_USER_INFO } from '../queries/user';
import { updateUserSession } from './reducers/user/userUtils';
import { GET_MY_BUSINESS } from '../queries/affiliates';

export const StateContext = React.createContext();

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const token = localStorage.getItem('oga_auth_token');

  // This is the only query functionality that should live in this file please!
  const [getUserInfo, { loading, data, refetch }] = useLazyQuery(GET_USER_INFO);
  const [getStorePreferences, { loading: businessLoading, data: businessData }] = useLazyQuery(GET_MY_BUSINESS);

  useLayoutEffect(() => {
    getUserInfo({ fetchPolicy: 'network-only' });
    getStorePreferences({ fetchPolicy: 'network-only' });
  }, [token, getUserInfo, getStorePreferences]);

  const userData = loading || !data?.userInfo ? null : data;
  const businessPreference = businessLoading || !businessData?.business ? null : businessData;

  const sessionData = updateUserSession(userData);
  const stateWithSession = {
    ...state,
    user: {
      ...state.user,
      ...sessionData,
      businessPreference,
      getUserInfoRefetch: refetch
    }
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = { state: stateWithSession, dispatch };
  return (
    <StateContext.Provider value={values}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);

StateProvider.propTypes = {
  children: PropTypes.instanceOf(Object)
};

StateProvider.defaultProps = {
  children: {}
};

export default StateProvider;
