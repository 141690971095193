import {
  TableCell, Grid, TableContainer, TableRow, IconButton, Paper,
  Typography
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomButton from '../../customComponents/customButton';

export const DivFullWidth = styled('div')`
  width: 100%;
`;

export const GridContainer = styled(Grid)`
  padding: 30px;
`;

export const WrapperPaper = styled(Paper)`
  border-radius: 10px;
  box-shadow: 3px 3px 10px 4px rgb(194 194 194 / 9%);
  width: 100%;
  padding: 1.3rem;
`;

export const TableWrapper = styled('div')`
  margin-bottom: 1.5rem;
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-bottom: 1rem;
  }
`;

export const PerformanceTab = styled(Grid)`
  justify-content: space-between;
`;

export const TableColumnHeaderTitle = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #F3F9FF;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.5rem;
  padding-left: 0px;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  &:nth-child(1) {
    width: 5%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 10%;
  }
  &:nth-child(5) {
    width: 10%;
  }
  &:nth-child(6) {
    width: 15%;
  }
  &:nth-child(7) {
    width: 10%;
  }
  &:nth-child(8) {
    width: 15%;
  }
`;
export const MainTableRow = styled(TableRow)`
  cursor: pointer;
  & > * {
    border-bottom: unset;
  }
`;
export const AffiliateBodyCell = styled(TableCell)`
  font-size: 1.5rem;

  &:first-child, :last-child {
    border-bottom: 0;
  }
  &:nth-child(2), :nth-child(3), :nth-last-child(2) {
    padding: 0;
  }

  @media(min-width: 992px) {
    font-size: 1rem;
    padding: 16px;
    padding-left: 0;
  }
`;
export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-bottom: 30px;
`;
export const AffiliateTableContainerWrapper = styled(TableContainer)`
  overflow-y: hidden;
  padding: 0 20px;
`;

export const AffiliateRowImageContainer = styled('div')`
  width: 3rem;
  height: 3rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
`;
export const AffiliateLocation = styled(Grid)`
  margin-top: 5px;
  font-size: 0.8rem;
  color: #A3A3A3;
`;

export const SortCart = styled('div')`
  position: absolute;
  background: white;
  border: 0.1px solid #f0eaea;
  padding: 1%;
  right: 50px;
  margin-top: -0.2%;
  width: 319px;
  box-shadow: 1px 2px 3px #00000029;
  border-radius: 0px 0px 7px 7px;
`;

export const SortImage = styled('img')`
  width: 7%;
  margin-right: 2%;
`;

export const PaddingBottom = styled('div')`
  padding-bottom: 10%;
`;

export const StyledCheckBox = styled(CheckBox)`
  position: abosolute;
  left: 265px;
`;

export const DeleteSaleIcon = styled(DeleteIcon)`
  font-size: 3.5rem;
  color: #A3A3A3;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  padding-top: 7px;
`;

export const TabImage = styled('img')`
  width: 26px;
`;

export const SaleReturnTag = styled('span')`
  background-color: #4bADE8;
  border-radius: 15%;
  color: #FFF;
  margin-right: 1rem;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
`;

export const TableGrid = styled(Grid)`
`;

export const TableColumnHeaders = styled('div')`
  display: flex;
  padding: 0 35px;
`;

export const TableColumnRows = styled('div')`
  display: flex;

  :nth-child(odd) {
    background: #F3F9FF;
  }
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: 11px;
  font-weight: 700;
  line-height: 24px;
  color: #303030;
  padding: 20px 0px;

  @media(min-width: 992px) {
    font-size: 20px;
    padding: 10px 0px;
  }
`;

export const StyledCustomButton = styled(CustomButton)`
  width: 136px;
  height: 36.67px;
  border-radius: 5px;

  & .label {
    font-weight: 700;
    font-size: 9.16854px;
  }
`;

export const SearchGrid = styled(Grid)`
  padding: 1rem 0
`;

export const ShowingTextGrid = styled(Grid)`
  font-size: 1.6rem;
  font-weight: 500;
  color: #606060;
  margin: 10px 0;

  @media (min-width: 992px) {
    font-size: 14px;
    margin: 14px 0;
  }
`;

export const FilterText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #606060;
  opacity: 0.5;
`;
