import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useMediaQuery, Grid } from '@mui/material';
import toast from 'react-hot-toast';
import StatusDropdown from './statusDropdown';
import currencyFormatter from '../../../utils/currencyFormatter';
import { UPDATE_PRODUCT_BATCH_STATUS, DELETE_BATCH_MUTATION } from '../../../mutations/products';
// import ActionPopper from './actionPopper';
import ConfirmDialog from '../../shared/confirmDialog';
import { BlueEyeOutline } from '../../../assets/svgs';
import {
  TableColumnRowText, TableColumnRows, ViewContainer, ViewText, DropDownArrow, ColorField, Status
} from './returnRow.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { useStateValue } from '../../../providers/stateProvider';

const ReturnRow = ({
  row, rowIndx, handleBatchEdit, refetch
}) => {
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);

  const navigate = useNavigate();

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const {
    id, dateCreated, batchNo, brandName, quantityInStock, status, supplier, orderCost,
    expiryDate, lastUpdatedField, lastUpdatedBy, lastUpdatedDate, previousInfo, currentInfo
  } = row;

  const datePlaced = moment(dateCreated).format('D MMM YYYY h:mm a');
  const lastUpdated = moment(lastUpdatedDate).format('DD MMM YYYY h:mm a');

  const [updateProductBatchStatus] = useMutation(UPDATE_PRODUCT_BATCH_STATUS);
  const handleStatusChange = (_status) => {
    setDotsButtonEl(null);
    updateProductBatchStatus({
      variables: {
        batchId: id,
        status: _status,
      }
    }).then(({ data }) => {
      const { updateProductBatchStatus: { message } } = data ?? {};
      toast.success(message);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const [deleteBatchMutate, { loading }] = useMutation(DELETE_BATCH_MUTATION);

  if (confirmStatus) {
    deleteBatchMutate({
      variables: {
        batchId: id,
      }
    }).then(({ data }) => {
      const { deleteBatch: { message } } = data ?? {};
      toast.success(message);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
    setDotsButtonEl(null);
    setConfirmStatus(false);
  }

  const renderStatus = (_status) => (
    <Grid
      item
      container
      style={{
        cursor: 'pointer', pointerEvents: !userPermissions?.includes('batch_details_update_batch') ? 'none' : ''
      }}
      onClick={(e) => setDotsButtonEl(e.currentTarget)}
    >
      <ColorField>
        <Status status={_status} />
        {_status}
      </ColorField>
      <DropDownArrow />
    </Grid>
  );

  const getStatus = () => {
    switch (status) {
      case 'EXPIRED':
        return renderStatus('expired');
      case 'DAMAGED':
        return renderStatus('damaged');
      default:
        return renderStatus('sellable');
    }
  };

  const cells = [
    { name: batchNo, width: '150px' }, { name: currencyFormatter(orderCost), width: '130px' }, { name: expiryDate ? moment(expiryDate).format('DD MMM YYYY') : 'N/A', width: '150px' }, { name: getStatus(), width: '150px' },
    { name: quantityInStock, width: '100px' }, { name: lastUpdatedField, width: '175px' }, { name: lastUpdatedBy, width: '150px' },
    { name: lastUpdated, width: '250px' }, { name: previousInfo, width: '230px' }, { name: currentInfo, width: '220px' },
  ];

  const returnCell = (value) => value || '-';

  const useRenderBodyCells = () => {
    const isLarge = useMediaQuery('(min-width:992px)');
    return cells.map(({ name, width }) => (
      <TableColumnRowText style={{ width }} key={name}>{isLarge ? returnCell(name) : ''}</TableColumnRowText>
    ));
  };

  const handleClick = () => {
    handleBatchEdit(row);
  };

  const options = [
    { name: 'sellable', disabled: status === 'SELLABLE' },
    { name: 'damaged', disabled: status === 'DAMAGED' },
    { name: 'expired', disabled: status === 'EXPIRED' }
  ];

  // const handleAction = (type, setOpen) => {
  //   switch (type) {
  //     case 'view_batch_info':
  //       return navigate(`/batch-details-info/${id}`);
  //     case 'delete_batch':
  //       return setOpenConfirmDialog(true);
  //     default:
  //       break;
  //   }
  //   setOpen(false);
  // };

  const viewBatchHandler = () => navigate(`/batch-details-info/${id}`);

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '70px' }}>{rowIndx + 1}</TableColumnRowText>
      <TableColumnRowText style={{ width: '250px' }}>{datePlaced}</TableColumnRowText>
      <TableColumnRowText style={{ width: '250px' }}>
        {supplier?.name}
      </TableColumnRowText>
      <TableColumnRowText
        style={{
          cursor: 'pointer', width: '250px',
          pointerEvents: !userPermissions?.includes('batch_details_edit_batch') ? 'none' : ''
        }}
        onClick={handleClick}
      >
        {brandName}
      </TableColumnRowText>
      {useRenderBodyCells()}
      <TableColumnRowText style={{ width: '210px' }}>
        {/* <ActionPopper handleAction={handleAction} /> */}
        <ViewContainer onClick={viewBatchHandler}>
          <BlueEyeOutline />
          <ViewText>View Batch Info</ViewText>
        </ViewContainer>
      </TableColumnRowText>

      <StatusDropdown
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
        action={handleStatusChange}
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Delete Batch"
        desc="Are you sure you want to delete batch?"
        options={['Cancel', 'Yes, Delete']}
        setStatus={setConfirmStatus}
        loading={loading}
      />
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  rowIndx: PropTypes.number.isRequired,
  handleBatchEdit: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
