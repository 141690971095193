import React, { useState, useLayoutEffect } from 'react';
import Hidden from '@mui/material/Hidden';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import Model from './models';
import MainContent from '../../customComponents/mainContent';
import {
  ModelGridContainer, ModelsTab, ModelsNumber, SortImg, SortText, ExportBox,
  IconsGridContainer, TabIcon, TabImage, ModelNumberBox, SortBox, CategoryTab
} from './models.styles';
import ReportExportModel from '../../../utils/reportExportModel';
import TitleGrid from './titleGrid';
import CustomTableSearchField from '../customTableSearchField';
import sortAsc from '../../../assets/images/supplier/Sort Ascending Icon.png';
import sortDesc from '../../../assets/images/supplier/Sort Descending Icon.png';
import exportIcon from '../../../assets/images/supplier/Export Icon.png';
import { useStateValue } from '../../../providers/stateProvider';
import CustomExportButton from '../../downloads/exportModule/exportComponent';
import CustomSearchField from '../CustomSearchField';
import sort from '../../../assets/images/marketOrder/sort.png';
import { ExportIcon } from '../../../assets/svgs';
import { EXPORT_MUTATION } from '../../../mutations/reports';

function Models({
  findModelsQuery, modelName, modelTotalNumber, modelQueryName, extraModelQueryParam,
  renderModelCard, modelCardOnclickAction, renderIndividualModel, modelNamePlural,
  loop, renderTable, categoriesList
}) {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    searchText: '',
    orderBy: 'ASC',
    model: []
  };

  let endpoint;

  const [state, setState] = useState(initialState);

  const [openExportModel, setOpenExportModel] = useState(false);
  const [reload, setReload] = useState(false);
  const reloadFunc = () => setReload(!reload);

  const [{ model: { modelsCount }, user: { allowedPermissionsMap: { userPermissions } } }] = Object.values(useStateValue());

  const {
    currentPage, searchText
  } = state;

  const handleOpenExport = () => {
    setOpenExportModel(true);
  };

  if (modelName === 'Supplier') {
    endpoint = 'suppliers';
  } else if (modelName === 'Customer') {
    endpoint = 'customers';
  } else if (modelName === 'Category') {
    endpoint = 'categories';
  } else if (modelName === 'Product type') {
    endpoint = 'therapeutic-types';
  }

  let fileName;
  if (modelName === 'Supplier') {
    fileName = 'supplier';
  } else if (modelName === 'Customer') {
    fileName = 'customer';
  } else if (modelName === 'Category') {
    fileName = 'category';
  } else if (modelName === 'Product type') {
    fileName = 'therapeutic-type';
  }

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, searchValue: searchText });
    } else if (!searchText) {
      setState({ ...state, searchValue: '' });
    }
  }, [searchText]);

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };
  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };
  const handleSort = () => {
    const { orderBy } = state;
    if (orderBy === 'ASC') return setState({ ...state, orderBy: 'DESC' });
    return setState({ ...state, orderBy: 'ASC' });
  };

  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const randomNum = Math.floor(Math.random() * 10000);
  const NewfileName = `${endpoint} ${randomNum}`;

  const handleClick = async () => {
    exportMutation({
      variables: {
        type: endpoint,
        name: NewfileName,
        status: ''
        // ...variable
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const sortImage = (state.orderBy === 'ASC') ? sortDesc : sortAsc;
  return (
    <MainContent>
      <ModelGridContainer>
        <TitleGrid
          modelNamePlural={modelNamePlural}
          modelName={modelName}
          renderIndividualModel={renderIndividualModel}
          endpoint={endpoint}
          fileName={fileName}
          categoriesList={categoriesList}
          reloadFunc={reloadFunc}
        />

        {categoriesList ? (
          <CategoryTab>
            <ModelNumberBox>
              <ModelsNumber>
                {`${modelsCount} ${modelsCount <= 1 ? modelName : `${modelNamePlural}`}`}
              </ModelsNumber>
            </ModelNumberBox>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder={`Search ${modelName}`}
              handleChange={(e) => handleSearch(e.target.value)}
              style={{ width: '100%' }}
              data-testid="searchCategory"
            />
            <SortBox onClick={handleSort} style={{ cursor: 'pointer' }}>
              <SortImg src={sort} alt="sort" />
              <SortText>Sort</SortText>
            </SortBox>
            <ExportBox
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
            >
              <ExportIcon />
              <SortText>Export</SortText>
            </ExportBox>
          </CategoryTab>
        )
          : (
            <ModelsTab item container>
              <ModelsNumber>
                {`${modelsCount} ${modelsCount <= 1 ? modelName : `${modelNamePlural}`}`}
              </ModelsNumber>
              <Hidden mdDown>
                <IconsGridContainer item container md={6}>
                  <CustomTableSearchField
                    searchText={searchText}
                    placeholder={`Search ${modelName.toLowerCase()}`}
                    handleChange={(e) => handleSearch(e.target.value)}
                  />
                  <TabIcon onClick={handleSort}>
                    <TabImage src={sortImage} alt="Search" />
                  </TabIcon>
                  {endpoint === 'therapeutic-types' ? (
                    <TabIcon onClick={handleOpenExport}>
                      <TabImage src={exportIcon} alt="Search" />
                    </TabIcon>
                  ) : (
                    <CustomExportButton
                      title="Export"
                      color="secondary"
                      fileName={endpoint}
                      onlyIcon="onlyIcon"
                      name={endpoint}
                      disabled={!userPermissions?.includes(`${endpoint}_download`)}
                    />
                  )}
                  <ReportExportModel
                    open={openExportModel}
                    handleClose={() => setOpenExportModel(false)}
                    endpoint={endpoint}
                    params={extraModelQueryParam}
                  />
                </IconsGridContainer>
              </Hidden>
            </ModelsTab>
          )}
        <Model
          loop={loop}
          state={state}
          handleChangePage={handleChangePage}
          page={currentPage}
          findModelsQuery={findModelsQuery}
          extraModelQueryParam={extraModelQueryParam}
          modelName={modelName}
          modelNamePlural={modelNamePlural}
          modelTotalNumber={modelTotalNumber}
          modelQueryName={modelQueryName}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSearch={handleSearch}
          renderModelCard={renderModelCard}
          renderTable={renderTable}
          modelCardOnclickAction={modelCardOnclickAction}
          categoriesList={categoriesList}
          reload={reload}
        />
      </ModelGridContainer>
    </MainContent>
  );
}
Models.propTypes = {
  findModelsQuery: PropTypes.instanceOf(Object).isRequired,
  extraModelQueryParam: PropTypes.instanceOf(Object),
  modelName: PropTypes.string.isRequired,
  modelTotalNumber: PropTypes.string.isRequired,
  modelQueryName: PropTypes.string.isRequired,
  renderModelCard: PropTypes.func.isRequired,
  renderTable: PropTypes.func.isRequired,
  modelCardOnclickAction: PropTypes.func.isRequired,
  renderIndividualModel: PropTypes.func.isRequired,
  modelNamePlural: PropTypes.string.isRequired,
  loop: PropTypes.bool.isRequired,
  categoriesList: PropTypes.bool
};
Models.defaultProps = {
  extraModelQueryParam: {},
  categoriesList: false
};
export default Models;
