import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import CustomSearchField from '../../shared/CustomSearchField';
import { SearchFlex } from './ordersFilter.styles';
import { EXPORT_ORDER_LIST } from '../../../mutations/reports';

const OrdersFilter = ({
  search, setSearch, saveChangesHandler, loading
}) => {
  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_ORDER_LIST);
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    exportMutation({
      variables: {
        type: 'download_order_list_products', name: `order_list_products_${randomNum}`, productName: search
      }
    })
      .then(({ data: exportData }) => {
        const { message } = exportData?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <SearchFlex>
      <CustomSearchField
        name="search"
        value={search}
        placeholder="Search for products..."
        handleChange={(e) => setSearch(e.target.value)}
        style={{ width: '80%' }}
      />
      <Button disabled={loading} onClick={saveChangesHandler} variant="outlined" style={{ width: '10%' }}>
        Save Changes
      </Button>
      <Button disabled={exportLoading} onClick={handleDownloadCSV} variant="outlined" style={{ width: '10%' }}>
        { exportLoading ? 'loading...' : 'Export as CSV' }
      </Button>
    </SearchFlex>
  );
};

OrdersFilter.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  saveChangesHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default OrdersFilter;
