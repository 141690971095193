import React from 'react';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import { InputAdornment, Button, Grid } from '@mui/material';
import moment from 'moment';
import { CaretUpIcon, CaretDownIcon, RemoveCircle } from '../../assets/svgs';
import {
  CDialog, DialogWrapper, SubText, HeaderText, DialogHeader, DialogBody, DialogActions,
  CloseIcon, TableHeader, HeaderGrid, MainTableRow,
  BodyCell, CustomInputBase, ProductName, HeaderIcon
} from './transferDialog.styles';
import StockTransferTypes from '../../providers/reducers/stockTransfer/stockTransferTypes';

import { useStateValue } from '../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const TransferDialog = ({
  openDialog, closeDialog, handleSelect, openLocationDialog
}) => {
  const [{
    stockTransfer: { transferItems },
  }, dispatch] = Object.values(useStateValue());

  const handleChange = (e, batch, product) => {
    const { value } = e?.target ?? '';
    if (value && (+value < 0 || +value > batch.quantityInStock)) return;
    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER_BATCHES,
      payload: {
        batchId: batch.id,
        quantity: +value,
        product
      }
    });
  };

  const handleIncrement = (batch, product) => {
    const { qtyToTransfer = 0 } = batch;
    if (qtyToTransfer === batch.quantityInStock) return;
    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER_BATCHES,
      payload: {
        batchId: batch.id,
        quantity: qtyToTransfer + 1,
        product
      }
    });
  };

  const handleDecrement = (batch, product) => {
    const { qtyToTransfer = 0 } = batch;
    if (qtyToTransfer <= 0) return;
    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER_BATCHES,
      payload: {
        batchId: batch.id,
        quantity: qtyToTransfer - 1,
        product
      }
    });
  };

  const handleContinue = () => {
    closeDialog();
    openLocationDialog();
  };

  const handleDisable = () => {
    if (!transferItems.length) return null;
    return transferItems.every((item) => item.batches.some((batch) => batch.qtyToTransfer));
  };

  const headers = ['S/N', 'Batch #', 'Transfer Date', 'Qty in Stock', 'Qty to Transfer'];

  const renderBatchItems = (item) => {
    const batchList = [];
    item.batches.forEach((batch) => {
      batchList.push(batch);
    });
    return (
      <DialogBody item container>
        <HeaderGrid item container>
          {headers.map((header) => <TableHeader key={header}>{header}</TableHeader>)}
        </HeaderGrid>
        {batchList.map((batch, indx) => (
          <MainTableRow key={batch.orderProductId} item container>
            <BodyCell>{indx + 1}</BodyCell>
            <BodyCell>{batch?.batchNo}</BodyCell>
            <BodyCell>{moment().format('YYYY-MM-DD')}</BodyCell>
            <BodyCell>{batch?.quantityInStock}</BodyCell>
            <BodyCell>
              <CustomInputBase
                type="number"
                placeholder="0"
                min={1}
                size="medium"
                value={batch.qtyToTransfer}
                onChange={(e) => handleChange(e, batch, item)}
                endAdornment={(
                  <InputAdornment position="end">
                    <Grid item container direction="column">
                      <CaretUpIcon
                        style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                        onClick={() => handleIncrement(batch, item)}
                      />
                      <CaretDownIcon
                        style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                        onClick={() => handleDecrement(batch, item)}
                      />
                    </Grid>
                  </InputAdornment>
                )}
              />
            </BodyCell>
          </MainTableRow>
        ))}
      </DialogBody>
    );
  };

  return (
    <CDialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <Grid item container justifyContent="flex-end">
            <CloseIcon onClick={closeDialog} />
          </Grid>
          <Grid item container>
            <HeaderText>Multi-Transfer Stocks</HeaderText>
          </Grid>
        </DialogHeader>
        {transferItems.length ? transferItems.map((item) => (
          <Grid item container key={item.brandName} style={{ marginBottom: '3rem' }}>
            <Grid item container>
              <ProductName>{item.brandName}</ProductName>
              <Grid item container xs={7} alignItems="center">
                <SubText variant="caption">
                  Pack Size:
                  &nbsp;
                  <b style={{ color: '#303030' }}>{item.packSize}</b>
                </SubText>
                <SubText variant="caption">
                  Quantity in Stock:
                  &nbsp;
                  <b style={{ color: '#303030' }}>{item.quantityInStock}</b>
                </SubText>
              </Grid>
              <Grid item container xs={5} justifyContent="flex-end">
                <HeaderIcon onClick={(e) => handleSelect(e, item)}>
                  <RemoveCircle style={{ fontSize: '1.5rem', marginRight: '8px' }} />
                  <SubText variant="caption">Remove Product</SubText>
                </HeaderIcon>
              </Grid>
            </Grid>
            {renderBatchItems(item)}
          </Grid>
        )) : (
          <ProductName>There are no Products to transfer</ProductName>
        )}
        <DialogActions item container>
          <Button
            variant="outlined"
            onClick={closeDialog}
            style={{ marginRight: '1.2rem' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!handleDisable()}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </DialogActions>
      </DialogWrapper>
    </CDialog>
  );
};

TransferDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  openLocationDialog: PropTypes.func.isRequired
};

export default TransferDialog;
