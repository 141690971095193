import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import MainContent from '../../customComponents/mainContent';
import TitleGrid from './titleGrid';
import SideNav from '../shared/sideNav/sideNav';
import {
  TitleGridWrapper, SubBusinessContentWrapper,
} from './subBusinesses.styles';
import { GET_SUB_BUSINESS } from '../../../queries/businesses';
import { useStateValue } from '../../../providers/stateProvider';
import Businesses from './businesses';

const startDate = moment().startOf('day').format('YYYY-MM-DD');
const endDate = moment().endOf('day').format('YYYY-MM-DD');
const initialState = {
  searchText: '',
  dateFilter: {
    dateFrom: startDate,
    dateTo: endDate
  }
};

const SubBusinesses = () => {
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const { searchText } = state;
  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const variables = {
    pageCount,
    pageNumber,
    search: searchValue
  };

  const {
    loading, data, refetch
  } = useQuery(GET_SUB_BUSINESS, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const {
    subBusinesses = [],
  } = data || {};

  return (
    <MainContent>
      <TitleGridWrapper>
        <TitleGrid />
      </TitleGridWrapper>
      <SubBusinessContentWrapper container spacing={3}>
        <SideNav />
        <Businesses
          state={state}
          rows={subBusinesses}
          handleSearch={handleSearch}
          loading={loading}
          refetch={refetch}
        />
      </SubBusinessContentWrapper>
    </MainContent>
  );
};

export default SubBusinesses;
