import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Grid, TableRow, TableBody, Paper
} from '@mui/material';
import { AFFILIATE_INVOICE_QUERY } from '../../../queries/affiliates';
import Loader from '../../shared/loader';
import CustomTableSearchField from '../../shared/customTableSearchField';
import CTablePagination from '../../customComponents/cTablePagination';
import {
  TableGridContainer, PaymentColumnHeaderTitle, PaymentRowText, TableContainerWrapper,
  PaymentTableTitle, TableOptionsTab, TableComponent, IconsGridContainer, MainTableHead,
  NoPaymentText, MainTableRow, PaymentCountText, FooterGrid, InvoiceButton
} from './affiliatesDetailsContainer.styles';
import currencyFormatter from '../../shared/currencyFormatter';
import { OgaToken } from '../../../graphql/token';

const AffiliatesPaymentHistory = () => {
  const { id } = useParams();
  const [searchText, setSearchText] = useState('');
  const [showing, setShowing] = useState([1, 10]);

  const initialState = {
    currentPage: 1,
    currentPageCount: 10,
    data: {},
  };
  const [state, setState] = useState(initialState);

  const {
    currentPage, currentPageCount
  } = state;

  const handleChangePage = (_, newPage) => {
    if (newPage === currentPage) {
      setShowing([showing[0] + 10, showing[1] + 10]);
    } else {
      setShowing([showing[0] - 10, showing[1] - 10]);
    }
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const {
    loading, error, data
  } = useQuery(AFFILIATE_INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      affiliateId: id,
      pageCount: currentPageCount,
      pageNumber: currentPage,
      ...(searchText && searchText.length >= 3 && { status: searchText }),
    },
  });
  const affiliateInvoices = data?.affiliateInvoices;
  const invoicesTotalNumber = data?.invoicesTotalNumber;

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  };
  const handleDownload = (invoiceId) => {
    window.open(
      `${OgaToken.SERVER_LINK}download_partial_invoice/${invoiceId}`,
      '_blank'
    );
  };

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <TableGridContainer container>
      <TableOptionsTab container alignItems="center">
        <Grid item>
          <PaymentTableTitle>
            {`Payments(${affiliateInvoices.length})`}
          </PaymentTableTitle>
        </Grid>
        <IconsGridContainer item container md={8}>
          <CustomTableSearchField
            id="payment-search"
            searchText={searchText}
            placeholder="Search payments"
            handleChange={(event) => handleChangeSearchText(event)}
          />
        </IconsGridContainer>
      </TableOptionsTab>

      <PaymentCountText>
        Showing
        <b>
          {` ${showing[0]} - ${affiliateInvoices.length < showing[1] ? affiliateInvoices.length : showing[1]} `}
        </b>
        of
        <b>
          {` ${invoicesTotalNumber} `}
        </b>
      </PaymentCountText>

      <TableContainerWrapper component={Paper}>
        <TableComponent>
          <MainTableHead>
            <TableRow>
              <PaymentColumnHeaderTitle>
                Date Created
              </PaymentColumnHeaderTitle>
              <PaymentColumnHeaderTitle>
                Order ID
              </PaymentColumnHeaderTitle>
              <PaymentColumnHeaderTitle>
                Amount
              </PaymentColumnHeaderTitle>
              <PaymentColumnHeaderTitle>
                Status
              </PaymentColumnHeaderTitle>
              <PaymentColumnHeaderTitle>
                Credit Days
              </PaymentColumnHeaderTitle>
              <PaymentColumnHeaderTitle>
                Tax
              </PaymentColumnHeaderTitle>
              <PaymentColumnHeaderTitle>
                Invoice ID
              </PaymentColumnHeaderTitle>
              <PaymentColumnHeaderTitle />
            </TableRow>

          </MainTableHead>
          <TableBody>
            {
                affiliateInvoices && affiliateInvoices.length ? (
                  affiliateInvoices
                    .sort((a, b) => new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf())
                    .map((payment) => (
                      <MainTableRow key={payment.reference}>
                        <PaymentRowText>
                          {moment(payment.dateCreated).format('DD-MMM-YYYY')}
                        </PaymentRowText>
                        <PaymentRowText>{payment?.order?.id}</PaymentRowText>
                        <PaymentRowText>{currencyFormatter(payment?.total)}</PaymentRowText>
                        <PaymentRowText>{payment?.status}</PaymentRowText>
                        <PaymentRowText>{payment?.creditDays}</PaymentRowText>
                        <PaymentRowText>{payment?.tax}</PaymentRowText>
                        <PaymentRowText>{payment?.id}</PaymentRowText>
                        <PaymentRowText>
                          <InvoiceButton onClick={() => handleDownload(payment?.id)}>Open</InvoiceButton>
                        </PaymentRowText>
                      </MainTableRow>
                    ))
                ) : (
                  <Grid container>
                    <NoPaymentText>There are no payments for this affiliate.</NoPaymentText>
                  </Grid>
                )
              }
          </TableBody>
        </TableComponent>
      </TableContainerWrapper>
      <FooterGrid item container xs={12} justifyContent="flex-end">
        <Grid item container justifyContent="flex-end">
          <CTablePagination
            component="div"
            count={invoicesTotalNumber}
            page={currentPage - 1}
            onChangePage={handleChangePage}
            rowsPerPage={currentPageCount}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </FooterGrid>
    </TableGridContainer>
  );
};

export default AffiliatesPaymentHistory;
